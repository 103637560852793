import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  constructor() {}

  reload() {
    // https://github.com/Microsoft/TypeScript/issues/28898
    // tslint:disable-next-line
    location.reload(true);
  }
}
