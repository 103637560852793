import { Inject, Injectable, isDevMode, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import {
  ChartSource,
  FlexExchange,
  JSFCEntry,
  StockController,
  StockView,
  StockWrapper,
  Trace,
  ViewHistoryService,
} from '@argentumcode/brisk-common';
import { JsfcService } from 'fita3/src/app/core/jsfc.service';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { StockDetailsService } from './stock-details.service';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from './order/types/interfaces';
import { SingleStockOperatorService } from './single-stock-operator.service';
import { MarketsService } from 'fita3/src/app/core/markets.service';
import { environment as fita3Env } from 'fita3/src/environments/default/environment';

@Injectable()
export class StockDetailService<T = any> implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private stockWrapper?: StockWrapper;

  public jsfcEntries: BehaviorSubject<Array<JSFCEntry>> = new BehaviorSubject([]);
  public issueCode: number | undefined;
  private _issueCodeSubj = new BehaviorSubject<number | undefined>(null);
  issueCode$ = this._issueCodeSubj.asObservable();
  issueCodeChanged = new Subject<number>();
  chartSource: BehaviorSubject<ChartSource> = new BehaviorSubject<ChartSource>(null);
  detailToolBoxChartSource: BehaviorSubject<ChartSource> = new BehaviorSubject<ChartSource>(null);
  enableSyncWithCocomero = false;
  private _visible = false;
  private _visibleSubj = new BehaviorSubject(this._visible);
  visible$: Observable<boolean> = this._visibleSubj.asObservable();

  private _isOnPrimaryPanel = false;
  private _isOnPrimaryPanelSubj = new BehaviorSubject(this._isOnPrimaryPanel);
  isOnPrimaryPanel$ = this._isOnPrimaryPanelSubj.asObservable();

  private _orderStateInitialized = false;
  private _orderState: T;
  private _orderStateInitial = new ReplaySubject<T>(1);
  orderStateInitial$: Observable<T> = this._orderStateInitial.asObservable();
  private _orderStateUpdated = new ReplaySubject<T>(1);
  orderStateUpdated: Observable<T> = this._orderStateUpdated.asObservable();

  private _jsfcTimerSubscription: Subscription;

  private _syncingCocomero = true;

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (this._visible !== value) {
      this._visible = value;
      this._visibleSubj.next(value);
    }
  }

  get isOnPrimaryPanel(): boolean {
    return this._isOnPrimaryPanel;
  }

  set isOnPrimaryPanel(value: boolean) {
    if (this._isOnPrimaryPanel !== value) {
      this._isOnPrimaryPanel = value;
      this._isOnPrimaryPanelSubj.next(value);
    }
  }

  get stockOperator(): StockWrapper {
    return this.stockWrapper;
  }

  get time() {
    return this.cocomero.time;
  }

  constructor(
    private market: MarketsService,
    private cocomero: CocomeroService,
    private zone: NgZone,
    private jsfc: JsfcService,
    private chart: ChartService,
    private viewHistoryService: ViewHistoryService,
    private stockDetails: StockDetailsService,
    private singleStockOperator: SingleStockOperatorService,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator
  ) {
    this.subscription.add(
      this.orderOperator.updateStock.subscribe((issueCode) => {
        if (this.stockOperator) {
          if (issueCode === null || issueCode === this.stockOperator.current.master.issueCode) {
            this.stockOperator.update = true;
          }
        }
      })
    );
    this.subscription.add(
      this.market.jsfcUpdate$.subscribe((updated) => {
        if (updated.has(this.issueCode)) {
          const issueCode = this.issueCode;

          this.zone.runOutsideAngular(() => {
            if (this._jsfcTimerSubscription) {
              this._jsfcTimerSubscription.unsubscribe();
            }
            this._jsfcTimerSubscription = timer((60 + Math.random() * 300) * 1000).subscribe(() => {
              this.zone.runGuarded(() => {
                if (this.issueCode !== issueCode) {
                  return;
                }
                this.jsfc.getJSFC(this.issueCode).subscribe(([ic, e]) => {
                  if (ic === this.issueCode) {
                    this.jsfcEntries.next(e);
                  }
                });
              });
            });
          });
        }
      })
    );
  }

  syncWithCocomero(): number {
    this.enableSyncWithCocomero = true;
    this.subscription.add(
      this.cocomero.issueCodeChanged.subscribe((issueCode) => {
        if (!this._syncingCocomero) {
          this.changeIssueCode(issueCode);
        }
      })
    );
    return this.cocomero.issueCode;
  }

  changeIssueCode(issueCode: number, updateHistory = true) {
    if (this.issueCode === issueCode) {
      return;
    }
    if (isDevMode()) {
      if (typeof issueCode !== 'number') {
        throw new Error('IssueCode must be number');
      }
    }
    if (!(issueCode in this.cocomero.op.issueCodeMap)) {
      console.error('invalid issue code');
      if (issueCode !== fita3Env.flexConfig.fallbackIssueCode) {
        // fallback
        this.changeIssueCode(fita3Env.flexConfig.fallbackIssueCode, updateHistory);
      }
      return;
    }
    if (this.enableSyncWithCocomero) {
      this._syncingCocomero = true;
      this.cocomero.changeIssueCode(issueCode);
      this._syncingCocomero = false;
    } else if (updateHistory) {
      this.cocomero.updateHistory(issueCode);
    }
    if (this.stockWrapper) {
      this.singleStockOperator.disposeStockWrapper(this.stockWrapper);
    }
    this.stockWrapper = this.singleStockOperator.createStockWrapper(issueCode);
    this.issueCode = issueCode;
    this.issueCodeChanged.next(this.issueCode);
    this._issueCodeSubj.next(this.issueCode);

    this.jsfcEntries.next([]);
    this.jsfc.getJSFC(issueCode).subscribe(([ic, e]) => {
      if (ic === this.issueCode) {
        this.jsfcEntries.next(e);
      }
    });

    if ((fita3Env.flexConfig.exchange as FlexExchange) === FlexExchange.Tokyo) {
      this.chartSource.next(
        new ChartSource(
          this.stockOperator,
          this.chart.getChart(issueCode, this.stockOperator.date),
          3,
          undefined,
          undefined,
          undefined,
          this.chart.getNk225TodayChart(),
          this.chart.nk225Name,
          this.chart.updated,
          this.chart.getNk225PastChart(this.stockOperator.date)
        )
      );
    } else {
      this.chartSource.next(new ChartSource(this.stockOperator, this.chart.getChart(issueCode, this.stockOperator.date), 3));
    }
    this.detailToolBoxChartSource.next(
      new ChartSource(
        this.stockOperator,
        null,
        1,
        undefined,
        undefined,
        undefined,
        this.chart.getIndustryChart(this.stockOperator.base.master),
        this.stockOperator.base.master.industryName,
        this.chart.updated
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this._jsfcTimerSubscription) {
      this._jsfcTimerSubscription.unsubscribe();
    }
    if (this.stockWrapper) {
      this.singleStockOperator.disposeStockWrapper(this.stockWrapper);
      this.stockWrapper = undefined;
    }
  }

  saveOrderState(state: T) {
    if (!this._orderStateInitialized) {
      throw new Error('not initialized');
    }
    this._orderState = state;
    this._orderStateUpdated.next(this._orderState);
  }

  setInitialOrderState(state: T) {
    if (this._orderStateInitialized) {
      throw new Error('already initialized');
    }
    this._orderState = state;
    this._orderStateInitialized = true;
    this._orderStateInitial.next(state);
    this._orderStateInitial.complete();
  }
}
