import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CancelEventArgs } from '@grapecity/wijmo';
import { Font } from '../font';
import { ResizeService } from '../../resize/resize.service';
import { Subscription } from 'rxjs';

export class CellRenderEvent {
  row: number;
  col: number;
  render: CellRenderer;
  force: boolean;
}

export class DrawContext {
  context: CanvasRenderingContext2D;
  row: number;
  column: number;
  top: number;
  height: number;
  left: number;
  width: number;
}

export class Column {
  bold: boolean;
  width: string | number;
  heading: string;
  name: string;
}

class ColumnWidth {
  originalWidth: string | number;
  width: number;
  left: number;
}

class Border {
  /**
   * Border Color
   */
  color: string;

  /**
   * Border Width
   */
  width: number;
}

export class CellRenderer {
  /**
   *  セルの描画を行わないならば True
   */
  skip: boolean;

  /**
   * 描画する文字列
   */
  text: string;

  /**
   * テキストの行揃え
   */
  textAlign: undefined | 'left' | 'center' | 'right';

  /**
   * 背景色
   */
  backgroundColor: string;

  /**
   * 文字色
   */
  color: string;

  /**
   * 下側ボーダー
   */
  borderBottom: Border;

  /**
   * 右側ボーダー
   */
  borderRight: Border;

  /**
   * ユーザー定義のセル背景レンダラー
   */
  customBackgroundRenderer: (DrawContext) => void;

  /**
   * ユーザー定義の罫線レンダラー
   */
  customBorderRenderer: (DrawContext) => void;

  /**
   * ユーザー定義のテキストレンダラー
   */
  customTextRenderer: (DrawContext) => void;

  /**
   * パディング
   */
  padding: number;

  /**
   * フォントウェイト
   */
  fontWeight: number;

  /**
   * キャッシュ用のキー
   */
  cacheKey: string;

  public createSimpleCacheKey() {
    if (this.skip) {
      return;
    }
    if (this.customBackgroundRenderer || this.customBorderRenderer || this.customTextRenderer) {
      this.cacheKey = undefined;
      return;
    }
    this.cacheKey =
      `${this.text}|${this.textAlign}|${this.backgroundColor}|` +
      `${this.color}|${this.padding}|${this.borderKey(this.borderBottom)}|${this.borderKey(this.borderRight)}|${this.fontWeight}`;
  }

  public appendSimpleCacheKey() {
    this.cacheKey +=
      `${this.text}|${this.textAlign}|${this.backgroundColor}|` +
      `${this.color}|${this.padding}|${this.borderKey(this.borderBottom)}|${this.borderKey(this.borderRight)}|${this.fontWeight}`;
  }

  private borderKey(b: Border): string {
    if (!b) {
      return '';
    } else {
      return `${b.width}|${b.color}`;
    }
  }
}

export interface CellMouseEvent extends MouseEvent {
  row: number;
  col: number;
}

export class ScrollChangedEvent {
  public row: number;
  public includeScrollTopChange = true;
  public eventSource: any;
}

@Component({
  selector: 'brisk-canvas-grid',
  templateUrl: './canvas-grid.component.html',
  styleUrls: ['./canvas-grid.component.css'],
})
export class CanvasGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _scrollTopChange = false;
  private _scrollTopChangeObject: any = null;

  get scrollIndex(): number {
    return Math.round(this.scroll.nativeElement.scrollTop / this.rowHeight);
  }

  get rows(): number {
    return this._rows;
  }

  set rows(value: number) {
    if (this._rows !== value) {
      this._rows = value;
      this.updateSize();
    }
  }

  public rowHeaderElement: ElementRef;
  public rowElement: ElementRef;
  public rowHeaderHeightOriginal: number = undefined;
  public rowHeightOriginal: number = undefined;

  @Input()
  public columns: Array<Column> = [];
  private columnsWidth: Array<ColumnWidth> = [];

  public dataCache: Array<Array<string>>;

  public rowHeaderHeight = 23;
  public rowHeight = 19;
  private _rows = 0;
  public dummyRows = undefined;
  private scrollBarShown: boolean = undefined;

  public defaultHeaderBackgroundColor = '#eaeaea';
  public defaultHeaderFont: Font;
  public defaultBackgroundColor = undefined;
  public defaultColor = undefined;
  public defaultFont: Font;

  public defaultBorderWidth = 1;
  public defaultBorderColor = '#c6c6c6';
  public defaultPadding = 3;

  @Input()
  public cursorStyle = 'auto';

  protected currentMouseCell: [number, number] = null;

  private forceQueue = false;
  @ViewChild('canvas', { static: true })
  private canvasElement: ElementRef;
  @ViewChild('canvasWrapper', { static: true })
  private canvasWrapperElement: ElementRef;

  @ViewChild('focusElement', { static: true })
  public focusElement: ElementRef;

  @Output()
  public cellRender = new EventEmitter<CellRenderEvent>();

  @Output()
  public cellMouseDown = new EventEmitter<CellMouseEvent>();

  @Output()
  public cellMouseUp = new EventEmitter<CellMouseEvent>();

  @Output()
  public cellMouseLeave = new EventEmitter<CellMouseEvent>();

  @Output()
  public cellMouseEnter = new EventEmitter<CellMouseEvent>();

  @Output()
  public cellDblClick = new EventEmitter<CellMouseEvent>();

  @Output()
  public headerCellDblClick = new EventEmitter<CellMouseEvent>();

  @Output()
  public drawing = new EventEmitter<CancelEventArgs>();

  @Output()
  public sizeUpdated = new EventEmitter();

  public maxRowCount: number;

  @Input()
  public realMaxRowCount: number;

  @Input()
  public realRowCount: number;

  @Input()
  public headerRowCount = 0;

  @Input()
  public footerRowCount = 0;

  @Input()
  public fixedRowCount = false;

  @Input()
  fixedWidth: number = undefined;

  @Output()
  public scrollChange = new EventEmitter<ScrollChangedEvent>();

  public height: number = null;

  private columnId = 0;

  private lastSize = null;

  private scaleFactor = 1.0;

  private columnMap = new Map<number, Column>();

  private initialized = false;

  @ViewChild('canvasHost', { static: true })
  private hostElement: ElementRef;

  @ViewChild('scrollContent', { static: true })
  public scrollContent: ElementRef;

  @ViewChild('scroll', { static: true })
  public scroll: ElementRef;

  @Input()
  public showScrollbar = false;

  private _scrollIndex: number;
  private _resizeSubscription: Subscription;

  constructor(private resize: ResizeService) {
    this._resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
  }

  public getColumnId() {
    return this.columnId++;
  }

  public addColumn(id: number, column: Column) {
    this.columnMap.set(id, column);
    this.updateColumn();
  }

  public removeColumn(id: number) {
    this.columnMap.delete(id);
    this.updateColumn();
  }

  private updateColumn() {
    this.columns = [];
    const keys: Array<number> = [];
    this.columnMap.forEach((value, key) => {
      keys.push(Number(key));
    });
    for (const id of keys.sort((a, b) => a - b)) {
      this.columns.push(this.columnMap.get(Number(id)));
    }
    this.updateSize(true);
  }

  private initializeTheme() {
    this.initialized = true;
    this.defaultColor = getComputedStyle((this.rowElement && this.rowElement.nativeElement) || this.hostElement.nativeElement).color;
    this.defaultFont = new Font(getComputedStyle((this.rowElement && this.rowElement.nativeElement) || this.hostElement.nativeElement));
    this.defaultHeaderFont = new Font(
      getComputedStyle((this.rowHeaderElement && this.rowHeaderElement.nativeElement) || this.hostElement.nativeElement)
    );

    if (this.rowHeaderHeightOriginal) {
      this.rowHeaderHeight = this.rowHeaderHeightOriginal;
    } else {
      this.rowHeaderHeight =
        parseInt(
          getComputedStyle((this.rowHeaderElement && this.rowHeaderElement.nativeElement) || this.hostElement.nativeElement).lineHeight,
          10
        ) + 1;
    }
    if (this.rowHeightOriginal) {
      this.rowHeight = this.rowHeightOriginal;
    } else {
      this.rowHeight =
        parseInt(getComputedStyle((this.rowElement && this.rowElement.nativeElement) || this.hostElement.nativeElement).lineHeight, 10) + 1;
    }
    const bw = getComputedStyle((this.rowElement && this.rowElement.nativeElement) || this.hostElement.nativeElement).borderWidth;
    if (bw && bw !== '0px') {
      this.defaultBorderWidth = Math.max(1 / this.scaleFactor, Math.round(parseInt(bw, 10) * this.scaleFactor) / this.scaleFactor);
    }
    if (this.rowHeaderElement) {
      this.defaultHeaderBackgroundColor = getComputedStyle(this.rowHeaderElement.nativeElement).backgroundColor;
    }
    this.defaultBackgroundColor = getComputedStyle(this.hostElement.nativeElement).backgroundColor;

    this.defaultBorderColor = getComputedStyle(
      (this.rowElement && this.rowElement.nativeElement) || this.hostElement.nativeElement
    ).borderBottomColor;
  }

  ngOnInit() {
    this.initializeTheme();
  }

  ngOnDestroy() {
    this._resizeSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.updateSize();
  }

  updateSize(force = false) {
    if (!this.initialized) {
      return;
    }
    const host: HTMLElement = this.hostElement.nativeElement;
    const canvas: HTMLCanvasElement = this.canvasElement.nativeElement;
    if (!this.fixedWidth) {
      host.style.width = `${this.scrollContent.nativeElement.offsetWidth}px`;
    }
    const width = this.fixedWidth ? this.fixedWidth : host.offsetWidth - 1;
    const height = this.fixedWidth ? 100 : host.offsetHeight - 1;
    if (
      this.lastSize !== null &&
      this.lastSize[0] === width &&
      this.lastSize[1] === height &&
      force === false &&
      !this.fixedRowCount &&
      false
    ) {
      return;
    }
    this.lastSize = [width, height];
    if (width < 0 || (height < 0 && !this.fixedRowCount)) {
      return;
    }
    this.scaleFactor = Math.ceil(window.devicePixelRatio || 1);
    canvas.width = width * this.scaleFactor;
    canvas.style.width = `${width}px`;
    if (!this.fixedRowCount) {
      canvas.height = height * this.scaleFactor;
      canvas.style.height = `${height}px`;
    }
    this.initializeTheme();
    this.updateColumnsWidth();
    const lastMaxRowCount = this.maxRowCount;
    this.maxRowCount = Math.max(0, Math.trunc((height - this.rowHeaderHeight) / this.rowHeight));
    this.canvasWrapperElement.nativeElement.style.width = `${width}px`;
    if (this.fixedRowCount) {
      this.canvasWrapperElement.nativeElement.style.height = `${this.rowHeaderHeight + this._rows * this.rowHeight}px`;
      this.height = this.rowHeaderHeight + (this.dummyRows || this._rows) * this.rowHeight + this.defaultBorderWidth / this.scaleFactor;
      canvas.height = (this.rowHeaderHeight + (this.dummyRows || this._rows) * this.rowHeight) * this.scaleFactor;
      canvas.style.height = `${this.rowHeaderHeight + (this.dummyRows || this._rows) * this.rowHeight}px`;

      this.scroll.nativeElement.style.height = `${this.rowHeaderHeight + this._rows * this.rowHeight}px`;

      if (this.showScrollbar && this.realMaxRowCount && this._rows < this.realMaxRowCount) {
        this.scrollContent.nativeElement.style.height = `${
          this.rowHeaderHeight + this.realMaxRowCount * this.rowHeight + this.defaultBorderWidth
        }px`;
        if (this.scrollBarShown !== true) {
          this.scrollBarShown = true;
          this.updateSize();
        }
      } else {
        this.scrollContent.nativeElement.style.height = '0px';
        if (this.scrollBarShown !== false) {
          this.scrollBarShown = false;
          this.updateSize();
        }
      }
    } else {
      this.canvasWrapperElement.nativeElement.style.height = `${
        this.rowHeaderHeight + (this._rows || this.maxRowCount) * this.rowHeight
      }px`;
      if (lastMaxRowCount !== this.maxRowCount) {
        if (lastMaxRowCount < this.maxRowCount) {
          if (this.scrollIndex + this.maxRowCount > this.realMaxRowCount) {
            this._scrollTopChange = true;
          }
        }
      }
      this.height = this.rowHeaderHeight + (this._rows || this.maxRowCount) * this.rowHeight + this.defaultBorderWidth / this.scaleFactor;
      this.scroll.nativeElement.style.height = `${
        this.rowHeaderHeight + (this._rows || this.maxRowCount) * this.rowHeight + this.defaultBorderWidth
      }px`;
      if (this.showScrollbar && this.realMaxRowCount && this._rows < this.realMaxRowCount) {
        this.scrollContent.nativeElement.style.height = `${
          this.rowHeaderHeight + this.realMaxRowCount * this.rowHeight + this.defaultBorderWidth
        }px`;
        if (this.scrollBarShown !== true) {
          this.scrollBarShown = true;
          this.updateSize();
        }
      } else {
        this.scrollContent.nativeElement.style.height = '0px';
        if (this.scrollBarShown !== false) {
          this.scrollBarShown = false;
          this.updateSize();
        }
      }
    }
    this.canvasWrapperElement.nativeElement.style.borderTopWidth = `${this.defaultBorderWidth / this.scaleFactor}px`;
    this.canvasWrapperElement.nativeElement.style.borderLeftWidth = `${this.defaultBorderWidth / this.scaleFactor}px`;

    this.sizeUpdated.emit();
    this.draw(true);
  }

  private updateColumnsWidth() {
    const canvas: HTMLCanvasElement = this.canvasElement.nativeElement;
    let allWidth = canvas.width / this.scaleFactor;
    let fixedWidth = 0;
    let flexibleWidth = 0;
    this.columnsWidth = [];
    for (let i = 0; i < this.columns.length; i++) {
      this.columnsWidth.push(new ColumnWidth());
      this.columnsWidth[i].originalWidth = this.columns[i].width;
      if (typeof this.columns[i].width === 'number') {
        fixedWidth += Number(this.columns[i].width);
        this.columnsWidth[i].width = Number(this.columns[i].width);
      } else {
        if (this.columns[i].width === '*') {
          this.columnsWidth[i].width = 1;
        } else {
          this.columnsWidth[i].width = Number(String(this.columns[i].width).slice(0, -1));
        }
        flexibleWidth += this.columnsWidth[i].width;
      }
    }
    allWidth -= fixedWidth;
    for (let i = 0; i < this.columns.length; i++) {
      if (typeof this.columnsWidth[i].originalWidth !== 'number') {
        this.columnsWidth[i].width = Math.round((this.columnsWidth[i].width / flexibleWidth) * allWidth);
      }
    }
    let sum = 0;
    for (let i = 0; i < this.columnsWidth.length; i++) {
      this.columnsWidth[i].left = sum;
      sum += this.columnsWidth[i].width;
    }
    allWidth += fixedWidth;
    if (sum !== allWidth) {
      for (let i = this.columnsWidth.length - 1; i >= 0; i--) {
        if (typeof this.columnsWidth[i].originalWidth !== 'number') {
          this.columnsWidth[i].width -= sum - allWidth;
          break;
        }
      }
      sum = 0;
      for (let i = 0; i < this.columnsWidth.length; i++) {
        this.columnsWidth[i].left = sum;
        sum += this.columnsWidth[i].width;
      }

      if (sum !== allWidth) {
        console.error('Invalid size');
      }
    }
  }

  private drawHeader(context: CanvasRenderingContext2D) {
    context.save();
    try {
      const width = this.canvasElement.nativeElement.width;
      context.fillStyle = this.defaultHeaderBackgroundColor;
      context.fillRect(0, 0, width, this.rowHeaderHeight);

      context.strokeStyle = this.defaultBorderColor;
      context.lineWidth = this.defaultBorderWidth;
      context.beginPath();
      context.moveTo(0, this.rowHeaderHeight - this.defaultBorderWidth / 2);
      context.lineTo(width, this.rowHeaderHeight - this.defaultBorderWidth / 2);
      for (let i = 0; i < this.columnsWidth.length; i++) {
        const columnsWidth = this.columnsWidth[i];
        context.moveTo(columnsWidth.left + columnsWidth.width - this.defaultBorderWidth / 2, this.defaultBorderWidth / 2);
        context.lineTo(
          columnsWidth.left + columnsWidth.width - this.defaultBorderWidth / 2,
          this.rowHeaderHeight + this.defaultBorderWidth / 2
        );
      }
      context.stroke();

      context.textBaseline = 'middle';
      context.textAlign = 'center';
      context.fillStyle = this.defaultColor;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].bold) {
          context.font = this.defaultHeaderFont.setFontWeight('700').toString();
        } else {
          context.font = this.defaultHeaderFont.toString();
        }
        context.fillText(this.columns[i].heading, this.columnsWidth[i].left + this.columnsWidth[i].width / 2, this.rowHeaderHeight / 2);
      }
    } finally {
      context.restore();
    }
  }

  private checkCache(): boolean {
    if (!this.dataCache) {
      return true;
    }
    if (this.dataCache.length !== this._rows) {
      return true;
    }
    if (this._rows > 0 && this.dataCache[0].length !== this.columns.length) {
      return true;
    }
    return false;
  }

  private clearCache() {
    if (!(this.dataCache && this.dataCache.length === this._rows)) {
      this.dataCache = new Array<Array<string>>(this._rows);
    }
    for (let i = 0; i < this.dataCache.length; i++) {
      if (this.dataCache[i] && this.dataCache[i].length === this.columns.length) {
        for (let j = 0; j < this.columns.length; j++) {
          this.dataCache[i][j] = undefined;
        }
      } else {
        this.dataCache[i] = new Array<string>(this.columns.length);
      }
    }
  }

  /*
   * グリッドの描画を行う
   * @param {boolean} force 全項目の再描画を行うかどうか。falseの場合は更新されていないセルを描画しない。
   */
  public draw(force: boolean = true) {
    if (!this.initialized) {
      return;
    }
    if (this.columnsWidth.length !== this.columns.length) {
      return;
    }
    if (this.drawing) {
      const c = new CancelEventArgs();
      c.cancel = false;
      this.drawing.emit(c);
      if (c.cancel) {
        if (force) {
          this.forceQueue = force;
        }
        return;
      }
    }
    if (this.forceQueue) {
      this.forceQueue = false;
      force = true;
    }
    if (force || this.checkCache()) {
      this.clearCache();
      force = true;
    }
    const canvas: HTMLCanvasElement = this.canvasElement.nativeElement;
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const width = canvas.width / this.scaleFactor;
    const height = canvas.height / this.scaleFactor;
    context.save();
    try {
      context.scale(this.scaleFactor, this.scaleFactor);
      // ヘッダとその罫線の描画
      if (force) {
        // All Clear
        context.clearRect(0, 0, width, height);
        // Header
        this.drawHeader(context);
        // Default Background
        if (this.defaultBackgroundColor) {
          context.fillStyle = this.defaultBackgroundColor;
          context.fillRect(0, this.rowHeaderHeight, width, height - this.rowHeaderHeight);
        }
        // Default Cell Border
        context.strokeStyle = this.defaultBorderColor;
        context.lineWidth = this.defaultBorderWidth;
        context.beginPath();
        for (let row = 0; row < this._rows; row++) {
          const top = this.rowHeaderHeight + (row + 1) * this.rowHeight;
          context.moveTo(0, top - this.defaultBorderWidth / 2);
          context.lineTo(width, top - this.defaultBorderWidth / 2);
        }
        for (let col = 0; col < this.columnsWidth.length; col++) {
          const w = this.columnsWidth[col];
          context.moveTo(w.left + w.width - this.defaultBorderWidth / 2, this.rowHeaderHeight - this.defaultBorderWidth / 2);
          context.lineTo(
            w.left + w.width - this.defaultBorderWidth / 2,
            this.rowHeaderHeight + this._rows * this.rowHeight - this.defaultBorderWidth / 2
          );
        }
        context.stroke();
      }

      // 全行・全項目の更新内容を取得
      const renderer = this.getCellRender(force);

      // 更新された行がいるかどうかの判定
      for (let row = 0; row < this._rows; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          if (renderer[row][col].cacheKey !== undefined) {
            if (this.dataCache[row][col] === renderer[row][col].cacheKey) {
              renderer[row][col].skip = true;
            }
          }
        }
      }

      const backgroundColors: { [key: string]: Array<[number, number]> } = {};

      // 背景描画
      for (let row = 0; row < this._rows; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          if (renderer[row][col].skip) {
            continue;
          }
          if (renderer[row][col].customBackgroundRenderer) {
            const top = row * this.rowHeight + this.rowHeaderHeight;
            const left = this.columnsWidth[col].left;
            const dContext: DrawContext = {
              context: context,
              column: col,
              row: row,
              width: this.columnsWidth[col].width,
              height: this.rowHeight,
              left: left,
              top: top,
            };
            renderer[row][col].customBackgroundRenderer(dContext);
          } else if (renderer[row][col].backgroundColor) {
            if (!(renderer[row][col].backgroundColor in backgroundColors)) {
              backgroundColors[renderer[row][col].backgroundColor] = [];
            }
            backgroundColors[renderer[row][col].backgroundColor].push([row, col]);
          } else {
            const top = row * this.rowHeight + this.rowHeaderHeight;
            const left = this.columnsWidth[col].left;
            const bWidth = this.defaultBorderWidth;
            context.clearRect(left, top, this.columnsWidth[col].width - bWidth, this.rowHeight - bWidth);
          }
        }
      }
      for (const backgroundColor of Object.keys(backgroundColors)) {
        context.fillStyle = backgroundColor;
        for (const [row, col] of backgroundColors[backgroundColor]) {
          const top = row * this.rowHeight + this.rowHeaderHeight;
          const left = this.columnsWidth[col].left;
          const bWidth = this.defaultBorderWidth;
          context.fillRect(left, top, this.columnsWidth[col].width - bWidth, this.rowHeight - bWidth);
        }
      }
      // 罫線描画
      for (let row = 0; row < this._rows; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          if (renderer[row][col].skip) {
            continue;
          }
          if (renderer[row][col].customBorderRenderer) {
            // TODO: Not Implemented
            throw new Error('Not Implemented');
          } else {
            const top = row * this.rowHeight + this.rowHeaderHeight;
            const left = this.columnsWidth[col].left;
            const border = renderer[row][col].borderBottom;
            const lineWidth = (border && border.width) || this.defaultBorderWidth;
            context.strokeStyle = (border && border.color) || this.defaultBorderColor;
            context.lineWidth = lineWidth;
            context.beginPath();
            context.moveTo(left, top + this.rowHeight - lineWidth / 2);
            context.lineTo(left + this.columnsWidth[col].width, top + this.rowHeight - lineWidth / 2);
            context.stroke();
          }
        }
      }
      // テキスト描画
      context.save();
      context.font = this.defaultFont.toString();
      try {
        for (let row = 0; row < this._rows; row++) {
          for (let col = 0; col < this.columns.length; col++) {
            if (renderer[row][col].skip) {
              continue;
            }
            if (!renderer[row][col].customTextRenderer && renderer[row][col].text !== '') {
              const render = renderer[row][col];
              context.save();
              try {
                const top = row * this.rowHeight + this.rowHeaderHeight;
                const left = this.columnsWidth[col].left;
                const rightBorderWidth = (render.borderRight && render.borderRight.width) || this.defaultBorderWidth;
                const padding = render.padding || this.defaultPadding;
                context.beginPath();
                context.rect(
                  left,
                  top,
                  this.columnsWidth[col].width - rightBorderWidth,
                  this.rowHeight - ((render.borderBottom && render.borderBottom.width) || this.defaultBorderWidth)
                );
                context.clip();
                context.textBaseline = 'middle';
                context.textAlign = render.textAlign;
                context.fillStyle = render.color || this.defaultColor;

                if (render.fontWeight) {
                  context.font = this.defaultFont.setFontWeight(render.fontWeight.toString()).toString();
                }
                if (render.textAlign === 'center') {
                  context.fillText(
                    render.text,
                    left + (this.columnsWidth[col].width - rightBorderWidth) / 2,
                    top + (this.rowHeight - this.defaultBorderWidth) / 2
                  );
                } else if (render.textAlign === 'right') {
                  context.fillText(
                    render.text,
                    left + this.columnsWidth[col].width - rightBorderWidth - padding,
                    top + (this.rowHeight - this.defaultBorderWidth) / 2
                  );
                } else if (render.textAlign === 'left') {
                  context.fillText(render.text, left + padding, top + (this.rowHeight - this.defaultBorderWidth) / 2);
                }
              } finally {
                context.restore();
              }
            }
          }
        }
      } finally {
        context.restore();
      }
      for (let row = 0; row < this._rows; row++) {
        for (let col = 0; col < this.columns.length; col++) {
          if (renderer[row][col].skip) {
            continue;
          }
          if (renderer[row][col].customTextRenderer) {
            const top = row * this.rowHeight + this.rowHeaderHeight;
            const left = this.columnsWidth[col].left;
            const dContext: DrawContext = {
              context: context,
              column: col,
              row: row,
              width:
                this.columnsWidth[col].width -
                ((renderer[row][col].borderRight && renderer[row][col].borderRight.width) || this.defaultBorderWidth),
              height:
                this.rowHeight - ((renderer[row][col].borderBottom && renderer[row][col].borderBottom.width) || this.defaultBorderWidth),
              left: left,
              top: top,
            };

            renderer[row][col].customTextRenderer(dContext);
          }
        }
      }
      if (false) {
        console.assert(this.dataCache.length === this._rows);
        console.assert(renderer.length === this._rows);
      }
      for (let col = 0; col < this.columns.length; col++) {
        for (let row = 0; row < this._rows; row++) {
          if (false) {
            console.assert(this.dataCache[row].length === this.columns.length);
            console.assert(renderer[row].length === this.columns.length);
          }
          this.dataCache[row][col] = renderer[row][col].cacheKey;
        }
      }
    } finally {
      context.restore();
    }
  }

  private getCellRender(force: boolean): Array<Array<CellRenderer>> {
    const ret = [];
    for (let i = 0; i < this._rows; i++) {
      ret.push([]);
      for (let j = 0; j < this.columns.length; j++) {
        const c = new CellRenderer();
        this.onCellRender(i, j, c, force);
        ret[ret.length - 1].push(c);
      }
    }
    return ret;
  }

  private onCellRender(row: number, col: number, render: CellRenderer, force: boolean) {
    this.cellRender.emit({ row, col, render, force });
  }

  private onCellMouseDown(event: CellMouseEvent) {
    this.cellMouseDown.emit(event);
  }

  private onCellMouseLeave(event: CellMouseEvent) {
    this.cellMouseLeave.emit(event);
  }

  private onCellMouseEnter(event: CellMouseEvent) {
    this.cellMouseEnter.emit(event);
  }

  private onCellMouseUp(event: CellMouseEvent) {
    this.cellMouseUp.emit(event);
  }

  public onMouseDown(sender: HTMLElement, event: MouseEvent) {
    const [r, c] = this.getRowColumn(event.offsetY - sender.scrollTop, event.offsetX);
    if (r !== null && c !== null) {
      const e = <CellMouseEvent>event;
      e.row = r;
      e.col = c;
      this.onCellMouseDown(e);
    }
    this.focus();
  }

  public onMouseUp(sender: HTMLElement, event: MouseEvent) {
    const [r, c] = this.getRowColumn(event.offsetY - sender.scrollTop, event.offsetX);
    if (r !== null && c !== null) {
      const e = <CellMouseEvent>event;
      e.row = r;
      e.col = c;
      this.onCellMouseUp(e);
    }
  }

  public onMouseEnter(sender: HTMLElement, event: MouseEvent) {
    const [r, c] = this.getRowColumn(event.offsetY - sender.scrollTop, event.offsetX);
    if (r !== null && c !== null) {
      this.leaveCurrentCell(event);
      const e = <CellMouseEvent>event;
      e.row = r;
      e.col = c;
      this.currentMouseCell = [r, c];
      this.onCellMouseEnter(e);
    } else {
      this.leaveCurrentCell(event);
    }
  }

  private leaveCurrentCell(ev: MouseEvent) {
    if (this.currentMouseCell === null) {
      return;
    }
    const e = <CellMouseEvent>ev;
    e.row = this.currentMouseCell[0];
    e.col = this.currentMouseCell[1];
    if (e.row < this.rows && e.col < this.columns.length) {
      this.onCellMouseLeave(e);
    }
    this.currentMouseCell = null;
  }

  public onMouseLeave(sender: HTMLElement, event: MouseEvent) {
    this.leaveCurrentCell(event);
  }

  public onMouseMove(sender: HTMLElement, event: MouseEvent) {
    const [r, c] = this.getRowColumn(event.offsetY - sender.scrollTop, event.offsetX);
    if (this.currentMouseCell === null || this.currentMouseCell[0] !== r || this.currentMouseCell[1] !== c) {
      this.leaveCurrentCell(event);
      if (r !== null && c !== null) {
        const e = <CellMouseEvent>event;
        e.row = r;
        e.col = c;
        this.currentMouseCell = [r, c];
        this.onCellMouseEnter(e);
      }
    }
  }

  public onCellDblClick(e: CellMouseEvent) {
    this.cellDblClick.emit(e);
  }

  public onHeaderCellDblClick(e: CellMouseEvent) {
    this.headerCellDblClick.emit(e);
  }

  public onDblClick(sender: HTMLElement, event: MouseEvent) {
    const [r, c] = this.getRowColumn(event.offsetY - sender.scrollTop, event.offsetX);
    if (r !== null && c !== null) {
      const e = <CellMouseEvent>event;
      e.row = r;
      e.col = c;
      this.onCellDblClick(e);
    } else if (r === -1 && event.offsetY - sender.scrollTop >= 0) {
      let col = 0;
      for (const cw of this.columnsWidth) {
        if (cw.left <= event.offsetX && event.offsetX <= cw.left + cw.width - 1) {
          const e = <CellMouseEvent>event;
          e.row = -1;
          e.col = col;
          this.onHeaderCellDblClick(e);
          return;
        }
        col++;
      }
    }
  }

  public onContextMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  protected getRowColumn(y: number, x: number): [number, number] {
    if (y < this.rowHeaderHeight) {
      return [-1, null]; // Header
    }
    const r = Math.trunc((y - this.rowHeaderHeight) / this.rowHeight);
    if (r >= this._rows) {
      return [null, null];
    }
    let c = 0;
    for (const cw of this.columnsWidth) {
      if (cw.left <= x && x <= cw.left + cw.width - 1) {
        return [r, c];
      }
      c++;
    }
    return [null, null];
  }

  /**
   * 画像を取得する。
   */
  public getImageCanvas(config: GetImageConfig): HTMLCanvasElement {
    const host: HTMLElement = this.hostElement.nativeElement;
    const canvas: HTMLCanvasElement = this.canvasElement.nativeElement;
    const width = host.offsetWidth - 1;
    const height = this.height;
    const result = document.createElement('canvas');

    const paddingCommon = Math.round(this.rowHeight / 2);
    const paddingLeft = paddingCommon;
    const paddingRight = paddingCommon;
    const paddingTop = paddingCommon + this.rowHeight;
    const paddingBottom = paddingCommon + this.rowHeight;

    if (canvas.height <= 0 || canvas.width <= 0) {
      return null;
    }

    result.width = (paddingLeft + width + this.defaultBorderWidth + paddingRight) * this.scaleFactor;
    result.height = (paddingTop + height + this.defaultBorderWidth + paddingBottom) * this.scaleFactor;
    const c = result.getContext('2d');
    c.fillStyle = this.defaultBackgroundColor;
    c.scale(this.scaleFactor, this.scaleFactor);
    c.fillRect(0, 0, result.width, result.height);
    c.drawImage(
      canvas,
      0,
      0,
      Math.min(canvas.width, width * this.scaleFactor),
      Math.min(canvas.height, height * this.scaleFactor),
      paddingLeft + this.defaultBorderWidth,
      paddingTop + this.defaultBorderWidth,
      width,
      height
    );
    c.strokeStyle = this.defaultBorderColor;
    c.lineWidth = this.defaultBorderWidth;
    c.beginPath();
    c.moveTo(paddingLeft + this.defaultBorderWidth / 2, paddingTop + this.defaultBorderWidth / 2);
    c.lineTo(paddingLeft + width + this.defaultBorderWidth / 2, paddingTop + this.defaultBorderWidth / 2);
    c.moveTo(paddingLeft + this.defaultBorderWidth / 2, paddingTop + this.defaultBorderWidth / 2);
    c.lineTo(paddingLeft + this.defaultBorderWidth / 2, paddingTop + height + this.defaultBorderWidth / 2);
    c.stroke();

    c.scale(1, 1);

    // 文字列の書き込み
    if (config && config.bottomLeft) {
      c.textBaseline = 'middle';
      c.textAlign = 'left';
      c.fillStyle = this.defaultColor;
      c.font = this.defaultFont.toString();
      c.fillText(config.bottomLeft, paddingLeft, paddingTop + this.defaultBorderWidth + height + this.rowHeight / 2);
    }
    if (config && config.bottomRight) {
      c.textBaseline = 'middle';
      c.textAlign = 'right';
      c.fillStyle = this.defaultColor;
      c.font = this.defaultFont.toString();
      c.fillText(config.bottomRight, paddingLeft + width, paddingTop + this.defaultBorderWidth + height + this.rowHeight / 2);
    }
    if (config && config.headingLeft) {
      c.textBaseline = 'middle';
      c.textAlign = 'left';
      c.fillStyle = this.defaultColor;
      c.font = this.defaultFont.toString();
      c.fillText(config.headingLeft, paddingLeft, paddingTop - this.rowHeight / 2);
    }
    if (config && config.headingCenter) {
      c.textBaseline = 'middle';
      c.textAlign = 'center';
      c.fillStyle = config.headingCenterColor || this.defaultColor;
      c.font = this.defaultFont.toString();
      c.fillText(config.headingCenter, paddingLeft + width * 0.45, paddingTop - this.rowHeight / 2);
    }
    if (config && config.headingRight) {
      c.textBaseline = 'middle';
      c.textAlign = 'right';
      c.fillStyle = this.defaultColor;
      c.font = this.defaultFont.toString();
      c.fillText(config.headingRight, paddingLeft + width, paddingTop - this.rowHeight / 2);
    }
    return result;
  }

  public getImage(config: GetImageConfig): string {
    const canvas: HTMLCanvasElement = this.getImageCanvas(config);
    if (canvas) {
      return canvas.toDataURL();
    }
    return '';
  }

  public onScroll(sender: HTMLElement, event: Event) {
    const ev = new ScrollChangedEvent();
    ev.row = Math.round(sender.scrollTop / this.rowHeight);
    ev.includeScrollTopChange = this._scrollTopChange;
    ev.eventSource = this._scrollTopChangeObject;
    this._scrollTopChangeObject = null;
    this._scrollTopChange = false;
    this.scrollChange.emit(ev);
  }

  focus() {
    (this.focusElement.nativeElement as HTMLElement).focus({
      preventScroll: true,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('fixedWidth' in changes) {
      if (this.fixedWidth) {
        this.updateSize(false);
      }
    }
  }

  setScrollIndex(value: number, eventSource: any) {
    if (this.scrollIndex !== value) {
      if (this.showScrollbar) {
        this._scrollTopChangeObject = eventSource;
        this._scrollTopChange = true;
        this.scroll.nativeElement.scrollTop = this.rowHeight * value;
      }
    }
  }

  onResize() {
    this.updateSize();

    // 以下のコードは通常のケースでは無意味である。DOMを移動させる時にスクロールポジションが0になるバグがChromeにあるため、それへの対応。
    // (DOMの移動はGoldenLayoutで発生するので、Resizeも発生する想定のため)
    // See: https://js-zyiwts.stackblitz.io/
    if (this.scroll.nativeElement.scrollTop === 0 && this.scrollIndex !== 0) {
      this.scroll.nativeElement.scrollTop = this.rowHeight * this.scrollIndex;
    }
  }
}

class GetImageConfig {
  headingLeft?: string;
  headingRight?: string;
  bottomRight?: string;
  bottomLeft?: string;
  headingCenter?: string;
  headingCenterColor?: string;
}
