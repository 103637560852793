import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ResizeService } from '@argentumcode/brisk-common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fita4-ita-order-tutorial',
  templateUrl: './ita-order-tutorial.component.html',
  styleUrls: ['./ita-order-tutorial.component.scss'],
})
export class ItaOrderTutorialComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('iframe')
  private iframe: ElementRef<HTMLIFrameElement>;

  public largeEnough = true;
  private subscription: Subscription;

  constructor(private resize: ResizeService) {}

  ngAfterViewInit() {
    this.subscription = this.resize.observeElement(this.iframe.nativeElement).subscribe(() => {
      this.onResize();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onResize() {
    const iframe = this.iframe?.nativeElement;
    if (!iframe) {
      return;
    }
    // iframe内のチュートリアルが要求する900x620以上を満たすか判定
    this.largeEnough = iframe.offsetWidth >= 900 && iframe.offsetHeight >= 620;
  }
}
