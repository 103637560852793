import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from '../../order/types/interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fita4-reconnecting-message',
  templateUrl: './reconnecting-message.component.html',
  styleUrls: ['./reconnecting-message.component.scss'],
})
export class ReconnectingMessageComponent implements OnDestroy {
  private orderReconnecting = false;
  private _subscription: Subscription;
  get reconnecting() {
    return this.cocomero.reconnecting || this.orderReconnecting;
  }

  constructor(
    private cocomero: CocomeroService,
    @Inject(ORDER_OPERATOR_TOKEN) orderOperator: OrderOperator,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._subscription = orderOperator.reconnecting$.subscribe((v) => {
      this.orderReconnecting = v;
      console.log('order reconneting', v);
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
