<app-ita-portfolio
  #itaPortfolio
  *ngIf="collection"
  [visible]="visible"
  [dataSource]="collection"
  [itaRows]="state.rows"
  [columns]="itaColumnCount"
  [peg]="state.pegState"
  (pegChange)="updatePegState($event)"
  [normalized]="false"
  [paging]="false"
  (stockSelected)="onStockSelected($event)"
  (cellDblClicked)="onCellDblClicked()"
></app-ita-portfolio>
