<div class="ohlc-5min-panel--container">
  <div class="ohlc-5min-panel--header">
    <brisk-checkbox
      *ngIf="showIndustryPriceCheckbox"
      [ngModel]="state.showIndustryLine"
      (ngModelChange)="updateShowIndustryLine($event)"
      [disabled]="!state.normalized"
      >業種「{{ industryName }}」騰落率平均を表示</brisk-checkbox
    >
  </div>
  <div class="ohlc-5min-panel--chart-wrapper">
    <brisk-chart
      id="chart"
      #chart
      [dataSource]="dataSource"
      type="5min"
      [lineChartType]="'Industry'"
      [showLineChart]="state.showIndustryLine"
      [normalized]="state.normalized"
      (normalizedChange)="updateNormalized($event)"
    ></brisk-chart>
  </div>
</div>
