import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { CanvasGridComponent } from '../canvas-grid/canvas-grid.component';

@Component({
  selector: 'brisk-canvas-grid-style',
  template: '',
  styles: [':host {visibility: hidden; position: absolute; z-index: -1;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasGridStyleComponent implements OnInit {
  @Input()
  public type: 'Header' | 'Cell';

  @Input()
  public height: number | 'Auto' = 'Auto';

  constructor(private hostElement: ElementRef, private canvasGrid: CanvasGridComponent) {}

  ngOnInit() {
    if (this.type === 'Header') {
      this.canvasGrid.rowHeaderElement = this.hostElement;
      this.canvasGrid.rowHeaderHeightOriginal = this.height === 'Auto' ? undefined : this.height;
    } else if (this.type === 'Cell') {
      this.canvasGrid.rowElement = this.hostElement;
      this.canvasGrid.rowHeightOriginal = this.height === 'Auto' ? undefined : this.height;
    }
  }
}
