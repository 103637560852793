<ng-container *ngIf="singleBackMode">
  <button *ngIf="!view || stockWrapper.live" class="c-btn c-btn--default btn-controller" (click)="view && (stockWrapper.live = false)">
    履歴
  </button>
  <ng-container *ngIf="view && !stockWrapper.live">
    <span class="p-inline-element">{{ view.timestamp | timestampFormat: { milliseconds: true } }} / {{ view.frame }}</span>

    <button
      class="c-btn c-btn--default btn-controller btn-rew"
      [disabled]="!view.hasPreviousFrame"
      (click)="stockWrapper.goUpdateNumber(view.frame - 1)"
    >
      戻る
    </button>
    <button
      class="c-btn c-btn--default btn-controller btn-ff"
      [disabled]="!view.hasNextFrame"
      (click)="stockWrapper.goUpdateNumber(view.frame + 1)"
    >
      進む
    </button>
    <button class="c-btn c-btn--red btn-controller btn-live brisk-lite-live" (click)="stockWrapper.live = true">LIVE</button>
  </ng-container>
</ng-container>
<ng-container *ngIf="view && !singleBackMode">
  <brisk-checkbox class="p-inline-element p-ita-center-checkbox" [checked]="pegEnabled" (checkedChange)="onPegClicked()">
    自動板中心値
  </brisk-checkbox>
  <span class="p-inline-element">{{ view.timestamp | timestampFormat: { microseconds: timestampMilliseconds } }} / {{ view.frame }}</span>

  <ng-container *ngIf="showHistoryButtons">
    <button *ngIf="stockWrapper.live" class="c-btn c-btn--default btn-controller" (click)="stockWrapper.live = false">履歴</button>
    <button
      *ngIf="!stockWrapper.live"
      class="c-btn c-btn--default btn-controller btn-rew"
      [disabled]="!view.hasPreviousFrame"
      (click)="stockWrapper.goUpdateNumber(view.frame - 1)"
    >
      戻る
    </button>
    <button
      *ngIf="!stockWrapper.live"
      class="c-btn c-btn--default btn-controller btn-ff"
      [disabled]="!view.hasNextFrame"
      (click)="stockWrapper.goUpdateNumber(view.frame + 1)"
    >
      進む
    </button>
    <button *ngIf="!stockWrapper.live" class="c-btn c-btn--red btn-controller btn-live" (click)="stockWrapper.live = true">LIVE</button>
  </ng-container>
  <button class="c-btn c-btn--default btn-controller btn-save" (click)="saveClick.emit($event)" *ngIf="showCamera">
    <fa-icon [icon]="['fas', 'camera']"></fa-icon>
  </button>
</ng-container>

<ng-container *ngIf="!controllerOnly">
  <ng-template briskAssist="timestamp-header-frame-back" #assist="briskAssist">
    <button
      type="button"
      class="c-assist-popover-button p-assist-timestamp"
      placement="bottom"
      popoverClass="c-assist-popover c-assist-popover--default"
      [container]="'body'"
      [ngbPopover]="popContent"
    ></button>

    <ng-template #popContent>
      <div class="c-assist-popover__description">
        <p class="c-assist-popover__text">板状況の再現</p>
      </div>
      <div class="c-assist-popover__buttons">
        <a class="c-assist-popover__button" (click)="assist.openAssist($event)" href="#">
          <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon> もっと詳しく
        </a>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
