<div
  id="header"
  class="btn-group btn-group-justified btn-group-toggle"
  ngbRadioGroup
  [(ngModel)]="type"
  (ngModelChange)="typeChange.emit($event)"
>
  <label ngbButtonLabel class="btn-sm shadow-none c-btn c-btn--default"> <input ngbButton type="radio" value="5min" />5分足 </label>
  <label ngbButtonLabel class="btn-sm shadow-none c-btn c-btn--default"> <input ngbButton type="radio" value="1day" />日足 </label>
  <label ngbButtonLabel class="btn-sm shadow-none c-btn c-btn--default"> <input ngbButton type="radio" value="1week" />週足 </label>
  <label ngbButtonLabel class="btn-sm shadow-none c-btn c-btn--default"> <input ngbButton type="radio" value="1month" />月足 </label>
</div>

<div *ngIf="showNormalizedPriceCheckbox" id="header-pricing">
  <brisk-checkbox class="header-pricing--checkbox" [(ngModel)]="priceMode">価格表示</brisk-checkbox>
  <brisk-checkbox
    *ngIf="showNk225Checkbox"
    class="header-pricing--checkbox"
    [(ngModel)]="showNk225"
    [disabled]="!normalized"
    [ngbTooltip]="showNk225Tooltip"
    >日経225投信(1321)表示</brisk-checkbox
  >
</div>
<brisk-chart
  #chart
  id="chart"
  [dataSource]="dataSource"
  [type]="type"
  [nonProportional]="nonProportional"
  [(normalized)]="normalized"
  [showLineChart]="showNk225"
></brisk-chart>

<ng-template #showNk225Tooltip>騰落率表示(価格表示チェックオフ)時に<br />日経225投信(1321)騰落率を重ねて表示</ng-template>

<ng-container *ngIf="shown">
  <ng-template briskAssist="selectable-chart-wheel" #assistBtn="briskAssist">
    <button
      type="button"
      class="c-assist-popover-button p-assist-wheel-click"
      placement="bottom"
      container="body"
      [ngbPopover]="popContent"
    ></button>

    <ng-template #popContent>
      <div class="c-assist-popover__container">
        <div>
          <div class="c-assist-popover__description">
            <p class="c-assist-popover__text">「価格表示」チェックボックスまたはマウスホイールクリックで縦軸切替え<br />(値段⇔騰落率)</p>
          </div>
          <div class="c-assist-popover__buttons" *ngIf="assistBtn.openable">
            <a class="c-assist-popover__button" (click)="assistBtn.openAssist($event)" href="#">
              <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
              もっと詳しく
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
