import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlOnHide, GlOnResize, GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { ItaCaptureComponent, ItaComponent as BriskItaComponent, PegState, ResizeService } from '@argentumcode/brisk-common';
import { DOCUMENT } from '@angular/common';
import { StockDetailService } from '../../stock-detail.service';
import { Container } from 'golden-layout';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from '../../order/types/interfaces';
import { ItaPanelHeaderComponent } from '../../order/noop-order-ui/ita-panel-header/ita-panel-header.component';
import { PersistentStateService } from '../../fita4-core/persistent-state.service';

interface ItaState {
  peg?: PegState;
  normalized?: boolean;
  visible?: boolean;
}

@Component({
  selector: 'app-fita4-ita-panel',
  templateUrl: './ita-panel.component.html',
  styleUrls: ['./ita-panel.component.scss'],
})
export class ItaPanelComponent implements OnInit, GlOnResize, OnDestroy, GlOnShow, GlOnHide {
  // 横幅から決まる状態
  showTotal = false;
  showOrder = false;
  showClose = false;
  showMyOrder = false;
  showVolumeChartOnly = false;

  showTimestampMilliseconds = true;
  showHistoryButtons = true;
  showCamera = false;

  // ユーザー指定の状態
  normalize = false;
  peg = PegState.Enabled;

  get orderEnabled(): boolean {
    return this.orderOperator.config.orderEnabled;
  }

  state: ItaState;

  isOnPrimaryPanel = false;
  stockDetailPanelVisible = false;
  tabActive = false;

  @ViewChild('ita', { static: true })
  public ita: BriskItaComponent;
  @ViewChild('itaCapture', { static: true })
  public itaCapture: ItaCaptureComponent;
  @ViewChild('itaPanelHeader', { static: true })
  private itaPanelHeader: ItaPanelHeaderComponent;
  @ViewChild('headerElement', { static: true })
  public headerElement: ElementRef<HTMLDivElement>;

  private componentSubscription = new Subscription();

  get time() {
    return this.stockDetailService.time;
  }

  get stockOperator() {
    return this.stockDetailService.stockOperator;
  }

  get shown() {
    return !this.container.isHidden && this.stockDetailPanelVisible;
  }

  id: string;

  constructor(
    private stockDetailService: StockDetailService,
    private resize: ResizeService,
    private element: ElementRef,
    @Inject(DOCUMENT) document: any,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(GoldenLayoutContainer) private container: Container,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    private persistentStateService: PersistentStateService
  ) {
    this.componentSubscription.add(
      this.resize.resize.subscribe(() => {
        this.onResize();
      })
    );
    this.componentSubscription.add(
      this.stockDetailService.isOnPrimaryPanel$.subscribe((v) => {
        this.isOnPrimaryPanel = v;
      })
    );
  }

  ngOnInit(): void {
    this.onResize();
    this.state = this.container.getState() || {};
    const newState = { ...this.state };
    let updateState = false;
    if (!this.state.normalized) {
      newState.normalized = false;
      updateState = true;
    }
    if (this.state.peg === undefined || this.state.peg === PegState.TemporaryDisabled) {
      newState.peg = PegState.Enabled;
      updateState = true;
    }
    if (updateState) {
      this.updateState(newState);
    }
    this.componentSubscription.add(
      this.resize.observeElement(this.headerElement.nativeElement).subscribe(() => {
        this.resize.dispatchResize();
      })
    );
    this.isOnPrimaryPanel = this.stockDetailService.isOnPrimaryPanel;
    this.stockDetailPanelVisible = this.stockDetailService.visible;
    this.componentSubscription.add(
      this.stockDetailService.visible$.subscribe((v) => {
        if (this.stockDetailPanelVisible !== v) {
          this.stockDetailPanelVisible = v;
          this.changeDetectorRef.detectChanges();
        }
      })
    );
    this.tabActive = !this.container.isHidden;
  }

  updatePegState(peg: PegState) {
    this.updateState({
      ...this.state,
      peg,
    });
  }

  updateNormalized(normalized: boolean) {
    this.updateState({
      ...this.state,
      normalized,
    });
  }

  updateState(state: ItaState) {
    this.state = state;
    this.container.setState(state);
  }

  glOnResize(): void {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  glOnShow() {
    this.resize.dispatchResize();
    this.tabActive = true;
  }

  glOnHide() {
    this.tabActive = false;
  }

  ngOnDestroy(): void {
    this.componentSubscription.unsubscribe();
  }

  onResize() {
    if (this.container.isHidden || !this.stockDetailService.visible) {
      return;
    }
    const width = this.element.nativeElement.offsetWidth;
    const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;

    this.showTotal = false;
    this.showOrder = false;
    this.showClose = false;
    this.showVolumeChartOnly = true;
    this.showTimestampMilliseconds = false;
    this.showHistoryButtons = false;
    this.showCamera = false;
    this.showMyOrder = false;

    if (normalizedWidth >= 300) {
      this.showTimestampMilliseconds = true;
    }
    if (normalizedWidth >= 400) {
      this.showCamera = true;
    }
    if (normalizedWidth >= 500) {
      this.showHistoryButtons = true;
    }
    if (normalizedWidth >= 768) {
      this.showMyOrder = true;
    }

    if (normalizedWidth >= 996) {
      this.showClose = true;
      this.showVolumeChartOnly = false;
    }
    if (normalizedWidth >= 1200) {
      this.showTotal = true;
    }
    if (normalizedWidth >= 1300) {
      this.showOrder = true;
    }
    this.itaPanelHeader.onUpdateItaPanelSize(normalizedWidth);
    this.changeDetectorRef.markForCheck();
  }

  saveImage(event: MouseEvent) {
    if ((event.ctrlKey || event.metaKey) && this.itaCapture) {
      this.itaCapture.capture();
    } else if (this.ita) {
      this.ita.saveImage('BRiSK / ArGentumCode K.K.');
    }
  }

  dblClickTutorialComplete() {
    this.persistentStateService.updateFirstTutorialScrollTutorials(true);
  }
}
