interface FontData {
  fontStyle: string;
  fontWeight: string;
  fontStretch: string;
  fontSize: string;
  lineHeight: string;
  fontFamily: string;
  fontVariant: string;
}

export class Font {
  public readonly fontStyle: string;
  public readonly fontWeight: string;
  public readonly fontStretch: string;
  public readonly fontSize: string;
  public readonly lineHeight: string;
  public readonly fontFamily: string;
  public readonly fontVariant: string;

  constructor(style: FontData) {
    this.fontStyle = style.fontStyle;
    this.fontWeight = style.fontWeight;
    this.fontStretch = style.fontStretch === '100%' ? 'normal' : style.fontStretch;
    this.fontSize = style.fontSize;
    this.lineHeight = style.lineHeight;
    this.fontFamily = style.fontFamily;
    this.fontVariant = style.fontVariant;
  }

  setFontWeight(s: string): Font {
    return new Font({
      fontStyle: this.fontStyle,
      fontWeight: s,
      fontStretch: this.fontStretch,
      fontSize: this.fontSize,
      lineHeight: this.lineHeight,
      fontFamily: this.fontFamily,
      fontVariant: this.fontVariant,
    });
  }

  toString() {
    return `${this.fontStyle} ${this.fontWeight} ${this.fontStretch} ` + `${this.fontSize} / ${this.lineHeight} ${this.fontFamily}`;
  }
}
