<app-portfolio
  *ngIf="stockCollectionView"
  #stockPortfolio
  [dataSource]="stockCollectionView"
  [allowDelete]="editable"
  [allowSorting]="allowSorting"
  [allowFilter]="allowFilter"
  [showPrefix]="true"
  nameHeader="銘柄名"
  [showIssueCode]="true"
  [showSpecialQuote]="false"
  [showIndex]="editable"
  [showOpenPriceChange]="true"
  [visibleOpenPriceChange]="true"
  [showVolume]="true"
  [showIndustry]="true"
  [editable]="editable"
  [showPrice]="true"
  [showMarketCapitalization]="true"
  [showPriceBasePriceChanges]="true"
  [formerPriceChange]="true"
  [formerVwap]="true"
  [showLowPrice]="true"
  [showHighPrice]="true"
  (click)="$event.stopPropagation()"
  (issueCodeUpdated)="onIssueCodeUpdated($event)"
  (invalidInput)="onInvalidInput($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (rowDeleting)="onRowDeleting($event)"
  (gridPaste)="paste($event)"
  (draggedRow)="onDraggedRow($event)"
  (draggingRow)="onDraggingRow($event)"
  (cellDblClick)="onCellDblClick()"
  [applyMinWidth]="true"
  [columnsState]="columnsState"
  appFita4GridColumnsEditor
  #columnEditor="appFita4GridColumnsEditor"
  [attachContextMenu]="false"
  (headerContextMenu)="columnEditor.showContextMenu($event.event, stockPortfolio.grid)"
  (vgGridColumnsStateChange)="updateColumnsState($event)"
></app-portfolio>
<!--

  (cellDblClick)="onDblClicked()"
  (draggedRow)="onDraggedRow($event)"
  (draggingRow)="onDraggingRow($event)"

-->
