import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { StockListItem } from 'fita3/src/app/portfolio/summary-list.service';
import uuidv4 from 'uuid/v4';
import { NewPortfolioEntry, Portfolio, StockPortfolio } from 'fita3/src/app/portfolio/portfolio';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { Summary, SummaryType } from '@argentumcode/brisk-common';
import { SummaryPortfolio } from 'fita3/src/app/portfolio/portfolio/portfolio.component';
import { filter, map, take } from 'rxjs/operators';
import { SmartListSummary } from 'fita3/src/app/core/summary.service';
@Injectable()
export class SummaryListsService {
  private active = '';
  private changeActive = new BehaviorSubject('');
  changeActive$: Observable<string> = this.changeActive.asObservable();
  private summaryChange = new Subject<[string, string]>();
  summaryChange$ = this.summaryChange.asObservable();

  private _currentStockListItem = new BehaviorSubject<StockListItem>(null);
  public currentStockListItem$ = this._currentStockListItem.asObservable();

  private _currentStockListItemRequest = new Subject<StockListItem>();
  public currentStockListItemRequest$ = this._currentStockListItemRequest.asObservable();

  private _stockLists = new BehaviorSubject<Observable<Array<StockListItem>>>(null);
  public stockLists$ = this._stockLists.asObservable();

  private _closeRequest = new Subject<StockListItem>();
  public closeRequest$ = this._closeRequest.asObservable();

  private _closeAllRequest = new ReplaySubject<StockListItem>(1);
  public closeAllRequest$ = this._closeAllRequest.asObservable();

  private _selectFirstRequest = new Subject<{}>();
  public selectFirstRequest$ = this._selectFirstRequest.asObservable();

  private subscription = new Subscription();

  public newListItem = new ReplaySubject<string>(1);

  private items = new WeakSet<StockListItem>();

  private summaryItemGetter: BehaviorSubject<(summaryId) => SummaryPortfolio> = new BehaviorSubject(undefined);
  private firstItemGetter: BehaviorSubject<() => string> = new BehaviorSubject(undefined);

  currentItem: StockListItem;

  firstSelect = true;

  constructor(private cocomero: CocomeroService) {}

  setSummaryItemGetter(f: (summaryId) => SummaryPortfolio) {
    this.summaryItemGetter.next(f);
  }

  setFirstItemGetter(f: () => string) {
    this.firstItemGetter.next(f);
  }

  updateCurrentItem(item: StockListItem) {
    this.currentItem = item;
    this._currentStockListItem.next(item);
  }

  requestChangeCurrentItem(item: StockListItem) {
    this._currentStockListItemRequest.next(item);
  }

  updateStockList(obs: Observable<Array<StockListItem>>) {
    this.subscription.unsubscribe();

    this.subscription = obs.subscribe((items) => {
      for (const item of items) {
        if (item && item.summary && !this.items.has(item)) {
          this.items.add(item);
          if (item && item.summary) {
          }
        }
      }
    });
    this._stockLists.next(obs);
  }

  requestClose(item: StockListItem) {
    this._closeRequest.next(item);
  }

  closeAll() {
    this._closeAllRequest.next();
  }

  selectFirst() {
    this._selectFirstRequest.next();
  }

  getItem(summaryId: string): Observable<StockListItem | null> {
    return this.summaryItemGetter.pipe(
      filter((a) => a !== undefined),
      take(1),
      map((f) => {
        const s = f(summaryId);
        if (s) {
          return this.createItem(s, s.summary.type === SummaryType.WatchList);
        } else {
          return null;
        }
      })
    );
  }

  getFirstItem(): Observable<string | null> {
    return this.firstItemGetter.pipe(
      filter((a) => a !== undefined),
      take(1),
      map((f) => f())
    );
  }

  createItem(summary: SummaryPortfolio, editable: boolean): StockListItem {
    if (summary.summary.type === SummaryType.SmartList) {
      if (summary.summary instanceof SmartListSummary) {
        summary.summary.update(this.cocomero.op);
      }
    }
    const r: Array<Portfolio> = [];
    let index = 0;
    for (const [portfolio, master, info] of this.cocomero.summaries.getStockPortfolio(summary.summary.issueList)) {
      r.push(new StockPortfolio(master, portfolio, info, this.cocomero.time, ++index));
    }
    if (editable) {
      r.push(new NewPortfolioEntry());
    }
    const item = new StockListItem();
    item.summary = summary;
    item.portfolios = null;
    item.stocks = r;
    item.editable = editable;
    item.stockListUpdatedSubject.next();
    return item;
  }

  selectItem(itemId: string, newItem: boolean) {
    if (this.firstSelect) {
      this.firstSelect = false;
      return;
    }
    if (newItem) {
      this.newListItem.next(itemId);
    } else {
      this.summaryChange.next([this.active, itemId]);
    }
  }

  setActive(id: string) {
    this.active = id;
    this.changeActive.next(id);
  }
}
