import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@argentumcode/brisk-common';
import { Observable } from 'rxjs';
import { captureException, configureScope } from '@sentry/browser';

@Injectable()
export class SubWindowErrorHandlerService {
  public errorRaised: Observable<any>;
  constructor(private parentErrorHandler: ErrorHandlerService) {
    this.errorRaised = parentErrorHandler.errorRaised;
    configureScope((scope) => {
      scope.setUser({
        id: this.parentErrorHandler.userId,
      });
    });
  }

  handleError(err: any): void {
    console.error(err);
    if (this.parentErrorHandler.handleErrorWithoutSentry(err)) {
      captureException(err);
    }
  }
}
