import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConditionCombination, ConditionType, SpecialValueFilterType, ValueType, VgGridColumnConditionFilter } from '../vg-grid-filter';

@Component({
  selector: 'brisk-vg-grid-condition-filter',
  templateUrl: './vg-grid-condition-filter.component.html',
  styleUrls: ['./vg-grid-condition-filter.component.scss'],
})
export class VgGridConditionFilterComponent implements OnInit, OnChanges {
  conditionType1: ConditionType = ConditionType.None;
  conditionType2: ConditionType = ConditionType.None;
  conditionValue1 = null;
  conditionValue2 = null;
  combination: ConditionCombination = ConditionCombination.And;

  specialValueFilterType: SpecialValueFilterType = SpecialValueFilterType.None;

  @Input()
  filter: VgGridColumnConditionFilter;

  @Output()
  apply = new EventEmitter<VgGridColumnConditionFilter>();

  @Output()
  cancel = new EventEmitter<{}>();

  roundPrecision = 2;

  valueType: ValueType;

  conditionEnum = [
    { value: 'none', name: '(設定しない)' },
    { value: 'equal', name: '指定の値に等しい' },
    { value: 'notEqual', name: '指定の値に等しくない' },
    { value: 'greater', name: '指定の値より大きい' },
    { value: 'greaterOrEqual', name: '指定の値以上' },
    { value: 'less', name: '指定の値より小さい' },
    { value: 'lessOrEqual', name: '指定の値以下' },
  ];

  specialValueFilterTypes = [
    {
      value: SpecialValueFilterType.None,
      name: '表示する',
    },
    {
      value: SpecialValueFilterType.Only,
      name: 'だけを表示する',
    },
    {
      value: SpecialValueFilterType.Hide,
      name: '非表示にする',
    },
  ];

  specialValueFilterName: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.filter) {
      this.conditionType1 = this.filter.conditionType1;
      this.conditionType2 = this.filter.conditionType2;
      this.conditionValue1 = this.filter.conditionValue1;
      this.conditionValue2 = this.filter.conditionValue2;
      this.combination = this.filter.combination;
      this.roundPrecision = this.filter.roundPrecision;
      this.valueType = this.filter.valueType;
      this.specialValueFilterName = this.filter.specialValueName;
      this.specialValueFilterType = this.filter.specialValueFilter;
    }
  }

  applyFilter() {
    const filter = new VgGridColumnConditionFilter();
    if (this.filter) {
      filter.binding = this.filter.binding;
      filter.valueType = this.filter.valueType;
      filter.valueGetter = this.filter.valueGetter;
      filter.specialValue = this.filter.specialValue;
      filter.specialValueName = this.filter.specialValueName;
    }
    filter.roundPrecision = this.filter.roundPrecision;
    filter.combination = this.combination;
    filter.conditionValue1 = this.conditionValue1;
    filter.conditionValue2 = this.conditionValue2;
    filter.conditionType1 = this.conditionType1;
    filter.conditionType2 = this.conditionType2;
    filter.specialValueFilter = this.specialValueFilterType;
    this.apply.emit(filter);
  }

  updateConditionValue(t: any) {
    console.log(t);
  }

  clearFilter() {
    this.apply.emit(null);
  }
}
