import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { Subscription, timer } from 'rxjs';
import { WindowRefService } from '@argentumcode/brisk-common';

interface State {
  hidden: boolean;
  time: number;
  density: number;
}
@Component({
  selector: 'app-fita4-ita-clock',
  templateUrl: './ita-clock.component.html',
  styleUrls: ['./ita-clock.component.scss'],
})
export class ItaClockComponent implements OnInit, OnDestroy {
  state: State = undefined;

  private _rafId: number = undefined;

  constructor(
    private cocomero: CocomeroService,
    private window: WindowRefService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._rafId = this.window.nativeWindow.requestAnimationFrame(this._eventLoopWrapper.bind(this));
  }

  private _eventLoopWrapper() {
    const nextState: State = {
      hidden: this.cocomero.wsFlexTimeDiff() >= 10 * 1000 || this.cocomero.wsFlexLastTime === 0 || this.cocomero.reconnecting,
      time: Math.trunc(this.cocomero.wsFlexLastTime / 1000000) * 1000000,
      density: (Math.trunc(Math.min(15000, this.cocomero.wsFlexTimeCountOneSec()) / 100) * 100) / 15000,
    };

    if (!this.state || this.state.hidden !== nextState.hidden || this.state.time !== nextState.time) {
      this.ngZone.run(() => {
        this.state = nextState;
        this.changeDetectorRef.markForCheck();
      });
    }
    this.window.nativeWindow.requestAnimationFrame(this._eventLoopWrapper.bind(this));
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this._rafId !== undefined) {
      this.window.nativeWindow.cancelAnimationFrame(this._rafId);
    }
  }
}
