import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fita4-order-dialogs',
  templateUrl: './order-dialogs.component.html',
  styleUrls: ['./order-dialogs.component.scss'],
})
export class OrderDialogsComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
