import { Component, OnInit, StaticProvider, ViewChild } from '@angular/core';
import { StockDetailService } from '../../stock-detail.service';
import { Observable } from 'rxjs';
import { JSFCEntry, ResizeService, JsfcComponent as CommonJsfcComponent } from '@argentumcode/brisk-common';
import { GlHeaderItem, GlOnResize, GlOnShow } from '@argentumcode/ngx-golden-layout';
import { SAVE_ITEM_HEADER, SaveItemHeaderComponent } from '../../headers/save-item-header/save-item-header.component';

@Component({
  selector: 'app-fita4-jsfc-panel',
  templateUrl: './jsfc-panel.component.html',
  styleUrls: ['./jsfc-panel.component.scss'],
})
export class JsfcPanelComponent implements OnInit, GlOnShow, GlOnResize, GlHeaderItem {
  jsfcEntries: Observable<Array<JSFCEntry>>;

  @ViewChild('jsfcComponent', { static: true }) jsfcComponent: CommonJsfcComponent;
  headerComponent = SaveItemHeaderComponent;
  additionalTokens: Array<StaticProvider> = [
    {
      provide: SAVE_ITEM_HEADER,
      useValue: {
        tooltip: '証金残CSVエクスポート',
        click: () => {
          this.export();
        },
      },
    },
  ];

  constructor(private stockDetailService: StockDetailService, private resize: ResizeService) {
    this.jsfcEntries = this.stockDetailService.jsfcEntries;
  }

  ngOnInit(): void {}

  glOnResize(): void {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  glOnShow(): void {
    this.resize.dispatchResize();
    console.log('onShow');
  }

  export(): void {
    if (this.stockDetailService.stockOperator) {
      this.jsfcComponent.exportCsv(`jsfc-${this.stockDetailService.stockOperator.base.master.issueCode}.csv`);
    }
  }
}
