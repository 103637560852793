import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PersistentStateService } from './persistent-state.service';
import { take } from 'rxjs/operators';
import { History, HistoryType } from '@argentumcode/brisk-common';

const SAVE_COUNT = 150;

@Injectable()
export class ViewHistoryService implements OnDestroy {
  histories: History[] = [];
  orderHistory: number[] = [];

  private orderHistorySubject = new Subject<number[]>();
  orderHistory$ = this.orderHistorySubject.asObservable();

  constructor(private persistentState: PersistentStateService<unknown>) {
    this.persistentState.issueCodeHistory$.pipe(take(1)).subscribe((issueCodes) => {
      this.histories = issueCodes.map((ic) => ({
        code: ic,
        type: HistoryType.Stock,
      }));
    });
  }

  pushStock(issueCode: number) {
    const data = {
      type: HistoryType.Stock,
      code: Number(issueCode),
    };
    this.histories.push(data);
    this.normalize();
    this.saveToLocalStorage();
  }

  pushOrder(issueCode: number) {
    // Just ignore it
  }

  loadFromLocalStorage() {
    // Just ignore it
  }

  saveToLocalStorage() {
    this.persistentState.updateIssueCodeHistory(this.histories.map((a) => a.code));
  }

  clean(issueCodeMap: { [key: number]: any }) {
    this.histories = this.histories.filter((a) => a.code in issueCodeMap);
    this.orderHistory = this.orderHistory.filter((a) => a in issueCodeMap);
  }

  private normalize() {
    const last = this.histories.slice(-1)[0];
    for (let i = 0; i < this.histories.length - 1; i++) {
      if (this.histories[i].code === last.code && this.histories[i].type === last.type) {
        this.histories.splice(i, 1);
        i--;
      }
    }
    if (this.histories.length > SAVE_COUNT) {
      this.histories.splice(0, this.histories.length - SAVE_COUNT);
    }
  }

  /**
   * 最後にアクセスしたIssueCodeを取得する
   */
  getLatestIssueCode(stocks?: { [key: number]: any }): number {
    // TODO: stocks引数の削除(cleanすれば良いため)
    if (stocks) {
      const h = this.histories.filter((a) => a.type === HistoryType.Stock && a.code in stocks).slice(-1)[0];
      return h ? h.code : null;
    } else {
      if (this.histories.length > 0) {
        return this.histories.slice(-1)[0].code;
      } else {
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    this.orderHistorySubject.complete();
  }
}
