import { TickOperator } from './flex-operator';

export class Tick {
  constructor(private op: TickOperator, private tickType: number) {}

  public price10ToIndex(price10: number): number {
    return this.op.tickPrice10ToIndex(this.tickType, price10);
  }

  public indexToPrice10(index: number): number {
    return this.op.tickIndexToPrice10(this.tickType, index);
  }

  public roundPrice(price10: number) {
    const idx = this.price10ToIndex(price10);
    const p1 = this.indexToPrice10(idx);
    if (p1 === price10) {
      return p1;
    }
    const p2 = this.indexToPrice10(idx + 1);
    return p2 - price10 < price10 - p1 ? p2 : p1;
  }

  public roundUpPrice(price10: number) {
    const idx = this.price10ToIndex(price10);
    const p1 = this.indexToPrice10(idx);
    if (p1 === price10) {
      return p1;
    }
    return this.indexToPrice10(idx + 1);
  }

  public downTick(price10: number) {
    const idx = this.price10ToIndex(price10);
    const p1 = this.indexToPrice10(idx);
    const p2 = this.indexToPrice10(idx - 1);
    return p1 - p2;
  }

  public getUpRenewalPrice10(price10: number) {
    const up = price10 + this.op.getRenewalPrice(price10);
    return this.roundUpPrice(up);
  }

  public getDownRenewalPrice10(price10: number) {
    return price10 - this.op.getRenewalPrice(price10);
  }

  public getUpDoubleRenewalPrice10(price10: number) {
    const up = price10 + 2 * this.op.getRenewalPrice(price10);
    return this.roundUpPrice(up);
  }

  public getDownDoubleRenewalPrice10(price10: number) {
    return price10 - 2 * this.op.getRenewalPrice(price10);
  }
}
