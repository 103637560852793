<div *ngIf="summary" class="p-summary">
  <app-hit-lift-bar
    *ngIf="!summary.disabledHitCount"
    id="hitLiftChart"
    [hitCount]="summary.hitCount"
    [liftCount]="summary.liftCount"
    [issueCount]="summary.uniqueIssueCount"
  ></app-hit-lift-bar>
  <span id="name">
    {{ summary.name }}
  </span>
  <ng-container *ngIf="showSummaryDetail">
    <span id="lastPriceBasePriceChange">
      <span class="heading-indexLastPriceBasePriceChange">騰落率</span>
      <span [ngClass]="summary.lastPriceBasePriceChange | priceChangeClass" ngbTooltip="騰落率単純平均">{{
        summary.lastPriceBasePriceChange | priceChanges | predictTransform: summary.predict
      }}</span>
    </span>
    <span id="vwapChange">
      <span class="heading-indexVwapChange">VWAP比</span>
      <span [ngClass]="summary.vwapPriceChange | priceChangeClass" ngbTooltip="VWAP比単純平均">{{
        summary.vwapPriceChange | priceChanges | predictTransform: summary.vwapPredict
      }}</span>
    </span>
    <span id="openPriceBasePriceChange" *ngIf="showOpen">
      <span class="heading-indexOpenPriceBasePriceChange">寄騰落率</span>
      <span [ngClass]="summary.openPriceBasePriceChange | priceChangeClass" ngbTooltip="寄騰落率単純平均">{{
        summary.openPriceBasePriceChange | priceChanges | predictTransform: summary.openPriceBasePriceChangePredict
      }}</span>
    </span>
    <span id="turnover"
      ><span class="heading-indexTurnover">売買代金</span
      ><ng-container *ngIf="summary.turnover10"
        >{{
          summary.turnover10
            | fastDecimal
              : {
                  separateComma: true,
                  fractionDigits: 2
                }
            | predictTransform: summary.turnoverPredict
        }}
        億円</ng-container
      ></span
    >
    <span id="issueCount" *ngIf="showCount">
      <span class="heading-issueCount">銘柄数</span>
      <span>{{ summary.uniqueIssueCount | fastDecimal: { separateComma: true } }}</span>
    </span>
    <ng-container *ngIf="showUpDown">
      <span id="upCount">
        <span class="heading-upCount">上昇</span>
        <span>{{ summary.upCount | fastDecimal: { separateComma: true } }}</span>
      </span>
      <span id="downCount">
        <span class="heading-downCount">下落</span>
        <span>{{ summary.downCount | fastDecimal: { separateComma: true } }}</span>
      </span>
    </ng-container>
  </ng-container>
</div>
