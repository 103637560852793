import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { StockView } from '../flex';
import * as GIF from 'gif.js';
import { saveAs } from 'file-saver';
import { ItaCaptureOperatorService } from './ita-capture-operator-service';
import { ItaComponent } from '../ita/ita/ita.component';
import { TimestampFormatPipe } from '../brisk-common/timestamp-format.pipe';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { FlexConfig, FLEX_CONFIG } from '../flex/flex-config';

@Component({
  selector: 'brisk-ita-capture',
  templateUrl: './ita-capture.component.html',
  styleUrls: ['./ita-capture.component.scss'],
})
export class ItaCaptureComponent implements OnInit {
  @Input()
  normalized = false;

  @Input()
  view: StockView;

  @ViewChild('ita', { static: true })
  private _ita: ItaComponent;

  private _capturing = false;

  constructor(
    private op: ItaCaptureOperatorService,
    private timestampFormatPipe: TimestampFormatPipe,
    @Inject(FLEX_CONFIG) private flexConfig: FlexConfig
  ) {}

  ngOnInit() {}

  capture() {
    if (this._capturing) {
      return;
    }
    const updated = new Subject<boolean>();
    const view = this.op.cloneStockView(this.view);
    const stockOperator = new StockWrapper(this.flexConfig, updated, view);
    this._ita.stockOperator = stockOperator;

    const gifConfig = {
      workerScript: this.op.workerFileName(),
      workers: 4,
      quality: 30,
    };

    const gif = new GIF(gifConfig);

    let curFrame = 0;
    let frames = 11;

    for (let i = -10; i <= 0; i++) {
      this.op.goUpdateNumber(view, view.maxFrame + i);
      this.op.updateStockView(view);
      if (view.frame !== view.maxFrame + i) {
        frames--;
        continue;
      }
      curFrame++;
      updated.next(true);
      const [qrString, qrColor] = this._ita.getQRStringAndColor(view);
      const canvas = this._ita.grid.getImageCanvas({
        bottomLeft: `${curFrame}/${frames}`,
        bottomRight: this.op.bottom(),
        headingLeft: `${view.master.issueCode} ${view.master.name}`,
        headingRight: `${this.timestampFormatPipe.transform(view.timestamp, { microseconds: true })} / ${view.frame}`,
        headingCenter: qrString,
        headingCenterColor: qrColor,
      });
      if (canvas === null) {
        return;
      }
      gif.addFrame(canvas, { delay: i === 0 ? 3000 : 1500 });
    }

    gif.on('finished', (blob) => {
      saveAs(blob, this.op.filename(view));
      this._capturing = false;
    });
    this._capturing = true;
    gif.render();
    updated.complete();
  }
}
