<div [style.display]="_showFlagForAvoidEdgeScrollBug ? 'block' : 'none'" class="vg-grid">
  <cdk-virtual-scroll-viewport
    #cdkViewport
    [itemSize]="rowHeight * _multipleRowCount"
    class="vg-grid-viewport"
    (scroll)="_onScroll()"
    (contextmenu)="_onContextMenu($event)"
    tabindex="0"
    (keydown)="onKeyDown($event)"
    (focusout)="_onFocusOut($event)"
    (focusin)="_onFocusIn($event)"
  >
    <div
      #rowElement
      *cdkVirtualFor="let row of _indexes$; templateCacheSize: templateCacheSize"
      class="vg-grid-row"
      [style.minWidth]="minWidth ? minWidth.toString() + 'px' : null"
      [style.width]="_requiredWidth ? _requiredWidth.toString() + 'px' : null"
      [style.height]="rowHeight * _multipleRowCount + 'px'"
      [ngClass]="
        (row !== null && selectedItem === _getRow(row) ? ['vg-grid-row-selected'] : [])
          .concat(row !== null && selectedItemSet.has(_getRow(row)) ? ['vg-grid-row-selected'] : [])
          .concat(_getRow(row) === _dragTargetUp ? ['vg-grid-row-drag-target-up'] : [])
          .concat(_getRow(row) === _dragTargetDown ? ['vg-grid-row-drag-target-down'] : [])
          .concat(_getRow(row) === _dragItem ? ['vg-grid-row-drag-target'] : [])
          .concat(additionalRowClasses ? additionalRowClasses(_getRow(row)) : [])
          .join(' ')
      "
      [class.vg-grid-row--even]="row % 2 == 0"
      (dragenter)="row !== null && _onDragEnter(row, _getRow(row), $event, rowElement)"
      (dragleave)="row !== null && _onDragLeave(row, _getRow(row), $event, rowElement)"
      (drop)="row !== null && _onDrop(row, _getRow(row), $event, rowElement)"
      (dragstart)="row !== null && _onDragStart(row, _getRow(row), $event, rowElement)"
      (dragover)="row !== null && _onDragOver(row, _getRow(row), $event, rowElement)"
      (dragend)="row !== null && _onDragEnd(row, _getRow(row), $event, rowElement)"
      [draggable]="!editing"
    >
      <ng-container *ngIf="row !== null">
        <ng-container *ngIf="!_isGroupHeader(_getRow(row)); else groupHeader">
          <!-- TODO: 関数呼び出しを避ける -->
          <ng-template #columns let-sticky="sticky">
            <ng-container *ngFor="let column of visibleColumns; let i = index">
              <div
                *ngIf="sticky ? i < _stickyColumnsVisibleIndex : i >= _stickyColumnsVisibleIndex"
                [class.vg-grid-cell--last-sticky-column]="i == _stickyColumnsVisibleIndex - 1"
                class="vg-grid-cell"
                [style.width]="visibleColumnsWidth[i].width + 'px'"
                [style.left]="visibleColumnsWidth[i].left + 'px'"
                [style.top]="visibleColumnsWidth[i].rowIndex * rowHeight + 'px'"
                [style.height]="visibleColumnsWidth[i].rowSpan * rowHeight + 'px'"
                [ngClass]="
                  [column.cssClass]
                    .concat(editingItem === _getRow(row) && editingColumn === column ? ['vg-grid-cell-active-editor'] : [])
                    .join(' ')
                "
                [class.vg-grid-cell--top-of-row]="visibleColumnsWidth[i].rowIndex === 0"
                [class.vg-grid-cell--bottom-of-row]="visibleColumnsWidth[i].rowIndex + 1 === _multipleRowCount"
                [class.vg-grid-cell--first-column]="i === 0"
                [class.vg-grid-cell--last-column]="i === visibleColumns.length - 1"
                [style.textAlign]="column.align"
                (mousedown)="onCellPointerDown(row, _getRow(row), column, $event)"
                (mouseup)="onCellPointerUp(row, _getRow(row), column, $event)"
                (dblclick)="_onCellDblClick(row, _getRow(row), column, $event)"
                (contextmenu)="_onContextMenu($event)"
              >
                <ng-container *ngIf="!column.cellTemplate">
                  {{ _getRow(row)[column.binding] }}
                </ng-container>
                <ng-container
                  *ngIf="editingItem === _getRow(row) && editingColumn === column; then renderEditingTemplate; else renderCellTemplate"
                ></ng-container>
                <ng-template #renderEditingTemplate>
                  <ng-container
                    *ngTemplateOutlet="
                      column.editTemplate;
                      context: { $implicit: _getRow(row), item: _getRow(row), editingContext: editingContext }
                    "
                  >
                  </ng-container>
                </ng-template>
                <ng-template #renderCellTemplate>
                  <ng-container *ngTemplateOutlet="column.cellTemplate; context: { $implicit: _getRow(row), item: _getRow(row) }">
                  </ng-container>
                </ng-template>
              </div>
            </ng-container>
          </ng-template>
          <ng-container *ngTemplateOutlet="columns; context: { sticky: false }"></ng-container>
          <div
            class="vg-grid-row--sticky-columns"
            [class.vg-grid-row--sticky-columns-disable-sticky]="!cssStickyUsable"
            [style.left]="cssStickyUsable ? '0px' : _scrollLeft + 'px'"
            [style.width]="_stickyColumnsWidth + 'px'"
          >
            <ng-container *ngTemplateOutlet="columns; context: { sticky: true }"></ng-container>
          </div>
        </ng-container>
        <ng-template #groupHeader>
          <div class="vg-grid-cell vg-grid-cell-group-header" [style.right]="'0px'" [style.left]="'0px'" [style.height]="rowHeight + 'px'">
            <ng-container *ngIf="groupHeaderTemplate">
              <ng-container *ngTemplateOutlet="groupHeaderTemplate; context: { item: _getRow(row) }"> </ng-container>
            </ng-container>
            <ng-container *ngIf="!groupHeaderTemplate">
              {{ _getRow(row).name }}
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
  <div
    #headerScrollWrapper
    class="vg-grid-row-header-scroll-wrapper"
    [style.height]="rowHeight * _multipleRowCount + 'px'"
    [style.width]="!cssStickyUsable ? _realWidth + 'px' : _requiredWidth ? _requiredWidth + 'px' : null"
    [hidden]="!showRowHeader"
  >
    <div class="vg-grid-row vg-grid-row-header" [style.height]="rowHeight * _multipleRowCount + 'px'" *ngIf="showRowHeader">
      <ng-container *ngTemplateOutlet="headerColumns; context: { sticky: false }"></ng-container>
      <div
        class="vg-grid-row--sticky-columns"
        [class.vg-grid-row--sticky-columns-disable-sticky]="!cssStickyUsable"
        [style.left]="cssStickyUsable ? '0px' : _scrollLeft + 'px'"
        [style.width]="_stickyColumnsWidth + 'px'"
      >
        <ng-container *ngTemplateOutlet="headerColumns; context: { sticky: true }"></ng-container>
      </div>
      <ng-template #headerColumns let-sticky="sticky">
        <ng-container *ngFor="let item of visibleColumns; let i = index">
          <div
            *ngIf="sticky ? i < _stickyColumnsVisibleIndex : i >= _stickyColumnsVisibleIndex"
            class="vg-grid-cell vg-grid-cell-header"
            [class.vg-grid-cell--last-sticky-column]="i == _stickyColumnsVisibleIndex - 1"
            [style.width]="visibleColumnsWidth[i].width + 'px'"
            [style.left]="visibleColumnsWidth[i].left + 'px'"
            [style.top]="visibleColumnsWidth[i].rowIndex * rowHeight + 'px'"
            [style.height]="visibleColumnsWidth[i].rowSpan * rowHeight + 'px'"
            (click)="_onHeaderClick(item, $event)"
            [ngbPopover]="filterContent"
            triggers="manual"
            placement="bottom-left top-left bottom-right top-right"
            container="body"
            popoverClass="vg-grid-filter-popover"
            [ngClass]="
              (item._filtered ? ['vg-grid-filter-active'] : [])
                .concat(item._sortDirection === 0 || item._sortDirection === 1 ? ['vg-grid-sort-active'] : [])
                .join(' ')
            "
            #filterPopover="ngbPopover"
            (contextmenu)="_onContextMenuHeader(item, $event)"
          >
            <fa-icon *ngIf="item._sortDirection === 0" class="vg-grid-sort-icon" [icon]="['fas', 'sort-up']"></fa-icon>
            <fa-icon class="vg-grid-sort-icon" *ngIf="item._sortDirection === 1" [icon]="['fas', 'sort-down']"></fa-icon>
            {{ item.header }}

            <button
              *ngIf="item.filterType"
              class="vg-grid-filter-button"
              (mousedown)="_onFilterMouseDown(item, $event, filterPopover)"
              (click)="_onFilterClick($event)"
            >
              <fa-icon [icon]="['fas', 'filter']" transform="shrink-4"></fa-icon>
            </button>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
<ng-template #filterContent let-filter="filter" let-popover="popover" let-item="item">
  <brisk-vg-grid-filter
    [filter]="filter"
    (apply)="_onApplyFilter(popover, $event, item)"
    (focusOut)="_onFocusOutFilter(popover, $event)"
    (cancel)="_onCancelFilter(popover)"
  >
  </brisk-vg-grid-filter>
</ng-template>
