<div class="map-view-header__container" *ngIf="state$ | async as state">
  <span class="p-summary-line__map-group-label">グループ化</span>
  <wj-combo-box
    class="u-small-combo-box u-small-combo-box--w-10"
    [itemsSource]="groupingTypesDataSource"
    [ngModel]="state.grouping"
    (ngModelChange)="mapView.updateGroupings($event)"
    selectedValuePath="value"
    displayMemberPath="name"
  ></wj-combo-box>
  <button class="c-icon-btn" (click)="mapView.saveImage()" [ngbTooltip]="'マップ画像の保存'">
    <fa-icon [icon]="['fas', 'camera']" [fixedWidth]="true"></fa-icon>
  </button>
</div>
