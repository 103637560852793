import { interval, timer, Observable, merge, Subject } from 'rxjs';
import { ZoneSyncData } from '../zone-sync/ng-zone-sync';
import { dematerialize, filter, map, materialize, skip, take, takeWhile } from 'rxjs/operators';

interface TimerSyncWindow {
  timer(dueTime: number | Date, period?: number): Observable<number>;
  // main windowとの時間差。このnow()の値にdeltaの値を加えたものがmain windowのnowになる想定
}

interface TimerSyncData {
  windows: Array<TimerSyncWindow>;
  tick$: Observable<void>;
  tick(): void;
  now(): number;
}

declare global {
  interface Window {
    timerSync: TimerSyncData;
  }
}

function ticker() {
  setInterval(() => {
    window.timerSync.tick();
  }, 10);
}

// multipleWindowTimerを利用するための初期設定を行う。
// 親ウィンドウで呼び出す必要がある。
export function prepareTimerForParent() {
  const tick = new Subject<void>();
  window.timerSync = {
    windows: [
      {
        timer: timer,
      },
    ],
    tick: () => {
      tick.next();
    },
    tick$: tick.asObservable(),
    now: () => performance.now(),
  };
  ticker();
}

// multipleWindowTimerを利用するための初期設定を行う。
// 子ウィンドウで呼び出す必要がある。
export function prepareTimerForChildren() {
  window.timerSync = opener.timerSync;
  window.timerSync.windows.push({
    timer: timer,
  });
  ticker();
}

// background timer throttlingの影響を避けつつタイマーを実行する
// Notice: AngularのZoneの外で実行されるので必要に応じてZoneの中で実行すること。
export function multipleWindowTimer(dueTime: number, period?: number): Observable<number> {
  const current = window.timerSync.now();
  let skipTime = 0;
  let count = 0;
  if (period) {
    return window.timerSync.tick$.pipe(
      map(() => {
        const diff = window.timerSync.now() - current - skipTime;
        if (diff >= dueTime + period * count) {
          const currentCount = Math.floor((diff - dueTime) / period);
          if (currentCount > count + 5) {
            skipTime += (currentCount - (count + 5)) * period;
          }
          return count++;
        }
        return undefined;
      }),
      filter((val) => val !== undefined),
      map((v) => v as number)
    );
  } else {
    return window.timerSync.tick$.pipe(
      filter(() => {
        const diff = window.timerSync.now() - current;
        return diff >= dueTime;
      }),
      map(() => 0),
      take(1)
    );
  }
}

// background timer throttlingの影響を避けつつ一定間隔でタイマーを実行する
// Notice: AngularのZoneの外で実行されるので必要に応じてZoneの中で実行すること。
export function multipleWindowInterval(period: number): Observable<number> {
  return multipleWindowTimer(period, period);
}
