import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ItaViewHeaderComponent } from '../../headers/ita-view-header/ita-view-header.component';
import { ITA_GRID_CONTROLLER_TOKEN, ItaGridState } from '../ita-grid/interface';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { StockListService } from '../../stock-list.service';
import { StockDetailsService } from '../../stock-details.service';
import { PegState, ResizeService } from '@argentumcode/brisk-common';
import { CellDoubleClickService } from '../../cell-double-click.service';
import { GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { Container } from 'golden-layout';
import { GroupingTypeKey, GroupingTypes, MAP_VIEW_CONTROLLER_TOKEN, MapViewController, MapViewState } from './interface';
import { GroupingType } from 'fita3/src/app/portfolio/map-view/grouper';
import { MapViewHeaderComponent } from '../../headers/map-view-header/map-view-header.component';

@Component({
  selector: 'app-fita4-map-view-panel',
  templateUrl: './map-view-panel.component.html',
  styleUrls: ['./map-view-panel.component.scss'],
  providers: [
    {
      provide: MAP_VIEW_CONTROLLER_TOKEN,
      useExisting: forwardRef(() => MapViewPanelComponent),
    },
  ],
})
export class MapViewPanelComponent implements OnInit, MapViewController, OnDestroy {
  @ViewChild('mapView')
  mapView: MapViewPanelComponent;

  headerComponent = MapViewHeaderComponent;
  state: MapViewState;
  groupings: Array<GroupingType>;
  get collection() {
    return this.stockListService.stockCollectionView;
  }
  visible = false;
  itaColumnCount = 0;
  stateSubj: BehaviorSubject<MapViewState>;
  state$: Observable<MapViewState>;
  constructor(
    private stockListService: StockListService,
    private stockDetailsService: StockDetailsService,
    private element: ElementRef,
    private resize: ResizeService,
    private cellDoubleClick: CellDoubleClickService,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.itaColumnCount = 1;
    const state: Partial<MapViewState> = this.container.getState() || {};
    this.state = {
      grouping: state.grouping && state.grouping in GroupingTypes ? state.grouping : 'Industry',
    };
    this.groupings = GroupingTypes[this.state.grouping].groupings.slice();
    this.stateSubj = new BehaviorSubject<MapViewState>(this.state);
    this.state$ = this.stateSubj.asObservable();
  }

  updateGroupings(grouping: GroupingTypeKey): void {
    this.updateState({
      ...this.state,
      grouping: grouping,
    });
    this.groupings = GroupingTypes[this.state.grouping].groupings.slice();
  }

  ngOnInit(): void {
    this.visible = !this.container.isHidden;
    this.resize.dispatchResize();
  }

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
    setTimeout(() => {
      this.resize.dispatchResize();
    });
    this.visible = true;
  }

  glOnHide() {
    this.visible = false;
  }

  updateState(state: MapViewState) {
    this.state = state;
    this.stateSubj.next(this.state);
    this.container.setState(this.state);
  }

  onCellDblClicked() {
    this.cellDoubleClick.doubleClicked();
  }

  ngOnDestroy(): void {
    this.stateSubj.complete();
  }

  saveImage() {
    if (this.mapView) {
      this.mapView.saveImage();
    }
  }
}
