import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CanvasGridComponent, Column } from '../canvas-grid/canvas-grid.component';

@Component({
  selector: 'brisk-canvas-grid-column',
  template: '',
  styles: [''],
})
export class CanvasGridColumnComponent implements OnInit, OnChanges {
  @Input()
  get header(): string {
    return this._column.heading;
  }

  set header(h: string) {
    this._column.heading = h;
  }

  @Input()
  get width(): string | number {
    return this._column.width;
  }

  set width(w: string | number) {
    this._column.width = w;
  }

  @Input()
  get name(): string {
    return this._column.name;
  }

  set name(name: string) {
    this._column.name = name;
  }

  @Input()
  get bold(): boolean {
    return this._column.bold;
  }

  set bold(b: boolean) {
    this._column.bold = b;
  }

  private _column = new Column();

  @Input()
  enable = true;

  id: number = null;

  constructor(private canvasGrid: CanvasGridComponent) {}

  ngOnInit() {
    this.id = this.canvasGrid.getColumnId();
    if (this.enable) {
      this.canvasGrid.addColumn(this.id, this._column);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id === null) {
      return;
    }
    if ('enable' in changes) {
      const change = changes['enable'];
      if (change.currentValue && !change.previousValue) {
        this.canvasGrid.addColumn(this.id, this._column);
      } else if (!change.currentValue && change.previousValue) {
        this.canvasGrid.removeColumn(this.id);
      }
    }
  }
}
