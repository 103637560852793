import {
  checkStable,
  NgZoneForParentWindow,
  NgZonePrivate,
  onEnter,
  onLeave,
  runAllInAngularZone,
  updateMicroTaskStatus,
  ZoneSyncChild,
  ZoneSyncData,
} from './ng-zone-sync';

interface NgZoneChild {
  _inner: Zone;
  _outer: Zone;
  ngZone: NgZoneForParentWindow;
  destroy(): void;
}

export function setupNgZoneForChild(opener: Window): NgZoneChild {
  if (typeof Zone === 'undefined') {
    throw new Error(`In this configuration Angular requires Zone.js`);
  }

  Zone.assertZonePatched();

  const zones: NgZoneChild = {
    _inner: Zone.current,
    _outer: Zone.current,
    ngZone: opener.zoneSync.zone,
    destroy: () => {
      const index = opener.zoneSync.zones.indexOf(zoneChild);
      if (index >= 0) {
        opener.zoneSync.zones.splice(index, 1);
      }
    },
  };
  if ((Zone as any)['wtfZoneSpec']) {
    zones._inner = zones._inner.fork((Zone as any)['wtfZoneSpec']);
  }
  if ((Zone as any)['TaskTrackingZoneSpec']) {
    zones._inner = zones._inner.fork(new ((Zone as any)['TaskTrackingZoneSpec'] as any)());
  }
  forkInnerZoneWithAngularBehavior(opener.zoneSync, zones, opener.zoneSync.zone);
  const zoneChild: ZoneSyncChild = {
    inner: zones._inner,
    outer: zones._outer,
    Zone,
  };
  opener.zoneSync.zones.push(zoneChild);
  return zones;
}

function forkInnerZoneWithAngularBehavior(zoneSync: ZoneSyncData, zone: NgZoneChild, parentZone: NgZonePrivate) {
  let countMicroTask = false;
  let countMacroTask = false;
  zone._inner = zone._inner.fork({
    name: 'angular',
    properties: <any>{ isAngularZone: true, maybeDelayChangeDetection: false, childWindow: true },
    onInvokeTask: (delegate: ZoneDelegate, current: Zone, target: Zone, task: Task, applyThis: any, applyArgs: any): any => {
      return runAllInAngularZone(zoneSync, () => {
        try {
          onEnter(parentZone);
          return delegate.invokeTask(target, task, applyThis, applyArgs);
        } finally {
          onLeave(parentZone);
        }
      });
    },

    onInvoke: (
      delegate: ZoneDelegate,
      current: Zone,
      target: Zone,
      callback: Function,
      applyThis: any,
      applyArgs?: any[],
      source?: string
    ): any => {
      return runAllInAngularZone(zoneSync, () => {
        try {
          onEnter(parentZone);
          return delegate.invoke(target, callback, applyThis, applyArgs, source);
        } finally {
          onLeave(parentZone);
        }
      });
    },

    onHasTask: (delegate: ZoneDelegate, current: Zone, target: Zone, hasTaskState: HasTaskState) => {
      delegate.hasTask(target, hasTaskState);
      if (current === target) {
        // We are only interested in hasTask events which originate from our zone
        // (A child hasTask event is not interesting to us)
        if (hasTaskState.change === 'microTask') {
          if (countMicroTask !== hasTaskState.microTask) {
            if (hasTaskState.microTask) {
              parentZone._countPendingMicrotasks++;
            } else {
              parentZone._countPendingMicrotasks--;
            }
          }
          updateMicroTaskStatus(parentZone);
          checkStable(parentZone);
          countMicroTask = hasTaskState.microTask;
        } else if (hasTaskState.change === 'macroTask') {
          if (countMacroTask !== hasTaskState.macroTask) {
            if (hasTaskState.macroTask) {
              parentZone._countPendingMacrotasks++;
            } else {
              parentZone._countPendingMacrotasks--;
            }
          }
          parentZone.hasPendingMacrotasks = parentZone._countPendingMacrotasks > 0;
          countMacroTask = hasTaskState.macroTask;
        }
      }
    },

    onHandleError: (delegate: ZoneDelegate, current: Zone, target: Zone, error: any): boolean => {
      delegate.handleError(target, error);
      parentZone.runOutsideAngular(() => parentZone.onError.emit(error));
      return false;
    },
  });
}
