import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { TimestampHeaderComponent } from './timestamp-header/timestamp-header.component';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { NgbCollapseModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  imports: [
    CommonModule,
    BriskCommonModule,
    WjInputModule,
    WjCoreModule,
    NgbPopoverModule,
    NgbCollapseModule,
    NgbTooltipModule,
    IconsModule,
  ],
  declarations: [HeaderComponent, TimestampHeaderComponent],
  exports: [HeaderComponent, TimestampHeaderComponent],
})
export class HeaderModule {
  constructor() {}
}
