import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FatalErrorDialogComponent } from './fatal-error-dialog/fatal-error-dialog.component';
import { SplashComponent } from './splash/splash.component';
import { ReconnectingMessageComponent } from './reconnecting-message/reconnecting-message.component';
import { RestartDialogComponent } from './restart-dialog/restart-dialog.component';
import { UnrecommendedBrowserMessageComponent } from './unrecommended-browser-message/unrecommended-browser-message.component';
import { ItaCaptureOperatorService, IconsModule, FLEX_CONFIG } from '@argentumcode/brisk-common';
import { BriskItaCaptureOperatorService } from './brisk-ita-capture-operator.service';
import { environment } from '../../environments/default/environment';

@NgModule({
  imports: [CommonModule, SharedModule, HttpClientModule, IconsModule],
  declarations: [
    FatalErrorDialogComponent,
    SplashComponent,
    ReconnectingMessageComponent,
    RestartDialogComponent,
    UnrecommendedBrowserMessageComponent,
  ],
  exports: [
    FatalErrorDialogComponent,
    SplashComponent,
    ReconnectingMessageComponent,
    RestartDialogComponent,
    UnrecommendedBrowserMessageComponent,
  ],
  providers: [
    { provide: ItaCaptureOperatorService, useClass: BriskItaCaptureOperatorService },
    { provide: FLEX_CONFIG, useValue: environment.flexConfig },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modcules in the AppModule only.');
    }
  }
}
