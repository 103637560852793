import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { Subscription } from 'rxjs';
import { ResizeService } from '../resize/resize.service';
import { WindowRefService } from '../brisk-browser/window-ref.service';

@Directive({
  selector: '[briskFlexGridFlexibleRowSize]',
})
export class FlexGridFlexibleRowSizeDirective implements OnDestroy, OnInit {
  @Input()
  public flexibleRowSizeCoefficient = 0.08;
  private initializedSubscription: Subscription = null;
  private resizeSubscription: Subscription = null;

  constructor(
    private flexGrid: WjFlexGrid,
    private elementRef: ElementRef,
    private window: WindowRefService,
    private resize: ResizeService
  ) {
    this.initializedSubscription = this.flexGrid.initialized.subscribe(() => {
      this.onResize();
    });
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
  }

  ngOnDestroy(): void {
    if (this.initializedSubscription) {
      this.initializedSubscription.unsubscribe();
      this.initializedSubscription = null;
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
  }

  ngOnInit(): void {
    this.onResize();
  }

  private onResize() {
    const lineHeight = this.window.nativeWindow.getComputedStyle(this.elementRef.nativeElement).lineHeight;
    if (!lineHeight) {
      return;
    }
    const rowSize =
      (lineHeight === 'normal' ? 16 : Math.round(Number(lineHeight.slice(0, -2)))) +
      (this.flexibleRowSizeCoefficient * window.innerWidth) / 100;
    this.flexGrid.rows.maxSize = this.flexGrid.rows.defaultSize = rowSize;
    this.flexGrid.columnHeaders.rows.maxSize = this.flexGrid.columnHeaders.rows.defaultSize = rowSize;
  }
}
