<fa-icon
  *ngIf="saveItemHeader.icon"
  (click)="saveItemHeader.click($event)"
  [icon]="saveItemHeader.icon"
  [fixedWidth]="true"
  [ngbTooltip]="saveItemHeader.tooltip"
  transform="grow-2"
></fa-icon>
<fa-icon
  *ngIf="!saveItemHeader.icon"
  (click)="saveItemHeader.click($event)"
  [icon]="['fas', 'file-export']"
  [fixedWidth]="true"
  [ngbTooltip]="saveItemHeader.tooltip"
  transform="grow-2"
></fa-icon>
