export enum Side {
  Buy = '1'.charCodeAt(0),
  LongSell = '2'.charCodeAt(0),
  ShortSell = '5'.charCodeAt(0),
  ShortSellExempt = '6'.charCodeAt(0),
}

export enum Condition {
  Zaraba,
  OnOpen,
  OnClose,
  IOC,
  Funari,
  VWAP,
  Iceberg,
}

export type Strategy = 'VWAP' | 'Iceberg';
export namespace Strategy {
  export const VWAP: Strategy = 'VWAP';
  export const Iceberg: Strategy = 'Iceberg';
}

export enum CashMargin {
  Cash = '1'.charCodeAt(0),
  MarginOpen = '2'.charCodeAt(0),
  MarginClose = '3'.charCodeAt(0),
}

export enum OrdStatus {
  New = '0'.charCodeAt(0),
  PartiallyFilled = '1'.charCodeAt(0),
  Filled = '2'.charCodeAt(0),
  DoneForDay = '3'.charCodeAt(0),
  Canceled = '4'.charCodeAt(0),
  Replaced = '5'.charCodeAt(0),
  PendingCancel = '6'.charCodeAt(0),
  Rejected = '8'.charCodeAt(0),
  Expired = 'C'.charCodeAt(0),
  PendingReplace = 'E'.charCodeAt(0),
}

export function getSideString(side: Side): string {
  switch (side) {
    case Side.Buy:
      return '買';
    case Side.LongSell:
      return '売';
    case Side.ShortSell:
      return '空売';
  }
  return '';
}

export function getConditionString(condition: Condition): string {
  switch (condition) {
    case Condition.Funari:
      return '不成';
    case Condition.IOC:
      return 'IOC';
    case Condition.OnClose:
      return '引';
    case Condition.OnOpen:
      return '寄';
    case Condition.Zaraba:
      return '';
    case Condition.VWAP:
      return 'VWAP';
    case Condition.Iceberg:
      return 'アイス';
  }
  return '';
}

export class Share {
  static add(lh: Share, rh: Share): Share {
    return new Share(lh.value_ + rh.value_);
  }

  static sub(lh: Share, rh: Share): Share {
    return new Share(lh.value_ - rh.value_);
  }

  static min(lh: Share, rh: Share): Share {
    return new Share(Math.min(lh.value, rh.value));
  }

  static max(lh: Share, rh: Share): Share {
    return new Share(Math.max(lh.value, rh.value));
  }

  constructor(private value_: number) {}

  get value(): number {
    return this.value_;
  }

  add(share: Share): void {
    this.value_ += share.value_;
  }

  sub(share: Share): void {
    this.value_ -= share.value_;
  }

  mul(multiplier: number): void {
    this.value_ *= multiplier;
  }

  eq(share: Share): boolean {
    return this.value_ === share.value_;
  }

  gt(share: Share): boolean {
    return this.value_ > share.value_;
  }

  lt(share: Share): boolean {
    return this.value_ < share.value_;
  }
}

export class Price10 {
  constructor(private value_: number) {}

  get value(): number {
    return this.value_;
  }

  get isMarket(): boolean {
    return this.value_ === 0;
  }

  eq(price10: Price10): boolean {
    return this.value_ === price10.value_;
  }

  ne(price10: Price10): boolean {
    return this.value_ !== price10.value_;
  }

  le(price10: Price10): boolean {
    return this.value_ <= price10.value_;
  }

  lt(price10: Price10): boolean {
    return this.value_ < price10.value_;
  }

  ge(price10: Price10): boolean {
    return this.value_ >= price10.value_;
  }

  gt(price10: Price10): boolean {
    return this.value_ > price10.value_;
  }
}

export class Amount10 {
  static add(lh: Amount10, rh: Amount10): Amount10 {
    return new Amount10(lh.value_ + rh.value_);
  }

  static sub(lh: Amount10, rh: Amount10): Amount10 {
    return new Amount10(lh.value_ - rh.value_);
  }

  public static from(share: Share, price10: Price10): Amount10;
  public static from(price10: Price10, share: Share): Amount10;
  public static from(x: any, y: any): Amount10 {
    return new Amount10(x.value * y.value);
  }

  constructor(private value_: number) {}

  get value(): number {
    return this.value_;
  }

  add(amount10: Amount10): void {
    this.value_ += amount10.value_;
  }

  sub(amount10: Amount10): void {
    this.value_ -= amount10.value_;
  }

  mul(multiplier: number): void {
    this.value_ *= multiplier;
  }

  eq(amount10: Amount10): boolean {
    return this.value_ === amount10.value_;
  }
}
