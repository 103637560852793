import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart/chart.component';
import { ChartAxisScrollbarComponent } from './chart-axis-scrollbar/chart-axis-scrollbar.component';
import { WjChartModule } from '@grapecity/wijmo.angular2.chart';
import { SelectableChartComponent } from './selectable-chart/selectable-chart.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ResizeModule } from '../resize/resize.module';
import { BindFunctionPipe } from './bind-function.pipe';
import { QuantityMajorUnitPipe } from './quantity-major-unit.pipe';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  imports: [CommonModule, WjChartModule, NgbModule, FormsModule, ResizeModule, BriskCommonModule, NgbPopoverModule, IconsModule],
  declarations: [ChartComponent, ChartAxisScrollbarComponent, SelectableChartComponent, BindFunctionPipe, QuantityMajorUnitPipe],
  exports: [ChartComponent, SelectableChartComponent],
})
export class ChartModule {
  constructor() {}
}
