import { Pipe, PipeTransform } from '@angular/core';
import { Color } from '@grapecity/wijmo';

@Pipe({
  name: 'hitLiftBackground',
})
export class HitLiftBackgroundPipe implements PipeTransform {
  transform(value: any, opacity: any, hitColor: Color, liftColor: Color): any {
    if (value === 'HIT') {
      return `rgba(${hitColor.r}, ${hitColor.g}, ${hitColor.b}, ${hitColor.a * opacity})`;
    } else if (value === 'LIFT') {
      return `rgba(${liftColor.r}, ${liftColor.g}, ${liftColor.b}, ${liftColor.a * opacity})`;
    } else {
      return undefined;
    }
  }
}
