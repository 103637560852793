import { TemplateRef } from '@angular/core';
import { FilterValueGetter, SortComparer, SortDirection } from './collections/data-collecton';
import { CellTemplateContext } from './vg-grid-cell-template.directive';
import { FilterType, GridFilterChoice, ValueType } from './vg-grid-filter';

export const WidthUnit = {
  // @deprecated 整数の場合はピクセル。文字列の場合はFr
  Auto: 'Auto' as const,
  // 残り幅に対する割合
  Fr: 'Fr' as const,
  // ピクセル数
  Px: 'Px' as const,
  // em
  Em: 'Em' as const,
} as const;

export type WidthUnit = typeof WidthUnit[keyof typeof WidthUnit];

export function stringToSortDirection(s: string): SortDirection {
  if (s === '' || s === 'Asc') {
    return SortDirection.Asc;
  } else if (s === 'Desc') {
    return SortDirection.Desc;
  } else {
    throw new Error(`Invalid Sort Order ${s}`);
  }
}

export class VgGridColumnWidth {
  /**
   * 計算されたwidth
   */
  width: number;

  /**
   * 計算されたleftの値
   */
  left: number;

  /**
   * 計算された行数
   */
  rowIndex = 0;

  /**
   * 計算されたrowSpan
   */
  rowSpan = 1;
}

export class VgGridColumn<T> {
  /**
   * ヘッダとして表示する文字列
   */
  header: string;

  /**
   * カラムの幅を取得または設定します。
   *
   * @example 300, '10*'
   */
  width: number;

  /**
   * カラムの幅の単位を指定します。
   */
  widthUnit: WidthUnit;

  /**
   * カラムの最小幅を取得または設定します。
   */
  minWidth?: number;
  /**
   * カラムの最小幅の単位を指定します。
   */
  minWidthUnit?: WidthUnit;

  /**
   * カラムの最大幅を取得または設定します。
   *
   * 整数を指定した場合はピクセル単位での幅を設定し、'<number>*'を指定した時は<number>の重み付けでの幅の自動設定を行う。
   */
  maxWidth?: number;
  /**
   * カラムの最大幅の単位を指定します。
   */
  maxWidthUnit?: WidthUnit;

  /**
   * 余った幅を分配するときのfactor
   */
  widthGrowFactor = 0;

  /**
   * カラム名を設定します。
   */
  name: string;

  /**
   * カラムを表示するかどうかを示す値を取得または設定します。
   */
  visible: boolean;

  /**
   * カラムをバインドする対象のカラム名を取得します
   */
  binding: string;

  /**
   * カラムの項目に利用するCSS Classを取得または設定します
   */
  cssClass: string;

  /**
   * カラムがソート可能かどうかを取得または設定します。
   */
  allowSorting: boolean;

  /**
   * カラムが編集可能かどうかを取得または設定します。
   */
  allowEditing: boolean;

  /**
   * カラムに利用可能なフィルタを取得または設定します。
   */
  filterType: FilterType = FilterType.None;

  /**
   * カラムのフィルタで利用する小数点以下の桁数を指定します。
   */
  filterRoundPrecision = 2;

  /**
   * カラムの条件フィルタで利用する型を指定します。
   */
  filterValueType: ValueType = ValueType.Number;

  /**
   * カラムの条件フィルタでの特異値を設定します。
   */
  filterSpecialValue: any = undefined;

  /**
   * カラムの条件フィルタでの特異値の名前を設定します。
   */
  filterSpecialValueName: string = undefined;

  /**
   * カラムのフィルタで利用する選択肢を設定します。
   */
  filterChoices: Array<GridFilterChoice<T, any>> = [];

  align: string;

  /**
   * カラムをドラッグすることで行を移動できるかどうかを取得または設定します
   */
  allowRowDragging: boolean;

  /**
   * セルの描画に用いるテンプレートを指定します。
   */
  cellTemplate: TemplateRef<CellTemplateContext<T>> = null;

  /**
   * 編集セルの描画に用いるテンプレートを指定します。
   */
  editTemplate: TemplateRef<CellTemplateContext<T>> = null;

  /**
   * 初回ソートクリック時の標準でのソート
   */
  defaultSortDirection: SortDirection = SortDirection.Asc;

  /**
   * CollectionViewにおけるソートの方向を取得します。
   * 内部的に利用され、VgGridComponent以外からの編集は想定されていません。
   */
  _sortDirection: SortDirection = null;
  /**
   * CollectionViewにおけるフィルタの状態を取得します。
   * 内部的に利用され、VgGridComponent以外からの編集は想定されていません。
   */
  _filtered = false;

  /**
   * @deprecated ポインタキャプチャを利用しない形式になったため、廃止予定
   * ポインタキャプチャを行うかどうかを指定します。
   * カラムでマウスイベント(click, dblclick)などを利用する場合はFalseに設定します。
   */
  capturePointer = true;

  /// 複数行モードでの行の結合数を表します。単一行モードでは単に無視されます。
  rowSpan = 1;

  /// 複数行モードでの列の結合数を表します。単一行モードでは単に無視されます。
  colSpan = 1;

  /// カラム編集を利用する場合の、初期状態での表示有無です。
  initialVisible = true;

  /// カラムの設定を利用する場合の、初期状態での固定の有無です。
  initialStickyColumn = false;

  // 非表示にすることが出来ないカラムを設定します。
  disableHide = false;

  // ヘッダカラムかどうか
  isHeader = false;

  // 表示用の名前
  displayName = '';

  // カラムクリック時にソートに利用する値の取得方法
  sortComparer: SortComparer<T>;

  // フィルタ時に利用する値の取得方法
  filterValueGetter: FilterValueGetter<T>;

  // ファイタ時に利用する名前の取得関数
  filterNameGetter?: (v: any) => string;

  /// カラムの固定の有無を行うかを設定します。
  /// Trueの場合、そのカラム及びそれより左のカラムは固定されます。
  stickyColumn = false;
}
