import { Injectable, OnDestroy } from '@angular/core';

declare const COMMIT_ID: string;

@Injectable()
export class MainWindowService implements OnDestroy {
  subWindow: Window | null = null;

  get opened(): boolean {
    return this.subWindow && !this.subWindow.closed;
  }

  constructor() {}

  focusToFullIta() {
    if (!this.opened) {
      this.toggleFullIta();
    } else {
      this.subWindow.focus();
    }
  }

  toggleFullIta() {
    if (this.subWindow && !this.subWindow.closed) {
      this.subWindow.close();
    } else {
      this.subWindow = window.open(COMMIT_ID ? `secondary-window.${COMMIT_ID}.html` : 'secondary-window', '_blank');
    }
  }

  ngOnDestroy() {
    if (this.subWindow) {
      this.subWindow.close();
      this.subWindow = null;
    }
  }
}
