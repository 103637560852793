<div
  #popover="ngbPopover"
  [placement]="placement"
  [ngbPopover]="popContent"
  [container]="container"
  [popoverClass]="'c-assist-popover c-assist-popover--default c-tutorial-popover ' + cssClass"
></div>

<ng-template #popContent>
  <div class="p-tutorial-body" [class.p-tutorial-body--with-image]="imagePath">
    <img *ngIf="imagePath" class="p-tutorial-image" [src]="imagePath" />
    <div class="p-tutorial-content">
      <ng-content></ng-content>
    </div>
    <div class="p-tutorial-buttons">
      <a *ngIf="detailLink" class="c-assist-popover__button" [href]="detailLink" target="_blank">
        <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
        もっと詳しく
      </a>
      <button class="c-assist-popover__button" href="#" (click)="close()">
        閉じる
      </button>
    </div>
  </div>
</ng-template>
