import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';

@Component({
  selector: 'app-fita4-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashComponent {
  public get bootStatus$(): Observable<number> {
    return this.cocomero.bootStatus.asObservable();
  }

  constructor(private cocomero: CocomeroService) {}
}
