import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ArgumentOutOfRangeError, Observable, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import {
  FlexExchange,
  FlexVersion,
  ItaRow,
  ItaRowItem,
  Portfolio,
  QRRow,
  Side,
  StockMaster,
  StockView,
  Summary,
  SummaryPortfolio,
} from '@argentumcode/brisk-common';
import { environment } from '../../environments/default/environment';

declare namespace FlexWasm {
  type Buffer = number;
  type FlexOperatorId = number;

  class Wasm {
    readonly HEAPU8: Uint8Array;

    addFunction(func: any, sig: string): number;

    _malloc(size: number): Buffer;

    _free(buf: Buffer): number;

    _initialize(
      funcPtr: number,
      funcPtr2: number,
      funcPtr3: number,
      basePriceCallback: number,
      authErrorCallback: number,
      marketFinishedCallback: number,
      flexVersion: number
    ): FlexOperatorId;

    _push(id: FlexOperatorId, buf: Buffer, length: number);

    _pushWs(id: FlexOperatorId, buf: Buffer, length: number, timestamp: Buffer, count: Buffer);

    _getPortfolio(id: FlexOperatorId, issueCode: number, buf: Buffer): boolean;

    _getPortfolios(id: FlexOperatorId, issueCodes: number, length: number, portfolios: number, all: number): boolean;

    _getStockMaster(id: FlexOperatorId, buf: Buffer, length: number): boolean;

    _stockCount(id: FlexOperatorId): number;

    _addSummary(id: FlexOperatorId): number;

    _updateSummary(id: FlexOperatorId, summaryId: number, buf: Buffer, count: number): number;

    _getSummaryPortfolio(id: FlexOperatorId, summaryId: number, buf: Buffer, timestamp: number): number;

    _getItaRows(
      id: FlexOperatorId,
      stockHandlder: number,
      price10: number,
      count: number,
      rows: number,
      market: number,
      over: number,
      under: number,
      miniMode: number
    );

    _getQR(id: FlexOperatorId, stockHandlder: number, lastFrameNumber: number, rows: number, maxCount: number);

    _getStockView(id: FlexOperatorId, issueCode: number, buf: Buffer): boolean;

    _unserialize(id: FlexOperatorId, buf: Buffer, length: number, start: number, end: number): boolean;

    _getFrameNumbers(id: FlexOperatorId, buf: Buffer, count: number): number;

    _fitItaViewRowPrice10(id: FlexOperatorId, stockHandler: number, price10: number, rows: number);

    _fitItaViewRowPriceIndex(id: FlexOperatorId, stockHandler: number, price10: number, rows: number);

    _getTickPrice10ToIndex(id: FlexOperatorId, tick: number, price10: number);

    _getTickIndexToPrice10(id: FlexOperatorId, tick: number, index: number);

    _apiRecieved(id: FlexOperatorId);

    _cloneStock(id: FlexOperatorId, stockHandler: number): number;

    _goUpdateNumber(id: FlexOperatorId, stockHandler: number, updateNumber: number);

    _getRenewalTick10(id: FlexOperatorId, stockHandler: number, price10: number);

    _getDate(id: FlexOperatorId): number;

    _getTime(id: FlexOperatorId, ts: number): number;

    _getSessionDone(id: FlexOperatorId): number;

    _addTraceUpdate(id: FlexOperatorId): number;

    _clearTrace(id: FlexOperatorId, traceId: number): number;

    _getTrace(id: FlexOperatorId, traceId: number, issueIds: Buffer, maxSize: number): number;

    _hasTrace(id: FlexOperatorId, traceId: number, issueId: number): number;

    _pushSQ(
      id: FlexOperatorId,
      issueCode: number,
      side: number,
      range10: number,
      sec: number,
      limitDown10: number,
      limitUp10: number
    ): number;

    _applyBasePriceQueue(id: FlexOperatorId): number;

    _getStockPointer(id: FlexOperatorId, stockHandler: number): number;

    _pushBasePrice(id: FlexOperatorId, issueId: number, basePrice10: number, limitDown10: number, limitUp10: number);

    addFunction(func: any, type?: string);
  }
}

import FlexOperatorId = FlexWasm.FlexOperatorId;
import { UserAgentService } from '@argentumcode/brisk-common';

declare var CC: any;
declare const WASM_ID: string;

const MAX_STOCK_COUNT = 8192;
const OHLC_LENGTH = environment.flexConfig.ohlcLength;
const OHLC_SIZE = OHLC_LENGTH * 6;
const PORTFOLIO_SIZE = 88 + 4 * OHLC_LENGTH;
const ITA_ROW_SIZE = 204;
const STOCK_VIEW_SIZE = 148 + 24 * OHLC_LENGTH;
const QR_ROW_SIZE = 28;
const QR_ROW_MAX = 2000;

export class QRManager {
  private qrs: { [key: number]: QRRow[] } = {};

  initialize(stock: number): void {
    this.qrs[stock] = [];
  }

  deinitialize(stock: number): void {
    console.assert(!!this.qrs[stock]);
    delete this.qrs[stock];
  }

  emplace(stock: number, type: number, timestamp: number, price10: number, quantity: number, lotSize: number, frameNumber: number): void {
    console.assert(!!this.qrs[stock]);
    const row = new QRRow();
    row.price10 = price10;
    row.timestamp = timestamp;
    row.quantity = quantity * lotSize;
    row.type = type;
    row.frameNumber = frameNumber;
    this.qrs[stock].push(row);
  }

  clone(to: number, from: number) {
    console.assert(!!this.qrs[from]);
    this.qrs[to] = this.qrs[from].slice(0);
  }

  popBack(stock: number) {
    console.assert(!!this.qrs[stock]);
    if (this.qrs[stock].length > 0) {
      this.qrs[stock].pop();
    }
  }

  getQR(stock: number, updateNumber: number): QRRow[] {
    console.assert(!!this.qrs[stock]);
    const qrs = this.qrs[stock];
    let startIndex = 0;
    for (let i = qrs.length - 1; i >= 0; i--) {
      if (qrs[i].frameNumber < updateNumber) {
        startIndex = i + 1;
        break;
      }
    }
    return qrs.slice(startIndex);
  }

  getLatestQR(stock: number): QRRow {
    console.assert(!!this.qrs[stock]);
    const qrs = this.qrs[stock];
    if (qrs.length > 0) {
      return qrs[qrs.length - 1];
    } else {
      return null;
    }
  }
  size(stock: number): number {
    return this.qrs[stock].length;
  }
  lastPrice10(stock: number): number {
    return this.qrs[stock][this.qrs[stock].length - 1].price10;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FlexOperatorService implements OnDestroy {
  constructor(private _zone: NgZone, private userAgent: UserAgentService) {
    if (this.useQRM) {
      this.qrm = new QRManager();
      window['qrm'] = this.qrm;
    }
    const useWasm = window['WebAssembly'] && !this.userAgent.isiOS && !(this.userAgent.isEdge && this.userAgent.majorVersion === 16);
    this.useAsmJS = !useWasm;
    const suffix = (environment.flexConfig.exchange as FlexExchange) === FlexExchange.Fukuoka ? '-fukuoka' : '';
    if (!WASM_ID) {
      // For development
      if (useWasm) {
        this.loadScript(`./assets/wasm${suffix}/fita.js`);
      } else {
        this.loadScript(`./assets/asm${suffix}/fita.js`);
      }
    } else {
      if (useWasm) {
        this.loadScript(`./assets/wasm${suffix}/fita.${WASM_ID}.js`);
      } else {
        this.loadScript(`./assets/asm${suffix}/fita.${WASM_ID}.js`);
      }
    }
  }
  static BUFFER_SIZE = 4 * 1024 * 1024;
  private useAsmJS = false;
  private useQRM = true;
  private qrm: QRManager;
  private buf: number = null;
  private wasm: FlexWasm.Wasm = null;

  private portfolioBuf: number = null;
  private stockViewBuf: number = null;
  private summaryPortfolioBuf: number = null;
  private issueCodeBuf: number = null;
  private itaRowBuf: number = null;
  private itaRowBufSize: number;
  private qrRowBuf: number = null;
  private timestampBuf: number = null;
  private traceBuf: number = null;
  private bufWsBuf: number;

  private initializedSubject = new ReplaySubject<boolean>();
  private updateNumberSubject = new Subject<[FlexOperatorId, Uint32Array]>();
  private heartbeatSubject = new Subject<[FlexOperatorId, number, number]>();
  private basePriceSubject = new Subject<[FlexOperatorId, number]>();
  private authErrorSubject = new Subject<FlexOperatorId>();
  private marketFinishedSubject = new Subject<FlexOperatorId>();

  public basePrice$ = this.basePriceSubject.asObservable();

  public initialized: Observable<boolean> = this.initializedSubject.asObservable();
  public updateNumber: Observable<[FlexOperatorId, Uint32Array]> = this.updateNumberSubject.asObservable();
  public heartbeat = this.heartbeatSubject.asObservable();
  public authError = this.authErrorSubject.asObservable();
  public marketFinished = this.marketFinishedSubject.asObservable();

  // 毎回Float64Arrayを生成すると遅いため最初に確保して使い回す
  private _floatParseArray = new Float64Array([0]);
  private _floatParseArray32 = new Uint32Array(this._floatParseArray.buffer);

  private loadScriptTimeout: Subscription = null;

  loadScript(filename: string) {
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.src = filename;
    script.async = true;
    script.addEventListener('load', () => {
      this.onLoadScript();
    });
    this._zone.runOutsideAngular(() => {
      this.loadScriptTimeout = timer(60 * 1000).subscribe(() => {
        this._zone.runGuarded(() => {
          throw new Error('Core Load Timeout');
        });
      });
    });
    window.document.body.appendChild(script);
  }

  onLoadScript() {
    const module = CC({
      locateFile: (path, prefix) => {
        const suffix = (environment.flexConfig.exchange as FlexExchange) === FlexExchange.Fukuoka ? '-fukuoka' : '';
        if (path.endsWith('.wasm')) {
          return `./assets/wasm${suffix}/${path}`;
        } else if (path.endsWith('.mem')) {
          return `./assets/asm${suffix}/${path}`;
        } else {
          return prefix + path;
        }
      },
    });
    module.locateFile = '';
    module.then((obj: FlexWasm.Wasm) => {
      if (this.loadScriptTimeout) {
        this.loadScriptTimeout.unsubscribe();
        this.loadScriptTimeout = null;
      }
      this.wasm = obj;
      this.buf = this.malloc(FlexOperatorService.BUFFER_SIZE);
      this.initializedSubject.next(true);
      this.initializedSubject.complete();
    });
  }

  private prepareBuffer<T>(size: number, callback: (buf: number) => T): T {
    if (size < FlexOperatorService.BUFFER_SIZE) {
      return callback(this.buf);
    }
    const buf = this.malloc(size);
    try {
      return callback(buf);
    } catch (ex) {
      throw ex;
    } finally {
      this.wasm._free(buf);
    }
  }

  public initialize(callback: (Uint8Array) => void, version: FlexVersion): number {
    return this.wasm._initialize(
      this.wasm.addFunction(this.send(callback), 'i32i8*i32'),
      this.wasm.addFunction(this.updateNumberCallback(), 'i32i32*i32'),
      this.wasm.addFunction(this.heartbeatCallback(), 'i32i32*i32'),
      this.wasm.addFunction(this.basePriceCallback(), 'i32i32'),
      this.wasm.addFunction(this.authErrorCallback(), 'i32i32'),
      this.wasm.addFunction(this.marketFinishedCallback(), 'i32'),
      version
    );
  }

  public send(callback: (buf: Uint8Array) => void): (op: number, buffer: number, length: number) => void {
    return (op: number, buf: number, length: number) => {
      callback(this.wasm.HEAPU8.slice(buf, buf + length));
    };
  }

  public updateNumberCallback(): (op: number, updateNumber: number, length: number) => void {
    return (op: number, updateNumber: number, length: number) => {
      const temp = new Uint8Array(length * 4);
      temp.set(this.wasm.HEAPU8.subarray(updateNumber, updateNumber + length * 4));
      this._zone.runGuarded(() => {
        setTimeout(() => {
          this.updateNumberSubject.next([op, new Uint32Array(temp.buffer)]);
        });
      });
    };
  }

  public heartbeatCallback(): (op: number, low: number, high: number) => void {
    return (op: number, low: number, high: number) => {
      if (this) {
        if (low < 0) {
          low = 4294967296 + low;
        }
        if (high < 0) {
          high = 4294967296 + high;
        }
        this._zone.runGuarded(() => {
          this.heartbeatSubject.next([op, low, high]);
        });
      }
    };
  }

  public basePriceCallback(): (op: number, issueCodeId: number) => void {
    return (op: number, issueCodeId: number) => {
      this._zone.runGuarded(() => {
        if (this) {
          this.basePriceSubject.next([op, issueCodeId]);
        }
      });
    };
  }

  public authErrorCallback(): (op: number) => void {
    return (op: number) => {
      this._zone.runGuarded(() => {
        if (this) {
          this.authErrorSubject.next(op);
        }
      });
    };
  }

  public marketFinishedCallback(): (op: number) => void {
    return (op: number) => {
      this._zone.runGuarded(() => {
        this.marketFinishedSubject.next(op);
      });
    };
  }

  public push(id: FlexOperatorId, buf: Uint8Array) {
    let ofs = 0;
    while (ofs < buf.length) {
      const sub = buf.subarray(ofs, ofs + FlexOperatorService.BUFFER_SIZE);
      this.wasm.HEAPU8.set(sub, this.buf);
      this.wasm._push(id, this.buf, sub.length);
      ofs += sub.length;
    }
  }

  public pushWs(id: FlexOperatorId, buf: Uint8Array): [number, number] {
    if (!this.bufWsBuf) {
      this.bufWsBuf = this.malloc(64);
    }
    this.wasm.HEAPU8.set(buf, this.buf);
    this.wasm._pushWs(id, this.buf, buf.length, this.bufWsBuf, this.bufWsBuf + 8);
    const u32 = new Uint32Array(this.wasm.HEAPU8.buffer);
    const time = u32[this.bufWsBuf / 4] + u32[this.bufWsBuf / 4 + 1] * 4294967296;
    const count = u32[this.bufWsBuf / 4 + 2] + u32[this.bufWsBuf / 4 + 3] * 4294967296;
    return [time, count];
  }

  private portfolioFromBuf(portfolio: Portfolio, buf: Uint32Array) {
    portfolio.issueCode = buf[0];
    portfolio.lastPrice10 = buf[1];
    portfolio.quotePrice10 = buf[2];
    portfolio.predictPrice10 = buf[3];
    portfolio.openPrice10 = buf[4];
    for (let i = 0; i < OHLC_LENGTH; i++) {
      portfolio.lastPrices10[i] = buf[5 + i];
    }
    portfolio.volume = buf[OHLC_LENGTH + 5] + buf[OHLC_LENGTH + 6] * 4294967296;
    portfolio.turnover10 = buf[OHLC_LENGTH + 7] + buf[OHLC_LENGTH + 8] * 4294967296;
    portfolio.predict = buf[OHLC_LENGTH + 9] !== 0;
    // portfolio.hitType = buf[70];
    // portfolio.hitTime = buf[71] + buf[72] * 4294967296;
    const qr = this.qrm.getLatestQR(buf[OHLC_LENGTH + 10]);
    if (qr) {
      portfolio.hitType = qr.type;
      portfolio.hitTime = qr.timestamp;
      portfolio.lastQRPrice10 = qr.price10;
    } else {
      portfolio.hitType = 0;
      portfolio.hitTime = 0;
      portfolio.lastQRPrice10 = 0;
    }
    portfolio.specialQuote = buf[OHLC_LENGTH + 11];
    portfolio.specialQuoteSide = <Side>buf[OHLC_LENGTH + 12];
    portfolio.specialQuoteTime = buf[OHLC_LENGTH + 13] + buf[OHLC_LENGTH + 14] * 4294967296;
    portfolio.issueStatus = buf[OHLC_LENGTH + 15];
    portfolio.quoteFlag = buf[OHLC_LENGTH + 16];
    portfolio.quoteSide = buf[OHLC_LENGTH + 17];
    portfolio.qrCount = this.qrm.size(buf[OHLC_LENGTH + 10]);
    portfolio.bidPrice10 = buf[OHLC_LENGTH + 18];
    portfolio.askPrice10 = buf[OHLC_LENGTH + 19];
    portfolio.highPrice10 = buf[OHLC_LENGTH + 20];
    portfolio.lowPrice10 = buf[OHLC_LENGTH + 21];
  }

  private consumePortfolioBuf() {
    if (this.portfolioBuf === null) {
      // this.portfolioBuf = this.malloc(PORTFOLIO_SIZE * MAX_STOCK_COUNT);
      if (PORTFOLIO_SIZE * MAX_STOCK_COUNT > FlexOperatorService.BUFFER_SIZE) {
        throw new Error('Too large portfolio buf');
      }
      this.portfolioBuf = this.buf;
    }
  }

  private consumeIssueCodesBuf() {
    if (this.issueCodeBuf === null) {
      if (4 * MAX_STOCK_COUNT > FlexOperatorService.BUFFER_SIZE) {
        throw new Error('Too large issueCode buf');
      }
      this.issueCodeBuf = this.malloc(4 * MAX_STOCK_COUNT);
    }
  }

  public getPortfolio(id: FlexOperatorId, issueCode: number, portfolio: Portfolio = null): [boolean, Portfolio] {
    if (portfolio === null) {
      portfolio = new Portfolio(OHLC_LENGTH);
    }
    this.consumePortfolioBuf();
    const ret = this.wasm._getPortfolio(id, issueCode, this.portfolioBuf);
    const buf = new Uint32Array(this.wasm.HEAPU8.buffer).subarray(this.portfolioBuf / 4, this.portfolioBuf / 4 + PORTFOLIO_SIZE / 4);
    console.assert(buf[0] === issueCode);
    this.portfolioFromBuf(portfolio, buf);
    return [ret, portfolio];
  }

  public getStockView(id: FlexOperatorId, stockId: number, view: StockView): boolean {
    if (this.stockViewBuf === null) {
      this.stockViewBuf = this.buf;
    }
    const ret = this.wasm._getStockView(id, stockId, this.stockViewBuf);
    const buf = new Uint32Array(this.wasm.HEAPU8.buffer).subarray(this.stockViewBuf / 4, this.stockViewBuf / 4 + STOCK_VIEW_SIZE / 4);
    view.lastPrice10 = buf[1];
    view.openPrice10 = buf[2];
    view.highPrice10 = null;
    view.lowPrice10 = null;
    for (let i = 0; i < OHLC_LENGTH; i++) {
      view.ohlc[i].openPrice10 = buf[3 + 6 * i + 0];
      view.ohlc[i].highPrice10 = buf[3 + 6 * i + 1];
      view.ohlc[i].lowPrice10 = buf[3 + 6 * i + 2];
      view.ohlc[i].closePrice10 = buf[3 + 6 * i + 3];
      view.ohlc[i].turnover10 = buf[3 + 6 * i + 4] + buf[3 + 6 * i + 5] * 4294967296;
      if (view.highPrice10 === null && view.ohlc[i].highPrice10) {
        view.highPrice10 = view.ohlc[i].highPrice10;
      } else if (view.highPrice10 && view.ohlc[i].highPrice10) {
        view.highPrice10 = Math.max(view.highPrice10, view.ohlc[i].highPrice10);
      }
      if (view.lowPrice10 === null && view.ohlc[i].lowPrice10) {
        view.lowPrice10 = view.ohlc[i].lowPrice10;
      } else if (view.lowPrice10 && view.ohlc[i].lowPrice10) {
        view.lowPrice10 = Math.min(view.lowPrice10, view.ohlc[i].lowPrice10);
      }
    }
    view.volume = buf[OHLC_SIZE + 3] + buf[OHLC_SIZE + 4] * 4294967296;
    view.turnover10 = buf[OHLC_SIZE + 5] + buf[OHLC_SIZE + 6] * 4294967296;
    view.bidPrice10 = buf[OHLC_SIZE + 7];
    view.askPrice10 = buf[OHLC_SIZE + 8];
    view.predictPrice10 = buf[OHLC_SIZE + 9];
    view.predictVolume = buf[OHLC_SIZE + 10] + buf[OHLC_SIZE + 11] * 0x100000000;
    view.predictTurnover10 = view.predictPrice10 * view.predictVolume;
    view.predictSide = buf[OHLC_SIZE + 12];
    view.predictClosePrice10 = buf[OHLC_SIZE + 13];
    view.predictCloseVolume = buf[OHLC_SIZE + 14] + buf[OHLC_SIZE + 15] * 0x100000000;
    view.predictCloseSide = buf[OHLC_SIZE + 16];
    view.predictCloseTurnover10 = view.predictClosePrice10 * view.predictCloseVolume;

    view.timestamp = buf[OHLC_SIZE + 17] + buf[OHLC_SIZE + 18] * 4294967296;
    view.frame = buf[OHLC_SIZE + 19];
    view.maxFrame = buf[OHLC_SIZE + 20];
    view.vwap10 = view.volume === 0 ? 0 : view.master.tick.roundPrice(view.turnover10 / view.volume);
    view.quoteFlag = buf[OHLC_SIZE + 21];
    view.quoteSide = buf[OHLC_SIZE + 22];
    view.specialQuoteTime = buf[OHLC_SIZE + 23] + buf[OHLC_SIZE + 24] * 4294967296;

    view.predictLastPrice.lastPrice10 = buf[OHLC_SIZE + 25];
    view.predictLastPrice.predict = buf[OHLC_SIZE + 26] !== 0;
    view.predictLastPrice.quoteFlag = buf[OHLC_SIZE + 27] & 15;
    view.predictLastPrice.quoteSide = buf[OHLC_SIZE + 27] >> 4;
    view.predictLastPrice.turnover10 = buf[OHLC_SIZE + 28] + buf[OHLC_SIZE + 29] * 4294967296;
    view.predictLastPrice.volume = buf[OHLC_SIZE + 30] + buf[OHLC_SIZE + 31] * 4294967296;

    view.predictOpenPrice10 = buf[OHLC_SIZE + 32];

    view.renewalUpRange10 = buf[OHLC_SIZE + 33];
    view.renewalDownRange10 = buf[OHLC_SIZE + 34];
    view.auctionPrice10 = buf[OHLC_SIZE + 35];

    view.hasNextFrame = (buf[OHLC_SIZE + 36] & 1) === 1;
    view.hasPreviousFrame = (buf[OHLC_SIZE + 36] & 2) === 2;

    return ret;
  }

  public getPortfolios(id: FlexOperatorId, issueCode: Array<number>, portfolio: Array<Portfolio>): boolean {
    this.consumePortfolioBuf();
    this.consumeIssueCodesBuf();
    const issueCodeBuf = new Uint32Array(this.wasm.HEAPU8.buffer, this.issueCodeBuf, issueCode.length);
    for (let i = 0; i < issueCode.length; i++) {
      issueCodeBuf[i] = issueCode[i];
    }

    const ret = this.wasm._getPortfolios(id, this.issueCodeBuf, issueCode.length, this.portfolioBuf, 0);
    if (!ret) {
      return false;
    }
    for (let i = 0; i < portfolio.length; i++) {
      this.portfolioFromBuf(
        portfolio[i],
        new Uint32Array(this.wasm.HEAPU8.buffer).subarray(
          (this.portfolioBuf + PORTFOLIO_SIZE * i) / 4,
          (this.portfolioBuf + PORTFOLIO_SIZE * (i + 1)) / 4
        )
      );
    }
    return true;
  }

  public getAllPortfolio(id: FlexOperatorId, portfolio: Array<Portfolio>): boolean {
    this.consumePortfolioBuf();
    const ret = this.wasm._getPortfolios(id, 0, 0, this.portfolioBuf, 1);
    if (!ret) {
      return false;
    }
    if (portfolio.length !== this.stocksCount(id)) {
      throw new ArgumentOutOfRangeError();
    }
    const arr = new Uint32Array(this.wasm.HEAPU8.buffer);
    for (let i = 0; i < portfolio.length; i++) {
      this.portfolioFromBuf(
        portfolio[i],
        arr.subarray((this.portfolioBuf + PORTFOLIO_SIZE * i) / 4, (this.portfolioBuf + PORTFOLIO_SIZE * (i + 1)) / 4)
      );
    }
    return true;
  }

  public getStockMaster(id: FlexOperatorId): Array<StockMaster> {
    const MasterSize = 144;
    const count = this.stocksCount(id);
    if (count * MasterSize > FlexOperatorService.BUFFER_SIZE) {
      throw new Error('Too small buffer');
    }
    const buf = this.buf;
    const stocks = [];
    const decoder = new TextDecoder('utf-8');
    this.wasm._getStockMaster(id, buf, count * MasterSize);
    for (let i = 0; i < count; i++) {
      const stock = new StockMaster();
      const data = new Uint32Array(this.wasm.HEAPU8.buffer).subarray((buf + MasterSize * i) / 4, (buf + MasterSize * i + MasterSize) / 4);
      stock.id = i;
      stock.issueCode = data[0];
      stock.tickType = data[1];
      stock.basePrice10 = data[2];
      stock.limitUp10 = data[3];
      stock.limitDown10 = data[4];
      stock.lotSize = data[5] + data[6] * 0x100000000;
      stock.issueType = data[7];
      stock.industryCode = data[8];
      stock.loanMarginFlag = data[9];
      stock.indexFlag = data[10];
      stock.name = decoder.decode(this.wasm.HEAPU8.subarray(buf + MasterSize * i + 68, buf + MasterSize * i + 68 + data[16]));
      stock.prefix = decoder.decode(this.wasm.HEAPU8.subarray(buf + MasterSize * i + 112, buf + MasterSize * i + 112 + data[27]));
      stock.showPrefix = data[31] !== 0;

      stock.maxItaRowCount = data[32];
      stock.isNewListed = data[33] !== 0;
      stock.seiriKanriFlag = data[34];
      stock.exRightFlag = data[35];

      stocks.push(stock);
    }
    return stocks;
  }

  public stocksCount(id: FlexOperatorId): number {
    return this.wasm._stockCount(id);
  }

  public addSummary(id: FlexOperatorId): number {
    return this.wasm._addSummary(id);
  }

  public updateSummary(id: FlexOperatorId, summary: Summary): boolean {
    const count = Object.keys(summary.issueCodes).length;
    return this.prepareBuffer(count * 24, (buf) => {
      const b = new Uint32Array(this.wasm.HEAPU8.buffer, buf, 3 * count);
      let index = 0;
      for (const key of Object.keys(summary.issueCodes)) {
        b[index++] = Number(key);
        b[index++] = summary.issueCodes[key] % 0x100000000;
        b[index++] = Math.trunc(summary.issueCodes[key] / 0x100000000);
      }
      return this.wasm._updateSummary(id, summary.id, buf, count) !== 0;
    });
  }

  public applyBasePriceQueue(id: FlexOperatorId) {
    this.wasm._applyBasePriceQueue(id);
  }

  public getSummaryPortfolio(
    id: FlexOperatorId,
    summaryId: number,
    portfolio: SummaryPortfolio,
    timestamp: number
  ): [boolean, SummaryPortfolio] {
    if (portfolio === null) {
      portfolio = new SummaryPortfolio();
    }
    const PortfolioSize = 60;
    if (this.summaryPortfolioBuf === null) {
      if (FlexOperatorService.BUFFER_SIZE < PortfolioSize) {
        throw new Error('Too small buffer');
      }
      this.summaryPortfolioBuf = this.buf;
    }
    this.wasm._getSummaryPortfolio(id, summaryId, this.summaryPortfolioBuf, timestamp);
    const f = new Float64Array(this.wasm.HEAPU8.buffer, this.summaryPortfolioBuf, PortfolioSize / 8);
    const u32 = new Uint32Array(this.wasm.HEAPU8.buffer, this.summaryPortfolioBuf, PortfolioSize / 4);
    portfolio.lastPriceChange = Number.isNaN(f[0]) ? null : f[0];
    portfolio.vwapChange = Number.isNaN(f[1]) ? null : f[1];
    portfolio.openPriceBasePriceChange = Number.isNaN(f[2]) ? null : f[2];
    portfolio.hitCount = u32[6];
    portfolio.liftCount = u32[7];
    portfolio.volume = u32[8] + u32[9] * 0x100000000;
    portfolio.turnover10 = u32[10] + u32[11] * 0x100000000;
    portfolio.predict = (u32[12] & 1) !== 0;
    portfolio.predictOpenPrice = (u32[12] & 2) !== 0;
    portfolio.turnoverPredict = (u32[12] & 4) !== 0;
    portfolio.upCountPredict = (u32[12] & 8) !== 0;
    portfolio.downCountPredict = (u32[12] & 16) !== 0;
    portfolio.vwapPredict = (u32[12] & 32) !== 0;
    portfolio.upCount = u32[13];
    portfolio.downCount = u32[14];
    return [true, portfolio];
  }

  private parse64bit(array: Uint32Array, offset = 0): number {
    if (this.useAsmJS) {
      this._floatParseArray32[0] = array[offset];
      this._floatParseArray32[1] = array[offset + 1];
      return this._floatParseArray[0];
    } else {
      return array[offset] + array[offset + 1] * 0x100000000;
    }
  }

  private parseItaRowItem(item: ItaRowItem, array: Uint32Array, offset = 0) {
    item.quantity = this.parse64bit(array, offset);
    item.normalizedQuantity = this.parse64bit(array, offset + 2);
    item.order = this.parse64bit(array, offset + 4);
    item.delta = array[offset + 6];
    item.total = this.parse64bit(array, offset + 7);
    item.normalizedTotal = this.parse64bit(array, offset + 9);
  }

  private parseItaRow(row: ItaRow, array: Uint32Array, offset = 0) {
    row.price10 = array[offset];
    row.fillQuantity = this.parse64bit(array, offset + 1);
    row.normalizedFillQuantity = this.parse64bit(array, offset + 3);
    this.parseItaRowItem(row.bid, array, offset + 5);
    this.parseItaRowItem(row.bidClose, array, offset + 16);
    row.bidState = array[offset + 27];
    this.parseItaRowItem(row.ask, array, offset + 28);
    this.parseItaRowItem(row.askClose, array, offset + 39);
    row.askState = array[offset + 50];
  }

  public getItaRow(
    id: FlexOperatorId,
    stockHandler: number,
    price10: number,
    count: number,
    rows: Array<ItaRow>,
    market: ItaRow,
    over: ItaRow,
    under: ItaRow,
    miniMode = false
  ): boolean {
    if (FlexOperatorService.BUFFER_SIZE < (count + 3) * ITA_ROW_SIZE) {
      throw new Error('Too small buffer');
    }
    // if (this.itaRowBuf === null) {
    //   this.itaRowBuf = this.malloc((count + 3) * ITA_ROW_SIZE);
    //   this.itaRowBufSize = count + 3;
    // } else if (this.itaRowBufSize < count + 3) {
    //   this.wasm._free(this.itaRowBuf);
    //   this.itaRowBuf = this.malloc((count + 3) * ITA_ROW_SIZE);
    // }
    this.itaRowBuf = this.buf;
    const ret = this.wasm._getItaRows(
      id,
      stockHandler,
      price10,
      count,
      this.itaRowBuf + 3 * ITA_ROW_SIZE,
      this.itaRowBuf,
      this.itaRowBuf + ITA_ROW_SIZE,
      this.itaRowBuf + ITA_ROW_SIZE * 2,
      miniMode ? 1 : 0
    );
    if (!ret) {
      return false;
    }
    const bufArr = new Uint32Array(this.wasm.HEAPU8.buffer, this.itaRowBuf, (ITA_ROW_SIZE / 4) * (count + 3));
    for (let i = 0; i < count; i++) {
      this.parseItaRow(rows[i], bufArr, (i + 3) * (ITA_ROW_SIZE / 4));
    }
    this.parseItaRow(market, bufArr, 0);
    this.parseItaRow(over, bufArr, ITA_ROW_SIZE / 4);
    this.parseItaRow(under, bufArr, (2 * ITA_ROW_SIZE) / 4);
    return true;
  }

  public getQR(id: FlexOperatorId, stockHandler: number, lastFrameNumber: number): Array<QRRow> {
    if (!this.useQRM) {
      const ret: Array<QRRow> = [];
      if (this.qrRowBuf === null) {
        this.qrRowBuf = this.malloc(QR_ROW_SIZE * QR_ROW_MAX);
      }
      let count: number;
      do {
        count = this.wasm._getQR(id, stockHandler, lastFrameNumber, this.qrRowBuf, QR_ROW_MAX);
        const qrBuf = new Uint32Array(this.wasm.HEAPU8.buffer, this.qrRowBuf, count * QR_ROW_SIZE);
        for (let i = 0; i < count; i++) {
          const row = new QRRow();
          row.type = qrBuf[i * 7];
          row.timestamp = qrBuf[i * 7 + 1] + qrBuf[i * 7 + 2] * 0x100000000;
          row.price10 = qrBuf[i * 7 + 3];
          row.quantity = qrBuf[i * 7 + 4] + qrBuf[i * 7 + 5] * 0x100000000;
          row.frameNumber = qrBuf[i * 7 + 6];
          lastFrameNumber = row.frameNumber;
          ret.push(row);
        }
      } while (count === QR_ROW_MAX);
      return ret;
    } else {
      const stockPtr = this.wasm._getStockPointer(id, stockHandler);
      return this.qrm.getQR(stockPtr, lastFrameNumber);
    }
  }

  private getUint32(u8: Uint8Array, ofs: number) {
    return u8[ofs] + u8[ofs + 1] * 0x100 + u8[ofs + 2] * 0x10000 + u8[ofs + 3] * 0x1000000;
  }

  public unserialize(id: FlexOperatorId, u8: Uint8Array) {
    const bufSize = FlexOperatorService.BUFFER_SIZE;
    const buf = this.buf;
    let sz = 0;
    let ofs = 0;
    let issueId = 0;
    let issueCount = 0;
    for (let i = 0; i < u8.length; ) {
      const curSize = this.getUint32(u8, i);
      if (sz + curSize > bufSize) {
        this.wasm.HEAPU8.set(u8.subarray(ofs, ofs + sz), buf);
        this.wasm._unserialize(id, buf, sz, issueId, issueId + issueCount);
        ofs += sz;
        issueId += issueCount;
        issueCount = 0;
        sz = 0;
      }
      sz += curSize;
      issueCount++;
      i += curSize;
    }
    this.wasm.HEAPU8.set(u8.subarray(ofs, ofs + sz), buf);
    this.wasm._unserialize(id, buf, sz, issueId, issueId + issueCount);
  }

  public getFrameNumbers(id: FlexOperatorId): Uint32Array {
    const count = this.stocksCount(id);
    const buf = this.buf;
    this.wasm._getFrameNumbers(id, buf, count);
    const ret = new Uint32Array(count);
    const u32 = new Uint32Array(this.wasm.HEAPU8.buffer, buf, count);
    ret.set(u32);
    return ret;
  }

  private malloc(size: number): number {
    return this.wasm._malloc(size);
  }

  public fitItaViewRowPrice10(id: FlexOperatorId, stockHandler: number, price10: number, rowCount: number) {
    return this.wasm._fitItaViewRowPrice10(id, stockHandler, price10, rowCount);
  }

  public fitItaViewRowPriceIndex(id: FlexOperatorId, stockHandler: number, priceIndex: number, rowCount: number) {
    return this.wasm._fitItaViewRowPriceIndex(id, stockHandler, priceIndex, rowCount);
  }

  public tickPrice10ToIndex(id: FlexOperatorId, tick: number, price10: number) {
    return this.wasm._getTickPrice10ToIndex(id, tick, price10);
  }

  public tickIndexToPrice10(id: FlexOperatorId, tick: number, index: number) {
    return this.wasm._getTickIndexToPrice10(id, tick, index);
  }

  public apiRecieved(id: FlexOperatorId) {
    return this.wasm._apiRecieved(id);
  }

  public cloneStockView(id: FlexOperatorId, view: StockView): number {
    return this.wasm._cloneStock(id, view.id);
  }

  public goUpdateNumber(id: FlexOperatorId, view: StockView, updateNumber: number): number {
    return this.wasm._goUpdateNumber(id, view.id, updateNumber);
  }

  public getRenewalPrice(id: FlexOperatorId, price10: number) {
    return this.wasm._getRenewalTick10(id, 0, price10);
  }

  public getDate(id: FlexOperatorId): number {
    return this.wasm._getDate(id);
  }

  public getTime(id: FlexOperatorId): number {
    if (this.timestampBuf === null) {
      this.timestampBuf = this.buf;
    }
    this.wasm._getTime(id, this.timestampBuf);
    const tmp = new Uint32Array(this.wasm.HEAPU8.buffer, this.timestampBuf, 2);
    return tmp[0] + tmp[1] * 0x100000000;
  }

  public getSessionDone(id: FlexOperatorId): boolean {
    return this.wasm._getSessionDone(id) !== 0;
  }

  public addTrace(id: FlexOperatorId): number {
    return this.wasm._addTraceUpdate(id);
  }

  public clearTrace(id: FlexOperatorId, traceId: number) {
    return this.wasm._clearTrace(id, traceId);
  }

  public getTrace(id: FlexOperatorId, traceId: number): Uint16Array {
    if (this.traceBuf === null) {
      // this.traceBuf = this.wasm._malloc(2 * MAX_STOCK_COUNT);
      this.traceBuf = this.buf;
    }
    const count = this.wasm._getTrace(id, traceId, this.traceBuf, MAX_STOCK_COUNT);
    if (count > MAX_STOCK_COUNT || count < 0) {
      throw new Error('Traceの取得に失敗しました');
    }
    return new Uint16Array(this.wasm.HEAPU8.buffer, this.traceBuf, count).slice();
  }

  public hasTrace(id: FlexOperatorId, traceId: number, issueId: number): boolean {
    return this.wasm._hasTrace(id, traceId, issueId) !== 0;
  }

  public pushExceptionalSQ(
    id: FlexOperatorId,
    issueCode: number,
    side: number,
    range10: number,
    sec: number,
    limitDown10: number,
    limitUp10: number
  ): boolean {
    return this.wasm._pushSQ(id, issueCode, side, range10, sec, limitDown10, limitUp10) !== 0;
  }

  public pushBasePrice(id: FlexOperatorId, issueId: number, basePrice10: number, limitDown10: number, limitUp10: number) {
    this.wasm._pushBasePrice(id, issueId, basePrice10, limitDown10, limitUp10);
  }

  ngOnDestroy(): void {
    this.updateNumberSubject.complete();
    this.heartbeatSubject.complete();
  }
}
