import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { IExtendedGoldenLayoutConfig } from '@argentumcode/ngx-golden-layout';
import { LocalStorageService } from '@argentumcode/brisk-common';
import { asapScheduler, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, observeOn } from 'rxjs/operators';
export type LayoutType = 'ViewOnly' | 'Order';

export const SmartListAutoRefreshType = {
  Realtime: 'Realtime',
  Second5: 'Second5',
  Minute: 'Minute',
  Manual: 'Manual',
} as const;

export type SmartListAutoRefreshType = typeof SmartListAutoRefreshType[keyof typeof SmartListAutoRefreshType];

export interface PersistentLayout {
  // メインWindowのレイアウト
  mainWindowLayout?: IExtendedGoldenLayoutConfig;
  // フル板Windowのレイアウト
  fullItaWindowLayout?: IExtendedGoldenLayoutConfig;
}

export interface FirstTutorialState {
  fullItaButton?: boolean;
  newFullItaTabIcon?: boolean;
  // フル板におけるスクロールとダブルクリックのチュートリアルが完了しているか
  scrollTutorials?: boolean;
}

export interface PersistentStateInitialized280<OrderState> {
  initialized: true;

  // ステートのバージョン
  version: '2.8.0';

  // 最後に起動した時のレイアウトの種別
  lastLayoutType?: LayoutType;

  // 発注有効時のレイアウト
  layoutWithOrder: PersistentLayout;
  // 発注無効時のレイアウト
  //     発注有効と無効を切り替えた時にレイアウトが失なわれるのを避けるため。
  layoutViewOnly: PersistentLayout;

  // メインWindowのフォントサイズ
  mainWindowFontSize: string;
  // フル板Windowのフォントサイズ
  fullItaWindowFontSize: string;
  // テーマ
  theme: string;

  // 銘柄リスト一覧を幅広く表示するかどうか
  wideSummaryList?: boolean;

  // 非表示銘柄リスト
  invisibleSummary: Array<string>;

  // 銘柄閲覧利益
  issueCodeHistory: Array<number>;

  // 非推奨ブラウザダイアログのSKIP
  skipUnsupportedBrowserDialog?: boolean;

  // 発注関連のステート
  orderState?: OrderState;
}

export interface PersistentStateInitialized281<OrderState, ViewOnlyState> {
  initialized: true;

  // ステートのバージョン
  version: '2.8.1';

  // 最後に起動した時のレイアウトの種別
  lastLayoutType?: LayoutType;

  // 発注有効時のレイアウト
  layoutWithOrder: PersistentLayout;
  // 発注無効時のレイアウト
  //     発注有効と無効を切り替えた時にレイアウトが失なわれるのを避けるため。
  layoutViewOnly: PersistentLayout;

  // メインWindowのフォントサイズ
  mainWindowFontSize: string;
  // フル板Windowのフォントサイズ
  fullItaWindowFontSize: string;
  // テーマ
  theme: string;

  // 銘柄リスト一覧を幅広く表示するかどうか
  wideSummaryList?: boolean;

  // 非表示銘柄リスト
  invisibleSummary: Array<string>;

  // 銘柄閲覧利益
  issueCodeHistory: Array<number>;

  // 非推奨ブラウザダイアログのSKIP
  skipUnsupportedBrowserDialog?: boolean;

  // 効果音のミュート状態
  muteSound: boolean;
  // 効果音の音量
  soundVolume: number;

  // 初回ポップアップの状態
  firstTutorialState?: FirstTutorialState;

  // 発注関連のステート
  orderState?: OrderState;

  // 発注無効時のステート
  viewOnlyState?: ViewOnlyState;

  // デフォルトのスマートリストの更新間隔
  defaultSmartListAutoRefreshType?: SmartListAutoRefreshType;
}

export interface PersistentStateInitialized<OrderState, ViewOnlyState> {
  initialized: true;

  // ステートのバージョン
  version: '2.8.2';

  // 最後に起動した時のレイアウトの種別
  lastLayoutType?: LayoutType;

  // 発注有効時のレイアウト
  layoutWithOrder: PersistentLayout;
  // 発注無効時のレイアウト
  //     発注有効と無効を切り替えた時にレイアウトが失なわれるのを避けるため。
  layoutViewOnly: PersistentLayout;

  // メインWindowのフォントサイズ
  mainWindowFontSize: string;
  // フル板Windowのフォントサイズ
  fullItaWindowFontSize: string;
  // テーマ
  theme: string;

  // 銘柄リスト一覧を幅広く表示するかどうか
  wideSummaryList?: boolean;

  // 非表示銘柄リスト
  invisibleSummary: Array<string>;

  // 銘柄閲覧利益
  issueCodeHistory: Array<number>;

  // 非推奨ブラウザダイアログのSKIP
  skipUnsupportedBrowserDialog?: boolean;

  // 効果音のミュート状態
  muteSound: boolean;
  // 効果音の音量
  soundVolume: number;

  // 初回ポップアップの状態
  firstTutorialState?: FirstTutorialState;

  // 発注関連のステート
  orderState?: OrderState;

  // 発注無効時のステート
  viewOnlyState?: ViewOnlyState;

  // デフォルトのスマートリストの更新間隔
  defaultSmartListAutoRefreshType?: SmartListAutoRefreshType;

  // 適時開示遷移前ダイアログの表示ステート
  doNotShowTedinetDialog?: boolean;
}

type PersistentStateInitializedVersions<OrderState, ViewOnlyState> =
  | PersistentStateInitialized<OrderState, ViewOnlyState>
  | PersistentStateInitialized281<OrderState, ViewOnlyState>
  | PersistentStateInitialized280<OrderState>;
export type PersistentState<OrderState, ViewOnlyState> = PersistentStateInitialized<OrderState, ViewOnlyState> | { initialized: false };

export const PERSISTENT_STATE_KEY = 'fita4-state';

export class PersistentStateLoadError extends Error {
  origError?: any;
  constructor(message?: string, error?: any) {
    super(message);
    this.name = 'PersistentStateLoadError';
    this.origError = error;
  }
}

function initializedFilter<OrderState, ViewOnlyState>(
  s: PersistentState<OrderState, ViewOnlyState>
): s is PersistentStateInitialized<OrderState, ViewOnlyState> {
  return s.initialized;
}
function checkInitialized<OrderState, ViewOnlyState>(
  state: PersistentState<OrderState, ViewOnlyState>
): asserts state is PersistentStateInitialized<OrderState, ViewOnlyState> {
  if (!state.initialized) {
    if (isDevMode()) {
      console.error('not initialized', state);
    }
    throw new Error('State Not Initialized');
  }
}

function checkNotInitialized<OrderState, ViewOnlyState>(
  state: PersistentState<OrderState, ViewOnlyState>
): asserts state is { initialized: false } {
  if (state.initialized) {
    if (isDevMode()) {
      console.error('already initialized', state);
    }
    throw new Error('State Already Initialized');
  }
}

/*
 * 永続的な状態を管理するためのサービス
 * LocalStorageServiceを直接利用すると永続的なステートが分散し、バージョンアップが大変になるため、fita4では利用しない方針とした。
 *
 * ここで扱うステートは全てImmutableなものとする。
 */
@Injectable({
  providedIn: 'root',
})
export class PersistentStateService<OrderState = unknown, ViewOnlyState = unknown> implements OnDestroy {
  private _state: PersistentState<OrderState, ViewOnlyState> = { initialized: false };
  private _stateSubject = new BehaviorSubject(this._state);
  private _stateUpdating = false;
  private _stateToSave = false;

  state$ = this._stateSubject.asObservable();
  // 初期化後のState
  initializedState$ = this.state$.pipe(filter(initializedFilter));

  // Queries
  // テーマ
  theme$ = this.initializedState$.pipe(
    map((s) => s.theme),
    distinctUntilChanged()
  );

  mainWindowFontSize$ = this.initializedState$.pipe(
    map((s) => s.mainWindowFontSize),
    distinctUntilChanged()
  );

  fullItaWindowFontSize$ = this.initializedState$.pipe(
    map((s) => s.fullItaWindowFontSize),
    distinctUntilChanged()
  );

  mainPanelLayoutWithOrder$ = this.initializedState$.pipe(
    map((s) => s.layoutWithOrder.mainWindowLayout),
    distinctUntilChanged()
  );

  mainPanelLayoutViewOnly$ = this.initializedState$.pipe(
    map((s) => s.layoutViewOnly.mainWindowLayout),
    distinctUntilChanged()
  );

  fullItaWindowLayoutWithOrder$ = this.initializedState$.pipe(
    map((s) => s.layoutWithOrder.fullItaWindowLayout),
    distinctUntilChanged()
  );

  fullItaWindowLayoutViewOnly$ = this.initializedState$.pipe(
    map((s) => s.layoutViewOnly.fullItaWindowLayout),
    distinctUntilChanged()
  );

  issueCodeHistory$ = this.initializedState$.pipe(
    map((s) => s.issueCodeHistory),
    distinctUntilChanged()
  );

  orderState$ = this.initializedState$.pipe(
    map((s) => s.orderState),
    distinctUntilChanged()
  );

  viewOnlyState$ = this.initializedState$.pipe(
    map((s) => s.viewOnlyState),
    distinctUntilChanged()
  );

  invisibleSummary$ = this.initializedState$.pipe(
    map((s) => s.invisibleSummary),
    distinctUntilChanged()
  );

  wideSummaryList$ = this.initializedState$.pipe(
    map((s) => s.wideSummaryList),
    distinctUntilChanged()
  );

  skipUnsupportedBrowserDialog$ = this.initializedState$.pipe(
    map((s) => s.skipUnsupportedBrowserDialog || false),
    distinctUntilChanged()
  );

  muteSound$ = this.initializedState$.pipe(
    map((s) => s.muteSound),
    distinctUntilChanged()
  );

  soundVolume$ = this.initializedState$.pipe(
    map((s) => s.soundVolume),
    distinctUntilChanged()
  );

  firstTutorialFullItaWindow$ = this.initializedState$.pipe(
    map((s) => s.firstTutorialState?.fullItaButton || false),
    distinctUntilChanged()
  );

  firstTutorialNewFullItaTabIcon$ = this.initializedState$.pipe(
    map((s) => s.firstTutorialState?.newFullItaTabIcon ?? false),
    distinctUntilChanged()
  );

  firstTutorialScrollTutorials$ = this.initializedState$.pipe(
    map((s) => s.firstTutorialState?.scrollTutorials ?? false),
    distinctUntilChanged()
  );

  defaultSmartListAutoRefreshType$ = this.initializedState$.pipe(
    map((s) => s.defaultSmartListAutoRefreshType || SmartListAutoRefreshType.Second5),
    distinctUntilChanged()
  );

  doNotShowTedinetDialog$ = this.initializedState$.pipe(
    map((s) => s.doNotShowTedinetDialog),
    distinctUntilChanged()
  );

  constructor(private localStorage: LocalStorageService) {}

  private _persistent() {
    checkInitialized(this._state);
    this.localStorage.setItem(PERSISTENT_STATE_KEY, JSON.stringify(this._state));
  }

  private _updateState(nextState: PersistentState<OrderState, ViewOnlyState>) {
    if (this._stateUpdating) {
      if (isDevMode()) {
        console.error('Synchronous updating can cause unintended behavior. Consider using asapScheduler. See ReactiveX/rxjs#2155');
      } else {
        console.error('Synchronous updating');
      }
    }
    this._stateUpdating = true;
    this._state = nextState;
    this._stateToSave = true;
    this._stateSubject.next(this._state);
    this._stateUpdating = false;
  }

  // Service
  setupPersistentState(prefix: string) {
    checkNotInitialized(this._state);
    this.localStorage.setPrefix(prefix);

    // デモでは常にチュートリアルを表示する
    this.localStorage.removeItem('first_tutorial_ita_scroll');
    this.localStorage.removeItem('first_tutorial_ita_dblclick');

    const state = this.localStorage.getItem(PERSISTENT_STATE_KEY);
    try {
      if (state) {
        const jsonState: PersistentStateInitializedVersions<OrderState, ViewOnlyState> = { initialized: true, ...JSON.parse(state) };
        let initialState: PersistentStateInitialized<OrderState, ViewOnlyState>;
        if (jsonState.theme === 'ns') {
          jsonState.theme = 'black';
        }
        if (jsonState.version === '2.8.0') {
          initialState = {
            ...jsonState,
            version: '2.8.2',
            muteSound: false,
            soundVolume: 100,
            doNotShowTedinetDialog: false,
          };
        } else if (jsonState.version === '2.8.1') {
          initialState = {
            ...jsonState,
            version: '2.8.2',
            doNotShowTedinetDialog: false,
          };
        } else {
          initialState = jsonState;
        }
        this._updateState({
          ...initialState,
          firstTutorialState: {
            fullItaButton: false,
            newFullItaTabIcon: false,
            scrollTutorials: false,
          },
        });
      } else {
        const invisibleSummary = [
          'smartlist:lastPriceBasePriceChangeTop',
          'smartlist:lastPriceBasePriceChangeBottom',
          'smartlist:vwapTop',
          'smartlist:vwapBottom',
          'smartlist:openPriceBasePriceChangeTop',
          'smartlist:openPriceBasePriceChangeBottom',
        ]
          .map((id) => [id + 'Small', id + 'Medium', id + 'Large'])
          .reduce((a, c) => a.concat(c));
        this._updateState({
          initialized: true,
          version: '2.8.2',
          layoutWithOrder: {},
          layoutViewOnly: {},
          mainWindowFontSize: 's',
          fullItaWindowFontSize: 'xs',
          muteSound: false,
          soundVolume: 100,
          invisibleSummary,
          issueCodeHistory: [],
          theme: 'dark',
        });
      }
    } catch (e) {
      throw new PersistentStateLoadError(e);
    }

    // 状態の更新時に保存する
    // 同一のTask内での複数の更新を1回にまとめるために、asapSchedulerを利用
    // TODO: debounceTimeなどの利用の検討。複数Windowのタイマー問題があるため、一旦利用していない
    this.initializedState$.pipe(observeOn(asapScheduler)).subscribe(() => {
      if (this._stateToSave) {
        this._persistent();
      }
    });
  }

  updateTheme(theme: string) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      theme,
    });
  }

  updateMainWindowFontSize(fontSize: string) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      mainWindowFontSize: fontSize,
    });
  }
  updateFullItaWindowFontSize(fontSize: string) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      fullItaWindowFontSize: fontSize,
    });
  }

  updateMainWindowLayoutWithOrder(l: IExtendedGoldenLayoutConfig) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      layoutWithOrder: {
        ...this._state.layoutWithOrder,
        mainWindowLayout: l,
      },
    });
  }

  updateMainWindowLayoutViewOnly(l: IExtendedGoldenLayoutConfig) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      layoutViewOnly: {
        ...this._state.layoutViewOnly,
        mainWindowLayout: l,
      },
    });
  }

  updateFullItaWindowLayoutWithOrder(l: IExtendedGoldenLayoutConfig) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      layoutWithOrder: {
        ...this._state.layoutWithOrder,
        fullItaWindowLayout: l,
      },
    });
  }

  updateFullItaWindowLayoutViewOnly(l: IExtendedGoldenLayoutConfig) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      layoutViewOnly: {
        ...this._state.layoutViewOnly,
        fullItaWindowLayout: l,
      },
    });
  }

  updateIssueCodeHistory(issueCodeHistory: Array<number>) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      issueCodeHistory,
    });
  }

  updateOrderState(orderState: OrderState) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      orderState,
    });
  }

  updateViewOnlyState(viewOnlyState: ViewOnlyState) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      viewOnlyState,
    });
  }

  updateInvisibleSummary(invisibleSummary: Array<string>) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      invisibleSummary,
    });
  }

  updateWideSummaryList(wideSummaryList: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      wideSummaryList,
    });
  }

  updateSkipUnsupportedBrowserDialog(skipUnsupportedBrowserDialog: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      skipUnsupportedBrowserDialog,
    });
  }

  updateMuteSound(muteSound: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      muteSound,
    });
  }

  updateSoundVolume(soundVolume: number) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      soundVolume,
    });
  }

  updateFirstTutorialFullItaButton(shown: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      firstTutorialState: {
        ...this._state.firstTutorialState,
        fullItaButton: shown,
      },
    });
  }

  updateFirstTutorialNewFullItaTabButton(shown: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      firstTutorialState: {
        ...this._state.firstTutorialState,
        newFullItaTabIcon: shown,
      },
    });
  }

  updateFirstTutorialScrollTutorials(shown: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      firstTutorialState: {
        ...this._state.firstTutorialState,
        scrollTutorials: shown,
      },
    });
  }

  updateDefaultSmartListAutoRefreshType(defaultSmartListAutoRefreshType: SmartListAutoRefreshType) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      defaultSmartListAutoRefreshType,
    });
  }

  updateDoNotShowTedinetDialog(value: boolean) {
    checkInitialized(this._state);
    this._updateState({
      ...this._state,
      doNotShowTedinetDialog: value,
    });
  }

  ngOnDestroy() {
    this._stateSubject.complete();
  }
}
