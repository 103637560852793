import { InjectionToken } from '@angular/core';

export const FlexExchange = {
  Tokyo: 'Tokyo',
  Fukuoka: 'Fukuoka',
} as const;

export type FlexExchange = typeof FlexExchange[keyof typeof FlexExchange];

export interface FlexConfig {
  exchange: FlexExchange;
  ohlcLength: number;
  marketCloseTimestamp: number;
  fallbackIssueCode: number;
}

export const FLEX_CONFIG = new InjectionToken<FlexConfig>('FLEX_CONFIG');
