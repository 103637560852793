import { Injectable } from '@angular/core';
import { Color } from '@grapecity/wijmo';

const EPS = 1e-6;

@Injectable({
  providedIn: 'root',
})
export class ColorConverterService {
  constructor() {}

  public priceChangeToClass(priceChange: number): string {
    if (priceChange === null || priceChange === undefined) {
      return '';
    } else if (priceChange > EPS) {
      return 'u-price-color-positive';
    } else if (priceChange < -EPS) {
      return 'u-price-color-negative';
    } else {
      return '';
    }
  }

  public priceChangeToVariable(priceChange: number): string {
    if (priceChange === null || priceChange === undefined) {
      return '';
    } else if (priceChange > EPS) {
      return 'price-color-positive';
    } else if (priceChange < -EPS) {
      return 'price-color-negative';
    } else {
      return '';
    }
  }

  public priceChangeToBarColor(
    priceChange: number,
    thresholdUpColor: Color,
    thresholdDownColor: Color,
    upColor: Color,
    downColor: Color,
    zeroColor: Color,
    undefinedColor: Color
  ) {
    if (priceChange === undefined || priceChange === null || Number.isNaN(priceChange)) {
      return undefinedColor.toString();
    } else if (priceChange === 0) {
      return zeroColor.toString();
    }

    const threshold = 0.04;
    if (priceChange > 0) {
      const r = this.toRGB(
        priceChange > threshold ? thresholdUpColor.r : upColor.r + ((zeroColor.r - upColor.r) * (threshold - priceChange)) / threshold
      );
      const g = this.toRGB(
        priceChange > threshold ? thresholdUpColor.g : upColor.g + ((zeroColor.g - upColor.g) * (threshold - priceChange)) / threshold
      );
      const b = this.toRGB(
        priceChange > threshold ? thresholdUpColor.b : upColor.b + ((zeroColor.b - upColor.b) * (threshold - priceChange)) / threshold
      );
      return `#${r}${g}${b}`;
    } else {
      const r = this.toRGB(
        priceChange < -threshold
          ? thresholdDownColor.r
          : downColor.r + ((zeroColor.r - downColor.r) * (threshold + priceChange)) / threshold
      );
      const g = this.toRGB(
        priceChange < -threshold
          ? thresholdDownColor.g
          : downColor.g + ((zeroColor.g - downColor.g) * (threshold + priceChange)) / threshold
      );
      const b = this.toRGB(
        priceChange < -threshold
          ? thresholdDownColor.b
          : downColor.b + ((zeroColor.b - downColor.b) * (threshold + priceChange)) / threshold
      );
      return `#${r}${g}${b}`;
    }
  }

  private toRGB(val: number): string {
    if (val > 255) {
      val = 255;
    } else if (val < 0) {
      val = 0;
    }
    return ('00' + Math.trunc(val).toString(16)).slice(-2);
  }
}
