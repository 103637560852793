import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StockMaster, StockView } from '../flex';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SortDirection, VgStreamView } from '../vg-grid/collections/data-collecton';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { VgGridComponent } from '../vg-grid/public_api';
import { TimestampFormatPipe } from '../brisk-common/timestamp-format.pipe';
import { take } from 'rxjs/operators';

@Component({
  selector: 'brisk-qr-grid',
  templateUrl: './qr-grid.component.html',
  styleUrls: ['./qr-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrGridComponent implements OnInit, OnDestroy {
  public lastDayTurnover: number = null;

  @Input()
  public normalized = false;

  @Input()
  public shortTimestamp = false;

  @Input()
  public alignRight = false;

  @Input()
  get stockOperator(): StockWrapper {
    return this._stockOperator;
  }

  @Input()
  public forceSeparateComma = false;

  @Input()
  get shown() {
    return this._shown;
  }

  set shown(value) {
    if (this._shown !== value) {
      this._shown = value;
      if (this._shown) {
        this.update();
      }
    }
  }

  @Input()
  minWidthPx?: number;

  @Input()
  showAmount = true;

  private _shown = true;

  @ViewChild('grid', { static: true })
  private _grid: VgGridComponent;

  set stockOperator(value: StockWrapper) {
    if (this._stockOperator !== value) {
      if (this.refreshSubscription !== null) {
        this.refreshSubscription.unsubscribe();
        this.refreshSubscription = null;
      }
      this._stockOperator = value;
      this.clear();
      if (value) {
        this.lastDayTurnover = value.current.info.lastDayTurnover;
      } else {
        this.update();
      }
      if (value && value.updated) {
        this.refreshSubscription = value.updated.subscribe(() => {
          this.refresh();
        });
        this.refresh();
      }
    }
  }

  public get master(): StockMaster {
    return this.view && this.view.master;
  }

  private _stockOperator: StockWrapper;
  private refreshSubscription: Subscription = null;

  public get view(): StockView {
    return this.stockOperator && this.stockOperator.current;
  }

  public get live(): boolean {
    return !this.stockOperator || this.stockOperator.current === this.stockOperator.base;
  }

  private _qr: Array<any>;
  private _qrSubject = new BehaviorSubject<Array<any>>([]);

  public dataSource: VgStreamView = new VgStreamView(this._qrSubject, SortDirection.Desc);

  constructor(private timestampFormat: TimestampFormatPipe, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  update() {
    if (!this._shown) {
      return;
    }
    if (this.view) {
      this._qr = this.view.qr;
      this._qrSubject.next(this._qr);
    } else {
      this._qr = [];
      this._qrSubject.next(this._qr);
    }
    this.changeDetectorRef.markForCheck();
  }

  clear() {
    this._grid.scrollToTop();
  }

  refresh() {
    this.update();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    if (this._qrSubject) {
      this._qrSubject.complete();
    }
  }

  exportCsv(): void {
    if (!this.stockOperator || !this.view) {
      return;
    }
    const csv = this._createCsv();
    const filename = `qr-${this.view.master.issueCode}-${format(this.stockOperator.date, 'YYYYMMDD')}.csv`;

    // Excel対策にBOMを付ける
    const blob = new Blob([String.fromCharCode(0xfeff), csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename, true);
  }

  private _createCsv(): string {
    const fields = ['値段', '株数', '金額', '時刻'];
    const data = [];
    let csvString = '';
    const subscription = this.dataSource.items.pipe(take(1)).subscribe((items) => {
      for (let i = 0; i < items.length; i++) {
        const idx = this.dataSource.reverse ? items.length - i - 1 : i;
        const qr = items[idx];
        if (qr.price10 % 10 === 0) {
          data.push([qr.price, qr.quantity, qr.price * qr.quantity, this.timestampFormat.transform(qr.timestamp, { microseconds: false })]);
        } else if ((qr.price10 * qr.quantity) % 10 === 0) {
          data.push([
            qr.price.toFixed(1),
            qr.quantity,
            (qr.price10 * qr.quantity) / 10,
            this.timestampFormat.transform(qr.timestamp, { microseconds: false }),
          ]);
        } else {
          data.push([
            qr.price.toFixed(1),
            qr.quantity,
            ((qr.price10 * qr.quantity) / 10).toFixed(1),
            this.timestampFormat.transform(qr.timestamp, { microseconds: false }),
          ]);
        }
      }
      csvString = unparse({
        fields: fields,
        data: data,
      });
    });
    subscription.unsubscribe();
    return csvString;
  }
}
