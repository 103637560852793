<brisk-selectable-chart
  #selectableChart
  id="selectableChart"
  [chartSource]="chartSource | async"
  [type]="state?.chartType"
  (typeChange)="updateChartType($event)"
  [disableLocalStorage]="true"
  [normalized]="state?.normalized"
  (normalizedChange)="updateNormalized($event)"
  [showNk225Checkbox]="true"
  [showNk225]="state?.showNk225"
  (showNk225Change)="updateShowNk225($event)"
></brisk-selectable-chart>
