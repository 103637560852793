import { Injectable, Optional } from '@angular/core';
import { MainWindowService } from './main-window.service';
import { Fita4Service } from './fita4-core/fita4.service';

@Injectable({
  providedIn: 'root',
})
export class CellDoubleClickService {
  constructor(@Optional() private _mainWindow: MainWindowService, private fita4: Fita4Service) {}

  doubleClicked() {
    if (this._mainWindow) {
      this._mainWindow.focusToFullIta();
    }
    this.fita4.updatePendingFocusToMainPanel(true);
  }
}
