import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { GoldenLayoutComponent } from '@argentumcode/ngx-golden-layout';
import { ResizeService } from '@argentumcode/brisk-common';
import { asyncScheduler, Subscription } from 'rxjs';
import { debounceTime, take, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appFita4GoldenLayoutAutoResize]',
})
export class GoldenLayoutAutoResizeDirective implements OnDestroy {
  private _subscription: Subscription = new Subscription();
  constructor(private goldenLayoutComponent: GoldenLayoutComponent, private elementRef: ElementRef, private resize: ResizeService) {
    // Ignore window resize event
    this.goldenLayoutComponent.onResize = function () {};

    this._subscription.add(
      this.resize
        .observeElement(this.elementRef.nativeElement)
        .pipe(
          throttleTime(100, asyncScheduler, {
            leading: true,
            trailing: true,
          })
        )
        .subscribe((resizeEntry) => {
          if (resizeEntry.contentRect.width !== 0) {
            try {
              this.goldenLayoutComponent.getGoldenLayoutInstance().updateSize();
            } catch (ex) {}
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
