import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { StorageService, TutorialState } from '../core/storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { OverlayComponent } from './overlay/overlay.component';
import { TutorialStepComponent } from './tutorial-step/tutorial-step.component';
import { take } from 'rxjs/operators';
import { EventManager } from '@angular/platform-browser';
import { StockListViewMode } from '../portfolio/summary-list.service';
import { History as ViewHistory, LocalStorageService, ViewHistoryService } from '@argentumcode/brisk-common';
import { WatchList, WatchListService } from '../core/watch-list.service';

export class StateBackup {
  viewMode: StockListViewMode;
  defaultSelection2: number;
  history: Array<ViewHistory>;
  orderHistory: Array<number>;
  splitSize: number;
  invisibleSummary: Array<string>;
  watchLists: string;
}

export class TutorialStep {
  constructor(public readonly type: TutorialStepType, public readonly waitAction = false, public readonly final = false) {}

  initialize() {}
}

export class TutorialStepIta extends TutorialStep {
  scroll = false;
  dblClick = false;

  constructor(type: TutorialStepType, waitAction = false, final = false) {
    super(type, waitAction, final);
  }

  initialize() {
    this.scroll = false;
    this.dblClick = false;
  }
}

export class TutorialStepSwitch extends TutorialStep {
  ita = false;
  chart = false;

  constructor(type: TutorialStepType, waitAction = false, final = false) {
    super(type, waitAction, final);
  }

  initialize() {
    this.ita = false;
    this.chart = false;
  }
}

export class TutorialStepMainSplit extends TutorialStep {
  dragged = false;

  constructor(type: TutorialStepType, waitAction = false, final = false) {
    super(type, waitAction, final);
  }

  initialize() {
    this.dragged = false;
  }
}

// チュートリアルの各ステップ
export enum TutorialStepType {
  // チュートリアル未実行
  None,
  // Intro
  Introduction,
  // ピックアップ(大型)を選択する
  WaitSelectPickupLarge,

  // 銘柄リスト
  IssueList,
  // 板
  Ita,
  // メイン分割
  MainSplit,
  // フル板ボタン
  FullItaButton,
  // フル板ボタン
  FullItaButton2,
  // 板ビュー切り替え
  SwitchPortfolioView,
  // 板ビュー説明,
  ExplainItaView,
  // アウトロ
  Outroduction,
}

export class TutorialAction {}

export class TutorialActionSelectPickupLarge extends TutorialAction {}

export class TutorialActionDoubleClickIssue extends TutorialAction {}

export class TutorialActionFullItaBtnClick extends TutorialAction {}

export class TutorialActionItaDblClick extends TutorialAction {}

export class TutorialActionItaScroll extends TutorialAction {}

export class TutorialActionSwitchView extends TutorialAction {
  constructor(public type: string) {
    super();
  }
}

export class TutorialActionSplitMove extends TutorialAction {}

const steps = [
  new TutorialStep(TutorialStepType.Introduction),
  new TutorialStep(TutorialStepType.WaitSelectPickupLarge, true),

  new TutorialStep(TutorialStepType.IssueList, true),

  new TutorialStepIta(TutorialStepType.Ita, false),

  new TutorialStepMainSplit(TutorialStepType.MainSplit, false),

  new TutorialStep(TutorialStepType.FullItaButton, true),
  new TutorialStepSwitch(TutorialStepType.SwitchPortfolioView, false),
  //
  // new TutorialStep(
  //   TutorialStepType.FullItaButton2,
  //   true,
  // ),

  new TutorialStep(TutorialStepType.Outroduction, false, true),
];

export class TutorialStartEvent {}

export class TutorialStopEvent {
  constructor(public readonly skip: boolean) {}
}

@Injectable({
  providedIn: 'root',
})
export class TutorialService implements OnDestroy {
  // チュートリアル中か否か
  public tutorial = false;

  private _stepSubject = new BehaviorSubject<TutorialStep>(null);
  public step$ = this._stepSubject.asObservable();

  private _start = new Subject<TutorialStartEvent>();
  public start$ = this._start.asObservable();

  private _stop = new Subject<TutorialStopEvent>();
  public stop$ = this._stop.asObservable();

  private _overlay: OverlayComponent;
  private _stepComponent: TutorialStepComponent;

  private _stepIndex = 0;

  // タブキーの無効化イベントを削除するための関数
  private _enableTabKeyFunction: Function = null;

  constructor(
    private storage: StorageService,
    private zone: NgZone,
    private eventManager: EventManager,
    private history: ViewHistoryService,
    private localStorage: LocalStorageService,
    private watchList: WatchListService
  ) {}

  ngOnDestroy(): void {
    if (this._start) {
      this._start.complete();
    }
    if (this._overlay) {
      this._overlay.stop();
      this._overlay = null;
    }
    this.enableTabKey();
  }

  setOverlayComponent(overlay: OverlayComponent) {
    this._overlay = overlay;
  }

  setTutorialStepComponent(component: TutorialStepComponent) {
    this._stepComponent = component;
  }

  // チュートリアルを開始します。
  start() {
    this.onStart();
  }

  startOverlay(element?: Element) {
    this._overlay.start(element);
  }

  setTutorialStepLeft(element: Element) {
    this._stepComponent.setLeftArrow(element);
  }

  setTutorialStepTop(element: Element) {
    this._stepComponent.setTopArrow(element);
  }

  setTutorialStepBottom(element: Element) {
    this._stepComponent.setBottomArrow(element);
  }

  setTutorialCenter() {
    this._stepComponent.setCenter();
  }

  nextStep() {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.zone.runGuarded(() => {
        this._stepIndex++;
        steps[this._stepIndex].initialize();
        this._stepSubject.next(steps[this._stepIndex]);
      });
    });
  }

  skip() {
    this.onExit(true);
  }

  finish() {
    this.onExit(false);
  }

  dispatchAction(action: TutorialAction) {
    if (action instanceof TutorialActionSelectPickupLarge) {
      if (this._stepIndex === 1) {
        // TODO: Remove Magic Number
        this.nextStep();
      }
    } else if (action instanceof TutorialActionDoubleClickIssue) {
      if (this._stepIndex === 2) {
        // TODO: Remove Magic Number
        this.nextStep();
      }
    } else if (action instanceof TutorialActionFullItaBtnClick) {
      if (
        steps[this._stepIndex] &&
        (steps[this._stepIndex].type === TutorialStepType.FullItaButton || steps[this._stepIndex].type === TutorialStepType.FullItaButton2)
      ) {
        this.nextStep();
      }
    } else if (action instanceof TutorialActionItaDblClick) {
      if (steps[this._stepIndex] && steps[this._stepIndex].type === TutorialStepType.Ita) {
        (<TutorialStepIta>steps[this._stepIndex]).dblClick = true;
      }
    } else if (action instanceof TutorialActionItaScroll) {
      if (steps[this._stepIndex] && steps[this._stepIndex].type === TutorialStepType.Ita) {
        (<TutorialStepIta>steps[this._stepIndex]).scroll = true;
      }
    } else if (action instanceof TutorialActionSwitchView) {
      if (steps[this._stepIndex] && steps[this._stepIndex].type === TutorialStepType.SwitchPortfolioView) {
        if (action.type === 'ita') {
          (<TutorialStepSwitch>steps[this._stepIndex]).ita = true;
        } else if (action.type === 'chart') {
          (<TutorialStepSwitch>steps[this._stepIndex]).chart = true;
        }
      }
    } else if (action instanceof TutorialActionSplitMove) {
      if (steps[this._stepIndex] && steps[this._stepIndex].type === TutorialStepType.MainSplit) {
        (<TutorialStepMainSplit>steps[this._stepIndex]).dragged = true;
      }
    }
  }

  // チュートリアル開始時の処理
  private onStart() {
    this.watchList.disableSave = true;
    if (this.storage.tutorialState !== TutorialState.NewUser) {
      // 状態を保存する
      this.backupState();
    }
    // ウォッチリストの作成
    this.setupWatchList();

    // 初期化
    this.disableTabKey();

    // 開始処理
    this._stepIndex = 0;
    this._stepSubject.next(steps[this._stepIndex]);
    this._stepComponent.visible = true;
    this.tutorial = true;
    this._start.next(new TutorialStartEvent());
  }

  // チュートリアル終了時の処理
  private onExit(skip: boolean) {
    // イベントを削除する
    this.enableTabKey();

    // 終了処理
    this._overlay.enable = false;
    this._stepComponent.visible = false;
    this._stepIndex = steps.length;
    this.tutorial = false;
    this._stepSubject.next(null);
    // Storageの状態を復元する
    this.restoreState();
    this.watchList.loadFromLocalStorage();
    this.watchList.disableSave = false;

    this._stop.next(new TutorialStopEvent(skip));
    this.storage.tutorialBackup = null;
  }

  // タブキーによるコントロールの遷移を無効化する
  private disableTabKey() {
    if (this._enableTabKeyFunction) {
      return;
    }
    this._enableTabKeyFunction = this.eventManager.addGlobalEventListener('window', 'keydown', function (event: KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
      }
    });
  }

  // タブキーによるコントロールの遷移を有効化する
  private enableTabKey() {
    if (this._enableTabKeyFunction) {
      this._enableTabKeyFunction();
      this._enableTabKeyFunction = null;
    }
  }

  // 現在のステートをバックアップする
  private backupState() {
    // 既にバックアップがある場合は更新しない
    // → 前回チュートリアル後に終了しなかったということになるため。前回チュートリアルの終了状態で更新されるのを防ぐ
    if (this.storage.tutorialBackup) {
      const stateBackup: StateBackup = JSON.parse(this.storage.tutorialBackup);
      this.storage.tutorialBackup = JSON.stringify(stateBackup);
    } else {
      // バックアップを作成する
      const stateBackup = new StateBackup();
      stateBackup.viewMode = this.storage.viewMode;
      stateBackup.splitSize = this.storage.splitSize;
      stateBackup.defaultSelection2 = this.storage.getDefaultTabIndex(1);
      stateBackup.history = this.history.histories;
      stateBackup.orderHistory = this.history.orderHistory;
      stateBackup.invisibleSummary = this.storage.invisibleSummary;
      this.storage.tutorialBackup = JSON.stringify(stateBackup);
    }
  }

  private restoreState() {
    if (!this.storage.tutorialBackup) {
      return;
    }
    const stateBackup: StateBackup = JSON.parse(this.storage.tutorialBackup);
    this.storage.viewMode = stateBackup.viewMode;
    this.storage.splitSize = stateBackup.splitSize;
    this.storage.setDefaultTabIndex(1, stateBackup.defaultSelection2);
    this.storage.invisibleSummary = stateBackup.invisibleSummary;
    // Historyの復帰
    this.history.histories = stateBackup.history;
    this.history.orderHistory = stateBackup.orderHistory;

    this.watchList.loadFromLocalStorage();
    this.history.saveToLocalStorage();
  }

  private setupWatchList() {
    for (const wl of this.watchList.all()) {
      this.watchList.delete(wl.id);
    }
    const watchList = new WatchList({
      id: null,
      name: '銘柄リスト1',
      entries: [7203, 9432, 9984, 9437, 6758],
    });
    this.watchList.add(watchList);
  }
}
