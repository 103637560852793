import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  BriskDialogService,
  FirstTutorialComponent,
  InvalidIssueCodeEvent,
  LocalStorageService,
  Market,
  ResizeService,
  StockView,
  StockWrapper,
  SummaryType,
  ThemeService,
  Toast,
  ToasterService,
  ToastType,
  UserAgentService,
  ViewHistoryService,
} from '@argentumcode/brisk-common';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { asapScheduler, BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { ApiService } from 'fita3/src/app/core/api.service';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { environment } from 'fita3/src/environments/default/environment';
import { environment as fita4Env } from '../../../environments/default/environment';
import { MarketsService } from 'fita3/src/app/core/markets.service';
import { StockListViewMode } from 'fita3/src/app/portfolio/summary-list.service';
import { Title } from '@angular/platform-browser';
import { ParentWindowSyncService, proxyObservable } from '@argentumcode/multiple-window';
import { ORDER_OPERATOR_TOKEN, ORDER_UI_SERVICE_TOKEN, OrderOperator, OrderUiService } from '../../order/types/interfaces';
import { GoldenLayoutComponent, IExtendedGoldenLayoutConfig } from '@argentumcode/ngx-golden-layout';
import { SummaryListComponent } from 'fita3/src/app/portfolio/summary-list/summary-list.component';
import { SummaryListsService } from '../../summary-lists.service';
import { FontSizeService } from '../../font-size.service';
import { ComponentConfig, Config, ContentItem, ItemConfigType } from 'golden-layout';
import { MainWindowService } from '../../main-window.service';
import { PersistentStateService } from '../../fita4-core/persistent-state.service';
import { mergeMap, observeOn, take, takeWhile } from 'rxjs/operators';
import { Fita4Service } from '../../fita4-core/fita4.service';
import { CellDoubleClickService } from '../../cell-double-click.service';
import { DemoFinishedDialogService } from '../../shared-components/demo-finished-dialog.service';
import { commonDimensionParameters } from '../../golden-layout-utils';

type HelpEntry = { name: string; class?: string } & ({ link: string } | { onClick: string });

class GoldenLayoutParser {
  constructor(private layout: Config) {}

  removeItem(id: string) {
    this._remoteItemRec(id, this.layout.content[0]);
  }

  result(): Config {
    return this.layout;
  }

  private _remoteItemRec(id: string, c: ItemConfigType, parent?: ItemConfigType, parentIndex?: number): boolean {
    if (c.id === id) {
      return true;
    }
    if (c.content) {
      for (let i = 0; i < c.content.length; i++) {
        if (this._remoteItemRec(id, c.content[i], c, i)) {
          // 削除対象
          c.content.splice(i, 1);
          if (c.content.length !== 0 && c.type === 'stack') {
            if (c['activeItemIndex'] === i) {
              c['activeItemIndex'] = 0;
            }
          }
          if (c.content.length === 0) {
            return true;
          }
          if (c.type === 'row' || c.type === 'column') {
            if (c.content.length === 1 && parent) {
              parent.content[parentIndex] = c.content[0];
            }
          }
          return false;
        }
      }
    }
    return false;
  }
}

const INITIAL_LAYOUT_WITH_TRADE: IExtendedGoldenLayoutConfig = {
  settings: {
    showPopoutIcon: false,
    showMaximiseIcon: false,
    showCloseIcon: false,
  },
  dimensions: {
    minItemWidth: 100,
  },
  content: [
    {
      type: 'column',
      content: [
        {
          type: 'stack',
          content: [
            {
              type: 'component',
              componentName: 'Trade', // The name defined in componentTypes
              title: '取引',
              id: 'trade',
              componentState: {
                resetLayout: true,
              },
              isClosable: false,
            },
          ],
        },
        {
          type: 'stack',
          content: [
            {
              type: 'component',
              componentName: 'StockList', // The name defined in componentTypes
              title: '銘柄一覧',
              componentState: {
                active: true,
              },
              id: 'stockListMain',
              isClosable: false,
            },
          ],
        },
      ],
    },
  ],
};

const INITIAL_LAYOUT_WITHOUT_TRADE: IExtendedGoldenLayoutConfig = {
  settings: {
    hasHeaders: true,
    constrainDragToContainer: true,
    reorderEnabled: true,
    selectionEnabled: false,
    popoutWholeStack: false,
    blockedPopoutsThrowError: true,
    closePopoutsOnUnload: true,
    showPopoutIcon: false,
    showMaximiseIcon: false,
    showCloseIcon: false,
  },
  dimensions: {
    borderWidth: 5,
    minItemHeight: 10,
    minItemWidth: 10,
    headerHeight: 25,
    dragProxyWidth: 300,
    dragProxyHeight: 200,
  },
  labels: {
    close: 'close',
    maximise: 'maximise',
    minimise: 'minimise',
    popout: 'open in new window',
  },
  content: [
    {
      type: 'stack',
      isClosable: true,
      title: '',
      content: [
        {
          type: 'component',
          componentName: 'StockList',
          title: '銘柄一覧(高流動性100銘柄)',
          componentState: {
            active: true,
            uniqId: '0b861aa7-ccb6-4faa-9838-e60305000486',
            summaryId: 'index:2',
            config: {
              settings: {
                hasHeaders: true,
                constrainDragToContainer: true,
                reorderEnabled: true,
                selectionEnabled: false,
                popoutWholeStack: false,
                blockedPopoutsThrowError: true,
                closePopoutsOnUnload: true,
                showPopoutIcon: false,
                showMaximiseIcon: false,
                showCloseIcon: false,
                responsiveMode: 'onload',
                tabOverlapAllowance: 0,
                reorderOnTabMenuClick: true,
                tabControlOffset: 10,
              },
              dimensions: {
                borderWidth: 5,
                borderGrabWidth: 9,
                minItemHeight: 10,
                minItemWidth: 10,
                headerHeight: 25,
                dragProxyWidth: 300,
                dragProxyHeight: 200,
              },
              labels: {
                close: 'close',
                maximise: 'maximise',
                minimise: 'minimise',
                popout: 'open in new window',
                popin: 'pop in',
                tabDropdown: 'additional tabs',
              },
              content: [
                {
                  type: 'stack',
                  isClosable: true,
                  reorderEnabled: true,
                  title: '',
                  activeItemIndex: 1,
                  content: [
                    {
                      type: 'component',
                      title: '一覧',
                      componentName: 'StockListGrid',
                      isClosable: false,
                      reorderEnabled: true,
                      id: 'ec9bd0a4-e818-4f44-be6f-0fb944268a85',
                    },
                    {
                      type: 'component',
                      title: '板',
                      componentName: 'ItaGrid',
                      isClosable: false,
                      reorderEnabled: true,
                      id: 'c954cdaa-e0c9-46e9-80c2-e80150e4d115',
                    },
                    {
                      type: 'component',
                      title: 'チャート',
                      isClosable: false,
                      componentName: 'ChartGrid',
                      reorderEnabled: true,
                      id: '299647c7-6dff-46ce-a562-04520fd37102',
                    },
                    {
                      type: 'component',
                      title: 'マップ',
                      isClosable: false,
                      componentName: 'MapView',
                      id: 'MapView',
                      reorderEnabled: true,
                    },
                  ],
                },
              ],
              isClosable: true,
              reorderEnabled: true,
              title: '',
              openPopouts: [],
              maximisedItemId: null,
            },
            smartListAutoRefreshType: 'Second5',
          },
          id: 'stockListMain',
          isClosable: false,
        },
      ],
    },
  ],
};

@Component({
  selector: 'app-fita4-root',
  templateUrl: './main-window.component.html',
  styleUrls: ['./main-window.component.scss'],
})
export class MainWindowComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  get viewMode(): StockListViewMode {
    return this._viewMode;
  }

  set viewMode(value: StockListViewMode) {
    if (this._viewMode !== value) {
      this._viewMode = value;
      if (this._viewModeInitialized) {
        this.persistentState.updateWideSummaryList(value === StockListViewMode.DetailWide);
      }
    }
  }

  ViewMode = StockListViewMode;

  private initialStateChange = false;

  tradeShown = false;

  marketShown = false;

  orderInitialized = false;

  fita4Initialized = false;

  public normalized = false;

  @ViewChildren('fullItaButtonTutorial')
  public fullItaButtonTutorial: QueryList<FirstTutorialComponent>;

  watchListIssueCodes$: Observable<Set<number>>;

  private _viewMode: StockListViewMode = StockListViewMode.Detail;
  private _viewModeInitialized = false;

  private issueCodeSubscription: Subscription = null;
  private summaryChangedSubscription: Subscription = null;
  marketError$: Observable<Error>;

  private issueCodeBehavior = new BehaviorSubject<number>(null);

  private doNotShowTedinetDialog = false;

  public get stockOperator(): StockWrapper {
    return this.cocomero.stockWrapper;
  }

  public get view(): StockView {
    return this.cocomero.stockWrapper && this.cocomero.stockWrapper.base;
  }

  public get issueCode$(): Observable<number> {
    return this.issueCodeBehavior.asObservable();
  }

  public get jsfcOnly(): boolean {
    return environment.jsfcOnly;
  }

  public get showTrade(): boolean {
    return environment.showTrade;
  }

  public get itaSeparateComma(): boolean {
    return environment.itaSeparateComma;
  }

  public get publicBeta(): boolean {
    return environment.publicBeta;
  }

  public get orderButtonType(): 'none' | 'single' | 'monex' | 'triple' {
    return <any>environment.orderButtonType;
  }

  get isMac(): boolean {
    return this._userAgent.isMac;
  }

  get tedinetUrl(): string | null {
    return fita4Env.tedinetUrl;
  }

  get marketConditions(): Array<Market> {
    return this.cocomero.marketConditions;
  }

  get subWindowOpened(): boolean {
    return this._mainWindow.opened;
  }

  get helps(): Array<HelpEntry> {
    return fita4Env.helps;
  }

  @ViewChild(GoldenLayoutComponent, { static: false }) layout: GoldenLayoutComponent;
  layoutConfig$ = new ReplaySubject(1);

  @ViewChild('summaryList', { static: false })
  summaryListComponent: SummaryListComponent;

  orderEnabled = false;
  private _summaryListInitialized = false;

  showViewConfig = false;

  constructor(
    public cocomero: CocomeroService,
    private changeDetectorRef: ChangeDetectorRef,
    private api: ApiService,
    private chart: ChartService,
    private resize: ResizeService,
    private market: MarketsService,
    private localStorage: LocalStorageService,
    private theme: ThemeService,
    private summaryList: SummaryListsService,
    private history: ViewHistoryService,
    private _zone: NgZone,
    private _userAgent: UserAgentService,
    private _toaster: ToasterService,
    private _title: Title,
    private _fontSize: FontSizeService,
    private _parentWindowSync: ParentWindowSyncService,
    private _mainWindow: MainWindowService,
    private persistentState: PersistentStateService<unknown>,
    private fita4: Fita4Service,
    private dialog: BriskDialogService,
    private dblClick: CellDoubleClickService,
    private _demoFinishedDialog: DemoFinishedDialogService,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    @Inject(ORDER_UI_SERVICE_TOKEN) private orderUi: OrderUiService
  ) {
    this.fita4.initialized$.subscribe(() => {
      this.fita4Initialized = true;
      this.changeDetectorRef.markForCheck();
    });
    this._parentWindowSync.initialize();
    this.orderOperator.initialized.subscribe(() => {
      this.orderInitialized = true;
      this.changeDetectorRef.markForCheck();
    });
    this.cocomero.disableChangeIssueCodeThrottle();
    this.cocomero.disableStockUpdate = true;
    this._title.setTitle('BRiSK Next');
    this.issueCodeSubscription = this.cocomero.issueCodeChanged.subscribe(() => {
      const issueCode = this.cocomero.issueCode;

      this.changeDetectorRef.markForCheck();
      this.issueCodeBehavior.next(issueCode);
    });

    this.cocomero.sessionInitialized.subscribe(() => {
      this.persistentState.setupPersistentState(this.localStorage['prefix']);
    });

    this.persistentState.theme$.subscribe((t) => {
      this.theme.theme = t;
    });

    this.marketError$ = this.fita4.marketError$;

    persistentState.wideSummaryList$.pipe(observeOn(asapScheduler)).subscribe((wide) => {
      if (wide) {
        this.viewMode = StockListViewMode.DetailWide;
      } else {
        this.viewMode = StockListViewMode.Detail;
      }
      this._viewModeInitialized = true;
    });

    forkJoin([
      proxyObservable(orderOperator.initialized).pipe(
        mergeMap(() =>
          orderOperator.config.orderEnabled ? persistentState.mainPanelLayoutWithOrder$ : persistentState.mainPanelLayoutViewOnly$
        ),
        take(1)
      ),
      proxyObservable(orderOperator.initialized),
      this._fontSize.fontSizeLoaded$,
    ]).subscribe(([savedLayout]) => {
      this.orderEnabled = this.orderOperator.config.orderEnabled;
      if (savedLayout) {
        try {
          const layout = { ...savedLayout };
          if (layout) {
            layout.dimensions = {
              ...layout.dimensions,
              ...commonDimensionParameters,
              headerHeight: this._fontSize.glHeaderSize(),
            };
            this.layoutConfig$.next(layout);
          }
        } catch {
          this._toaster.add(new Toast('レイアウトの読み込みに失敗しました。初期レイアウトを利用します。'));
        }
      } else {
        this.layoutConfig$.next({
          ...(this.orderEnabled ? INITIAL_LAYOUT_WITH_TRADE : INITIAL_LAYOUT_WITHOUT_TRADE),
          dimensions: {
            ...commonDimensionParameters,
            headerHeight: this._fontSize.glHeaderSize(),
          },
        });
      }

      this._fontSize.fontSize$.subscribe(() => {
        if (this.layout) {
          this.layout.getGoldenLayoutInstance().config.dimensions.headerHeight = this._fontSize.glHeaderSize();
        }
      });
    });

    this.cocomero.notAvailableIssueCode$.subscribe((issueCode) => {
      this._toaster.add(new Toast(`銘柄コード ${issueCode} はBRiSKでは表示できません。`, ToastType.Error));
    });

    this.watchListIssueCodes$ = this.fita4.watchListIssueCodes$;

    this.persistentState.doNotShowTedinetDialog$.subscribe((doNotShowTedinetDialog) => {
      this.doNotShowTedinetDialog = doNotShowTedinetDialog;
    });
  }

  get initialized(): boolean {
    return this.cocomero.initialized;
  }

  ngAfterViewChecked() {
    if (this.summaryListComponent && !this._summaryListInitialized) {
      this._summaryListInitialized = true;
      this.summaryList.setSummaryItemGetter((item) => this.summaryListComponent.getSummaryPortfolio(item));
      this.summaryList.setFirstItemGetter(() => this.summaryListComponent.getFirstPortfolio());

      this.summaryList.newListItem.subscribe((item) => {
        this.openStockList(item);
      });
    }
  }

  ngOnInit() {
    this.resize.dispatchResize();
  }

  ngAfterViewInit() {
    combineLatest([this.persistentState.firstTutorialFullItaWindow$, this.fullItaButtonTutorial.changes])
      .pipe(takeWhile(([shown]) => !shown))
      .subscribe(() => {
        this.fullItaButtonTutorial.forEach((tutorial) => {
          tutorial.show();
        });
      });
  }

  ngOnDestroy() {
    if (this.issueCodeSubscription) {
      this.issueCodeSubscription.unsubscribe();
      this.issueCodeSubscription = null;
    }
    if (this.summaryChangedSubscription) {
      this.summaryChangedSubscription.unsubscribe();
      this.summaryChangedSubscription = null;
    }
    if (this.issueCodeBehavior) {
      this.issueCodeBehavior.complete();
    }
    this._mainWindow.ngOnDestroy();
  }

  onCellDblClicked() {
    this.dblClick.doubleClicked();
  }

  onSummarySelectionChanged() {}

  onStockSelected(issueCode: number) {
    this.cocomero.changeIssueCode(issueCode);
  }

  onInvalidInput(e: InvalidIssueCodeEvent) {
    if (!e.text) {
      return;
    }
    e.cancel = true;
    e.callback = this.dialog.openDialog(
      {
        content: `銘柄「${e.text}」が見つかりません。`,
        buttons: [
          {
            text: 'OK',
          },
        ],
      },
      { width: '30em' }
    );
  }

  toggleFullIta() {
    this._mainWindow.toggleFullIta();
  }

  toggleTradePanel() {
    if (this.layout) {
      const tradePanel = this.layout.getGoldenLayoutInstance().root.getItemsById('trade');
      if (tradePanel.length > 0) {
        tradePanel[0].remove();
      } else {
        const root = this.layout.getGoldenLayoutInstance().root.contentItems[0];
        if (root.isColumn) {
          root.addChild(
            {
              type: 'stack',
              content: [
                {
                  type: 'component',
                  componentName: 'Trade', // The name defined in componentTypes
                  title: '取引',
                  id: 'trade',
                  componentState: {},
                  isClosable: false,
                },
              ],
            },
            0
          );
        } else {
          const column = (this.layout.getGoldenLayoutInstance().createContentItem({
            type: 'column',
            width: root.config.width,
            height: root.config.height,
            content: [],
          }) as any) as ContentItem;
          column.addChild({
            type: 'stack',
            content: [
              {
                type: 'component',
                componentName: 'Trade', // The name defined in componentTypes
                title: '取引',
                id: 'trade',
                componentState: {},
                isClosable: false,
              },
            ],
          });
          root.parent.replaceChild(root, column);
          column.addChild(root, 1);
        }
      }
      this.tradeShown = this.layout.getGoldenLayoutInstance().root.getItemsById('trade').length > 0;
    }
  }

  toggleMarketPanel() {
    if (this.layout) {
      const marketPanel = this.layout.getGoldenLayoutInstance().root.getItemsById('marketList');
      if (marketPanel.length > 0) {
        marketPanel[0].remove();
      } else {
        const root = this.layout.getGoldenLayoutInstance().root.contentItems[0];
        if (root.isColumn) {
          root.addChild({
            type: 'stack',
            content: [
              {
                type: 'component',
                componentName: 'MarketList', // The name defined in componentTypes
                title: '場況一覧',
                id: 'marketList',
                isClosable: true,
              },
            ],
          });
        } else {
          const column = (this.layout.getGoldenLayoutInstance().createContentItem({
            type: 'column',
            width: root.config.width,
            height: root.config.height,
            content: [],
          }) as any) as ContentItem;
          column.addChild({
            type: 'stack',
            content: [
              {
                type: 'component',
                componentName: 'MarketList', // The name defined in componentTypes
                title: '場況一覧',
                id: 'marketList',
                isClosable: true,
              },
            ],
          });
          root.parent.replaceChild(root, column);
          column.addChild(root, 0);
        }
      }
      this.marketShown = this.layout.getGoldenLayoutInstance().root.getItemsById('marketList').length > 0;
    }
  }

  resetLayout() {
    this.dialog
      .openDialog(
        {
          content: 'レイアウトを初期化しますか。',
          buttons: [
            {
              text: 'はい',
              cssClass: 'c-btn c-btn--danger',
              result: 'yes',
            },
            {
              text: 'いいえ',
              cssClass: 'c-btn c-btn--default',
              result: 'no',
              initialFocus: true,
            },
          ],
        },
        {
          width: '30em',
        }
      )
      .subscribe((result) => {
        if (result.type === 'button' && result.value === 'yes') {
          this.layoutConfig$.next({
            ...(this.orderEnabled ? INITIAL_LAYOUT_WITH_TRADE : INITIAL_LAYOUT_WITHOUT_TRADE),
            dimensions: {
              ...commonDimensionParameters,
              headerHeight: this._fontSize.glHeaderSize(),
            },
          });
        }
      });
  }

  openStockList(item: string) {
    if (!this.layout) {
      return;
    }
    const components = this.layout.getComponents();
    let targetSummaryList = null;
    for (const key of Object.keys(components)) {
      const component = components[key];
      if ((component.config as ComponentConfig).componentName === 'StockList') {
        if (targetSummaryList === null || (component.config as ComponentConfig).componentState?.active) {
          targetSummaryList = component.id;
        }
      }
    }

    const config = {
      type: 'component',
      componentName: 'StockList', // The name defined in componentTypes
      title: '銘柄リスト',
      componentState: {
        active: true,
        summaryId: item,
      },
    };
    this.layout.createNewComponent(config, targetSummaryList);
  }

  onStateChanged() {
    if (!this.initialStateChange) {
      this.initialStateChange = true;
      if (!this.orderOperator.config.orderEnabled) {
        this.layout.closeComponent('trade');
      } else {
        this.orderUi.tryShowItaOrderTutorial(false);
      }
    }
    this.tradeShown = this.layout.getGoldenLayoutInstance().root.getItemsById('trade').length > 0;
    this.marketShown = this.layout.getGoldenLayoutInstance().root.getItemsById('marketList').length > 0;

    if (!this.cocomero.error) {
      const config = this.layout.getGoldenLayoutInstance().toConfig();
      if (this.orderOperator.config.orderEnabled) {
        this.persistentState.updateMainWindowLayoutWithOrder(config);
      } else {
        this.persistentState.updateMainWindowLayoutViewOnly(config);
      }
    }
  }

  @HostListener('document:contextmenu', ['$event'])
  ignoreOverlayBackdropContextMenu(event: MouseEvent) {
    if (event.target && event.target['classList']) {
      if (event.target['classList'].contains('cdk-overlay-backdrop')) {
        event.preventDefault();
      }
    }
  }

  closeFullItaButtonTutorial() {
    this.persistentState.updateFirstTutorialFullItaButton(true);
  }

  openHelp(help: HelpEntry) {
    if ('link' in help) {
      window.open(help.link, '_blank', 'noopener');
    } else {
      if (help.onClick === 'itaOrderTutorial') {
        this.orderUi.tryShowItaOrderTutorial(true);
      }
    }
  }

  openEShitenPage() {
    window.open('https://www.e-shiten.jp/', '_blank', 'noopener');
    gtag('event', 'cv_click', {
      click_target: 'open_account_button',
      event_label: 'https://www.e-shiten.jp/',
      button_place: 'main-window-header',
      value: 1,
    });
  }

  tryOpenTedinet() {
    if (!this.tedinetUrl) {
      return;
    }
    if (this.doNotShowTedinetDialog) {
      this.openTedinet();
    } else {
      this.dialog
        .openDialog(
          {
            content: ['アルゲンタム・コード運営の外部サイト「BRiSK適時開示」を別ウィンドウで開きます。'],
            closeOnEscape: true,
            checkboxText: '今後、このメッセージを表示しない ',
            showCloseButton: true,
            buttons: [
              {
                text: 'OK',
                initialFocus: true,
                result: 'open',
              },
              {
                text: 'キャンセル',
                result: 'cancelled',
              },
            ],
          },
          {
            width: '48em',
          }
        )
        .subscribe((result) => {
          if (result.type === 'button' && result.value === 'open') {
            if (result.checked) {
              this.persistentState.updateDoNotShowTedinetDialog(true);
            }
            this.openTedinet();
          }
        });
    }
  }

  openTedinet() {
    window.open(this.tedinetUrl, '_blank');
  }
}
