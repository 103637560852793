import { Injectable } from '@angular/core';
import { FlexOperator } from './flex-operator';
import { QRRowType, StockView } from '@argentumcode/brisk-common';

@Injectable({
  providedIn: 'root',
})
export class QrLazyService {
  private loadingIssue: { [key: number]: boolean } = {};
  private opId = new Map<FlexOperator, number>();

  constructor() {}

  private getKey(op: FlexOperator, view: StockView) {
    if (!this.opId.has(op)) {
      this.opId.set(op, this.opId.size);
    }
    return this.opId.get(op) * 1000000 + view.master.issueCode;
  }

  get(op: FlexOperator, view: StockView) {
    const key = this.getKey(op, view);
    if (this.loadingIssue[key]) {
      return;
    }
    this.loadingIssue[key] = true;
    let count = 0;
    for (let i = 0; i < view.qr.length; i++) {
      if (view.qr[i].type === QRRowType.Lazy) {
        count++;
      } else {
        break;
      }
    }
    console.log(`qr load count ${count}`);
  }

  loading(op: FlexOperator, view: StockView): boolean {
    const key = this.getKey(op, view);
    return this.loadingIssue[key] || false;
  }
}
