interface OHLCCommon {
  open_price: number;
  close_price: number;
  high_price: number;
  low_price: number;
  turnover: number;
  open_turnover?: number;
  close_turnover?: number;
}

interface OHLC5min extends OHLCCommon {
  date: string;
  index: number;
  diff: number;
}

interface OHLC1day extends OHLCCommon {
  date: string;
}

interface OHLC1week extends OHLCCommon {
  year: number;
  week: number;
}

interface OHLC1year extends OHLCCommon {
  year: number;
  month: number;
}

export interface OHLCResponse {
  ohlc5min: Array<OHLC5min>;
  ohlc1day: Array<OHLC1day>;
  ohlc1week: Array<OHLC1week>;
  ohlc1month: Array<OHLC1year>;
}

// @dynamic
export class OHLCResponseExtended {
  constructor(
    public ohlc: OHLCResponse,
    public data5min: Array<number>,
    public data1day: Array<number>,
    public data1week: Array<number>,
    public data1month: Array<number>
  ) {}

  static createPriceChanges(ohlc: OHLCResponse, basePrice: number): OHLCResponseExtended {
    return new this(
      ohlc,
      ohlc.ohlc5min.map((d) => d.close_price / basePrice - 1),
      ohlc.ohlc1day.map((d) => d.close_price / basePrice - 1),
      ohlc.ohlc1week.map((d) => d.close_price / basePrice - 1),
      ohlc.ohlc1month.map((d) => d.close_price / basePrice - 1)
    );
  }
}
