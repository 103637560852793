<ng-container *ngIf="item && stockWrapper.current">
  <div (click)="itemClicked.emit()" (dblclick)="itemDblClicked.emit()">
    <div class="stockTitle">
      <span class="loanMarginFlag" [ngbTooltip]="tooltipLoanMargin">
        <fa-icon *ngIf="view && view.master.loanMarginFlag === 1" [icon]="['fas', 'circle']" transform="shrink-8"></fa-icon>
        <fa-icon *ngIf="view && view.master.loanMarginFlag === 2" [icon]="['far', 'circle']" transform="shrink-8"></fa-icon>
      </span>
      {{ stockWrapper.current.master.issueCode }} {{ stockWrapper.current.master.name }}
    </div>
    <div class="stockInfo">
      <!-- <span class="qrType" [attr.data-type]="view.qr.length > 0 ? view.qr[view.qr.length - 1].type : 0"></span> -->
      <span
        *ngIf="stockWrapper.current.master.exRightFlag"
        class="ex-right-flag"
        [ngbTooltip]="stockWrapper.current.master.exRightFlag | exRightFlag"
      >
        落
      </span>

      <span>
        <span [ngbTooltip]="'騰落率(基準値段比)'" [ngClass]="view.lastPriceBasePriceChange | priceChangeClass">{{
          view.lastPriceBasePriceChange | priceChanges | predictTransform: view.predict
        }}</span>
      </span>
      /
      <span>
        <span [ngbTooltip]="'VWAP比'" [ngClass]="view.vwapPriceChange | priceChangeClass">
          {{ view.vwapPriceChange | priceChanges | predictTransform: view.predict }}
        </span>
      </span>
      <span> / </span>
      <span *ngIf="view.turnover10 || view.predictTurnover10" [ngbTooltip]="'売買代金'">
        {{
          (view.turnover10 || view.predictTurnover10) / 1000000000
            | fastDecimal: { separateComma: true, fractionDigits: 2 }
            | predictTransform: view.turnoverPredict
        }}
        億円
      </span>
    </div>
  </div>
  <brisk-ita
    #ita
    *ngIf="stockWrapper"
    [normalized]="normalized"
    (normalizedChange)="normalizedChange.emit($event)"
    [miniMode]="true"
    [fixedRows]="rows"
    [stockOperator]="stockWrapper"
    [fillStopPrice]="true"
    [alignRight]="true"
    [forceSeparateComma]="true"
    [timeProvider]="timeProvider"
    (gridHeightChanged)="gridHeightChanged.emit()"
    [showScrollbar]="true"
    [peg]="peg"
    (pegChange)="pegChange.emit($event)"
    [itaWidth]="itaWidth"
  ></brisk-ita>
</ng-container>

<ng-template #tooltipLoanMargin>
  ● 貸借銘柄<br />
  ○ 貸借融資銘柄（制度信用買のみ可能）
</ng-template>
