import { Pipe, PipeTransform } from '@angular/core';
import { QuoteFlag, Side } from '../flex';

@Pipe({
  name: 'quoteSideStr',
})
export class QuoteSideStrPipe implements PipeTransform {
  transform(flag: any, side: any): string {
    if (flag !== QuoteFlag.SQ && flag !== QuoteFlag.CEQ) {
      return '';
    }

    if (flag === QuoteFlag.CEQ) {
      return 'C';
    }

    if (side === Side.Ask) {
      return 'ウ';
    } else {
      return 'カ';
    }
  }
}
