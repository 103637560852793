import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { WindowRefService } from '../brisk-browser/window-ref.service';

export const DISALLOW_MULTIPLE_TABS_KEY = new InjectionToken('DISALLOW_MULTIPLE_TABS_KEY');

@Injectable({
  providedIn: 'root',
})
export class DisallowMultipleTabsService implements OnDestroy {
  private removeTabCheckFunction: Function;

  constructor(
    private window: WindowRefService,
    private eventManager: EventManager,
    @Optional() @Inject(DISALLOW_MULTIPLE_TABS_KEY) private readonly storageKey: string
  ) {
    if (!this.storageKey) {
      this.storageKey = 'tab';
    }
  }

  check() {
    const key = new Uint8Array(10);
    (window.crypto || window['msCrypto']).getRandomValues(key);
    this.window.nativeWindow.localStorage.setItem(this.storageKey, JSON.stringify(key));
    this.removeTabCheckFunction = this.eventManager.addGlobalEventListener('window', 'storage', (e: StorageEvent) => {
      if (e.key === this.storageKey) {
        setTimeout(() => {
          if (this.window.nativeWindow.localStorage.getItem(this.storageKey) === JSON.stringify(key)) {
            return;
          }
          location.href = '/multiple_tabs';
        }, 1000);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.removeTabCheckFunction) {
      this.removeTabCheckFunction();
    }
  }
}
