import { Injectable } from '@angular/core';
import { LocalStorageService, PegState } from '@argentumcode/brisk-common';
import { StockListViewMode } from '../portfolio/summary-list.service';

export enum TutorialState {
  NewUser = 1, // 新規ユーザー
  ExistingUser = 2, // 既存ユーザー
  Finished = 10, // 完了済
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private localStorage: LocalStorageService) {}

  public get invisibleSummary(): Array<string> {
    const item = this.localStorage.getItem('invisible_summary');
    try {
      return JSON.parse(item) || [];
    } catch {
      return [];
    }
  }

  public set invisibleSummary(arr: Array<string>) {
    this.localStorage.setItem('invisible_summary', JSON.stringify(arr));
  }

  public addInvisibleSummary(summaryId: string) {
    const arr = this.invisibleSummary;
    const idx = arr.indexOf(summaryId);
    if (idx !== -1) {
      return;
    }
    arr.push(summaryId);
    this.localStorage.setItem('invisible_summary', JSON.stringify(arr));
  }

  public removeInvisibleSummary(summaryId: string) {
    const arr = this.invisibleSummary;
    const idx = arr.indexOf(summaryId);
    if (idx === -1) {
      return;
    }
    arr.splice(idx, 1);
    this.localStorage.setItem('invisible_summary', JSON.stringify(arr));
  }

  public get splitSize(): number {
    return Number(this.localStorage.getItem('split_size') || '25');
  }

  public set splitSize(num: number) {
    this.localStorage.setItem('split_size', num.toString());
  }

  public getDefaultTabIndex(index: number): number {
    const num = Number(this.localStorage.getItem(`tab_index_${index}`) || index);
    if (num < 0 || num >= 3) {
      return 0;
    }
    return num;
  }

  public setDefaultTabIndex(index: number, number: number) {
    this.localStorage.setItem(`tab_index_${index}`, number.toString());
  }

  public getShowIndustryPrice(index: number): boolean {
    const item = this.localStorage.getItem(`chart_show_industry_price_${index}`);
    return item ? item === '1' : true; // default to true
  }

  public setShowIndustryPrice(index: number, value: boolean) {
    this.localStorage.setItem(`chart_show_industry_price_${index}`, value ? '1' : '0');
  }

  public get theme(): string {
    const t = this.localStorage.getItem('theme');
    return t;
  }

  public set theme(s: string) {
    this.localStorage.setItem('theme', s);
  }

  public get viewMode(): StockListViewMode {
    const item = this.localStorage.getItem('view_mode');
    if (item === 'Detail') {
      return StockListViewMode.Detail;
    } else if (item === 'DetailWide') {
      return StockListViewMode.DetailWide;
    } else {
      return StockListViewMode.Normal;
    }
  }

  public set viewMode(s: StockListViewMode) {
    if (s === StockListViewMode.Normal) {
      this.localStorage.setItem('view_mode', 'Normal');
    }
    if (s === StockListViewMode.Detail) {
      this.localStorage.setItem('view_mode', 'Detail');
    }
    if (s === StockListViewMode.DetailWide) {
      this.localStorage.setItem('view_mode', 'DetailWide');
    }
  }

  public get peg(): PegState {
    const s = this.localStorage.getItem('peg');
    if (s === 'Disabled') {
      return PegState.Disabled;
    } else {
      return PegState.Enabled;
    }
  }

  public set peg(p: PegState) {
    if (p === PegState.Disabled) {
      this.localStorage.setItem('peg', 'Disabled');
    } else {
      this.localStorage.setItem('peg', 'Enabled');
    }
  }

  public get tutorialState(): TutorialState {
    const state = Number(this.localStorage.getItem('tutorial_state'));
    if (state === TutorialState.NewUser || state === TutorialState.ExistingUser || state === TutorialState.Finished) {
      return state;
    } else {
      return null;
    }
  }

  public set tutorialState(state: TutorialState) {
    this.localStorage.setItem('tutorial_state', state.toString());
  }

  public get tutorialBackup(): string {
    return this.localStorage.getItem('tutorial_backup');
  }

  public set tutorialBackup(s: string) {
    if (!s) {
      this.localStorage.removeItem('tutorial_backup');
    } else {
      this.localStorage.setItem('tutorial_backup', s);
    }
  }

  public get doNotShowTedinetDialog(): boolean {
    return this.localStorage.getItem('tedinet_dialog') === 'true';
  }

  public set doNotShowTedinetDialog(value: boolean) {
    this.localStorage.setItem('tedinet_dialog', value ? 'true' : 'false');
  }
}
