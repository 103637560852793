<wj-popup
  #smartPasteDialog
  id="smartPasteDialog"
  [modal]="true"
  [hideTrigger]="'None'"
  [fadeIn]="false"
  [fadeOut]="false"
  (hidden)="onHidden()"
>
  <div class="modal-header">
    <h5 class="modal-title">スマートコピペ</h5>
    <span class="close wj-hide" data-dismiss="modal" aria-label="Close" (click)="smartPasteDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <form *ngIf="form" [formGroup]="form">
    <div class="modal-body">
      <p *ngIf="listName; else createNewListMessage">
        <!--        銘柄リスト「{{listName}}」に追加するか、名前を指定して新しい銘柄リストを作成します。-->
      </p>
      <ng-template #createNewListMessage>
        <p>
          新しい登録銘柄リストを作成します。名前を入力してください。
        </p>
      </ng-template>
      <div class="form-group" *ngIf="listName">
        <div class="form-check">
          <input class="form-check-input" type="radio" id="smartPasteDialogAddToList" value="add" formControlName="addOrCreate" />
          <label class="form-check-label" for="smartPasteDialogAddToList">「{{ listName }}」に追加する</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="smartPasteDialogCreateList" value="create" formControlName="addOrCreate" />
          <label class="form-check-label" for="smartPasteDialogCreateList">&nbsp;&nbsp;新しく登録銘柄リストを作成する</label>
        </div>
      </div>
      <div class="form-group" *ngIf="showName">
        <label for="smartPasteDialogName" *ngIf="listName">銘柄リスト名</label>
        <input
          type="text"
          class="form-control"
          id="smartPasteDialogName"
          maxlength="9"
          formControlName="name"
          [class.is-invalid]="name.invalid && (name.dirty || name.touched)"
        />
        <div class="invalid-feedback">
          <ng-container *ngIf="name.invalid && (name.dirty || name.touched)">
            <ng-container *ngIf="name.errors?.required">
              銘柄リスト名を入力してください。
            </ng-container>
            <ng-container *ngIf="name.errors?.notUnique">
              銘柄リスト名が重複しています。
            </ng-container>
            <ng-container *ngIf="name.errors?.nameLength">
              銘柄リスト名は9文字以内で入力してください。
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" [disabled]="!form.valid" (click)="onSubmit()">OK</button>
      <button class="btn btn-light wj-hide">キャンセル</button>
    </div>
  </form>
</wj-popup>
