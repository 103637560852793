import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VgGridComponent } from './vg-grid/vg-grid.component';
import { VgGridColumnComponent } from './vg-grid-column/vg-grid-column.component';
import { VgGridCellTemplateDirective } from './vg-grid-cell-template.directive';
import { VgGridInputDirective } from './vg-grid-input.directive';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { VgGridFilterComponent } from './vg-grid-filter/vg-grid-filter.component';
import { VgGridConditionFilterComponent } from './vg-grid-condition-filter/vg-grid-condition-filter.component';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { FormsModule } from '@angular/forms';
import { VgGridValueFilterComponent } from './vg-grid-value-filter/vg-grid-value-filter.component';
import { PlatformModule } from '@angular/cdk/platform';
import { IconsModule } from '../icons/icons.module';
import { DragDropModule } from '../drag-drop/drag-drop.module';
import { VgGridCustomColumnDirective } from './vg-grid-custom-column.directive';
import { VgGridChoiceFilterComponent } from './vg-grid-choice-filter/vg-grid-choice-filter.component';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';

@NgModule({
  declarations: [
    VgGridComponent,
    VgGridColumnComponent,
    VgGridCellTemplateDirective,
    VgGridInputDirective,
    VgGridFilterComponent,
    VgGridConditionFilterComponent,
    VgGridValueFilterComponent,
    VgGridCustomColumnDirective,
    VgGridChoiceFilterComponent,
  ],
  exports: [VgGridComponent, VgGridColumnComponent, VgGridCellTemplateDirective, VgGridInputDirective, VgGridCustomColumnDirective],
  imports: [
    CommonModule,
    ScrollingModule,
    IconsModule,
    NgbPopoverModule,
    WjInputModule,
    FormsModule,
    PlatformModule,
    DragDropModule,
    BriskCommonModule,
  ],
})
export class VgGridModule {
  constructor() {}
}
