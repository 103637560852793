// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { SimpleOrderService } from '../../app/core/simple-order.service';
import { MonexOrderService } from '../../app/core/monex-order.service';
import { flexConfigTokyo } from '../flex-config';

export const environment = {
  flexConfig: flexConfigTokyo,
  title: 'BRiSK for マネックス証券',
  name: 'brisk-monex',
  production: true,
  debugSession: true,
  heartbeatIntervalMilliSeconds: 7000,
  disallowMultipleTabs: true,
  jsfcOnly: true,
  showTrade: false,
  itaSeparateComma: true,
  usePrefix: true,
  publicBeta: false,
  loginUrl: 'https://www.brisk.jp',
  helpUrl: '',
  helpTitle: '',
  assistTitle: 'ヘルプ',
  feedbackTitle: 'ご意見',
  feedbackMessage: null,
  showForm: false, // お問い合わせボタンの表示,
  uploadIdentity: true,
  showAssistButton: true,
  disableFeedback: false,
  feedbackUrl: 'https://info.monex.co.jp/support/index.html',
  orderButtonType: 'monex',
  assistUrl: {
    headerLastPrice: 'https://static.brisk.jp/assist-v2/predict.html',
    timestampHeaderFrameBack: 'https://static.brisk.jp/assist-v2/ita.html',
    itaWheelClick: 'https://static.brisk.jp/assist-v2/ita.html',
    itaDblClick: 'https://static.brisk.jp/assist-v2/ita.html',
    marketLinePopup: 'https://static.brisk.jp/assist-v2/markets.html',
    portfolioEditableIssueCode: 'https://static.brisk.jp/assist-v2/portfolio.html',
    portfolioOpenPredict: 'https://static.brisk.jp/assist-v2/portfolio.html',
    summaryListCopyPaste: 'https://static.brisk.jp/assist-v2/smart-copipe.html',
    summaryListCtrlClick: 'https://static.brisk.jp/assist-v2/summary-portfolio.html',
    jsfc: 'https://static.brisk.jp/assist-v2/jsfc.html',
  },
  availableThemes: ['light', 'dark', 'dark-inverse', 'ns'],
  defaultTheme: 'dark',
  newsUrl: 'https://static.brisk.jp/help/news-monex.html',
  chromeSupportLink: null,
  helps: [
    {
      name: 'メインパネル',
      link: 'https://static.brisk.jp/assist-v2/mainpanelhelp.html',
    },
    {
      name: 'サイドパネル',
      link: 'https://static.brisk.jp/assist-v2/sidepanelhelp.html',
    },
    {
      name: 'フル板パネル',
      link: 'https://static.brisk.jp/assist-v2/fullitapanelhelp.html',
    },
    {
      name: '場況速報',
      link: 'https://static.brisk.jp/assist-v2/bakyohelp.html',
    },
    {
      name: 'スマートコピペ',
      link: 'https://static.brisk.jp/assist-v2/smart-copipe.html',
    },
    {
      name: 'よくあるご質問(FAQ)',
      link: 'https://static.brisk.jp/assist-v2/faq.html',
    },
  ],
  tedinetUrl: null,
  tedinetMainPageUrl: 'https://timely-disclosure-embed.brisk.jp',
  edgeUpdateMessageType2: false,
  zenitaOrigin: undefined,
  showMapView: true,
};

// 環境別の発注機能用のサービス
export const simpleOrderService = MonexOrderService;

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
