import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MapViewTooltipService } from '../../portfolio/map-view-tooltip/map-view-tooltip.service';
import { StockWrapper } from '@argentumcode/brisk-common';
import { Subscription } from 'rxjs';
import { ItaViewService } from '../../core/ita-view.service';

@Component({
  selector: 'app-map-view-tooltip',
  templateUrl: './map-view-tooltip.component.html',
  styleUrls: ['./map-view-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapViewTooltipComponent implements OnInit, OnDestroy {
  public stockWrapper: StockWrapper;
  public groupName: string | undefined;
  private id: number = null;
  private _subscription: Subscription = new Subscription();

  constructor(
    private tooltipService: MapViewTooltipService,
    private itaView: ItaViewService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._subscription.add(
      this.tooltipService.issueCode$.subscribe((issueCode) => {
        if (issueCode) {
          this.discardStock();
          [this.id, this.stockWrapper] = this.itaView.getStock(issueCode, true);
        }
        this._changeDetectorRef.markForCheck();
      })
    );
    this._subscription.add(
      this.tooltipService.groupName$.subscribe((groupName) => {
        this.groupName = groupName;
        this._changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.discardStock();
    this._subscription.unsubscribe();
  }

  private discardStock() {
    if (this.id !== null) {
      this.itaView.removeStock(this.id);
      this.id = null;
    }
  }
}
