import { EventEmitter, Injectable } from '@angular/core';
import { FIXHandshake, FIXService, FIXWebsocketStatus } from './fix.service';

@Injectable({
  providedIn: 'root',
})
export class FixWebsocketService {
  /// テストのために外部(FIXService)から呼び出してますが、
  /// 当然サーバとのインタラクションのみで駆動すべき
  // Handshake成功 callback
  // 再接続時のメッセージ履歴を全部入れてもいいかも
  connected = new EventEmitter<FIXHandshake>();
  // Message受信 callback
  received = new EventEmitter<Uint8Array>();
  // 何か接続状態等が変化したとき callback
  statusChanged = new EventEmitter<FIXWebsocketStatus>();

  messages: Array<Uint8Array> = [];

  subscribe(fix: FIXService): void {
    this.connected.subscribe(fix.onConnected.bind(fix));
    this.statusChanged.subscribe(fix.onStatusChanged.bind(fix));
    this.received.subscribe(fix.onReceived.bind(fix));

    this.connected.emit({
      userID: 0,
      brokerAccounts: [
        { id: 0, broker: '立花', account: 'TACH' },
        { id: 1, broker: '立花', account: 'TachiJP' },
        {
          id: 2,
          broker: 'GS',
          account: 'GSJ',
        },
      ],
    });
    this.statusChanged.emit({ isOrderEntryReady: true });

    // DUMMY restore
    this.received.subscribe((ary) => {
      console.log(ary);
      this.messages.push(ary);
    });
    for (const msg of this.messages) {
      this.received.emit(msg);
    }
    // DUMMY restore
  }

  // userID: 対象注文のUserID（super-userは自身のUserIDとは異なる値を指定して発注（キャンセル）することが可能）
  // brokerAccountID: 対象注文のBrokerAccountID（同上）
  // message: Message by Message でFIXハンドラに渡すこと. テスト段階ではlength==0のデータで呼び出すことがあります
  // expectedMessages: テスト用.echobackするとFIXハンドラへの結合前に一応のテストができます.最終的には消します
  send(userID: number, brokerAccountID: number, message: Uint8Array, expectedMessages: Array<Uint8Array>): boolean {
    // actual send
    for (const msg of expectedMessages) {
      this.received.emit(msg);
    }
    return true;
  }

  dispose(): void {
    if (this.connected !== null) {
      this.connected.complete();
      this.connected.unsubscribe();
      this.connected = null;
    }
    if (this.received !== null) {
      this.received.complete();
      this.received.unsubscribe();
      this.received = null;
    }
    if (this.statusChanged !== null) {
      this.statusChanged.complete();
      this.statusChanged.unsubscribe();
      this.statusChanged = null;
    }
  }
}
