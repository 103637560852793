import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { Event as SentryEvent, EventHint } from '@sentry/types';
import { configureScope, captureException, init } from '@sentry/browser';

export enum ErrorCode {
  InvalidSession = '10001',
  TimeoutSession = '10003',
  OtherLogin = '10005',
  OtherLoginWebsockets = '10006',
  BootOrderSession = '10010',
  BootOrderParent = '10011',
  MemoryError = '20000',
  FatalError = '20001',
  ConnectionError = '30000',
  BadRequestError = '30001',
  RateLimitError = '30002',
  ServerError = '40000',
  MarketError = '40002',
}

export class ErrorWrapper {
  constructor(
    public err: Error,
    public code: ErrorCode,
    public errorMessage: string,
    public showRestart: boolean,
    public showLogin: boolean
  ) {}
}

export class ErrorWithCode extends Error {
  errorCode: ErrorCode;
  _errorType = 'custom' as const;
  constructor(errorCode: ErrorCode, message?: string) {
    super(message);
    this.errorCode = errorCode;
    this.name = 'OrderError';
  }
}

// 発注系のエラーのベース
export class OrderError<T> extends Error {
  errorCode: T;
  _errorType = 'order' as const;

  constructor(errorCode: T, message?: string) {
    super(message);
    this.errorCode = errorCode;
    this.name = 'OrderError';
  }
}

export class SessionExpireError extends Error {
  public static errorName = 'SessionExpireError';

  constructor() {
    super('Session Expires');
    this.name = SessionExpireError.errorName;
  }
}

@Injectable()
export class ErrorHandlerService implements ErrorHandler, OnDestroy {
  private errorSubject = new ReplaySubject<ErrorWrapper>();
  public errorRaised: Observable<any> = this.errorSubject.asObservable();
  private firstError = true;
  public userId: string;

  private errors: { [key: number]: any } = {};

  constructor() {}

  setErrorMessages(errors: { [key: number]: any }) {
    this.errors = errors;
  }

  getErrorMessage(code: ErrorCode): string {
    return this.errors[code].message;
  }

  getMarketErrorMessage(): string {
    return this.errors[ErrorCode.MarketError].message;
  }

  setUserContext(id: string) {
    this.userId = id;
    configureScope((scope) => {
      scope.setUser({
        id: id,
      });
    });
  }

  private _handleError(err: any, sentToSentry: boolean): boolean {
    // NOTICE: Wijmoのバグを無視する
    if (
      err &&
      err.message &&
      (err.message.includes('null') || err.message.includes('NULL') || err.message.includes('undefined')) &&
      (err.message.includes('getAttribute') || err.message.includes('hostElement'))
    ) {
      return false;
    }
    if (
      err &&
      err.message &&
      (err.message === `Cannot read property 'children' of null` ||
        err.message === `Unable to get property 'children' of undefined or null reference` ||
        (err.message.includes('children') &&
          (err.message.includes('null') || err.message.includes('NULL') || err.message.includes('undefined'))))
    ) {
      // IME入力中にリスト切り替え時のバグ
      console.error('Ime switch bug');
      return false;
    }
    console.error('Error', err);
    if (this.firstError) {
      this.firstError = false;
      const code = this.errorToCode(err);
      const error = this.errors[code];
      this.errorSubject.next(new ErrorWrapper(err, code, error?.message, error?.enable_restart, error?.enable_login));
      if (sentToSentry) {
        captureException(err);
      }
      return true;
    }
    return false;
  }

  handleErrorWithoutSentry(err: any): boolean {
    return this._handleError(err, false);
  }

  handleError(err: any): void {
    this._handleError(err, true);
  }

  ngOnDestroy(): void {
    this.errorSubject.complete();
  }

  errorToCode(err: any): ErrorCode {
    if (err && err._errorType === 'order') {
      return err.errorCode;
    }
    if (err && err._errorType === 'custom') {
      return err.errorCode;
    }
    if (err && err.response) {
      // HTTP エラー
      if (err.response.status === 401) {
        if (err.authErrorCode === '1') {
          return ErrorCode.OtherLogin;
        } else if (err.authErrorCode === '2') {
          return ErrorCode.TimeoutSession;
        }
        return ErrorCode.InvalidSession;
      }
      if (err.rateLimitError) {
        return ErrorCode.RateLimitError;
      }
      if (err.response.status === 400) {
        return ErrorCode.BadRequestError;
      } else if (err.response.status === 403) {
        return ErrorCode.RateLimitError;
      } else if (err.response.status >= 500 && err.response.status < 599) {
        return ErrorCode.ServerError;
      } else if (err.response.status === 0) {
        return ErrorCode.ConnectionError;
      }
    }
    if (err && err.authError) {
      return ErrorCode.OtherLoginWebsockets;
    }
    if (
      err &&
      ((err.message && err.message.startsWith('Array buffer allocation failed')) || // Google Chrome
        (err.message && err.message.startsWith('WebAssembly.Memory(): could not allocate memory')) || // Google Chrome
        <any>err === 'out of memory' || // Firefox
        (err && err.message && err.message.includes('Out of memory')) ||
        (err.name === 'InternalError' && err.message && err.message.startsWith('std::bad_alloc')))
    ) {
      return ErrorCode.MemoryError;
    }
    if (err && err.name === SessionExpireError.errorName) {
      return ErrorCode.TimeoutSession;
    }
    return ErrorCode.FatalError;
  }
}

function setupEventFingerprint(event: SentryEvent, hint?: EventHint) {
  if (hint && hint.originalException) {
    const orig = hint.originalException as any;
    if (orig && orig.response) {
      event.fingerprint = ['http_error', `code:${orig.response.status}`];
    } else if (orig.name === 'WebSocketConnectionError') {
      event.fingerprint = ['websocket', 'connection_error'];
    } else if (orig.name === 'WebsocketHeartbeatError') {
      event.fingerprint = ['websocket', 'heartbeat_error'];
    } else if (orig.name === 'FlexTimestampError') {
      event.fingerprint = ['websocket', 'flex_timestamp_error'];
    }
  }
}

const sentryProcessed = new WeakMap<any, boolean>();
let hasOom = false;

export function sentryEventBeforeSend(event: SentryEvent, hint?: EventHint): Promise<SentryEvent | null> | SentryEvent | null {
  if (hint && hint.originalException) {
    // RxJSのCatchされていないエラーがSentryのハンドラとAngularのハンドラによって二重送信されることを防ぐ (#176)
    const exp = hint.originalException as any;
    if (exp instanceof Error) {
      if (sentryProcessed.has(exp)) {
        return null;
      }
      sentryProcessed.set(exp, true);
    } else if (exp === 'out of memory') {
      if (hasOom) {
        return null;
      }
      hasOom = true;
    }
  }
  if (event.exception && event.exception.values && event.exception.values.length === 1) {
    const err = { message: event.exception.values[0].value };
    if (
      err &&
      err.message &&
      (err.message.includes('null') || err.message.includes('NULL') || err.message.includes('undefined')) &&
      (err.message.includes('getAttribute') || err.message.includes('hostElement'))
    ) {
      console.log('Ignore Wijmo Error', err);
      return null;
    }
    if (
      err &&
      err.message &&
      (err.message === `Cannot read property 'children' of null` ||
        err.message === `Unable to get property 'children' of undefined or null reference` ||
        (err.message.includes('children') &&
          (err.message.includes('null') || err.message.includes('NULL') || err.message.includes('undefined'))))
    ) {
      // IME入力中にリスト切り替え時のバグ
      console.error('Ime switch bug');
      return null;
    }
  }
  for (const bread of event.breadcrumbs) {
    if (bread.category === 'xhr' || bread.category === 'fetch') {
      // e-shiten api_v4r2
      if (bread.data.url.match(/^https:\/\/.*\.e-shiten\.jp\//)) {
        bread.data.url = bread.data.url.replace(/\/request\/.*?\//, '/request/[removed]/');
        bread.data.url = bread.data.url.replace(/\/event\/.*?\//, '/event/[removed]/');
        bread.data.url = bread.data.url.replace(/"531":".*?"/, '"531":"[removed]"'); // sPassword
        bread.data.url = bread.data.url.replace(/"690":".*?"/, '"690":"[removed]"'); // sUserId
        bread.data.url = bread.data.url.replace(/"544":".*?"/, '"544":"[removed]"'); // sSecondPassword
        bread.data.url = bread.data.url.replace(/"589":".*?"/, '"589":"[removed]"'); // sStkSessionId
      }
    }
  }
  setupEventFingerprint(event, hint);
  return event;
}
