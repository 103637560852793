<div>
  <fa-icon
    (click)="stockDetailStackHeader.click($event)"
    [icon]="['fas', 'plus']"
    [fixedWidth]="true"
    [ngbTooltip]="'新しいタブ'"
    transform="grow-2"
  ></fa-icon>
  <brisk-first-tutorial #newTabTutorial class="p-scroll-tutorial" placement="right-top" (closed)="stockDetailStackHeader.onTutorialClose()">
    <fa-icon [icon]="['fas', 'plus']" [fixedWidth]="true" transform="grow-2"></fa-icon> をクリックして銘柄タブを追加しましょう。<br />
    銘柄タブやその中のコンポーネントはドラッグ＆ドロップでお好きなところに配置できますよ。
  </brisk-first-tutorial>
</div>
