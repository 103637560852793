import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'predictTransform',
})
export class PredictTransformPipe implements PipeTransform {
  transform(value: any, predict: boolean): any {
    if (!value) {
      return value;
    }
    if (predict) {
      return `(${value})`;
    } else {
      return value;
    }
  }
}
