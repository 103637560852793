<wj-auto-complete
  #autoComplete
  [ngClass]="grid ? 'grid' : ''"
  [placeholder]="grid ? '' : placeHolder"
  [delay]="0"
  [showDropDownButton]="false"
  [minLength]="1"
  [isRequired]="false"
  [maxItems]="maxItems"
  [dropDownCssClass]="'brisk-issue-code-input-dropdown'"
  (click)="onClick($event)"
  (lostFocus)="onLostFocus()"
  (isDroppedDownChanging)="onIsDroppedDownChanged()"
  (keydown.enter)="onEnterKeyDown($event)"
  (gotFocus)="onGotFocus($event)"
  (compositionstart)="onCompositionStart()"
  (compositionend)="onCompositionEnd()"
>
  <ng-template wjItemTemplate let-item="item">{{ item.issueCode }} {{ item.name }}</ng-template>
</wj-auto-complete>
