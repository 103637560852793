import { FlexOperatorService } from './flex-operator.service';
import {
  IssueStatus,
  ItaRow,
  Portfolio,
  QRRowType,
  QuoteFlag,
  Side,
  StockInfo,
  StockMaster,
  StockView,
  Summary,
  SummaryPortfolio,
  SummaryType,
  Tick,
  TickOperator,
  Trace,
  TraceOperator,
  FlexVersion,
  FlexConfig,
} from '@argentumcode/brisk-common';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { BasePriceUpdate, StockVolume } from './api.service';
import { differenceInSeconds, subSeconds } from 'date-fns';
import { environment } from '../../environments/default/environment';

const MARKET_CLOSE_TIMESTAMP = environment.flexConfig.marketCloseTimestamp;
const OHLC_LENGTH = environment.flexConfig.ohlcLength;

export class AuthError extends Error {
  public readonly authError = true;

  constructor() {
    super('Websocket同時接続エラー');
  }
}

export function dateToFlexTimestamp(date: Date) {
  const hour = (date.getUTCHours() + 9) % 24;
  const minute = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const timestamp = (60 * (hour * 60 + minute) + seconds) * 1000000 + date.getMilliseconds() * 1000;
  return timestamp;
}

export class TimestampPair {
  public constructor(public readonly serverTimestamp: Date, public readonly clientTimestamp: Date) {}

  public getServerTimestamp(clientTimestamp: Date): Date {
    return new Date(this.serverTimestamp.getTime() + clientTimestamp.getTime() - this.clientTimestamp.getTime());
  }
}

export class FlexOperator implements TraceOperator, TickOperator {
  private readonly id: number;

  public readonly flexConfig: Readonly<FlexConfig> = environment.flexConfig;

  public done = false;
  // 1銘柄でも15:00:00.000000に到達したかどうか
  public marketFinished = false;
  private marketFinishedSubject = new ReplaySubject<true>(1);
  marketFinished$ = this.marketFinishedSubject.asObservable();
  public portfolios: Portfolio[] = null;
  public stockMasters: StockMaster[] = null;
  public summaries: Summary[] = [];
  public summaryPortfolios: SummaryPortfolio[] = [];

  public stockViews: Array<StockView> = [];
  public stockInfos: Array<StockInfo> = [];

  public issueCodeMap: { [key: number]: number } = {};
  public websocketFrameNumber: Uint32Array = null;
  public websocketFrameNumberReceived = new Subject<Uint32Array>();

  public serverTimestamp: TimestampPair = null;
  public lastHeartbeat: Date = null; // Server Time
  private basePriceUpdatedSubject = new Subject<[number, number]>();
  public basePriceUpdated = this.basePriceUpdatedSubject.asObservable();

  private onSendDataSubject = new Subject<Uint8Array>();
  private updateNumberSubscription: Subscription = null;
  private heartbeatSubscription: Subscription = null;
  private basePriceSubscription: Subscription = null;
  private authErrorSubscription: Subscription = null;
  private marketFinishedSubscription: Subscription = null;
  public onSendData: Observable<Uint8Array> = this.onSendDataSubject.asObservable();

  private itaRowCache: { [key: number]: number } = {};

  private industryCodes = {
    '50': '水産農林',
    '1050': '鉱業',
    '2050': '建設',
    '3050': '食料品',
    '3100': '繊維',
    '3150': 'パルプ紙',
    '3200': '化学',
    '3250': '医薬品',
    '3300': '石油石炭',
    '3350': 'ゴム',
    '3400': 'ガラス土石',
    '3450': '鉄鋼',
    '3500': '非鉄金属',
    '3550': '金属',
    '3600': '機械',
    '3650': '電気機器',
    '3700': '輸送用機器',
    '3750': '精密機器',
    '3800': 'その他製品',
    '4050': '電気ガス',
    '5050': '陸運',
    '5100': '海運',
    '5150': '空運',
    '5200': '倉庫運輸',
    '5250': '情報通信',
    '6050': '卸売',
    '6100': '小売',
    '7050': '銀行',
    '7100': '証券等',
    '7150': '保険',
    '7200': 'その他金融',
    '8050': '不動産',
    '9050': 'サービス',
  };

  // マスタ読み込み完了済みか
  public masterLoaded = false;
  // 以下の変数はマスタ読み込み後に利用可能
  public date: Date | undefined;

  private basePriceUpdateQueue: Array<number> = [];

  public constructor(private ops: FlexOperatorService, private flexVersion: FlexVersion) {
    this.id = this.ops.initialize(this.send.bind(this), flexVersion);
    this.updateNumberSubscription = this.ops.updateNumber.subscribe(([id, updateNumber]) => {
      if (id !== this.id) {
        return;
      }
      this.onUpdateNumber(updateNumber);
    });
    this.heartbeatSubscription = this.ops.heartbeat.subscribe(([id, timestampLow, timestampHigh]) => {
      if (id !== this.id) {
        return;
      }
      this.onHeartbeat(timestampLow, timestampHigh);
    });
    this.basePriceSubscription = this.ops.basePrice$.subscribe(([id, issueCodeMap]) => {
      if (id !== this.id) {
        return;
      }
      this.basePriceUpdateQueue.push(issueCodeMap);
    });
    this.authErrorSubscription = this.ops.authError.subscribe((id) => {
      if (id !== this.id) {
        return;
      }
      throw new AuthError();
    });
    this.marketFinishedSubscription = this.ops.marketFinished.subscribe((id) => {
      if (id !== this.id) {
        return;
      }
      this.marketFinished = true;
      this.marketFinishedSubject.next(true);
      this.marketFinishedSubject.complete();
    });
  }

  public dispose() {
    if (this.updateNumberSubscription) {
      this.updateNumberSubscription.unsubscribe();
      this.updateNumberSubscription = null;
    }
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
      this.heartbeatSubscription = null;
    }
    if (this.authErrorSubscription) {
      this.authErrorSubscription.unsubscribe();
      this.authErrorSubscription = null;
    }
    if (this.marketFinishedSubscription) {
      this.marketFinishedSubscription.unsubscribe();
      this.marketFinishedSubscription = null;
    }
    this.marketFinishedSubject.complete();
  }

  private onUpdateNumber(updateNumber: Uint32Array) {
    this.websocketFrameNumber = updateNumber;
    this.websocketFrameNumberReceived.next(this.websocketFrameNumber);
    this.websocketFrameNumberReceived.complete();
  }

  public prepareReconnect() {
    this.websocketFrameNumberReceived = new Subject<Uint32Array>();
  }

  private onHeartbeat(timestampLow: number, timestampHigh) {
    const nanoSec =
      ((timestampLow % 1000000000) + (((39 * ((6144 * (1231 * timestampHigh)) % 1000000000)) % 1000000000) % 1000000000)) % 1000000000;
    const sec = Math.round((timestampLow - nanoSec) / 1000000000 + timestampHigh * 4.294967296);
    this.serverTimestamp = new TimestampPair(new Date(sec * 1000 + nanoSec * 1e-6), new Date());
    this.lastHeartbeat = new Date(sec * 1000 + nanoSec * 1e-6);
  }

  private send(buf: Uint8Array) {
    this.onSendDataSubject.next(buf);
  }

  public push(buf: Uint8Array): void {
    this.ops.push(this.id, buf);
  }

  public pushWs(buf: Uint8Array): [number, number] {
    return this.ops.pushWs(this.id, buf);
  }

  public getStockMaster(): Array<StockMaster> {
    const master = this.ops.getStockMaster(this.id);
    const tick1 = new Tick(this, 1);
    const tick3 = new Tick(this, 3);
    for (const m of master) {
      m.tick = m.tickType === 1 ? tick1 : m.tickType === 3 ? tick3 : null;
    }
    return master;
  }

  public applyBasePrice(): number {
    if (this.basePriceUpdateQueue.length === 0) {
      return 0;
    }
    let count = 0;
    const newMaster = this.ops.getStockMaster(this.id);
    for (const issueId of this.basePriceUpdateQueue) {
      this.stockMasters[issueId].limitUp10 = newMaster[issueId].limitUp10;
      this.stockMasters[issueId].limitDown10 = newMaster[issueId].limitDown10;
      this.stockMasters[issueId].maxItaRowCount = newMaster[issueId].maxItaRowCount;
      count++;
      this.basePriceUpdatedSubject.next([issueId, newMaster[issueId].issueCode]);
    }
    this.basePriceUpdateQueue.splice(0, this.basePriceUpdateQueue.length);
    return count;
  }

  public updateSummary(summary: Summary) {
    if (summary.type === SummaryType.SmartList) {
      return;
    }
    if (summary.id === null || summary.id === undefined) {
      return;
    }
    return this.ops.updateSummary(this.id, summary);
  }

  public loadMaster(): boolean {
    if (this.masterLoaded) {
      return true;
    }
    this.stockMasters = this.getStockMaster();
    this.ops.applyBasePriceQueue(this.id);
    if (this.stockMasters && this.stockMasters.length > 0) {
      this.masterLoaded = true;
      this.portfolios = [];
      for (let i = 0; i < this.stockMasters.length; i++) {
        this.portfolios.push(new Portfolio(OHLC_LENGTH));
        this.issueCodeMap[this.stockMasters[i].issueCode] = i;
        this.stockInfos.push(new StockInfo());
      }
      if (!this.updatePortfolios()) {
        throw new Error('Failed to initialize portfolio');
      }
      const d = this.ops.getDate(this.id).toString(10).padStart(8, '0');
      this.date = new Date(parseInt(d.slice(0, 4), 10), parseInt(d.slice(4, 6), 10) - 1, parseInt(d.slice(6, 8), 10));
      return true;
    }
    return false;
  }

  public updatePortfolios(target?: Uint16Array): boolean {
    let update = false;
    if (target) {
      const p: Array<Portfolio> = [];
      const issueCodes: Array<number> = [];
      for (let i = 0; i < target.length; i++) {
        const id = target[i];
        p.push(this.portfolios[id]);
        issueCodes.push(this.portfolios[id].issueCode);
      }
      this.ops.getPortfolios(this.id, issueCodes, p);
      if (p.length > 0) {
        update = true;
      }
    } else {
      if (!this.ops.getAllPortfolio(this.id, this.portfolios)) {
        return false;
      }
      update = true;
    }
    const flexTime = this.getTimestamp();
    const serverTimestamp = this.serverTimestamp ? this.serverTimestamp.getServerTimestamp(new Date()) : new Date();
    for (let i = 0; i < this.portfolios.length; i++) {
      if (this.portfolios[i].hitType !== QRRowType.None && this.portfolios[i].hitType !== QRRowType.Lazy) {
        this.portfolios[i].hitClose = false;
        if (this.portfolios[i].issueStatus === IssueStatus.EndOfSession) {
          if (flexTime >= MARKET_CLOSE_TIMESTAMP * 1000 && this.portfolios[i].hitTime === MARKET_CLOSE_TIMESTAMP * 1000) {
            this.portfolios[i].hitClose = true;
          }
        }
        if (this.portfolios[i].hitDateTimeCache !== this.portfolios[i].hitTime) {
          this.portfolios[i].hitDateTimeCache = this.portfolios[i].hitTime;
          this.portfolios[i].hitDateTime = new Date(
            Date.UTC(
              this.date.getFullYear(),
              this.date.getMonth(),
              this.date.getDate(),
              Math.trunc(this.portfolios[i].hitTime / 60 / 60 / 1000000) - 9,
              Math.trunc(this.portfolios[i].hitTime / 60 / 1000000) % 60,
              Math.trunc(this.portfolios[i].hitTime / 1000000) % 60,
              Math.trunc(this.portfolios[i].hitTime / 1000) % 1000
            )
          );
          update = true;
        }
        if (differenceInSeconds(serverTimestamp, this.portfolios[i].hitDateTime) > 30 && !this.portfolios[i].hitClose) {
          this.portfolios[i].hitType = QRRowType.None;
          update = true;
        }
      }
      if (this.portfolios[i].specialQuoteTime && this.portfolios[i].specialQuoteDateTimeCache !== this.portfolios[i].specialQuoteTime) {
        this.portfolios[i].specialQuoteDateTime = new Date(
          Date.UTC(
            this.date.getFullYear(),
            this.date.getMonth(),
            this.date.getDate(),
            Math.trunc(this.portfolios[i].specialQuoteTime / 60 / 60 / 1000000) - 9,
            Math.trunc(this.portfolios[i].specialQuoteTime / 60 / 1000000) % 60,
            Math.trunc(this.portfolios[i].specialQuoteTime / 1000000) % 60,
            Math.trunc(this.portfolios[i].specialQuoteTime / 1000) % 1000
          )
        );
        this.portfolios[i].specialQuoteDateTimeCache = this.portfolios[i].specialQuoteTime;
      }
    }
    return update;
  }

  public updateSummaries(): boolean {
    let ts: number;
    if (this.serverTimestamp) {
      const t = subSeconds(this.serverTimestamp.getServerTimestamp(new Date()), 30);
      const h = (t.getUTCHours() + 9) % 24;
      ts = (h * 60 * 60 + t.getMinutes() * 60 + t.getSeconds()) * 1000 + t.getMilliseconds();
      if (ts >= MARKET_CLOSE_TIMESTAMP) {
        ts = MARKET_CLOSE_TIMESTAMP;
      }
    } else {
      ts = MARKET_CLOSE_TIMESTAMP;
    }
    for (let i = 0; i < this.summaries.length; i++) {
      if (!this.ops.getSummaryPortfolio(this.id, this.summaries[i].id, this.summaryPortfolios[i], ts)) {
        return false;
      }
    }
    return true;
  }

  public addSummary(summary: Summary) {
    if (summary.type === SummaryType.SmartList) {
      return;
    }
    summary.id = this.ops.addSummary(this.id);
    this.ops.updateSummary(this.id, summary);
    this.summaries.push(summary);
    this.summaryPortfolios.push(new SummaryPortfolio());
  }

  public createStockView(issueCodeIdx: number, useCache = true, update = true): StockView {
    const view = new StockView(issueCodeIdx, this.stockMasters[issueCodeIdx], this.stockInfos[issueCodeIdx], this.flexVersion, OHLC_LENGTH);
    if (useCache) {
      view.itaRowPriceIndex = this.itaRowCache[issueCodeIdx];
    }
    if (update) {
      this.updateStockView(view);
    }
    return view;
  }

  public cloneStockView(view: StockView): StockView {
    const newId = this.ops.cloneStockView(this.id, view);
    const newView = new StockView(newId, view.master, view.info, this.flexVersion, OHLC_LENGTH);
    newView.itaRowPriceIndex = view.itaRowPriceIndex;
    newView.itaRowCount = view.itaRowCount;
    newView.rows.splice(0, newView.rows.length);
    for (let i = 0; i < newView.itaRowCount; i++) {
      newView.rows.push(new ItaRow());
    }
    this.updateStockView(newView);
    return newView;
  }

  public updateStockView(view: StockView, skipIta = false, skipQr = false, forceUpdateIta = true, miniMode = false): boolean {
    const oldFrame = view.frame;
    if (!this.ops.getStockView(this.id, view.id, view)) {
      console.error('Failed to get base');
      return false;
    }
    if (view.frame !== oldFrame) {
      forceUpdateIta = true;
    }
    const lastPriceOrBasePrice10 = view.lastPrice10 || view.master.basePrice10;
    let middlePrice10 =
      view.askPrice10 === 0 ? view.bidPrice10 : view.bidPrice10 === 0 ? view.askPrice10 : (view.bidPrice10 + view.askPrice10) / 2;
    if (middlePrice10) {
      view.itaCenterPrice10Raw = middlePrice10;
      middlePrice10 = this.roundPrice10NearTarget(middlePrice10, lastPriceOrBasePrice10, view.master.tick);
    } else {
      view.itaCenterPrice10Raw = 0;
    }
    view.itaCenterPrice10 = middlePrice10;
    if (view.quoteFlag === QuoteFlag.SQ || view.quoteFlag === QuoteFlag.CEQ) {
      if (view.quoteSide === Side.Bid) {
        view.itaCenterPrice10 = view.bidPrice10;
      } else {
        view.itaCenterPrice10 = view.askPrice10;
      }
    }
    if (view.itaCenterPrice10 === 0) {
      view.itaCenterPrice10 = lastPriceOrBasePrice10;
    }
    if (view.itaCenterPrice10Raw === 0) {
      view.itaCenterPrice10Raw = view.itaCenterPrice10;
    }
    if (!skipIta && view.itaRowCount !== null) {
      if (view.itaRowCountUpdated) {
        view.itaRowPriceIndexUpdated = true;
        if (view.itaRowPriceIndex !== undefined && view.itaRowPriceIndex !== null) {
          view.itaRowPriceIndex -= view.itaRowCount - view.rows.length;
        }
        while (view.rows.length < view.itaRowCount) {
          view.rows.push(new ItaRow());
        }
        while (view.itaRowCount < view.rows.length) {
          view.rows.pop();
        }
      }
      if (view.itaRowCount !== null && (view.itaRowPriceIndex === undefined || view.itaRowPriceIndex === null)) {
        view.itaRowPriceIndex = this.ops.fitItaViewRowPrice10(this.id, view.id, view.itaCenterPrice10, view.itaRowCount);
        view.itaRowPriceIndexUpdated = false;
      }
      if (view.itaRowPriceIndexUpdated) {
        view.itaRowPriceIndex = this.ops.fitItaViewRowPriceIndex(
          this.id,
          view.id,
          view.itaRowPriceIndex + Math.trunc(view.itaRowCount / 2),
          view.itaRowCount
        );
        view.itaRowPriceIndexUpdated = false;
      }
      if (view.itaRowCount !== null) {
        if (forceUpdateIta) {
          if (
            !this.ops.getItaRow(
              this.id,
              view.id,
              view.itaRowPriceIndex,
              view.itaRowCount,
              view.rows,
              view.market,
              view.over,
              view.under,
              miniMode
            )
          ) {
            return false;
          }
        }
        // console.log('getItaRow');
      }
    }
    if (!skipQr) {
      const lastFrameNumber = view.qr.length === 0 ? 0 : view.qr[view.qr.length - 1].frameNumber + 1;
      const newQr = this.ops.getQR(this.id, view.id, lastFrameNumber);
      for (const row of newQr) {
        view.qr.push(row);
      }
      while (view.qr.length > 0 && view.frame < view.qr[view.qr.length - 1].frameNumber) {
        view.qr.pop();
      }
    }
    if (view.specialQuoteTime) {
      if (view.specialQuoteTimeCache !== view.specialQuoteTime) {
        view.specialQuoteTimeCache = view.specialQuoteTime;
        view.specialQuoteDateTime = new Date(
          Date.UTC(
            this.date.getFullYear(),
            this.date.getMonth(),
            this.date.getDate(),
            Math.trunc(view.specialQuoteTime / 60 / 60 / 1000000) - 9,
            Math.trunc(view.specialQuoteTime / 60 / 1000000) % 60,
            Math.trunc(view.specialQuoteTime / 1000000) % 60,
            Math.trunc(view.specialQuoteTime / 1000) % 1000
          )
        );
      }
    } else {
      view.specialQuoteDateTime = null;
      view.specialQuoteTimeCache = null;
    }
    return true;
  }

  private roundPrice10NearTarget(price10: number, target10: number, tick: Tick): number {
    const up = tick.roundUpPrice(price10);
    if (up === price10) {
      return up;
    }
    const pr = tick.indexToPrice10(tick.price10ToIndex(price10));

    if (Math.abs(pr - target10) < Math.abs(up - target10)) {
      return pr;
    } else {
      return up;
    }
  }

  public unserialize(buf: Uint8Array) {
    this.ops.unserialize(this.id, buf);
  }

  public getFrameNumbers(): Uint32Array {
    return this.ops.getFrameNumbers(this.id);
  }

  public apiStart() {
    this.ops.apiRecieved(this.id);
  }

  public goUpdateNumber(view: StockView, frameNumber) {
    this.ops.goUpdateNumber(this.id, view, frameNumber);
  }

  public getRenewalPrice10(price10: number): number {
    return this.ops.getRenewalPrice(this.id, price10);
  }

  public getTimestamp(): number {
    return this.ops.getTime(this.id);
  }

  public setStockInfo(volumes: Array<StockVolume>) {
    for (const volume of volumes) {
      if (volume.issue_code in this.issueCodeMap) {
        const issueCodeIdx = this.issueCodeMap[volume.issue_code];
        this.stockInfos[issueCodeIdx].lastDayTurnover = volume.turnover;
        this.stockInfos[issueCodeIdx].calcSharesOutstanding = volume.calc_shares_outstanding;
      }
    }
  }

  public sessionDone(): boolean {
    if (this.done) {
      return true;
    } else {
      this.done = this.ops.getSessionDone(this.id);
      return this.done;
    }
  }

  public createTrace(): Trace {
    return new Trace(this.ops.addTrace(this.id), this);
  }

  public pushExceptionalSQ(issueCode: number, side: number, range10: number, sec: number, limitDown10: number, limitUp10: number): boolean {
    return this.ops.pushExceptionalSQ(this.id, issueCode, side, range10, sec, limitDown10, limitUp10);
  }

  clearTrace(traceId: number): void {
    this.ops.clearTrace(this.id, traceId);
  }

  getRenewalPrice(price10: number): number {
    return this.ops.getRenewalPrice(this.id, price10);
  }

  getTrace(traceId: number): Uint16Array {
    return this.ops.getTrace(this.id, traceId);
  }

  hasTrace(traceId: number, issueId: number): boolean {
    return this.ops.hasTrace(this.id, traceId, issueId);
  }

  tickIndexToPrice10(tickType: number, index: number): number {
    return this.ops.tickIndexToPrice10(this.id, tickType, index);
  }

  tickPrice10ToIndex(tickType: number, price10: number): number {
    return this.ops.tickPrice10ToIndex(this.id, tickType, price10);
  }

  pushBasePrice(bp: BasePriceUpdate) {
    this.ops.pushBasePrice(this.id, this.issueCodeMap[bp.issueCode], bp.basePrice10, bp.limitDown10, bp.limitUp10);
    this.basePriceUpdateQueue.push(this.issueCodeMap[bp.issueCode]);
  }
}
