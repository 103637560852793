export enum SummaryType {
  Index,
  WatchList,
  Stock,
  SmartList,
  Market,
  Master,
  Industry,
  // 閲覧履歴
  History,
  // 売買履歴
  OrderHistory,
  // サーバーから起動時に取得
  Server,
}

export class Summary {
  /**
   * サマリーに含まれる銘柄コードと比率
   */
  public readonly issueCodes: { [key: number]: number };

  public readonly issueList: Array<[number, number]>;

  public issueCount = 0;

  /***
   * サマリーの内部ID。起動毎に変化しうる。
   */
  public id: number;

  /**
   * @param summaryId サマリーを一意に識別するためのID。起動毎に変化しないことを想定する。
   * @param name サマリーの名称
   */
  constructor(public type: SummaryType, public summaryId: string, public name: string, public position: number) {
    this.issueCodes = {};
    this.issueList = [];
    this.id = null;
  }

  public addIssue(issueCode: number, weight = 1) {
    if (!(issueCode in this.issueCodes)) {
      this.issueCodes[issueCode] = 0;
    }
    if (this.issueCodes[issueCode] === 0) {
      this.issueCount++;
    }
    this.issueCodes[issueCode] += weight;
    this.issueList.push([issueCode, weight]);
    // console.assert(Object.values(this.issueCodes).filter(a => a).length === this.issueCount);
  }

  public removeIssue(index: number) {
    const [issueCode, weight] = this.issueList[index];
    this.issueList.splice(index, 1);
    this.issueCodes[issueCode] -= weight;
    if (this.issueCodes[issueCode] === 0) {
      this.issueCount--;
    }
    // console.assert(Object.values(this.issueCodes).filter(a => a).length === this.issueCount);
  }

  public changeIssue(index: number, newIssueCode: number, newWeight = 1) {
    const [issueCode, weight] = this.issueList[index];
    this.issueCodes[issueCode] -= weight;
    if (this.issueCodes[issueCode] === 0) {
      this.issueCount--;
    }
    if (!(newIssueCode in this.issueCodes)) {
      this.issueCodes[newIssueCode] = 0;
    }
    if (this.issueCodes[newIssueCode] === 0) {
      this.issueCount++;
    }
    this.issueCodes[newIssueCode] += newWeight;
    this.issueList[index] = [newIssueCode, newWeight];
    // console.assert(Object.values(this.issueCodes).filter(a => a).length === this.issueCount);
  }

  public clear() {
    for (const key of Object.keys(this.issueCodes)) {
      delete this.issueCodes[key];
    }
    this.issueList.splice(0, this.issueList.length);
    this.issueCount = 0;
  }
}
