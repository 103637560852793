import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Group, PortfolioComponent, SummaryPortfolio, SummaryPortfolioFromStock } from '../portfolio/portfolio.component';
import { CollectionView } from '@grapecity/wijmo';
import { Summary, SummaryType } from '@argentumcode/brisk-common';
import { CocomeroService } from '../../core/cocomero.service';
import { InputDialogService } from '../../shared/input-dialog.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SummaryChangeEvent, SummaryChangeType, UpdateSummaryType } from '../../core/summary-change-event';
import { ShowIndexDialogComponent } from '../show-index-dialog/show-index-dialog.component';
import { SmartPasteService } from '../smart-paste.service';
import { CsvImportDialogComponent } from '../csv-import-dialog/csv-import-dialog.component';
import { WatchListService } from '../../core/watch-list.service';
import {
  AssistService,
  CellEditingEvent,
  FirstTutorialComponent,
  IGroupDescription,
  ResizeService,
  RowDeletingEvent,
  SortDescription,
  SortDirection,
  TimeProvider,
  Toast,
  ToasterService,
  ToastType,
  VgCollectionView,
} from '@argentumcode/brisk-common';
import { CsvExportDialogComponent } from '../csv-export-dialog/csv-export-dialog.component';
import { SmartListSummary } from '../../core/summary.service';
import { NewPortfolioEntry, Portfolio, StockPortfolio } from '../portfolio';
import { StockListItem, StockListViewMode, SummaryListService } from '../summary-list.service';
import { TutorialActionSelectPickupLarge, TutorialService, TutorialStepType } from '../../tutorial/tutorial.service';
import { DialogResult, SmartPasteDialogComponent } from '../smart-paste-dialog/smart-paste-dialog.component';

class GroupDescriptionFromType extends IGroupDescription {
  constructor() {
    super();
  }

  group(item: any): any {
    const portfolio = item as SummaryPortfolio;
    switch (portfolio.group) {
      case Group.Registered:
        return '登録銘柄リスト';
      case Group.Smart:
        return '注目・ランキング';
      case Group.Segment:
        return '市場区分';
      case Group.Other:
        return 'その他';
      case Group.Industry:
        return '業種';
      case Group.Financial:
        return 'ファイナンス';
    }
  }

  defaultCollapsed(items: Array<SummaryPortfolio>): boolean {
    if (items.length === 0) {
      return false;
    }
    const portfolio = items[0];

    switch (portfolio.group) {
      case Group.Registered:
        return false;
      case Group.Smart:
        return false;
      case Group.Segment:
        return false;
      case Group.Other:
        return true;
      case Group.Industry:
        return true;
    }
  }
}

@Component({
  selector: 'app-summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryListComponent implements OnInit, OnChanges, OnDestroy {
  public ViewMode = StockListViewMode;

  private stockLists: Array<StockListItem> = [];

  public currentStockList: StockListItem = null;

  private stockListsSubject = new BehaviorSubject<Array<StockListItem>>([]);
  public stockLists$: Observable<Array<StockListItem>> = this.stockListsSubject.asObservable();

  @ViewChild('summaryPortfolio', { static: true })
  public summaryPortfolio: PortfolioComponent;

  @ViewChild('showIndexDialog', { static: true })
  public showIndexDialog: ShowIndexDialogComponent;

  @ViewChild('csvImportDialog', { static: true })
  public csvImportDialog: CsvImportDialogComponent;

  @ViewChild('csvExportDialog', { static: true })
  public csvExportDialog: CsvExportDialogComponent;

  @ViewChild('summaryPortfolioContainer', { static: true })
  public summaryPortfolioContainer: ElementRef;

  @ViewChild('summaryPortfolioPosition', { static: true })
  private summaryPortfolioPosition: ElementRef;

  @ViewChild('smartPasteTutorial')
  private _smartPasteTutorial: FirstTutorialComponent;

  @ViewChild('smartPasteDialog', { static: true })
  private smartPasteDialog: SmartPasteDialogComponent;

  @Input()
  public timeProvider: TimeProvider;
  @Input()
  viewMode: StockListViewMode;
  @Input()
  showAssistToBody = false;
  @Output()
  viewModeChange = new EventEmitter<StockListViewMode>();

  @Output()
  cellDblClicked = new EventEmitter();

  @Output()
  selectionChanged = new EventEmitter();

  public portfolios: CollectionView = null;
  private readonly _summaries = new Array<SummaryPortfolio>();
  private readonly _summariesSubject = new BehaviorSubject(this._summaries);
  public readonly summaryDataView: VgCollectionView = new VgCollectionView(this._summariesSubject.asObservable());
  public editable = false;

  public currentSummary: Portfolio = null;

  private summaryChangeSubscription: Subscription = null;
  private updateFrameSubscription: Subscription = null;
  private currentItemRequestSubscription: Subscription = null;
  private closeSubscription: Subscription = null;
  private closeAllSubscription: Subscription = null;
  private selectFirstSubscription: Subscription = null;
  private tutorialSubscription: Subscription = null;
  private summaryListChangedSubscription: Subscription = null;

  private setupInitialSummary = false;

  private tutorialWaiting = false;

  get tutorialMode(): boolean {
    return this.tutorial.tutorial;
  }

  get assist$(): Observable<boolean> {
    return this.assist.assist$;
  }

  constructor(
    private cocomero: CocomeroService,
    private changeDetectorRef: ChangeDetectorRef,
    private inputDialog: InputDialogService,
    private smartPaste: SmartPasteService,
    private watchList: WatchListService,
    private resize: ResizeService,
    private toaster: ToasterService,
    private tutorial: TutorialService,
    private assist: AssistService,
    private summaryListService: SummaryListService
  ) {
    this.currentItemRequestSubscription = this.summaryListService.currentStockListItemRequest$.subscribe((item) => {
      this.currentStockList = item;
      this.summaryListService.updateCurrentItem(this.currentStockList);
      this.summaryPortfolio.setCurrentItem(item.summary);
    });
    this.summaryListService.updateStockList(this.stockLists$);
    this.closeSubscription = this.summaryListService.closeRequest$.subscribe((item) => {
      this.closeStockList(item);
    });
    this.closeAllSubscription = this.summaryListService.closeAllRequest$.subscribe(() => {
      for (const item of this.stockLists) {
        this.closeStockList(item);
      }
    });
    this.selectFirstSubscription = this.summaryListService.selectFirstRequest$.subscribe(() => {
      if (this._summaries[0]) {
        this.summaryPortfolio.setCurrentItem(this._summaries[0]);
      }
    });

    this.tutorialSubscription = this.tutorial.stop$.subscribe(() => {});

    this.tutorialSubscription.add(
      this.tutorial.step$.subscribe((step) => {
        if (step && step.type === TutorialStepType.WaitSelectPickupLarge) {
          this.tutorial.startOverlay(this.summaryPortfolioContainer.nativeElement);
          this.tutorial.setTutorialStepLeft(this.summaryPortfolioPosition.nativeElement);

          for (const portfolio of this._summaries) {
            if (portfolio.summary && portfolio.summary.summaryId === `watchlist:1`) {
              portfolio.highlightName = true;
            }
          }
          this._summariesSubject.next(this._summaries);
          this.tutorialWaiting = true;
          this.summaryPortfolio.focus();
        } else {
          this.tutorialWaiting = false;
          for (const portfolio of this._summaries) {
            if (portfolio.summary && portfolio.summary.summaryId === `watchlist:1`) {
              portfolio.highlightName = false;
            }
          }
          this._summariesSubject.next(this._summaries);
        }
      })
    );
  }

  private _initializeSummary() {
    let index = 0;
    this._summaries.splice(0, this._summaries.length);
    for (const [summary, summaryPortfolio] of this.cocomero.summaries.getSummaryPortfolios()) {
      if (summary.type === SummaryType.Stock) {
        const [portfolio, master, info] = this.cocomero.summaries.getNewStockPortfolio(Number(summary.summaryId.split(':')[1]));
        this._summaries.push(
          <SummaryPortfolio>(<any>new SummaryPortfolioFromStock(++index, summary, summaryPortfolio, portfolio, master, info))
        );
      } else {
        this._summaries.push(new SummaryPortfolio(++index, summary, summaryPortfolio));
      }
    }
    this.cocomero.summaries.update();
    this.setupInitialSummary = true;
    this.summaryDataView.groupDescription = new GroupDescriptionFromType();
    this.summaryDataView.sortDescriptions.push(new SortDescription('group', SortDirection.Asc));
    this.summaryDataView.sortDescriptions.push(null);
    this.summaryDataView.sortDescriptions.push(new SortDescription('index', SortDirection.Asc));

    if (this._summaries.length > 0 && !this.tutorialMode) {
      this.summaryPortfolio.setCurrentItem(this._summaries[0]);
    }
    this.summaryDataView.update((header) => {
      if (header.name === '業種' || header.name === 'その他') {
        header.collapsed = true;
      }
    });
  }

  ngOnInit() {
    this.cocomero.summaryInitialized.subscribe(() => {
      this._initializeSummary();

      this.summaryChangeSubscription = this.cocomero.summaries.summaryChanged.subscribe((e) => {
        this.onSummaryChanged(e);
      });
    });
    this.summaryListChangedSubscription = this.cocomero.summaries.summaryListChanged.subscribe(() => {
      this._initializeSummary();
    });
    this.updateFrameSubscription = this.cocomero.updateFrame.subscribe((summary) => {
      this.refresh(summary);
    });
  }

  ngOnDestroy() {
    if (this.summaryChangeSubscription) {
      this.summaryChangeSubscription.unsubscribe();
      this.summaryChangeSubscription = null;
    }
    if (this.updateFrameSubscription) {
      this.updateFrameSubscription.unsubscribe();
      this.updateFrameSubscription = null;
    }
    if (this.currentItemRequestSubscription) {
      this.currentItemRequestSubscription.unsubscribe();
      this.currentItemRequestSubscription = null;
    }
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
      this.closeSubscription = null;
    }
    if (this.closeAllSubscription) {
      this.closeAllSubscription.unsubscribe();
      this.closeAllSubscription = null;
    }
    if (this.selectFirstSubscription) {
      this.selectFirstSubscription.unsubscribe();
      this.selectFirstSubscription = null;
    }
    if (this._summariesSubject) {
      this._summariesSubject.complete();
    }
    if (this.stockListsSubject) {
      this.stockListsSubject.complete();
    }
    if (this.tutorialSubscription) {
      this.tutorialSubscription.unsubscribe();
      this.tutorialSubscription = null;
    }
    if (this.summaryListChangedSubscription) {
      this.summaryListChangedSubscription.unsubscribe();
    }
  }

  private selectItem(item: any) {
    let newItem = false;
    if (!this.currentStockList) {
      if (this.tutorialMode && !this.tutorialWaiting) {
        return;
      }
      this.currentStockList = new StockListItem();
      this.summaryListService.updateCurrentItem(this.currentStockList);
      this.stockLists.push(this.currentStockList);
      this.stockListsSubject.next(this.stockLists);
      newItem = true;
    }
    if (this.currentStockList.summary === item) {
      return;
    }
    this.currentStockList.summary = item;
    if (item.summary.type === SummaryType.WatchList) {
      this.currentStockList.editable = true;
    } else {
      this.currentStockList.editable = false;
    }
    if (item.summary.type === SummaryType.SmartList) {
      if (item.summary instanceof SmartListSummary) {
        item.summary.update(this.cocomero.op);
      }
    }
    if (this.currentStockList.portfolios) {
      this.currentStockList.portfolios.moveCurrentToPosition(-1);
    }
    const r: Array<Portfolio> = [];
    r.splice(0, r.length);
    let index = 0;
    for (const [portfolio, master, info] of this.cocomero.summaries.getStockPortfolio(item.summary.issueList)) {
      r.push(new StockPortfolio(master, portfolio, info, this.cocomero.time, ++index));
    }
    this.currentStockList.stockListUpdatedSubject.next();
    this.currentStockList.portfolios = new CollectionView(r);
    this.currentStockList.stocks = r;
    this.currentStockList.portfolios.moveCurrentToPosition(-1);
    this.currentStockList.portfolios.trackChanges = true;
    this.resetCollectionView(this.currentStockList.portfolios);
    if (this.currentStockList.editable) {
      r.push(new NewPortfolioEntry());
    }
    this.refresh();
    this.currentStockList.portfolios.refresh();
    this.stockListsSubject.next(this.stockLists);
    this.summaryListService.selectItem(item.summary.summaryId, newItem);
  }

  public onCellCtrlClick(data: any) {
    if (this.tutorialMode) {
      return;
    }
    console.log(data);
    if (!(data[0] instanceof SummaryPortfolio) && !(data[0] instanceof SummaryPortfolioFromStock)) {
      return;
    }
    this.currentStockList = null;
    this.selectItem(data[0]);
    this.summaryListService.updateCurrentItem(this.currentStockList);
  }

  onCellEditBeginning(event: CellEditingEvent) {
    if (this.tutorialMode) {
      event.cancel = true;
      return;
    }
    event.cancel = !event.item.summary || event.item.summary.type !== SummaryType.WatchList; // 登録銘柄リストのみ名前編集可能
  }

  onDragEnd() {
    this.resize.dispatchResize();
  }

  refresh(summaryRefresh = false) {
    if (summaryRefresh && this.summaryPortfolio) {
      // ソート条件 / またはフィルタ条件が存在する場合は更新する
      if (this.summaryDataView.sortDescriptions[1] || this.summaryDataView.filterDescriptions.length > 0) {
        this.summaryDataView.update();
      }
      this.summaryPortfolio.refresh();
    }
  }

  paste(event: ClipboardEvent) {
    if (this.tutorialMode) {
      return;
    }
    this.smartPaste.paste(event).subscribe((result) => {
      if (result.canceled) {
        return;
      }
      if (result.emptyClipboard || result.issueCodes.length === 0) {
        this.smartPaste.showToaster(result);
        return;
      }
      let summary: Summary;
      let watchListName: string = null;
      if (this.currentSummary instanceof SummaryPortfolio) {
        summary = this.currentSummary.summary;
        if (summary.type === SummaryType.WatchList) {
          watchListName = summary.name;
        }
      }
      this.smartPasteDialog.showDialog(watchListName).subscribe((dialogResult: DialogResult) => {
        if (!dialogResult) {
          return;
        }
        if (dialogResult.addToList) {
          if (summary.type !== SummaryType.WatchList) {
            return;
          }
          for (const issueCode of result.issueCodes) {
            summary.addIssue(issueCode);
          }
          this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
          this.cocomero.summaries.saveWatchList(summary);
          this.smartPaste.showToaster(result);
        } else {
          const name = dialogResult.name.trim();
          if (name.length > 9) {
            this.toaster.add(new Toast(`登録銘柄リスト名「${name}」は9文字以内で入力してください`, ToastType.Error));
            return;
          }
          const newSummary = this.cocomero.summaries.addWatchList(name);
          if (newSummary === null) {
            this.toaster.add(new Toast(`登録銘柄リスト名「${name}」が重複しています`, ToastType.Error));
            return;
          }
          for (const issueCode of result.issueCodes) {
            newSummary.addIssue(issueCode);
          }
          this.cocomero.summaries.updateSummary(newSummary, UpdateSummaryType.AddIssues);
          this.cocomero.summaries.saveWatchList(newSummary);
          this.selectSummary(newSummary);
          this.smartPaste.showToaster(result);
        }
      });

      // this.inputDialog.dialog('登録銘柄リストの名前を入力してください', '登録銘柄リストの追加').subscribe(name => {
      //   if (name === null || name.trim() === '') {
      //     return;
      //   }
      //   name = name.trim();
      //   if (name.length > 9) {
      //     this.toaster.add(new Toast(`登録銘柄リスト名「${name}」は9文字以内で入力してください`, ToastType.Error));
      //     return;
      //   }
      //   const summary = this.cocomero.summaries.addWatchList(name);
      //   if (summary === null) {
      //     this.toaster.add(new Toast(`登録銘柄リスト名「${name}」が重複しています`, ToastType.Error));
      //     return;
      //   }
      //   for (const issueCode of result.issueCodes) {
      //     summary.addIssue(issueCode);
      //   }
      //   this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
      //   this.cocomero.summaries.saveWatchList(summary);
      //   this.selectSummary(summary);
      //   this.smartPaste.showToaster(result);
      // });
    });
  }

  onSummaryChanged(e: SummaryChangeEvent) {
    switch (e.type) {
      case SummaryChangeType.AddSummary: {
        let target = 0;
        for (let i = 0; i < this._summaries.length; i++) {
          if (this._summaries[i].summary.type !== SummaryType.WatchList) {
            target = i;
            break;
          }
        }
        const newList = new SummaryPortfolio(target + 1, e.summary, this.cocomero.summaries.getSummaryPortfolio(e.summary));
        this._summaries.splice(target, 0, newList);
        for (let i = target + 1; i < this._summaries.length; i++) {
          this._summaries[i].index = i + 1;
        }
        this._summariesSubject.next(this._summaries);
        break;
      }
      case SummaryChangeType.DeleteSummary: {
        for (let i = 0; i < this._summaries.length; i++) {
          if (this._summaries[i].summary === e.summary) {
            this._summaries.splice(i, 1);
            for (let j = i; j < this._summaries.length; j++) {
              this._summaries[j].index = j + 1;
            }
            break;
          }
        }
        break;
      }
    }
  }

  closeStockList(item: StockListItem) {
    const index = this.stockLists.indexOf(item);
    if (index === -1) {
      return;
    }
    this.stockLists = this.stockLists.filter((a) => a !== item);
    item.stockListUpdatedSubject.complete();
    this.stockListsSubject.next(this.stockLists);
    if (this.currentStockList === item) {
      this.currentStockList = null;
      if (this.stockLists.length > 0) {
        if (index < this.stockLists.length) {
          this.currentStockList = this.stockLists[index];
        } else {
          this.currentStockList = this.stockLists[index - 1];
        }
      }
      this.summaryListService.updateCurrentItem(this.currentStockList);
    }
    this.resize.dispatchResize();
  }

  onNameUpdated([item, newName]: [Portfolio, string]) {
    console.log(item, newName);
    if (item instanceof SummaryPortfolio) {
      if (item.summary.type !== SummaryType.WatchList) {
        return;
      }
      newName = newName.trim();
      if (newName === '') {
        return;
      }
      if (newName === item.name) {
        // 名前に変更がない場合はスキップ
        return;
      }
      if (newName.length > 9) {
        this.toaster.add(new Toast(`登録銘柄リスト名「${newName}」は9文字以内で入力してください`, ToastType.Error));
        return;
      }
      if (!this.watchList.validName(newName)) {
        this.toaster.add(new Toast(`登録銘柄リスト名「${newName}」が重複しています`, ToastType.Error));
        return;
      }
      item.summary.name = newName;
      this.cocomero.summaries.saveWatchList(item.summary);
    }
  }

  addNewIndex() {
    this.inputDialog.dialog('登録銘柄リスト名を入力してください', '登録銘柄リストの作成').subscribe((name) => {
      if (name === null || name.trim() === '') {
        return;
      }
      name = name.trim();
      if (name.length > 9) {
        this.toaster.add(new Toast(`登録銘柄リスト名「${name}」は9文字以内で入力してください`, ToastType.Error));
        return;
      }
      const summary = this.cocomero.summaries.addWatchList(name);
      if (summary === null) {
        this.toaster.add(new Toast(`登録銘柄リスト名「${name}」が重複しています`, ToastType.Error));
        return;
      }
      this.cocomero.summaries.saveWatchList(summary);
      this.selectSummary(summary);
    });
  }

  showIndexClicked() {
    this.showIndexDialog.show();
  }

  showIndex(indexes: Array<string>, temporary: boolean = false) {
    let item: Summary = null;
    let curIndex = 0;
    for (const [summary, summaryPortfolio] of this.cocomero.summaries
      .getSummaryPortfoliosFromSummaryId(indexes)
      .sort((a, b) => a[0].position - b[0].position)) {
      while (curIndex < this._summaries.length && this._summaries[curIndex].summary.position < summary.position) {
        curIndex++;
      }
      if (summary.type === SummaryType.Stock) {
        const [portfolio, master, info] = this.cocomero.summaries.getNewStockPortfolio(Number(summary.summaryId.split(':')[1]));
        const newItem = new SummaryPortfolioFromStock(curIndex + 1, summary, summaryPortfolio, portfolio, master, info);
        this._summaries.splice(curIndex, 0, <SummaryPortfolio>(<any>newItem));
        if (!item) {
          item = summary;
        }
      } else {
        const newItem = new SummaryPortfolio(curIndex + 1, summary, summaryPortfolio);
        this._summaries.splice(curIndex, 0, newItem);
        if (!item) {
          item = summary;
        }
      }
    }
    for (let i = 0; i < this._summaries.length; i++) {
      this._summaries[i].index = i + 1;
    }
    this._summariesSubject.next(this._summaries);
    this.cocomero.summaries.update();
    if (!temporary) {
      this.cocomero.summaries.show(indexes);
      this.selectSummary(item);
    }
  }

  importCSV() {
    this.csvImportDialog.show().subscribe((watchList) => {
      this.selectSummary(watchList);
    });
  }

  exportCSV() {
    const items = this.summaryPortfolio.selectedItems();
    const summaryId = [];
    for (const i of items) {
      if (i instanceof SummaryPortfolio) {
        if (i && i.summary && i.summary.summaryId) {
          summaryId.push(i.summary.summaryId);
        }
      }
    }
    this.csvExportDialog.show(summaryId);
  }

  hideSummaryList() {
    this.viewMode = StockListViewMode.Detail;
    this.viewModeChange.emit(this.viewMode);
    this.resize.dispatchResize();
    this.changeDetectorRef.markForCheck();
  }

  updateShowSummaryList() {
    this.viewMode = StockListViewMode.DetailWide;
    this.viewModeChange.emit(this.viewMode);
    this.resize.dispatchResize();
    setTimeout(() => {
      this.summaryPortfolio.refresh(true);
    });
  }

  selectSummary(summary: Summary) {
    const sc = this._summaries;
    let target = null;
    for (let i = 0; i < sc.length; i++) {
      if (sc[i].summary === summary) {
        target = sc[i];
      }
    }
    this.summaryPortfolio.setCurrentItem(target);
    // TODO: setTimeoutを利用せずに実現できないか
    setTimeout(() => {
      this.summaryPortfolio.scrollIntoView();
    });
  }

  private resetCollectionView(cv: CollectionView) {
    cv.canSort = true;
    cv.sortDescriptions.clear();
    cv.filter = null;
    cv.canFilter = true;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onSelectionChanged(item: Portfolio) {
    if (!(item instanceof SummaryPortfolio) && !(item instanceof SummaryPortfolioFromStock)) {
      return;
    }
    this.summaryListService.updateCurrentItem(this.currentStockList);
    this.currentSummary = item;
    if (item) {
      this.selectItem(item);
      if (this.setupInitialSummary) {
        this.selectionChanged.emit();
      }

      if (this.tutorialMode) {
        if (item.summary.summaryId === 'watchlist:1') {
          this.tutorial.dispatchAction(new TutorialActionSelectPickupLarge());
        }
      }
    }
  }

  onRowDeleting(event: RowDeletingEvent) {
    if (this.tutorialMode) {
      return;
    }
    let index = this._summaries.length + 1;
    event.itemSet.forEach((sp) => {
      index = Math.min(index, sp.index);
      this.cocomero.summaries.remove(sp.summary);
    });
    for (let i = index - 1; i < this._summaries.length; i++) {
      this._summaries[i].index = i + 1;
    }
    this.currentSummary = null;
    this._summariesSubject.next(this._summaries);
  }

  showTutorial() {
    if (this._smartPasteTutorial) {
      this._smartPasteTutorial.show();
    }
  }

  getSummaryPortfolio(summaryId: string): SummaryPortfolio | null {
    for (const s of this._summaries) {
      if (s.summary.summaryId === summaryId) {
        return s;
      }
    }
    return null;
  }

  getFirstPortfolio(): string | null {
    if (this._summaries.length > 0) {
      return this._summaries[0].summary.summaryId;
    }
    return null;
  }
}
