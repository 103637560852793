import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
  constructor() {}

  get nativeWindow(): Window {
    return window;
  }
}
