import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { ItaViewService } from '../../core/ita-view.service';
import { Subject, Subscription } from 'rxjs';
import { CocomeroService } from '../../core/cocomero.service';
import { PagedView, PegState, ResizeService, TimeProvider, VgCollectionView, VgGridComponent } from '@argentumcode/brisk-common';
import { ItaPortfolioItemComponent } from '../ita-portfolio-item/ita-portfolio-item.component';
import { throttleTime } from 'rxjs/operators';

export class ItaViewItem {
  private itaRowIndexUpdatedSubject = new Subject();
  public itaRowIndexUpdated = this.itaRowIndexUpdatedSubject.asObservable();

  constructor(public issueCode: number, public itaRowIndex = null) {}

  onItaRowIndexUpdated() {
    this.itaRowIndexUpdatedSubject.next();
  }
}

@Component({
  selector: 'app-ita-portfolio',
  templateUrl: './ita-portfolio.component.html',
  styleUrls: ['./ita-portfolio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItaPortfolioComponent implements OnInit, OnDestroy {
  private debouncer = new Subject<ItaPortfolioItemComponent>();

  @Input()
  get columns(): number {
    return this._columns;
  }

  set columns(value: number) {
    this._columns = value;
    this.columnIndexes = new Array(this._columns).fill(0).map((x, i) => i);
    if (this.dataSourceInput) {
      this._updateCollection();
    }
    this.changeDetectorRef.markForCheck();
  }

  get maxPage(): number {
    return this.pagedView ? this.pagedView.pages : null;
  }

  get currentPage(): number {
    return this.pagedView.current + 1;
  }

  set currentPage(value: number) {
    this.pagedView.current = value - 1;
    this._updateCollection();
  }

  @Input()
  get paging(): boolean {
    return this.pagedView ? true : false;
  }

  set paging(value: boolean) {
    if (value) {
      this.pagedView = new PagedView();
    } else {
      this.pagedView = null;
    }
    this._updateCollection();
  }

  pagedView: PagedView = null;

  @Input()
  get itaRows(): number {
    return this._itaRows;
  }

  set itaRows(value: number) {
    if (this._itaRows === value) {
      return;
    }
    this._itaRows = value;
    this.clearRowCount();
    this.scrollToTop();
    this.onResize();
  }

  @Input('dataSource')
  get dataSourceInput(): VgCollectionView {
    return this._dataSourceInput;
  }

  set dataSourceInput(value: VgCollectionView) {
    if (this._dataSourceInput === value) {
      return;
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    this._dataSourceInput = value;
    if (this._dataSourceInput) {
      this._dataSourceSubscription = this._dataSourceInput.items.subscribe((items) => {
        this._items = items;
        this._onCollectionChanged();
      });
    }
  }

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (this._visible === value) {
      return;
    }
    this._visible = value;
    if (value) {
      this._updateCollection();
      if (!this.started) {
        this.itaView.start();
        this.started = true;
      }
    } else {
      if (this.started) {
        this.itaView.stop();
        this.started = false;
      }
    }
  }

  @Input()
  public timeProvider: TimeProvider;

  private _visible: boolean;

  private started: boolean;
  private _dataSourceInput: VgCollectionView = null;
  private _dataSourceSubscription: Subscription = null;

  @Output()
  cellDblClicked = new EventEmitter<{}>();

  @Input()
  public peg: PegState;

  @Output()
  public pegChange = new EventEmitter<PegState>();

  private _items: Array<any> = [];

  private _itaRows = 10;

  @ViewChild('grid', { static: true })
  public grid: VgGridComponent;

  private _columns = 1;
  public readonly rowSizeCoefficient = 0.22;
  public dataSource: Array<Array<ItaViewItem>> = [];

  public columnIndexes = new Array(this._columns).fill(0).map((x, i) => i);

  private updatedViewSubject = new Subject<any>();
  public updatedView = this.updatedViewSubject.asObservable();

  @Input()
  public normalized = false;

  // 板の幅をItaComponentに計算させると、スクロールが停滞するので、親コンポーネントから渡す
  public itaWidth = 100;

  @Output()
  public normalizedChange = new EventEmitter<boolean>();

  @ViewChild('itaWidthPadding', { static: true })
  public itaWidthPadding: ElementRef;

  @Output()
  stockSelected = new EventEmitter<number>();

  public cache = new WeakMap();

  constructor(
    private itaView: ItaViewService,
    private cocomero: CocomeroService,
    private changeDetectorRef: ChangeDetectorRef,
    private resize: ResizeService
  ) {
    this.debouncer.pipe(throttleTime(60)).subscribe((item) => {
      this.onResizeImpl(item);
    });
  }

  ngOnInit() {
    if (this.visible && !this.started) {
      this.itaView.start();
    }
  }

  scroll(grid: FlexGrid, event: KeyboardEvent, direction: number) {
    const root = grid.hostElement.querySelector('[wj-part="root"]');
    if (root) {
      root.scrollTop += (root.clientHeight / 2) * direction;
      event.preventDefault();
    }
  }

  onInitialized(grid: FlexGrid) {
    this.onResize();
  }

  onUpdatedView(grid: FlexGrid) {
    this.updatedViewSubject.next();
  }

  onResizeImpl(itaItem?: ItaPortfolioItemComponent) {
    console.log('updRowHeight');
    if (!itaItem) {
      this.grid.rowHeight = window.innerWidth * this.rowSizeCoefficient;
      return;
    } else {
      const item: HTMLElement = <HTMLElement>itaItem.elementRef.nativeElement;
      if (item && item.offsetHeight > 0) {
        if (this.grid.rowHeight !== item.offsetHeight + 1) {
          this.grid.rowHeight = item.offsetHeight + 1;
        }
      }
    }
  }

  onResize(itaItem?: ItaPortfolioItemComponent) {
    if (itaItem) {
      this.debouncer.next(itaItem);
    }
  }

  private _onCollectionChanged() {
    if (!this.visible) {
      return;
    }
    this._updateCollection();
  }

  private _updateCollection() {
    let targetArray = this._items;
    if (this.pagedView) {
      this.pagedView.itemsPerPage = this.columns * 2; // 2行表示する
      this.pagedView.dataSource = this._items.filter((a) => a.issueCode);
      targetArray = this.pagedView.data;
    }

    this.dataSource = [];
    for (let i = 0; i < targetArray.length; i++) {
      if (targetArray[i].issueCode) {
        if (i % this._columns === 0) {
          this.dataSource.push([]);
        }

        const lastRow = this.dataSource[this.dataSource.length - 1];
        const item = this.cache.has(targetArray[i]) ? this.cache.get(targetArray[i]) : new ItaViewItem(Number(targetArray[i].issueCode));
        lastRow.push(item);
        this.cache.set(targetArray[i], item);
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  scrollToTop() {
    if (this.grid) {
      this.grid.scrollToTop();
      if (this.pagedView) {
        this.pagedView.current = 0;
      }
    }
  }

  onItemClicked(item: ItaViewItem) {
    if (item) {
      this.cocomero.changeIssueCode(item.issueCode);
      this.stockSelected.emit(item.issueCode);
    }
  }

  onItemDblClicked(item: ItaViewItem) {
    if (item) {
      this.cellDblClicked.emit();
    }
  }

  resetAll() {
    for (const row of this.dataSource) {
      for (const item of row) {
        item.itaRowIndex = null;
        item.onItaRowIndexUpdated();
      }
    }
  }

  clearRowCount() {
    this.itaView.clearRowCount();
    for (const row of this.dataSource) {
      for (const item of row) {
        item.itaRowIndex = null;
        item.onItaRowIndexUpdated();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.started) {
      this.itaView.stop();
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    if (this.updatedViewSubject) {
      this.updatedViewSubject.complete();
    }
    if (this.debouncer) {
      this.debouncer.complete();
    }
  }

  onColumnWidthChanged() {
    const width = this.grid.getColumnWidth(0);
    if (width) {
      const elem = <Element>this.itaWidthPadding.nativeElement;
      const padding = elem.scrollWidth;
      if (width - padding >= 0) {
        this.itaWidth = width - padding;
      }
    }
  }

  focus() {
    if (this.grid) {
      this.grid.focus();
    }
  }
}
