<app-fita4-fatal-error-dialog></app-fita4-fatal-error-dialog>
<brisk-toaster-container></brisk-toaster-container>
<app-input-dialog></app-input-dialog>
<app-modal-dialog></app-modal-dialog>
<app-restart-dialog></app-restart-dialog>
<app-fita4-splash *ngIf="!(initialized && orderInitialized && fita4Initialized)"></app-fita4-splash>
<ng-container *ngIf="initialized && orderInitialized && fita4Initialized">
  <div id="news">
    <app-fita4-ita-clock></app-fita4-ita-clock>
    <brisk-demo-markets-line></brisk-demo-markets-line>
    <button class="c-btn c-btn--default header__button" [class.active]="subWindowOpened" (click)="toggleFullIta()">
      発注フル板
      <brisk-first-tutorial
        class="header__button-tutor"
        #fullItaButtonTutorial
        placement="bottom bottom-left bottom-right"
        (closed)="closeFullItaButtonTutorial()"
      >
        ボタンをクリックして、発注フル板 Window を立ち上げましょう。<br />
        ドッキングレイアウトでお好きなところに何枚でもフル板を配置できます。
      </brisk-first-tutorial>
    </button>

    <button class="c-btn c-btn--default header__button" [class.active]="tradeShown" (click)="toggleTradePanel()" *ngIf="orderEnabled">
      取引パネル
    </button>
    <button class="c-btn c-btn--default header__button" (click)="tryOpenTedinet()" *ngIf="tedinetUrl">適時開示</button>
    <span ngbDropdown class="header__button" [container]="'body'" [className]="">
      <button
        i18n="@@commonThemeSelectorButton"
        ngbDropdownToggle
        class="c-btn c-btn--default dropdown-toggle p-btn-view-mode"
        id="laytouDropdownMenu"
      >
        レイアウト
      </button>
      <div class="c-dropdown-popup c-dropdown--options" ngbDropdownMenu aria-labelledby="laytouDropdownMenu">
        <button ngbDropdownItem (click)="resetLayout()">レイアウトの初期化</button>
      </div>
    </span>
    <button
      class="c-btn c-btn--default dropdown-toggle p-btn-view-mode header__button"
      (click)="showViewConfig = !showViewConfig"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      [class.active]="showViewConfig"
    >
      表示
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="showViewConfig"
      (detach)="showViewConfig = false"
      (backdropClick)="showViewConfig = false"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'c-backdrop-none'"
    >
      <div class="c-dropdown-popup c-dropdown--options">
        <app-fita4-view-options (closeRequest)="showViewConfig = false"></app-fita4-view-options>
      </div>
    </ng-template>
    <app-fita4-trade-config class="header__button" *ngIf="orderEnabled"></app-fita4-trade-config>

    <span *ngIf="helps" ngbDropdown class="header__button" [container]="'body'" [className]="">
      <button ngbDropdownToggle class="c-btn c-btn--default dropdown-toggle p-btn-view-mode">ヘルプ</button>
      <div class="c-dropdown-popup" ngbDropdownMenu aria-labelledby="dropdownHelpMenu">
        <button *ngFor="let help of helps" class="dropdown-item" [ngClass]="help.class ? help.class : ''" (click)="openHelp(help)">
          {{ help.name }}
        </button>
      </div>
    </span>

    <button class="c-btn c-btn--open-e-shiten header__button" (click)="openEShitenPage()">立花証券e支店に口座開設</button>
  </div>
  <div id="mainSplitWrapper" [ngClass]="viewMode === ViewMode.Detail ? 'u-detail-mode' : 'u-detail-wide-mode'">
    <app-summary-list
      #summaryList
      class="p-summary-list--normal-mode"
      [ngClass]="viewMode === ViewMode.Detail ? 'u-detail-mode' : viewMode === ViewMode.Normal ? 'u-normal-mode' : ''"
      (cellDblClicked)="onCellDblClicked()"
      [timeProvider]="cocomero.time"
      [(viewMode)]="viewMode"
      (selectionChanged)="onSummarySelectionChanged()"
    ></app-summary-list>

    <div id="content">
      <golden-layout-root appFita4GoldenLayoutAutoResize [layout]="layoutConfig$" (stateChanged)="onStateChanged()"></golden-layout-root>
    </div>
  </div>
</ng-container>
<app-fita4-reconnecting-message></app-fita4-reconnecting-message>
<app-fita4-order-dialogs></app-fita4-order-dialogs>
<app-fita4-order-dialogs-per-window></app-fita4-order-dialogs-per-window>
