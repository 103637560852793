import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { IndexPortfolioLineComponent } from './index-portfolio-line/index-portfolio-line.component';
import { BarchartComponent } from './barchart/barchart.component';
import { SharedModule } from '../shared/shared.module';
import { ItaModule, MiniChartModule, PriceChangesPipe, QuoteSideStrPipe, VgGridModule, IconsModule } from '@argentumcode/brisk-common';
import { SummaryListComponent } from './summary-list/summary-list.component';
import { ItaPortfolioComponent } from './ita-portfolio/ita-portfolio.component';
import { ItaPortfolioItemComponent } from './ita-portfolio-item/ita-portfolio-item.component';
import { StockListComponent } from './stock-list/stock-list.component';
import { ShowIndexDialogComponent } from './show-index-dialog/show-index-dialog.component';
import { CoreModule } from '../core/core.module';
import { MiniChartComponent } from './mini-chart/mini-chart.component';
import { ChartPortfolioComponent } from './chart-portfolio/chart-portfolio.component';
import { ChartPortfolioItemComponent } from './chart-portfolio-item/chart-portfolio-item.component';
import { CsvImportDialogComponent } from './csv-import-dialog/csv-import-dialog.component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CsvExportDialogComponent } from './csv-export-dialog/csv-export-dialog.component';
import { HitLiftBarComponent } from './hit-lift-bar/hit-lift-bar.component';
import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { HitLiftBackgroundPipe } from './hit-lift-background.pipe';
import { StockListSplitComponent } from './stock-list-split/stock-list-split.component';
import { SmartPasteDialogComponent } from './smart-paste-dialog/smart-paste-dialog.component';
import { ContextMenuModule } from '@argentumcode/ngx-contextmenu';
import { MapViewComponent } from './map-view/map-view.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MapViewTooltipComponent } from './map-view-tooltip/map-view-tooltip.component';
import { TreeMapModule } from '@argentumcode/tree-map';

@NgModule({
  imports: [
    WjCoreModule,
    CommonModule,
    SharedModule,
    ItaModule,
    CoreModule,
    MiniChartModule,
    NgbDropdownModule,
    IconsModule,
    VgGridModule,
    ContextMenuModule,
    OverlayModule,
    TreeMapModule,
  ],
  declarations: [
    PortfolioComponent,
    IndexPortfolioLineComponent,
    BarchartComponent,
    SummaryListComponent,
    ItaPortfolioComponent,
    ItaPortfolioItemComponent,
    StockListComponent,
    ShowIndexDialogComponent,
    MiniChartComponent,
    ChartPortfolioComponent,
    ChartPortfolioItemComponent,
    CsvImportDialogComponent,
    CsvExportDialogComponent,
    HitLiftBarComponent,
    HitLiftBackgroundPipe,
    StockListSplitComponent,
    SmartPasteDialogComponent,
    MapViewComponent,
    MapViewTooltipComponent,
  ],
  exports: [
    PortfolioComponent,
    IndexPortfolioLineComponent,
    SummaryListComponent,
    StockListSplitComponent,
    IndexPortfolioLineComponent,
    ItaPortfolioComponent,
    ChartPortfolioComponent,
    MapViewComponent,
  ],
  providers: [QuoteSideStrPipe, PriceChangesPipe, PercentPipe],
})
export class PortfolioModule {
  constructor() {}
}
