import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { PortfolioModule } from 'fita3/src/app/portfolio/portfolio.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'fita3/src/app/shared/shared.module';
import {
  BriskBrowserModule,
  ChartModule,
  ErrorHandlerService,
  FrontendApiService,
  HeaderModule,
  IconsModule,
  IndividualDetailModule,
  IssueCodeInputService,
  ItaCaptureModule,
  ItaModule,
  LocalStorageService,
  MarketsModule,
  QrGridModule,
  ToasterModule,
  ToasterService,
  ViewHistoryService,
} from '@argentumcode/brisk-common';
import { CoreModule } from 'fita3/src/app/core/core.module';
import { FixModule } from 'fita3/src/app/fix/fix.module';
import { NgbDropdownModule, NgbPopoverConfig, NgbPopoverModule, NgbTabsetModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TutorialModule } from 'fita3/src/app/tutorial/tutorial.module';

import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { SimpleOrderService } from 'fita3/src/app/core/simple-order.service';
import { simpleOrderService } from 'fita3/src/environments/monex/environment';
import { FixItaService } from 'fita3/src/app/fix/fix-ita.service';
import { FIXService } from 'fita3/src/app/fix/fix.service';
import { MainWindowComponent } from './main-window/main-window.component';
import { MultipleWindowModule } from '@argentumcode/multiple-window';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { ApiService } from 'fita3/src/app/core/api.service';
import { ReloadService } from 'fita3/src/app/core/reload.service';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { environment } from '../../environments/default/environment';
import { ORDER_OPERATOR_TOKEN } from '../order/types/interfaces';
import { GoldenLayoutModule } from '@argentumcode/ngx-golden-layout';
import { StockListPanelComponent } from '../panels/stock-list-panel/stock-list-panel.component';
import { SummaryListService } from 'fita3/src/app/portfolio/summary-list.service';
import { SummaryListsService } from '../summary-lists.service';
import { FontSizeConfig } from '../font-size.service';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MainWindowService } from '../main-window.service';
import { PersistentStateService } from '../fita4-core/persistent-state.service';
import { ViewHistoryService as Fita4ViewHistoryService } from '../fita4-core/view-history.service';
import { StorageService } from 'fita3/src/app/core/storage.service';
import { Fita4StorageService } from '../fita4-core/fita4-storage.service';
import { VersionService } from 'fita3/src/app/core/version.service';
import { Fita4VersionService } from '../fita4-core/fita4-version.service';
import { ContextMenuModule } from '@argentumcode/ngx-contextmenu';
import { BriskDialogModule } from '@argentumcode/brisk-common';
import { Fita4Service } from '../fita4-core/fita4.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MarketsPanelComponent } from '../panels/markets-panel/markets-panel.component';

const popoverConfig = new NgbPopoverConfig();
popoverConfig.autoClose = false;
popoverConfig.popoverClass = 'c-assist-popover c-assist-popover--default';

const tooltipConfig = new NgbTooltipConfig();
tooltipConfig.openDelay = 100;
tooltipConfig.container = 'body';
tooltipConfig.tooltipClass = 'c-tooltip';
tooltipConfig.triggers = 'hover';
@NgModule({
  declarations: [MainWindowComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    BriskBrowserModule,
    CoreModule,
    IndividualDetailModule,
    FixModule,
    ChartModule,
    MarketsModule,
    ToasterModule,
    PortfolioModule,
    ItaModule,
    HeaderModule,
    QrGridModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTabsetModule,
    TutorialModule,
    IconsModule,
    WjCoreModule,
    ItaCaptureModule,
    PortfolioModule,
    environment.orderUiModuleForRoot,
    ...environment.tradeModules,
    SharedComponentsModule,
    GoldenLayoutModule.forRoot([]),
    MultipleWindowModule.forParentWindow({
      CocomeroService: CocomeroService,
      ApiService: ApiService,
      FrontendApiService: FrontendApiService,
      IssueCodeInputService: IssueCodeInputService,
      ErrorHandlerService: ErrorHandlerService,
      LocalStorageService: LocalStorageService,
      ReloadService: ReloadService,
      ChartService: ChartService,
      OrderOperator: ORDER_OPERATOR_TOKEN,
      ToasterService: ToasterService,
      PersistentStateService: PersistentStateService,
      Fita4Service: Fita4Service,
      ...environment.sharedServices,
    }),
    ContextMenuModule.forRoot({
      useBackdrop: true,
      useBootstrap4: true,
      backdropClass: '',
      autoFocus: true,
    }),
    BriskDialogModule.forMultipleWindowParent(),
    OverlayModule,
  ],
  providers: [
    ErrorHandlerService,
    { provide: ErrorHandler, useExisting: ErrorHandlerService },
    { provide: FIXService, useValue: null },
    { provide: FixItaService, useValue: null },
    { provide: NgbPopoverConfig, useValue: popoverConfig },
    { provide: NgbTooltipConfig, useValue: tooltipConfig },
    { provide: SimpleOrderService, useClass: simpleOrderService },
    { provide: SummaryListService, useExisting: SummaryListsService },
    { provide: FontSizeConfig, useValue: 'mainWindow' },
    { provide: ViewHistoryService, useClass: Fita4ViewHistoryService },
    { provide: StorageService, useClass: Fita4StorageService },
    { provide: VersionService, useClass: Fita4VersionService },
    MainWindowService,
    SummaryListsService,
    Fita4Service,
    GoldenLayoutModule.forChild([
      {
        name: 'StockList',
        type: StockListPanelComponent,
      },
      {
        name: 'MarketList',
        type: MarketsPanelComponent,
      },
      ...environment.tradePanels,
    ]),
  ],
  bootstrap: [MainWindowComponent],
})
export class MainWindowModule {}
