import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IssueCodeInputService, IssueCodeInputListElement } from './issue-code-input.service';
import { AutoComplete } from '@grapecity/wijmo.input';
import { Observable, Subscription } from 'rxjs';
import { ResizeService } from '../../resize/resize.service';
import * as Sentry from '@sentry/browser';
import { Platform } from '@angular/cdk/platform';

export class InvalidIssueCodeEvent {
  cancel = false;
  callback: Observable<{}> = null;

  constructor(public text: string) {}
}

@Component({
  selector: 'brisk-issue-code-input',
  templateUrl: './issue-code-input.component.html',
  styleUrls: ['./issue-code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCodeInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autoComplete', { static: true })
  private autoComplete: AutoComplete;

  @Input()
  public grid = false;

  @Input()
  autoFocus = false;

  @Input()
  defaultInput: string;

  @Input()
  placeHolder = '銘柄コード / 銘柄名';

  public readonly maxItems: number;

  private _initialized = false;

  private _composing = false;

  private readonly empty = new IssueCodeInputListElement({ issueCode: null, name: null });

  @Output()
  public stockSelected = new EventEmitter<number>();

  @Output()
  public invalidInput = new EventEmitter<InvalidIssueCodeEvent>();

  @Output()
  lostFocus = new EventEmitter<any>();

  private resizeSubscription: Subscription = null;

  private clearOnLostFocus = true;

  constructor(private issueCodeInput: IssueCodeInputService, private resize: ResizeService, private platform: Platform) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
    if (this.platform.TRIDENT) {
      // IEでは件数を増やすことで、如実に体感が悪化したため、21件のままとする。
      this.maxItems = 21;
    } else {
      this.maxItems = 100;
    }
  }

  ngOnInit() {
    this.autoComplete.itemsSourceFunction = this.itemSourcesFunction.bind(this);
    this.onResize();
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.autoComplete.focus();
      if (!this.defaultInput) {
        this.onClick(null);
      }
    }
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

  private itemSourcesFunction(query: string, max: number, callback: Function) {
    const ret = this.issueCodeInput.filter(query, max);
    try {
      callback(ret.slice(0, max));
    } catch (ex) {
      // Issue #574
      // callbackは完全にWijmo側の処理なので、例外は無視して、Sentryに送信する。
      Sentry.captureException(ex);
    }
  }

  public onClick(event: MouseEvent) {
    if (this.autoComplete.isDroppedDown) {
      return;
    }
    this.autoComplete.focus();
    this.autoComplete.itemsSource = this.issueCodeInput
      .filter(this.autoComplete.text, this.autoComplete.maxItems)
      .slice(0, this.autoComplete.maxItems);
    this.autoComplete.selectedIndex = -1;
    this.autoComplete.isDroppedDown = true;
  }

  public onLostFocus() {
    if (this.clearOnLostFocus) {
      this.autoComplete.selectedIndex = -1;
      this.lostFocus.emit();
    }
  }

  public onIsDroppedDownChanged() {
    if (this.autoComplete.isDroppedDown && this.autoComplete.selectedItem) {
      this.select();
    }
  }

  public onEnterKeyDown(event: KeyboardEvent) {
    if (this._composing || event['isComposing']) {
      return;
    }
    // tslint:disable-next-line
    if (event.keyCode === 229) {
      // MacのSafariでの確定イベントを取得する。(MacのSafariでは、KeydownイベントはcomposeEndの後に発生するため)
      return;
    }
    if (this.autoComplete.text === '') {
      return;
    }
    // CallbackでDialogを開いた時に、DialogにもEnterのイベントが発生するのを防ぐために必要
    event.preventDefault();
    event.stopPropagation();

    const backup = this.autoComplete.text;
    if (this.autoComplete.selectedIndex === -1) {
      this.autoComplete.selectedIndex = 0;
      if (this.autoComplete.selectedIndex === -1) {
        this.clearOnLostFocus = false;
        const ev = new InvalidIssueCodeEvent(backup);
        this.invalidInput.emit(ev);
        if (ev.cancel) {
          this.autoComplete.text = backup;
        }
        if (ev.callback) {
          ev.callback.subscribe((res) => {
            console.log('done', res);
            this.clearOnLostFocus = true;
            this.autoComplete.focus();
          });
        } else {
          this.clearOnLostFocus = false;
        }
      } else {
        this.select();
      }
    } else {
      this.select();
    }
  }

  public select() {
    if (this.autoComplete.selectedItem && this.autoComplete.selectedItem.issueCode) {
      const issueCode = this.autoComplete.selectedItem && this.autoComplete.selectedItem.issueCode;
      setTimeout(() => {
        this.stockSelected.emit(issueCode);
      });
    }
    this.autoComplete.selectedIndex = -1;
  }

  onResize() {
    this.autoComplete.maxDropDownHeight = (window.innerHeight * 2) / 5;
  }

  onGotFocus(event) {
    if (this.defaultInput && !this._initialized) {
      this.autoComplete.text = this.defaultInput;
      this._initialized = true;
    }
  }

  onCompositionStart() {
    this._composing = true;
  }

  onCompositionEnd() {
    this._composing = false;
  }
}
