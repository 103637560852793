import { Injectable } from '@angular/core';
import { WindowRefService } from '@argentumcode/brisk-common';

@Injectable({
  providedIn: 'root',
})
export class MonexOrderService {
  constructor(private windowRef: WindowRefService) {}

  orderWithIssueCode(issueCode: number, orderType?: string) {
    let url = '';
    if (orderType === 'cashBuy') {
      url = `https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp?url1=kei&url2=/servlet/ITS/stock/StkBuyOrder&dscrCD=${issueCode}`;
    } else if (orderType === 'cashSell') {
      url = `https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp?url1=kei&url2=/servlet/ITS/stock/StkHavingList&searchDscrCD=${issueCode}`;
    } else if (orderType === 'margin') {
      url = `https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp?url1=kei&url2=/servlet/ITS/stock/MrgNewOrder&dscrCD=${issueCode}`;
    } else if (orderType === 'marginRefund') {
      url = `https://mst.monex.co.jp/pc/ITS/login/LoginIDPassword.jsp?url1=kei&url2=/servlet/ITS/stock/MrgRefundList&dscrCD=${issueCode}`;
    } else {
      throw new Error('Unknown order type');
    }
    this.windowRef.nativeWindow.open(url, 'order', 'resizable');
  }

  initialize(boot: any) {
    // Do nothing
  }
}
