import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toast } from './toast';

@Injectable({
  providedIn: 'root',
})
export class ToasterService implements OnDestroy {
  private _toasts: Array<Toast> = [];
  private _subject = new BehaviorSubject<Array<Toast>>([]);
  readonly toasts$: Observable<Array<Toast>> = this._subject.asObservable();

  constructor() {}

  add(toast: Toast) {
    this._toasts.push(toast);
    this._subject.next(this._toasts);
  }

  remove(toast: Toast) {
    this._toasts = this._toasts.filter((a) => a !== toast);
    this._subject.next(this._toasts);
  }

  ngOnDestroy(): void {
    this._subject.complete();
  }
}
