<div matDialogTitle class="modal-header">
  <h5>{{ dialogTitle }}</h5>
  <span *ngIf="showCloseButton" class="close wj-hide" data-dismiss="modal" aria-label="Close" (click)="onCloseButtonClick()">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<mat-dialog-content class="mat-typography" *ngIf="wrapContent; else noWrap">
  <ng-container *ngIf="contentText; else showTemplate">
    <ng-container *ngFor="let message of contentText; let i = index">
      <ng-container *ngIf="i > 0"><br /></ng-container>
      {{ message }}
    </ng-container>
  </ng-container>
  <ng-template #showTemplate>
    <ng-template [cdkPortalOutlet]="content"></ng-template>
  </ng-template>
</mat-dialog-content>
<ng-template #noWrap>
  <ng-template [cdkPortalOutlet]="content"></ng-template>
</ng-template>
<mat-dialog-actions align="end" *ngIf="buttons.length > 0 || checkboxText">
  <brisk-checkbox style="flex: 1" *ngIf="checkboxText" [(ngModel)]="checked">{{ checkboxText }}</brisk-checkbox>
  <button
    *ngFor="let button of buttons"
    class="{{ button.cssClass === undefined ? 'c-btn c-btn--default' : button.cssClass }}"
    [attr.cdkFocusInitial]="button.initialFocus || null"
    (click)="onClick(button.result)"
  >
    {{ button.text }}
  </button>
</mat-dialog-actions>
