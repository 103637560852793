import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmartListSummary } from 'fita3/src/app/core/summary.service';
import {
  InvalidIssueCodeEvent,
  ResizeService,
  RowDeletingEvent,
  Toast,
  ToasterService,
  ToastType,
  VgCollectionView,
  VgGridColumnsState,
} from '@argentumcode/brisk-common';
import { SummaryListService } from 'fita3/src/app/portfolio/summary-list.service';
import { PortfolioComponent, SummaryPortfolio } from 'fita3/src/app/portfolio/portfolio/portfolio.component';
import { Portfolio, StockPortfolio } from 'fita3/src/app/portfolio/portfolio';
import { Subscription } from 'rxjs';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { GlOnResize, GlOnShow, GoldenLayoutComponentState, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { StockListService } from '../../stock-list.service';
import { StockDetailsService } from '../../stock-details.service';
import { CancelEventArgs } from '@grapecity/wijmo';
import { CellDoubleClickService } from '../../cell-double-click.service';
import { Container } from 'golden-layout';

export interface StockListGridState {
  columnsState: VgGridColumnsState;
}

@Component({
  selector: 'app-fita4-stock-list-grid-panel',
  templateUrl: './stock-list-grid-panel.component.html',
  styleUrls: ['./stock-list-grid-panel.component.scss'],
})
export class StockListGridPanelComponent implements OnInit, OnDestroy, GlOnResize, GlOnShow {
  allowSorting = true;
  allowFilter = true;
  isSmartList = false;
  private updatingCount = 0;
  private _subscription = new Subscription();
  columnsState: VgGridColumnsState = undefined;

  get stockCollectionView(): VgCollectionView {
    return this.stockListService.stockCollectionView;
  }

  get summary(): SummaryPortfolio {
    return this.stockListService.summary;
  }

  get editable(): boolean {
    return this.stockListService.editable;
  }

  @ViewChild('stockPortfolio') stockPortfolio: PortfolioComponent;

  constructor(
    private summaryListService: SummaryListService,
    private cocomero: CocomeroService,
    private toaster: ToasterService,
    private resize: ResizeService,
    private stockListService: StockListService,
    private stockDetailsService: StockDetailsService,
    private cellDoubleClickService: CellDoubleClickService,
    @Inject(GoldenLayoutComponentState) componentState: StockListGridState,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this._subscription.add(
      this.stockListService.setCurrentItem.subscribe((item) => {
        this.stockPortfolio.setCurrentItem(item);
        setTimeout(() => {
          this.stockPortfolio.scrollIntoView();
        });
      })
    );

    this._subscription.add(
      this.stockListService.summarySelectionChanged.subscribe(() => {
        this.onSummarySelectionChanged();
      })
    );

    this._subscription.add(
      this.cocomero.updateFrame.subscribe(() => {
        this.stockPortfolio.refresh();
        if (!this.container.isHidden) {
          this.stockCollectionView.update();
        }
      })
    );

    this.columnsState = componentState?.columnsState;
  }

  onSummarySelectionChanged() {
    this.allowSorting = true;
    this.allowFilter = true;
    this.isSmartList = false;
    if (this.summary && this.summary.summary instanceof SmartListSummary) {
      this.allowSorting = false;
      this.allowFilter = false;
      this.isSmartList = true;
    }
  }

  private deferUpdate(func: () => void) {
    try {
      this.updatingCount++;
      func();
    } finally {
      this.updatingCount--;
    }
  }

  onIssueCodeUpdated([item, issueCode]: [Portfolio, number]) {
    this.stockListService.updateIssueCode(item, issueCode);
  }

  onInvalidInput(e: InvalidIssueCodeEvent) {
    if (!e.text) {
      return;
    }
    e.cancel = true;
    this.toaster.add(new Toast(`銘柄「${e.text}」が見つかりません。`, ToastType.Error));
  }

  onSelectionChanged(item: Portfolio) {
    if (item instanceof StockPortfolio) {
      if (item.issueCode) {
        this.stockDetailsService.changeIssueCodeRequest(Number(item.issueCode));
      }
    }
  }

  onRowDeleting(event: RowDeletingEvent) {
    this.stockListService.rowDelete(event);
  }

  ngOnInit(): void {}

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
  }

  paste(event: ClipboardEvent) {
    console.log(this.stockListService);
    this.stockListService.paste(event);
  }

  onDraggingRow([portfolio, e]: [Portfolio, CancelEventArgs]) {
    if (portfolio instanceof StockPortfolio) {
      e.cancel = false;
    }
  }

  onDraggedRow([oldRow, newRow]: [number, number]) {
    this.stockListService.onDraggedRow([oldRow, newRow]);
  }

  onCellDblClick() {
    this.cellDoubleClickService.doubleClicked();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  updateColumnsState(columnsState: VgGridColumnsState) {
    this.columnsState = columnsState;
    this.container.setState({
      columnsState,
    });
  }
}
