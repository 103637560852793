import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Popup } from '@grapecity/wijmo.input';
import { CocomeroService } from '../cocomero.service';
import { Subscription } from 'rxjs';
import { ReloadService } from '../reload.service';

@Component({
  selector: 'app-restart-dialog',
  templateUrl: './restart-dialog.component.html',
  styleUrls: ['./restart-dialog.component.scss'],
})
export class RestartDialogComponent implements OnInit, OnDestroy {
  @ViewChild('restartPopup', { static: true })
  public restartPopup: Popup;

  private dateChangedSubscription: Subscription;

  public date: string;

  constructor(private cocomero: CocomeroService, private changeDetectorRef: ChangeDetectorRef, private _reload: ReloadService) {}

  ngOnInit() {
    this.dateChangedSubscription = this.cocomero.dateChanged$.subscribe((date) => {
      this.date = date.split('-')[1] + '月' + date.split('-')[2] + '日';
      this.changeDetectorRef.markForCheck();
      this.restartPopup.show();
    });
  }

  ngOnDestroy() {
    if (this.dateChangedSubscription) {
      this.dateChangedSubscription.unsubscribe();
    }
  }

  reload() {
    this._reload.reload();
  }
}
