import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { PegState } from '@argentumcode/brisk-common';
import { ITA_GRID_CONTROLLER_TOKEN, ItaGridController, ItaGridState } from '../../panels/ita-grid/interface';

@Component({
  selector: 'app-fita4-ita-view-header',
  templateUrl: './ita-view-header.component.html',
  styleUrls: ['./ita-view-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItaViewHeaderComponent implements OnInit, OnDestroy {
  private customHeaderElement: HTMLElement;
  PegState = PegState;
  state$: Observable<ItaGridState>;
  constructor(private elementRef: ElementRef<HTMLElement>, @Inject(ITA_GRID_CONTROLLER_TOKEN) public itaGrid: ItaGridController) {
    this.state$ = this.itaGrid.state$;
  }

  ngOnInit(): void {
    this.customHeaderElement = (this.elementRef.nativeElement as HTMLElement).parentElement;
    this.customHeaderElement.classList.add('ita-view-header');
  }

  ngOnDestroy() {
    this.customHeaderElement.classList.remove('ita-view-header');
  }
}
