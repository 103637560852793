import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanvasGridComponent } from './canvas-grid/canvas-grid.component';
import { CanvasGridColumnComponent } from './canvas-grid-column/canvas-grid-column.component';
import { CanvasGridStyleComponent } from './canvas-grid-style/canvas-grid-style.component';
import { ResizeModule } from '../resize/resize.module';

@NgModule({
  imports: [CommonModule, ResizeModule],
  declarations: [CanvasGridComponent, CanvasGridColumnComponent, CanvasGridStyleComponent],
  exports: [CanvasGridComponent, CanvasGridStyleComponent, CanvasGridColumnComponent],
})
export class CanvasGridModule {}
