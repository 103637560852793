import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssueCodeInputComponent } from './issue-code-input/issue-code-input.component';
import { IssueCodeInputService } from './issue-code-input/issue-code-input.service';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { TimestampFormatPipe } from './timestamp-format.pipe';
import { SplitResizeEventDirective } from './split-resize-event.directive';
import { SafeUrlPipe } from './safe-url.pipe';
import { PriceChangesPipe } from './price-changes.pipe';
import { PredictTransformPipe } from './predict-transform.pipe';
import { FormatPricePipe } from './format-price.pipe';
import { FlexGridFlexibleRowSizeDirective } from './flex-grid-flexible-row-size.directive';
import { FlexGridDisableScrollDirective } from './flex-grid-disable-scroll.directive';
import { FastDecimalPipe } from './fast-decimal.pipe';
import { ColorConverterService } from './color-converter.service';
import { QuoteFlagPipe } from './quote-flag.pipe';
import { QuoteSideStrPipe } from './quote-side-str.pipe';
import { AssistDirective } from './assist.directive';
import { PriceChangeClassPipe } from './price-change-class.pipe';
import { SideClassPipe } from './side-class.pipe';
import { ExRightFlagPipe } from './ex-right-flag.pipe';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { VgGridFlexibleRowSizeDirective } from './vg-grid-flexible-row-size.directive';
import { FormsModule } from '@angular/forms';
import { PagingComponent } from './paging/paging.component';
import { FirstTutorialComponent } from './first-tutorial/first-tutorial.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SochiPipe } from './sochi.pipe';
import { IconsModule } from '../icons/icons.module';
import { LastPriceComponent } from './last-price/last-price.component';
import { LastPriceFromPortfolioComponent } from './last-price-from-portfolio/last-price-from-portfolio.component';
import { PriceChangesComponent } from './price-changes/price-changes.component';
import { LastPriceBasePriceChangesFromPortfolioComponent } from './last-price-base-price-changes-from-portfolio/last-price-base-price-changes-from-portfolio.component';
import { LastPriceVwapChangesFromPortfolioComponent } from './last-price-vwap-changes-from-portfolio/last-price-vwap-changes-from-portfolio.component';
import { BuySellColorClassPipe } from './buy-sell-color-class.pipe';
import { LastPriceFromStockViewComponent } from './last-price-from-stock-view/last-price-from-stock-view.component';

@NgModule({
  imports: [CommonModule, WjInputModule, FormsModule, IconsModule, NgbPopoverModule],
  declarations: [
    IssueCodeInputComponent,
    TimestampFormatPipe,
    SplitResizeEventDirective,
    SafeUrlPipe,
    PriceChangesPipe,
    PredictTransformPipe,
    FormatPricePipe,
    FlexGridFlexibleRowSizeDirective,
    FlexGridDisableScrollDirective,
    FastDecimalPipe,
    QuoteFlagPipe,
    QuoteSideStrPipe,
    AssistDirective,
    PriceChangeClassPipe,
    SideClassPipe,
    ExRightFlagPipe,
    CheckboxComponent,
    VgGridFlexibleRowSizeDirective,
    PagingComponent,
    FirstTutorialComponent,
    SochiPipe,
    LastPriceComponent,
    LastPriceFromPortfolioComponent,
    PriceChangesComponent,
    LastPriceBasePriceChangesFromPortfolioComponent,
    LastPriceVwapChangesFromPortfolioComponent,
    BuySellColorClassPipe,
    LastPriceFromStockViewComponent,
  ],
  exports: [
    IssueCodeInputComponent,
    TimestampFormatPipe,
    SplitResizeEventDirective,
    SafeUrlPipe,
    PriceChangesPipe,
    PredictTransformPipe,
    FormatPricePipe,
    FlexGridFlexibleRowSizeDirective,
    FlexGridDisableScrollDirective,
    FastDecimalPipe,
    QuoteFlagPipe,
    QuoteSideStrPipe,
    AssistDirective,
    PriceChangeClassPipe,
    SideClassPipe,
    ExRightFlagPipe,
    CheckboxComponent,
    VgGridFlexibleRowSizeDirective,
    PagingComponent,
    FirstTutorialComponent,
    SochiPipe,
    LastPriceComponent,
    LastPriceFromPortfolioComponent,
    PriceChangesComponent,
    LastPriceBasePriceChangesFromPortfolioComponent,
    LastPriceVwapChangesFromPortfolioComponent,
    BuySellColorClassPipe,
    LastPriceFromStockViewComponent,
  ],
  providers: [IssueCodeInputService, ColorConverterService, FastDecimalPipe, TimestampFormatPipe, SochiPipe, FormatPricePipe],
})
export class BriskCommonModule {
  constructor() {}
}
