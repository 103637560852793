<svg [attr.viewBox]="'0 0 ' + outerWidth.toString() + ' ' + outerHeight.toString()">
  <ng-container *ngIf="showXGrid">
    <!-- 時刻Xグリッド -->
    <line
      *ngFor="let xTick of xLines"
      [attr.x1]="xTick.x + innerXPadding + paddingLeft"
      [attr.y1]="paddingTop"
      [attr.x2]="xTick.x + innerXPadding + paddingLeft"
      [attr.y2]="paddingTop + lastPriceChangesHeight + quantityHeight"
      class="mini-chart-axis-x-grid-line"
    />
  </ng-container>

  <!-- 値段のグラフ -->
  <svg preserveAspectRatio="none" [attr.x]="paddingLeft" [attr.y]="paddingTop" [attr.width]="width" [attr.height]="lastPriceChangesHeight">
    <!-- 枠線 -->
    <rect x="0" y="0" [attr.width]="width" [attr.height]="lastPriceChangesHeight" class="p-price-box"></rect>
    <!-- Y目盛線 -->
    <line
      *ngFor="let yTick of yLines"
      x1="1"
      [attr.y1]="((-yTick.y + yLimit) / yLimit / 2) * lastPriceChangesHeight"
      [attr.x2]="width - 1"
      [attr.y2]="((-yTick.y + yLimit) / yLimit / 2) * lastPriceChangesHeight"
      class="mini-chart-axis-y-line"
    ></line>
    <!-- グラフ -->
    <svg preserveAspectRatio="none" [attr.x]="innerXPadding" [attr.width]="innerWidth" [attr.height]="lastPriceChangesHeight">
      <clipPath [id]="'upSide_' + randomIdForClipPath">
        <rect x="0" [attr.y]="lastPriceChangesHeight / 2" [attr.width]="width" [attr.height]="lastPriceChangesHeight / 2"></rect>
      </clipPath>
      <clipPath [id]="'downSide_' + randomIdForClipPath">
        <rect x="0" y="0" [attr.width]="innerWidth" [attr.height]="lastPriceChangesHeight / 2"></rect>
      </clipPath>
      <polygon
        *ngIf="lastPrices && !isSafari"
        [attr.points]="
          lastPrices | priceChartPolygon: { width: innerWidth, height: lastPriceChangesHeight, limitY: yLimit, upSide: true, stroke: false }
        "
        [style]="'clip-path: url(#upSide_' + randomIdForClipPath + ');'"
        class="price-down-fill"
      ></polygon>

      <polygon
        *ngIf="lastPrices && !isSafari"
        [attr.points]="
          lastPrices
            | priceChartPolygon: { width: innerWidth, height: lastPriceChangesHeight, limitY: yLimit, upSide: false, stroke: false }
        "
        [style]="'clip-path: url(#downSide_' + randomIdForClipPath + ');'"
        class="price-up-fill"
      ></polygon>
      <polyline
        *ngIf="lastPrices"
        [attr.points]="
          lastPrices | priceChartPolygon: { width: innerWidth, height: lastPriceChangesHeight, limitY: yLimit, upSide: false, stroke: true }
        "
        class="price-line"
      ></polyline>
      <circle
        *ngIf="showVwap && vwap"
        [attr.cx]="
          ([vwapIndex, vwap] | transformPricePoint: { width: innerWidth, height: lastPriceChangesHeight, limitY: yLimit, N: N })[0]
        "
        [attr.cy]="
          ([vwapIndex, vwap] | transformPricePoint: { width: innerWidth, height: lastPriceChangesHeight, limitY: yLimit, N: N })[1]
        "
        r="3"
        class="vwap-circle"
        [ngbTooltip]="vwapTooltip"
      ></circle>
    </svg>
  </svg>

  <!-- 値段Y目盛テキスト -->
  <text
    *ngFor="let yTick of yLines"
    [attr.x]="paddingLeft"
    [attr.y]="paddingTop + ((-yTick.y + yLimit) / yLimit / 2) * lastPriceChangesHeight + fontSize / 2"
    text-anchor="end"
    class="mini-chart-axis-y-text"
    [attr.font-size]="fontSize"
  >
    {{ yTick.label }}
  </text>

  <!-- 金額のグラフ -->
  <svg
    *ngIf="quantityHeight"
    preserveAspectRatio="none"
    [attr.x]="paddingLeft"
    [attr.y]="paddingTop + lastPriceChangesHeight"
    [attr.widht]="width"
    [attr.height]="quantityHeight"
  >
    <!-- Y目盛線 -->
    <line
      *ngIf="quantityYLine"
      x1="0"
      [attr.y1]="quantityHeight * (1 - quantityYLine.y / quantityYLimit)"
      [attr.x2]="width"
      [attr.y2]="quantityHeight * (1 - quantityYLine.y / quantityYLimit)"
      class="mini-chart-axis-y-line"
    ></line>
    <!-- グラフ -->
    <svg preserveAspectRatio="none" [attr.x]="innerXPadding" [attr.width]="innerWidth" [attr.height]="quantityHeight">
      <rect
        *ngFor="let quantity of quantities; let i = index"
        [attr.x]="i * quantityWidth"
        [attr.y]="quantityHeight * (1 - quantity / quantityYLimit)"
        [attr.height]="quantityHeight * (quantity / quantityYLimit)"
        [attr.width]="quantityWidth"
        class="quantityRect"
      />
    </svg>
    <!-- 枠線 -->
    <rect x="0" y="-1" [attr.width]="width" [attr.height]="quantityHeight + 1" class="p-quantity-box" fill="none" stroke="black"></rect>
  </svg>

  <!-- 金額Y目盛テキスト -->
  <text
    *ngIf="quantityYLine && quantityHeight"
    [attr.x]="paddingLeft"
    [attr.y]="paddingTop + lastPriceChangesHeight + quantityHeight * (1 - quantityYLine.y / quantityYLimit) + fontSize / 2"
    text-anchor="end"
    class="mini-chart-axis-y-text"
    [attr.font-size]="fontSize"
  >
    {{ quantityYLine.label }}
  </text>

  <!-- 金額Y目盛テキスト -->
  <text
    *ngIf="quantityHeight"
    [attr.x]="paddingLeft"
    [attr.y]="paddingTop + lastPriceChangesHeight + quantityHeight"
    text-anchor="end"
    class="mini-chart-axis-y-text mini-chart-axis-y-text-unit"
    [attr.font-size]="fontSize * 0.7"
  >
    (/百万円)
  </text>

  <ng-container *ngIf="showXTick">
    <!-- 時刻X目盛/テキスト -->
    <line
      *ngFor="let xTick of xLines"
      [attr.x1]="xTick.x + innerXPadding + paddingLeft"
      [attr.y1]="paddingTop + lastPriceChangesHeight + quantityHeight"
      [attr.x2]="xTick.x + innerXPadding + paddingLeft"
      [attr.y2]="paddingTop + lastPriceChangesHeight + quantityHeight + xTickLength"
      class="mini-chart-axis-x-line"
    />
    <text
      *ngFor="let xTick of xLines"
      [attr.x]="xTick.x + innerXPadding + paddingLeft"
      [attr.y]="paddingTop + lastPriceChangesHeight + quantityHeight + xTickLength + fontSize"
      text-anchor="middle"
      class="mini-chart-axis-x-text"
      [attr.font-size]="fontSize"
    >
      {{ xTick.label }}
    </text>
  </ng-container>
</svg>

<ng-template #vwapTooltip>
  <div>
    VWAP: <span>{{ vwapPrice | fastDecimal: { fractionDigits: 1 } }}円</span> <br />基準値段比:
    <span [ngClass]="vwap | priceChangeClass">{{ vwap | priceChanges }}</span>
  </div>
</ng-template>
