import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemeService } from '@argentumcode/brisk-common';
import { FontSizeService } from '../../font-size.service';
import { PersistentStateService } from '../../fita4-core/persistent-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fita4-view-options',
  templateUrl: './view-options.component.html',
  styleUrls: ['./view-options.component.scss'],
})
export class ViewOptionsComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  @Output()
  closeRequest = new EventEmitter();

  form: FormGroup;

  availableThemes: Array<{
    name: string;
    value: string;
  }> = [];

  constructor(private theme: ThemeService, private fontSize: FontSizeService, private persistentState: PersistentStateService<unknown>) {
    this.availableThemes = ['light', 'dark', 'dark-inverse', 'black'].map((a) => ({
      name: this.theme.getName(a),
      value: a,
    }));
    this.setupForm();
    this._subscription = this.persistentState.theme$.subscribe((t) => {
      this.form.patchValue({ theme: t });
    });
  }

  setupForm() {
    this.form = new FormGroup({
      fontSize: new FormControl(this.fontSize.fontSize),
      theme: new FormControl(this.theme.theme),
    });
  }

  onSubmitForm() {
    this.persistentState.updateTheme(this.form.get('theme').value);
    this.fontSize.fontSize = this.form.get('fontSize').value;
    this.closeRequest.emit();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
