<wj-popup
  class="modal-content"
  #popup
  id="popup"
  [modal]="true"
  [fadeIn]="false"
  [fadeOut]="false"
  [hideTrigger]="'None'"
  [showTrigger]="'None'"
>
  <div class="modal-header">
    <h5 class="modal-title">推奨動作環境以外で起動しています。</h5>
    <span class="close" data-dismiss="modal" aria-label="Close" (click)="popup.hide()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="_osMessage; else versionMessage">
      <div class="win7-message">
        Windows 7 32bitとお使いのブラウザの組み合わせはBRiSKの動作を不安定にすることがあります。<br />Google
        Chromeのご利用をご検討ください。
      </div>
    </ng-container>
    <ng-template #versionMessage>
      <div>
        推奨動作環境以外で起動しています。快適にBRiSKをご利用いただけるよう、下記の推奨動作環境への移行をご検討いただけますよう、お願い申し上げます。
      </div>
      <div>
        推奨動作環境は以下の通りです。
        <ul>
          <li>Google Chrome: 最新版</li>
          <li>Edge: 最新2メジャーバージョン</li>
          <li>Firefox: 最新版</li>
          <li>Safari: 最新2メジャーバージョン</li>
        </ul>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer justify-content-between">
    <div class="text-left">
      <brisk-checkbox *ngIf="_osMessage" [(checked)]="doNotShowMessageAgain">今後、このメッセージを表示しない</brisk-checkbox>
    </div>
    <div>
      <button class="c-btn c-btn--default" (click)="close()">OK</button>
    </div>
  </div>
</wj-popup>
