import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterContainerComponent } from './toaster-container/toaster-container.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  declarations: [ToasterContainerComponent],
  imports: [CommonModule, NgbToastModule, IconsModule],
  exports: [ToasterContainerComponent],
})
export class ToasterModule {
  constructor() {}
}
