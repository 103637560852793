import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay/overlay.component';
import { TutorialStepComponent } from './tutorial-step/tutorial-step.component';
import { IconsModule } from '@argentumcode/brisk-common';

@NgModule({
  declarations: [OverlayComponent, TutorialStepComponent],
  exports: [OverlayComponent, TutorialStepComponent],
  imports: [CommonModule, IconsModule],
})
export class TutorialModule {
  constructor() {}
}
