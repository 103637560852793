import { Component, OnInit, ViewChild } from '@angular/core';
import { InputDialogService } from '../input-dialog.service';
import { Observable, Observer } from 'rxjs';
import { Popup } from '@grapecity/wijmo.input';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  @ViewChild('inputDialogPopup', { static: true })
  public inputDialogPopup: Popup;
  private observer: Observer<string | null>;

  public value = '';

  public title = '';
  public message = '';

  constructor(private inputDialog: InputDialogService) {}

  ngOnInit() {
    this.inputDialog.setupComponent(this);
  }

  prompt(message: string, title?: string): Observable<string | null> {
    return new Observable((observer: Observer<string | null>) => {
      this.observer = observer;
      this.message = message;
      this.title = title || '';
      this.value = '';
      this.inputDialogPopup.show(true);
    });
  }

  submit(text: string) {
    if (this.observer) {
      this.observer.next(text);
      this.observer.complete();
      this.observer = null;
      this.inputDialogPopup.hide();
    }
  }

  complete() {
    if (this.observer) {
      this.observer.next(null);
      this.observer.complete();
      this.observer = null;
    }
  }
}
