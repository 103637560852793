<div class="p-stock-list--main" *ngIf="summary">
  <div class="p-stock-list--header">
    <app-index-portfolio-line
      *ngIf="summary"
      class="p-summary-line__summary"
      #indexPortfolioLine
      [summary]="summary"
      [showUpDown]="true"
      [showOpen]="true"
      [showCount]="true"
    >
    </app-index-portfolio-line>
    <ng-container *ngIf="isSmartList && _smartListAutoRefreshType">
      <wj-combo-box
        [itemsSource]="updateTypes"
        displayMemberPath="name"
        selectedValuePath="key"
        class="u-small-combo-box u-small-combo-box--w-16"
        [ngModel]="_smartListAutoRefreshType"
        (ngModelChange)="_updateRefreshType($event)"
      ></wj-combo-box>
      <button
        type="button"
        class="c-btn c-btn--default c-btn--center p-summary-line__refresh_button"
        (click)="$event.stopPropagation(); refreshSmartList()"
        [disabled]="!_enableSmartListRefreshButton"
      >
        更新
      </button>
    </ng-container>
  </div>
  <div class="p-stock-list--content">
    <golden-layout-root
      #layout
      [layout]="layout$"
      (stateChanged)="onStateChanged(layout)"
      appFita4GoldenLayoutAutoResize
    ></golden-layout-root>
  </div>
</div>
<div class="p-stock-list--deleted" *ngIf="!summary">銘柄リストは削除されました。サイドパネルより、別の銘柄リストを選択してください。</div>
