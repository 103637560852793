import { Injectable } from '@angular/core';
import { Portfolio } from './portfolio';
import { CollectionView } from '@grapecity/wijmo';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { VgCollectionView } from '@argentumcode/brisk-common';

export class StockListItem {
  public summary: Portfolio = null;
  public portfolios: CollectionView = null;
  public stocks: Array<Portfolio> = null;
  public editable = false;
  public stockListUpdatedSubject = new Subject<{}>();
  public stockListUpdated = this.stockListUpdatedSubject.asObservable();
}

export enum StockListViewMode {
  Normal = 0,
  Detail = 1,
  DetailWide = 2,
}

@Injectable({
  providedIn: 'root',
})
export class SummaryListService {
  private _currentStockListItem = new BehaviorSubject<StockListItem>(null);
  public currentStockListItem$ = this._currentStockListItem.asObservable();

  private _currentStockListItemRequest = new Subject<StockListItem>();
  public currentStockListItemRequest$ = this._currentStockListItemRequest.asObservable();

  private _stockLists = new BehaviorSubject<Observable<Array<StockListItem>>>(null);
  public stockLists$ = this._stockLists.asObservable();

  private _closeRequest = new Subject<StockListItem>();
  public closeRequest$ = this._closeRequest.asObservable();

  private _closeAllRequest = new ReplaySubject<StockListItem>(1);
  public closeAllRequest$ = this._closeAllRequest.asObservable();

  private _selectFirstRequest = new Subject<{}>();
  public selectFirstRequest$ = this._selectFirstRequest.asObservable();

  constructor() {}
  updateCurrentItem(item: StockListItem) {
    this._currentStockListItem.next(item);
  }

  requestChangeCurrentItem(item: StockListItem) {
    this._currentStockListItemRequest.next(item);
  }

  updateStockList(obs: Observable<Array<StockListItem>>) {
    this._stockLists.next(obs);
  }

  requestClose(item: StockListItem) {
    this._closeRequest.next(item);
  }

  closeAll() {
    this._closeAllRequest.next();
  }

  selectFirst() {
    this._selectFirstRequest.next();
  }

  selectItem(itemId: string, newItem: boolean) {
    // do nothing
    // this is for fita3-gl
  }
}
