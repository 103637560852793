<div class="ita-view-header--container" *ngIf="state$ | async as state">
  <brisk-checkbox
    [ngModel]="state.pegState === PegState.Enabled"
    (ngModelChange)="itaGrid.updatePegState($event ? PegState.Enabled : PegState.Disabled)"
    >自動板中心値</brisk-checkbox
  >
  <button class="c-btn c-btn--default c-btn__header" (click)="itaGrid.resetAll()">板中心値</button>
  <wj-input-number
    [min]="1"
    [max]="99"
    format="n0"
    [step]="1"
    [showSpinner]="true"
    [isRequired]="true"
    [ngModel]="state.rows"
    (ngModelChange)="itaGrid.updateRows($event)"
    class="u-custom-input-number-gl-header ita-view-header--ita-rows-count"
  ></wj-input-number>
</div>
