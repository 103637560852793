import { Inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { PersistentStateService } from './persistent-state.service';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from '../order/types/interfaces';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { BriskDialogService, SummaryType } from '@argentumcode/brisk-common';
import { UnsupportedBrowserComponent } from '../shared-components/unsupported-browser/unsupported-browser.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { DemoStartDialogComponent } from '../shared-components/demo-start-dialog/demo-start-dialog.component';
import { ItaOrderTutorialComponent } from '../order/tachibana-noop-order-ui/ita-order-tutorial/ita-order-tutorial.component';

interface Fita4UiState {
  pendingFocusToMainPanel: boolean;
}

interface Fita4State {
  ui: Fita4UiState;
}

@Injectable()
export class Fita4Service implements OnDestroy {
  private _state: Fita4State = {
    ui: {
      pendingFocusToMainPanel: false,
    },
  };
  private _stateSubject = new BehaviorSubject(this._state);
  state$ = this._stateSubject.asObservable();

  private _recurUpdating = false;

  initialized$: Observable<void>;

  pendingFocusToMainPanel$: Observable<boolean> = this.state$.pipe(
    map((a) => a.ui.pendingFocusToMainPanel),
    distinctUntilChanged()
  );

  private _watchListIssueCodes = new Set<number>();
  private _watchListIssueCodesSubject = new BehaviorSubject(this._watchListIssueCodes);
  watchListIssueCodes$ = this._watchListIssueCodesSubject.asObservable();

  marketError$: Observable<Error>;

  private _browserCheck = new ReplaySubject<void>(1);
  private _subscription = new Subscription();

  private _updateState(state: Fita4State) {
    if (this._recurUpdating) {
      if (isDevMode()) {
        console.error('Synchronous updating can cause unintended behavior. Consider using asapScheduler. See ReactiveX/rxjs#2155');
      } else {
        console.error('Synchronous updating');
      }
    }
    this._recurUpdating = true;
    this._state = state;
    this._stateSubject.next(this._state);
    this._recurUpdating = false;
  }

  constructor(
    private dialog: BriskDialogService,
    private cocomero: CocomeroService,
    private persistentState: PersistentStateService<unknown>,
    private chart: ChartService,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator
  ) {
    const hashArgs: { [key: string]: string } = {};
    for (const [key, value] of location.hash
      .substr(1)
      .split('&')
      .map((a) => a.split('='))) {
      if (key) {
        hashArgs[key] = value;
      }
    }

    this.initialized$ = this._browserCheck.asObservable();

    this.dialog
      .openDialog(
        {
          title: '板発注チュートリアル',
          closeOnEscape: false,
          showCloseButton: true,
          content: new ComponentPortal(ItaOrderTutorialComponent),
        },
        {
          width: '1000px',
          additionalPanelClass: ['brisk-dialog-long-height'],
          additionalBackdropClass: ['brisk-dialog-overlay--strong'],
        }
      )
      .subscribe(() => {
        this.cocomero.wsMockStart();
      });

    combineLatest([this.persistentState.skipUnsupportedBrowserDialog$.pipe(first()), this.orderOperator.config$]).subscribe(
      ([skipConfirmation, config]) => {
        // 発注が有効な場合のみ表示する。発注が無効の場合は各OrderModule側に対応してもらう
        if (!skipConfirmation && config.orderEnabled === false && config.showBrowserDialog && !this.cocomero.checkBrowserForOrder()) {
          this.dialog
            .openDialog(
              {
                content: new ComponentPortal(UnsupportedBrowserComponent),
                closeOnEscape: false,
                title: '推奨動作環境以外で起動しています。',
                checkboxText: '今後、このメッセージを表示しない ',
                showCloseButton: false,
                buttons: [
                  {
                    text: '発注機能を利用せずに起動する',
                    initialFocus: true,
                  },
                ],
              },
              {
                width: '48em',
              }
            )
            .subscribe((result) => {
              if (result.type === 'button') {
                if (result.checked) {
                  this.persistentState.updateSkipUnsupportedBrowserDialog(result.checked);
                }
              }
              this._browserCheck.next();
              this._browserCheck.complete();
            });
        } else {
          this._browserCheck.next();
          this._browserCheck.complete();
        }
      }
    );
    // ウォッチリスト銘柄の一覧の作成 (場況ハイライト用)とチャートの初期化
    this.cocomero.summaryInitialized.subscribe(() => {
      this.updateWatchListIssueCodes();
      this._subscription.add(
        this.cocomero.summaries.summaryChanged.subscribe(() => {
          this.updateWatchListIssueCodes();
        })
      );
      this.chart.initialize(this.cocomero.op, this.cocomero.summaries);
    });

    this.marketError$ = this.cocomero.marketError$;
  }

  updatePendingFocusToMainPanel(pendingFocusToMainPanel: boolean): void {
    this._updateState({
      ...this._state,
      ui: {
        ...this._state.ui,
        pendingFocusToMainPanel,
      },
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._watchListIssueCodesSubject.complete();
  }

  private updateWatchListIssueCodes() {
    if (!this.cocomero.summaries) {
      return;
    }
    this._watchListIssueCodes.clear();
    for (const summary of this.cocomero.summaries.summaries.filter((s) => s.type === SummaryType.WatchList)) {
      for (const issueCode of Object.keys(summary.issueCodes)) {
        this._watchListIssueCodes.add(Number(issueCode));
      }
    }
    this._watchListIssueCodesSubject.next(this._watchListIssueCodes);
  }
}
