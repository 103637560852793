import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { VgGridComponent } from './vg-grid/vg-grid.component';

@Directive({
  selector: '[briskVgGridInput]',
})
export class VgGridInputDirective<T> implements OnInit {
  private _composing = false;

  constructor(private elementRef: ElementRef, private vgGrid: VgGridComponent<T>) {}

  @HostListener('blur', ['$event'])
  private _blur() {
    this.vgGrid.endCellEditing();
  }

  @HostListener('compositionstart', ['$event'])
  private _compositionStart() {
    this._composing = true;
  }

  @HostListener('compositionend', ['$event'])
  private _compositionEnd() {
    this._composing = false;
  }

  @HostListener('keydown', ['$event'])
  private _keydown(ev: KeyboardEvent) {
    // ts-lint:disable-next-line
    if (this._composing || ev['isComposing'] || ev.keyCode === 229) {
      // Safari及びIEで確定のEnterに反応しないようにする (#343)
      ev.stopPropagation();
    }
  }

  ngOnInit(): void {
    const nativeElement: HTMLInputElement = this.elementRef.nativeElement;
    nativeElement.focus();
  }
}
