import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from '../brisk-browser/local-storage.service';
import { Subject } from 'rxjs';

export const enum HistoryEventType {
  Add,
  Modify,
  Delete,
}

export interface HistoryEvent {
  type: HistoryEventType;
  data: string;
}

export const enum HistoryType {
  Stock,
  Basket,
}

export interface History {
  type: HistoryType;
  code: number;
}

const SAVE_COUNT = 20;

@Injectable({
  providedIn: 'root',
})
export class ViewHistoryService implements OnDestroy {
  histories: History[] = [];
  orderHistory: number[] = [];

  private orderHistorySubject = new Subject<number[]>();
  orderHistory$ = this.orderHistorySubject.asObservable();

  constructor(private localStorage: LocalStorageService) {}

  pushStock(issueCode: number) {
    const data = {
      type: HistoryType.Stock,
      code: Number(issueCode),
    };
    this.histories.push(data);
    this.normalize();
    this.saveToLocalStorage();
  }

  pushOrder(issueCode: number) {
    this.orderHistory = this.orderHistory.filter((a) => Number(a) !== Number(issueCode));
    this.orderHistory.unshift(Number(issueCode));
    if (this.orderHistory.length > SAVE_COUNT) {
      this.orderHistory = this.orderHistory.splice(0, SAVE_COUNT);
    }
    this.orderHistorySubject.next(this.orderHistory);
    this.saveToLocalStorage();
  }

  loadFromLocalStorage() {
    this.deserialize(this.localStorage.getItem('histories'));

    this.orderHistory = JSON.parse(this.localStorage.getItem('order_history') || '[]');
    this.orderHistory = this.orderHistory.splice(0, SAVE_COUNT);
  }

  saveToLocalStorage() {
    this.localStorage.setItem('histories', this.serialize());

    if (this.orderHistory && this.orderHistory.length > 0) {
      this.localStorage.setItem('order_history', JSON.stringify(this.orderHistory));
    }
  }

  private deserialize(s: string) {
    this.histories = JSON.parse(s) || [];
  }

  private serialize(): string {
    return JSON.stringify(this.histories);
  }

  clean(issueCodeMap: { [key: number]: any }) {
    this.histories = this.histories.filter((a) => a.code in issueCodeMap);
    this.orderHistory = this.orderHistory.filter((a) => a in issueCodeMap);
  }

  private normalize() {
    const last = this.histories.slice(-1)[0];
    for (let i = 0; i < this.histories.length - 1; i++) {
      if (this.histories[i].code === last.code && this.histories[i].type === last.type) {
        this.histories.splice(i, 1);
        i--;
      }
    }
    if (this.histories.length > SAVE_COUNT) {
      this.histories.splice(0, this.histories.length - SAVE_COUNT);
    }
  }

  /**
   * 最後にアクセスしたIssueCodeを取得する
   */
  getLatestIssueCode(stocks?: { [key: number]: any }): number {
    // TODO: stocks引数の削除(cleanすれば良いため)
    if (stocks) {
      const h = this.histories.filter((a) => a.type === HistoryType.Stock && a.code in stocks).slice(-1)[0];
      return h ? h.code : null;
    } else {
      if (this.histories.length > 0) {
        return this.histories.slice(-1)[0].code;
      } else {
        return null;
      }
    }
  }

  ngOnDestroy(): void {
    this.orderHistorySubject.complete();
  }
}
