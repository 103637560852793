import { PegState } from '@argentumcode/brisk-common';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface ItaGridState {
  rows: number;
  pegState: PegState;
}

export interface ItaGridController {
  state$: Observable<ItaGridState>;

  updateRows(rows: number): void;
  updatePegState(s: PegState): void;
  resetAll(): void;
}

export const ITA_GRID_CONTROLLER_TOKEN = new InjectionToken<ItaGridController>('ItaGridController');
