import GoldenLayout, { ComponentConfig, Dimensions } from 'golden-layout';
import { GetComponentFromLayoutManager } from '@argentumcode/ngx-golden-layout';

/// 表示しているタブを保持したまま、新規タブを追加する
/// reference. GoldenLayoutComponent#createNewComponent method
/// レイアウトが空の場合を想定していない。
export function createNewComponentKeepOriginalTab(
  goldenLayout: GoldenLayout,
  config: GoldenLayout.ComponentConfig,
  componentToDock: string,
  findBy: 'id' | 'componentName' = 'id'
) {
  const myConfig: GoldenLayout.ItemConfig = config;

  let element: GoldenLayout.ContentItem = null;
  const c =
    findBy === 'id'
      ? GetComponentFromLayoutManager(goldenLayout, componentToDock)
      : goldenLayout.root.getItemsByFilter(
          (contentItem) => contentItem.isComponent && (contentItem.config as ComponentConfig).componentName === componentToDock
        )[0];
  if (!c) {
    goldenLayout.root.contentItems[0].addChild(goldenLayout.createContentItem(myConfig) as any);
    return;
  }
  if (c.parent.isStack) {
    element = c.parent;
  } else {
    const stack = goldenLayout.createContentItem({
      type: 'stack',
      width: c.parent.config.width,
      height: c.parent.config.height,
      content: [],
    }) as any;
    (c.parent.replaceChild as any)(c, stack, false);
    stack.addChild(c);
    element = stack;
  }
  const originalActive = element.getActiveContentItem();
  element.addChild(goldenLayout.createContentItem(myConfig) as any);
  element.setActiveContentItem(originalActive);
}

/// Dimensionパラメータに設定する共通の値
export const commonDimensionParameters: Dimensions = {
  // デフォルトは15px(5pxずつ左右にMarginあり)
  borderGrabWidth: 9,
} as any;
