<wj-popup
  class="modal-content"
  #modalDialogPopup
  [modal]="true"
  [fadeIn]="false"
  [fadeOut]="false"
  [hideTrigger]="'None'"
  [ngClass]="cssClass"
  (hidden)="complete()"
>
  <div *ngIf="title || closable" class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <span *ngIf="closable" class="close" data-dismiss="modal" aria-label="Close" (click)="modalDialogPopup.hide()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="messages; else showTemplate">
      <ng-container *ngFor="let message of messages; let i = index">
        <ng-container *ngIf="i > 0"><br /></ng-container>
        {{ message }}
      </ng-container>
    </ng-container>
    <ng-template #showTemplate>
      <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button *ngFor="let button of buttons" class="btn {{ button.cssClass }} shadow-none" (click)="submit(button.value)">
      {{ button.name }}
    </button>
  </div>
</wj-popup>
