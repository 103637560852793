import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WjPopup } from '@grapecity/wijmo.angular2.input';
import { Observable, Observer, Subscription } from 'rxjs';
import * as encoding from 'encoding-japanese';
import { map } from 'rxjs/operators';
import { parse as csvParse } from 'papaparse';
import { CocomeroService } from '../../core/cocomero.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { UpdateSummaryType } from '../../core/summary-change-event';
import { WatchListService } from '../../core/watch-list.service';
import { ObservableArray } from '@grapecity/wijmo';
import { CellRangeEventArgs, FlexGrid } from '@grapecity/wijmo.grid';
import { halfWidthLength } from '../../shared/length';
import { Summary } from '@argentumcode/brisk-common';

class NewWatchList {
  name = '';
  nameError: {
    notUnique?: boolean;
    required?: boolean;
    nameLength?: boolean;
  };
  originalName = '';
  issueCodes: Array<number> = [];
  invalidIssueCode: Array<string> = [];
  checked = true;

  validate(watchListNames: Set<string>) {
    this.nameError = {};
    if (watchListNames && watchListNames.has((this.name || '').toString().trim())) {
      this.nameError.notUnique = true;
    }
    if ((this.name || '').toString().trim() === '') {
      this.nameError.required = true;
    }
    console.log(this.nameError, watchListNames);
  }
}
@Component({
  selector: 'app-csv-import-dialog',
  templateUrl: './csv-import-dialog.component.html',
  styleUrls: ['./csv-import-dialog.component.scss'],
})
export class CsvImportDialogComponent implements OnInit {
  formGroup = this.formBuilder.group({
    filename: ['', [Validators.required]],
    name: ['', [Validators.required, this.uniqueNameValidator.bind(this), this.nameLengthValidator.bind(this)]],
    columnIndex: [null, Validators.required],
  });

  get filename() {
    return this.formGroup.get('filename');
  }

  get name() {
    return this.formGroup.get('name');
  }

  get columnIndex() {
    return this.formGroup.get('columnIndex');
  }

  @ViewChild('csvImportDialog', { static: true })
  csvImportDialog: WjPopup;

  @ViewChild('okBtn')
  okBtn: ElementRef;

  fileReadSubscription: Subscription;
  columns: Array<string> = [];
  csvParseResult: Array<Array<string>>;
  issueCount: Array<number>;

  watchListNames: Set<string>;
  csvError = '';
  multipleWatchLists = false;
  allowFiveDigits: Array<boolean>;

  watchLists: ObservableArray = new ObservableArray();
  validMultipleWatchLists = false;

  private subscribe: Observer<Summary>;
  private resultWatchList: Summary;

  constructor(
    private cocomero: CocomeroService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private watchList: WatchListService
  ) {}

  ngOnInit() {}

  show(): Observable<Summary> {
    this.watchListNames = new Set<string>();
    for (const w of this.watchList.all()) {
      this.watchListNames.add(w.name);
    }
    this.columns = [];
    this.issueCount = null;
    this.allowFiveDigits = null;
    this.formGroup.reset();
    this.csvImportDialog.show();
    this.csvError = '';
    this.multipleWatchLists = false;
    this.validMultipleWatchLists = false;
    this.resultWatchList = null;
    return new Observable<Summary>((sub) => {
      this.subscribe = sub;
    });
  }

  onFileSelected(file: File) {
    if (file) {
      this.formGroup.patchValue({
        filename: null,
      });
      this.columns = [];
      this.csvError = '';
      this.columnIndex.patchValue(null);
      if (this.fileReadSubscription) {
        this.fileReadSubscription.unsubscribe();
      }
      this.formGroup.disable();
      this.fileReadSubscription = this.readFileAsText(file).subscribe(
        (data) => {
          this.formGroup.enable();
          const result = csvParse(data, {
            skipEmptyLines: true,
          });
          if (result.data.length === 0) {
            this.csvError = '空のファイルです';
            return;
          }
          if (result.data[0][0] === '銘柄リスト名' && result.data[0][1] === '銘柄コード') {
            // 複数件追加モード
            this.multipleWatchLists = true;
            console.log('複数件モード');
          } else {
            this.multipleWatchLists = false;
          }
          this.formGroup.patchValue({
            filename: file.name,
          });
          this.csvParseResult = result.data;
          if (this.multipleWatchLists) {
            this.formGroup.patchValue({
              name: 'watchlist',
              columnIndex: 1,
            });
            this.createMultipleWatchList();
          } else {
            this.columns = result.data[0] || [];
            this.formGroup.patchValue({
              name: file.name.split('.')[0],
            });
            this.name.markAsTouched();
            this.columnIndex.markAsTouched();
            this.selectBestColumn();
          }
          this.okBtn.nativeElement.focus();
          this.changeDetectorRef.markForCheck();
        },
        () => {
          this.fileReadSubscription = null;
          this.formGroup.enable();
          this.csvError = 'ファイルの読み込み中にエラーが発生しました。';
        },
        () => {
          this.fileReadSubscription = null;
          this.formGroup.enable();
        }
      );
    } else {
      this.formGroup.patchValue({
        filename: null,
      });
    }
  }

  readFileAsText(file: File): Observable<string> {
    return this.readFile(file).pipe(
      map((arr) => {
        let coding: string = encoding.detect(arr);
        switch (coding) {
          case 'UTF8':
            coding = 'utf-8';
            break;
          case 'SJIS':
            coding = 'shift_jis';
            break;
          default:
            coding = 'utf-8';
            break;
        }
        return new TextDecoder(coding).decode(arr);
      })
    );
  }

  readFile(file: File): Observable<Uint8Array> {
    return new Observable((sub) => {
      const fr = new FileReader();
      fr.onerror = () => {
        sub.error(fr.error);
      };
      fr.onabort = () => {
        sub.complete();
      };
      fr.onload = () => {
        if (fr.result instanceof ArrayBuffer) {
          sub.next(new Uint8Array(fr.result));
        }
        sub.complete();
      };
      fr.readAsArrayBuffer(file);
      return () => {
        if (fr.readyState === 1) {
          fr.abort();
        }
        fr.onload = null;
        fr.onerror = null;
        fr.onabort = null;
      };
    });
  }

  selectBestColumn() {
    this.allowFiveDigits = [];
    const issueCount = [];
    for (const row of this.csvParseResult) {
      for (let i = 0; i < row.length; i++) {
        if (this.allowFiveDigits.length <= i) {
          this.allowFiveDigits[i] = this.checkDigits(i);
        }
        issueCount[i] = (issueCount[i] || 0) + (this.isIssueCode(row[i], this.allowFiveDigits[i]) ? 1 : 0);
      }
    }
    this.issueCount = issueCount.map((a) => a || 0);
    if (issueCount.length > 0) {
      const maxCount = issueCount.map((a) => a || 0).reduce((a, b) => Math.max(a, b));
      for (let i = 0; i < issueCount.length; i++) {
        if (issueCount[i] === maxCount) {
          while (this.columns.length < i) {
            this.columns.push('');
          }
          this.formGroup.patchValue({
            columnIndex: i,
          });
          this.columnIndex.enable();
          break;
        }
      }
    }
  }

  onSubmit() {
    if (this.multipleWatchLists) {
      this.validateWatchLists();
      if (this.validMultipleWatchLists) {
        for (let j = 0; j < this.watchLists.length; j++) {
          const wl = <NewWatchList>this.watchLists[j];
          if (!wl.checked) {
            continue;
          }
          const summary = this.cocomero.summaries.addWatchList(wl.name);
          if (j === 0) {
            this.resultWatchList = summary;
          }
          for (const issueCode of wl.issueCodes) {
            summary.addIssue(issueCode, 1);
          }
          this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
          this.cocomero.summaries.saveWatchList(summary);
        }
        this.csvImportDialog.hide();
      }
    } else {
      if (this.formGroup.valid) {
        const summary = this.cocomero.summaries.addWatchList(this.name.value.toString().trim());
        const allowFiveDigits = this.checkDigits(this.columnIndex.value);
        for (let i = 0; i < this.csvParseResult.length; i++) {
          if (this.isIssueCode(this.csvParseResult[i][this.columnIndex.value], allowFiveDigits)) {
            summary.addIssue(this.getIssueCode(this.csvParseResult[i][this.columnIndex.value], allowFiveDigits));
          }
        }
        this.resultWatchList = summary;
        this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
        this.cocomero.summaries.saveWatchList(summary);
        this.csvImportDialog.hide();
      }
    }
  }

  private checkDigits(col: number): boolean {
    for (let j = 1; j < this.csvParseResult.length; j++) {
      const row = this.csvParseResult[j];
      if (row.length <= col) {
        return false;
      }
      if (row[col].trim().length !== 5) {
        return false;
      }
    }
    return true;
  }

  private isIssueCode(str: string, allowFiveDigits: boolean): boolean {
    str = str.trim();
    if (str.length === 4 || str.length === 5) {
      let issueCode = Number(str);
      if (allowFiveDigits && issueCode > 9999 && issueCode % 10 === 0) {
        issueCode = issueCode / 10;
      }
      return issueCode in this.cocomero.op.issueCodeMap;
    }
    return false;
  }

  private getIssueCode(str: string, allowFiveDigits: boolean): number {
    str = str.trim();
    if (str.length === 4 || str.length === 5) {
      let issueCode = Number(str);
      if (allowFiveDigits && issueCode > 9999 && issueCode % 10 === 0) {
        issueCode = issueCode / 10;
      }
      return issueCode;
    }
    return null;
  }

  uniqueNameValidator(control: AbstractControl): { [key: string]: any } | null {
    return !this.multipleWatchLists && this.watchListNames && this.watchListNames.has((control.value || '').toString().trim())
      ? {
          notUnique: control.value,
        }
      : null;
  }

  nameLengthValidator(control: AbstractControl): { [key: string]: any } | null {
    return !this.multipleWatchLists && (control.value || '').length > 9
      ? {
          nameLength: control.value,
        }
      : null;
  }

  // 複数件ウォッチリストの初期化
  createMultipleWatchList() {
    this.watchLists.clear();
    const watchLists = {};
    for (let i = 1; i < this.csvParseResult.length; i++) {
      const row = this.csvParseResult[i];
      const [name, issueCode] = [row[0], row[1]];
      let id;
      if (name in watchLists) {
        id = watchLists[name];
      } else {
        const newWl = new NewWatchList();
        newWl.name = newWl.originalName = name;
        this.watchLists.push(newWl);
        id = watchLists[name] = this.watchLists.length - 1;
      }
      const wl = <NewWatchList>this.watchLists[id];
      if (this.isIssueCode(issueCode, false)) {
        wl.issueCodes.push(Number(issueCode));
      } else {
        wl.invalidIssueCode.push(issueCode);
      }
    }
    this.validateWatchLists();
  }

  onCellEditEnded(s: FlexGrid, e: CellRangeEventArgs) {
    this.validateWatchLists();
  }

  private validateWatchLists() {
    this.validMultipleWatchLists = true;
    let count = 0;
    const names = new Set<string>();
    for (let i = 0; i < this.watchLists.length; i++) {
      const wl = <NewWatchList>this.watchLists[i];
      wl.validate(this.watchListNames);
      if (wl.checked) {
        count++;
        if (names.has(wl.name.trim())) {
          wl.nameError.notUnique = true;
        }
        if (wl.name.trim().length > 9) {
          wl.nameError.nameLength = true;
        }
        names.add(wl.name.trim());
        if (wl.nameError.required || wl.nameError.notUnique || wl.nameError.nameLength) {
          this.validMultipleWatchLists = false;
        }
      }
    }
    if (count === 0) {
      this.validMultipleWatchLists = false;
    }
  }

  onBeginningEdit(sender: FlexGrid, e: CellRangeEventArgs): void {
    if (e.col === sender.columns.getColumn('name').index) {
      setTimeout(function () {
        if (sender.activeEditor) {
          sender.activeEditor.setAttribute('maxlength', '9');
        }
      });
    }
  }

  onHidden() {
    if (this.subscribe) {
      if (this.resultWatchList) {
        this.subscribe.next(this.resultWatchList);
      }
      this.subscribe.complete();
      this.subscribe = null;
    }
    this.resultWatchList = null;
  }
}
