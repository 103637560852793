import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { StockWrapper, UserAgentService } from '@argentumcode/brisk-common';
import { Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-mini-chart',
  templateUrl: './mini-chart.component.html',
  styleUrls: ['./mini-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniChartComponent implements OnInit, OnChanges, OnDestroy {
  private updatedSubscription: Subscription = null;
  public isEdge = false;
  public hasTspanTooltipBug = false;

  @Input()
  stockWrapper: StockWrapper;

  constructor(private changeDetectorRef: ChangeDetectorRef, private platform: Platform, private userAgent: UserAgentService) {
    this.isEdge = platform.EDGE || platform.TRIDENT;
    this.hasTspanTooltipBug = this.userAgent.isWindows10 && this.userAgent.IE;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }
  }

  updateSize() {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('stockWrapper' in changes) {
      if (this.updatedSubscription) {
        this.updatedSubscription.unsubscribe();
        this.updatedSubscription = null;
      }
      if (this.stockWrapper) {
        this.updatedSubscription = this.stockWrapper.updated.subscribe(() => {
          this.changeDetectorRef.markForCheck();
        });
      }
    }
  }
}
