import { Injectable } from '@angular/core';
import { StockMaster } from '../flex';
import { FastDecimalPipe } from '../brisk-common/fast-decimal.pipe';
import { SochiPipe } from '../brisk-common/sochi.pipe';

// Markets-Line / Markets-Gridで表示用の場況情報
class MarketCondition {
  /// 場況の種別名
  typeName: string;
  /// 銘柄コード
  issueCode?: string;
  /// 銘柄名
  stockName?: string;
  /// Prefix(市場区分)
  prefix?: string;
  /// 詳細
  text: string;
  /// 金額(Line表示用)
  priceText: string;
  /// 金額(Grid表示用)
  turnover: number;
}

export type Market = GyakuhibuTop | FlexMarket;

interface MarketCommon {
  type: number;
  time: string;
  issue_code?: number;

  value10?: number;

  // 表示用の項目
  time2?: string;
  stockName?: string;
  prefix?: string;
  issueCode?: string;
  typeName?: string;
  text?: string;
  priceText?: string;
  turnover?: number;
}

export interface GyakuhibuTop extends MarketCommon {
  rank?: number;
  application_date: string;
  fee: number;
  fee_day_count: number;
  fee_percent: number;
  restriction: string | null;
  max_fee: number;
}

export interface FlexMarket extends MarketCommon {
  index: number;

  kind?: number;
  market?: number;
  exceeding?: number;
  value_ratio?: number;
  side?: number;
  updown?: number;
  state?: number;
  price10?: number;
  price_ratio?: number;
  diff_bps_from_last?: number;
}

function shift(number: number, precision: number, reverseShift: boolean) {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = ('' + number).split('e');
  return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
}

// From: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function round(number: number, precision: number) {
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

@Injectable()
export class MarketConditionsService {
  /// Openingの既に追加されたIssueCode
  /// 同じ銘柄についてOpeningは1度のみ表示するために利用する
  private _openingIssueCodes = new Set<number>();
  private _markets: Array<Market> = [];

  get markets(): Array<Market> {
    return this._markets;
  }

  constructor(private fastDecimal: FastDecimalPipe, private sochi: SochiPipe) {}

  prepareGyakuhibuTop(data: Array<GyakuhibuTop>): Array<GyakuhibuTop> {
    for (let i = 0; i < data.length; i++) {
      data[i].rank = i;
      data[i].type = 20;
    }
    return data;
  }

  setText(data: GyakuhibuTop | FlexMarket, master?: StockMaster) {
    data.time2 = data.time.substring(0, 8);
    data.stockName = master ? master.name : '';
    data.prefix = master ? master.prefix : '';
    data.issueCode = master ? master.issueCode.toString() : '';
    data.typeName = this.typeToString(data['type']);
    [data.text, data.priceText] = this.getMessage(data);
    if (data.value10 !== undefined && data.value10 !== null) {
      data.turnover = round(Math.abs(data.value10 / 10 / 100000000), 1); // 億円単位の金額
    }
    if (data.text === null) {
      return null;
    }
    return data;
  }

  /**
   * BRiSKに表示するべき場況かどうかを判断する。表示するべきでない場合はTrueを返す。
   * @param data
   */
  checkSkip(data: Market): boolean {
    if (!data || this.typeToString(data.type) === '') {
      return true;
    }
    if (data.type === 5) {
      if ((data as FlexMarket).exceeding !== 1) {
        return true;
      }
      // 同じ銘柄は2回表示しない
      if (this._openingIssueCodes.has(data.issue_code)) {
        return true;
      }
    }
    return false;
  }

  /**
   * 場況情報を取得し、表示用に保持する
   * @param markets 場況の配列
   * @param getMasterCallback IssueCodeからStockMasterを取得するための関数
   */
  pushMarkets(markets: Array<Market>, getMasterCallback: (issueCode: number) => StockMaster | undefined): Array<Market> {
    const ret: Array<Market> = [];
    for (const market of markets) {
      if (this.checkSkip(market)) {
        continue;
      }
      const master = market.issue_code ? getMasterCallback(market.issue_code) : undefined;
      // 銘柄コードが設定されているがMasterに存在しない場合
      if (market.issue_code && !master) {
        // 表示対象外とする。
        continue;
      }
      if (this.setText(market, master)) {
        this._markets.push(market);
        if (market.type === 5) {
          this._openingIssueCodes.add(market.issue_code);
        }
        ret.push(market);
      }
    }
    if (markets.length > 0) {
      this._markets.sort((i1, i2) => {
        if (i1.time < i2.time) {
          return -1;
        }
        if (i1.time > i2.time) {
          return 1;
        }
        if ((i1 as FlexMarket).index !== undefined && (i2 as GyakuhibuTop).rank !== undefined) {
          return 1;
        }
        if ((i1 as GyakuhibuTop).rank !== undefined && (i2 as FlexMarket).index !== undefined) {
          return -1;
        }
        if ((i1 as FlexMarket).index > (i2 as FlexMarket).index) {
          return 1;
        }
        if ((i1 as FlexMarket).index < (i2 as FlexMarket).index) {
          return -1;
        }
        return -((i1 as GyakuhibuTop).rank - (i2 as GyakuhibuTop).rank);
      });
      // Change Detectionを実行させるために、オブジェクトを再生成する
      this._markets = this._markets.slice(0);
    }
    return ret;
  }

  private typeToString(type: number): string {
    switch (type) {
      case 2:
        return '225型バスケット';
      case 3:
        return 'ストップ高安接近';
      case 4:
        return '特別気配更新接近';
      case 5:
        return '寄付出来高急増予想';
      case 6:
        return '大口注文';
      case 7:
        return '5分足出来高急増';
      case 8:
        return 'ストップ高';
      case 9:
        return 'ストップ安';
      case 10:
        return 'IPO';
      case 20:
        return '高額逆日歩';
    }
    return '';
  }

  private getMessage(data: GyakuhibuTop | FlexMarket): [string, string] {
    switch (data['type']) {
      case 2:
        return this.basketToString(data as FlexMarket);
      case 3:
        return this.limitUpDownToStringOldVersion(data as FlexMarket);
      case 4:
        return this.squpdateToString(data as FlexMarket);
      case 5:
        return this.openingToString(data as FlexMarket);
      case 6:
        return this.largeLotToString(data as FlexMarket);
      case 7:
        return ['', ''];
      case 8:
      case 9:
        return this.limitUpDownToString(data as FlexMarket);
      case 10:
        return this.ipoString(data as FlexMarket);
      case 20:
        return this.gyakuhibuString(data as GyakuhibuTop);
    }
    return ['', ''];
  }

  private basketToString(data: FlexMarket): [string, string] {
    const kindMap = { 1: '買', 2: '売', 3: '買引', 4: '売引' };
    const market = data.market === 1 ? '成行' : '';
    return [`${kindMap[data.kind]}${market}${data.value10 < 0 ? '取消' : ''}`, `${Math.abs(Math.round(data.value10 / 1000000000))}億円`];
  }

  private openingToString(data: FlexMarket): [string, string] {
    if (data.exceeding === 1) {
      return [`${Math.round(data.value_ratio)}倍`, `${Math.round(data.value10 / 1000000000)}億円`];
    } else {
      return [null, null];
    }
  }

  private squpdateToString(data: FlexMarket): [string, string] {
    const kindMap = { 1: 'Jump', 2: 'Open' };
    const sideMap = { 1: '買', 2: '売' };
    return [`${sideMap[data.side]}${kindMap[data.kind]} 30秒前`, `(${Math.abs(Math.round(data.value10 / 100000000) / 10).toFixed(1)}億円)`];
  }

  private limitUpDownToStringOldVersion(data: FlexMarket): [string, string] {
    const upDownMap = { 1: '高', 2: '安' };
    return [`ストップ${upDownMap[data.updown]} 接近`, ''];
  }

  private largeLotToString(data: FlexMarket): [string, string] {
    const kindMap = {
      1: '買',
      2: '売',
      3: '買引',
      4: '売引',
      5: '買不成',
      6: '売不成',
    };
    const value = Math.round(data.value10 / 1000000000);
    const price =
      data.price10 % 10 === 0
        ? this.fastDecimal.transform(data.price10 / 10, { separateComma: true })
        : this.fastDecimal.transform(data.price10 / 10, { separateComma: true, fractionDigits: 1 });
    if (price === '0') {
      return [`${kindMap[data.kind]}成行${value < 0 ? '取消' : ''}`, `(${Math.abs(Math.round(data.value10 / 1000000000))}億円)`];
    } else {
      return [`${kindMap[data.kind]}${value < 0 ? '取消' : ''}@${price}円`, `(${Math.abs(Math.round(data.value10 / 1000000000))}億円)`];
    }
  }

  private limitUpDownToString(data: FlexMarket): [string, string] {
    switch (data.state) {
      case 1:
        return ['接近', ''];
      case 2:
        return ['約定', ''];
    }
    return ['', ''];
  }

  private ipoString(data: FlexMarket): [string, string] {
    return ['初値決定', ''];
  }

  private gyakuhibuString(data: GyakuhibuTop): [string, string] {
    const price = `${this.fastDecimal.transform(data.fee, {
      separateComma: true,
      fractionDigits: Math.round(data.fee) !== data.fee ? 2 : 0,
    })}円`;
    let text = `${this.fastDecimal.transform(data.fee_percent, { separateComma: true, fractionDigits: 1 })}% / ${price}`;

    if (data.restriction) {
      text += ' / ' + this.sochi.transform(data.restriction);
    }
    return [text, ''];
  }
}
