<app-chart-portfolio
  *ngIf="collection"
  #chartPortfolio
  [visible]="visible"
  [dataSource]="collection"
  [columns]="itaColumnCount"
  (click)="$event.stopPropagation()"
  (stockSelected)="onStockSelected($event)"
  (cellDblClicked)="onCellDblClicked()"
></app-chart-portfolio>
