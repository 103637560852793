import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Button, ModalDialogService, ModelDialogComponentInterface } from '../modal-dialog.service';
import { Observable, Observer } from 'rxjs';
import { Popup } from '@grapecity/wijmo.input';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit, ModelDialogComponentInterface {
  @ViewChild('modalDialogPopup', { static: true })
  public modalDialogPopup: Popup;
  private observer: Observer<string | null>;

  public value = '';

  public title = '';
  public messages: Array<string> = [];
  public messageTemplate: TemplateRef<any> = null;
  public closable = true;
  public buttons: Array<Button>;
  public cssClass: string;

  constructor(private modalDialog: ModalDialogService) {}

  ngOnInit() {
    this.modalDialog.setupComponent(this);
  }

  prompt(
    message: string | Array<string> | TemplateRef<any>,
    buttons: Array<Button>,
    closable = true,
    title?: string,
    cssClass?: string
  ): Observable<string | null> {
    return new Observable((observer: Observer<string | null>) => {
      this.observer = observer;
      this.messageTemplate = null;
      this.messages = null;
      if (typeof message === 'string') {
        this.messages = [message];
      } else if (message instanceof Array) {
        this.messages = message;
      } else {
        this.messageTemplate = message;
      }
      this.title = title || '';
      this.value = '';
      this.closable = closable;
      this.buttons = buttons;
      this.cssClass = cssClass || 'dialog-default';
      setTimeout(() => {
        this.modalDialogPopup.show(true);
      });
    });
  }

  submit(result: string) {
    if (this.observer) {
      this.observer.next(result);
      this.observer.complete();
      this.observer = null;
      this.modalDialogPopup.hide();
    }
  }

  complete() {
    if (this.observer) {
      this.observer.next(null);
      this.observer.complete();
      this.observer = null;
    }
  }
}
