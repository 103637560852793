import { Pipe, PipeTransform } from '@angular/core';
import { FastDecimalPipe } from './fast-decimal.pipe';

@Pipe({
  name: 'formatPrice',
})
export class FormatPricePipe implements PipeTransform {
  constructor(private fastDecimal: FastDecimalPipe) {}

  transform(value: any, args?: any): any {
    if (value === null || value === undefined || value === 0) {
      return '';
    }
    return this.fastDecimal.transform(value / 10, { separateComma: true, fractionDigits: 1 });
  }
}
