import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

interface DialogComponent {
  prompt(message: string, title?: string): Observable<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class InputDialogService {
  private component: DialogComponent;

  constructor() {}

  public dialog(message: string, title?: string): Observable<string | null> {
    return this.component.prompt(message, title);
  }

  public setupComponent(component: DialogComponent) {
    this.component = component;
  }
}
