import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';

@Injectable({
  providedIn: 'root',
})
export class StockDetailsService {
  private active = '';
  private changeActive = new BehaviorSubject('');
  changeActive$: Observable<string> = this.changeActive.asObservable();
  private issueCodeChange = new Subject<[string, number]>();
  issueCodeChange$: Observable<[string, number]> = this.issueCodeChange.asObservable();

  animationFrame$ = new Subject<{}>();

  private funcs: Array<() => void> = [];

  constructor(zone: NgZone, private cocomero: CocomeroService) {
    zone.runOutsideAngular(() => {
      const raf = () => {
        this.animationFrame$.next();
        if (this.funcs.length > 0) {
          zone.runGuarded(() => {
            for (const f of this.funcs) {
              f();
            }
            this.funcs = [];
          });
        }
        window.requestAnimationFrame(raf);
      };
      window.requestAnimationFrame(raf);
    });
  }

  runInAngular(updateFn: () => void) {
    this.funcs.push(updateFn);
  }

  setActive(id: string) {
    this.active = id;
    this.changeActive.next(id);
  }

  changeIssueCodeRequest(issueCode: number) {
    this.cocomero.changeIssueCode(issueCode);
  }
}
