import { Injectable } from '@angular/core';
import { FIXOrder, FIXService, isFiltered } from './fix.service';
import { StockView } from '@argentumcode/brisk-common';
import { OrderSource, OrderSourceService } from './order-source.service';
import { Condition, Price10, Share, Side } from './object';
import { Subject, Subscription, timer } from 'rxjs';
import { ErrorService, StockWrapper } from '@argentumcode/brisk-common';

export enum ItaRegisteredOrderType {
  BidClose,
  Bid,
  Ask,
  AskClose,
}

class ItaAmendingOrder {
  issueCode: number;
  visible = false;
  orders: Array<FIXOrder> = [];
  srcPrice10: Price10 = null;
  srcOverUnder: 'OVER' | 'UNDER' | null = null;
  srcOrderType: ItaRegisteredOrderType = null;
  dstPrice10: Price10 = null;
  dstOrderType: ItaRegisteredOrderType = null;
}

class ItaRegisteredOrder {
  get price10(): Price10 {
    return this.detail.price10;
  }

  get quantity(): Share {
    return this.detail.quantity;
  }

  constructor(public issueCode: number, public type: ItaRegisteredOrderType, public detail: OrderSource) {}
}

export class FixItaRowSide {
  // 板に登録された仮order
  registeredOrder = 0;
  // 訂正: 移動先のオーダー
  amendingOrder = 0;
  // 訂正：移動前のオーダーかどうか
  isAmendingOrderSrc = false;
  // 実際に発注済のオーダ
  order = 0;
  // 個数 * price10
  normalizedOrder = 0;
}

export class FixItaRow {
  price10: number;
  ask: FixItaRowSide;
  askClose: FixItaRowSide;
  bid: FixItaRowSide;
  bidClose: FixItaRowSide;

  constructor() {
    this.ask = new FixItaRowSide();
    this.bid = new FixItaRowSide();
    this.askClose = new FixItaRowSide();
    this.bidClose = new FixItaRowSide();
  }
}

@Injectable({
  providedIn: 'root',
})
export class FixItaService {
  private amending: ItaAmendingOrder = new ItaAmendingOrder();
  private issueCache = new Map<string, FixItaRow[]>();
  private itaRegisteredOrder: ItaRegisteredOrder = null;
  private timerSubscription: Subscription = null;
  private registeredClearedSubject = new Subject<{}>();
  public readonly registerCleared = this.registeredClearedSubject.asObservable();
  private price10ClickedSubject = new Subject<number>();
  public readonly price10Clicked = this.price10ClickedSubject.asObservable();

  constructor(private fix: FIXService, private orderSourceService: OrderSourceService, private error: ErrorService) {
    this.fix.updated.subscribe(() => {
      this.cacheClear();
    });
  }

  private cacheClear() {
    this.issueCache.clear();
  }

  public get(stockWrapper: StockWrapper): FixItaRow[] {
    const view = stockWrapper.current;
    const cacheKey = `${view.itaRowCount}:${view.master.issueCode}:${view.itaRowPriceIndex}`;
    if (!this.issueCache.has(cacheKey)) {
      if (this.itaRegisteredOrder && view.master.issueCode !== this.itaRegisteredOrder.issueCode) {
        this.clearRegisteredOrder(stockWrapper);
      }
      const arr = new Array<FixItaRow>(view.rows.length + 3).fill(null).map(() => new FixItaRow());
      const pos = this.fix.portfolio.positionsDic[view.master.issueCode];
      for (let i = -1; i < view.rows.length; i++) {
        const row = i === -1 ? arr[0] : arr[arr.length - 2 - i]; // -1: 成行
        const viewRow = i === -1 ? view.market : view.rows[i];
        let order: ItaRegisteredOrder = null;
        if (viewRow && this.itaRegisteredOrder && viewRow.price10 === this.itaRegisteredOrder.price10.value) {
          order = this.itaRegisteredOrder;
          if (order.type === ItaRegisteredOrderType.AskClose) {
            row.askClose.registeredOrder = order.quantity.value;
          } else if (order.type === ItaRegisteredOrderType.Ask) {
            row.ask.registeredOrder = order.quantity.value;
          } else if (order.type === ItaRegisteredOrderType.BidClose) {
            row.bidClose.registeredOrder = order.quantity.value;
          } else if (order.type === ItaRegisteredOrderType.Bid) {
            row.bid.registeredOrder = order.quantity.value;
          }
        }
        if (pos) {
          if (pos.bids[viewRow.price10]) {
            row.bid.order = pos.bids[viewRow.price10].value;
            row.bid.normalizedOrder = pos.bids[viewRow.price10].value * viewRow.price10;
          }
          if (pos.bidCloses[viewRow.price10]) {
            row.bidClose.order = pos.bidCloses[viewRow.price10].value;
            row.bidClose.normalizedOrder = pos.bidCloses[viewRow.price10].value * viewRow.price10;
          }
          if (pos.asks[viewRow.price10]) {
            row.ask.order = pos.asks[viewRow.price10].value;
            row.ask.normalizedOrder = pos.asks[viewRow.price10].value * viewRow.price10;
          }
          if (pos.askCloses[viewRow.price10]) {
            row.askClose.order = pos.askCloses[viewRow.price10].value;
            row.askClose.normalizedOrder = pos.askCloses[viewRow.price10].value * viewRow.price10;
          }
        }
        if (this.isAmending(stockWrapper) && this.amending.visible) {
          const amendingQty = new Share(0);
          for (const ord of this.amending.orders) {
            amendingQty.add(ord.workingQuantity);
          }
          if (
            this.amending.srcPrice10 &&
            this.amending.srcPrice10.value === viewRow.price10 &&
            this.amending.srcPrice10.ne(new Price10(0))
          ) {
            if (this.amending.srcOrderType === ItaRegisteredOrderType.AskClose) {
              row.askClose.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.Ask) {
              row.ask.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.Bid) {
              row.bid.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.BidClose) {
              row.bidClose.isAmendingOrderSrc = true;
            }
          }
          if (
            this.amending.dstPrice10 &&
            this.amending.dstPrice10.value === viewRow.price10 &&
            this.amending.dstPrice10.ne(new Price10(0))
          ) {
            if (this.amending.dstOrderType === ItaRegisteredOrderType.AskClose) {
              row.askClose.amendingOrder = amendingQty.value;
            } else if (this.amending.dstOrderType === ItaRegisteredOrderType.Ask) {
              row.ask.amendingOrder = amendingQty.value;
            } else if (this.amending.dstOrderType === ItaRegisteredOrderType.Bid) {
              row.bid.amendingOrder = amendingQty.value;
            } else if (this.amending.dstOrderType === ItaRegisteredOrderType.BidClose) {
              row.bidClose.amendingOrder = amendingQty.value;
            }
          }
        }
      }
      // OVER / UNDER
      if (pos) {
        const over = arr[1],
          under = arr[arr.length - 1];
        this.accumulateOverUnder(over, under, view, pos.bids, (row, val, p10) => {
          row.bid.order += val;
          row.bid.normalizedOrder += val * p10;
        });
        this.accumulateOverUnder(over, under, view, pos.asks, (row, val, p10) => {
          row.ask.order += val;
          row.ask.normalizedOrder += val * p10;
        });
        this.accumulateOverUnder(over, under, view, pos.bidCloses, (row, val, p10) => {
          row.bidClose.order += val;
          row.bidClose.normalizedOrder += val * p10;
        });
        this.accumulateOverUnder(over, under, view, pos.askCloses, (row, val, p10) => {
          row.askClose.order += val;
          row.askClose.normalizedOrder += val * p10;
        });
        if (this.isAmending(stockWrapper) && this.amending.visible) {
          if (this.amending.srcPrice10 === null) {
            const row = this.amending.srcOverUnder === 'OVER' ? over : under;
            if (this.amending.srcOrderType === ItaRegisteredOrderType.AskClose) {
              row.askClose.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.Ask) {
              row.ask.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.Bid) {
              row.bid.isAmendingOrderSrc = true;
            } else if (this.amending.srcOrderType === ItaRegisteredOrderType.BidClose) {
              row.bidClose.isAmendingOrderSrc = true;
            }
          }
        }
      }

      this.issueCache.set(cacheKey, arr);
    }
    return this.issueCache.get(cacheKey);
  }

  private accumulateOverUnder(
    over: FixItaRow,
    under: FixItaRow,
    view: StockView,
    vals: { [key: number]: Share },
    callback: (row: FixItaRow, val: number, p10: number) => void
  ) {
    for (const price10 of Object.keys(vals).map((a) => Number(a))) {
      let target: FixItaRow = null;
      if (price10 < view.rows[0].price10) {
        target = under;
      } else if (price10 > view.rows[view.rows.length - 1].price10) {
        target = over;
      }
      if (target) {
        callback(target, vals[price10].value, price10);
      }
    }
  }

  public tryRegisterOrder(stockWrapper: StockWrapper, price10: Price10, quantity: Share, type: ItaRegisteredOrderType): boolean {
    const src = this.orderSourceService.get(stockWrapper.base.master);
    if (!src) {
      return false;
    }

    let condition: Condition = Condition.Zaraba;
    let side: Side = Side.Buy;
    switch (type) {
      case ItaRegisteredOrderType.Bid:
        condition = Condition.Zaraba;
        side = Side.Buy;
        break;
      case ItaRegisteredOrderType.Ask:
        condition = Condition.Zaraba;
        side = Side.ShortSell;
        break;
      case ItaRegisteredOrderType.BidClose:
        condition = Condition.OnClose;
        side = Side.Buy;
        break;
      case ItaRegisteredOrderType.AskClose:
        condition = Condition.OnClose;
        side = Side.ShortSell;
        break;
    }
    src.price10 = price10;
    src.quantity = quantity;
    src.side = side;
    src.condition = condition;

    return this.tryRegisterFromSource(stockWrapper);
  }

  public tryRegisterSendFromSource(stockWrapper: StockWrapper): boolean {
    const src = this.orderSourceService.get(stockWrapper.base.master);
    if (!src) {
      this.clearRegisteredOrder(stockWrapper);
      return false;
    }

    if (this.itaRegisteredOrder !== null) {
      const ord = this.itaRegisteredOrder.detail;
      if (
        stockWrapper.base.master.issueCode === this.itaRegisteredOrder.issueCode &&
        ord.price10.eq(src.price10) &&
        ord.quantity.eq(src.quantity) &&
        ord.account === src.account &&
        ord.side === src.side &&
        ord.condition === src.condition &&
        ord.tag === src.tag
      ) {
        const fill =
          (this.itaRegisteredOrder.type === ItaRegisteredOrderType.Bid &&
            stockWrapper.current.askPrice10 !== 0 &&
            (this.itaRegisteredOrder.price10.isMarket ||
              new Price10(stockWrapper.current.askPrice10).le(this.itaRegisteredOrder.price10))) ||
          (this.itaRegisteredOrder.type === ItaRegisteredOrderType.Ask &&
            stockWrapper.current.bidPrice10 !== 0 &&
            (this.itaRegisteredOrder.price10.isMarket || new Price10(stockWrapper.current.bidPrice10).ge(this.itaRegisteredOrder.price10)));
        this.fix.sendNew(
          src.account.id,
          src.tag,
          stockWrapper.base.master.issueCode,
          src.price10,
          src.quantity,
          src.side,
          src.condition,
          fill
        );
        this.clearRegisteredOrder(stockWrapper);
        return true;
      }
    }

    if (this.tryRegisterFromSource(stockWrapper)) {
      return true;
    } else {
      this.clearRegisteredOrder(stockWrapper);
      return false;
    }
  }

  public tryRegisterFromSource(stockWrapper: StockWrapper): boolean {
    const source = this.orderSourceService.get(stockWrapper.base.master);
    if (!source) {
      return false;
    }
    let quantity = source.quantity;
    let side = source.side;
    const master = stockWrapper.base.master;
    if (!source.account) {
      this.error.alert('証券会社:アカウントが選択されていません');
      return false;
    } else if (
      !isFiltered(this.fix.filterUserID, this.fix.filterBrokerAccountID, this.fix.filterTag, this.fix.userID, source.account.id, source.tag)
    ) {
      this.error.alert('フィルタで選択されている条件から外れた注文です');
      return false;
    }
    if (source.side !== Side.LongSell) {
      const [cappedQuantity, cappedSide] = this.fix.capNewOrder(master.issueCode, source.quantity, source.side === Side.Buy);
      quantity = cappedQuantity;
      side = cappedSide;
    }
    let found = source.price10.isMarket;
    if (!found) {
      if (stockWrapper.base.master.tick.indexToPrice10(master.tick.price10ToIndex(source.price10.value)) === source.price10.value) {
        const idx = master.tick.price10ToIndex(source.price10.value);
        if (
          stockWrapper.current.itaRowPriceIndex <= idx &&
          idx < stockWrapper.current.itaRowPriceIndex + stockWrapper.current.itaRowCount
        ) {
          found = true;
        }
      }
    }
    if (!found) {
      this.error.alert('指定された値段は表示されていません');
      return false;
    }
    const type = this.getRegisteredOrderType(side, source.condition);
    const detail = source.clone();
    detail.quantity = quantity;
    detail.side = side;
    this.itaRegisteredOrder = new ItaRegisteredOrder(master.issueCode, type, detail);
    this.cacheClear();
    if (this.timerSubscription !== null) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.timerSubscription = timer(3000).subscribe(() => {
      this.clearRegisteredOrder(stockWrapper);
    });
    stockWrapper.update = true;
    return true;
  }

  clearRegisteredOrder(stockWrapper: StockWrapper): void {
    stockWrapper.update = true;
    this.itaRegisteredOrder = null;
    this.cacheClear();
    if (this.timerSubscription !== null) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    if (this.registeredClearedSubject !== null) {
      this.registeredClearedSubject.next();
    }
  }

  getRegisteredOrderType(side: Side, condition: Condition): ItaRegisteredOrderType {
    if (side === Side.Buy) {
      if (condition === Condition.OnClose) {
        return ItaRegisteredOrderType.BidClose;
      } else {
        return ItaRegisteredOrderType.Bid;
      }
    } else {
      if (condition === Condition.OnClose) {
        return ItaRegisteredOrderType.AskClose;
      } else {
        return ItaRegisteredOrderType.Ask;
      }
    }
  }

  tryRegisterOrderByClick(stockWrapper: StockWrapper, p10: Price10, lotSize: Share, type: ItaRegisteredOrderType, ctrl: boolean) {
    if (this.isAmending(stockWrapper)) {
      this.clearAmending(stockWrapper);
      this.error.alert('訂正中には板登録できません.訂正を中断します');
      return;
    }
    let qty = new Share(0);
    if (ctrl) {
      if (this.isBuy(type)) {
        for (const row of stockWrapper.current.rows) {
          if (new Price10(row.price10).le(p10)) {
            qty.add(new Share(row.ask.quantity));
          }
        }
        qty.add(new Share(stockWrapper.current.under.ask.quantity));
        qty.add(new Share(stockWrapper.current.market.ask.quantity));
      } else {
        for (const row of stockWrapper.current.rows) {
          if (new Price10(row.price10).ge(p10)) {
            qty.add(new Share(row.bid.quantity));
          }
        }
        qty.add(new Share(stockWrapper.current.over.bid.quantity));
        qty.add(new Share(stockWrapper.current.market.bid.quantity));
      }
    } else {
      if (
        this.itaRegisteredOrder &&
        this.itaRegisteredOrder.issueCode === stockWrapper.base.master.issueCode &&
        this.itaRegisteredOrder.quantity.eq(lotSize) &&
        this.itaRegisteredOrder.price10.eq(p10) &&
        this.itaRegisteredOrder.type === type
      ) {
        qty = new Share(lotSize.value * 10);
      } else {
        qty = lotSize;
      }
    }
    stockWrapper.update = true;
    this.clearRegisteredOrder(stockWrapper);
    if (qty.gt(new Share(0))) {
      this.tryRegisterOrder(stockWrapper, p10, qty, type);
    }
  }

  trySendOrderByClick(stockWrapper: StockWrapper, p10: Price10, lotSize: Share, type: ItaRegisteredOrderType): boolean {
    const view = stockWrapper.current;
    stockWrapper.update = true;
    if (this.itaRegisteredOrder && this.itaRegisteredOrder.issueCode !== view.master.issueCode) {
      this.clearRegisteredOrder(stockWrapper);
      return false;
    }

    if (this.itaRegisteredOrder) {
      if (this.itaRegisteredOrder.type === type) {
        if (this.fix) {
          const fill =
            (this.itaRegisteredOrder.type === ItaRegisteredOrderType.Bid &&
              view.askPrice10 !== 0 &&
              (this.itaRegisteredOrder.price10.isMarket || new Price10(view.askPrice10).le(this.itaRegisteredOrder.price10))) ||
            (this.itaRegisteredOrder.type === ItaRegisteredOrderType.Ask &&
              view.bidPrice10 !== 0 &&
              (this.itaRegisteredOrder.price10.isMarket || new Price10(view.bidPrice10).ge(this.itaRegisteredOrder.price10)));
          const source = this.itaRegisteredOrder.detail;
          this.fix.sendNew(
            source.account.id,
            source.tag,
            view.master.issueCode,
            source.price10,
            source.quantity,
            source.side,
            source.condition,
            fill
          );
        }
        this.clearRegisteredOrder(stockWrapper);
      }
      return true;
    } else {
      return false;
    }
  }

  public clearAmending(stockOperator: StockWrapper) {
    if (this.isAmending(stockOperator)) {
      this.amending.orders = [];
      this.amending.srcPrice10 = this.amending.dstPrice10 = null;
      this.amending.srcOrderType = this.amending.dstOrderType = null;
      this.amending.srcOverUnder = null;
      this.amending.issueCode = null;
      stockOperator.update = true;
    }
  }

  public hasRegisteredOrder(stockOperator: StockWrapper) {
    return (
      stockOperator &&
      stockOperator.base &&
      this.itaRegisteredOrder &&
      this.itaRegisteredOrder.issueCode === stockOperator.base.master.issueCode
    );
  }

  public isAmending(stockOperator: StockWrapper) {
    return this.amending.issueCode === stockOperator.current.master.issueCode && this.amending.orders.length > 0;
  }

  public startAmending(stockOperator: StockWrapper, p10: Price10 | 'OVER' | 'UNDER', side: ItaRegisteredOrderType) {
    const qty = new Share(0);
    const orders: Array<FIXOrder> = [];
    const view = stockOperator.current;
    const isBuy = side === ItaRegisteredOrderType.BidClose || side === ItaRegisteredOrderType.Bid;
    const isOnClose = side === ItaRegisteredOrderType.BidClose || side === ItaRegisteredOrderType.AskClose;
    for (const ordKey of Object.keys(this.fix.portfolio.ordersDic)) {
      const ord = this.fix.portfolio.ordersDic[ordKey];
      if (ord.condition === Condition.VWAP || ord.condition === Condition.Iceberg) {
        continue;
      }
      const isOrdBuy = ord.side === Side.Buy;
      const isOrdOnClose = ord.condition === Condition.OnClose;
      if (
        ord.issueCode === stockOperator.current.master.issueCode &&
        isOrdBuy === isBuy &&
        isOrdOnClose === isOnClose &&
        ord.workingQuantity.gt(new Share(0))
      ) {
        if (p10 instanceof Price10 && ord.workingPrice10.eq(p10)) {
          qty.add(ord.workingQuantity);
          orders.push(ord);
        } else if (p10 === 'OVER' && ord.workingPrice10.gt(new Price10(view.rows[view.rows.length - 1].price10))) {
          qty.add(ord.workingQuantity);
          orders.push(ord);
        } else if (p10 === 'UNDER' && ord.workingPrice10.lt(new Price10(view.rows[0].price10))) {
          qty.add(ord.workingQuantity);
          orders.push(ord);
        }
      }
    }
    console.log(orders);
    if (orders.length > 0) {
      this.amending.issueCode = stockOperator.current.master.issueCode;
      this.amending.visible = false;
      this.amending.orders = orders;
      if (p10 instanceof Price10) {
        this.amending.srcPrice10 = this.amending.dstPrice10 = p10;
        this.amending.srcOverUnder = null;
      } else {
        this.amending.srcPrice10 = this.amending.dstPrice10 = null;
        this.amending.srcOverUnder = p10;
      }
      this.amending.srcOrderType = this.amending.dstOrderType = side;
    } else {
      this.amending.issueCode = stockOperator.current.master.issueCode;
      this.amending.visible = false;
      this.amending.orders = [];
      this.amending.srcPrice10 = this.amending.dstPrice10 = null;
      this.amending.srcOverUnder = null;
      this.amending.srcOrderType = this.amending.dstOrderType = null;
    }
    this.cacheClear();
  }

  public updateAmending(stockOperator: StockWrapper, p10: Price10, side: ItaRegisteredOrderType) {
    if (this.isAmending(stockOperator)) {
      if (
        (this.amending.srcPrice10 === null || this.amending.srcPrice10.ne(p10) || this.amending.srcOrderType !== side) &&
        this.isBuy(this.amending.srcOrderType) === this.isBuy(side)
      ) {
        this.amending.visible = true;
        this.amending.dstPrice10 = p10;
        this.amending.dstOrderType = side;
      } else {
        this.amending.visible = false;
      }
      this.cacheClear();
      this.clearRegisteredOrder(stockOperator); // ?
      stockOperator.update = true;
    }
  }

  public invisibleAmending(stockOperator: StockWrapper) {
    if (this.amending.issueCode === stockOperator.current.master.issueCode) {
      this.cacheClear();
      this.amending.visible = false;
      stockOperator.update = true;
    }
  }

  private isBuy(type: ItaRegisteredOrderType) {
    return type === ItaRegisteredOrderType.Bid || type === ItaRegisteredOrderType.BidClose;
  }

  private isOnClose(type: ItaRegisteredOrderType) {
    return type === ItaRegisteredOrderType.AskClose || type === ItaRegisteredOrderType.BidClose;
  }

  public runAmending(stockWrapper: StockWrapper, p10: Price10, type: ItaRegisteredOrderType) {
    const view = stockWrapper.current;
    if (this.amending.issueCode === stockWrapper.current.master.issueCode) {
      const isOnClose = type === ItaRegisteredOrderType.BidClose || type === ItaRegisteredOrderType.AskClose;
      const fill =
        (type === ItaRegisteredOrderType.Bid && view.askPrice10 !== 0 && (p10.isMarket || new Price10(view.askPrice10).le(p10))) ||
        (type === ItaRegisteredOrderType.Ask && view.bidPrice10 !== 0 && (p10.isMarket || new Price10(view.bidPrice10).ge(p10)));
      for (const ord of this.amending.orders) {
        const isOrdOnClose = ord.condition === Condition.OnClose;
        const condition = isOrdOnClose ? Condition.Zaraba : Condition.OnClose;
        if (ord.workingQuantity.gt(new Share(0))) {
          this.fix.sendAmend(ord, p10, ord.workingQuantity, isOrdOnClose !== isOnClose ? condition : ord.condition, fill);
        }
      }
      stockWrapper.update = true;
    }
  }

  public tryCancel(stockWrapper: StockWrapper, p10: Price10 | 'OVER' | 'UNDER', type: ItaRegisteredOrderType) {
    const rows = stockWrapper.current.rows;
    if (this.itaRegisteredOrder && this.itaRegisteredOrder.issueCode === stockWrapper.current.master.issueCode) {
      this.clearRegisteredOrder(stockWrapper);
      this.error.alert('板登録中には取消できません');
      return;
    }
    if (this.isAmending(stockWrapper)) {
      this.clearAmending(stockWrapper);
      this.error.alert('訂正中には取消できません。訂正を中断します。');
      return;
    }
    const isBuy = this.isBuy(type);
    const isOnClose = this.isOnClose(type);
    for (const ord of Object.values(this.fix.portfolio.ordersDic)) {
      if (ord.condition === Condition.VWAP) {
        continue;
      }
      const isOrdBuy = ord.side === Side.Buy;
      const isOrdOnClose = ord.condition === Condition.OnClose;
      if (
        ord.issueCode === stockWrapper.current.master.issueCode &&
        ord.workingQuantity.gt(new Share(0)) &&
        isOrdBuy === isBuy &&
        isOrdOnClose === isOnClose
      ) {
        if (
          (p10 instanceof Price10 && ord.workingPrice10.eq(p10)) ||
          (p10 === 'OVER' && ord.workingPrice10.gt(new Price10(rows[rows.length - 1].price10))) ||
          (p10 === 'UNDER' && ord.workingPrice10.lt(new Price10(rows[0].price10)))
        ) {
          this.fix.sendCancel(ord);
        }
      }
    }
    stockWrapper.update = true;
    this.clearRegisteredOrder(stockWrapper);
  }

  price10Click(price10: number) {
    this.price10ClickedSubject.next(price10);
  }
}
