<ngb-toast
  *ngFor="let toast of toasts$ | async"
  [class]="'c-toast ' + toast.cssClass"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="_onHide(toast)"
  (click)="_onHide(toast)"
>
  <div class="c-toast__wrapper">
    <div class="c-toast__icon">
      <fa-icon *ngIf="toast.icon" [icon]="toast.icon"></fa-icon>
    </div>
    <div class="c-toast__message">
      <ng-container *ngFor="let message of toast.messages; let i = index">
        <ng-container *ngIf="i > 0"><br /></ng-container>
        {{ message }}
      </ng-container>
    </div>
  </div>
</ngb-toast>
