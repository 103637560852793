import { ObservableArray } from '@grapecity/wijmo';
import { Portfolio as FlexPortfolio, QRRowType, QuoteFlag, Side, StockInfo, StockMaster } from '@argentumcode/brisk-common';
import { differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import { environment } from '../../environments/default/environment';

const OHLC_LENGTH = environment.flexConfig.ohlcLength;

export interface Time {
  getTime(): Date;
}

export abstract class Portfolio {
  abstract get index(): number;

  abstract get name(): string;

  abstract get lastPriceBasePriceChange(): number;

  abstract get vwapPriceChange(): number;

  abstract get priceBasePriceChanges(): ObservableArray;

  abstract get volume(): number;

  abstract get turnover10(): number;

  abstract get predict(): boolean;

  abstract get turnoverPredict(): boolean;

  // Sortとフィルタのための疑似的なカラム
  get lastPriceBasePriceChange100(): number {
    if (this.lastPriceBasePriceChange === undefined || this.lastPriceBasePriceChange === null) {
      return this.lastPriceBasePriceChange;
    }
    return this.lastPriceBasePriceChange * 100;
  }

  // Sortとフィルタのための疑似的なカラム
  get vwapPriceChange100(): number {
    if (this.vwapPriceChange === undefined || this.vwapPriceChange === null) {
      return this.vwapPriceChange;
    }
    return this.vwapPriceChange * 100;
  }
}

export class NewPortfolioEntry extends Portfolio {
  index = null;
  name = null;
  lastPriceBasePriceChange = null;
  vwapPriceChange = null;
  priceBasePriceChanges = null;
  openPriceChange = null;
  volume = null;
  turnover10 = null;
  issueCode = null;
  predict = false;
  turnoverPredict = false;
}

export class StockPortfolio extends Portfolio {
  private _lastPriceChanges: ObservableArray = null;

  public constructor(
    public master: StockMaster,
    public portfolio: FlexPortfolio,
    public info: StockInfo,
    public time: Time,
    public index: number
  ) {
    super();
  }

  get issueCode(): string {
    return this.master.issueCode.toString();
  }

  set issueCode(_) {}

  get prefix(): string {
    return this.master.prefix;
  }

  set prefix(_) {}

  get price10(): number {
    if (this.portfolio.lastPrice10 === 0) {
      return null;
    }
    return this.portfolio.lastPrice10;
  }

  set price10(_) {}

  // Sortとフィルタのための疑似的なカラム
  get price(): number {
    if (this.price10 === null || this.price10 === undefined) {
      return this.price10;
    }
    return this.price10 / 10;
  }

  set price(_) {}

  get openPrice10(): number {
    if (this.portfolio.openPrice10) {
      return this.portfolio.openPrice10;
    }
    return this.portfolio.predictPrice10;
  }

  set openPrice10(_) {}

  get openPrice10BasePriceChanges100(): number {
    if (this.openPrice10BasePriceChanges === null || this.openPrice10BasePriceChanges === undefined) {
      return this.openPrice10BasePriceChanges;
    }
    return this.openPrice10BasePriceChanges * 100;
  }

  set openPrice10BasePriceChanges100(_) {}

  get openPrice10BasePriceChanges(): number {
    if (this.openPrice10) {
      return this.openPrice10 / this.master.basePrice10 - 1;
    }
    return null;
  }

  set openPrice10BasePriceChanges(_) {}

  get openPrice10Predict(): boolean {
    return !this.portfolio.openPrice10 && !!this.portfolio.predictPrice10;
  }

  set openPrice10Predict(_) {}

  get hitLift(): string {
    switch (this.portfolio.hitType) {
      case QRRowType.Bid:
        return 'HIT';
      case QRRowType.Ask:
        return 'LIFT';
      default:
        return '';
    }
  }

  set hitLift(_) {}

  get hitLiftOpacity(): number {
    if (
      this.time == null ||
      this.portfolio.hitClose ||
      (this.portfolio.hitType !== QRRowType.Ask && this.portfolio.hitType !== QRRowType.Bid)
    ) {
      return 1;
    } else {
      return 1 - differenceInSeconds(this.time.getTime(), this.portfolio.hitDateTime) / 30.0;
    }
  }

  set hitLiftOpacity(_) {}

  get vwapPriceChange(): number {
    if (this.portfolio.lastPrice10 === 0 || this.portfolio.turnover10 === 0 || this.portfolio.openPrice10 === 0) {
      return null;
    }
    return this.portfolio.lastPrice10 / (this.portfolio.turnover10 / this.portfolio.volume) - 1;
  }

  set vwapPriceChange(_) {}

  get openPriceChange100(): number {
    if (this.openPriceChange === undefined || this.openPriceChange === null) {
      return this.openPriceChange;
    }
    return this.openPriceChange * 100;
  }

  set openPriceChange100(_) {}

  get openPriceChange(): number {
    if (this.portfolio.lastPrice10 === 0 || this.portfolio.openPrice10 === 0) {
      return null;
    }
    return this.portfolio.lastPrice10 / this.portfolio.openPrice10 - 1;
  }

  set openPriceChange(_) {}

  get lastPriceBasePriceChange(): number {
    if (this.portfolio.lastPrice10 === 0) {
      return null;
    }
    return this.portfolio.lastPrice10 / this.master.basePrice10 - 1;
  }

  set lastPriceBasePriceChange(_) {}

  get name(): string {
    return this.master.name;
  }

  set name(_) {}

  get volume(): number {
    if (!this.portfolio.volume) {
      return null;
    }
    return this.portfolio.volume;
  }

  set volume(_) {}

  get turnoverRate100(): number {
    if (this.turnoverRate === null || this.turnoverRate === undefined) {
      return this.turnoverRate;
    } else {
      return this.turnoverRate * 100;
    }
  }

  set turnoverRate100(_) {}

  get turnoverRate(): number {
    if (!this.info.lastDayTurnover || !this.portfolio.turnover10) {
      return null;
    }
    return this.portfolio.turnover10 / 10 / this.info.lastDayTurnover;
  }

  set turnoverRate(_) {}

  get lastDayTurnover(): number {
    if (!this.info.lastDayTurnover) {
      return null;
    }
    return this.info.lastDayTurnover;
  }

  set lastDayTurnover(_) {}

  get turnover10(): number {
    if (!this.portfolio.turnover10) {
      return null;
    }
    return this.portfolio.turnover10 / 1000000000;
  }

  set turnover10(_) {}

  get priceBasePriceChanges(): ObservableArray {
    if (this._lastPriceChanges === null) {
      this._lastPriceChanges = new ObservableArray(
        this.portfolio.lastPrices10.map((lastPrice10) => (lastPrice10 !== 0 ? lastPrice10 / this.master.basePrice10 - 1 : null))
      );
    } else {
      this._lastPriceChanges.deferUpdate(() => {
        for (let x = 0; x < OHLC_LENGTH; x++) {
          this._lastPriceChanges[x] =
            this.portfolio.lastPrices10[x] !== 0 ? this.portfolio.lastPrices10[x] / this.master.basePrice10 - 1 : null;
        }
      });
    }
    return this._lastPriceChanges;
  }

  set priceBasePriceChanges(_) {}

  get predict() {
    return this.portfolio.predict;
  }

  set predict(_) {}

  get industryName(): string {
    return this.master.industryName;
  }

  set industryName(_) {}

  get specialQuote(): string {
    if (this.portfolio.quoteFlag !== QuoteFlag.SQ && this.portfolio.quoteFlag !== QuoteFlag.CEQ) {
      return '';
    }
    if (this.portfolio.quoteFlag === QuoteFlag.CEQ) {
      return 'C';
    }
    const sideStr = this.portfolio.quoteSide === Side.Ask ? 'ウ' : 'カ';
    if (!this.time || !this.portfolio.specialQuoteTime) {
      return sideStr + '';
    }
    const sec = Math.ceil(differenceInMilliseconds(this.portfolio.specialQuoteDateTime, this.time.getTime()) / 1000);
    if (sec < 10 && sec >= 0) {
      return sideStr + sec.toString();
    } else {
      return sideStr + '';
    }
  }

  set specialQuote(_) {}

  get specialQuoteStr(): string {
    if (this.portfolio.quoteFlag !== QuoteFlag.SQ && this.portfolio.quoteFlag !== QuoteFlag.CEQ) {
      return '';
    }
    if (this.portfolio.quoteFlag === QuoteFlag.CEQ) {
      return 'C';
    }
    const sideStr = this.portfolio.quoteSide === Side.Ask ? 'ウ' : 'カ';
    return sideStr;
  }

  set specialQuoteStr(_) {}

  get specialQuoteSide(): number {
    return this.portfolio.quoteSide;
  }

  set specialQuoteSide(_) {}

  get specialQuoteSort(): string {
    if (this.portfolio.quoteFlag !== QuoteFlag.SQ && this.portfolio.quoteFlag !== QuoteFlag.CEQ) {
      return null;
    }
    if (this.portfolio.quoteFlag === QuoteFlag.CEQ) {
      return 'C';
    }
    return this.portfolio.quoteSide === Side.Ask ? 'ウ' : 'カ';
  }

  set specialQuoteSort(_) {}

  get marketCapitalizationFilterValue(): number {
    if (this.marketCapitalization === null || this.marketCapitalization === undefined) {
      return this.marketCapitalization;
    } else {
      return this.marketCapitalization / 1e8;
    }
  }

  set marketCapitalizationFilterValue(_) {}

  get marketCapitalization(): number {
    if (!this.info.calcSharesOutstanding) {
      return null;
    }
    return (this.info.calcSharesOutstanding * this.master.basePrice10) / 10;
  }

  set marketCapitalization(_) {}

  get quoteFlag(): QuoteFlag {
    return this.portfolio.quoteFlag;
  }

  set quoteFlag(_) {}

  get turnoverPredict(): boolean {
    return this.openPrice10Predict;
  }

  set turnoverPredict(_) {}

  get vwapPredict(): boolean {
    return this.predict;
  }

  set vwapPredict(_) {}

  // 上昇率のソート用
  get rateOfIncrease() {
    if (this.vwapPriceChange !== null && this.vwapPriceChange !== undefined) {
      return this.lastPriceBasePriceChange100;
    } else {
      return this.openPrice10BasePriceChanges100;
    }
  }

  set rateOfIncrease(_) {}

  // QR回数のスマートリスト用
  get qrCount() {
    if (this.portfolio.qrCount) {
      return this.portfolio.qrCount;
    } else {
      return null;
    }
  }

  set qrCount(_) {}

  get highPriceBasePriceChange() {
    if (!this.portfolio.highPrice10) {
      return undefined;
    }
    return this.portfolio.highPrice10 / this.master.basePrice10 - 1;
  }

  set highPriceBasePriceChange(_) {}

  get lowPriceBasePriceChange() {
    if (!this.portfolio.lowPrice10) {
      return undefined;
    }
    return this.portfolio.lowPrice10 / this.master.basePrice10 - 1;
  }

  set lowPriceBasePriceChange(_) {}

  get highPriceBasePriceChange100(): number {
    if (this.highPriceBasePriceChange === undefined) {
      return this.highPriceBasePriceChange;
    } else {
      return this.highPriceBasePriceChange * 100;
    }
  }

  get lowPriceBasePriceChange100(): number {
    if (this.lowPriceBasePriceChange === undefined) {
      return this.lowPriceBasePriceChange;
    } else {
      return this.lowPriceBasePriceChange * 100;
    }
  }
}
