<wj-popup
  id="dialog"
  #popup
  dialog="fatalErrorDialog"
  [modal]="true"
  (keydown)="$event.preventDefault()"
  [showTrigger]="'None'"
  [hideTrigger]="'None'"
  (hiding)="hiding($event)"
>
  <div id="modal-body">
    <div id="error-dialog-contents-wrapper">
      <div id="error-code">メッセージ番号：{{ errorCode }}</div>
      <div id="error-message-wrapper">
        <div id="error-message">
          {{ errorMessage }}
          <ng-container *ngIf="errorCode === '20001'">
            <br />
            エラーが起動時に繰り返し表示される場合は、「レイアウトを初期化する」ボタンをクリックしてください。
          </ng-container>
        </div>
      </div>
      <div id="error-direction-wrapper">
        <button class="c-btn c-btn--default reload-btn" (click)="reloadWithResetLayout()" *ngIf="errorCode === '20001'">
          レイアウトを初期化する
        </button>
        <button class="c-btn c-btn--default reload-btn" (click)="reload()" *ngIf="showRestart">
          <fa-icon [icon]="['fas', 'redo-alt']"></fa-icon> 再読み込み
        </button>
      </div>
    </div>
  </div>
</wj-popup>
