import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  StaticProvider,
  ViewChild,
} from '@angular/core';
import {
  GlOnHide,
  GlOnResize,
  GlOnShow,
  GlOnTab,
  GoldenLayoutComponent,
  GoldenLayoutContainer,
  GoldenLayoutModule,
  IExtendedGoldenLayoutConfig,
} from '@argentumcode/ngx-golden-layout';
import { ItaPanelComponent } from '../ita-panel/ita-panel.component';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import { QrPanelComponent } from '../qr-panel/qr-panel.component';
import {
  BriskDialogService,
  InvalidIssueCodeEvent,
  ResizeService,
  StockOrderSummaryForHeader,
  StockWrapper,
} from '@argentumcode/brisk-common';
import { Ohlc5minPanelComponent } from '../ohlc5min-panel/ohlc5min-panel.component';
import { StockDetailService } from '../../stock-detail.service';
import { JsfcPanelComponent } from '../jsfc-panel/jsfc-panel.component';
import { ChartPanelComponent } from '../chart-panel/chart-panel.component';
import uuidv4 from 'uuid/v4';
import { StockDetailsService } from '../../stock-details.service';
import { Container, Tab } from 'golden-layout';
import { FontSizeService } from '../../font-size.service';
import { environment } from '../../../environments/default/environment';
import { environment as fita3Env } from 'fita3/src/environments/default/environment';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from '../../order/types/interfaces';
import { ExternalWebsitePanelComponent } from '../external-website-panel/external-website-panel.component';
import { commonDimensionParameters, createNewComponentKeepOriginalTab } from '../../golden-layout-utils';
import { PersistentStateService } from '../../fita4-core/persistent-state.service';
import {
  STOCK_DETAIL_STACK_HEADER,
  StockDetailStackHeaderComponent,
} from '../../headers/stock-detail-stack-header/stock-detail-stack-header.component';

const INITIAL_LAYOUT: IExtendedGoldenLayoutConfig = {
  settings: {
    showPopoutIcon: false,
    showMaximiseIcon: false,
    showCloseIcon: false,
  },
  content: [
    {
      type: 'row',
      content: [
        {
          type: 'stack',
          width: 75.0,
          content: [
            {
              type: 'component',
              componentName: 'Ita', // The name defined in componentTypes
              title: '板',
              isClosable: false,
              id: 'ita',
            },
            {
              type: 'component',
              componentName: 'Chart', // The name defined in componentTypes
              title: 'チャート',
              isClosable: false,
              id: 'chart',
            },
            {
              type: 'component',
              componentName: 'ExternalWebsite', // The name defined in componentTypes
              title: 'Kabutan(外部)',
              isClosable: false,
              id: 'externalKabutan',
              componentState: {
                urlTemplate: 'https://kabutan.jp/stock/?code={issueCode}',
              },
            },
            {
              type: 'component',
              componentName: 'ExternalWebsite',
              title: 'BC(外部)',
              isClosable: false,
              id: 'externalBuffetCode',
              componentState: {
                urlTemplate: 'https://www.buffett-code.com/company/{issueCode}/',
              },
            },
          ],
        },
        {
          type: 'stack',
          content: [
            {
              type: 'component',
              componentName: 'Qr', // The name defined in componentTypes
              title: '歩み値',
              isClosable: false,
              id: 'qr',
            },
            {
              type: 'component',
              componentName: 'Ohlc5min', // The name defined in componentTypes
              title: '5分足',
              isClosable: false,
              id: 'ohlc5min',
            },
            // {
            //   type: 'component',
            //   componentName: 'NewOrder', // The name defined in componentTypes
            //   title: '発注',
            //   isClosable: false,
            //   id: 'newOrder',
            // },
            {
              type: 'component',
              componentName: 'OrderList', // The name defined in componentTypes
              title: '注文一覧',
              isClosable: false,
              id: 'orderList',
            },
            {
              type: 'component',
              componentName: 'PositionList', // The name defined in componentTypes
              title: '信用建玉',
              isClosable: false,
              id: 'positionList',
            },
          ],
        },
      ],
    },
  ],
};

interface StockDetailState {
  uniqId?: string;
  syncWithCocomero?: boolean;
  issueCode?: number;
  config?: IExtendedGoldenLayoutConfig;
  orderState?: any;
  layoutVersion?: 1;
}

@Component({
  selector: 'app-fita4-stock-detail-panel',
  templateUrl: './stock-detail-panel.component.html',
  styleUrls: ['./stock-detail-panel.component.scss'],
  providers: [
    StockDetailService,
    GoldenLayoutModule.forChild([
      {
        type: ItaPanelComponent,
        name: 'Ita',
      },
      {
        type: QrPanelComponent,
        name: 'Qr',
      },
      {
        type: Ohlc5minPanelComponent,
        name: 'Ohlc5min',
      },
      {
        type: JsfcPanelComponent,
        name: 'Jsfc',
      },
      {
        type: ChartPanelComponent,
        name: 'Chart',
      },
      {
        type: ExternalWebsitePanelComponent,
        name: 'ExternalWebsite',
      },
      ...environment.stockDetailPanels,
    ]),
    ...environment.stockDetailProviders,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockDetailPanelComponent implements OnInit, AfterViewInit, GlOnResize, GlOnShow, GlOnTab, GlOnHide, OnDestroy {
  private isOnPrimaryStack$ = new BehaviorSubject(false);
  private showNewFullItaTabIconTutorial$ = new BehaviorSubject<boolean>(false);

  private scrollTutorialsShown = false;
  private tutorialShown = true;

  private detachTimeout?: number | null;

  headerComponent = StockDetailStackHeaderComponent;
  additionalTokens: Array<StaticProvider> = [
    {
      provide: STOCK_DETAIL_STACK_HEADER,
      useValue: {
        click: () => {
          if (this.state) {
            const newState: StockDetailState = {
              ...this.state,
              syncWithCocomero: false,
              uniqId: undefined,
            };
            this.container.parent.parent.addChild({
              type: 'component',
              componentName: 'StockDetail', // The name defined in componentTypes
              title: '銘柄詳細',
              componentState: newState,
            });
            this.persistentStateService.updateFirstTutorialNewFullItaTabButton(true);
          }
        },
        showTutorial$: this.showNewFullItaTabIconTutorial$,
        onTutorialClose: () => this.persistentStateService.updateFirstTutorialNewFullItaTabButton(true),
      },
    },
  ];
  headerAlignment = 'left' as const;

  @ViewChild('root', { static: true }) layout: GoldenLayoutComponent;
  layout$ = new BehaviorSubject(INITIAL_LAYOUT);
  state?: StockDetailState;
  initialized = false;
  highlightTimerSubscription: Subscription;
  componentSubscription = new Subscription();
  private useDefaultLayout = false;
  get time() {
    return this.stockDetailService.time;
  }

  get stockOperator() {
    return this.stockDetailService.stockOperator;
  }

  private _orderSummaryCache = new WeakMap<StockWrapper, Observable<StockOrderSummaryForHeader>>();
  get orderSummary$(): Observable<StockOrderSummaryForHeader> {
    if (this.stockDetailService.stockOperator) {
      const issueCode = this.stockDetailService.stockOperator.base.master.issueCode;
      const obs = this._orderSummaryCache.get(this.stockDetailService.stockOperator);
      if (obs) {
        return obs;
      } else {
        const ob = this.orderOperator.getStockInfo(issueCode);
        this._orderSummaryCache.set(this.stockDetailService.stockOperator, ob);
        return ob;
      }
    } else {
      return of({});
    }
  }

  constructor(
    private resize: ResizeService,
    private stockDetailService: StockDetailService,
    private element: ElementRef,
    private stockDetails: StockDetailsService,
    private dialog: BriskDialogService,
    private _fontSize: FontSizeService,
    private changeDetectionRef: ChangeDetectorRef,
    private persistentStateService: PersistentStateService,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.componentSubscription.add(
      this.resize.resize.subscribe(() => {
        this.onResize();
      })
    );
    this.onResize();

    this.componentSubscription.add(
      this.persistentStateService.firstTutorialNewFullItaTabIcon$.subscribe((v) => {
        this.tutorialShown = v;
        this.showNewFullItaTabIconTutorial$.next(!v && this.isOnPrimaryStack$.getValue() && this.scrollTutorialsShown);
      })
    );

    this.componentSubscription.add(
      this.persistentStateService.firstTutorialScrollTutorials$.subscribe((v) => {
        this.scrollTutorialsShown = v;
        this.showNewFullItaTabIconTutorial$.next(v && !this.tutorialShown && this.isOnPrimaryStack$.getValue());
      })
    );
  }

  ngAfterViewInit(): void {
    // patch to golden layout
    const orig = this.layout.getGoldenLayoutInstance().updateSize.bind(this.layout.getGoldenLayoutInstance());
    this.layout.getGoldenLayoutInstance().updateSize = (...args) => {
      if (this.container.isHidden) {
        return;
      }
      orig(...args);
    };

    this.componentSubscription.add(
      this._fontSize.fontSize$.subscribe(() => {
        if (this.layout) {
          this.layout.getGoldenLayoutInstance().config.dimensions = {
            ...this.layout.getGoldenLayoutInstance().config.dimensions,
            ...commonDimensionParameters,
            headerHeight: this._fontSize.glHeaderSize(),
          };
        }
      })
    );

    if (this.useDefaultLayout) {
      if (!this.orderOperator.config.orderEnabled) {
        this.layout.closeComponent('newOrder');
        this.layout.closeComponent('orderList');
        this.layout.closeComponent('positionList');
      }
    }
    if (this.layout.getGoldenLayoutInstance().root.getItemsById('newOrder').length !== 0) {
      // 新規発注タブはひとまず存在しないものとする
      this.layout.closeComponent('newOrder');
    }
    if (this.orderOperator.config.orderEnabled) {
      if (this.layout.getGoldenLayoutInstance().root.getItemsById('orderList').length === 0) {
        // this.layout.createNewComponent(
        //   {
        //     type: 'component',
        //     componentName: 'NewOrder', // The name defined in componentTypes
        //     title: '発注',
        //     isClosable: false,
        //     id: 'newOrder',
        //   },
        //   'qr'
        // );
        createNewComponentKeepOriginalTab(
          this.layout.getGoldenLayoutInstance(),
          {
            type: 'component',
            componentName: 'OrderList', // The name defined in componentTypes
            title: '注文一覧',
            isClosable: false,
            id: 'orderList',
          },
          'qr'
        );
      }
      if (this.layout.getGoldenLayoutInstance().root.getItemsById('positionList').length === 0) {
        createNewComponentKeepOriginalTab(
          this.layout.getGoldenLayoutInstance(),
          {
            type: 'component',
            componentName: 'PositionList', // The name defined in componentTypes
            title: '信用建玉',
            isClosable: false,
            id: 'positionList',
          },
          'qr'
        );
      }
    } else {
      this.layout.closeComponent('newOrder');
      this.layout.closeComponent('orderList');
      this.layout.closeComponent('positionList');
    }
    const kabutan = this.layout.getGoldenLayoutInstance().root.getItemsById('externalKabutan');
    if (environment.showKabutan) {
      if (kabutan.length === 0) {
        createNewComponentKeepOriginalTab(
          this.layout.getGoldenLayoutInstance(),
          {
            type: 'component',
            componentName: 'ExternalWebsite', // The name defined in componentTypes
            title: 'Kabutan(外部)',
            isClosable: false,
            id: 'externalKabutan',
            componentState: {
              urlTemplate: 'https://kabutan.jp/stock/?code={issueCode}',
            },
          },
          'ita'
        );
      }
    } else {
      if (kabutan.length > 0) {
        kabutan[0].remove();
      }
    }
    const buffetCode = this.layout.getGoldenLayoutInstance().root.getItemsById('externalBuffetCode');
    if (environment.showBuffetCode) {
      if (buffetCode.length === 0) {
        createNewComponentKeepOriginalTab(
          this.layout.getGoldenLayoutInstance(),
          {
            type: 'component',
            componentName: 'ExternalWebsite',
            title: 'BC(外部)',
            isClosable: false,
            id: 'externalBuffetCode',
            componentState: {
              urlTemplate: 'https://www.buffett-code.com/company/{issueCode}/',
            },
          },
          'ita'
        );
      }
    } else {
      if (buffetCode.length > 0) {
        buffetCode[0].remove();
      }
    }
    if (!this.stockDetailService.visible) {
      this.changeDetectionRef.detach();
    }

    this.updateIsOnPrimaryStack();
  }

  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.stockDetailService.visible = !this.container.isHidden;
    // init state
    this.state = this.container.getState() || {};
    let stateUpdated = false;
    if (this.state.config && this.state.layoutVersion === 1) {
      this.state.config.dimensions = {
        ...this.state.config.dimensions,
        ...commonDimensionParameters,
        headerHeight: this._fontSize.glHeaderSize(),
      };
      this.layout$.next(this.state.config);
    } else {
      this.useDefaultLayout = true;
    }
    this.state.layoutVersion = 1;
    if (!this.state.uniqId) {
      stateUpdated = true;
      // Actually, golden layout has unique id, but how to access it?
      this.state.uniqId = uuidv4();
    }
    if (this.state.syncWithCocomero) {
      this.state.issueCode = this.stockDetailService.syncWithCocomero();
    } else if (!this.state.issueCode) {
      this.state.issueCode = fita3Env.flexConfig.fallbackIssueCode;
    }
    if (this.state.orderState) {
      this.stockDetailService.setInitialOrderState(this.state.orderState);
    } else {
      this.stockDetailService.setInitialOrderState(undefined);
    }
    if (stateUpdated) {
      this.container.setState(this.state);
    }
    this.componentSubscription.add(
      this.stockDetailService.orderStateUpdated.subscribe((newOrderState) => {
        this.state.orderState = newOrderState;
        this.container.setState(this.state);
      })
    );

    this.componentSubscription.add(
      this.stockDetailService.issueCodeChanged.subscribe((issueCode) => {
        this.state.issueCode = issueCode;
        this.container.setState(this.state);
        this.container.setTitle(`銘柄詳細(${this.stockOperator.current.master.name})`);
        if (this.initialized) {
          const e = this.container.tab.element;
          e.addClass('app-fita4-tab-highlight');
          e.removeClass('app-fita4-tab-highlight-animation');

          if (this.highlightTimerSubscription) {
            this.highlightTimerSubscription.unsubscribe();
            this.highlightTimerSubscription = null;
          }
          this.highlightTimerSubscription = timer(1000).subscribe(() => {
            e.removeClass('app-fita4-tab-highlight-animation');
          });
          this.highlightTimerSubscription.add(
            timer().subscribe(() => {
              e.removeClass('app-fita4-tab-highlight');
              e.addClass('app-fita4-tab-highlight-animation');
            })
          );
        }
      })
    );
    this.stockDetailService.changeIssueCode(this.state.issueCode, false);
    this.componentSubscription.add(
      this.stockDetails.issueCodeChange$.subscribe(([id, issueCode]) => {
        if (id === this.state.uniqId) {
          this.stockDetailService.changeIssueCode(issueCode);
        }
      })
    );
    this.resize.dispatchResize();
    this.glOnTab(this.container.tab);
    this.initialized = true;
  }

  onInvalidInput(e: InvalidIssueCodeEvent) {
    if (!e.text) {
      return;
    }
    e.cancel = true;
    e.callback = this.dialog.openDialog(
      {
        content: `銘柄「${e.text}」が見つかりません。`,
        buttons: [
          {
            text: 'OK',
          },
        ],
      },
      { width: '30em' }
    );

    this.updateIsOnPrimaryStack();
  }

  onStockSelected(issueCode: number) {
    this.stockDetailService.changeIssueCode(issueCode);
  }

  glOnResize(): void {
    this.updateIsOnPrimaryStack();

    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  glOnShow() {
    if (this.detachTimeout) {
      clearTimeout(this.detachTimeout);
    }
    this.changeDetectionRef.reattach();
    this.resize.dispatchResize();
    setTimeout(() => {
      this.resize.dispatchResize();
    });
    this.updateIsOnPrimaryStack();
    this.stockDetailService.visible = true;
  }

  glOnHide() {
    this.stockDetailService.visible = false;
    this.updateIsOnPrimaryStack();
    this.detachTimeout = window.setTimeout(() => this.changeDetectionRef.detach(), 3000);
  }

  setActiveTabState(tab: Tab) {
    if (this.state.syncWithCocomero) {
      tab.element.addClass('app-fita4-active');
    } else {
      tab.element.removeClass('app-fita4-active');
    }
  }

  glOnTab(tab: Tab) {
    if (this.state) {
      this.setActiveTabState(tab);
    }
  }

  onResize() {
    const width = this.element.nativeElement.parentElement?.offsetWidth;
    const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;

    if (width <= 545) {
      // 3行表示
    } else if (width <= 1020) {
      // 2行表示
    } else if (width >= 1540) {
      // 1行表示可能
    }
  }
  onStateChange(root: GoldenLayoutComponent) {
    if (this.state) {
      this.state.config = root.getGoldenLayoutInstance().toConfig();
      this.container.setState(this.state);
    }
  }

  updateIsOnPrimaryStack() {
    let stackCandidate = this.container.parent.parent;

    if (stackCandidate.parent == null) {
      this.isOnPrimaryStack$.next(false);
      this.showNewFullItaTabIconTutorial$.next(false);
      this.stockDetailService.isOnPrimaryPanel = false;
      return;
    }

    const isOnPrimaryStack = (() => {
      while (stackCandidate.parent != null) {
        const parent = stackCandidate.parent;
        if (parent?.contentItems[0] !== stackCandidate) {
          return false;
        }
        stackCandidate = parent;
      }
      return true;
    })();
    this.isOnPrimaryStack$.next(isOnPrimaryStack);
    this.showNewFullItaTabIconTutorial$.next(this.scrollTutorialsShown && !this.tutorialShown && isOnPrimaryStack);
    this.stockDetailService.isOnPrimaryPanel = isOnPrimaryStack;
  }
}
