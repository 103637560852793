<div class="ita-panel--container">
  <div class="ita-panel--header" #headerElement>
    <app-fita4-ita-panel-header #itaPanelHeader></app-fita4-ita-panel-header>
    <brisk-timestamp-header
      [stockWrapper]="stockOperator"
      [showHistoryButtons]="showHistoryButtons"
      [timestampMilliseconds]="showTimestampMilliseconds"
      [peg]="state?.peg"
      (pegChange)="updatePegState($event)"
      [showCamera]="showCamera"
      (saveClick)="saveImage($event)"
    ></brisk-timestamp-header>
  </div>
  <div class="ita-panel--ita-wrapper">
    <brisk-ita
      #ita
      id="ita"
      [alignRight]="true"
      [fillStopPrice]="true"
      [forceSeparateComma]="true"
      [timeProvider]="time"
      [stockOperator]="stockOperator"
      [normalized]="state?.normalized"
      (normalizedChange)="updateNormalized($event)"
      [showMyOrder]="orderEnabled && showMyOrder"
      [showScrollbar]="true"
      [peg]="state?.peg"
      (pegChange)="updatePegState($event)"
      [shown]="shown"
      [showVolume]="true"
      [showOrder]="showOrder"
      [showClose]="showClose"
      [showTotal]="showTotal"
      [showVolumeChartOnly]="showVolumeChartOnly"
      [enableScrollTutorial]="isOnPrimaryPanel"
      [enableDblClickTutorial]="isOnPrimaryPanel"
      (priceDblClickTutorialComplete)="dblClickTutorialComplete()"
    >
    </brisk-ita>

    <brisk-ita-capture #itaCapture id="ita-capture" [normalized]="normalize" [view]="stockOperator.current"></brisk-ita-capture>
  </div>
</div>
