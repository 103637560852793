<brisk-vg-grid
  #grid
  briskVgGridFlexibleRowSize
  [updateRowSize]="false"
  [itemsSource]="dataSource"
  [showRowHeader]="false"
  [addDummyRow]="false"
  (briskResized)="onResize()"
>
  <brisk-vg-grid-column *ngFor="let columnIndex of columnIndexes" [width]="'*'" [align]="'center'" [capturePointer]="false">
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
      <app-chart-portfolio-item
        [item]="visible && item[columnIndex]"
        [updatedView]="updatedView"
        (itemClicked)="onItemClicked(item[columnIndex])"
        (itemDblClicked)="onItemDblClicked(item[columnIndex])"
      ></app-chart-portfolio-item>
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>
