import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StockView } from '../flex';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { Subscription } from 'rxjs';
import { InvalidIssueCodeEvent } from '../brisk-common/issue-code-input/issue-code-input.component';
import { UserAgentService } from '../brisk-browser/user-agent.service';
import { ResizeService } from '../resize/resize.service';

// 銘柄毎のサマリー情報
export interface StockOrderSummaryForHeader {
  // 現物保有株数
  genbutsuPosition?: number;
  // 現物売却可能数
  genbutsuSellable?: number;
  // 信用買建玉
  marginBuyPosition?: number;
  // 信用買建玉注文可能数
  marginBuyOrderable?: number;
  // 信用売建玉
  marginSellPosition?: number;
  // 信用売建玉注文可能数
  marginSellOrderable?: number;

  // 現物評価損益
  genbutuEvaluateProfitLoss?: number;
  // 信用評価損益
  marginEvaluateProfitLoss?: number;
  // 信用実現損益
  marginRealizedProfitLoss?: number;
}
interface Fita3GlHeaderState {
  fullWidth: boolean;
  miniInput: boolean;
  twoLines: boolean;
}

@Component({
  selector: 'brisk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy, OnChanges {
  private updatedSubscription: Subscription;

  // ライブ・停止を切り替え可能かどうか
  @Input()
  liveChangeable = true;

  @Input()
  get stockWrapper(): StockWrapper {
    return this._stockWrapper;
  }

  set stockWrapper(value: StockWrapper) {
    if (value === this._stockWrapper) {
      return;
    }
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }
    this._stockWrapper = value;
    if (value) {
      this.updatedSubscription = value.updated.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private _stockWrapper: StockWrapper;

  get view(): StockView {
    return this._stockWrapper ? this._stockWrapper.current : null;
  }

  get base(): StockView {
    return this._stockWrapper ? this._stockWrapper.base : null;
  }

  @Output()
  public stockSelected = new EventEmitter<number>();

  @Output()
  public invalidInput = new EventEmitter<InvalidIssueCodeEvent>();

  @Output()
  public backToNormalModeClick = new EventEmitter<{}>();

  @Output()
  public bootBriskClick = new EventEmitter<{}>();

  @Output()
  public tedinetButtonClicked = new EventEmitter<{}>();

  @Input()
  public showTimestamp = false;

  @Input()
  public liteSpMode = false;

  @Input()
  public liteSpLines = 2;

  @Input()
  disableNormalMode = false;

  @Input()
  variant?: 'fita3' | 'lita' | 'lita-sp' | 'fita4';

  @Input()
  orderSummary: StockOrderSummaryForHeader;

  @Input()
  showTedinetButton = false;

  @Input()
  showButtons = false;

  public spInputMode = false;

  public isMobile = false;

  private resizeSubscription: Subscription;

  glState: Fita3GlHeaderState;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    userAgent: UserAgentService,
    @Inject(ElementRef) private elementRef: ElementRef<HTMLElement>,
    private resize: ResizeService
  ) {
    this.isMobile = userAgent.isMobile;
    this.resizeSubscription = resize.resize.subscribe(() => {
      this.onResize();
    });
  }

  public toggleLive() {
    if (!this.liveChangeable) {
      return;
    }
    this._stockWrapper.live = !this._stockWrapper.live;
  }

  public back() {
    if (this._stockWrapper.live) {
      this._stockWrapper.live = false;
    }
    this._stockWrapper.goUpdateNumber(this._stockWrapper.current.frame - 1);
    this._stockWrapper.update = true;
  }

  public canBack() {
    if (this._stockWrapper.current.frame > 0 && this._stockWrapper.current.frame > this._stockWrapper.current.maxFrame - 3) {
      return null;
    } else {
      return true;
    }
  }

  public canNext(): boolean {
    if (this._stockWrapper.current.frame !== this._stockWrapper.current.maxFrame) {
      return null;
    } else {
      return true;
    }
  }

  public next() {
    this._stockWrapper.live = false;
    this._stockWrapper.goUpdateNumber(this._stockWrapper.current.frame + 1);
    this._stockWrapper.update = true;
  }

  public onStockSelected(issueCode: number) {
    this.stockSelected.emit(issueCode);
    this.spInputMode = false;
  }

  ngOnDestroy(): void {
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
  }

  get turnover10(): number {
    if (this.view.volume || !this.turnoverPredict) {
      return this.view.turnover10;
    } else {
      return this.view.predictVolume * this.view.predictPrice10;
    }
  }
  get volume(): number {
    if (this.view) {
      if (this.view.volume || !this.turnoverPredict) {
        return this.view.volume;
      } else {
        return this.view.predictVolume;
      }
    } else {
      return undefined;
    }
  }

  get turnoverPredict(): boolean {
    return this.view && this.view.turnoverPredict;
  }

  get showOpenPredict(): boolean {
    return this.view && this.view.lastPrice10 === 0;
  }

  get openPredict(): boolean {
    return this.view && !!this.view.predictOpenPrice10 && this.view.predictOpenPrice10 !== this.view.predictLastPrice.lastPrice10;
  }

  onHeaderClick() {
    if (this.spInputMode) {
      return;
    }
    this.spInputMode = true;
  }

  onLostFocus() {
    this.spInputMode = false;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('variant' in simpleChanges) {
      const change = simpleChanges['variant'];
      if (change.previousValue) {
        this.elementRef.nativeElement.classList.remove(change.previousValue);
      }
      if (change.currentValue) {
        this.elementRef.nativeElement.classList.add(change.currentValue);
      }
      if (this.variant === 'fita4') {
        this.onResize();
      }
    }
  }

  onResize() {
    if (this.variant !== 'fita4') {
      return;
    }
    const width = this.elementRef.nativeElement.offsetWidth;
    if (width === 0) {
      return;
    }
    const fontSize = parseInt(window.getComputedStyle(this.elementRef.nativeElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;
    const orig = this.glState?.twoLines;
    this.glState = {
      fullWidth: false,
      miniInput: true,
      twoLines: true,
    };
    if (normalizedWidth > 1100) {
      this.glState = {
        fullWidth: false,
        miniInput: true,
        twoLines: false,
      };
    }
    if (normalizedWidth > 1330) {
      this.glState = {
        miniInput: false,
        fullWidth: true,
        twoLines: false,
      };
    }
    this.changeDetectorRef.markForCheck();
    // Golden Layoutが自動でサイズ調整をしないため、resizeをdispatchする
    // TODO: resizeObserverなどで対応するべきでは?
    setTimeout(() => {
      if (orig !== this.glState.twoLines) {
        this.resize.dispatchResize();
      }
    });
  }
}
