<wj-popup
  #csvExportDialog
  id="csvExportDialog"
  [modal]="true"
  [hideTrigger]="'None'"
  id="dialog"
  (gotFocus)="listBox.focus()"
  [fadeIn]="false"
  [fadeOut]="false"
>
  <div class="modal-header">
    <h5 class="modal-title">CSVエクスポート</h5>
    <span class="close wj-hide" data-dismiss="modal" aria-label="Close" (click)="showIndexDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <wj-list-box
      #listBox
      id="listBox"
      (checkedItemsChanged)="onCheckedItemChanged()"
      [itemsSource]="summaries"
      displayMemberPath="name"
      selectedValuePath="summaryId"
      [maxHeight]="300"
      [checkedMemberPath]="'checked'"
    >
    </wj-list-box>
  </div>
  <div class="modal-footer">
    <button #okBtn class="btn btn-primary" [disabled]="listBox.checkedItems.length === 0" (click)="export(showIndexDialog, listBox)">
      エクスポート
    </button>
    <button #cancelBtn class="btn btn-light wj-hide">キャンセル</button>
  </div>
</wj-popup>
