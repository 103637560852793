import { QRRowType } from './qr-row';
import { Side } from './stock-view';

export class Portfolio {
  // From C++
  issueCode: number;
  lastPrice10: number;
  quotePrice10: number;
  predictPrice10: number;
  openPrice10: number;
  lastPrices10: number[];
  volume: number;
  turnover10: number;
  predict: boolean;
  hitType: QRRowType;
  hitTime: number;
  specialQuote: number;
  specialQuoteSide: Side;
  specialQuoteTime: number;
  issueStatus: number;
  quoteFlag: number;
  quoteSide: number;
  qrCount: number;

  bidPrice10: number;
  askPrice10: number;

  highPrice10: number;
  lowPrice10: number;

  // From JS
  hitDateTime: Date;
  hitDateTimeCache: number;
  hitClose: boolean;
  specialQuoteDateTime: Date;
  specialQuoteDateTimeCache: IssueStatus;
  // 最後の約定値段(lastPrice10は予測考慮のもののため)
  lastQRPrice10: number;

  constructor(ohlcLength: number) {
    this.lastPrices10 = new Array<number>(ohlcLength);
  }
}

export const enum IssueStatus {
  EndOfSession = 4,
}
