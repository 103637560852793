<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <div class="form-group">
    <label>文字サイズ</label>
    <div>
      <div class="btn-group btn-group-toggle" ngbRadioGroup [formControlName]="'fontSize'">
        <label ngbButtonLabel class="c-btn c-btn--default"> <input ngbButton type="radio" value="xs" />極小</label>
        <label ngbButtonLabel class="c-btn c-btn--default"> <input ngbButton type="radio" value="s" />小</label>
        <label ngbButtonLabel class="c-btn c-btn--default"> <input ngbButton type="radio" value="m" />通常</label>
        <label ngbButtonLabel class="c-btn c-btn--default"> <input ngbButton type="radio" value="l" />大</label>
        <label ngbButtonLabel class="c-btn c-btn--default"> <input ngbButton type="radio" value="xl" />極大</label>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>配色</label>
    <div>
      <wj-combo-box
        class="p-theme-selector"
        [itemsSource]="availableThemes"
        [displayMemberPath]="'name'"
        [selectedValuePath]="'value'"
        [formControl]="form.controls['theme']"
      ></wj-combo-box>
    </div>
  </div>

  <div class="p-options--actions">
    <button type="submit" class="c-btn c-btn--default">
      適用
    </button>
    <button class="c-btn c-btn--default" (click)="closeRequest.emit()" type="button">
      キャンセル
    </button>
  </div>
</form>
