import { Injectable, OnDestroy } from '@angular/core';
import { BriskDialogService } from '@argentumcode/brisk-common';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { DemoFinishedDialogComponent } from './demo-finished-dialog/demo-finished-dialog.component';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DemoFinishedDialogService implements OnDestroy {
  private subscription: Subscription;

  constructor(public cocomero: CocomeroService, private dialog: BriskDialogService) {
    const hashArgs: { [key: string]: string } = {};
    for (const [key, value] of location.hash
      .substr(1)
      .split('&')
      .map((a) => a.split('='))) {
      if (key) {
        hashArgs[key] = value;
      }
    }

    if (hashArgs['debug_show_dialog_second']) {
      const n = Number(hashArgs['debug_show_dialog_second']);
      this.subscription = this.cocomero.initializedSubject
        .asObservable()
        .pipe(delay(n * 1000))
        .subscribe(() => {
          this.dialog.open(DemoFinishedDialogComponent, { width: '50em' }).subscribe((result) => {});
        });
    } else {
      this.subscription = this.cocomero.wsMockDone$.subscribe(() => {
        this.dialog.open(DemoFinishedDialogComponent, { width: '50em' }).subscribe((result) => {});
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
