import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizedDirective } from './resized.directive';
import { ResizeService } from './resize.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ResizedDirective],
  providers: [ResizeService],
  exports: [ResizedDirective],
})
export class ResizeModule {}
