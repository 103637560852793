import { Injectable } from '@angular/core';
import { Amount10, CashMargin, Condition, getConditionString, getSideString, OrdStatus, Price10, Share, Side, Strategy } from './object';
import { Subject } from 'rxjs';
import { FixWebsocketService } from './fix-websocket.service';

export enum FIXMsgType {
  OrderSingle = 'D'.charCodeAt(0),
  OrderCancelRequest = 'F'.charCodeAt(0),
  OrderCancelReplaceRequest = 'G'.charCodeAt(0),
  ExecutionReport = '8'.charCodeAt(0),
  OrderCancelReject = '9'.charCodeAt(0),
}

export enum HandlInst {
  DMA = '1'.charCodeAt(0),
  Algo = '2'.charCodeAt(0),
  Manual = '3'.charCodeAt(0),
}

export enum OrdType {
  Market = '1'.charCodeAt(0),
  Limit = '2'.charCodeAt(0),
  MarketOnClose = '5'.charCodeAt(0),
  LimitOnClose = 'B'.charCodeAt(0),
  Funari = 'I'.charCodeAt(0),
}

export enum TimeInForce {
  Day = '0'.charCodeAt(0),
  GoodTillCancel = '1'.charCodeAt(0),
  AtTheOpening = '2'.charCodeAt(0),
  ImmediateOrCancel = '3'.charCodeAt(0),
  FillOrKill = '4'.charCodeAt(0),
}

export enum OrdRejReason {
  BrokerOption = '0'.charCodeAt(0),
  DuplicateOrder = '6'.charCodeAt(0),
}

export enum ExecType {
  New = '0'.charCodeAt(0),
  PartialFill = '1'.charCodeAt(0),
  Fill = '2'.charCodeAt(0),
  DoneForDay = '3'.charCodeAt(0),
  Canceled = '4'.charCodeAt(0),
  Replace = '5'.charCodeAt(0),
  PendingCancel = '6'.charCodeAt(0),
  Rejected = '8'.charCodeAt(0),
  Expired = 'C'.charCodeAt(0),
  PendingReplace = 'E'.charCodeAt(0),
}

export enum CxlRejReason {
  TooLateToCancel = '0'.charCodeAt(0),
  UnknownOrder = '1'.charCodeAt(0),
  BrokerOption = '2'.charCodeAt(0),
  OrderAlreadyInPending = '3'.charCodeAt(0),
}

export enum CxlRejResponseTo {
  OrderCancelRequest = '1'.charCodeAt(0),
  OrderCancelReplaceRequest = '2'.charCodeAt(0),
}

class FIXExecutionReport {
  OrderID: string;
  ClOrdID: string;
  OrigClOrdID: string;
  ExecID: string;
  OrdStatus: OrdStatus;
  Symbol: string;
  Side: Side;
  OrderQty: number;
  OrdType: OrdType;
  Price10: number;
  TimeInForce: TimeInForce;
  LastShares: number;
  LastPx10: number;
  Currency: string;
  LeavesQty: number;
  CumQty: number;
  TransactTime: string;
  HandlInst: HandlInst;
  LastMkt: string;
  TradeDate: string;
  Text: string;
  OrdRejReason: OrdRejReason;
  ExecType: ExecType;
  UserID: number;
  BrokerAccountID: number;
  Broker: string;
  Account: string;
  Tag: string;
  Strategy: Strategy;

  constructor(obj, brokerAccounts: { [key: number]: FIXBrokerAccount }) {
    this.OrderID = obj.OrderID;
    this.ClOrdID = obj.ClOrdID;
    this.OrigClOrdID = obj.OrigClOrdID;
    this.ExecID = obj.ExecID;
    this.OrdStatus = <OrdStatus>obj.OrdStatus.charCodeAt(0);
    this.Symbol = obj.Symbol;
    this.Side = <Side>obj.Side.charCodeAt(0);
    this.OrderQty = obj.OrderQty;
    this.OrdType = <OrdType>obj.OrdType.charCodeAt(0);
    this.Price10 = obj.Price10;
    this.TimeInForce = <TimeInForce>obj.TimeInForce.charCodeAt(0);
    this.LastShares = obj.LastShares;
    this.LastPx10 = obj.LastPx10;
    this.Currency = obj.Currency;
    this.LeavesQty = obj.LeavesQty;
    this.CumQty = obj.CumQty;
    this.TransactTime = obj.TransactTime;
    this.HandlInst = <HandlInst>obj.HandlInst.charCodeAt(0);
    this.LastMkt = obj.LastMkt;
    this.TradeDate = obj.TradeDate;
    this.Text = obj.Text;
    if (obj.OrdRejReason) {
      this.OrdRejReason = <OrdRejReason>obj.OrdRejReason.charCodeAt(0);
    } else {
      this.OrdRejReason = null;
    }
    this.ExecType = <ExecType>obj.ExecType.charCodeAt(0);
    this.UserID = obj.UserID;
    this.BrokerAccountID = obj.BrokerAccountID;
    this.Broker = obj.BrokerAccountID in brokerAccounts ? brokerAccounts[obj.BrokerAccountID].broker : 'N/A';
    this.Account = obj.BrokerAccountID in brokerAccounts ? brokerAccounts[obj.BrokerAccountID].account : 'N/A';
    this.Tag = obj.Tag;
    this.Strategy = obj.Strategy;
  }
}

class FIXOrderCancelReject {
  ClOrdID: string;
  OrdStatus: OrdStatus;
  OrigClOrdID: string;
  Text: string;
  TransactTime: string;
  CxlRejReason: CxlRejReason;
  CxlRejResponseTo: CxlRejResponseTo;

  constructor(obj) {
    this.ClOrdID = obj.ClOrdID;
    this.OrdStatus = <OrdStatus>obj.OrdStatus.charCodeAt(0);
    this.OrigClOrdID = obj.OrigClOrdID;
    this.Text = obj.Text;
    this.TransactTime = obj.TransactTime;
    this.CxlRejReason = <CxlRejReason>obj.CxlRejReason.charCodeAt(0);
    this.CxlRejResponseTo = <CxlRejResponseTo>obj.CxlRejResponseTo.charCodeAt(0);
  }
}

class FIXCondition {
  handlInst: HandlInst;
  ordType: OrdType;
  timeInForce: TimeInForce;
  strategy: Strategy;
}

function conditionToFIX(condition: Condition, price10: Price10): FIXCondition {
  let handlInst = HandlInst.DMA;
  let ordType = OrdType.Limit;
  let timeInForce = TimeInForce.Day;
  let strategy = null;
  switch (condition) {
    case Condition.Zaraba:
      if (price10.isMarket) {
        ordType = OrdType.Market;
      } else {
        ordType = OrdType.Limit;
      }
      timeInForce = TimeInForce.Day;
      break;
    case Condition.OnOpen:
      if (price10.isMarket) {
        ordType = OrdType.Market;
      } else {
        ordType = OrdType.Limit;
      }
      timeInForce = TimeInForce.AtTheOpening;
      break;
    case Condition.OnClose:
      if (price10.isMarket) {
        ordType = OrdType.MarketOnClose;
      } else {
        ordType = OrdType.LimitOnClose;
      }
      timeInForce = TimeInForce.Day;
      break;
    case Condition.IOC:
      if (price10.isMarket) {
        ordType = OrdType.Market;
      } else {
        ordType = OrdType.Limit;
      }
      timeInForce = TimeInForce.ImmediateOrCancel;
      break;
    case Condition.Funari:
      ordType = OrdType.Funari;
      timeInForce = TimeInForce.Day;
      break;
    case Condition.VWAP:
      handlInst = HandlInst.Algo;
      ordType = OrdType.Limit;
      strategy = Strategy.VWAP;
      break;
    case Condition.Iceberg:
      handlInst = HandlInst.Algo;
      ordType = OrdType.Limit;
      strategy = Strategy.Iceberg;
      break;
  }
  return { handlInst, ordType, timeInForce, strategy };
}

function FIXToCondition(ordType: OrdType, timeInForce: TimeInForce, strategy: Strategy): Condition {
  switch (ordType) {
    case OrdType.Limit:
    case OrdType.Market:
      if (strategy === Strategy.VWAP) {
        return Condition.VWAP;
      } else if (strategy === Strategy.Iceberg) {
        return Condition.Iceberg;
      } else {
        switch (timeInForce) {
          case TimeInForce.AtTheOpening:
            return Condition.OnOpen;
          case TimeInForce.Day:
            return Condition.Zaraba;
          case TimeInForce.ImmediateOrCancel:
            return Condition.IOC;
        }
      }
      break;
    case OrdType.LimitOnClose:
    case OrdType.MarketOnClose:
      return Condition.OnClose;
    case OrdType.Funari:
      return Condition.Funari;
  }
  return Condition.Zaraba;
}

export function isFiltered(
  filterUserID: number,
  filterBrokerAccountID: number,
  filterTag: string,
  targetUserID: number,
  targetBrokerAccountID: number,
  targetTag: string
): boolean {
  const userID = filterUserID === -1 || filterUserID === targetUserID;
  const brokerAccountID = filterBrokerAccountID === -1 || filterBrokerAccountID === targetBrokerAccountID;
  const tag = !filterTag || filterTag === targetTag;
  return userID && brokerAccountID && tag;
}

// -1, -1, ''を常に持ってアップデート
// 全注文からをフィルターして再構築する
export class FIXPortfolio {
  // Sum(pos.[buy,sell]workingAmount10)
  buyWorkingAmount10: Amount10 = new Amount10(0);
  sellWorkingAmount10: Amount10 = new Amount10(0);
  // Sum(pos.[buy,sell]FilledAmount10)
  buyFilledAmount10: Amount10 = new Amount10(0);
  sellFilledAmount10: Amount10 = new Amount10(0);

  // Sum(pos.positionPL10);
  positionPL10: Amount10 = new Amount10(0);
  // Sum(pos.executionPL10);
  executionPL10: Amount10 = new Amount10(0);

  // Sum((pos.buyFilledQuantity - pos.sellFilledQuantity) * stock.markPrice10)
  // >0のみ集めたもの
  longAmount10: Amount10 = new Amount10(0);
  // <0のみ集めたもの
  shortAmount10: Amount10 = new Amount10(0);

  get delta10(): Amount10 {
    return new Amount10(this.longAmount10.value - this.shortAmount10.value);
  }

  positions: Array<FIXPosition> = [];
  positionsDic: { [key: number]: FIXPosition } = {};
  orders: Array<FIXOrder> = [];
  ordersDic: { [key: string]: FIXOrder } = {};
}

export class FIXOrder {
  parent: FIXPosition;

  issueCode: number;

  orderID = '';
  clOrdID = '';
  userID = -1;
  brokerAccountID = -1;
  broker = '';
  account = '';
  tag = '';

  text = '';

  side: Side;

  get sideStr(): string {
    return getSideString(this.side);
  }

  condition: Condition;

  get conditionStr(): string {
    return getConditionString(this.condition);
  }

  workingPrice10: Price10 = new Price10(0);

  get workingPrice(): string {
    return this.condition === Condition.VWAP ? 'VWAP' : this.workingPrice10.isMarket ? '成行' : (this.workingPrice10.value / 10).toFixed(1);
  }

  workingQuantity: Share = new Share(0);
  workingAmount10: Amount10 = new Amount10(0);
  filledQuantity: Share = new Share(0);
  filledAmount10: Amount10 = new Amount10(0);

  get filledPrice(): string {
    return this.filledQuantity.eq(new Share(0)) ? '' : (this.filledAmount10.value / this.filledQuantity.value / 10).toFixed(1);
  }

  cashMargin: CashMargin = CashMargin.Cash;
  ordStatus: OrdStatus;

  constructor(issueCode: number, clOrdID: string, orderID: string) {
    this.issueCode = issueCode;
    this.clOrdID = clOrdID;
    this.orderID = orderID;
  }

  update(json, brokerAccounts: { [key: number]: FIXBrokerAccount }): void {
    const msgType = <FIXMsgType>json.MsgType.charCodeAt(0);
    switch (msgType) {
      case FIXMsgType.ExecutionReport:
        this.onExecutionReport(new FIXExecutionReport(json, brokerAccounts));
        break;
      case FIXMsgType.OrderCancelReject:
        this.onOrderCancelReject(new FIXOrderCancelReject(json));
        break;
    }
  }

  onExecutionReport(obj: FIXExecutionReport): void {
    if (obj.ExecType === ExecType.PendingCancel || obj.ExecType === ExecType.PendingReplace) {
      return;
    }

    const oldCondition = this.condition;
    const oldWorkingPrice10 = this.workingPrice10;
    const oldWorkingQuantity = this.workingQuantity;
    const fillPrice10 = new Price10(obj.LastPx10);
    const fillQuantity = new Share(obj.LastShares);

    this.ordStatus = obj.OrdStatus;
    this.clOrdID = obj.ClOrdID;
    this.userID = obj.UserID;
    this.brokerAccountID = obj.BrokerAccountID;
    this.broker = obj.Broker;
    this.account = obj.Account;
    this.tag = obj.Tag;
    this.side = obj.Side;
    this.condition = FIXToCondition(obj.OrdType, obj.TimeInForce, obj.Strategy);
    this.workingPrice10 = new Price10(obj.Price10);
    this.workingQuantity = new Share(obj.LeavesQty);
    this.workingAmount10 = Amount10.from(this.workingQuantity, this.workingPrice10);
    if (obj.ExecType === ExecType.PartialFill || obj.ExecType === ExecType.Fill) {
      this.filledQuantity.add(fillQuantity);
      this.filledAmount10.add(Amount10.from(fillPrice10, fillQuantity));
    }

    this.text = obj.Text;

    if (this.parent) {
      this.parent.update(this, oldCondition, oldWorkingPrice10, oldWorkingQuantity, fillPrice10, fillQuantity);
    }
  }

  onOrderCancelReject(obj): void {}
}

export class FIXPosition {
  beginPosition: Share = new Share(0);
  buyWorkingQuantity: Share = new Share(0);
  buyWorkingAmount10: Amount10 = new Amount10(0);
  buyFilledQuantity: Share = new Share(0);
  buyFilledAmount10: Amount10 = new Amount10(0);
  sellWorkingQuantity: Share = new Share(0);
  sellWorkingAmount10: Amount10 = new Amount10(0);
  sellFilledQuantity: Share = new Share(0);
  sellFilledAmount10: Amount10 = new Amount10(0);
  bids: { [key: number]: Share } = {};
  bidCloses: { [key: number]: Share } = {};
  asks: { [key: number]: Share } = {};
  askCloses: { [key: number]: Share } = {};

  constructor(public issueCode: number, public userID: number, public brokerAccountID: number, public tag: string) {}

  update(
    order: FIXOrder,
    oldCondition: Condition,
    oldWorkingPrice10: Price10,
    oldWorkingQuantity: Share,
    fillPrice10: Price10,
    fillQuantity: Share
  ): void {
    if (
      order.condition !== Condition.VWAP &&
      order.condition !== Condition.Iceberg &&
      (!oldWorkingPrice10.eq(order.workingPrice10) || !oldWorkingQuantity.eq(order.workingQuantity) || oldCondition !== order.condition)
    ) {
      const olds = this.getDic(order.side, oldCondition);
      const news = this.getDic(order.side, order.condition);
      if (oldWorkingQuantity.gt(new Share(0))) {
        olds[oldWorkingPrice10.value].sub(oldWorkingQuantity);
        if (olds[oldWorkingPrice10.value].eq(new Share(0))) {
          delete olds[oldWorkingPrice10.value];
        }
      }
      if (!(order.workingPrice10.value in news)) {
        news[order.workingPrice10.value] = new Share(0);
      }
      news[order.workingPrice10.value].add(order.workingQuantity);
    }
    const [filledQuantity, filledAmount10, workingQuantity, workingAmount10] =
      order.side === Side.Buy
        ? [this.buyFilledQuantity, this.buyFilledAmount10, this.buyWorkingQuantity, this.buyWorkingAmount10]
        : [this.sellFilledQuantity, this.sellFilledAmount10, this.sellWorkingQuantity, this.sellWorkingAmount10];
    filledQuantity.add(fillQuantity);
    filledAmount10.add(Amount10.from(fillQuantity, fillPrice10));
    workingQuantity.add(Share.sub(order.workingQuantity, oldWorkingQuantity));
    workingAmount10.add(
      Amount10.sub(Amount10.from(order.workingPrice10, order.workingQuantity), Amount10.from(oldWorkingPrice10, oldWorkingQuantity))
    );
  }

  add(order: FIXOrder) {
    const news = this.getDic(order.side, order.condition);
    if (!(order.workingPrice10.value in news)) {
      news[order.workingPrice10.value] = new Share(0);
    }
    news[order.workingPrice10.value].add(order.workingQuantity);
    const [filledQuantity, filledAmount10, workingQuantity, workingAmount10] =
      order.side === Side.Buy
        ? [this.buyFilledQuantity, this.buyFilledAmount10, this.buyWorkingQuantity, this.buyWorkingAmount10]
        : [this.sellFilledQuantity, this.sellFilledAmount10, this.sellWorkingQuantity, this.sellWorkingAmount10];
    filledQuantity.add(order.filledQuantity);
    filledAmount10.add(order.filledAmount10);
    workingQuantity.add(order.workingQuantity);
    workingAmount10.add(order.workingAmount10);
  }

  private getDic(side: Side, condition: Condition) {
    return condition === Condition.OnClose
      ? side === Side.Buy
        ? this.bidCloses
        : this.askCloses
      : side === Side.Buy
      ? this.bids
      : this.asks;
  }
}

export class FIXNewOrderPacket {
  MsgType: string;
  BaseClOrdID: number;
  HandlInst: string;
  OrderQty: number;
  OrdType: string;
  Price10: number;
  Side: string;
  Symbol: string;
  TimeInForce: string;
  TransactTime: string;
  ExDestination: string;
  CashMargin: string;
  Tag: string;
  Strategy: Strategy;
}

export class FIXNewOrder {
  constructor(
    public baseClOrdID: number,
    public orderQty: Share,
    public condition: Condition,
    public price10: Price10,
    public side: Side,
    public symbol: string,
    public transactTime: string,
    public exDestination: string,
    public cashMargin: CashMargin,
    public userID: number,
    public brokerAccountID: number,
    public tag: string
  ) {}

  toPacket(): FIXNewOrderPacket {
    const fixCondition = conditionToFIX(this.condition, this.price10);
    return {
      MsgType: String.fromCharCode(FIXMsgType.OrderSingle),
      BaseClOrdID: this.baseClOrdID,
      HandlInst: String.fromCharCode(fixCondition.handlInst),
      OrderQty: this.orderQty.value,
      OrdType: String.fromCharCode(fixCondition.ordType),
      Price10: this.price10.value,
      Side: String.fromCharCode(this.side),
      Symbol: this.symbol,
      TimeInForce: String.fromCharCode(fixCondition.timeInForce),
      TransactTime: this.transactTime,
      ExDestination: this.exDestination,
      CashMargin: String.fromCharCode(this.cashMargin),
      Tag: this.tag,
      Strategy: fixCondition.strategy,
    };
  }
}

export class FIXCancelOrderPacket {
  MsgType: string;
  BaseClOrdID: number;
  OrderQty: number;
  OrigClOrdID: string;
  Side: string;
  Symbol: string;
  TransactTime: string;
  Tag: string;
}

export class FIXCancelOrder {
  constructor(
    public baseClOrdID: number,
    public orderQty: Share,
    public origClOrdID: string,
    public side: Side,
    public symbol: string,
    public transactTime: string,
    public tag: string
  ) {}

  toPacket(): FIXCancelOrderPacket {
    return {
      MsgType: String.fromCharCode(FIXMsgType.OrderCancelRequest),
      BaseClOrdID: this.baseClOrdID,
      OrderQty: this.orderQty.value,
      OrigClOrdID: this.origClOrdID,
      Side: String.fromCharCode(this.side),
      Symbol: this.symbol,
      TransactTime: this.transactTime,
      Tag: this.tag,
    };
  }
}

export class FIXReplaceOrderPacket {
  MsgType: string;
  BaseClOrdID: number;
  OrigClOrdID: string;
  HandlInst: string;
  OrderQty: number;
  OrdType: string;
  Price10: number;
  Side: string;
  Symbol: string;
  TimeInForce: string;
  TransactTime: string;
  ExDestination: string;
  CashMargin: string;
  Strategy: Strategy;
  Tag: string;
}

export class FIXReplaceOrder {
  constructor(
    public baseClOrdID: number,
    public origClOrdID: string,
    public handlInst: HandlInst,
    public orderQty: Share,
    public condition: Condition,
    public price10: Price10,
    public side: Side,
    public symbol: string,
    public transactTime: string,
    public exDestination: string,
    public cashMargin: CashMargin,
    public tag: string
  ) {}

  toPacket(): FIXReplaceOrderPacket {
    const fixCondition = conditionToFIX(this.condition, this.price10);
    return {
      MsgType: String.fromCharCode(FIXMsgType.OrderCancelReplaceRequest),
      BaseClOrdID: this.baseClOrdID,
      OrigClOrdID: this.origClOrdID,
      HandlInst: String.fromCharCode(this.handlInst),
      OrderQty: this.orderQty.value,
      OrdType: String.fromCharCode(fixCondition.ordType),
      Price10: this.price10.value,
      Side: String.fromCharCode(this.side),
      Symbol: this.symbol,
      TimeInForce: String.fromCharCode(fixCondition.timeInForce),
      TransactTime: this.transactTime,
      ExDestination: this.exDestination,
      CashMargin: String.fromCharCode(this.cashMargin),
      Strategy: fixCondition.strategy,
      Tag: this.tag,
    };
  }
}

export class FIXBrokerAccount {
  id: number;
  broker: string;
  account: string;
}

export class FIXHandshake {
  userID: number;
  brokerAccounts: Array<FIXBrokerAccount> = [];
}

export class FIXAccount {
  id: number; // FIXBrokerAccountのID
  name: string; // FIXに設定する口座名と同一

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class FIXBroker {
  id: number; // とりあえず0を指定
  name: string; // 証券会社名　任意の文字列（大抵日本語？）
  accounts: Array<FIXAccount> = [];

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class FIXWebsocketStatus {
  isOrderEntryReady: boolean;
}

@Injectable()
export class FIXService {
  private encoder: TextEncoder = new TextEncoder();
  private decoder: TextDecoder = new TextDecoder('utf-8');

  private updatedSubject = new Subject();
  public updated = this.updatedSubject.asObservable();
  private connectedSubject = new Subject();
  public connected = this.connectedSubject.asObservable();
  public errorReceivedSubject = new Subject<string>();
  public errorReceived = this.errorReceivedSubject.asObservable();

  private userID_: number;

  baseClOrdID = 0;
  pendingBaseClOrdIDs: Set<number> = new Set<number>();

  filterUserID = -1;
  filterBrokerAccountID = -1;
  filterTag = '';

  userIDs: Set<number> = new Set<number>();
  tags: Set<string> = new Set<string>();

  portfolio: FIXPortfolio = new FIXPortfolio();
  orders: Array<FIXOrder> = [];
  ordersDic: { [key: string]: FIXOrder } = {};

  get userID(): number {
    return this.userID_;
  }

  private brokers_: Array<FIXBroker>;

  get brokers(): Array<FIXBroker> {
    return this.brokers_;
  }

  private brokerAccounts_: { [key: number]: FIXBrokerAccount } = {};

  constructor(private fixWebsock: FixWebsocketService) {
    fixWebsock.subscribe(this);
  }

  clear() {
    this.userID_ = undefined;
    this.baseClOrdID = 0;
  }

  dispose(): void {
    if (this.fixWebsock !== null) {
      // this.fixWebsock.dispose();
    }
  }

  // サーバ接続時に呼び出す
  onConnected(obj: FIXHandshake): void {
    this.userID_ = obj.userID;
    this.baseClOrdID = 0;
    this.brokerAccounts_ = {};
    const brokersDic: { [key: string]: FIXBroker } = {};
    for (const brokerAccount of obj.brokerAccounts) {
      if (!(brokerAccount.broker in brokersDic)) {
        brokersDic[brokerAccount.broker] = new FIXBroker(brokerAccount.id, 'GS');
      }
      brokersDic[brokerAccount.broker].accounts.push(new FIXAccount(brokerAccount.id, 'DCGS'));
      this.brokerAccounts_[brokerAccount.id] = brokerAccount;
    }
    this.brokers_ = [];
    for (const key of Object.keys(brokersDic)) {
      const broker = brokersDic[key];
      this.brokers_.push(broker);
    }
    console.log('fix connected');
    this.connectedSubject.next({});
  }

  onStatusChanged(status: FIXWebsocketStatus): void {}

  onReceived(status: Uint8Array): void {
    this.update(JSON.parse(this.decoder.decode(status)));
  }

  sendNew(
    brokerAccountID: number,
    tag: string,
    issueCode: number,
    price10: Price10,
    quantity: Share,
    side: Side,
    condition: Condition,
    fill: boolean
  ): void {
    const fixCond = conditionToFIX(condition, price10);
    const req: FIXNewOrder = new FIXNewOrder(
      this.baseClOrdID,
      quantity,
      condition,
      price10,
      side,
      issueCode.toString(),
      '',
      'T',
      CashMargin.Cash,
      this.userID,
      brokerAccountID,
      tag
    );
    const id = ('00000000' + this.userID).slice(-8) + ('00000000' + this.baseClOrdID).slice(-8);
    const msg = {
      MsgType: String.fromCharCode(FIXMsgType.ExecutionReport),
      OrderID: id,
      ClOrdID: id,
      OrigClOrdID: '',
      ExecID: '',
      OrdStatus: String.fromCharCode(OrdStatus.New),
      Symbol: issueCode.toString(),
      Side: String.fromCharCode(side),
      OrderQty: quantity.value,
      OrdType: String.fromCharCode(fixCond.ordType),
      Price10: price10.value,
      TimeInForce: String.fromCharCode(fixCond.timeInForce),
      LastShares: 0,
      LastPx10: 0,
      Currency: 'JPY',
      LeavesQty: quantity.value,
      CumQty: 0,
      TransactTime: '',
      HandlInst: String.fromCharCode(fixCond.handlInst),
      LastMkt: '',
      TradeDate: '',
      Text: '',
      OrdRejReason: '',
      ExecType: String.fromCharCode(ExecType.New),
      UserID: this.userID,
      BrokerAccountID: brokerAccountID,
      Tag: tag,
      Strategy: fixCond.strategy,
    };
    this.pendingBaseClOrdIDs.add(this.baseClOrdID);
    ++this.baseClOrdID;
    const msgs: Array<Uint8Array> = [];
    msgs.push(this.encoder.encode(JSON.stringify(msg)));
    if (fill) {
      msg.OrdStatus = String.fromCharCode(OrdStatus.Filled);
      msg.LeavesQty = 0;
      msg.CumQty = quantity.value;
      msg.LastPx10 = price10.value;
      msg.LastShares = quantity.value;
      msg.LastMkt = 'T';
      msg.ExecType = String.fromCharCode(ExecType.Fill);
      msgs.push(this.encoder.encode(JSON.stringify(msg)));
    }
    console.log(JSON.stringify(req.toPacket()));
    this.fixWebsock.send(this.userID, brokerAccountID, this.encoder.encode(JSON.stringify(req.toPacket())), msgs);
  }

  fill(order: FIXOrder, fillPrice10: Price10, fillQuantity: Share): void {
    const fixCond = conditionToFIX(order.condition, order.workingPrice10);
    const msg = {
      MsgType: String.fromCharCode(FIXMsgType.ExecutionReport),
      OrdStatus: String.fromCharCode(OrdStatus.PartiallyFilled),
      ClOrdID: order.clOrdID,
      OrderID: order.orderID,
      Side: String.fromCharCode(order.side),
      LeavesQty: order.workingQuantity.value - fillQuantity.value,
      CumQty: order.filledQuantity.value + fillQuantity.value,
      LastPx10: fillPrice10.value,
      LastMkt: 'T',
      LastShares: fillQuantity.value,
      Price10: order.workingPrice10.value,
      ExecType: String.fromCharCode(ExecType.PartialFill),
      OrdType: String.fromCharCode(fixCond.ordType),
      TimeInForce: String.fromCharCode(fixCond.timeInForce),

      ExecID: '',
      Symbol: order.issueCode.toString(),
      OrderQty: order.workingQuantity.value,
      Currency: 'JPY',
      TransactTime: '',
      HandlInst: String.fromCharCode(fixCond.handlInst),
      TradeDate: '',
      Text: '',
      OrdRejReason: '',

      UserID: order.userID,
      BrokerAccountID: order.brokerAccountID,
      Tag: order.tag,
      Strategy: fixCond.strategy,
    };
    this.fixWebsock.send(order.userID, order.brokerAccountID, this.encoder.encode(''), [this.encoder.encode(JSON.stringify(msg))]);
  }

  sendCancel(order: FIXOrder): void {
    const fixCond = conditionToFIX(order.condition, order.workingPrice10);
    const req: FIXCancelOrder = new FIXCancelOrder(
      this.baseClOrdID,
      Share.add(order.workingQuantity, order.filledQuantity),
      order.clOrdID,
      order.side,
      order.issueCode.toString(),
      '',
      order.tag
    );
    const msg = {
      MsgType: String.fromCharCode(FIXMsgType.ExecutionReport),
      OrdStatus: String.fromCharCode(OrdStatus.Canceled),
      ClOrdID: ('00000000' + order.userID).slice(-8) + ('00000000' + this.baseClOrdID).slice(-8),
      OrigClOrdID: order.clOrdID,
      OrderID: order.orderID,
      Side: String.fromCharCode(order.side),
      LastPx10: 0,
      LastShares: 0,
      Price10: order.workingPrice10.value,
      LeavesQty: 0,
      ExecType: String.fromCharCode(ExecType.Canceled),
      OrdType: String.fromCharCode(fixCond.ordType),
      TimeInForce: String.fromCharCode(fixCond.timeInForce),

      ExecID: '',
      Symbol: order.issueCode.toString(),
      OrderQty: order.workingQuantity.value,
      Currency: 'JPY',
      CumQty: order.filledQuantity.value,
      TransactTime: '',
      HandlInst: String.fromCharCode(fixCond.handlInst),
      LastMkt: '',
      TradeDate: '',
      Text: '',
      OrdRejReason: '',

      UserID: order.userID,
      BrokerAccountID: order.brokerAccountID,
      Tag: order.tag,
      Strategy: fixCond.strategy,
    };
    this.pendingBaseClOrdIDs.add(this.baseClOrdID);
    ++this.baseClOrdID;
    console.log(JSON.stringify(req.toPacket()));
    this.fixWebsock.send(order.userID, order.brokerAccountID, this.encoder.encode(JSON.stringify(req.toPacket())), [
      this.encoder.encode(JSON.stringify(msg)),
    ]);
  }

  sendAmend(order: FIXOrder, price10: Price10, quantity: Share, condition: Condition, fill: boolean): void {
    const cumLeavesQty = Share.add(order.filledQuantity, quantity);
    const req: FIXReplaceOrder = new FIXReplaceOrder(
      this.baseClOrdID,
      order.clOrdID,
      HandlInst.DMA,
      cumLeavesQty,
      condition,
      price10,
      order.side,
      order.issueCode.toString(),
      '',
      'T',
      CashMargin.Cash,
      order.tag
    );
    const fixCond = conditionToFIX(condition, price10);
    const msg = {
      MsgType: String.fromCharCode(FIXMsgType.ExecutionReport),
      OrdStatus: String.fromCharCode(OrdStatus.Replaced),
      ClOrdID: ('00000000' + order.userID).slice(-8) + ('00000000' + this.baseClOrdID).slice(-8),
      OrigClOrdID: order.clOrdID,
      OrderID: order.orderID,
      Side: String.fromCharCode(order.side),
      LastPx10: 0,
      LastShares: 0,
      Price10: price10.value,
      LeavesQty: quantity.value,
      ExecType: String.fromCharCode(ExecType.Replace),
      OrdType: String.fromCharCode(fixCond.ordType),
      TimeInForce: String.fromCharCode(fixCond.timeInForce),

      ExecID: '',
      Symbol: order.issueCode.toString(),
      OrderQty: Share.add(quantity, order.filledQuantity).value,
      Currency: 'JPY',
      CumQty: order.filledQuantity.value,
      TransactTime: '',
      HandlInst: String.fromCharCode(fixCond.handlInst),
      LastMkt: '',
      TradeDate: '',
      Text: '',
      OrdRejReason: '',

      UserID: order.userID,
      BrokerAccountID: order.brokerAccountID,
      Tag: order.tag,
      Strategy: fixCond.strategy,
    };
    this.pendingBaseClOrdIDs.add(this.baseClOrdID);
    ++this.baseClOrdID;
    const msgs: Array<Uint8Array> = [];
    msgs.push(this.encoder.encode(JSON.stringify(msg)));
    if (fill) {
      msg.OrdStatus = String.fromCharCode(OrdStatus.Filled);
      msg.LeavesQty = 0;
      msg.CumQty = quantity.value;
      msg.LastPx10 = price10.value;
      msg.LastShares = quantity.value;
      msg.LastMkt = 'T';
      msg.ExecType = String.fromCharCode(ExecType.Fill);
      msgs.push(this.encoder.encode(JSON.stringify(msg)));
    }
    this.fixWebsock.send(order.userID, order.brokerAccountID, this.encoder.encode(JSON.stringify(req.toPacket())), msgs);
  }

  update(json): void {
    const orderID = <string>json.OrderID;
    if (
      <FIXMsgType>json.MsgType.charCodeAt(0) === FIXMsgType.ExecutionReport &&
      (<ExecType>json.ExecType.charCodeAt(0) === ExecType.New || <ExecType>json.ExecType.charCodeAt(0) === ExecType.Rejected)
    ) {
      let pos = this.portfolio.positionsDic[+json.Symbol];
      if (!pos) {
        this.portfolio.positions.push((this.portfolio.positionsDic[+json.Symbol] = pos = new FIXPosition(+json.Symbol, -1, -1, '')));
      }
      const ord = (this.ordersDic[orderID] = new FIXOrder(+json.Symbol, <string>json.ClOrdID, orderID));
      this.orders.push(ord);
      const userID = +json.UserID;
      const brokerAccountID = +json.BrokerAccountID;
      const tag = <string>json.Tag;
      console.log([this.filterUserID, this.filterBrokerAccountID, this.filterTag, userID, brokerAccountID, tag]);
      if (isFiltered(this.filterUserID, this.filterBrokerAccountID, this.filterTag, userID, brokerAccountID, tag)) {
        this.portfolio.orders.push((this.portfolio.ordersDic[orderID] = ord));
        ord.parent = pos;
      } else {
        ord.parent = null;
      }
      if (!this.userIDs.has(userID)) {
        this.userIDs.add(userID);
      }
      if (tag && !this.tags.has(tag)) {
        this.tags.add(tag);
      }
    }
    {
      const clOrdID = +json.ClOrdID;
      const baseClOrdID = clOrdID % 100000000;
      const userID = +json.UserID;
      if (this.userID_ === userID) {
        this.baseClOrdID = Math.max(this.baseClOrdID, baseClOrdID + 1);
        if (this.pendingBaseClOrdIDs.has(baseClOrdID) && json.Text) {
          if (this.errorReceivedSubject) {
            this.errorReceivedSubject.next(<string>json.Text);
          }
        }
        if (<FIXMsgType>json.MsgType.charCodeAt(0) === FIXMsgType.ExecutionReport) {
          const execType = <ExecType>json.ExecType.charCodeAt(0);
          if (execType !== ExecType.PendingCancel || execType !== ExecType.PendingReplace) {
            this.pendingBaseClOrdIDs.delete(this.baseClOrdID);
          }
        } else {
          this.pendingBaseClOrdIDs.delete(this.baseClOrdID);
        }
      }
      if (orderID in this.ordersDic) {
        const order = this.ordersDic[orderID];
        order.update(json, this.brokerAccounts_);
      }

      if (this.updatedSubject) {
        this.updatedSubject.next();
      }
      console.log([json, this.baseClOrdID]);
    }
  }

  // Filterを設定してAggregatedPositionを生成する
  //
  setFilter(userID: number, brokerAccountID: number, tag: string): boolean {
    if (this.filterUserID === userID && this.filterBrokerAccountID === brokerAccountID && this.filterTag === tag) {
      // same filter. do nothing
      return false;
    } else {
      this.portfolio = this.filter(userID, brokerAccountID, tag);
      this.updatedSubject.next();
      return true;
    }
  }

  private filter(userID: number, brokerAccountID: number, tag: string): FIXPortfolio {
    const ret: FIXPortfolio = new FIXPortfolio();
    this.filterUserID = userID;
    this.filterBrokerAccountID = brokerAccountID;
    this.filterTag = tag;
    for (const ord of this.orders) {
      if (isFiltered(userID, brokerAccountID, tag, ord.userID, ord.brokerAccountID, ord.tag)) {
        let pos = ret.positionsDic[ord.issueCode];
        if (!pos) {
          ret.positions.push((ret.positionsDic[ord.issueCode] = pos = new FIXPosition(ord.issueCode, userID, brokerAccountID, tag)));
        }
        (ord.side === Side.Buy ? ret.buyWorkingAmount10 : ret.sellWorkingAmount10).add(ord.workingAmount10);
        (ord.side === Side.Buy ? ret.buyFilledAmount10 : ret.sellFilledAmount10).add(ord.filledAmount10);
        ret.orders.push((ret.ordersDic[ord.orderID] = ord));
        ord.parent = pos;
        pos.add(ord);
      } else {
        ord.parent = null;
      }
    }
    return ret;
  }

  updatePL(): void {}

  capNewOrder(issueCode: number, quantity: Share, isBuy: boolean): [Share, Side] {
    const pos = this.portfolio.positionsDic[issueCode];
    const qty: Share = new Share(0);
    if (pos) {
      qty.add(Share.add(pos.beginPosition, Share.sub(pos.buyFilledQuantity, pos.sellFilledQuantity)));
    }
    if (isBuy) {
      if (pos) {
        qty.add(pos.buyWorkingQuantity);
      }
      if (qty.lt(new Share(0))) {
        return [Share.min(new Share(-qty.value), quantity), Side.Buy];
      }
      return [quantity, Side.Buy];
    } else {
      if (pos) {
        qty.sub(pos.sellWorkingQuantity);
      }
      if (qty.gt(new Share(0))) {
        return [Share.min(qty, quantity), Side.LongSell];
      }
      return [quantity, Side.ShortSell];
    }
  }
}
