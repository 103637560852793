import { AfterViewInit, Component, Inject, InjectionToken, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FirstTutorialComponent } from '@argentumcode/brisk-common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface StockDetailStackHeader {
  click: (ev: MouseEvent) => void;
  showTutorial$: BehaviorSubject<boolean>;
  onTutorialClose: () => void;
}
export const STOCK_DETAIL_STACK_HEADER = new InjectionToken<StockDetailStackHeader>('STOCK_DETAIL_STACK_HEADER');

@Component({
  selector: 'app-fita4-save-item-header',
  templateUrl: './stock-detail-stack-header.component.html',
  styleUrls: ['./stock-detail-stack-header.component.scss'],
})
export class StockDetailStackHeaderComponent implements AfterViewInit, OnDestroy {
  private showTutorialSubscription = new Subscription();

  @ViewChild('newTabTutorial')
  private newTabTutorial?: FirstTutorialComponent;

  constructor(@Inject(STOCK_DETAIL_STACK_HEADER) public stockDetailStackHeader: StockDetailStackHeader) {}

  ngAfterViewInit(): void {
    this.showTutorialSubscription.add(
      this.stockDetailStackHeader.showTutorial$.pipe(distinctUntilChanged()).subscribe((v) => {
        // this.newTabTutorial is set at the timing of AfterViewInit
        if (v) {
          this.newTabTutorial.show();
        } else {
          this.newTabTutorial.closeWithoutEmit();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.newTabTutorial.closeWithoutEmit();
    this.showTutorialSubscription.unsubscribe();
  }
}
