import { Inject, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';

export interface Config {
  [key: string]: any;
}

export const PARENT_CONFIG = new InjectionToken<Config>('Parent Window Config');

export function parentWindowInitializer(): () => void {
  return () => {};
}

@Injectable()
export class ParentWindowService implements OnDestroy {
  constructor(_injector: Injector, @Inject(PARENT_CONFIG) _config: Array<Config>) {
    let mergedConfig: Config = {};
    for (const c of _config) {
      mergedConfig = { ...mergedConfig, ...c };
    }

    window.inject = (token: string) => {
      return _injector.get(mergedConfig[token]);
    };
  }

  ngOnDestroy() {
    delete window.inject;
  }
}
