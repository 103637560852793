import { Injectable, OnDestroy } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { BehaviorSubject, Observable } from 'rxjs';

interface ModalWithWindow {
  id: string;
  dialogWindow: Window;
}
export interface State {
  modalIds: Array<ModalWithWindow>;
}

@Injectable()
export class MultipleWindowDialogService implements OnDestroy {
  private _state: State = { modalIds: [] };
  private _stateSubject = new BehaviorSubject<State>(this._state);
  state$: Observable<State> = this._stateSubject.asObservable();

  private _updateState(state: State) {
    this._state = state;
    this._stateSubject.next(state);
  }

  constructor() {}

  openDialog(id: string, dialogWindow: Window) {
    this._updateState({
      modalIds: [...this._state.modalIds, { id, dialogWindow }],
    });
  }

  closeDialog(id: string) {
    this._updateState({
      modalIds: this._state.modalIds.filter((a) => a.id !== id),
    });
  }

  ngOnDestroy(): void {
    this._stateSubject.complete();
  }
}
