import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  isDevMode,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MouseEventWithElement, ElementType, Theme, TreeMapGroup, TreeMapStock, TreeMapStockUpdate } from '../types';
import { Item as LayoutItem, LayoutEngine, Padding, Position, Result as LayoutResult, SquarifyLayoutEngine } from '../layout-engine';
import { fromEvent, Subscription } from 'rxjs';

interface LayoutData {
  rightLine: boolean;
  bottomLine: boolean;
  groupType?: 'WithTitle' | 'None';
}

export const defaultTheme: Required<Theme> = {
  additionalDensity: 2.0,
  backgroundColor: '#ffffff',
  borderColor: '#000000',
  stockBorderColor: '#000000',
  font: 'sans-serif',
  borderWidth: 1,
  groupsBorderColor: [],
  groupsBorderWidth: [],
  stockBorderWidth: 1,
};

function _autoFontColor(backgroundColor: string): string {
  // 文字色の自動決定
  // https://www.w3.org/TR/AERT/#color-contrast
  const r = parseInt(backgroundColor.substr(1, 2), 16);
  const g = parseInt(backgroundColor.substr(3, 2), 16);
  const b = parseInt(backgroundColor.substr(5, 2), 16);

  if ((r * 299 + g * 587 + b * 114) / 1000 < 128) {
    return '#ffffff';
  } else {
    return '#000000';
  }
}

@Component({
  selector: 'agc-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMapComponent implements AfterViewInit, OnDestroy {
  private _theme?: Readonly<Required<Theme>>;

  @ViewChild('canvas')
  private _canvas?: ElementRef<HTMLCanvasElement>;

  /// 横幅(ピクセル数)
  private _width = 0;
  /// 縦幅(ピクセル数)
  private _height = 0;

  /// 描画する銘柄一覧
  private _stocksMap: { [key: string]: number } = {};
  private _stocks: Array<TreeMapStock> = [];
  private _stockLayouts: Array<LayoutResult<TreeMapStock, LayoutData>> = [];
  private _stockLayoutsMap: { [key: string]: number } = {};

  /// 描画するグループ一覧
  private _groupsMap: { [key: string]: number } = {};
  private _groups: Array<TreeMapGroup> = [];
  private _groupLayouts: Array<LayoutResult<TreeMapGroup, LayoutData>> = [];
  private _groupLayoutsMap: { [key: string]: number } = {};

  // 描画待ち状態
  private _updatedStocks: Array<string> = [];
  private _updatedGroups: Array<string> = [];
  private _requireFullDraw = false;
  private _requireLayout = false;

  // 要素をクリックした時に発生するイベント
  @Output()
  treeMapClick = new EventEmitter<MouseEventWithElement>();

  // 要素をダブルクリックした時に発生するイベント
  @Output()
  treeMapDblClick = new EventEmitter<MouseEventWithElement>();

  // カーソルが入った場合に発生するイベント
  @Output()
  treeMapMouseEnter = new EventEmitter<MouseEventWithElement>();

  // 要素からカーソルが離れた場合に発生するイベント
  @Output()
  treeMapMouseLeave = new EventEmitter<MouseEventWithElement>();

  // 要素にカーソルが移動中に発生するイベント。
  // NgZoneの外で発火される。
  @Output()
  treeMapMouseMove = new EventEmitter<MouseEventWithElement>();

  private _layoutEngine: LayoutEngine = new SquarifyLayoutEngine();

  private _mouseMoveSubscription?: Subscription;

  constructor(private _host: ElementRef<HTMLElement>, private _ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      if (!this._canvas) {
        /// unreachable
        if (isDevMode()) {
          throw new Error('canvas not found');
        }
        return;
      }
      this._mouseMoveSubscription = fromEvent<MouseEvent>(this._canvas.nativeElement, 'mousemove').subscribe((event: MouseEvent) => {
        this._onMouseMove(event);
      });
    });
    this.updateSize();
  }

  ngOnDestroy(): void {
    if (this._mouseMoveSubscription) {
      this._mouseMoveSubscription.unsubscribe();
      this._mouseMoveSubscription = undefined;
    }
  }

  /** 銘柄・グループ・テーマを指定して、TreeMapを初期化する
   * @param stocks - 銘柄のリスト
   * @param groups - グループのリスト。グループは階層が浅いものから順に並んでいなければならない。
   * @param theme - 描画に用いるテーマ
   */
  initialize(stocks: Array<TreeMapStock>, groups: Array<TreeMapGroup>, theme: Theme): void {
    this._theme = { ...defaultTheme, ...theme };
    this._stocks = stocks.map((a) => ({ ...a }));
    this._groups = groups.map((a) => ({ ...a }));
    this._stocksMap = {};
    for (let i = 0; i < this._stocks.length; i++) {
      this._stocksMap[this._stocks[i].key] = i;
    }
    this._groupsMap = {};
    for (let i = 0; i < this._groups.length; i++) {
      this._groupsMap[this._groups[i].key] = i;
    }
    this._updatedStocks = [];
    this._updatedGroups = [];
    this._requireFullDraw = true;
    this._requireLayout = true;

    this.updateSize();
  }

  private _layoutRecursive(g: TreeMapGroup): Array<LayoutItem<TreeMapGroup | TreeMapStock>> {
    if (g.childElementType === ElementType.Stock) {
      return g.children.map((key) => {
        const index: number | undefined = this._stocksMap[key];
        if (index === undefined) {
          throw new Error(`_layoutRecursive: not found stock key ${key}`);
        }
        return {
          item: this._stocks[index],
          value: this._stocks[index].weight,
        };
      });
    } else if (g.childElementType === ElementType.Group) {
      return g.children.map((key) => {
        const index: number | undefined = this._groupsMap[key];
        if (index === undefined) {
          throw new Error(`_layoutRecursive: not found group key ${key}`);
        }
        return {
          item: this._groups[index],
          children: this._layoutRecursive(this._groups[index]),
        };
      });
    } else {
      throw new Error('Invalid childElementType');
    }
  }

  private _extractLayout(layout: Array<LayoutResult<TreeMapGroup | TreeMapStock, LayoutData>>): void {
    for (const item of layout) {
      if (item.item.elementType === ElementType.Group) {
        this._groupLayoutsMap[item.item.key] = this._groupLayouts.length;
        this._groupLayouts.push(item as LayoutResult<TreeMapGroup, LayoutData>);
        this._extractLayout(item.children || []);
      } else {
        this._stockLayoutsMap[item.item.key] = this._stockLayouts.length;
        this._stockLayouts.push(item as LayoutResult<TreeMapStock, LayoutData>);
      }
    }
  }

  /**
   * 描画領域のサイズを更新する
   *
   * @remarks コンポーネント利用側は、要素の大きさが変わるたびに、この関数を呼び出す必要がある
   */
  updateSize(): void {
    if (!this._canvas) {
      return;
    }
    if (!this._theme) {
      return;
    }
    const scaleFactor = (window.devicePixelRatio || 1) * this._theme.additionalDensity;
    this._width = Math.round(this._host.nativeElement.offsetWidth);
    this._height = Math.round(this._host.nativeElement.offsetHeight);
    this._canvas.nativeElement.style.width = `${this._width}px`;
    this._canvas.nativeElement.style.height = `${this._height}px`;

    const ctx = this._canvas.nativeElement.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.canvas.width = this._width * scaleFactor;
    ctx.canvas.height = this._height * scaleFactor;

    ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
    ctx.clearRect(0, 0, this._width, this._height);

    this.updateLayout();
    this.draw();
  }

  private updateLayout(): void {
    if (!this._theme) {
      return;
    }
    const root: Array<LayoutItem<TreeMapGroup | TreeMapStock>> = [];
    for (const s of this._stocks) {
      if (s.parentsKey.length === 0) {
        root.push({
          value: s.weight,
          item: s,
        });
      }
    }
    for (const g of this._groups) {
      if (g.parentsKey.length === 0) {
        root.push({
          item: g,
          children: this._layoutRecursive(g),
        });
      }
    }
    const theme = this._theme;

    const layout = this._layoutEngine.layout(
      root,
      theme.borderWidth,
      theme.borderWidth,
      this._width - theme.borderWidth * 2,
      this._height - theme.borderWidth * 2,
      {
        getPadding: (item: TreeMapGroup | TreeMapStock, pos: Position, region: Position): [Padding, LayoutData] => {
          if (item.elementType === ElementType.Stock) {
            const rightLinePadding = region.x1 === pos.x1 ? 0 : theme.stockBorderWidth;
            const bottomLinePadding = region.y1 === pos.y1 ? 0 : theme.stockBorderWidth;
            return [
              {
                top: 0,
                bottom: bottomLinePadding,
                left: 0,
                right: rightLinePadding,
              },
              {
                bottomLine: bottomLinePadding > 0,
                rightLine: rightLinePadding > 0,
              },
            ];
          } else if (item.elementType === ElementType.Group) {
            const borderWidth = theme.groupsBorderWidth[item.parentsKey.length];
            const innerBorderWidth =
              item.parentsKey.length + 1 < theme.groupsBorderWidth.length
                ? theme.groupsBorderWidth[item.parentsKey.length + 1]
                : theme.stockBorderWidth;
            const rightLinePadding = region.x1 === pos.x1 ? 0 : borderWidth;
            const bottomLinePadding = region.y1 === pos.y1 ? 0 : borderWidth;
            const height = pos.y1 - pos.y0 - bottomLinePadding;
            const width = pos.x1 - pos.x0 - rightLinePadding;
            if (width >= item.titleMinSizeWidth && height >= item.titleMinSizeHeight) {
              // show title
              return [
                {
                  top: item.padding + item.titleHeight + innerBorderWidth,
                  bottom: item.padding + bottomLinePadding + innerBorderWidth,
                  left: item.padding + innerBorderWidth,
                  right: item.padding + rightLinePadding + innerBorderWidth,
                },
                {
                  bottomLine: bottomLinePadding > 0,
                  rightLine: rightLinePadding > 0,
                  groupType: 'WithTitle',
                },
              ];
            } else {
              // No-Title
              return [
                {
                  top: item.padding + innerBorderWidth,
                  bottom: item.padding + bottomLinePadding + innerBorderWidth,
                  left: item.padding + innerBorderWidth,
                  right: item.padding + rightLinePadding + innerBorderWidth,
                },
                {
                  bottomLine: bottomLinePadding > 0,
                  rightLine: rightLinePadding > 0,
                  groupType: 'None',
                },
              ];
            }
          } else {
            return [
              {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              },
              {
                bottomLine: false,
                rightLine: false,
              },
            ];
          }
        },
      }
    );
    this._groupLayoutsMap = {};
    this._groupLayouts = [];
    this._stockLayoutsMap = {};
    this._stockLayouts = [];
    this._extractLayout(layout);
    this._requireLayout = false;
    this._requireFullDraw = true;
  }

  /**
   * 単一銘柄の描画内容の更新
   *
   * @remarks 実際の描画はdrawを呼び出すまで実行されない
   *
   * @param key 銘柄のキー
   * @param s 更新するデータ
   */
  updateSingleStock(key: string, s: TreeMapStockUpdate): void {
    const index: number | undefined = this._stockLayoutsMap[key];
    if (index === undefined) {
      throw new Error(`updateSingleStock: not found key ${key}`);
    }
    if ('weight' in s && this._stockLayouts[index].item.weight !== s.weight) {
      this._requireLayout = true;
    }
    this._stockLayouts[index].item = {
      ...this._stockLayouts[index].item,
      ...s,
    };
    const originalIndex: number | undefined = this._stocksMap[key];
    if (originalIndex === undefined) {
      throw new Error(`updateSingleStock: not found key ${key}`);
    }
    this._stocks[originalIndex] = {
      ...this._stocks[originalIndex],
      ...s,
    };
    this._updatedStocks.push(key);
  }

  /**
   * 単一グループの描画内容の更新
   *
   * @remarks 実際の描画はdrawを呼び出すまで実行されない
   *
   * @param key 銘柄のキー
   * @param update 更新するデータ
   */
  updateSingleGroup(key: string, update: TreeMapStockUpdate): void {
    const index: number | undefined = this._groupLayoutsMap[key];
    if (index === undefined) {
      throw new Error(`updateSingleGroup: not found key ${key}`);
    }
    this._groupLayouts[index].item = {
      ...this._groupLayouts[index].item,
      ...update,
    };
    const originalIndex: number | undefined = this._groupsMap[key];
    if (originalIndex === undefined) {
      throw new Error(`updateSingleGroup: not found key ${key}`);
    }
    this._groups[originalIndex] = {
      ...this._groups[originalIndex],
      ...update,
    };
    this._updatedGroups.push(key);
  }

  /**
   * キャンバス要素の取得
   *
   * @returns TreeMapの描画に用いるキャンバス
   */
  getCanvas(): HTMLCanvasElement | undefined {
    if (this._canvas) {
      return this._canvas.nativeElement;
    }
    return undefined;
  }

  /**
   * TreeMapの描画を行う。
   *
   * @param full 全銘柄を再描画する場合はtrue。falseの場合は更新された銘柄のみを描画する。
   */
  draw(full = false): void {
    if (!this._theme) {
      return;
    }
    if (!this._canvas) {
      return;
    }
    if (this._requireLayout) {
      this.updateLayout();
      this._requireLayout = false;
    }
    const ctx = this._canvas.nativeElement.getContext('2d');
    if (!ctx) {
      return;
    }
    if (this._width < 0 || this._height < 0) {
      return;
    }
    if (full || this._requireFullDraw) {
      this._fullDraw(ctx, this._theme);
      this._requireFullDraw = false;
    } else {
      this._partialDraw(ctx, this._theme);
    }
  }

  private _fullDraw(ctx: CanvasRenderingContext2D, theme: Required<Theme>): void {
    // 初期化と全体の枠の描画
    ctx.fillStyle = theme.backgroundColor;
    ctx.fillRect(0, 0, this._width, this._height);
    if (theme.borderWidth > 0) {
      ctx.strokeStyle = theme.borderColor;
      ctx.lineWidth = theme.borderWidth;
      ctx.strokeRect(theme.borderWidth / 2, theme.borderWidth / 2, this._width - theme.borderWidth, this._height - theme.borderWidth);
    }
    // グループの描画
    for (const groupLayout of this._groupLayouts) {
      this._renderGroup(ctx, theme, groupLayout, true, false);
    }

    // 銘柄の描画
    for (const stockLayout of this._stockLayouts) {
      this._renderStock(ctx, theme, stockLayout, true, false, false, false, false);
    }
    ctx.beginPath();
    ctx.strokeStyle = theme.stockBorderColor;
    for (const stockLayout of this._stockLayouts) {
      this._renderStock(ctx, theme, stockLayout, false, true, false, false, false);
    }
    ctx.stroke();

    for (const stockLayout of this._stockLayouts) {
      this._renderStock(ctx, theme, stockLayout, false, false, false, true, true);
    }
  }

  private _partialDraw(ctx: CanvasRenderingContext2D, theme: Required<Theme>): void {
    for (const stockKey of this._updatedStocks) {
      const layoutIndex = this._stockLayoutsMap[stockKey];
      if (layoutIndex !== undefined) {
        this._renderStock(ctx, theme, this._stockLayouts[layoutIndex], true, false, false, true, true);
      }
    }
    this._updatedStocks = [];
    for (const groupKey of this._updatedGroups) {
      const layoutIndex = this._groupLayoutsMap[groupKey];
      if (layoutIndex !== undefined) {
        this._renderGroup(ctx, theme, this._groupLayouts[layoutIndex], true, false, false, false, false, true, true);
      }
    }
    this._updatedGroups = [];
  }

  private _renderGroup(
    ctx: CanvasRenderingContext2D,
    theme: Required<Theme>,
    g: LayoutResult<TreeMapGroup, LayoutData>,
    background = true,
    backgroundFull = true,
    border = true,
    innerBorder = true,
    stroke = true,
    text = true,
    autoTextColor = true
  ): void {
    if (g.pos.x1 - g.pos.x0 <= 0) {
      return;
    }
    if (g.pos.y1 - g.pos.y0 <= 0) {
      return;
    }
    const borderWidth = theme.groupsBorderWidth[g.item.parentsKey.length];
    const innerBorderWidth =
      g.item.parentsKey.length + 1 < theme.groupsBorderWidth.length
        ? theme.groupsBorderWidth[g.item.parentsKey.length + 1]
        : theme.stockBorderWidth;
    // 内部に銘柄を描画するための十分な大きさがあるかどうか
    const drawInner = g.innerPos.x1 - g.innerPos.x0 >= 1 && g.innerPos.y1 - g.innerPos.y0 >= 1;
    if (backgroundFull || (background && !drawInner)) {
      ctx.fillStyle = g.item.backgroundColor;
      ctx.fillRect(
        g.pos.x0,
        g.pos.y0,
        g.pos.x1 - g.pos.x0 - (g.layoutData.rightLine ? borderWidth : 0),
        g.pos.y1 - g.pos.y0 - (g.layoutData.bottomLine ? borderWidth : 0)
      );
    }
    if (border && borderWidth > 0) {
      if (stroke) {
        ctx.beginPath();
        ctx.strokeStyle = theme.groupsBorderColor[g.item.parentsKey.length];
        ctx.lineWidth = borderWidth;
      }
      if (g.layoutData.bottomLine) {
        ctx.moveTo(g.pos.x0, g.pos.y1 - borderWidth / 2);
        ctx.lineTo(g.pos.x1, g.pos.y1 - borderWidth / 2);
      }
      if (g.layoutData.rightLine) {
        ctx.moveTo(g.pos.x1 - borderWidth / 2, g.pos.y0);
        ctx.lineTo(g.pos.x1 - borderWidth / 2, g.pos.y1);
      }
      if (stroke) {
        ctx.stroke();
      }
    }
    if (innerBorder && drawInner && innerBorderWidth > 0) {
      if (stroke) {
        ctx.beginPath();
        ctx.strokeStyle =
          g.item.parentsKey.length + 1 < theme.groupsBorderWidth.length
            ? theme.groupsBorderColor[g.item.parentsKey.length + 1]
            : theme.stockBorderColor;
        ctx.lineWidth = innerBorderWidth;
      }
      ctx.rect(
        g.innerPos.x0 - innerBorderWidth / 2,
        g.innerPos.y0 - innerBorderWidth / 2,
        g.innerPos.x1 - g.innerPos.x0 + innerBorderWidth,
        g.innerPos.y1 - g.innerPos.y0 + innerBorderWidth
      );
      if (stroke) {
        ctx.stroke();
      }
    }
    if (background && drawInner) {
      ctx.fillStyle = g.item.backgroundColor;
      ctx.fillRect(
        g.pos.x0,
        g.pos.y0,
        g.pos.x1 - g.pos.x0 - (g.layoutData.rightLine ? borderWidth : 0),
        g.item.padding + (g.layoutData.groupType === 'WithTitle' ? g.item.titleHeight : 0)
      );
      ctx.fillRect(g.pos.x0, g.pos.y0, g.item.padding, g.pos.y1 - g.pos.y0 - (g.layoutData.bottomLine ? borderWidth : 0));
      ctx.fillRect(
        g.pos.x1 - g.item.padding - (g.layoutData.rightLine ? borderWidth : 0),
        g.pos.y0,
        g.item.padding,
        g.pos.y1 - g.pos.y0 - (g.layoutData.bottomLine ? borderWidth : 0)
      );
      ctx.fillRect(
        g.pos.x0,
        g.pos.y1 - g.item.padding - (g.layoutData.bottomLine ? borderWidth : 0),
        g.pos.x1 - g.pos.x0 - (g.layoutData.rightLine ? borderWidth : 0),
        g.item.padding
      );
    }
    if (text && g.layoutData.groupType === 'WithTitle') {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'left';
      if (autoTextColor) {
        ctx.fillStyle = _autoFontColor(g.item.backgroundColor);
      }
      ctx.font = `${g.item.fontSize}px / ${g.item.titleHeight}px ${theme.font}`;
      ctx.save();
      ctx.beginPath();
      ctx.rect(g.pos.x0, g.pos.y0, g.pos.x1 - g.pos.x0, g.item.titleHeight + g.item.padding);
      ctx.fillText(g.item.name, g.pos.x0 + g.item.padding, g.pos.y0 + (g.item.titleHeight + g.item.padding) / 2);
      ctx.restore();
    }
  }

  private _renderStock(
    ctx: CanvasRenderingContext2D,
    theme: Required<Theme>,
    s: LayoutResult<TreeMapStock, LayoutData>,
    background = true,
    border = true,
    stroke = true,
    text = true,
    autoTextColor = true
  ): void {
    if (s.pos.x1 - s.pos.x0 <= 0) {
      return;
    }
    if (s.pos.y1 - s.pos.y0 <= 0) {
      return;
    }
    if (background) {
      ctx.fillStyle = s.item.backgroundColor;
      ctx.fillRect(s.innerPos.x0, s.innerPos.y0, s.innerPos.x1 - s.innerPos.x0, s.innerPos.y1 - s.innerPos.y0);
    }

    if (theme.stockBorderWidth > 0) {
      if (stroke) {
        ctx.beginPath();
        ctx.strokeStyle = theme.stockBorderColor;
        ctx.lineWidth = theme.stockBorderWidth;
      }
      if (border) {
        if (s.layoutData.bottomLine) {
          ctx.moveTo(s.pos.x0, s.innerPos.y1 + theme.stockBorderWidth / 2);
          ctx.lineTo(s.pos.x1, s.innerPos.y1 + theme.stockBorderWidth / 2);
        }
        if (s.layoutData.rightLine) {
          ctx.moveTo(s.innerPos.x1 + theme.stockBorderWidth / 2, s.pos.y0);
          ctx.lineTo(s.innerPos.x1 + theme.stockBorderWidth / 2, s.pos.y1);
        }
      }
      if (stroke) {
        ctx.stroke();
      }
    }
    if (text) {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      if (autoTextColor) {
        ctx.fillStyle = _autoFontColor(s.item.backgroundColor);
      }
      if (s.innerPos.x1 - s.innerPos.x0 >= 6 && s.innerPos.y1 - s.innerPos.y0 >= 6) {
        const fontSizes = [6, 7, 8, 9, 10, 12, 14, 16, 20, 24, 32].reverse();

        for (const fs of fontSizes) {
          if (s.innerPos.y1 - s.innerPos.y0 < fs) {
            continue;
          }
          const fontWidth = fs * s.item.name.length;
          if (s.innerPos.x1 - s.innerPos.x0 < fontWidth + Math.max(6, fs)) {
            continue;
          }

          ctx.save();
          ctx.beginPath();
          ctx.rect(s.innerPos.x0, s.innerPos.y0, s.innerPos.x1 - s.innerPos.x0, s.innerPos.y1 - s.innerPos.y0);
          ctx.clip();
          if (s.innerPos.y1 - s.innerPos.y0 < fs * 1.5 * 2 || s.item.info === '') {
            ctx.font = `${fs}px / ${Math.ceil(fs * 1.5)}px ${theme.font}`;
            ctx.fillText(s.item.name, (s.innerPos.x0 + s.innerPos.x1) / 2, (s.innerPos.y0 + s.innerPos.y1) / 2);
          } else {
            let found = false;
            for (const fs2 of fontSizes) {
              if (fs2 > fs) {
                continue;
              }
              if (s.innerPos.x1 - s.innerPos.x0 >= s.item.info.length * 0.56 * fs2 + Math.max(6, fs2)) {
                ctx.font = `${fs2}px / ${Math.ceil(fs2 * 1.5)}px ${theme.font}`;
                ctx.fillText(s.item.info, (s.innerPos.x0 + s.innerPos.x1) / 2, (s.innerPos.y0 + s.innerPos.y1 + fs * 1.5) / 2);
                found = true;
                break;
              }
            }
            if (found) {
              ctx.font = `${fs}px / ${Math.ceil(fs * 1.5)}px ${theme.font}`;
              ctx.fillText(s.item.name, (s.innerPos.x0 + s.innerPos.x1) / 2, (s.innerPos.y0 + s.innerPos.y1 - fs * 1.5) / 2);
            } else {
              ctx.font = `${fs}px / ${Math.ceil(fs * 1.5)}px ${theme.font}`;
              ctx.fillText(s.item.name, (s.innerPos.x0 + s.innerPos.x1) / 2, (s.innerPos.y0 + s.innerPos.y1) / 2);
            }
          }
          ctx.restore();
          break;
        }
      }
    }
  }

  _onClick(event: MouseEvent): void {
    const elem = this._searchElement(event);
    this.treeMapClick.emit({
      element: elem,
      original: event,
    });
  }

  _onDblClick(event: MouseEvent): void {
    const elem = this._searchElement(event);
    this.treeMapDblClick.emit({
      element: elem,
      original: event,
    });
  }

  private _searchElement(event: MouseEvent): TreeMapStock | TreeMapGroup | undefined {
    // let stock: Result<StockElement, LayoutData>;
    for (const s of this._stockLayouts) {
      if (s.pos.x0 <= event.offsetX && event.offsetX < s.pos.x1) {
        if (s.pos.y0 <= event.offsetY && event.offsetY < s.pos.y1) {
          return s.item;
        }
      }
    }
    for (let i = this._groupLayouts.length - 1; i >= 0; i--) {
      const g = this._groupLayouts[i];
      if (g.pos.x0 <= event.offsetX && event.offsetX < g.pos.x1) {
        if (g.pos.y0 <= event.offsetY && event.offsetY < g.pos.y1) {
          return g.item;
        }
      }
    }
    return undefined;
  }

  _onMouseEnter(event: MouseEvent): void {
    const elem = this._searchElement(event);
    this.treeMapMouseEnter.emit({
      element: elem,
      original: event,
    });
  }

  _onMouseLeave(event: MouseEvent): void {
    const elem = this._searchElement(event);
    this.treeMapMouseLeave.emit({
      element: elem,
      original: event,
    });
  }

  _onMouseMove(event: MouseEvent): void {
    const elem = this._searchElement(event);
    this.treeMapMouseMove.emit({
      element: elem,
      original: event,
    });
  }
}
