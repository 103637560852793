import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColorConverterService, ResizeService, ThemeService, VgCollectionView } from '@argentumcode/brisk-common';
import { asyncScheduler, Subscription } from 'rxjs';
import { Color } from '@grapecity/wijmo';
import { StockPortfolio } from '../portfolio';
import { throttleTime } from 'rxjs/operators';
import { CocomeroService } from '../../core/cocomero.service';
import { Element as GroupingElement, GroupElement, GroupingType, IssueGrouper, Stock as GrouperStock, StockElement } from './grouper';
import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MapViewTooltipComponent } from '../map-view-tooltip/map-view-tooltip.component';
import { MapViewTooltipService } from '../map-view-tooltip/map-view-tooltip.service';
import { ItaViewService } from '../../core/ita-view.service';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import {
  ElementType,
  MouseEventWithElement,
  Theme as TreeMapTheme,
  TreeMapComponent,
  TreeMapGroup,
  TreeMapStock,
} from '@argentumcode/tree-map';
import { ComponentPortal } from '@angular/cdk/portal';
import { IssueTypesService } from '../../core/issue-types.service';

interface TooltipState {
  mouseX: number;
  mouseY: number;
  overlayWidth: number;
  overlayHeight: number;
  overlayRef: OverlayRef;
  componentRef: ComponentRef<MapViewTooltipComponent>;
  positionStrategy: GlobalPositionStrategy;
}

interface Theme {
  undefinedColor: string;
  zeroColor: string;
  thresholdUpColor: string;
  thresholdDownColor: string;
  upColor: string;
  downColor: string;
  backgroundColor: string;
  stockBorderColor: string;
  group1BorderColor: string;
  group2BorderColor: string;
}

interface ThemeColorCache {
  undefinedColor: Color;
  zeroColor: Color;
  thresholdUpColor: Color;
  thresholdDownColor: Color;
  upColor: Color;
  downColor: Color;
}

const lightTheme: Theme = {
  undefinedColor: '#dddddd',
  zeroColor: '#ffffff',
  thresholdUpColor: '#e05754',
  thresholdDownColor: '#338b39',
  upColor: '#f18885',
  downColor: '#63ab69',
  backgroundColor: '#ffffff',
  stockBorderColor: '#000000',
  group1BorderColor: '#7f7f7f',
  group2BorderColor: '#dddddd',
};

const darkTheme: Theme = {
  undefinedColor: '#202020',
  zeroColor: '#272f36',
  thresholdUpColor: '#ee1010',
  thresholdDownColor: '#30a030',
  upColor: '#ee1010',
  downColor: '#30a030',
  backgroundColor: '#272f36',
  stockBorderColor: '#000000',
  group1BorderColor: '#272f36',
  group2BorderColor: '#828282',
};

const blackTheme: Theme = {
  ...darkTheme,
  undefinedColor: '#202020',
  zeroColor: '#000000',
  backgroundColor: '#000000',
  stockBorderColor: '#000000',
  group1BorderColor: '#000000',
  group2BorderColor: '#828282',
};

interface StockUpdateCache {
  lastPrice10: number | undefined | null;
  predict: boolean;
}

function _inverseColor(t: Theme): Theme {
  return {
    ...t,
    upColor: t.downColor,
    downColor: t.upColor,
    thresholdUpColor: t.thresholdDownColor,
    thresholdDownColor: t.thresholdUpColor,
  };
}

function _createThemeCache(t: Theme): ThemeColorCache {
  return {
    undefinedColor: new Color(t.undefinedColor),
    upColor: new Color(t.upColor),
    downColor: new Color(t.downColor),
    thresholdUpColor: new Color(t.thresholdUpColor),
    thresholdDownColor: new Color(t.thresholdDownColor),
    zeroColor: new Color(t.zeroColor),
  };
}

const themes: { [key: string]: Theme } = {
  light: lightTheme,
  dark: darkTheme,
  'dark-inverse': _inverseColor(darkTheme),
  ns: blackTheme,
  black: blackTheme,
};

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
  providers: [MapViewTooltipService],
})
export class MapViewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _dataSourceInput: VgCollectionView;
  private _visible = false;
  private _started = false;
  private _dataSourceSubscription: Subscription;
  private _items: Array<StockPortfolio> = [];
  private _invisibleUpdate = false;

  @ViewChild('treeMap')
  private _treeMap: TreeMapComponent;

  private _currentTheme: Theme = lightTheme;
  private _currentThemeColorCache: ThemeColorCache = _createThemeCache(lightTheme);

  @Input()
  groupingType: Array<GroupingType>;

  @Output()
  public stockDblClick = new EventEmitter<undefined>();

  private _tooltipState?: TooltipState;

  private _subscription: Subscription = new Subscription();

  // 現在表示している銘柄情報
  private _stocks: Array<[StockElement, TreeMapStock, StockUpdateCache]> = [];
  private _groups: Array<[GroupElement, TreeMapGroup]> = [];

  constructor(
    private readonly _injector: Injector,
    private colorConverter: ColorConverterService,
    private changeDetectorRef: ChangeDetectorRef,
    private cocomero: CocomeroService,
    private issueTypes: IssueTypesService,
    private resize: ResizeService,
    private _host: ElementRef<HTMLElement>,
    private _overlay: Overlay,
    private _itaView: ItaViewService,
    private _tooltip: MapViewTooltipService,
    private _theme: ThemeService,
    private _ngZone: NgZone
  ) {
    if (_host.nativeElement) {
      this._subscription.add(
        resize
          .observeElement(_host.nativeElement)
          .pipe(
            throttleTime(100, asyncScheduler, {
              leading: true,
              trailing: true,
            })
          )
          .subscribe((entry) => {
            if (entry.contentRect.width !== 0) {
              if (this._treeMap) {
                this._treeMap.updateSize();
              }
            }
          })
      );
      this._subscription.add(
        this._theme.theme$.subscribe((theme) => {
          this._currentTheme = themes[theme] || lightTheme;
          this._currentThemeColorCache = _createThemeCache(this._currentTheme);
          this._updateCollection();
        })
      );
      this._currentTheme = themes[this._theme.theme] || lightTheme;
      this._currentThemeColorCache = _createThemeCache(this._currentTheme);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateCollection();
  }

  @Input('dataSource')
  get dataSourceInput(): VgCollectionView {
    return this._dataSourceInput;
  }

  set dataSourceInput(value: VgCollectionView) {
    if (this._dataSourceInput === value) {
      return;
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    this._dataSourceInput = value;
    if (this._dataSourceInput) {
      this._dataSourceSubscription = this._dataSourceInput.items.subscribe((items) => {
        this._items = items;
        this.onCollectionChanged();
      });
    }
  }

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (this._visible === value) {
      return;
    }
    this._visible = value;
    if (value) {
      if (this._invisibleUpdate) {
        this._updateCollection();
      }
      if (!this._started) {
        this._itaView.start();
        this._started = true;
      }
    } else {
      if (this._started) {
        this._itaView.stop();
        this._started = false;
      }
    }
  }

  onCollectionChanged() {
    if (!this.visible) {
      this._invisibleUpdate = true;
      return;
    }
    this._updateCollection();
  }

  ngOnInit() {
    if (this.visible && !this._started) {
      // this.itaView.start();
    }
    // portfolioの更新タイミングでMapViewを更新する
    this.cocomero.updateFrame.subscribe(() => {
      this._updateValues();
    });
  }

  ngOnDestroy() {
    if (this._started) {
      this._itaView.stop();
    }
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
  }

  ngAfterViewInit() {
    this._updateCollection();
  }

  saveImage(name?: string) {
    if (!this._treeMap) {
      return;
    }
    const canvas = this._treeMap.getCanvas();
    if (!canvas) {
      return;
    }
    let ret = canvas.toDataURL();
    if (canvas.height < 1 || canvas.width < 1) {
      return;
    }
    if (!ret) {
      return;
    }
    ret = ret.replace('data:image/png;base64,', '');
    const bin = window.atob(ret);
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'image/png',
    });
    saveAs(blob, `stock-map-${name ? name + '-' : ''}${format(new Date(), 'YYYYMMDD_HHmmss')}.png`, { autoBom: false });
  }

  private _updateCollection() {
    if (!this.visible) {
      return;
    }
    if (!this._treeMap) {
      return;
    }

    const stocks: Array<GrouperStock> = [];
    const issueCodeMap = new Set<number>();
    for (let i = 0; i < this._items.length; i++) {
      if (this._items[i].issueCode && this._items[i].info.calcSharesOutstanding) {
        if (!issueCodeMap.has(Number(this._items[i].issueCode))) {
          stocks.push({
            info: this._items[i].info,
            master: this._items[i].master,
            portfolio: this._items[i].portfolio,
          });
          issueCodeMap.add(Number(this._items[i].issueCode));
        }
      }
    }
    const grouped = new IssueGrouper(this.issueTypes.issueTypesForMapView()).createGroup(stocks, this.groupingType, [
      'Value',
      'Value',
      'Value',
    ]);
    this._invisibleUpdate = false;
    const treeMapStocks: Array<[StockElement, TreeMapStock, StockUpdateCache]> = [];
    const treeMapGroups: Array<[GroupElement, TreeMapGroup]> = [];
    this._processGrouped(grouped, treeMapStocks, treeMapGroups);
    let groupingTheme: Pick<TreeMapTheme, 'groupsBorderWidth' | 'groupsBorderColor' | 'borderWidth' | 'borderColor'>;
    if (this.groupingType.length === 0) {
      groupingTheme = {
        borderColor: this._currentTheme.stockBorderColor,
        borderWidth: 1,
        groupsBorderColor: [],
        groupsBorderWidth: [],
      };
    } else if (this.groupingType.length === 1) {
      groupingTheme = {
        borderColor: this._currentTheme.group1BorderColor,
        borderWidth: 1,
        groupsBorderColor: [this._currentTheme.group1BorderColor],
        groupsBorderWidth: [1],
      };
    } else {
      groupingTheme = {
        borderColor: this._currentTheme.group2BorderColor,
        borderWidth: 1,
        groupsBorderColor: [this._currentTheme.group2BorderColor, this._currentTheme.group1BorderColor],
        groupsBorderWidth: [0, 1],
      };
    }
    this._treeMap.initialize(
      treeMapStocks.map(([_, s]) => s),
      treeMapGroups.map(([_, g]) => g),
      {
        font: '"Noto Sans Japanese", sans-serif',
        stockBorderWidth: 1,
        stockBorderColor: this._currentTheme.stockBorderColor,
        backgroundColor: this._currentTheme.backgroundColor,
        ...groupingTheme,
      }
    );
    this._stocks = treeMapStocks;
    this._groups = treeMapGroups;
  }

  private _updateValues() {
    if (!this.visible) {
      this._invisibleUpdate = true;
      return;
    }
    if (!this._treeMap) {
      return;
    }
    for (const [stockElement, treeStock, updateCache] of this._stocks) {
      if (updateCache.predict !== stockElement.portfolio.predict || updateCache.lastPrice10 !== stockElement.portfolio.lastPrice10) {
        const data = this._stockData(stockElement);
        if (treeStock.info !== data.info || treeStock.backgroundColor !== data.backgroundColor) {
          treeStock.info = data.info;
          treeStock.backgroundColor = data.backgroundColor;
          this._treeMap.updateSingleStock(treeStock.key, data);
        }
      }
    }
    for (const [groupElement, treeMapGroup] of this._groups) {
      const data = this._groupData(groupElement);
      if (treeMapGroup.backgroundColor !== data.backgroundColor) {
        treeMapGroup.backgroundColor = data.backgroundColor;
        this._treeMap.updateSingleGroup(treeMapGroup.key, data);
      }
    }
    this._treeMap.draw(false);
  }

  private _stockData(s: GrouperStock): { backgroundColor: string; info: string } {
    const noPrice = s.portfolio.lastPrice10 === 0 || s.portfolio.lastPrice10 === null || s.portfolio.lastPrice10 === undefined;

    const bgColor = this.colorConverter.priceChangeToBarColor(
      noPrice ? undefined : s.portfolio.lastPrice10 / s.master.basePrice10 - 1,
      this._currentThemeColorCache.thresholdUpColor,
      this._currentThemeColorCache.thresholdDownColor,
      this._currentThemeColorCache.upColor,
      this._currentThemeColorCache.downColor,
      this._currentThemeColorCache.zeroColor,
      this._currentThemeColorCache.undefinedColor
    );

    let info = '';
    if (!noPrice) {
      info = ((s.portfolio.lastPrice10 / s.master.basePrice10 - 1) * 100).toFixed(2) + '%';
      if (s.portfolio.lastPrice10 > s.master.basePrice10) {
        info = '+' + info;
      }
      if (s.portfolio.predict) {
        info = `(${info})`;
      }
    }

    return {
      backgroundColor: bgColor.toString(),
      info,
    };
  }

  private _groupData(item: GroupElement): { backgroundColor: string } {
    if (item.children[0].itemType === 'stock') {
      let changesSum = 0;
      let changesCount = 0;
      for (const child of item.children) {
        if (child.itemType === 'stock') {
          const changes = this._calcLastPriceBasePriceChanges(child);
          if (changes !== undefined) {
            changesSum += changes;
            changesCount += 1;
          }
        }
      }
      let groupLastPriceBasePriceChanges: number | undefined;
      if (changesCount > 0) {
        groupLastPriceBasePriceChanges = changesSum / changesCount;
      } else {
        groupLastPriceBasePriceChanges = undefined;
      }
      return {
        backgroundColor: this.colorConverter.priceChangeToBarColor(
          groupLastPriceBasePriceChanges,
          this._currentThemeColorCache.thresholdUpColor,
          this._currentThemeColorCache.thresholdDownColor,
          this._currentThemeColorCache.upColor,
          this._currentThemeColorCache.downColor,
          this._currentThemeColorCache.zeroColor,
          this._currentThemeColorCache.undefinedColor
        ),
      };
    }
    return {
      backgroundColor: this._currentTheme.backgroundColor,
    };
  }

  private _processGrouped(
    items: Array<GroupingElement>,
    stocks: Array<[StockElement, TreeMapStock, StockUpdateCache]>,
    groups: Array<[GroupElement, TreeMapGroup]>
  ) {
    for (const item of items) {
      if (item.itemType === 'stock') {
        const key = `T${item.master.issueCode}`;
        const s: TreeMapStock = {
          elementType: ElementType.Stock,
          key,
          name: item.master.name,
          weight: item.value,
          parentsKey: item.parent.reduce((a, g) => a.concat(a.map((b) => b + ':').join('') + `${g.groupType}:${g.name}`), []),
          ...this._stockData(item),
        };
        stocks.push([item, s, { predict: item.portfolio.predict, lastPrice10: item.portfolio.lastPrice10 }]);
      } else {
        const key = item.parent.map((g) => `${g.groupType}:${g.name}:`).join() + `${item.groupType}:${item.name}`;
        const level = this.groupingType.length - item.parent.length - 1;

        const group: TreeMapGroup = {
          elementType: ElementType.Group,
          key,
          name: item.name,
          parentsKey: item.parent.map((a) => `${a.groupType}:${a.name}`),
          childElementType: item.children[0].itemType === 'stock' ? ElementType.Stock : ElementType.Group,
          children: item.children.map((a) => (a.itemType === 'stock' ? `T${a.master.issueCode}` : `${key}:${a.groupType}:${a.name}`)),
          padding: 2,
          fontSize: 12,
          titleHeight: 15,
          titleMinSizeWidth: 60,
          titleMinSizeHeight: 36,
          ...this._groupData(item),
        };
        groups.push([item, group]);
        this._processGrouped(item.children, stocks, groups);
      }
    }
  }

  private _getStockFromKey(key: string): StockElement | undefined {
    for (const [sd, t] of this._stocks) {
      if (t.key === key) {
        return sd;
      }
    }
    return undefined;
  }

  onClick(event: MouseEventWithElement) {
    if (event.element && event.element.elementType === ElementType.Stock) {
      const stock = this._getStockFromKey(event.element.key);
      if (stock) {
        this.cocomero.changeIssueCode(stock.master.issueCode);
      }
    }
  }

  onDblClick(event: MouseEventWithElement) {
    if (event.element && event.element.elementType === ElementType.Stock) {
      this.stockDblClick.emit();
    }
  }

  onMouseEnter(event: MouseEventWithElement) {
    this._showOrUpdateTooltip(event);
  }

  onMouseMove(event: MouseEventWithElement) {
    this._ngZone.runGuarded(() => {
      this._showOrUpdateTooltip(event);
    });
  }

  onMouseLeave() {
    this._cleanupTooltip();
  }

  private _showOrUpdateTooltip(event: MouseEventWithElement) {
    if (event.element && event.element.elementType === ElementType.Stock) {
      const stock = this._getStockFromKey(event.element.key);

      this._tooltip.updateIssueCode(stock.master.issueCode);
      const groupNames = stock.parent.map((g) => g.name);

      if (groupNames.length > 0) {
        this._tooltip.updateGroupName(groupNames.join(' / '));
      } else {
        this._tooltip.updateGroupName(undefined);
      }
      if (this._tooltipState) {
        this._tooltipState = {
          ...this._tooltipState,
          mouseX: event.original.pageX,
          mouseY: event.original.pageY,
        };
        this._updateTooltipPosition();
      } else {
        const pos = new GlobalPositionStrategy();
        const overlayRef = this._overlay.create({
          positionStrategy: pos,
          width: '48rem',
          height: '16rem',
          minWidth: '32rem',
          minHeight: '16rem',
        });
        const userProfilePortal = new ComponentPortal(MapViewTooltipComponent, null, this._injector);
        const componentRef = overlayRef.attach(userProfilePortal);
        this._tooltipState = {
          mouseX: event.original.pageX,
          mouseY: event.original.pageY,
          overlayWidth: (componentRef.location.nativeElement as HTMLElement).offsetWidth,
          overlayHeight: (componentRef.location.nativeElement as HTMLElement).offsetHeight,
          overlayRef,
          componentRef,
          positionStrategy: pos,
        };
        this._updateTooltipPosition();
      }
    } else {
      this._cleanupTooltip();
    }
  }

  private _updateTooltipPosition() {
    if (!this._tooltipState) {
      return;
    }
    // left
    if (
      this._tooltipState.overlayWidth / 2 <= this._tooltipState.mouseX &&
      this._tooltipState.mouseX + this._tooltipState.overlayWidth / 2 <= window.innerWidth
    ) {
      this._tooltipState.positionStrategy.left(`${this._tooltipState.mouseX - this._tooltipState.overlayWidth / 2}px`);
    } else if (this._tooltipState.mouseX < this._tooltipState.overlayWidth / 2) {
      this._tooltipState.positionStrategy.left('0px');
    } else {
      // this._tooltipState.mouseX + this._tooltipState.overlayWidth / 2 > window.innerWidth
      this._tooltipState.positionStrategy.left(`${window.innerWidth - this._tooltipState.overlayWidth}px`);
    }
    const yOffset = 70;
    // top
    if (this._tooltipState.mouseY - yOffset - this._tooltipState.overlayHeight >= 0) {
      this._tooltipState.positionStrategy.top(`${this._tooltipState.mouseY - this._tooltipState.overlayHeight - yOffset}px`);
    } else {
      this._tooltipState.positionStrategy.top(`${this._tooltipState.mouseY + yOffset}px`);
    }
    this._tooltipState.positionStrategy.apply();
  }

  private _cleanupTooltip() {
    if (!this._tooltipState) {
      return;
    }
    this._tooltipState.overlayRef.detach();
    this._tooltipState = undefined;
  }

  private _calcLastPriceBasePriceChanges(s: GrouperStock): number | undefined {
    if (s.portfolio.lastPrice10 === 0 || s.portfolio.lastPrice10 === null || s.portfolio.lastPrice10 === undefined) {
      return undefined;
    }
    return s.portfolio.lastPrice10 / s.master.basePrice10 - 1;
  }
}
