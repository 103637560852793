import { Injectable } from '@angular/core';
import { JSFCEntry } from '@argentumcode/brisk-common';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JsfcService {
  constructor(private api: ApiService) {}

  private cache = new Map<number, Array<JSFCEntry>>();

  public getJSFC(issueCode: number, days: number = 365): Observable<[number, Array<JSFCEntry>]> {
    const cacheKey = issueCode * 10000 + days;
    if (days > 10000) {
      // CacheKeyがおかしくなるため
      throw new Error('Too many days');
    }
    if (this.cache.has(cacheKey)) {
      // return of(this.cache.get(cacheKey));
    }
    return new Observable((observer) => {
      const ret = [];
      this.api.jsfc(issueCode, days).subscribe(
        (res) => {
          for (const date of Object.keys(res).sort().reverse()) {
            const entry = new JSFCEntry();
            Object.assign(entry, res[date]);
            ret.push(entry);
          }
          this.cache.set(cacheKey, ret);
          observer.next([issueCode, ret]);
        },
        (err) => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    });
  }
}
