import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fita4-order-dialogs-per-window',
  templateUrl: './order-dialogs-per-window.component.html',
  styleUrls: ['./order-dialogs-per-window.component.scss'],
})
export class OrderDialogsPerWindowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
