import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'brisk-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input()
  checked = false;

  @Output()
  checkedChange = new EventEmitter();

  @Input()
  disabled = false;

  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  registerOnChange(fn: any): void {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.checked = !!obj;
    this.changeDetectorRef.markForCheck();
  }

  onChangeInput(chk: HTMLInputElement) {
    this.checked = chk.checked;
    this.checkedChange.emit(this.checked);
    this._controlValueAccessorChangeFn(chk.checked);
  }
}
