<div class="vg-grid-condition-filter-title">
  抽出条件の設定
</div>
<div>
  <div class="form-group form-special-value" *ngIf="specialValueFilterName !== undefined">
    <div>{{ specialValueFilterName }}:</div>
    <wj-combo-box
      class="form-control"
      [itemsSource]="specialValueFilterTypes"
      [(ngModel)]="specialValueFilterType"
      displayMemberPath="name"
      selectedValuePath="value"
    ></wj-combo-box>
  </div>
  <div class="form-group">
    <wj-combo-box
      [isDisabled]="specialValueFilterType === 'Only'"
      class="form-control"
      [itemsSource]="conditionEnum"
      [(ngModel)]="conditionType1"
      displayMemberPath="name"
      selectedValuePath="value"
    ></wj-combo-box>
  </div>
  <div class="form-group">
    <ng-container [ngSwitch]="valueType">
      <wj-input-number
        [isDisabled]="specialValueFilterType === 'Only'"
        *ngSwitchCase="'Number'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue1"
        [format]="'n' + roundPrecision"
      >
      </wj-input-number>
      <wj-input-date
        *ngSwitchCase="'Date'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue1"
        [format]="'yyyy-MM-dd'"
        [isDisabled]="specialValueFilterType === 'Only'"
      >
      </wj-input-date>
      <wj-input-date-time
        [isDisabled]="specialValueFilterType === 'Only'"
        *ngSwitchCase="'DateTime'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue1"
        [timeFormat]="'HH:mm:ss'"
        [format]="'yyyy-MM-dd HH:mm:ss'"
      >
      </wj-input-date-time>
    </ng-container>
  </div>
  <div class="form-group vg-grid-condition-filter-checkbox">
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="combinationType"
        id="combinationTypeAnd"
        value="and"
        [(ngModel)]="combination"
        [disabled]="specialValueFilterType === 'Only'"
      />
      <label class="form-check-label" for="combinationTypeAnd" [attr.disabled]="specialValueFilterType === 'Only' || null">AND</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="combinationType"
        id="combinationTypeOr"
        value="or"
        [(ngModel)]="combination"
        [disabled]="specialValueFilterType === 'Only'"
      />
      <label class="form-check-label" for="combinationTypeOr" [attr.disabled]="specialValueFilterType === 'Only' || null">OR</label>
    </div>
  </div>
  <div class="form-group">
    <wj-combo-box
      class="form-control"
      [itemsSource]="conditionEnum"
      [(ngModel)]="conditionType2"
      displayMemberPath="name"
      selectedValuePath="value"
      [isDisabled]="specialValueFilterType === 'Only'"
    ></wj-combo-box>
  </div>
  <div class="form-group">
    <ng-container [ngSwitch]="valueType">
      <wj-input-number
        *ngSwitchCase="'Number'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue2"
        [format]="'n' + roundPrecision"
        [isDisabled]="specialValueFilterType === 'Only'"
      >
      </wj-input-number>
      <wj-input-date
        *ngSwitchCase="'Date'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue2"
        [format]="'yyyy-MM-dd'"
        [isDisabled]="specialValueFilterType === 'Only'"
      >
      </wj-input-date>
      <wj-input-date-time
        *ngSwitchCase="'DateTime'"
        class="form-control"
        [isRequired]="false"
        [(ngModel)]="conditionValue2"
        [timeFormat]="'HH:mm:ss'"
        [format]="'yyyy-MM-dd HH:mm:ss'"
        [isDisabled]="specialValueFilterType === 'Only'"
      >
      </wj-input-date-time>
    </ng-container>
  </div>
  <div class="vg-grid-condition-filter-actions">
    <button class="c-btn c-btn--default vg-grid-condition-filter-btn" type="button" (click)="applyFilter()">適用</button>
    <button class="c-btn c-btn--default vg-grid-condition-filter-btn" type="button" (click)="cancel.emit()">キャンセル</button>
    <button class="c-btn c-btn--default vg-grid-condition-filter-btn" type="button" (click)="clearFilter()">クリア</button>
  </div>
</div>
