<brisk-ita
  #ita
  [alignRight]="true"
  [fillStopPrice]="true"
  [forceSeparateComma]="true"
  [showScrollbar]="true"
  [peg]="false"
  [shown]="true"
  [enableDblClickTutorial]="false"
  [enableScrollTutorial]="false"
  [(normalized)]="normalized"
>
</brisk-ita>
