import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CocomeroService } from '../cocomero.service';
import { Popup } from '@grapecity/wijmo.input';
import { LocalStorageService, UserAgentService } from '@argentumcode/brisk-common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-unrecommended-browser-message',
  templateUrl: './unrecommended-browser-message.component.html',
  styleUrls: ['./unrecommended-browser-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnrecommendedBrowserMessageComponent implements OnInit, AfterViewInit {
  public _osMessage = false;
  public doNotShowMessageAgain = false;

  private _show = false;

  @ViewChild('popup', { static: true })
  public popup: Popup;

  public isIE: boolean;

  constructor(private cocomero: CocomeroService, userAgent: UserAgentService, private localStorage: LocalStorageService) {
    this.isIE = userAgent.IE;

    this.cocomero.initializedSubject
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        if (this._osMessage && this._show) {
          this.show();
        }
      });
  }

  ngOnInit() {
    if (!this.cocomero.checkWin7()) {
      if (this.localStorage.getItemRaw('doNotShowUnrecommendedMessageWin7')) {
        return;
      }
      this._osMessage = true;
      this._show = true;
    } else if (!this.cocomero.checkBrowser()) {
      this._osMessage = false;
      this._show = true;
    }
  }

  ngAfterViewInit() {
    if (this._show && !this._osMessage) {
      this.show();
    }
  }

  show() {
    this.popup.show();
  }

  close() {
    if (this._osMessage) {
      if (this.doNotShowMessageAgain) {
        this.localStorage.setItemRaw('doNotShowUnrecommendedMessageWin7', '1');
      }
    }
    this.popup.hide();
  }
}
