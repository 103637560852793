import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ColorConverterService, ThemeService } from '@argentumcode/brisk-common';
import { Color, EventArgs, ObservableArray } from '@grapecity/wijmo';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/default/environment';

const OHLC_LENGTH = environment.flexConfig.ohlcLength;

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarchartComponent implements AfterViewInit, OnDestroy {
  static currentTheme: string = null;
  static thresholdUpColor: Color;
  static thresholdDownColor: Color;
  static upColor: Color;
  static downColor: Color;
  static zeroColor: Color;
  static undefinedColor: Color;
  readonly OHLC_LENGTH = OHLC_LENGTH;

  private sourceData: ObservableArray = null;
  private lastData: Array<number> = null;

  private themeSubscription: Subscription = null;

  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('themeElement', { static: true }) themeElement: ElementRef;

  @Input()
  public get source(): ObservableArray {
    return this.sourceData;
  }

  public set source(value: ObservableArray) {
    if (this.sourceData === value) {
      return;
    }
    if (this.sourceData !== null && this.sourceData !== undefined && this.sourceData instanceof ObservableArray) {
      this.sourceData.collectionChanged.removeHandler(this.sourceChanged, this);
    }
    this.lastData = null;
    this.sourceData = null;
    this.sourceData = value;
    if (value !== null && value !== undefined && value instanceof ObservableArray) {
      this.sourceData.collectionChanged.addHandler(this.sourceChanged, this);
      this.clear();
      this.draw();
    } else {
      this.clear();
    }
  }

  sourceChanged(sender: ObservableArray, args: EventArgs) {
    this.draw();
  }

  constructor(private colorConverter: ColorConverterService, private theme: ThemeService) {
    this.themeSubscription = this.theme.theme$.subscribe(() => {
      this.onThemeUpdate();
    });
  }

  ngAfterViewInit() {
    this.onThemeUpdate();
    this.draw();
  }

  clear() {
    if (!BarchartComponent.thresholdUpColor) {
      return;
    }
    const context = this.canvas.nativeElement.getContext('2d');
    context.fillStyle = this.colorConverter.priceChangeToBarColor(
      undefined,
      BarchartComponent.thresholdUpColor,
      BarchartComponent.thresholdDownColor,
      BarchartComponent.upColor,
      BarchartComponent.downColor,
      BarchartComponent.zeroColor,
      BarchartComponent.undefinedColor
    );
    this.lastData = null;
    context.fillRect(0, 0, OHLC_LENGTH, 1);
  }

  draw() {
    if (!BarchartComponent.thresholdUpColor) {
      return;
    }
    const context = this.canvas.nativeElement.getContext('2d');

    if (!this.lastData) {
      this.lastData = new Array<number>(OHLC_LENGTH);
      for (let x = 0; x < OHLC_LENGTH; x++) {
        this.lastData[x] = undefined;
      }
    }
    for (let x = 0; x < OHLC_LENGTH; x++) {
      const cur = !this.source ? null : this.source[x];
      if (this.lastData[x] !== cur) {
        context.fillStyle = this.colorConverter.priceChangeToBarColor(
          cur,
          BarchartComponent.thresholdUpColor,
          BarchartComponent.thresholdDownColor,
          BarchartComponent.upColor,
          BarchartComponent.downColor,
          BarchartComponent.zeroColor,
          BarchartComponent.undefinedColor
        );
        context.fillRect(x, 0, 1, 1);
        this.lastData[x] = cur;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
      this.themeSubscription = null;
    }
  }

  onThemeUpdate() {
    if (this.theme.theme !== BarchartComponent.currentTheme) {
      BarchartComponent.thresholdUpColor = new Color(this._getThemeColor('p-bar-chart-threshold-up-color', 'bar-chart-threshold-up-color'));
      BarchartComponent.thresholdDownColor = new Color(
        this._getThemeColor('p-bar-chart-threshold-down-color', 'bar-chart-threshold-down-color')
      );
      BarchartComponent.upColor = new Color(this._getThemeColor('p-bar-chart-up-color', 'bar-chart-up-color'));
      BarchartComponent.downColor = new Color(this._getThemeColor('p-bar-chart-down-color', 'bar-chart-down-color'));
      BarchartComponent.zeroColor = new Color(this._getThemeColor('p-bar-chart-zero-color', 'bar-chart-zero-color'));
      BarchartComponent.undefinedColor = new Color(this._getThemeColor('p-bar-chart-undefined-color', 'bar-chart-undefined-color'));
      BarchartComponent.currentTheme = this.theme.theme;
    }
    this.clear();
    this.draw();
  }

  private _getThemeColor(className: string, colorName: string): string {
    let color = this.theme.getStyleValue(colorName);
    if (color) {
      return color;
    }
    const span: HTMLSpanElement = this.themeElement.nativeElement;
    span.className = `${className} d-none`;
    color = getComputedStyle(span).color;
    if (!color) {
      console.log('failed to get color');
      return '';
    }
    return color;
  }
}
