import { FlexConfig, StockView } from '../flex';
import { Observable } from 'rxjs';
import { StockController } from './stock-controller';

export class StockWrapper {
  constructor(
    public readonly flexConfig: Readonly<FlexConfig>,
    public readonly updated: Observable<{}>,
    public readonly base: StockView = null,
    public readonly date: Date = null,
    private controller: StockController = null
  ) {
    this.current = base;
  }

  /**
   * 次のフレームで描画の更新を強制的に行うかどうか
   *
   * Trueの時、次のフレームで描画の更新を強制的に。データの取得を強制的に行うわけではない。
   * */
  update: boolean;

  /**
   * 現在表示中の銘柄ビュー
   */
  current: StockView;

  /**
   * 表示上の最大行数
   */
  viewMaxItaRow: number;

  /**
   * 現在リアルタイム情報を表示しているかどうか
   */
  get live(): boolean {
    return this.current === this.base;
  }

  set live(val: boolean) {
    if (val === this.live) {
      return;
    }
    if (val) {
      this.controller.live(this);
    } else {
      this.controller.createSnapshot(this);
    }
  }

  goUpdateNumber(updateNumber: number) {
    this.controller.goUpdateNumber(this.current, updateNumber);
  }

  dispose() {
    this.controller = null;
  }
}
