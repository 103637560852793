import { Injectable } from '@angular/core';
import { LocalStorageService } from '@argentumcode/brisk-common';
import { lt, valid } from 'semver';
import { StorageService, TutorialState } from './storage.service';
import { StockListViewMode } from '../portfolio/summary-list.service';
import { WatchListService } from './watch-list.service';

declare const VERSION: string;
const currentVersion = VERSION;

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  public version: string;

  constructor(private localStorage: LocalStorageService, private storage: StorageService, private watchList: WatchListService) {}

  initialize() {
    this.version = valid(this.localStorage.getItem('version'));
    if (!this.version) {
      if (this.localStorage.getItem('histories')) {
        this.version = '1.0.0';
      } else {
        this.version = currentVersion;
        this.setupCurrentVersion();
      }
      this.localStorage.setItem('version', this.version);
    }
    if (this.storage.tutorialState === null) {
      this.storage.tutorialState = TutorialState.ExistingUser;
    }

    this.checkUpdate();
  }

  private checkUpdate() {
    if (lt(this.version, '1.1.0')) {
      // v1.0.X -> v.1.1.0
      // 初期状態で詳細表示とする
      this.storage.viewMode = StockListViewMode.Detail;
      // 業種の非表示項目のクリア
      for (const id of this.storage.invisibleSummary.filter((a) => a.startsWith('industry:'))) {
        this.storage.removeInvisibleSummary(id);
      }
      // 業種の非表示項目の設定
      this.setupInvisibleSmartSummary();

      this.updateVersion('1.1.0');
    }
    if (lt(this.version, '2.3.0')) {
      const tutorialBackup = this.storage.tutorialBackup;
      if (tutorialBackup) {
        if ('watchLists' in JSON.parse(tutorialBackup)) {
          const backupWatchLists = JSON.parse(tutorialBackup)['watchLists'];
          const [merged, _] = this.watchList.merge(
            this.watchList.deserialize(backupWatchLists),
            this.watchList.deserialize(this.localStorage.getItem('watchLists'))
          );
          this.localStorage.setItem('watchLists', this.watchList.serialize(merged));
        }
      }
    }
    this.updateVersion(currentVersion);
  }

  private setupCurrentVersion() {
    this.setupInvisibleSmartSummary();
    this.storage.tutorialState = TutorialState.NewUser;
  }

  private setupInvisibleSmartSummary() {
    const ids = [
      'smartlist:lastPriceBasePriceChangeTop',
      'smartlist:lastPriceBasePriceChangeBottom',
      'smartlist:vwapTop',
      'smartlist:vwapBottom',
      'smartlist:openPriceBasePriceChangeTop',
      'smartlist:openPriceBasePriceChangeBottom',
    ];
    const sz = ['Small', 'Medium', 'Large'];
    for (const id of ids) {
      for (const size of sz) {
        this.storage.addInvisibleSummary(id + size);
      }
    }
  }

  private updateVersion(version: string) {
    this.version = version;
    this.localStorage.setItem('version', version);
  }
}
