import squarify from 'squarify';

export interface Padding {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface Position {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
}

export interface LayoutOption<T, D> {
  getPadding(item: T, pos: Position, region: Position): [Padding, D];
}

export interface ItemLeaf<T> {
  item: T;
  value: number;
}

export interface ItemNode<T> {
  item: T;
  children: Array<ItemLeaf<T> | ItemNode<T>>;
}

export type Item<T> = ItemLeaf<T> | ItemNode<T>;

export interface Result<T, D = unknown> {
  item: T;
  pos: Position;
  layoutData: D;
  innerPos: Position;
  children?: Array<Result<T, D>>;
}

export interface LayoutEngine {
  layout<T, D>(item: Array<Item<T>>, x: number, y: number, width: number, height: number, option: LayoutOption<T, D>): Array<Result<T, D>>;
}

export class SquarifyLayoutEngine implements LayoutEngine {
  private static sum<T>(item: Item<T>, cache: WeakMap<Item<T>, number>): number {
    if ('value' in item) {
      return item.value;
    }
    const cached = cache.get(item);
    if (cached !== undefined) {
      return cached;
    }
    let result = 0;
    for (const c of item.children) {
      result += SquarifyLayoutEngine.sum(c, cache);
    }
    cache.set(item, result);
    return result;
  }

  private static layoutRecursive<T, D>(
    item: Array<Item<T>>,
    pos: Position,
    option: LayoutOption<T, D>,
    sumCache: WeakMap<Item<T>, number>
  ): Array<Result<T, D>> {
    const { getPadding } = option;
    const sItems: Array<{ value: number }> = [];
    for (const v of item) {
      sItems.push({ value: SquarifyLayoutEngine.sum(v, sumCache) });
    }
    const positionResult = squarify(sItems, pos);
    for (let i = 0; i < positionResult.length; i++) {
      positionResult[i].x0 = Math.round(positionResult[i].x0);
      positionResult[i].y0 = Math.round(positionResult[i].y0);
      positionResult[i].x1 = Math.round(positionResult[i].x1);
      positionResult[i].y1 = Math.round(positionResult[i].y1);
    }
    const ret: Array<Result<T, D>> = [];
    for (let i = 0; i < positionResult.length; i++) {
      const v = item[i];
      const [padding, data] = getPadding(v.item, positionResult[i], pos);
      const childPos = {
        x0: positionResult[i].x0 + padding.left,
        y0: positionResult[i].y0 + padding.top,
        x1: positionResult[i].x1 - padding.right,
        y1: positionResult[i].y1 - padding.bottom,
      };

      if ('children' in v) {
        ret.push({
          item: v.item,
          pos: {
            x0: positionResult[i].x0,
            x1: positionResult[i].x1,
            y0: positionResult[i].y0,
            y1: positionResult[i].y1,
          },
          innerPos: childPos,
          layoutData: data,
          children: SquarifyLayoutEngine.layoutRecursive(v.children, childPos, option, sumCache),
        });
      } else {
        ret.push({
          item: v.item,
          innerPos: childPos,
          layoutData: data,
          pos: {
            x0: positionResult[i].x0,
            x1: positionResult[i].x1,
            y0: positionResult[i].y0,
            y1: positionResult[i].y1,
          },
        });
      }
    }
    return ret;
  }

  layout<T, D>(item: Array<Item<T>>, x: number, y: number, width: number, height: number, option: LayoutOption<T, D>): Array<Result<T, D>> {
    return SquarifyLayoutEngine.layoutRecursive(
      item,
      { x0: x, y0: y, x1: x + width, y1: y + height },
      {
        ...option,
      },
      new WeakMap()
    );
  }
}
