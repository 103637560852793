import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { SecondaryWindowComponent } from './secondary-window.component';

import * as $ from 'jquery';
import { GoldenLayoutModule } from '@argentumcode/ngx-golden-layout';
import { PortfolioModule } from 'fita3/src/app/portfolio/portfolio.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'fita3/src/app/shared/shared.module';
import {
  BriskBrowserModule,
  ChartModule,
  ErrorHandlerService,
  FrontendApiService,
  HeaderModule,
  IconsModule,
  IndividualDetailModule,
  IssueCodeInputService,
  ItaCaptureModule,
  ItaModule,
  LocalStorageService,
  MarketsModule,
  QrGridModule,
  ToasterModule,
  ToasterService,
  ViewHistoryService,
  BriskDialogModule,
  DISALLOW_MULTIPLE_TABS_KEY,
} from '@argentumcode/brisk-common';
import { CoreModule } from 'fita3/src/app/core/core.module';
import { FixModule } from 'fita3/src/app/fix/fix.module';
import { NgbDropdownModule, NgbPopoverConfig, NgbPopoverModule, NgbTabsetModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TutorialModule } from 'fita3/src/app/tutorial/tutorial.module';
import { ViewHistoryService as Fita4ViewHistoryService } from '../fita4-core/view-history.service';
window['$'] = $;

import { WjCoreModule } from '@grapecity/wijmo.angular2.core';
import { FIXService } from 'fita3/src/app/fix/fix.service';
import { FixItaService } from 'fita3/src/app/fix/fix-ita.service';
import { SimpleOrderService } from 'fita3/src/app/core/simple-order.service';
import { simpleOrderService } from 'fita3/src/environments/default/environment';
import { SummaryListPanelComponent } from '../panels/summary-list-panel/summary-list-panel.component';
import { ItaPanelComponent } from '../panels/ita-panel/ita-panel.component';
import { StockDetailPanelComponent } from '../panels/stock-detail-panel/stock-detail-panel.component';
import { QrPanelComponent } from '../panels/qr-panel/qr-panel.component';
import { Ohlc5minPanelComponent } from '../panels/ohlc5min-panel/ohlc5min-panel.component';
import { JsfcPanelComponent } from '../panels/jsfc-panel/jsfc-panel.component';
import { ChartPanelComponent } from '../panels/chart-panel/chart-panel.component';
import { StockListPanelComponent } from '../panels/stock-list-panel/stock-list-panel.component';
import { StockListGridPanelComponent } from '../panels/stock-list-grid-panel/stock-list-grid-panel.component';
import { ItaGridPanelComponent } from '../panels/ita-grid/ita-grid-panel.component';
import { ChartGridPanelComponent } from '../panels/chart-grid-panel/chart-grid-panel.component';
import { SummaryListsService } from '../summary-lists.service';
import { SummaryListService } from 'fita3/src/app/portfolio/summary-list.service';
import { ItaViewHeaderComponent } from '../headers/ita-view-header/ita-view-header.component';
import { ChartViewHeaderComponent } from '../headers/chart-view-header/chart-view-header.component';
import { fromParentWindow, MultipleWindowModule } from '@argentumcode/multiple-window';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { ApiService } from 'fita3/src/app/core/api.service';
import { ReloadService } from 'fita3/src/app/core/reload.service';
import { SaveItemHeaderComponent } from '../headers/save-item-header/save-item-header.component';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { ORDER_OPERATOR_TOKEN } from '../order/types/interfaces';
import { environment } from '../../environments/default/environment';
import { FontSizeConfig } from '../font-size.service';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ExternalWebsitePanelComponent } from '../panels/external-website-panel/external-website-panel.component';
import { PersistentStateService } from '../fita4-core/persistent-state.service';
import { Fita4StorageService } from '../fita4-core/fita4-storage.service';
import { StorageService } from 'fita3/src/app/core/storage.service';
import { VersionService } from 'fita3/src/app/core/version.service';
import { Fita4VersionService } from '../fita4-core/fita4-version.service';
import { Fita4Service } from '../fita4-core/fita4.service';
import { MarketsPanelComponent } from '../panels/markets-panel/markets-panel.component';
import { SubWindowErrorHandlerService } from '../sub-window-error-handler.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContextMenuModule } from '@argentumcode/ngx-contextmenu';
import { MapViewPanelComponent } from '../panels/map-view-panel/map-view-panel.component';
import { MapViewHeaderComponent } from '../headers/map-view-header/map-view-header.component';
import { StockDetailStackHeaderComponent } from '../headers/stock-detail-stack-header/stock-detail-stack-header.component';

const popoverConfig = new NgbPopoverConfig();
popoverConfig.autoClose = false;
popoverConfig.popoverClass = 'c-assist-popover c-assist-popover--default';

const tooltipConfig = new NgbTooltipConfig();
tooltipConfig.openDelay = 100;
tooltipConfig.container = 'body';
tooltipConfig.tooltipClass = 'c-tooltip';
tooltipConfig.triggers = 'hover';
@NgModule({
  declarations: [
    SecondaryWindowComponent,
    SummaryListPanelComponent,
    ItaPanelComponent,
    StockDetailPanelComponent,
    QrPanelComponent,
    Ohlc5minPanelComponent,
    JsfcPanelComponent,
    ChartPanelComponent,
    StockListPanelComponent,
    StockListGridPanelComponent,
    ItaGridPanelComponent,
    ChartGridPanelComponent,
    ItaViewHeaderComponent,
    ChartViewHeaderComponent,
    SaveItemHeaderComponent,
    StockDetailStackHeaderComponent,
    ExternalWebsitePanelComponent,
    MarketsPanelComponent,
    MapViewPanelComponent,
    MapViewHeaderComponent,
  ],
  imports: [
    BrowserModule,
    GoldenLayoutModule.forRoot([]),
    BrowserAnimationsModule,
    SharedModule,
    BriskBrowserModule,
    CoreModule,
    IndividualDetailModule,
    FixModule,
    ChartModule,
    MarketsModule,
    ToasterModule,
    PortfolioModule,
    ItaModule,
    HeaderModule,
    QrGridModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTabsetModule,
    TutorialModule,
    IconsModule,
    WjCoreModule,
    ItaCaptureModule,
    PortfolioModule,
    MultipleWindowModule.forChildrenWindow(),
    SharedComponentsModule,
    BriskDialogModule.forMultipleWindowChildren(),
    environment.orderUiModule,
    ...environment.tradeModules,
    OverlayModule,
    ContextMenuModule.forRoot({
      useBackdrop: true,
      useBootstrap4: true,
      backdropClass: '',
      autoFocus: true,
    }),
  ],
  providers: [
    { provide: FIXService, useValue: null },
    { provide: FixItaService, useValue: null },
    { provide: NgbPopoverConfig, useValue: popoverConfig },
    { provide: NgbTooltipConfig, useValue: tooltipConfig },
    { provide: SimpleOrderService, useClass: simpleOrderService },
    { provide: SummaryListService, useExisting: SummaryListsService },
    { provide: FontSizeConfig, useValue: 'fullItaWindow' },
    { provide: ViewHistoryService, useClass: Fita4ViewHistoryService },
    { provide: StorageService, useClass: Fita4StorageService },
    { provide: VersionService, useClass: Fita4VersionService },
    { provide: ErrorHandler, useClass: SubWindowErrorHandlerService },
    {
      provide: DISALLOW_MULTIPLE_TABS_KEY,
      useValue: 'secondary_window_tab',
    },
    SummaryListsService,
    fromParentWindow(CocomeroService, 'CocomeroService'),
    fromParentWindow(FrontendApiService, 'FrontendApiService'),
    fromParentWindow(ApiService, 'ApiService'),
    fromParentWindow(IssueCodeInputService, 'IssueCodeInputService'),
    fromParentWindow(ErrorHandlerService, 'ErrorHandlerService'),
    fromParentWindow(LocalStorageService, 'LocalStorageService'),
    fromParentWindow(ReloadService, 'ReloadService'),
    fromParentWindow(ChartService, 'ChartService'),
    fromParentWindow(ORDER_OPERATOR_TOKEN, 'OrderOperator'),
    fromParentWindow(ToasterService, 'ToasterService'),
    fromParentWindow(PersistentStateService, 'PersistentStateService', { convertObservable: true }),
    fromParentWindow(Fita4Service, 'Fita4Service', { convertObservable: true }),
    GoldenLayoutModule.forChild([
      {
        name: 'SummaryList',
        type: SummaryListPanelComponent,
      },
      {
        name: 'StockDetail',
        type: StockDetailPanelComponent,
      },
      {
        name: 'StockList',
        type: StockListPanelComponent,
      },
      ...environment.tradePanels,
    ]),
    ...Object.keys(environment.sharedServices).map((s) => fromParentWindow(environment.sharedServices[s], s)),
  ],
  bootstrap: [SecondaryWindowComponent],
})
export class SecondaryWindowModule {}
