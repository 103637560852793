<div id="summaryPortfolioWrapper" [ngClass]="viewMode !== ViewMode.DetailWide ? 'hide-summary' : ''">
  <div id="summaryHeaderLine" [class.summary-header-line--normal-mode]="viewMode === ViewMode.Normal">
    <div class="d-inline-block" ngbDropdown [container]="'body'">
      <button ngbDropdownToggle class="c-btn c-btn--default add-watchlist-btn dropdown-toggle" id="dropdownMenu">
        銘柄リスト追加など
      </button>
      <div class="c-dropdown-popup" ngbDropdownMenu aria-labelledby="dropdownMenu">
        <button class="dropdown-item" (click)="addNewIndex()">新規作成</button>
        <button class="dropdown-item" (click)="importCSV()">CSVインポート</button>
        <button class="dropdown-item" (click)="exportCSV()">CSVエクスポート</button>
        <button class="dropdown-item" (click)="showIndexClicked()">銘柄リストの再表示</button>
      </div>
    </div>
    <button *ngIf="viewMode === ViewMode.DetailWide" class="btn hide-btn shadow-none" (click)="hideSummaryList()">
      <span class="wj-glyph-left"></span>
    </button>
    <button *ngIf="viewMode === ViewMode.Detail" class="btn show-btn shadow-none" (click)="updateShowSummaryList()">
      <span class="wj-glyph-right"></span>
    </button>
  </div>

  <div id="summaryPortfolio" #summaryPortfolioContainer>
    <app-portfolio
      #summaryPortfolio
      (cellClick)="showTutorial()"
      [dataSource]="summaryDataView"
      (gridPaste)="paste($event)"
      (cellCtrlClick)="onCellCtrlClick($event)"
      (cellEditBeginning)="onCellEditBeginning($event)"
      (nameUpdated)="onNameUpdated($event)"
      [allowDelete]="!tutorialMode && true"
      [showPrefix]="false"
      nameHeader="銘柄リスト"
      [showIssueCode]="false"
      [showIndex]="false"
      [showVolume]="false"
      [showIndustry]="false"
      [nameEditable]="true"
      [showIssueCount]="true"
      [showHitLiftBar]="true"
      [shortVolume]="true"
      [showLastPriceBasePriceChanges]="true"
      [visibleLastPriceBasePriceChanges]="viewMode === ViewMode.DetailWide"
      [showTurnover10]="true"
      [visibleTurnover10]="viewMode === ViewMode.DetailWide"
      [showVwap]="true"
      [visibleVwap]="viewMode === ViewMode.DetailWide"
      [groupSort]="true"
      (selectionChanged)="onSelectionChanged($event)"
      [sortDescriptorIndex]="1"
      (rowDeleting)="onRowDeleting($event)"
    ></app-portfolio>

    <ng-container *ngIf="viewMode === ViewMode.DetailWide || viewMode === ViewMode.Normal">
      <ng-template briskAssist="summary-list-copy-paste" #assistPaste="briskAssist">
        <button
          type="button"
          class="c-assist-popover-button p-assist-copy-paste"
          placement="top"
          [ngbPopover]="popContent"
          [container]="viewMode === ViewMode.Normal ? 'body' : undefined"
          [ngClass]="viewMode === ViewMode.Normal ? 'u-normal-mode' : ''"
          popoverClass="c-assist-popover c-assist-popover--default c-assist-popover--copy-paste"
        ></button>

        <ng-template #popContent>
          <div class="c-assist-popover__description">
            <p class="c-assist-popover__text">
              コピー＆ペーストで銘柄リストを楽々追加<br />
              （スマートコピペ）<br />
              右クリックまたはDeleteキーで不要な行を削除
            </p>
          </div>
          <div class="c-assist-popover__buttons">
            <a class="c-assist-popover__button" (click)="assistPaste.openAssist($event)" href="#">
              <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
              もっと詳しく
            </a>
          </div>
        </ng-template>
      </ng-template>

      <ng-template briskAssist="summary-list-ctrl-click" #assistCtrlClick="briskAssist">
        <button
          type="button"
          class="c-assist-popover-button p-assist-multiple-select"
          placement="bottom"
          [ngbPopover]="popContent2"
          [container]="viewMode === ViewMode.Normal ? 'body' : undefined"
          [ngClass]="viewMode === ViewMode.Normal ? 'u-normal-mode' : ''"
        ></button>

        <ng-template #popContent2>
          <div class="c-assist-popover__description">
            <p class="c-assist-popover__text">Ctrl を押しながら銘柄リストを選択することで、<br />複数の銘柄リストを同時表示</p>
          </div>
          <div class="c-assist-popover__buttons">
            <a class="c-assist-popover__button" (click)="assistCtrlClick.openAssist($event)" href="#">
              <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
              もっと詳しく
            </a>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>

    <brisk-first-tutorial
      class="p-smart-paste-tutorial"
      *ngIf="!tutorialMode && !(assist$ | async)"
      #smartPasteTutorial
      storageKey="smart_paste"
      placement="bottom"
      detailLink="https://static.brisk.jp/assist-v2/smart-copipe.html"
    >
      コピー＆ペーストで銘柄リストを楽々追加。
    </brisk-first-tutorial>
  </div>

  <div class="i-tutorial-position" #summaryPortfolioPosition></div>
</div>

<app-show-index-dialog #showIndexDialog (showIndex)="showIndex($event)"></app-show-index-dialog>
<app-csv-import-dialog #csvImportDialog (showIndex)="showIndex($event)"></app-csv-import-dialog>
<app-csv-export-dialog #csvExportDialog></app-csv-export-dialog>
<app-smart-paste-dialog #smartPasteDialog></app-smart-paste-dialog>
