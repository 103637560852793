import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupingTypes, MAP_VIEW_CONTROLLER_TOKEN, MapViewController, MapViewState } from '../../panels/map-view-panel/interface';
import { GroupingType } from 'fita3/src/app/portfolio/map-view/grouper';

@Component({
  selector: 'app-fita4-map-view-header',
  templateUrl: './map-view-header.component.html',
  styleUrls: ['./map-view-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapViewHeaderComponent implements OnInit, OnDestroy {
  private customHeaderElement: HTMLElement;
  state$: Observable<MapViewState>;

  groupingTypesDataSource = Object.keys(GroupingTypes)
    .map((key) => {
      return {
        name: GroupingTypes[key].name,
        value: key,
        order: GroupingTypes[key].order,
      };
    })
    .sort((a, b) => a.order - b.order);

  constructor(private elementRef: ElementRef<HTMLElement>, @Inject(MAP_VIEW_CONTROLLER_TOKEN) public mapView: MapViewController) {
    this.state$ = this.mapView.state$;
  }

  ngOnInit(): void {
    this.customHeaderElement = (this.elementRef.nativeElement as HTMLElement).parentElement;
    this.customHeaderElement.classList.add('map-view-header');
  }

  ngOnDestroy() {
    if (this.customHeaderElement) {
      this.customHeaderElement.classList.remove('map-view-header');
    }
  }
}
