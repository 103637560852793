import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  forwardRef,
} from '@angular/core';
import { stringToSortDirection, VgGridColumn, WidthUnit } from '../vg-grid-column';
import { VgGridCellTemplateDirective } from '../vg-grid-cell-template.directive';
import { FilterValueGetter, SortComparer } from '../collections/data-collecton';
import { GridFilterChoice, stringToFilterType, ValueType } from '../vg-grid-filter';
import { VG_GRID_COLUMN_GETTER, VgGridColumnGetter } from '../vg-grid/vg-grid.component';

function autoWidth(width: string | number): [number, WidthUnit] {
  if (typeof width === 'string') {
    if (width === '*') {
      return [1, WidthUnit.Fr];
    } else {
      return [parseFloat(width), WidthUnit.Fr];
    }
  } else {
    return [width, WidthUnit.Px];
  }
}

@Component({
  selector: 'brisk-vg-grid-column',
  templateUrl: './vg-grid-column.component.html',
  styleUrls: ['./vg-grid-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: VG_GRID_COLUMN_GETTER,
      useExisting: forwardRef(() => VgGridColumnComponent),
    },
  ],
})
export class VgGridColumnComponent<T> implements OnInit, OnChanges, AfterContentInit, VgGridColumnGetter<T> {
  column: VgGridColumn<T>;

  @Input()
  header: string;

  @Input()
  width: string | number;

  @Input()
  widthUnit: WidthUnit = WidthUnit.Auto;

  @Input()
  minWidth?: string | number;

  @Input()
  minWidthUnit?: WidthUnit;

  @Input()
  maxWidth?: string | number;

  @Input()
  maxWidthUnit?: WidthUnit;

  @Input()
  widthGrowFactor = 0;

  @Input()
  name: string;

  @Input()
  visible = true;

  @Input()
  binding = '';

  @Input()
  align = 'left';

  @Input()
  cssClass = '';

  @Input()
  allowSorting = true;

  @Input()
  allowEditing = false;

  @Input()
  filterType = '';

  @Input()
  filterRoundPrecision = 2;

  @Input()
  allowRowDragging = false;

  @Input()
  capturePointer = true;

  @Input()
  defaultSortDirection = 'Asc';

  @Input()
  isHeader = false;

  /// 複数行モードでの行の結合数を表します。単一行モードでは単に無視されます。
  @Input()
  rowSpan = 1;

  /// 複数行モードでの列の結合数を表します。単一行モードでは単に無視されます。
  @Input()
  colSpan = 1;

  @Input()
  stickyColumn = false;

  @Input()
  displayName = '';

  @Input()
  sortComparer: SortComparer<T> = undefined;

  @Input()
  disableHide = false;

  @Input()
  filterValueGetter: FilterValueGetter<T> = undefined;

  @Input()
  filterNameGetter: (v: any) => string = undefined;

  @Input()
  filterChoices: Array<GridFilterChoice<T, any>> = [];

  @Input()
  filterValueType: ValueType = ValueType.Number;

  @Input()
  filterSpecialValue: any = undefined;

  @Input()
  filterSpecialValueName: string = undefined;

  @Input()
  initialVisible = true;

  @Input()
  initialStickyColumn = false;

  @Output()
  change: EventEmitter<void> = new EventEmitter<void>();

  @ContentChildren(VgGridCellTemplateDirective)
  private templates: QueryList<VgGridCellTemplateDirective<T>>;

  constructor() {
    this.column = new VgGridColumn();
  }

  ngOnInit() {}

  ngOnChanges(): void {
    // 親に伝える
    this.column.name = this.name;
    this.column.visible = this.visible;
    if (this.widthUnit === WidthUnit.Auto) {
      [this.column.width, this.column.widthUnit] = autoWidth(this.width);
    } else if (typeof this.width !== 'string') {
      this.column.width = this.width;
      this.column.widthUnit = this.widthUnit;
    } else {
      throw new Error('Invalid width');
    }

    if (this.maxWidthUnit === WidthUnit.Auto) {
      [this.column.maxWidth, this.column.maxWidthUnit] = autoWidth(this.maxWidth);
    } else if (typeof this.maxWidth !== 'string') {
      this.column.maxWidth = this.maxWidth;
      this.column.maxWidthUnit = this.maxWidthUnit;
    } else {
      throw new Error('Invalid maxWidth');
    }

    if (this.minWidthUnit === WidthUnit.Auto) {
      [this.column.minWidth, this.column.minWidthUnit] = autoWidth(this.minWidth);
    } else if (typeof this.minWidth !== 'string') {
      this.column.minWidth = this.minWidth;
      this.column.minWidthUnit = this.minWidthUnit;
    } else {
      throw new Error('Invalid minWidth');
    }
    this.column.widthGrowFactor = this.widthGrowFactor;
    this.column.header = this.header;
    this.column.binding = this.binding;
    this.column.cssClass = this.cssClass;
    this.column.allowSorting = this.allowSorting;
    this.column.allowEditing = this.allowEditing;
    this.column.align = this.align;
    this.column.filterType = stringToFilterType(this.filterType);
    this.column.filterRoundPrecision = this.filterRoundPrecision;
    this.column.allowRowDragging = this.allowRowDragging;
    this.column.capturePointer = this.capturePointer;
    this.column.defaultSortDirection = stringToSortDirection(this.defaultSortDirection);
    this.column.rowSpan = this.rowSpan;
    this.column.colSpan = this.colSpan;
    this.column.stickyColumn = this.stickyColumn;
    this.column.isHeader = this.isHeader;
    this.column.displayName = this.displayName;
    this.column.sortComparer = this.sortComparer;
    this.column.disableHide = this.disableHide;
    this.column.filterValueGetter = this.filterValueGetter;
    this.column.filterChoices = this.filterChoices;
    this.column.filterNameGetter = this.filterNameGetter;
    this.column.filterValueType = this.filterValueType;
    this.column.filterSpecialValue = this.filterSpecialValue;
    this.column.filterSpecialValueName = this.filterSpecialValueName;
    this.column.initialVisible = this.initialVisible;
    this.column.initialStickyColumn = this.initialStickyColumn;

    this.change.emit();
  }

  ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      if (item.cellType === 'Cell') {
        this.column.cellTemplate = item.template;
      } else if (item.cellType === 'CellEdit') {
        this.column.editTemplate = item.template;
      }
    });
  }
}
