<div>
  <div class="form-group">
    <label>
      <input
        #checkbox
        type="checkbox"
        class="p-checkbox"
        [indeterminate]="patchForIEOrEdge ? false : indeterminate"
        (change)="_onCheckChanged(checkbox)"
        [checked]="allChecked && (patchForIEOrEdge ? !indeterminate : true)"
      />
      <span>すべて選択</span>
    </label>
  </div>

  <div class="form-group">
    <wj-list-box
      [itemsSource]="choices"
      displayMemberPath="label"
      checkedMemberPath="checked"
      class="form-control vg-grid-choice-filter-listbox"
      (itemChecked)="_updateItemChecked()"
    >
    </wj-list-box>
  </div>

  <div class="form-group">
    <div class="vg-grid-choice-filter-actions">
      <button class="c-btn c-btn--default vg-grid-choice-filter-btn" type="button" (click)="applyFilter()">適用</button>
      <button class="c-btn c-btn--default vg-grid-choice-filter-btn" type="button" (click)="cancel.emit()">キャンセル</button>
      <button class="c-btn c-btn--default vg-grid-choice-filter-btn" type="button" (click)="clearFilter()">クリア</button>
    </div>
  </div>
</div>
