import { Injectable } from '@angular/core';

// fita4では不要。
// 永続的なステートはPersistentStateServiceでバージョン付きで管理される
@Injectable({
  providedIn: 'root',
})
export class Fita4VersionService {
  constructor() {}

  initialize() {}
}
