import { Pipe, PipeTransform } from '@angular/core';
import { TransformPricePointPipe } from './transform-price-point.pipe';

@Pipe({
  name: 'priceChartPolygon',
})
export class PriceChartPolygonPipe implements PipeTransform {
  constructor(private transformPricePointPipe: TransformPricePointPipe) {}

  private getPoint(x: number, y: number, N: number, width: number, height: number, limitY: number): [number, number] {
    return this.transformPricePointPipe.transform([x, y], { N: N, width: width, height: height, limitY: limitY });
  }

  transform(points: Array<number>, arg: { width: number; height: number; limitY: number; upSide: boolean; stroke: boolean }): any {
    const N = points.length;
    let first = true;
    let ret = '';
    let lastI = null;
    for (let i = 0; i < N; i++) {
      if (points[i] === undefined) {
        continue;
      }
      if (first && !arg.stroke) {
        first = false;
        ret += `${this.getPoint(i, 0, N, arg.width, arg.height, arg.limitY)[0]} ${arg.upSide ? 0 : arg.height} `;
      }
      const [x, y] = this.getPoint(i, points[i], N, arg.width, arg.height, arg.limitY);
      ret += `${x} ${y} `;
      lastI = i;
    }
    if (lastI && !arg.stroke) {
      ret += `${this.getPoint(lastI, 0, N, arg.width, arg.height, arg.limitY)[0]} ${arg.upSide ? 0 : arg.height}`;
    }

    return ret;
  }
}
