<brisk-vg-grid
  #grid
  briskVgGridFlexibleRowSize
  [updateRowSize]="false"
  [itemsSource]="dataSource"
  [showRowHeader]="false"
  [rowHeight]="400"
  [addDummyRow]="false"
  (columnWidthChanged)="onColumnWidthChanged()"
  [templateCacheSize]="0"
>
  <brisk-vg-grid-column *ngFor="let columnIndex of columnIndexes" [width]="'*'" [align]="'center'" [capturePointer]="false">
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
      <app-ita-portfolio-item
        class="p-ita-portfolio-item"
        #itemComponent
        [normalized]="normalized"
        (normalizedChange)="normalizedChange.emit($event)"
        [item]="visible && item[columnIndex]"
        [updatedView]="updatedView"
        [rows]="itaRows"
        (itemClicked)="onItemClicked(item[columnIndex])"
        (itemDblClicked)="onItemDblClicked(item[columnIndex])"
        (gridHeightChanged)="onResize(itemComponent)"
        [timeProvider]="timeProvider"
        [peg]="peg"
        (pegChange)="pegChange.emit($event)"
        [itaWidth]="itaWidth"
      ></app-ita-portfolio-item>
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>
<div #itaWidthPadding style="visibility: hidden; position: absolute; width: 0.8rem;"></div>
