import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItaCaptureComponent } from './ita-capture.component';
import { ItaModule } from '../ita/ita.module';

@NgModule({
  declarations: [ItaCaptureComponent],
  exports: [ItaCaptureComponent],
  imports: [CommonModule, ItaModule],
})
export class ItaCaptureModule {}
