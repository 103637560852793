import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { MultipleWindowModalOverlayComponent } from './multiple-window-modal-overlay/multiple-window-modal-overlay.component';
import { MultipleWindowDialogService } from './multiple-window-dialog.service';
import { fromParentWindow, PARENT_CONFIG } from '@argentumcode/multiple-window';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { BriskDialogService } from './dialog.service';
@NgModule({
  declarations: [MultipleWindowModalOverlayComponent, SimpleDialogComponent],
  providers: [BriskDialogService],
  imports: [CommonModule, OverlayModule, MatDialogModule, BriskCommonModule, FormsModule, A11yModule, PortalModule],
})
export class BriskDialogModule {
  static forMultipleWindowParent(): ModuleWithProviders<BriskDialogModule> {
    return {
      ngModule: BriskDialogModule,
      providers: [
        MultipleWindowDialogService,
        {
          multi: true,
          provide: PARENT_CONFIG,
          useValue: {
            MultipleWindowDialogService: MultipleWindowDialogService,
          },
        },
      ],
    };
  }

  static forMultipleWindowChildren(): ModuleWithProviders<BriskDialogModule> {
    return {
      ngModule: BriskDialogModule,
      providers: [fromParentWindow(MultipleWindowDialogService, 'MultipleWindowDialogService', { convertObservable: true })],
    };
  }
}
