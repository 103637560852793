import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReloadService } from 'fita3/src/app/core/reload.service';

@Component({
  selector: 'app-fita4-demo-finished-dialog',
  templateUrl: './demo-finished-dialog.component.html',
  styleUrls: ['./demo-finished-dialog.component.scss'],
})
export class DemoFinishedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<any>, private _reload: ReloadService) {}

  reload() {
    gtag('event', 'cv_click', {
      click_target: 'reload_button',
      button_place: 'after-3min-dialog',
      value: 1,
    });
    this._reload.reload();
  }

  openEShitenPage() {
    window.open('https://www.e-shiten.jp/', '_blank', 'noopener');
    gtag('event', 'cv_click', {
      click_target: 'open_account_button',
      event_label: 'https://www.e-shiten.jp/',
      button_place: 'after-3min-dialog',
      value: 1,
    });
  }
}
