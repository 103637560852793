import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SecondaryWindowModule } from './app/secondary-window/secondary-window.module';
import { MainWindowModule } from './app/main-window/main-window.module';
import { environment } from './environments/default/environment';

import { setLicenseKey } from '@grapecity/wijmo';
import * as Sentry from '@sentry/browser';
import { sentryEventBeforeSend } from '@argentumcode/brisk-common';
import { NgZoneForParentWindow, prepareTimerForChildren, prepareTimerForParent, setupNgZoneForChild } from '@argentumcode/multiple-window';
import { InputNumber } from '@grapecity/wijmo.input';

import { setup, track, printSubscribers } from 'observable-profiler';
import 'node_modules/@grapecity/wijmo.cultures/wijmo.culture.ja.js';

InputNumber.controlTemplate = `<div class="wj-input"><div class="wj-input-group"><span wj-part="btn-dec" class="wj-input-group-btn" tabindex="-1"><button class="wj-btn wj-btn-default" tabindex="-1">-</button></span><input type="tel" wj-part="input" class="wj-form-control wj-numeric"/><span class="input-number-postpend"></span><span wj-part="btn-inc" class="wj-input-group-btn" tabindex="-1"><button class="wj-btn wj-btn-default" tabindex="-1">+</button></span></div></div>`;

function parseBoolean(s?: string): boolean {
  return s === 'true' || s === '1';
}

const hashArgs: { [key: string]: string } = {};
for (const [key, value] of location.hash
  .substr(1)
  .split('&')
  .map((a) => a.split('='))) {
  if (key) {
    hashArgs[key] = value;
  }
}

declare const WIJMO_KEY: string;
declare const STAGE: string;
declare const RELEASE: string;

if (WIJMO_KEY && !window['wijmoLisence']) {
  window['wijmoLisence'] = WIJMO_KEY;
}

const sentryDSN = 'https://4622d463a4bb4948b901d1fb2065a069@sentry.io/1232515';

if (window['wijmoLisence']) {
  setLicenseKey(window['wijmoLisence']);
}

if (environment.production) {
  enableProdMode();
}

if (STAGE && !window['stage']) {
  window['stage'] = STAGE;
}

if (window['stage'] && RELEASE) {
  Sentry.init({
    dsn: sentryDSN,
    ignoreErrors: [
      /^Websocket同時接続エラー$/,
      /^Session Expires$/,
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
    ],
    release: RELEASE,
    environment: `${environment.name}-${window['stage']}`,
    maxBreadcrumbs: 200,
    beforeSend: sentryEventBeforeSend,
  });
  // Sentryのconsole.assertは非常に遅いため、条件を満たしている時はskipする
  const origAssert = console.assert;
  console.assert = function (condition) {
    if (!condition) {
      origAssert(condition);
    }
  };
}

function startMainWindow() {
  window['isMainWindow'] = true;
  prepareTimerForParent();
  platformBrowserDynamic()
    .bootstrapModule(MainWindowModule, {
      ngZone: new NgZoneForParentWindow({}),
    })
    .then((app) => {
      if (isDevMode()) {
        track();

        window['stopProfiler'] = () => {
          try {
            app.destroy();
          } catch (ex) {
            console.error(ex);
          }

          const subscribers = track(false);
          printSubscribers({
            subscribers,
          });
          window['subscribers'] = subscribers;
        };
      }
      window.addEventListener('pagehide', () => {
        app.destroy();
      });
    })
    .catch((err) => console.error(err));
}

function startSubWindow() {
  const childNgZone = setupNgZoneForChild(window.opener);
  prepareTimerForChildren();
  platformBrowserDynamic()
    .bootstrapModule(SecondaryWindowModule, {
      ngZone: childNgZone.ngZone,
    })
    .then((app) => {
      if (isDevMode()) {
        track();
        window['stopProfiler'] = () => {
          try {
            app.destroy();
          } catch (ex) {
            console.error(ex);
          }

          const subscribers = track(false);
          printSubscribers({
            subscribers,
          });
          window['subscribers'] = subscribers;
        };
      }
      window.addEventListener('pagehide', () => {
        app.destroy();
        childNgZone.destroy();
        window.close();
      });
    })
    .catch((err) => console.error(err));
}

function startSingleWindow() {
  // Cypressでの e2eテスト用に一つのWindowで両方の機能を有効にする
  document.body.classList.add('u-test-single-window');
  prepareTimerForParent();
  platformBrowserDynamic()
    .bootstrapModule(MainWindowModule, {
      ngZone: new NgZoneForParentWindow({}),
    })
    .then((app) => {
      if (isDevMode()) {
        track();

        window['stopProfiler'] = () => {
          try {
            app.destroy();
          } catch (ex) {
            console.error(ex);
          }

          const subscribers = track(false);
          printSubscribers({
            subscribers,
          });
          window['subscribers'] = subscribers;
        };
      }
      window.addEventListener('pagehide', () => {
        app.destroy();
      });
    })
    .catch((err) => console.error(err));

  const origOpen = window.open;
  window.open = (url, target?, features?) => {
    if (url.includes('secondary-window')) {
      window.opener = window;
      const childNgZone = setupNgZoneForChild(window.opener);
      platformBrowserDynamic()
        .bootstrapModule(SecondaryWindowModule, {
          ngZone: childNgZone.ngZone,
        })
        .then((app) => {
          console.log('start sub window');
          if (isDevMode()) {
            track();
            window['stopProfiler'] = () => {
              try {
                app.destroy();
              } catch (ex) {
                console.error(ex);
              }

              const subscribers = track(false);
              printSubscribers({
                subscribers,
              });
              window['subscribers'] = subscribers;
            };
          }
          window.addEventListener('pagehide', () => {
            app.destroy();
            childNgZone.destroy();
            window.close();
          });
        })
        .catch((err) => console.error(err));
      return window;
    } else {
      return origOpen.bind(window)(url, target, features);
    }
  };
}

let subWindow = false;
if (window.opener) {
  try {
    if (window.opener['isMainWindow']) {
      subWindow = true;
    }
  } catch {}
}
import { Observable } from 'rxjs';

if (isDevMode()) {
  setup(Observable);
}
if (parseBoolean(hashArgs['single_window'])) {
  startSingleWindow();
} else {
  if (!subWindow) {
    startMainWindow();
  } else {
    startSubWindow();
  }
}
