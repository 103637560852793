import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'priceChanges',
})
export class PriceChangesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const option = {
      showPercent: 'showPercent' in (args || {}) ? args.showPercent : true,
    };
    if (value === undefined || value === null) {
      return '';
    } else if (value > 0) {
      return '+' + (value * 100).toFixed(2) + (option.showPercent ? '%' : '');
    } else {
      return (value * 100).toFixed(2) + (option.showPercent ? '%' : '');
    }
  }
}
