import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformPricePoint',
})
export class TransformPricePointPipe implements PipeTransform {
  transform([x, y]: [number, number], arg?: { N: number; width: number; height: number; limitY: number }): [number, number] {
    return [(x * arg.width) / arg.N, ((-y + arg.limitY) / arg.limitY / 2) * arg.height];
  }
}
