import { FlexOperator } from './flex-operator';
import { StorageService } from './storage.service';
import { WatchList, WatchListEntry, WatchListService } from './watch-list.service';
import {
  SummaryChangeEvent,
  SummaryChangeType,
  SummaryListChangeEvent,
  UpdateSummaryListType,
  UpdateSummaryType,
} from './summary-change-event';
import { Subject, Subscription } from 'rxjs';
import {
  FlexExchange,
  HistoryType,
  Portfolio,
  Side,
  StockInfo,
  StockMaster,
  Summary,
  SummaryPortfolio,
  SummaryType,
  ViewHistoryService,
} from '@argentumcode/brisk-common';
import { OnDestroy } from '@angular/core';
import { StockPortfolio } from '../portfolio/portfolio';
import { APIStockList } from 'fita3/src/app/core/api.service';
import { environment } from '../../environments/default/environment';
import { IssueTypeForSummaries } from './issue-types.service';

enum FeaturedIssueType {
  Small,
  Medium,
  Large,
  All,
}

export const fseOnlyStocks: Readonly<Array<number>> = [
  1771,
  1999,
  2058,
  2919,
  2974,
  3047,
  3824,
  4018,
  4250,
  4447,
  4827,
  4995,
  5953,
  6076,
  7441,
  7533,
  7894,
  8398,
  8540,
  8554,
  8559,
  8560,
  8996,
  9035,
  9407,
  9942,
];

export abstract class SmartListSummary extends Summary {
  protected filterCount = 30;

  protected constructor(summaryId: string, name: string, index: number) {
    super(SummaryType.SmartList, summaryId, name, index);
  }

  update(op: FlexOperator) {
    this.clear();
    let candiIssues: Array<StockPortfolio> = [];
    for (let i = 0; i < op.stockMasters.length; i++) {
      const portfolio = new StockPortfolio(op.stockMasters[i], op.portfolios[i], op.stockInfos[i], null, 0);
      if (this.filter(portfolio)) {
        candiIssues.push(portfolio);
      }
    }
    candiIssues = this.sort(candiIssues);
    if (this.filterCount > 0) {
      candiIssues = candiIssues.slice(0, this.filterCount);
    }
    for (const portfolio of candiIssues) {
      this.addIssue(portfolio.master.issueCode, 1);
    }
  }

  protected abstract filter(portfolio: StockPortfolio): boolean;

  protected abstract sort(data: Array<StockPortfolio>): Array<StockPortfolio>;
}

export class SmartListSummarySimpleSort extends SmartListSummary {
  constructor(
    summaryId: string,
    name: string,
    index: number,
    private columnName: string,
    private asc = true,
    private notNullFilter: boolean = true
  ) {
    super(summaryId, name, index);
  }

  protected filter(portfolio: StockPortfolio): boolean {
    // Exclude ETF
    if (this.notNullFilter) {
      if (portfolio[this.columnName] === null || portfolio[this.columnName] === undefined) {
        return false;
      }
    }
    return portfolio.master.prefix !== 'E';
  }

  protected sort(data: Array<StockPortfolio>): Array<StockPortfolio> {
    data.sort((p1, p2) => {
      const c1 = p1[this.columnName];
      const c2 = p2[this.columnName];
      if (c1 === c2) {
        return p1.master.issueCode - p2.master.issueCode;
      }
      return (this.asc ? 1 : -1) * (c1 > c2 ? 1 : -1);
    });
    return data;
  }
}

export class SmartListSummarySimpleSortWithSize extends SmartListSummarySimpleSort {
  constructor(
    summaryId: string,
    name: string,
    index: number,
    columnName: string,
    asc = true,
    private size: FeaturedIssueType,
    private jumpOrSuddenDrop: boolean
  ) {
    super(summaryId, name, index, columnName, asc);
  }

  protected filter(portfolio: StockPortfolio): boolean {
    if (!super.filter(portfolio)) {
      return false;
    }
    if (this.size === FeaturedIssueType.Large) {
      if (!portfolio.marketCapitalizationFilterValue || portfolio.marketCapitalizationFilterValue < 1000) {
        return false;
      }
    } else if (this.size === FeaturedIssueType.Medium) {
      if (
        portfolio.marketCapitalizationFilterValue === null ||
        portfolio.marketCapitalizationFilterValue === undefined ||
        portfolio.marketCapitalizationFilterValue >= 1000 ||
        portfolio.marketCapitalizationFilterValue < 100
      ) {
        return false;
      }
    } else if (this.size === FeaturedIssueType.Small) {
      if (
        portfolio.marketCapitalizationFilterValue === null ||
        portfolio.marketCapitalizationFilterValue === undefined ||
        portfolio.marketCapitalizationFilterValue >= 100
      ) {
        return false;
      }
    }
    if (this.jumpOrSuddenDrop) {
      const downTick = portfolio.master.tick.downTick(portfolio.master.basePrice10);
      const vwap = portfolio.portfolio.turnover10 / portfolio.portfolio.volume;
      return Math.abs(portfolio.price10 - vwap) > downTick + 1e-9;
    }
    return true;
  }
}

export class SmartListSummaryFeaturedIssue extends SmartListSummarySimpleSort {
  constructor(summaryId: string, name: string, index: number, private featuredType: FeaturedIssueType) {
    super(summaryId, name, index, 'turnoverRate100', false);
  }

  protected filter(portfolio: StockPortfolio): boolean {
    // Exclude ETF
    if (this.featuredType === FeaturedIssueType.Large) {
      if (Math.abs(portfolio.lastPriceBasePriceChange) < 0.01) {
        return false;
      }
      if (portfolio.marketCapitalizationFilterValue < 1000) {
        return false;
      }
      if (portfolio.lastDayTurnover < 3e8) {
        return false;
      }
    } else if (this.featuredType === FeaturedIssueType.Medium) {
      if (Math.abs(portfolio.lastPriceBasePriceChange) < 0.02) {
        return false;
      }
      if (portfolio.marketCapitalizationFilterValue >= 1000 || portfolio.marketCapitalizationFilterValue < 100) {
        return false;
      }
      if (portfolio.lastDayTurnover < 1e7) {
        return false;
      }
    } else if (this.featuredType === FeaturedIssueType.Small) {
      if (Math.abs(portfolio.lastPriceBasePriceChange) < 0.02) {
        return false;
      }
      if (portfolio.marketCapitalizationFilterValue >= 100) {
        return false;
      }
      if (portfolio.lastDayTurnover < 1e7) {
        return false;
      }
    }
    return portfolio.master.prefix !== 'E';
  }
}

export class SmartListSummarySpecialQuote extends SmartListSummary {
  constructor(summaryId: string, name: string, index: number, private side: Side) {
    super(summaryId, name, index);
    this.filterCount = 8192;
  }

  protected filter(portfolio: StockPortfolio): boolean {
    if (this.side === Side.Ask) {
      return portfolio.specialQuoteSort === 'ウ' && portfolio.master.prefix !== 'E';
    } else if (this.side === Side.Bid) {
      return portfolio.specialQuote === 'カ' && portfolio.master.prefix !== 'E';
    } else {
      throw new Error('Invalid Side');
    }
  }

  protected sort(data: Array<StockPortfolio>): Array<StockPortfolio> {
    data.sort((p1, p2) => {
      return p1.master.issueCode - p2.master.issueCode;
    });
    return data;
  }
}

export class LimitUpDownList extends SmartListSummary {
  constructor(summaryId: string, name: string, index: number, private limitUp: boolean) {
    super(summaryId, name, index);
    this.filterCount = 8192;
  }

  protected filter(portfolio: StockPortfolio): boolean {
    if (portfolio.master.prefix === 'E') {
      return false;
    }
    if (portfolio.portfolio.askPrice10 && portfolio.master.limitDown10 && this.limitUp === false) {
      return portfolio.portfolio.askPrice10 === portfolio.master.limitDown10;
    } else if (portfolio.portfolio.bidPrice10 && portfolio.master.limitUp10 && this.limitUp) {
      return portfolio.portfolio.bidPrice10 === portfolio.master.limitUp10;
    }
    return false;
  }

  protected sort(data: Array<StockPortfolio>): Array<StockPortfolio> {
    data.sort((p1, p2) => {
      return p1.master.issueCode - p2.master.issueCode;
    });
    return data;
  }
}

const enum MarketSummaryType {
  LimitUp,
  LimitDown,
}

function getMarketSummaryTypeFromMarketCondition(condition: any): MarketSummaryType | null {
  if (condition.type === 3) {
    if (condition.updown === 1) {
      return MarketSummaryType.LimitUp;
    } else if (condition.updown === 2) {
      return MarketSummaryType.LimitDown;
    }
  }
  return null;
}

export class Summaries {
  private summaryChangeSubject = new Subject<SummaryChangeEvent>();
  public summaryChanged = this.summaryChangeSubject.asObservable();

  private summaryListChangeSubject = new Subject<SummaryListChangeEvent>();
  public summaryListChanged = this.summaryListChangeSubject.asObservable();

  private orderHistorySubscription: Subscription = null;

  private indexBits = {
    2: '高流動性100銘柄',
  };

  public summaries: Array<Summary> = [];

  public invisibleSummary: Set<string>; // 非表示サマリー

  private indexPosition = 1000000000;
  private watchListPosition = 0;

  private marketSummaries: { [key: number]: Summary } = {};
  private updateMarkets: { [key: number]: boolean } = {};

  private historySummary: Summary = null;
  private orderHistorySummary: Summary = null;

  constructor(
    private op: FlexOperator,
    private storage: StorageService,
    private watchList: WatchListService,
    private viewHistory: ViewHistoryService,
    private issueTypes: Readonly<Array<IssueTypeForSummaries>>,
    serverSummaries?: Array<APIStockList>
  ) {
    this.invisibleSummary = new Set<string>(this.storage.invisibleSummary);
    this.initialize(serverSummaries);
  }

  private createAllIssuesSummary() {
    const summary = new Summary(SummaryType.Index, 'allIssue', '全銘柄', this.indexPosition++);
    this.summaries.push(summary);
    for (const master of this.op.stockMasters) {
      summary.addIssue(master.issueCode, 1);
    }
  }

  private createIndustrySummary(code: number, name: string) {
    const summary = new Summary(SummaryType.Industry, `industry:${code}`, name, this.indexPosition++);
    this.summaries.push(summary);
    for (const master of this.op.stockMasters.filter((m) => m.industryCode === code)) {
      summary.addIssue(master.issueCode, 1);
    }
  }

  private createIssueTypeSummary(issueType) {
    const summary = new Summary(SummaryType.Index, `issueType:${issueType.issueType}`, issueType.name, this.indexPosition++);
    this.summaries.push(summary);
    for (const master of this.op.stockMasters.filter((m) =>
      Array.isArray(issueType.issueType) ? issueType.issueType.includes(m.issueType) : m.issueType === issueType.issueType
    )) {
      summary.addIssue(master.issueCode, 1);
    }
  }

  private createIndexSummary() {
    for (const i of Object.keys(this.indexBits)) {
      const summary = new Summary(SummaryType.Index, `index:${i}`, this.indexBits[i], this.indexPosition++);
      this.summaries.push(summary);
      for (const master of this.op.stockMasters.filter((m) => (m.indexFlag >> Number(i)) & 1)) {
        summary.addIssue(master.issueCode, 1);
      }
    }
  }

  private createWatchListSummary() {
    const watchLists = this.watchList.all();
    for (const watchList of watchLists) {
      if (this.invisibleSummary.has(`watchlist:${watchList.id}`)) {
        this.watchList.delete(watchList.id);
        this.storage.removeInvisibleSummary(`watchlist:${watchList.id}`);
        continue;
      }
      const summary = new Summary(SummaryType.WatchList, `watchlist:${watchList.id}`, watchList.name, this.watchListPosition++);
      this.summaries.push(summary);
      for (const watchListEntry of watchList.entries) {
        if (watchListEntry.issueCode in this.op.issueCodeMap) {
          summary.addIssue(watchListEntry.issueCode, 1);
        }
      }
    }
  }

  private createSmartListSummary() {
    this.summaries.push(
      new SmartListSummaryFeaturedIssue(
        `smartlist:featuredIssuesLarge`,
        'ピックアップ(大型)',
        this.indexPosition++,
        FeaturedIssueType.Large
      )
    );
    this.summaries.push(
      new SmartListSummaryFeaturedIssue(
        `smartlist:featuredIssuesMedium`,
        'ピックアップ(中型)',
        this.indexPosition++,
        FeaturedIssueType.Medium
      )
    );
    this.summaries.push(
      new SmartListSummaryFeaturedIssue(
        `smartlist:featuredIssuesSmall`,
        'ピックアップ(小型)',
        this.indexPosition++,
        FeaturedIssueType.Small
      )
    );
    const lists: Array<[string, string, string, boolean, boolean]> = [
      [`smartlist:lastPriceBasePriceChangeTop`, '上昇率上位', 'rateOfIncrease', false, false],
      [`smartlist:lastPriceBasePriceChangeBottom`, '下落率上位', 'rateOfIncrease', true, false],
      [`smartlist:vwapTop`, '場中急騰銘柄', 'vwapPriceChange100', false, true],
      [`smartlist:vwapBottom`, '場中急落銘柄', 'vwapPriceChange100', true, true],
      [`smartlist:openPriceBasePriceChangeTop`, '寄付上昇率上位', 'openPrice10BasePriceChanges100', false, false],
      [`smartlist:openPriceBasePriceChangeBottom`, '寄付下落率上位', 'openPrice10BasePriceChanges100', true, false],
    ];

    for (const [summaryId, name, columnName, order, suddenDrop] of lists) {
      for (const sz of [
        { id: '', name: '', sz: FeaturedIssueType.All },
        { id: 'Large', name: '(大型)', sz: FeaturedIssueType.Large },
        { id: 'Medium', name: '(中型)', sz: FeaturedIssueType.Medium },
        { id: 'Small', name: '(小型)', sz: FeaturedIssueType.Small },
      ]) {
        this.summaries.push(
          new SmartListSummarySimpleSortWithSize(
            summaryId + sz.id,
            name + sz.name,
            this.indexPosition++,
            columnName,
            order,
            sz.sz,
            suddenDrop
          )
        );
      }
    }
    this.summaries.push(new SmartListSummarySimpleSort(`smartlist:turnOverTop`, '売買代金上位', this.indexPosition++, 'turnover10', false));

    this.summaries.push(new LimitUpDownList(`smartlist:limitUp10`, 'ストップ高', this.indexPosition++, true));
    this.summaries.push(new LimitUpDownList(`smartlist:limitDown10`, 'ストップ安', this.indexPosition++, false));

    this.summaries.push(new SmartListSummarySimpleSort(`smartlist:qrCount`, 'Tick回数上位', this.indexPosition++, 'qrCount', false));
    this.summaries.push(new SmartListSummarySpecialQuote(`smartlist:specialQuoteBuy`, '買特別気配', this.indexPosition++, Side.Bid));
    this.summaries.push(new SmartListSummarySpecialQuote(`smartlist:specialQuoteSell`, '売特別気配', this.indexPosition++, Side.Ask));
  }

  private createMasterSummaryAndServerSummary(serverSummary: Array<APIStockList>) {
    const ipoSummary = new Summary(SummaryType.Master, `master:ipo`, '新規上場', this.indexPosition++);
    const ipo3MonthSummary = new Summary(SummaryType.Server, `server:ipo3month"`, '直近上場銘柄', this.indexPosition++);
    const marketCapitalSummary = new Summary(SummaryType.Master, `master:market_captital`, '時価総額上位', this.indexPosition++);
    const seiriSummary = new Summary(SummaryType.Master, `master:seiri`, '整理銘柄', this.indexPosition++);
    const kanriSummary = new Summary(SummaryType.Master, `master:kanri`, '監理銘柄', this.indexPosition++);
    const exRightSummary = new Summary(SummaryType.Master, `master:exRight`, '権利落ち', this.indexPosition++);
    const highBasePriceSummary = new Summary(SummaryType.Master, `master:highBase`, '値嵩株', this.indexPosition++);
    const lowBasePriceSummary = new Summary(SummaryType.Master, `master:lowBase`, '低位株', this.indexPosition++);
    this.summaries.push(marketCapitalSummary);
    this.summaries.push(highBasePriceSummary);
    this.summaries.push(lowBasePriceSummary);
    this.summaries.push(ipoSummary);
    if (serverSummary.some((summary) => summary.id === 'ipo3month')) {
      this.summaries.push(ipo3MonthSummary);
    }
    this.summaries.push(kanriSummary);
    this.summaries.push(seiriSummary);
    this.summaries.push(exRightSummary);
    for (const master of this.op.stockMasters.slice().sort((a, b) => a.issueCode - b.issueCode)) {
      if (master.isNewListed) {
        ipoSummary.addIssue(master.issueCode, 1);
      }
      if (master.seiriKanriFlag === 1) {
        seiriSummary.addIssue(master.issueCode, 1);
      }
      if (master.seiriKanriFlag === 2) {
        kanriSummary.addIssue(master.issueCode, 1);
      }
      if (master.exRightFlag !== 0) {
        exRightSummary.addIssue(master.issueCode, 1);
      }
    }
    for (const master of this.op.stockMasters
      .slice()
      .filter((a) => a.prefix !== 'E' && a.prefix !== 'R')
      .sort((a, b) => {
        if (a.basePrice10 !== b.basePrice10) {
          return -(a.basePrice10 - b.basePrice10);
        }
        return a.issueCode - b.issueCode;
      })
      .slice(0, 30)) {
      highBasePriceSummary.addIssue(master.issueCode, 1);
    }
    for (const master of this.op.stockMasters
      .slice()
      .filter((a) => a.prefix !== 'E' && a.prefix !== 'R')
      .sort((a, b) => {
        if (a.basePrice10 !== b.basePrice10) {
          return a.basePrice10 - b.basePrice10;
        }
        return a.issueCode - b.issueCode;
      })
      .slice(0, 30)) {
      lowBasePriceSummary.addIssue(master.issueCode, 1);
    }

    const list = [];
    for (let i = 0; i < this.op.stockMasters.length; i++) {
      const master = this.op.stockMasters[i];
      if (master.prefix === 'E') {
        continue;
      }
      const info = this.op.stockInfos[i];
      if (info && info.calcSharesOutstanding) {
        list.push({
          market: info.calcSharesOutstanding * master.basePrice10,
          issueCode: master.issueCode,
        });
      }
    }
    for (const i of list
      .sort((a, b) => {
        if (a.market !== b.market) {
          return -(a.market - b.market);
        }
        return a.issueCode - b.issueCode;
      })
      .slice(0, 30)) {
      marketCapitalSummary.addIssue(i.issueCode, 1);
    }
    for (const summary of serverSummary) {
      if (summary.id === 'ipo3month') {
        ipo3MonthSummary.name = summary.name;
        for (const issueCode of summary.issue_codes) {
          ipo3MonthSummary.addIssue(issueCode, 1);
        }
      }
    }
  }

  private createNK225Summary() {
    const nk225s: Array<number> = [
      4151,
      4502,
      4503,
      4506,
      4507,
      4519,
      4523,
      4568,
      4578,
      3105,
      6479,
      6501,
      6503,
      6504,
      6506,
      6645,
      6674,
      6701,
      6702,
      6703,
      6724,
      6752,
      6758,
      6762,
      6770,
      6841,
      6857,
      6902,
      6952,
      6954,
      6971,
      6976,
      7735,
      7751,
      7752,
      8035,
      7201,
      7202,
      7203,
      7205,
      7211,
      7261,
      7267,
      7269,
      7270,
      7272,
      4543,
      4902,
      7731,
      7733,
      7762,
      9412,
      9432,
      9433,
      9613,
      9984,
      7186,
      8303,
      8304,
      8306,
      8308,
      8309,
      8316,
      8331,
      8354,
      8355,
      8411,
      8253,
      8601,
      8604,
      8628,
      8630,
      8725,
      8697,
      8750,
      8766,
      8795,
      1332,
      1333,
      2002,
      2269,
      2282,
      2501,
      2502,
      2503,
      2531,
      2801,
      2802,
      2871,
      2914,
      3086,
      3099,
      3382,
      8233,
      8252,
      8267,
      9983,
      2432,
      4324,
      4689,
      4704,
      4755,
      9602,
      9735,
      9766,
      1605,
      3101,
      3103,
      3401,
      3402,
      3861,
      3863,
      2413,
      6098,
      6178,
      3405,
      3407,
      4004,
      4005,
      4021,
      4042,
      4043,
      4061,
      4063,
      4183,
      4188,
      4208,
      4452,
      4901,
      4911,
      6988,
      5019,
      5020,
      5101,
      5108,
      5201,
      5202,
      5214,
      5232,
      5233,
      5301,
      5332,
      5333,
      5401,
      5406,
      5411,
      4631,
      5541,
      3436,
      5703,
      5706,
      5707,
      5711,
      5713,
      5714,
      5801,
      5802,
      5803,
      5901,
      2768,
      8001,
      8002,
      8015,
      8031,
      8053,
      8058,
      1721,
      1801,
      1802,
      1803,
      1808,
      1812,
      1925,
      1928,
      1963,
      5631,
      6103,
      6113,
      6301,
      6302,
      6305,
      6326,
      6361,
      6367,
      6471,
      6472,
      6473,
      7004,
      7011,
      7013,
      7003,
      7012,
      7911,
      7912,
      7951,
      3289,
      8801,
      8802,
      8804,
      8830,
      9001,
      9005,
      9007,
      9008,
      9009,
      9020,
      9021,
      9022,
      9062,
      9064,
      9101,
      9104,
      9107,
      9202,
      9301,
      9501,
      9502,
      9503,
      9531,
      9532,
      4751,
      7832,
      9434,
      3659,
      6753,
    ];
    const summary = new Summary(SummaryType.Stock, `stock:1321`, '日経225ETF', this.indexPosition++);
    for (const issueCode of nk225s.sort()) {
      summary.addIssue(issueCode, 1);
    }
    this.summaries.push(summary);
  }

  private createFseSingleSummary() {
    const summary = new Summary(SummaryType.Index, `fseSingle`, '単独上場', this.indexPosition++);
    this.summaries.push(summary);
    for (const issueCode of fseOnlyStocks) {
      summary.addIssue(issueCode, 1);
    }
  }

  private createFseMultiSummary() {
    const summary = new Summary(SummaryType.Index, `fseMulti`, '同時上場', this.indexPosition++);
    this.summaries.push(summary);
    const fseOnlyStocksSet = new Set(fseOnlyStocks);
    for (const master of this.op.stockMasters.filter((m) => !fseOnlyStocksSet.has(m.issueCode))) {
      summary.addIssue(master.issueCode, 1);
    }
  }

  private createFseMainSummary() {
    // Note: Q-Board(114)以外は全て本則市場
    // https://www.fse.or.jp/stock/
    const summary = new Summary(SummaryType.Index, `fseHonsoku`, '本則市場', this.indexPosition++);
    this.summaries.push(summary);
    for (const master of this.op.stockMasters.filter((m) => m.issueType !== 114)) {
      summary.addIssue(master.issueCode, 1);
    }
  }

  private createHistorySummary() {
    const summary = new Summary(SummaryType.History, `history`, '閲覧履歴', this.indexPosition++);
    for (const history of this.viewHistory.histories.slice().reverse()) {
      if (history.type === HistoryType.Stock && history.code in this.op.issueCodeMap) {
        summary.addIssue(history.code, 1);
      }
    }
    this.summaries.push(summary);
    this.historySummary = summary;
  }

  public initialize(serverSummary?: Array<APIStockList>) {
    this.createWatchListSummary();

    this.createHistorySummary();

    this.createSmartListSummary();

    if ((environment.flexConfig.exchange as FlexExchange) === FlexExchange.Tokyo) {
      this.createNK225Summary();
    }

    this.createAllIssuesSummary();

    if ((environment.flexConfig.exchange as FlexExchange) === FlexExchange.Tokyo) {
      this.createIndexSummary();
    }

    if ((environment.flexConfig.exchange as FlexExchange) === FlexExchange.Fukuoka) {
      this.createFseSingleSummary();
      this.createFseMultiSummary();
      this.createFseMainSummary();
    }

    for (const issueType of this.issueTypes) {
      if (!issueType.visible) {
        continue;
      }
      this.createIssueTypeSummary(issueType);
    }
    const industrySets = new Set<number>();

    for (const [industryCode, industryName] of this.op.stockMasters.map((a): [number, string] => [a.industryCode, a.industryName])) {
      if (industryCode === 9999) {
        continue;
      }
      if (industrySets.has(industryCode)) {
        continue;
      }
      industrySets.add(industryCode);
      this.createIndustrySummary(industryCode, industryName);
    }
    this.createMasterSummaryAndServerSummary(serverSummary || []);

    this.checkSummary();

    for (const summary of this.summaries) {
      if (this.invisibleSummary.has(summary.summaryId)) {
        continue;
      }
      this.op.addSummary(summary);
    }
    this.update();
  }

  private checkSummary() {
    const summaryIds = this.summaries.map((a) => a.summaryId).sort();
    for (let i = 0; i < summaryIds.length - 1; i++) {
      if (summaryIds[i] === summaryIds[i + 1]) {
        throw new Error('Same summary id');
      }
    }
  }

  public getSummaryPortfolios(): Array<[Summary, SummaryPortfolio]> {
    const ret: Array<[Summary, SummaryPortfolio]> = [];
    for (const summary of this.summaries.sort((a, b) => a.position - b.position)) {
      if (this.invisibleSummary.has(summary.summaryId)) {
        continue;
      }
      ret.push([summary, this.op.summaryPortfolios[summary.id]]);
    }
    return ret;
  }

  public getSummaryPortfolio(summary: Summary): SummaryPortfolio {
    return this.op.summaryPortfolios[summary.id];
  }

  public update() {
    this.op.updateSummaries();
  }

  public getStockPortfolio(issueList: Array<[number, number]>): Array<[Portfolio, StockMaster, StockInfo]> {
    const ret: Array<[Portfolio, StockMaster, StockInfo]> = [];
    for (const [issueCode, _] of issueList) {
      const idx = this.op.issueCodeMap[issueCode];
      if (!(issueCode in this.op.issueCodeMap)) {
        continue;
      }
      ret.push([this.op.portfolios[idx], this.op.stockMasters[idx], this.op.stockInfos[idx]]);
    }
    return ret;
  }

  public getNewStockPortfolio(issueCode: number): [Portfolio, StockMaster, StockInfo] {
    const idx = this.op.issueCodeMap[issueCode];
    return [this.op.portfolios[idx], this.op.stockMasters[idx], this.op.stockInfos[idx]];
  }

  public updateSummary(summary: Summary, type: UpdateSummaryType = UpdateSummaryType.None, indexes: Array<number> = null) {
    this.op.updateSummary(summary);
    const ev = new SummaryChangeEvent(SummaryChangeType.UpdateSummary, summary);
    ev.updateType = type;
    ev.indexes = indexes;
    this.summaryChangeSubject.next(ev);
  }

  public remove(summary: Summary) {
    if (summary.type === SummaryType.WatchList) {
      this.watchList.delete(Number(summary.summaryId.split(':')[1]));
      const index = this.summaries.indexOf(summary);
      if (index >= 0) {
        this.summaries.splice(index, 1);
      }
    } else {
      this.storage.addInvisibleSummary(summary.summaryId);
      this.invisibleSummary.add(summary.summaryId);
    }
    this.summaryChangeSubject.next(new SummaryChangeEvent(SummaryChangeType.DeleteSummary, summary));
  }

  public saveWatchList(summary: Summary) {
    if (summary.type !== SummaryType.WatchList) {
      throw new Error('Not Watchlist');
    }
    const id = Number(summary.summaryId.split(':')[1]);
    const watchList = this.watchList.find(id);
    if (watchList === null) {
      throw new Error('Watch list not found');
    }
    watchList.name = summary.name;
    watchList.entries = summary.issueList.map((a) => new WatchListEntry(a[0]));
    this.watchList.saveToLocalStorage();
  }

  public addWatchList(name): Summary {
    const watchLists = this.watchList.all();
    if (watchLists.filter((a) => a.name === name).length > 0) {
      return null;
    }
    const wl = new WatchList();
    this.watchList.add(wl);
    const summary = new Summary(SummaryType.WatchList, `watchlist:${wl.id}`, name, this.watchListPosition++);
    this.op.addSummary(summary);
    this.summaries.push(summary);
    this.summaryChangeSubject.next(new SummaryChangeEvent(SummaryChangeType.AddSummary, summary));
    return summary;
  }

  public reloadWatchList() {
    for (let i = 0; i < this.summaries.length; i++) {
      if (this.summaries[i].type === SummaryType.WatchList) {
        this.summaries.splice(i, 1);
        i--;
      }
    }
    this.createWatchListSummary();
    for (let i = 0; i < this.summaries.length; i++) {
      if (this.summaries[i].type === SummaryType.WatchList) {
        this.op.addSummary(this.summaries[i]);
      }
    }
    this.update();
    this.summaryListChangeSubject.next(new SummaryListChangeEvent(UpdateSummaryListType.FullUpdate));
  }

  public allInvisibleSummary(): Array<Summary> {
    const ret = [];
    this.invisibleSummary = new Set<string>(this.storage.invisibleSummary);
    for (const summary of this.summaries) {
      if (this.invisibleSummary.has(summary.summaryId)) {
        ret.push(summary);
      }
    }
    return ret;
  }

  public getSummaryPortfoliosFromSummaryId(summaryIds: Array<string>): Array<[Summary, SummaryPortfolio]> {
    const ret: Array<[Summary, SummaryPortfolio]> = [];
    const s = new Set<string>(summaryIds);
    for (const summary of this.summaries) {
      if (!s.has(summary.summaryId)) {
        continue;
      }
      if (summary.id === null) {
        this.op.addSummary(summary);
      }
      ret.push([summary, this.op.summaryPortfolios[summary.id]]);
    }
    return ret;
  }

  show(summaryIds: Array<string>) {
    for (const summaryId of summaryIds) {
      this.invisibleSummary.delete(summaryId);
      this.storage.removeInvisibleSummary(summaryId);
    }
  }

  pushMarket(market: any) {
    if (!('master' in market)) {
      return;
    }
    const type = getMarketSummaryTypeFromMarketCondition(market);
    if (type === null || type === undefined) {
      return;
    }
    const summary: Summary = this.marketSummaries[type];
    if (!summary) {
      return;
    }
    if (summary.issueCodes[market.issue_code]) {
      return;
    }
    summary.addIssue(market.issue_code);
    this.updateMarkets[type] = true;
  }

  applyMarket() {
    for (const type of Object.keys(this.updateMarkets)) {
      const summary: Summary = this.marketSummaries[type];
      this.op.updateSummary(summary);
      this.updateSummary(summary, UpdateSummaryType.AddIssues);
    }
    this.updateMarkets = {};
  }

  updateHistory() {
    if (!this.historySummary) {
      console.error('History Summary is null');
      return;
    }
    const codes = this.historySummary.issueList.map(([issueCode, _]) => Number(issueCode)).sort();
    const newCodes = this.viewHistory.histories.map((history) => Number(history.code)).sort();
    let matchCodes = true;
    if (codes.length !== newCodes.length) {
      matchCodes = false;
    } else {
      for (let i = 0; i < codes.length; i++) {
        if (codes[i] !== newCodes[i]) {
          matchCodes = false;
        }
      }
    }

    this.historySummary.clear();
    for (const history of this.viewHistory.histories.slice().reverse()) {
      if (history.type === HistoryType.Stock && history.code in this.op.issueCodeMap) {
        this.historySummary.addIssue(history.code, 1);
      }
    }
    this.op.updateSummary(this.historySummary);
    if (!matchCodes) {
      this.updateSummary(this.historySummary, UpdateSummaryType.FullUpdate);
    }
  }

  updateOrderHistory() {
    if (!this.orderHistorySummary) {
      console.error('Order History Summary is null');
    }
    const codes = this.orderHistorySummary.issueList.map(([issueCode, _]) => Number(issueCode)).sort();
    const newCodes = this.viewHistory.orderHistory.slice().sort();
    let matchCodes = true;
    if (codes.length !== newCodes.length) {
      matchCodes = false;
    } else {
      for (let i = 0; i < codes.length; i++) {
        if (codes[i] !== newCodes[i]) {
          matchCodes = false;
        }
      }
    }
    this.orderHistorySummary.clear();
    for (const issueCode of this.viewHistory.orderHistory) {
      if (issueCode in this.op.issueCodeMap) {
        this.orderHistorySummary.addIssue(issueCode, 1);
      }
    }
    this.op.updateSummary(this.orderHistorySummary);
    if (!matchCodes) {
      this.updateSummary(this.orderHistorySummary, UpdateSummaryType.FullUpdate);
    }
  }
}
