import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
  ResizeService,
  RowDeletingEvent,
  Summary,
  SummaryType,
  Toast,
  ToasterService,
  ToastType,
  VgCollectionView,
} from '@argentumcode/brisk-common';
import { SummaryPortfolio } from 'fita3/src/app/portfolio/portfolio/portfolio.component';
import { StockListItem } from 'fita3/src/app/portfolio/summary-list.service';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { SummaryChangeEvent, SummaryChangeType, UpdateSummaryType } from 'fita3/src/app/core/summary-change-event';
import { NewPortfolioEntry, Portfolio, StockPortfolio } from 'fita3/src/app/portfolio/portfolio';
import { SmartPasteService } from 'fita3/src/app/portfolio/smart-paste.service';
import { SummaryListsService } from './summary-lists.service';
import { SmartListSummary } from 'fita3/src/app/core/summary.service';

@Injectable()
export class StockListService implements OnDestroy {
  item: StockListItem;
  private stocksSubject: BehaviorSubject<Array<any>> = null;
  public stockCollectionView: VgCollectionView = null;
  summary: SummaryPortfolio;
  stocks: Array<Portfolio>;
  editable: boolean;
  private updatingCount = 0;

  setCurrentItem = new Subject<Portfolio>();
  summarySelectionChanged = new BehaviorSubject<void>(null);

  summaryId = new BehaviorSubject<string>(null);
  summaryId$ = this.summaryId.asObservable();

  private _subscription = new Subscription();
  private get updating() {
    return this.updatingCount > 0;
  }

  constructor(
    private summaryListService: SummaryListsService,
    private cocomero: CocomeroService,
    private toaster: ToasterService,
    private resize: ResizeService,
    private smartPaste: SmartPasteService
  ) {
    this._subscription.add(
      this.cocomero.initializedSubject.subscribe(() => {
        this._subscription.add(
          this.cocomero.summaries.summaryChanged.subscribe((e) => {
            this.onSummaryChanged(e);
          })
        );
      })
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();

    if (this.stocksSubject) {
      this.stocksSubject.complete();
      this.stocksSubject = null;
    }
  }

  setSummaryId(summaryId: string) {
    this._subscription.add(
      this.summaryListService.getItem(summaryId).subscribe((item) => {
        if (item) {
          this.summary = item.summary as SummaryPortfolio;
          this.stocks = item.stocks.slice();
          this.editable = item.editable;
          this._updateStocks();
          this.summarySelectionChanged.next();
          this.summaryId.next(summaryId);
        }
      })
    );
  }

  selectFirst() {
    this.summaryListService.getFirstItem().subscribe((summaryId) => {
      if (summaryId) {
        this.summaryListService.getItem(summaryId).subscribe((item) => {
          if (item) {
            this.summary = item.summary as SummaryPortfolio;
            this.stocks = item.stocks.slice();
            this.editable = item.editable;
            this._updateStocks();
            this.summarySelectionChanged.next();
            this.summaryId.next(summaryId);
          }
        });
      }
    });
  }

  private _updateStocks() {
    if (this.stocksSubject) {
      this.stocksSubject.complete();
      this.stocksSubject = null;
    }
    this.stocksSubject = new BehaviorSubject(this.stocks);
    this.stockCollectionView = new VgCollectionView(this.stocksSubject.asObservable());
  }

  onSummaryChanged(e: SummaryChangeEvent) {
    switch (e.type) {
      case SummaryChangeType.DeleteSummary: {
        if (this.summary && e.summary === this.summary.summary) {
          this.summary = null;
        }
        break;
      }
      case SummaryChangeType.UpdateSummary: {
        if (!this.summary) {
          return;
        }
        if (e.summary !== this.summary.summary) {
          return;
        }
        if (e.updateType === UpdateSummaryType.RemoveIssues) {
          const r: Array<Portfolio> = this.stocks;
          for (let i = 0; i < r.length; i++) {
            if (e.indexes.includes(r[i].index)) {
              r.splice(i, 1);
              i--;
            }
            if (r[i] instanceof StockPortfolio) {
              (<StockPortfolio>r[i]).index = i + 1;
            }
          }
          this.stocksSubject.next(this.stocks);
        } else if (e.updateType === UpdateSummaryType.ChangeIssue) {
          const r: Array<Portfolio> = this.stocks;
          let targetItem: Portfolio = null;
          for (const index of e.indexes) {
            const tmp = this.cocomero.summaries.getNewStockPortfolio(e.summary.issueList[index - 1][0]);
            r[index - 1] = new StockPortfolio(tmp[1], tmp[0], tmp[2], this.cocomero.time, index);
            targetItem = r[index - 1];
          }
          this.stocksSubject.next(this.stocks);
          if (this.updating && targetItem) {
            this.setCurrentItem.next(targetItem);
          }
        } else if (e.updateType === UpdateSummaryType.AddIssues) {
          const r: Array<Portfolio> = this.stocks;
          let startIndex = r.length;
          let targetItem: Portfolio = null;
          if (this.editable) {
            r.pop();
            startIndex--;
          }
          for (let i = startIndex; i < e.summary.issueList.length; i++) {
            const tmp = this.cocomero.summaries.getNewStockPortfolio(e.summary.issueList[i][0]);
            const item = new StockPortfolio(tmp[1], tmp[0], tmp[2], this.cocomero.time, i + 1);
            r.push(item);
          }
          if (this.editable) {
            const item = new NewPortfolioEntry();
            targetItem = item;
            r.push(item);
            this.stocksSubject.next(this.stocks);
            console.log('updating count', this.updatingCount, targetItem);
            if (this.updating && targetItem) {
              // TODO: setTimeoutを利用せずに実現できないか
              this.setCurrentItem.next(targetItem);
            }
          } else {
            this.stocksSubject.next(this.stocks);
          }
        } else if (e.updateType === UpdateSummaryType.FullUpdate) {
          const r: Array<Portfolio> = this.stocks;
          r.splice(0, r.length);
          let index = 0;
          for (const [issueCode, _] of e.summary.issueList) {
            const tmp = this.cocomero.summaries.getNewStockPortfolio(issueCode);
            const item = new StockPortfolio(tmp[1], tmp[0], tmp[2], this.cocomero.time, ++index);
            r.push(item);
          }
          this.stocksSubject.next(this.stocks);
        }
        break;
      }
    }
  }

  updateIssueCode(item: Portfolio, issueCode: number) {
    this.deferUpdate(() => {
      if (!this.stocks.includes(item)) {
        return;
      }
      if (!this.summary) {
        return;
      }
      const summary: Summary = this.summary.summary;
      if (summary.type !== SummaryType.WatchList) {
        return;
      }
      if (!this.cocomero.hasIssueCode(issueCode)) {
        console.log('invalid issue code');
        if (!isNaN(issueCode) && issueCode) {
          this.toaster.add(new Toast(`銘柄コード${issueCode}は存在しません`, ToastType.Error));
        }
        return;
      }
      if (item instanceof NewPortfolioEntry) {
        summary.addIssue(issueCode, 1);
        this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
      } else {
        summary.changeIssue(item.index - 1, issueCode);
        this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.ChangeIssue, [item.index]);
      }
      this.cocomero.changeIssueCode(issueCode);
      this.cocomero.summaries.saveWatchList(summary);
    });
  }

  private deferUpdate(func: () => void) {
    try {
      this.updatingCount++;
      func();
    } finally {
      this.updatingCount--;
    }
  }

  rowDelete(event: RowDeletingEvent) {
    if (!this.editable) {
      return;
    }
    if (!this.summary) {
      return;
    }
    const summary: Summary = this.summary.summary;
    if (summary.type !== SummaryType.WatchList) {
      return;
    }
    let index = this.stocks.length + 1;
    const removeIndexes = [];
    let hasNewPortfolio = false;
    const itemsRemoved = event.itemSet;
    itemsRemoved.sort((a, b) => b.index - a.index);
    for (const p of itemsRemoved) {
      index = Math.min(index, p.index);
      if (p instanceof NewPortfolioEntry) {
        hasNewPortfolio = true;
      } else {
        summary.removeIssue(p.index - 1);
        removeIndexes.push(p.index);
      }
      this.stocks.splice(p.index - 1, 1);
    }
    if (hasNewPortfolio) {
      this.stocks.push(new NewPortfolioEntry());
    }
    this.stocksSubject.next(this.stocks);
    this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.RemoveIssues, removeIndexes);
    this.cocomero.summaries.saveWatchList(summary);
  }

  paste(event: ClipboardEvent) {
    if (!this.editable) {
      // 編集不能リストでは貼り付け処理を行なわない
      return;
    }
    this.smartPaste.paste(event).subscribe((result) => {
      if (result.canceled) {
        return;
      }
      if (result.emptyClipboard || result.issueCodes.length === 0) {
        this.smartPaste.showToaster(result);
        return;
      }
      if (!this.summary) {
        return;
      }
      const summary: Summary = this.summary.summary;
      if (summary.type !== SummaryType.WatchList) {
        return;
      }
      this.stocks.pop();
      for (const issueCode of result.issueCodes) {
        summary.addIssue(issueCode);
      }
      this.stocks.push(new NewPortfolioEntry());
      this.stocksSubject.next(this.stocks);
      this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.AddIssues);
      this.cocomero.summaries.saveWatchList(summary);
      this.smartPaste.showToaster(result);
    });
  }

  onDraggedRow([oldRow, newRow]: [number, number]) {
    this.deferUpdate(() => {
      if (!this.summary) {
        return;
      }
      const summary: Summary = this.summary.summary;
      if (summary.type !== SummaryType.WatchList) {
        return;
      }
      if (summary.issueList.length === 0) {
        return;
      }
      if (newRow >= summary.issueList.length) {
        newRow--;
      }
      const oldIssueCode = summary.issueList[oldRow][0];
      const indexes = [];
      const d = oldRow < newRow ? 1 : -1;
      for (let i = oldRow; i !== newRow; i += d) {
        summary.changeIssue(i, summary.issueList[i + d][0]);
        indexes.push(i + 1); // Indexは+1された値
      }
      summary.changeIssue(newRow, oldIssueCode);
      indexes.push(newRow + 1);
      this.cocomero.summaries.updateSummary(summary, UpdateSummaryType.ChangeIssue, indexes);
      this.cocomero.summaries.saveWatchList(summary);
    });
  }

  refreshSmartList() {
    if (!this.stocks) {
      return;
    }
    const r: Array<Portfolio> = this.stocks;
    const oldPortfolio = r.splice(0, r.length);
    let index = 0;
    if (this.summary.summary instanceof SmartListSummary) {
      this.summary.summary.update(this.cocomero.op);
    }
    for (const [portfolio, master, info] of this.cocomero.summaries.getStockPortfolio(this.summary.summary.issueList)) {
      const portfolioIdx = oldPortfolio.findIndex((p) => p instanceof StockPortfolio && p.master === master);
      if (portfolioIdx >= 0) {
        const original = oldPortfolio[portfolioIdx] as StockPortfolio;
        oldPortfolio.splice(portfolioIdx, 1);
        original.index = ++index;
        r.push(original);
      } else {
        r.push(new StockPortfolio(master, portfolio, info, this.cocomero.time, ++index));
      }
    }
    this.stocksSubject.next(this.stocks);
  }
}
