import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { StockListService } from '../../stock-list.service';
import { HeaderComponent, ResizeService } from '@argentumcode/brisk-common';
import { GlHeaderItem, GlOnHide, GlOnResize, GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { StockDetailsService } from '../../stock-details.service';
import { Container } from 'golden-layout';
import { CellDoubleClickService } from '../../cell-double-click.service';

export interface ChartViewState {
  columns: number;
}

const chartWidth = 340;

@Component({
  selector: 'app-fita4-chart-grid-panel',
  templateUrl: './chart-grid-panel.component.html',
  styleUrls: ['./chart-grid-panel.component.scss'],
})
export class ChartGridPanelComponent implements OnInit, GlOnResize, GlOnShow, GlOnHide, OnDestroy {
  private resizeSubscription: Subscription;
  state: ChartViewState;
  stateSubj: BehaviorSubject<ChartViewState>;
  state$: Observable<ChartViewState>;

  visible = false;
  get collection() {
    return this.stockListService.stockCollectionView;
  }
  itaColumnCount = 0;
  constructor(
    private stockListService: StockListService,
    private stockDetailsService: StockDetailsService,
    private element: ElementRef,
    private resize: ResizeService,
    private cellDoubleClick: CellDoubleClickService,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
    this.itaColumnCount = 1;
    const state: Partial<ChartViewState> = this.container.getState() || {};
    this.state = {
      columns: state.columns || 4,
    };
    this.stateSubj = new BehaviorSubject<ChartViewState>(this.state);
    this.state$ = this.stateSubj.asObservable();
  }

  ngOnInit(): void {
    this.visible = !this.container.isHidden;
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
    this.stateSubj.complete();
  }

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
    setTimeout(() => {
      this.resize.dispatchResize();
    });
    this.visible = true;
    console.log('glOnShowChartGrid');
  }

  glOnHide() {
    this.visible = false;
  }

  onResize() {
    const width = this.element.nativeElement.offsetWidth;
    const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;
    if (normalizedWidth === 0) {
      return;
    }
    this.itaColumnCount = Math.max(1, Math.floor(normalizedWidth / chartWidth));
  }

  onStockSelected(issueCode: number) {
    this.stockDetailsService.changeIssueCodeRequest(Number(issueCode));
  }

  updateColumns(columns: number) {
    columns = Math.round(columns);
    if (columns === undefined || columns === null || isNaN(columns)) {
      columns = 4;
    }
    if (columns < 1) {
      columns = 1;
    }
    if (columns > 10) {
      columns = 10;
    }
    this.updateState({ ...this.state, columns });
  }

  updateState(state: ChartViewState) {
    this.state = state;
    this.stateSubj.next(state);
    this.container.setState(this.state);
  }

  onCellDblClicked() {
    this.cellDoubleClick.doubleClicked();
  }
}
