import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sochi',
})
export class SochiPipe implements PipeTransform {
  transform(value: string): any {
    if (value === 'URIKIN') {
      return '売禁';
    } else if (value === 'CHUIKANKI') {
      return '注喚';
    }
    return '';
  }
}
