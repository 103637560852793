import { Component, Host, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MiniChartComponent } from '../mini-chart/mini-chart.component';
import { StockWrapper } from '../../brisk-core/stock-wrapper';
import { MiniChartSource } from '../mini-chart-source';
import { Subject, Subscription } from 'rxjs';
import { FlexConfig, FLEX_CONFIG } from '../../flex';

@Component({
  selector: 'brisk-mini-chart-from-stock-view',
  templateUrl: './mini-chart-from-stock-view.component.html',
  styleUrls: ['./mini-chart-from-stock-view.component.scss'],
})
export class MiniChartFromStockViewComponent implements OnChanges, OnDestroy {
  constructor(@Host() private miniChart: MiniChartComponent, @Inject(FLEX_CONFIG) private flexConfig: FlexConfig) {}

  @Input()
  public stockWrapper: StockWrapper;

  private miniChartSource: MiniChartSource = null;
  private updatedSubscription: Subscription;
  private miniChartUpdateSubject: Subject<number>;
  private lastQrIndex = 0;

  ngOnDestroy(): void {
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
    }
    if (this.miniChartUpdateSubject) {
      this.miniChartUpdateSubject.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stockWrapper' in changes) {
      if (this.updatedSubscription) {
        this.updatedSubscription.unsubscribe();
      }
      if (this.stockWrapper) {
        this.updatedSubscription = this.stockWrapper.updated.subscribe(() => {
          this.update();
        });
        this.init();
      }
    }
  }

  init() {
    const view = this.stockWrapper.base;
    if (this.miniChartUpdateSubject) {
      this.miniChartUpdateSubject.complete();
    }
    this.miniChartUpdateSubject = new Subject<number>();
    this.miniChartSource = new MiniChartSource();
    this.miniChartSource.updated = this.miniChartUpdateSubject.asObservable();
    this.miniChartSource.lastPrices = new Array<number>(this.flexConfig.ohlcLength * 5);
    this.miniChartSource.lastPrices.fill(undefined);
    this.miniChartSource.quantity = new Array<number>(this.flexConfig.ohlcLength);
    this.miniChartSource.quantity.fill(0);

    // From QR
    for (let i = 0; i < view.qr.length; i++) {
      const idx = this.timestampToIndex(view.qr[i].timestamp);
      this.miniChartSource.lastPrices[idx] = view.qr[i].price10 / view.master.basePrice10 - 1;
    }
    this.lastQrIndex = view.qr.length;

    // From OHLC
    for (let i = 0; i < view.ohlc.length; i++) {
      this.miniChartSource.quantity[i] = view.ohlc[i].turnover10 / 10 / 1000000;
    }

    // VWAP
    if (view.qr.length > 0) {
      const vwap10 = view.turnover10 / view.volume;
      this.miniChartSource.vwap = vwap10 / view.master.basePrice10 - 1;
      this.miniChartSource.vwapPrice = Math.round(vwap10) / 10.0;
      this.miniChartSource.vwapIndex = this.timestampToIndex(view.qr[view.qr.length - 1].timestamp);
    }
    this.miniChart.miniChartSource = this.miniChartSource;
  }

  update() {
    const view = this.stockWrapper.base;
    if (this.lastQrIndex === view.qr.length) {
      return;
    }
    // From QR
    for (let i = this.lastQrIndex; i < view.qr.length; i++) {
      const idx = this.timestampToIndex(view.qr[i].timestamp);
      this.miniChartSource.lastPrices[idx] = view.qr[i].price10 / view.master.basePrice10 - 1;
    }
    if (view.qr.length > 0) {
      const vwap10 = view.turnover10 / view.volume;
      this.miniChartSource.vwap = vwap10 / view.master.basePrice10 - 1;
      this.miniChartSource.vwapPrice = Math.round(vwap10) / 10.0;
      this.miniChartSource.vwapIndex = this.timestampToIndex(view.qr[view.qr.length - 1].timestamp);
    } else {
      this.miniChartSource.vwap = undefined;
    }

    this.lastQrIndex = view.qr.length;
    // From OHLC
    for (let i = 0; i < view.ohlc.length; i++) {
      this.miniChartSource.quantity[i] = view.ohlc[i].turnover10 / 10 / 1000000;
    }
    if (this.miniChartUpdateSubject) {
      this.miniChartUpdateSubject.next();
    }
  }
  // Timestamp -> [0, ohlcLength * 5)
  timestampToIndex(timestamp: number): number {
    timestamp -= 9 * 60 * 60 * 1000000;
    timestamp = Math.trunc((timestamp - 1) / (1 * 60 * 1000000));
    if (timestamp >= 150) {
      timestamp -= 60;
    }
    return Math.max(0, Math.min(timestamp, this.flexConfig.ohlcLength * 5 - 1));
  }
}
