<mat-dialog-content>
  <p>
    表示するカラムにチェックを入れてください。
  </p>
  <div class="c-grid-column-dialog">
    <brisk-vg-grid
      #grid
      class="vg-grid--no-cell-border column-list"
      [itemsSource]="columns"
      briskVgGridFlexibleRowSize
      [showRowHeader]="false"
      [addDummyRow]="false"
      [selectionMode]="'SingleRow'"
    >
      <brisk-vg-grid-column binding="header" [width]="1" widthUnit="Fr">
        <div *briskVgGridCellTemplate="let item; source: columns" class="add-padding" [class.u-fixed-row]="item.sticky">
          <brisk-checkbox [disabled]="item.disableHide" [(ngModel)]="item.visible" (ngModelChange)="dirty = true"></brisk-checkbox
          >{{ item.name }}
        </div>
      </brisk-vg-grid-column>
    </brisk-vg-grid>
    <div class="actions">
      <button
        class="c-btn c-btn--default"
        (click)="moveToDelta(-1)"
        [disabled]="grid.selectedIndex === undefined || grid.selectedIndex === 0"
      >
        上へ移動
      </button>
      <button
        class="c-btn c-btn--default"
        (click)="moveToDelta(1)"
        [disabled]="grid.selectedIndex === undefined || grid.selectedIndex === columnsArray.length - 1"
      >
        下へ移動
      </button>
      <button class="c-btn c-btn--default" (click)="setFixed()" [disabled]="grid.selectedIndex === undefined">
        <ng-container *ngIf="grid.selectedItem?.fixed; else fixed">
          固定解除
        </ng-container>
        <ng-template #fixed>
          固定表示
        </ng-template>
      </button>
      <button class="c-btn c-btn--default" (click)="reset()">
        初期状態へリセット
      </button>
    </div>
  </div>
  <div>
    カラムの設定を変更後、現在のソート条件とフィルタ条件はリセットされます。
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="c-btn c-btn--default" type="submit" (click)="update()">
    設定
  </button>
  <button class="c-btn c-btn--default" type="button" (click)="close()">
    キャンセル
  </button>
</mat-dialog-actions>
