import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { StockOrderSummaryForHeader } from '@argentumcode/brisk-common';

// 証券会社毎の発注システムとfita4の間のConnectorの役割
export interface OrderOperator {
  // ログインなどの発注系に必要な処理が完了した場合に発行される。完了するまでUIを表示しない
  initialized: Observable<void>;

  // 銘柄の更新が必要な時に発行する。銘柄コードを指定した場合は、その銘柄のみ更新が必要であり、nullを指定した場合は全銘柄を更新する必要がある
  updateStock: Observable<number | null>;

  // 発注の設定。initializedの発行後にUIから読み出すことを想定している。Serviceから利用する場合はconfig$ を利用するこお。
  config: OrderUIConfig;

  // 発注の設定。初回設定後に1度だけ発行され、completeされる。
  config$: Observable<OrderUIConfig>;

  // リアルタイムAPIへの接続状況。再接続中の場合はTrue。
  reconnecting$: Observable<boolean>;

  // 銘柄毎の注文サマリーの取得
  getStockInfo(issueCode: number): Observable<StockOrderSummaryForHeader>;
}

export const ORDER_OPERATOR_TOKEN = new InjectionToken<OrderOperator>('OrderOperator');

// Order UI Service
// OrderOperatorと違いWindow毎に作成される前提であり、主にUI制御に利用する
export interface OrderUiService {
  tryShowItaOrderTutorial(force: boolean);
}

export const ORDER_UI_SERVICE_TOKEN = new InjectionToken<OrderUiService>('OrderUiService');

interface OrderUIConfigEnabled {
  // 発注機能を利用するかどうか
  orderEnabled: true;

  // 信用が利用可能かどうか
  marginEnabled: boolean;
}

interface OrderUIConfigDisabled {
  // 発注機能を利用するかどうか
  orderEnabled: false;

  // ブラウザが原因の場合にダイアログを表示するかどうか
  showBrowserDialog: boolean;
}

// 発注に関する共通の情報。
export type OrderUIConfig = OrderUIConfigEnabled | OrderUIConfigDisabled;
