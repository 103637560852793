<brisk-vg-grid
  briskVgGridFlexibleRowSize
  class="jsfcGrid"
  [itemsSource]="jsfcEntries"
  [addDummyRow]="true"
  [showRowHeader]="true"
  [minWidth]="minWidth"
>
  <brisk-vg-grid-column name="date" header="日付" binding="date" width="*" align="center">
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <span>{{ item.date | slice: 2 }}</span>
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column [name]="'sochi'" header="日証金 措置" [binding]="'sochi'" [width]="'*'" [align]="'center'">
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.sochi | sochi }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'jsfcLongShares'"
    header="日証金 買"
    [binding]="'jsfcLongShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.jsfcLongShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'jsfcShortShares'"
    header="日証金 売"
    [binding]="'jsfcShortShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.jsfcShortShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'jsfcDiffShares'"
    header="日証金 差"
    [binding]="'jsfcDiffShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.jsfcDiffShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'standardizedLongShares'"
    header="制度信用 買"
    [binding]="'standardizedLongShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.standardizedLongShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'standardizedShortShares'"
    header="制度信用 売"
    [binding]="'standardizedShortShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.standardizedShortShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'standardizedDiffShares'"
    header="制度信用 差"
    [binding]="'standardizedDiffShares'"
    [width]="'*'"
    align="right"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.standardizedDiffShares | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'gyakuhibuFee'"
    header="逆日歩"
    [binding]="'gyakuhibuFee'"
    [width]="'0.7*'"
    [align]="'right'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <ng-container *ngIf="isDecimal(item.gyakuhibuFee)">{{
        item.gyakuhibuFee | fastDecimal: { separateComma: true, fractionDigits: 2 }
      }}</ng-container>
      <ng-container *ngIf="!isDecimal(item.gyakuhibuFee)">{{ item.gyakuhibuFee | fastDecimal: { separateComma: true } }}</ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'gyakuhibuFeePercent'"
    header="逆日歩(%)"
    [binding]="'gyakuhibuFeePercent'"
    [width]="'1*'"
    [align]="'right'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.gyakuhibuFeePercent | fastDecimal: { separateComma: true, fractionDigits: 4 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column [name]="'gyakuhibuFeeDayCount'" header=" " [binding]="'gyakuhibuFeeDayCount'" [width]="'0.25*'" [align]="'center'">
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.gyakuhibuFeeDayCount | fastDecimal: { separateComma: true, fractionDigits: 0 } }}
    </ng-template>
  </brisk-vg-grid-column>

  <brisk-vg-grid-column
    cssClass="add-padding"
    [name]="'gyakuhibuMaxFee'"
    [binding]="'gyakuhibuMaxFee'"
    header="最大"
    [width]="'0.7*'"
    [align]="'right'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <ng-container *ngIf="isDecimal(item.gyakuhibuMaxFee)">{{
        item.gyakuhibuMaxFee | fastDecimal: { separateComma: true, fractionDigits: 2 }
      }}</ng-container>
      <ng-container *ngIf="!isDecimal(item.gyakuhibuMaxFee)">{{
        item.gyakuhibuMaxFee | fastDecimal: { separateComma: true }
      }}</ng-container>
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>

<ng-container>
  <ng-template briskAssist="jsfc" #assistBtn="briskAssist">
    <button type="button" class="c-assist-popover-button p-assist-jsfc" placement="bottom" [ngbPopover]="popContent"></button>

    <ng-template #popContent>
      <div class="c-assist-popover__container">
        <div>
          <div class="c-assist-popover__description">
            <p class="c-assist-popover__text">日証金が発表する貸借取引及び東証発表の信用取引情報</p>
          </div>
          <div class="c-assist-popover__buttons" *ngIf="assistBtn.openable">
            <a class="c-assist-popover__button" (click)="assistBtn.openAssist($event)" href="#">
              <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>もっと詳しく
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
