<div>
  <p>BRiSK Nextはスマホ・タブレット未対応です。以下の環境でご利用ください。</p>
  <div>
    推奨動作環境
    <div class="environments">
      <div class="os">Windows10</div>
      <div>
        <div>- Google Chrome</div>
        <div>- Microsoft Edge</div>
      </div>
      <div class="os">Mac</div>
      <div>
        <div>- Google Chrome</div>
      </div>
    </div>
  </div>
</div>
