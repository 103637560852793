import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketsGridComponent } from './markets-grid/markets-grid.component';
import { MarketsLineComponent } from './markets-line/markets-line.component';
import { DemoMarketsLineComponent } from './demo-markets-line/demo-markets-line.component';
import { MarketConditionsService } from './market-conditions.service';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { VgGridModule } from '../vg-grid/vg-grid.module';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  imports: [CommonModule, WjGridModule, WjInputModule, WjGridFilterModule, BriskCommonModule, NgbPopoverModule, IconsModule, VgGridModule],
  declarations: [MarketsGridComponent, MarketsLineComponent, DemoMarketsLineComponent],
  exports: [MarketsGridComponent, MarketsLineComponent, DemoMarketsLineComponent],
  providers: [MarketConditionsService],
})
export class MarketsModule {
  constructor() {}
}
