import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { WjListBox, WjPopup } from '@grapecity/wijmo.angular2.input';
import { CocomeroService } from '../../core/cocomero.service';
import { bom, unparse } from 'papaparse';
import { saveAs } from 'file-saver';
import { WatchListEntry, WatchListService } from '../../core/watch-list.service';
import { PriceChangesPipe, QuoteSideStrPipe } from '@argentumcode/brisk-common';
import { PercentPipe } from '@angular/common';

class WatchList {
  public items: Array<WatchListEntry>;
  public name: string;
  public summaryId: string;
  public checked: boolean;
}

@Component({
  selector: 'app-csv-export-dialog',
  templateUrl: './csv-export-dialog.component.html',
  styleUrls: ['./csv-export-dialog.component.scss'],
})
export class CsvExportDialogComponent implements OnInit {
  @ViewChild('csvExportDialog', { static: true })
  public showIndexDialog: WjPopup;

  public summaries: Array<WatchList> = [];

  constructor(
    private cocomero: CocomeroService,
    private watchList: WatchListService,
    private changeDetectorRef: ChangeDetectorRef,
    private quotePipe: QuoteSideStrPipe,
    private priceChanges: PriceChangesPipe,
    private percent: PercentPipe
  ) {}

  ngOnInit() {}

  show(initialList: Array<string> = []) {
    this.summaries = this.watchList.all().map((watchList) => {
      const s = new WatchList();
      s.name = watchList.name;
      s.summaryId = `watchlist:${watchList.id}`;
      s.checked = initialList.includes(s.summaryId);
      s.items = watchList.entries;
      return s;
    });
    console.log(this.summaries);
    this.showIndexDialog.show(true);
  }

  export(dialog: WjPopup, listBox: WjListBox) {
    const ret: Array<Array<any>> = [];
    let fileName;
    for (const item of listBox.checkedItems) {
      const summary = <WatchList>item;
      const issueList = [];
      for (const wlItem of summary.items) {
        issueList.push([wlItem.issueCode, 1]);
      }
      for (const [portfolio, master, info] of this.cocomero.summaries.getStockPortfolio(issueList)) {
        ret.push([
          summary.name,
          master.issueCode,
          master.name,
          portfolio.lastPrice10 ? (portfolio.lastPrice10 / 10).toFixed(1) : null,
          portfolio.lastPrice10 ? this.priceChanges.transform(portfolio.lastPrice10 / master.basePrice10 - 1) : null,
          this.quotePipe.transform(portfolio.quoteFlag, portfolio.quoteSide),
          portfolio.volume,
          portfolio.turnover10 / 10,
          info.lastDayTurnover ? this.percent.transform(portfolio.turnover10 / 10 / info.lastDayTurnover, '1.2-2') : null,
          portfolio.turnover10 ? this.priceChanges.transform(portfolio.lastPrice10 / (portfolio.turnover10 / portfolio.volume) - 1) : null,
          portfolio.openPrice10
            ? this.priceChanges.transform(portfolio.openPrice10 / master.basePrice10 - 1)
            : portfolio.predictPrice10
            ? this.priceChanges.transform(portfolio.predictPrice10 / master.basePrice10 - 1)
            : null,
          portfolio.lastPrice10 && portfolio.openPrice10
            ? this.priceChanges.transform(portfolio.lastPrice10 / portfolio.openPrice10 - 1)
            : null,
          master.prefix,
          master.industryName,
          info.calcSharesOutstanding ? (info.calcSharesOutstanding * master.basePrice10) / 10 : null,
        ]);
      }
      fileName = summary.name;
    }
    let csv = '';
    const fields = [
      '銘柄リスト名',
      '銘柄コード',
      '銘柄名',
      '現値',
      '騰落率',
      '',
      '出来高/株数',
      '売買代金/円',
      '代金前日比',
      'VWAP比',
      '寄騰落率',
      '始値比',
      '',
      '業種',
      '時価総額/円',
    ];
    if (listBox.checkedItems.length === 1) {
      csv = unparse({
        fields: fields.slice(1),
        data: ret.map((a) => a.slice(1)),
      });
    } else {
      csv = unparse({
        fields: fields,
        data: ret,
      });
      fileName = 'issue-lists';
    }
    // Excel対策にBOMを付ける
    const blob = new Blob([String.fromCharCode(0xfeff), csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, fileName + '.csv', true);
    dialog.hide();
  }

  onCheckedItemChanged() {
    this.changeDetectorRef.markForCheck();
  }
}
