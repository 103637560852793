import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bindFunction',
})
export class BindFunctionPipe implements PipeTransform {
  transform(value: any, name: string): any {
    return value[name].bind(value);
  }
}
