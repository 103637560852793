<div
  #wrapper
  id="qrChartWrapper"
  (briskResized)="setSymbolSize()"
  [ngStyle]="{ visibility: this.dataSource ? '' : 'hidden' }"
  (mousedown)="onMouseDown($event)"
>
  <wj-flex-chart
    *ngIf="drawInfo"
    #qrChart
    class="qrChart"
    (rendered)="onRendered()"
    [itemsSource]="qrChartData"
    [bindingX]="'x'"
    [plotMargin]="plotMarginOHLC"
    [tooltipContent]="drawInfo | bindFunction: 'qrTooltip'"
  >
    <wj-flex-chart-series
      *ngFor="let _ of dummyArray"
      [symbolSize]="symbolSize"
      [chartType]="'Candlestick'"
      [binding]="normalized ? 'high, low, open, close' : 'highPrice10, lowPrice10, openPrice10, closePrice10'"
      [altStyle]="positiveStyle"
      [style]="negativeStyle"
    >
    </wj-flex-chart-series>
    <ng-container *ngIf="normalized && showLineChart">
      <wj-flex-chart-series
        *ngFor="let lineChartData of lineChartSegments"
        [symbolSize]="symbolSize"
        [chartType]="'Line'"
        [itemsSource]="lineChartData"
        [binding]="'rate'"
        [style]="lineChartStyle"
      >
      </wj-flex-chart-series>
      <wj-flex-chart-series
        *ngFor="let _ of dummyArray"
        [symbolSize]="pointSymbolSize"
        [chartType]="'Scatter'"
        [itemsSource]="lineChartPoint"
        [binding]="'rate'"
        [style]="lineChartStyle"
      >
      </wj-flex-chart-series>
    </ng-container>
    <!-- 非表示 -->
    <wj-flex-chart-axis
      [wjProperty]="'axisX'"
      [axisLine]="false"
      [majorGrid]="false"
      [minorGrid]="false"
      [labels]="false"
      [min]="drawInfo.axisXMin"
      [max]="drawInfo.axisXMax"
      [majorUnit]="drawInfo.axisXMajorUnit"
    ></wj-flex-chart-axis>

    <wj-flex-chart-axis
      [wjProperty]="'axisY'"
      [majorGrid]="true"
      [majorUnit]="normalized ? drawInfo.axisYMajorUnit : null"
      [itemFormatter]="normalized ? priceChangeFormatter : price10Formatter"
      [min]="
        normalized
          ? -(showLineChart ? chartTypeInfo.lineChartMaxDiff : chartTypeInfo.maxDiff) - 0.0001
          : chartTypeInfo.minPrice10 || chartTypeInfo.basePrice10 * 0.99
      "
      [max]="
        normalized
          ? (showLineChart ? chartTypeInfo.lineChartMaxDiff : chartTypeInfo.maxDiff) + 0.0001
          : chartTypeInfo.maxPrice10 || chartTypeInfo.basePrice10 * 1.01
      "
    ></wj-flex-chart-axis>
  </wj-flex-chart>

  <wj-flex-chart
    *ngIf="drawInfo"
    #qrQuantityChart
    class="qrQuantityChart"
    [plotMargin]="plotMarginQuantity"
    [symbolSize]="symbolSize"
    [chartType]="'Column'"
    [itemsSource]="qrChartData"
    [bindingX]="'x'"
    [stacking]="'Stacked'"
    [tooltipContent]="drawInfo | bindFunction: 'quantityTooltip'"
  >
    <wj-flex-chart-axis
      [wjProperty]="'axisX'"
      [axisLine]="true"
      [min]="drawInfo.axisXMin"
      [max]="drawInfo.axisXMax"
      [majorUnit]="drawInfo.axisXMajorUnit"
      [itemFormatter]="drawInfo | bindFunction: 'axisXItemFormatter'"
    ></wj-flex-chart-axis>

    <wj-flex-chart-axis
      [wjProperty]="'axisY'"
      [majorGrid]="true"
      [majorUnit]="chartTypeInfo.maxQuantity | quantityMajorUnit"
      [min]="0"
      [max]="chartTypeInfo.maxQuantity || 1"
      [itemFormatter]="drawInfo | bindFunction: 'quantityXItemFormatter'"
    ></wj-flex-chart-axis>

    <wj-flex-chart-series [binding]="'openQuantity'" [style]="openQuantityStyle"> </wj-flex-chart-series>
    <wj-flex-chart-series #qrQuantitySeries [binding]="'quantity'" [style]="quantityStyle"> </wj-flex-chart-series>
    <wj-flex-chart-series [binding]="'closeQuantity'" [style]="closeQuantityStyle"> </wj-flex-chart-series>
  </wj-flex-chart>
</div>

<span #themeElement class="d-none"></span>
