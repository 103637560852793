import { Injectable } from '@angular/core';
import { parse as parseUA } from 'woothee';
import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  readonly isChrome: boolean;
  readonly isWindows: boolean;

  parsedUserAgent: {
    category?: string;
    name?: string;
    version?: string;
    vendor?: string;
    os?: string;
    os_version?: string;
  };

  constructor(private windowRef: WindowRefService) {
    this.parsedUserAgent = parseUA(windowRef.nativeWindow.navigator.userAgent);
    this.isChrome = this.parsedUserAgent.name === 'Chrome';
    this.isWindows = this.parsedUserAgent.os && this.parsedUserAgent.os.startsWith('Windows');
  }

  get IE(): boolean {
    return this.parsedUserAgent.name === 'Internet Explorer';
  }

  get isMobile(): boolean {
    return this.parsedUserAgent.category === 'smartphone' || this.parsedUserAgent.category === 'mobilephone';
  }

  get isChromeForiOS(): boolean {
    return (this.parsedUserAgent.os === 'iPad' || this.parsedUserAgent.os === 'iPhone') && this.parsedUserAgent.name === 'Chrome';
  }

  get isWindows10(): boolean {
    return this.parsedUserAgent.os === 'Windows 10';
  }

  get isEdge(): boolean {
    return this.parsedUserAgent.name === 'Edge';
  }

  get majorVersion(): number {
    return parseInt(this.parsedUserAgent.version, 10);
  }

  get isiOS() {
    return this.parsedUserAgent.os === 'iPad' || this.parsedUserAgent.os === 'iPhone';
  }

  get isWin32(): boolean {
    if (this.parsedUserAgent.os && !this.parsedUserAgent.os.startsWith('Windows')) {
      return false;
    }
    const ua = this.windowRef.nativeWindow.navigator.userAgent;
    if (!ua.match(/Win64|WOW64/i)) {
      return true;
    }
    return false;
  }

  get isMac(): boolean {
    return this.parsedUserAgent.os && this.parsedUserAgent.os.startsWith('Mac');
  }
}
