import { Inject, Injectable } from '@angular/core';
import { WindowRefService } from '../brisk-browser/window-ref.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private window: WindowRefService) {}

  alert(message) {
    this.window.nativeWindow.alert(message);
  }
}
