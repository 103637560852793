export class JSFCEntry {
  constructor(
    public date?: Date,
    public sochi?: string,
    public kakuhoLongShares?: number,
    public kakuhoShortShares?: number,
    public sokuhoLongShares?: number,
    public sokuhoShortShares?: number,
    public standardizedLongShares?: number,
    public standardizedShortShares?: number,
    public gyakuhibuFee?: number,
    public gyakuhibuFeePercent?: number,
    public gyakuhibuFeeDayCount?: number,
    public gyakuhibuMaxFee?: number
  ) {}

  // 表示用項目
  get jsfcLongShares(): number {
    if (this.kakuhoLongShares !== null) {
      return this.kakuhoLongShares;
    }
    return this.sokuhoLongShares;
  }

  get jsfcShortShares(): number {
    if (this.kakuhoShortShares !== null) {
      return this.kakuhoShortShares;
    }
    return this.sokuhoShortShares;
  }

  get jsfcDiffShares(): number {
    if (this.jsfcLongShares !== null) {
      return this.jsfcLongShares - this.jsfcShortShares;
    }
    return null;
  }

  get standardizedDiffShares(): number {
    if (this.standardizedLongShares !== null) {
      return this.standardizedLongShares - this.standardizedShortShares;
    }
    return null;
  }
}
