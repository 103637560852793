import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { ResizeService } from './resize.service';
import { Subscription } from 'rxjs';

class ResizeEvent {
  public constructor(public readonly width: number, public readonly height: number) {}
}

@Directive({
  selector: '[briskResized]',
})
export class ResizedDirective implements OnDestroy {
  @Output()
  public briskResized = new EventEmitter<ResizeEvent>();

  private resizeSubscription: Subscription = null;
  private oldHeight: number = null;
  private oldWidth: number = null;

  constructor(private element: ElementRef, private resize: ResizeService) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
    this.onResize();
  }

  onResize() {
    const rect: ClientRect = this.element.nativeElement.getBoundingClientRect();
    if (this.oldWidth === rect.width && this.oldHeight === rect.height) {
      return;
    }
    this.oldWidth = rect.width;
    this.oldHeight = rect.height;
    this.briskResized.emit(new ResizeEvent(rect.width, rect.height));
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
  }
}
