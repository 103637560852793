import { ChartDataSourceAbstract } from './chart-data-source-abstract';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable, asyncScheduler } from 'rxjs';
import { ObservableArray } from '@grapecity/wijmo';
import { ChartInfo, ChartTypeInfo } from './chart-info';
import { ChartDrawInfo1day, ChartDrawInfo1month, ChartDrawInfo1week, ChartDrawInfo5min } from './chart-draw-info';
import { StockView } from '../flex';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { OHLCResponse, OHLCResponseExtended } from './ohlc';
import { QRChartItem, LineChartItem } from './chart-data';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  getISOWeek,
  getISOYear,
  parse,
  setISOWeek,
  setISOYear,
  startOfISOWeek,
} from 'date-fns';
import { ChartSource, LineChartTodayData } from './chart-source';
import { throttle, throttleTime } from 'rxjs/operators';

@Injectable()
export class ChartDataSourceStock extends ChartDataSourceAbstract implements OnDestroy {
  static readonly EPS = 1e-4;

  private updatedSubscription: Subscription = null;

  public get date(): Date {
    return this.stockWrapper && this.stockWrapper.date;
  }

  public readonly ohlc5min: ObservableArray = new ObservableArray();
  public readonly ohlc1day: ObservableArray = new ObservableArray();
  public readonly ohlc1week: ObservableArray = new ObservableArray();
  public readonly ohlc1month: ObservableArray = new ObservableArray();

  public line5minSegments: Array<ObservableArray> = [];
  public line1daySegments: Array<ObservableArray> = [];
  public line1weekSegments: Array<ObservableArray> = [];
  public line1monthSegments: Array<ObservableArray> = [];

  public readonly line5minPoint: ObservableArray = new ObservableArray();
  public readonly line1dayPoint: ObservableArray = new ObservableArray();
  public readonly line1weekPoint: ObservableArray = new ObservableArray();
  public readonly line1monthPoint: ObservableArray = new ObservableArray();

  private ohlc1dayToday: QRChartItem = null;
  private ohlc1weekToday: QRChartItem = null;
  private ohlc1monthToday: QRChartItem = null;

  private line1dayToday: LineChartItem = null;
  private line1weekToday: LineChartItem = null;
  private line1monthToday: LineChartItem = null;

  private openSetup = false;
  private closeSetup = false;

  public chartInfo: ChartInfo;
  private lastQrLength = 0;

  private readonly usePastData;
  public readonly stockWrapper: StockWrapper;
  public readonly lineChartTodayData: LineChartTodayData;
  public readonly lineChartName: string;
  public readonly lineChartUpdated: Observable<{}>;

  public readonly ohlcLength: number;
  public readonly marketCloseTimestamp: number;

  private fiveMinDays = 1;
  private days: number = null;
  private weeks: number = null;
  private months: number = null;

  public callbacks: Subject<{}> = new Subject();
  public drawInfo: {
    '5min'?: ChartDrawInfo5min;
    '1day'?: ChartDrawInfo1day;
    '1week'?: ChartDrawInfo1week;
    '1month'?: ChartDrawInfo1month;
  };

  private get view(): StockView {
    return this.stockWrapper ? this.stockWrapper.base : null;
  }

  constructor(private chartSource: ChartSource) {
    super();
    if (chartSource.pastData) {
      this.usePastData = true;
    } else {
      this.usePastData = false;
    }
    this.stockWrapper = chartSource.stockWrapper;
    this.ohlcLength = chartSource.stockWrapper ? chartSource.stockWrapper.flexConfig.ohlcLength : 60;
    this.marketCloseTimestamp = chartSource.stockWrapper ? chartSource.stockWrapper.flexConfig.marketCloseTimestamp : 15 * 60 * 60 * 1000;
    this.fiveMinDays = chartSource.fiveMinDays;
    this.days = chartSource.days;
    this.weeks = chartSource.weeks;
    this.months = chartSource.months;
    this.lineChartTodayData = chartSource.lineChartTodayData;
    this.lineChartName = chartSource.lineChartName;
    this.lineChartUpdated = chartSource.lineChartUpdated;

    this.line5minSegments = Array.from({ length: this.fiveMinDays }, () => new ObservableArray());
    if (this.usePastData) {
      this.line1daySegments = Array.from({ length: 1 }, () => new ObservableArray());
      this.line1weekSegments = Array.from({ length: 1 }, () => new ObservableArray());
      this.line1monthSegments = Array.from({ length: 1 }, () => new ObservableArray());
    }

    this.clear();
    if (this.stockWrapper) {
      this.updatedSubscription = this.stockWrapper.updated
        .pipe(throttleTime(1000, asyncScheduler, { leading: true, trailing: true }))
        .subscribe(() => {
          this.update();
        });
      if (this.lineChartUpdated) {
        this.updatedSubscription.add(
          this.lineChartUpdated.subscribe(() => {
            this.updateLineChart();
          })
        );
      }
      this.setup();
    }
  }

  ngOnDestroy() {
    if (this.updatedSubscription !== null) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }

    this.callbacks.complete();
  }

  clear(): void {
    this.ohlc5min.clear();
    this.ohlc1day.clear();
    this.ohlc1week.clear();
    this.ohlc1month.clear();
    this.line5minPoint.clear();
    this.line1dayPoint.clear();
    this.line1weekPoint.clear();
    this.line1monthPoint.clear();
    // 大きさ固定用のダミーデータ(Flex Chartの棒グラフの横幅は指定できないため)
    for (let x = -4; x <= 4; x++) {
      if (x === 0) {
        continue;
      }
      this.ohlc1day.push(new QRChartItem(x));
      this.ohlc1week.push(new QRChartItem(x));
      this.ohlc1month.push(new QRChartItem(x));
    }
    this.lastQrLength = 0;

    this.chartInfo = {
      lastTime: -1,
      lastIndex: -1,
      lastRow: 0,
      info5min: {
        maxDiff: 0.04,
        lineChartMaxDiff: 0.04,
        maxQuantity: 0,
        maxPrice10: 0,
        minPrice10: 0,
      },
      info1day: {
        maxDiff: 0.1,
        lineChartMaxDiff: 0.1,
        maxQuantity: 0,
        maxPrice10: 0,
        minPrice10: 0,
      },
      info1week: {
        maxDiff: 0.3,
        lineChartMaxDiff: 0.3,
        maxQuantity: 0,
        maxPrice10: 0,
        minPrice10: 0,
      },
      info1month: {
        maxDiff: 0.6,
        lineChartMaxDiff: 0.6,
        maxQuantity: 0,
        maxPrice10: 0,
        minPrice10: 0,
      },
    };
    this.drawInfo = {};
    this.ohlc1monthToday = this.ohlc1weekToday = this.ohlc1dayToday = null;
    if (this.view) {
      this.drawInfo['5min'] = new ChartDrawInfo5min(this.date, this.fiveMinDays, this.ohlcLength, this.lineChartName);
      if (this.usePastData) {
        this.drawInfo['1day'] = new ChartDrawInfo1day(this.date, this.lineChartName);
        if (this.days) {
          this.drawInfo['1day'].axisXMin = -this.days - 0.5;
        }
        this.drawInfo['1week'] = new ChartDrawInfo1week(this.date, this.lineChartName);
        if (this.weeks) {
          this.drawInfo['1week'].axisXMin = -this.weeks - 0.5;
        }
        this.drawInfo['1month'] = new ChartDrawInfo1month(this.date, this.lineChartName);
        if (this.months) {
          this.drawInfo['1month'].axisXMin = -this.months - 0.5;
        }
      }
    }
  }

  private updateChartInfo(info: ChartTypeInfo, row: QRChartItem) {
    if (row.high && row.low) {
      info.maxDiff = Math.max(info.maxDiff, row.high);
      info.maxDiff = Math.max(info.maxDiff, -row.low);
      info.lineChartMaxDiff = Math.max(info.lineChartMaxDiff, info.maxDiff);
      info.maxQuantity = Math.max(info.maxQuantity, row.quantity + (row.openQuantity || 0) + (row.closeQuantity || 0));
      if (info.minPrice10 === 0) {
        info.minPrice10 = row.lowPrice10;
      } else {
        info.minPrice10 = Math.min(info.minPrice10, row.lowPrice10);
      }
      info.maxPrice10 = Math.max(info.maxPrice10, row.highPrice10);
    }
  }

  private updateLineChartInfo(info: ChartTypeInfo, row: LineChartItem) {
    if (row.rate) {
      info.lineChartMaxDiff = Math.max(info.lineChartMaxDiff, Math.abs(row.rate));
    }
  }

  private setupToday() {
    this.openSetup = false;
    this.closeSetup = false;
    // 当日分5分足の初期データ
    this.ohlc5min.beginUpdate();
    let index = 0;
    for (const ohlc of this.view.ohlc) {
      if (ohlc.lowPrice10 !== 0) {
        this.ohlc5min.push(
          new QRChartItem(
            this.drawInfo['5min'].timeToX(0, index++),
            this.view.master.basePrice10,
            ohlc.openPrice10,
            ohlc.highPrice10,
            ohlc.lowPrice10,
            ohlc.closePrice10,
            ohlc.turnover10
          )
        );
        this.updateChartInfo(this.chartInfo.info5min, this.ohlc5min[this.ohlc5min.length - 1]);
      } else {
        this.ohlc5min.push(new QRChartItem(this.drawInfo['5min'].timeToX(0, index++)));
      }
    }
    this.tryOpen();
    this.tryClose();
    this.lastQrLength = this.view.qr.length;
    this.ohlc5min.endUpdate();
    this.chartInfo.info5min.basePrice10 = this.view.master.basePrice10;
    this.callbacks.next({});
  }

  private setupTodayLineChart() {
    let line5minToday: ObservableArray;
    if (this.lineChartTodayData && this.line5minSegments && this.line5minSegments.length !== 0) {
      line5minToday = this.line5minSegments[0];
    } else {
      return;
    }
    line5minToday.beginUpdate();
    this.line5minPoint.beginUpdate();
    for (const [i, rate] of this.lineChartTodayData.rates.entries()) {
      line5minToday.push(new LineChartItem(this.drawInfo['5min'].timeToX(0, i), rate));
      this.updateLineChartInfo(this.chartInfo.info5min, line5minToday[line5minToday.length - 1]);
    }
    this.line5minPoint.push(line5minToday[this.lineChartTodayData.lastIndex]);
    line5minToday.endUpdate();
    this.line5minPoint.endUpdate();
    this.callbacks.next({});
  }

  private tryOpen() {
    if (!this.openSetup) {
      if (
        this.view.qr.length > 0 &&
        this.view.qr[0] &&
        this.view.qr[0].price10 &&
        this.view.qr[0].timestamp !== this.marketCloseTimestamp * 1000
      ) {
        const idx = this.timestampToIndex(this.view.qr[0].timestamp);
        this.ohlc5min[idx].quantity -= this.view.qr[0].price10 * this.view.qr[0].quantity;
        this.ohlc5min[idx].openQuantity = this.view.qr[0].price10 * this.view.qr[0].quantity;
        this.openSetup = true;
      }
    }
  }

  private tryClose() {
    if (!this.closeSetup) {
      if (this.view.qr.length > 0 && this.view.qr[this.view.qr.length - 1].timestamp === this.marketCloseTimestamp * 1000) {
        const qr = this.view.qr[this.view.qr.length - 1];
        this.ohlc5min[this.ohlc5min.length - 1].quantity -= qr.price10 * qr.quantity;
        this.ohlc5min[this.ohlc5min.length - 1].closeQuantity = qr.price10 * qr.quantity;
        this.closeSetup = true;
      }
    }
  }

  setupPastData(data: OHLCResponse) {
    const basePrice = this.view.master.basePrice10 / 10;

    try {
      this.ohlc5min.beginUpdate();
      this.ohlc1day.beginUpdate();
      this.ohlc1week.beginUpdate();
      this.ohlc1month.beginUpdate();

      this.chartInfo.info1day.basePrice10 = this.view.master.basePrice10;
      this.chartInfo.info1week.basePrice10 = this.view.master.basePrice10;
      this.chartInfo.info1month.basePrice10 = this.view.master.basePrice10;

      if (!data) {
        return;
      }
      if (data.ohlc5min) {
        if (this.ohlc5min.length !== this.ohlcLength) {
          throw new Error('Invalid OHLC Length');
        }
        for (const d of data.ohlc5min) {
          if (d.diff <= -this.fiveMinDays) {
            continue;
          }
          this.drawInfo['5min'].dateMap[d.diff] = parse(d.date);
          this.ohlc5min.push(
            new QRChartItem(
              this.drawInfo['5min'].timeToX(d.diff, d.index),
              this.view.master.basePrice10,
              d.open_price * 10,
              d.high_price * 10,
              d.low_price * 10,
              d.close_price * 10,
              (d.turnover - (d.open_turnover || 0) - (d.close_turnover || 0)) * 10,
              d.open_turnover * 10,
              d.close_turnover * 10,
              { date: parse(d.date) }
            )
          );
          this.updateChartInfo(this.chartInfo.info5min, this.ohlc5min[this.ohlc5min.length - 1]);
        }
      }
      if (data.ohlc1day) {
        for (const d of data.ohlc1day) {
          const date = parse(d.date);
          const x = differenceInDays(date, this.date);
          if (x < this.drawInfo['1day'].axisXMin) {
            continue;
          }
          this.ohlc1day.push(
            new QRChartItem(
              x,
              this.view.master.basePrice10,
              d.open_price * 10,
              d.high_price * 10,
              d.low_price * 10,
              d.close_price * 10,
              d.turnover * 10,
              0,
              0,
              { date: date }
            )
          );
          this.updateChartInfo(this.chartInfo.info1day, this.ohlc1day[this.ohlc1day.length - 1]);
          if (x === 0) {
            this.ohlc1dayToday = this.ohlc1day[this.ohlc1day.length - 1];
            this.ohlc1dayToday['originalQuantity'] = this.ohlc1dayToday.quantity;
          }
        }
        if (this.ohlc1dayToday === null) {
          this.ohlc1dayToday = new QRChartItem(0, this.view.master.basePrice10, undefined, undefined, undefined, undefined, 0, 0, 0, {
            date: this.date,
          });
          this.ohlc1day.push(this.ohlc1dayToday);
          this.updateChartInfo(this.chartInfo.info1day, this.ohlc1day[this.ohlc1day.length - 1]);
        }
      }
      if (data.ohlc1week) {
        const startWeek: Date = startOfISOWeek(this.date);
        for (const d of data.ohlc1week) {
          const tmpDate = startOfISOWeek(setISOWeek(setISOYear(new Date(2020, 5, 1, 0, 0, 0, 0), d.year), d.week));
          const x = differenceInWeeks(tmpDate, startWeek);
          if (x < this.drawInfo['1week'].axisXMin) {
            continue;
          }
          this.ohlc1week.push(
            new QRChartItem(
              x,
              this.view.master.basePrice10,
              d.open_price * 10,
              d.high_price * 10,
              d.low_price * 10,
              d.close_price * 10,
              d.turnover * 10,
              0,
              0,
              { year: d.year, week: d.week }
            )
          );
          this.updateChartInfo(this.chartInfo.info1week, this.ohlc1week[this.ohlc1week.length - 1]);
          if (x === 0) {
            this.ohlc1weekToday = this.ohlc1week[this.ohlc1week.length - 1];
            this.ohlc1weekToday['originalQuantity'] = this.ohlc1weekToday.quantity;
          }
        }
        if (this.ohlc1weekToday === null) {
          this.ohlc1weekToday = new QRChartItem(0, this.view.master.basePrice10, undefined, undefined, undefined, undefined, 0, 0, 0, {
            year: getISOYear(this.date),
            week: getISOWeek(this.date),
          });
          this.ohlc1week.push(this.ohlc1weekToday);
          this.updateChartInfo(this.chartInfo.info1week, this.ohlc1week[this.ohlc1week.length - 1]);
        }
      }
      if (data.ohlc1month) {
        for (const d of data.ohlc1month) {
          const x = differenceInMonths(new Date(d.year, d.month - 1), this.date);
          if (x < this.drawInfo['1month'].axisXMin) {
            continue;
          }

          this.ohlc1month.push(
            new QRChartItem(
              x,
              this.view.master.basePrice10,
              d.open_price * 10,
              d.high_price * 10,
              d.low_price * 10,
              d.close_price * 10,
              d.turnover * 10,
              0,
              0,
              { year: d.year, month: d.month }
            )
          );
          this.updateChartInfo(this.chartInfo.info1month, this.ohlc1month[this.ohlc1month.length - 1]);
          if (x === 0) {
            this.ohlc1monthToday = this.ohlc1month[this.ohlc1month.length - 1];
            this.ohlc1monthToday['originalQuantity'] = this.ohlc1monthToday.quantity;
          }
        }
        if (!this.ohlc1monthToday) {
          this.ohlc1monthToday = new QRChartItem(0, this.view.master.basePrice10, undefined, undefined, undefined, undefined, 0, 0, 0, {
            year: this.date.getFullYear(),
            week: this.date.getMonth() + 1,
          });
          this.ohlc1month.push(this.ohlc1monthToday);
          this.updateChartInfo(this.chartInfo.info1month, this.ohlc1monthToday);
        }
      }
    } finally {
      this.ohlc5min.endUpdate();
      this.ohlc1day.endUpdate();
      this.ohlc1week.endUpdate();
      this.ohlc1month.endUpdate();
    }

    if (this.ohlc1dayToday) {
      this.updateChartDataByToday(this.ohlc1dayToday);
      this.updateChartInfo(this.chartInfo.info1day, this.ohlc1dayToday);
    }
    if (this.ohlc1weekToday) {
      this.updateChartDataByToday(this.ohlc1weekToday);
      this.updateChartInfo(this.chartInfo.info1week, this.ohlc1weekToday);
    }
    if (this.ohlc1monthToday) {
      this.updateChartDataByToday(this.ohlc1monthToday);
      this.updateChartInfo(this.chartInfo.info1month, this.ohlc1monthToday);
    }

    this.callbacks.next({});
  }

  setupPastLineChart(data: OHLCResponseExtended) {
    try {
      this.line5minSegments.forEach((s) => s.beginUpdate());
      this.line1daySegments.forEach((s) => s.beginUpdate());
      this.line1weekSegments.forEach((s) => s.beginUpdate());
      this.line1monthSegments.forEach((s) => s.beginUpdate());
      this.line1dayPoint.beginUpdate();
      this.line1weekPoint.beginUpdate();
      this.line1monthPoint.beginUpdate();

      if (!data) {
        return;
      }
      if (data.ohlc.ohlc5min) {
        for (const [i, d] of data.ohlc.ohlc5min.entries()) {
          if (d.diff <= -this.fiveMinDays) {
            continue;
          }
          this.drawInfo['5min'].dateMap[d.diff] = parse(d.date);
          const line5min = this.line5minSegments[-d.diff];
          line5min.push(new LineChartItem(this.drawInfo['5min'].timeToX(d.diff, d.index), data.data5min[i]));
          this.updateLineChartInfo(this.chartInfo.info5min, line5min[line5min.length - 1]);
        }
      }
      if (data.ohlc.ohlc1day && this.line1daySegments.length !== 0) {
        const line1day = this.line1daySegments[0];
        for (const [i, d] of data.ohlc.ohlc1day.entries()) {
          const date = parse(d.date);
          const x = differenceInDays(date, this.date);
          if (x < this.drawInfo['1day'].axisXMin) {
            continue;
          }
          line1day.push(new LineChartItem(x, data.data1day[i]));
          this.updateLineChartInfo(this.chartInfo.info1day, line1day[line1day.length - 1]);
          if (x === 0) {
            this.line1dayToday = line1day[line1day.length - 1];
          }
        }
        if (this.line1dayToday === null) {
          this.line1dayToday = new LineChartItem(0, undefined);
          line1day.push(this.line1dayToday);
          this.updateLineChartInfo(this.chartInfo.info1day, line1day[line1day.length - 1]);
        }
      }
      if (data.ohlc.ohlc1week && this.line1weekSegments.length !== 0) {
        const line1week = this.line1weekSegments[0];
        const startWeek: Date = startOfISOWeek(this.date);
        for (const [i, d] of data.ohlc.ohlc1week.entries()) {
          const tmpDate = startOfISOWeek(setISOWeek(setISOYear(new Date(2020, 5, 1, 0, 0, 0, 0), d.year), d.week));
          const x = differenceInWeeks(tmpDate, startWeek);
          if (x < this.drawInfo['1week'].axisXMin) {
            continue;
          }
          line1week.push(new LineChartItem(x, data.data1week[i]));
          this.updateLineChartInfo(this.chartInfo.info1week, line1week[line1week.length - 1]);
          if (x === 0) {
            this.line1weekToday = line1week[line1week.length - 1];
          }
        }
        if (this.line1weekToday === null) {
          this.line1weekToday = new LineChartItem(0, undefined);
          line1week.push(this.line1weekToday);
          this.updateLineChartInfo(this.chartInfo.info1week, line1week[line1week.length - 1]);
        }
      }
      if (data.ohlc.ohlc1month && this.line1monthSegments.length !== 0) {
        const line1month = this.line1monthSegments[0];
        for (const [i, d] of data.ohlc.ohlc1month.entries()) {
          const x = differenceInMonths(new Date(d.year, d.month - 1), this.date);
          if (x < this.drawInfo['1month'].axisXMin) {
            continue;
          }
          this.line1monthSegments[0].push(new LineChartItem(x, data.data1month[i]));
          this.updateLineChartInfo(this.chartInfo.info1month, line1month[line1month.length - 1]);
          if (x === 0) {
            this.line1monthToday = line1month[line1month.length - 1];
          }
        }
        if (this.line1monthToday === null) {
          this.line1monthToday = new LineChartItem(0, undefined);
          line1month.push(this.line1monthToday);
          this.updateLineChartInfo(this.chartInfo.info1month, line1month[line1month.length - 1]);
        }
      }

      const lastRate = this.lineChartTodayData.rates[this.lineChartTodayData.lastIndex];
      if (this.line1dayToday) {
        this.line1dayToday.rate = lastRate;
        this.updateLineChartInfo(this.chartInfo.info1day, this.line1dayToday);
        this.line1dayPoint.push(this.line1dayToday);
      }
      if (this.line1weekToday) {
        this.line1weekToday.rate = lastRate;
        this.updateLineChartInfo(this.chartInfo.info1week, this.line1weekToday);
        this.line1weekPoint.push(this.line1weekToday);
      }
      if (this.line1monthToday) {
        this.line1monthToday.rate = lastRate;
        this.updateLineChartInfo(this.chartInfo.info1month, this.line1monthToday);
        this.line1monthPoint.push(this.line1monthToday);
      }
    } finally {
      this.line5minSegments.forEach((s) => s.endUpdate());
      this.line1daySegments.forEach((s) => s.endUpdate());
      this.line1weekSegments.forEach((s) => s.endUpdate());
      this.line1monthSegments.forEach((s) => s.endUpdate());
      this.line1dayPoint.endUpdate();
      this.line1weekPoint.endUpdate();
      this.line1monthPoint.endUpdate();
    }
    this.callbacks.next({});
  }

  private setup() {
    this.setupToday();
    this.setupTodayLineChart();
    if (this.usePastData) {
      this.chartSource.pastData.subscribe((ohlc) => {
        this.setupPastData(ohlc);
      });
      if (this.chartSource.lineChartPastData) {
        this.chartSource.lineChartPastData.subscribe((lineChart) => {
          this.setupPastLineChart(lineChart);
        });
      }
    }
  }

  private updateChartDataByToday(d: QRChartItem) {
    if (!d.openPrice10 && this.view.qr.length > 0) {
      d.openPrice10 = this.view.qr[0].price10;
    }
    if (!d.highPrice10 && this.view.highPrice10) {
      d.highPrice10 = this.view.highPrice10;
    } else if (this.view.highPrice10) {
      d.highPrice10 = Math.max(d.highPrice10, this.view.highPrice10);
    }
    if (!d.lowPrice10 && this.view.lowPrice10) {
      d.lowPrice10 = this.view.lowPrice10;
    } else if (this.view.lowPrice10) {
      d.lowPrice10 = Math.min(d.lowPrice10, this.view.lowPrice10);
    }
    if (this.view.lastPrice10) {
      d.closePrice10 = this.view.lastPrice10;
    }
    d.quantity = d.originalQuantity + this.view.turnover10;
  }

  private update() {
    if (!this.view || this.view.qr.length === this.lastQrLength) {
      return;
    }
    this.ohlc5min.beginUpdate();
    this.ohlc1day.beginUpdate();
    this.ohlc1week.beginUpdate();
    this.ohlc1month.beginUpdate();

    const updated5min = new Set<number>();
    for (let i = this.lastQrLength; i < this.view.qr.length; i++) {
      const idx = this.timestampToIndex(this.view.qr[i].timestamp);
      if (updated5min.has(idx)) {
        continue;
      }
      updated5min.add(idx);
      const ohlc = this.view.ohlc[idx];
      if (this.ohlc5min[idx].openQuantity) {
        this.ohlc5min[idx].openQuantity = 0;
        this.openSetup = false;
      }
      this.ohlc5min[idx] = new QRChartItem(
        this.drawInfo['5min'].timeToX(0, idx),
        this.view.master.basePrice10,
        ohlc.openPrice10,
        ohlc.highPrice10,
        ohlc.lowPrice10,
        ohlc.closePrice10,
        ohlc.turnover10
      );
      this.updateChartInfo(this.chartInfo.info5min, this.ohlc5min[idx]);
    }
    this.lastQrLength = this.view.qr.length;
    this.tryOpen();
    this.tryClose();
    // 日足・週足・月足の生成
    if (this.ohlc1dayToday) {
      this.updateChartDataByToday(this.ohlc1dayToday);
      this.updateChartInfo(this.chartInfo.info1day, this.ohlc1dayToday);
    }
    if (this.ohlc1weekToday) {
      this.updateChartDataByToday(this.ohlc1weekToday);
      this.updateChartInfo(this.chartInfo.info1week, this.ohlc1weekToday);
    }
    if (this.ohlc1monthToday) {
      this.updateChartDataByToday(this.ohlc1monthToday);
      this.updateChartInfo(this.chartInfo.info1month, this.ohlc1monthToday);
    }
    this.ohlc5min.endUpdate();
    this.ohlc1day.endUpdate();
    this.ohlc1week.endUpdate();
    this.ohlc1month.endUpdate();
    this.callbacks.next({});
  }

  private updateLineChart() {
    let line5minToday: ObservableArray;
    if (this.lineChartTodayData && this.line5minSegments && this.line5minSegments.length !== 0) {
      line5minToday = this.line5minSegments[0];
    } else {
      return;
    }
    line5minToday.beginUpdate();
    this.line1daySegments.forEach((s) => s.beginUpdate());
    this.line1weekSegments.forEach((s) => s.beginUpdate());
    this.line1monthSegments.forEach((s) => s.beginUpdate());
    this.line5minPoint.beginUpdate();
    this.line1dayPoint.beginUpdate();
    this.line1weekPoint.beginUpdate();
    this.line1monthPoint.beginUpdate();

    for (const [i, rate] of this.lineChartTodayData.rates.entries()) {
      line5minToday[i].rate = rate;
      this.updateLineChartInfo(this.chartInfo.info5min, line5minToday[i]);
    }
    if (this.line5minPoint.length !== 0) {
      this.line5minPoint[0] = line5minToday[this.lineChartTodayData.lastIndex];
    }
    const lastRate = this.lineChartTodayData.rates[this.lineChartTodayData.lastIndex];
    if (this.line1dayToday) {
      this.line1dayToday.rate = lastRate;
      this.updateLineChartInfo(this.chartInfo.info1day, this.line1dayToday);
    }
    if (this.line1weekToday) {
      this.line1weekToday.rate = lastRate;
      this.updateLineChartInfo(this.chartInfo.info1week, this.line1weekToday);
    }
    if (this.line1monthToday) {
      this.line1monthToday.rate = lastRate;
      this.updateLineChartInfo(this.chartInfo.info1month, this.line1monthToday);
    }

    line5minToday.endUpdate();
    this.line1daySegments.forEach((s) => s.endUpdate());
    this.line1weekSegments.forEach((s) => s.endUpdate());
    this.line1monthSegments.forEach((s) => s.endUpdate());
    this.line5minPoint.endUpdate();
    this.line1dayPoint.endUpdate();
    this.line1weekPoint.endUpdate();
    this.line1monthPoint.endUpdate();
    this.callbacks.next({});
  }

  timestampToIndex(timestamp) {
    let ret = Math.trunc((timestamp - 9 * 60 * 60 * 1000000 + 5 * 60 * 1000000 - 1) / (5 * 60 * 1000000));
    if (ret <= 30) {
      ret -= 1;
      if (ret < 0) {
        ret = 0;
      }
      return ret;
    }
    ret -= 13;
    if (ret === 29) {
      ret = 30;
    }
    return ret;
  }
}
