import { Portfolio, StockInfo, StockMaster } from '@argentumcode/brisk-common';
import { isDevMode } from '@angular/core';
import { IssueTypeBase } from '../../core/issue-types.service';

export const GroupingType = {
  Industry: 'Industry',
  Section: 'Section',
} as const;
export type GroupingType = typeof GroupingType[keyof typeof GroupingType];

const GroupingOrder = {
  Defined: 'Defined',
  Value: 'Value',
} as const;
export type GroupingOrder = typeof GroupingOrder[keyof typeof GroupingOrder];

export interface Group {
  groupType: GroupingType;
  name: string;
  order: number;
}

export interface Stock {
  master: StockMaster;
  info: StockInfo;
  portfolio: Portfolio;
}

function group(m: StockMaster, i: StockInfo, type: GroupingType, issueTypes: Readonly<Array<IssueTypeBase>>): Group {
  switch (type) {
    case GroupingType.Industry: {
      if (m.industryCode === 9999) {
        if (m.issueType === 115 || m.issueType === 116) {
          return {
            groupType: type,
            name: 'ETF',
            order: 999910,
          };
        } else if (m.issueType === 119) {
          return {
            groupType: type,
            name: 'REIT',
            order: 999910,
          };
        } else {
          return {
            groupType: type,
            name: 'その他',
            order: 999999,
          };
        }
      } else {
        return {
          groupType: type,
          name: m.industryName,
          order: m.industryCode * 100,
        };
      }
    }
    case GroupingType.Section: {
      if (m.indexFlag & (1 << 2)) {
        return {
          groupType: type,
          name: '高流動性100',
          order: 10,
        };
      }
      let index = 20;
      for (const issueT of issueTypes) {
        if (m.issueType === issueT.issueType || (Array.isArray(issueT.issueType) && issueT.issueType.includes(m.issueType))) {
          return {
            groupType: type,
            name: issueT.name,
            order: index,
          };
        }
        index++;
      }
      return {
        groupType: type,
        name: 'その他',
        order: 9999,
      };
    }
  }
}

export interface StockElement extends Stock {
  itemType: 'stock';
  parent: Array<Group>;
  value: number;
}

export interface GroupElement extends Group {
  itemType: 'group';
  parent: Array<Group>;
  children: Array<StockElement | GroupElement>;
}

export type Element = GroupElement | StockElement;

// 銘柄をグルーピングするツール
export class IssueGrouper {
  constructor(private issueTypes: Readonly<Array<IssueTypeBase>>) {}

  createGroup(
    stocks: Array<Stock>,
    grouping: Array<GroupingType>,
    groupingOrder: Array<GroupingOrder>,
    parent: Array<Group> = []
  ): Array<Element> {
    if (grouping.length === 0) {
      const ret: Array<StockElement> = [];
      for (const s of stocks) {
        ret.push({
          ...s,
          itemType: 'stock',
          parent,
          value: s.info.calcSharesOutstanding * s.master.basePrice10,
        });
        if (!s.info.calcSharesOutstanding && isDevMode()) {
          throw new Error('CalcSharesOutstanding must be exists');
        }
      }
      if (groupingOrder[0] === GroupingOrder.Value) {
        ret.sort((a, b) => {
          if (a.value !== b.value) {
            return -(a.value - b.value);
          }
          if (a.itemType === 'stock' && b.itemType === 'stock') {
            return a.master.issueCode - b.master.issueCode;
          }
          return 0;
        });
      }
      return ret;
    } else {
      const groups: { [key: string]: { group: Group; stocks: Array<Stock>; value: number } } = {};
      for (const stock of stocks) {
        const g = group(stock.master, stock.info, grouping[0], this.issueTypes);
        const key = `${g.order.toString().padEnd(10, '0')}:${g.name}`;
        if (!(key in groups)) {
          groups[key] = {
            group: g,
            stocks: [],
            value: 0,
          };
        }
        groups[key].stocks.push(stock);
        groups[key].value += stock.info.calcSharesOutstanding * stock.master.basePrice10;
      }
      const ret: Array<Element> = [];
      for (const key of Object.keys(groups).sort()) {
        ret.push({
          ...groups[key].group,
          itemType: 'group',
          parent,
          children: this.createGroup(groups[key].stocks, grouping.slice(1), groupingOrder.slice(1), parent.concat(groups[key].group)),
        });
      }
      if (groupingOrder[0] === GroupingOrder.Value) {
        ret.sort((a, b) => {
          if (a.itemType === 'group' && b.itemType === 'group') {
            const aKey = `${a.order.toString().padEnd(10, '0')}:${a.name}`;
            const bKey = `${b.order.toString().padEnd(10, '0')}:${b.name}`;
            return -(groups[aKey].value - groups[bKey].value);
          }
          return 0;
        });
      }
      return ret;
    }
  }
}
