<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'fita4'">
    <ng-container *ngIf="glState">
      <div class="header-line">
        <ng-container *ngTemplateOutlet="fita3GlIssueCodeInput"></ng-container>
        <ng-container *ngTemplateOutlet="fita3GlPrice"></ng-container>
        &nbsp;/&nbsp;
        <ng-container *ngTemplateOutlet="fita3GlVolume"></ng-container>
        &nbsp;/&nbsp;
        <ng-container *ngTemplateOutlet="fita3GlMarketInfo"></ng-container>
      </div>
      <div class="header-line">
        <ng-container *ngTemplateOutlet="fita4PositionSummary"></ng-container>
        <ng-container *ngTemplateOutlet="fita4ProfitLossSummary"></ng-container>
      </div>

      <ng-template #fita3GlIssueCodeInput>
        <div class="p-issue-header__issue-input" [class.p-issue-header__issue-input--mini]="false">
          <brisk-issue-code-input
            class="issueCodeInput brisk"
            (stockSelected)="onStockSelected($event)"
            (invalidInput)="invalidInput.emit($event)"
            [placeHolder]="'銘柄コード / 銘柄名'"
          ></brisk-issue-code-input>
        </div>

        <div class="p-issue-header__loan-margin-flag" [ngbTooltip]="tooltipLoanMargin">
          <fa-icon *ngIf="view && view.master.loanMarginFlag === 1" [icon]="['fas', 'circle']"></fa-icon>
          <fa-icon *ngIf="view && view.master.loanMarginFlag === 2" [icon]="['far', 'circle']"></fa-icon>
        </div>
        <div *ngIf="view" class="p-issue-header__issue-code-name" [class.p-issue-header__pricing--full-width]="glState.fullWidth">
          <span class="p-issue-header__issue-code">{{ view.master.issueCode }}</span>
          <span class="p-issue-header__issue-name">{{ view.master.name }}-{{ view.master.prefix }}</span>
        </div>
      </ng-template>

      <ng-template #fita3GlPrice>
        <div *ngIf="view" class="p-issue-header__pricing" [class.p-issue-header__pricing--full-width]="glState.fullWidth">
          <span *ngIf="view.master.exRightFlag" class="p-issue-header__ex-right-flag" [ngbTooltip]="view.master.exRightFlag | exRightFlag">
            落
          </span>
          <span [ngbTooltip]="tooltipPriceDetail"
            ><ng-container *ngIf="view.predictLastPrice.predict">(</ng-container
            ><span
              *ngIf="view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide"
              [ngClass]="view.predictLastPrice.quoteSide | sideClass"
              >{{ view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide }} </span
            >{{ view.predictLastPrice.lastPrice10 | formatPrice }}<ng-container *ngIf="view.predictLastPrice.predict">)</ng-container></span
          >
          /
          <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass" [ngbTooltip]="tooltipPriceChangeDetail">{{
            view.lastPriceBasePriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
          }}</span>
          <ng-container *ngIf="showOpenPredict && openPredict">
            <span
              >/ (寄
              <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass">{{
                view.predictOpenPriceBasePriceChange | priceChanges
              }}</span
              >)</span
            >
          </ng-container>
          <ng-container *ngIf="!showOpenPredict">
            /
            <span [ngClass]="view.vwapPriceChange | priceChangeClass" [ngbTooltip]="tooltipVwapDetail">{{
              view.vwapPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
            }}</span>
          </ng-container>
        </div>
      </ng-template>
      <ng-template #fita3GlVolume>
        <div
          class="p-issue-header__turnover"
          *ngIf="view"
          [class.p-issue-header__turnover--full-width]="glState.fullWidth"
          [ngbTooltip]="turnover10 && volumeTooltip"
        >
          <!--          <span class="p-issue-header__heading">出来高</span>-->
          <ng-container *ngIf="turnover10"
            >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株
          </ng-container>
          /
          <ng-container *ngIf="turnover10"
            >{{
              turnover10 / 1000000000
                | fastDecimal
                  : {
                      separateComma: true,
                      fractionDigits: 2
                    }
                | predictTransform: turnoverPredict
            }}
            億円
          </ng-container>
        </div>
      </ng-template>

      <ng-template #volumeTooltip>
        出来高:
        <ng-container *ngIf="turnover10"
          >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株 /
          {{
            turnover10 / 1000000000
              | fastDecimal
                : {
                    separateComma: true,
                    fractionDigits: 2
                  }
              | predictTransform: turnoverPredict
          }}億円
        </ng-container>
      </ng-template>
      <ng-template #fita3GlMarketInfo>
        <div
          class="p-issue-header__market"
          *ngIf="view"
          [class.p-issue-header__market--full-width]="glState.fullWidth"
          [ngbTooltip]="view.info.calcSharesOutstanding !== null && view.info.calcSharesOutstanding !== undefined && marketInfoTooltip"
        >
          <!--          <span class="p-issue-header__heading">時価総額</span>-->
          <span *ngIf="view.info.calcSharesOutstanding !== null && view.info.calcSharesOutstanding !== undefined"
            >{{
              ((view.info.calcSharesOutstanding || 0) * (view.master.basePrice10 || 0)) / 1000000000
                | fastDecimal: { separateComma: true, fractionDigits: 0 }
            }}
            億円</span
          >
        </div>
        <ng-template #marketInfoTooltip>
          時価総額:
          {{
            ((view.info.calcSharesOutstanding || 0) * (view.master.basePrice10 || 0)) / 1000000000
              | fastDecimal: { separateComma: true, fractionDigits: 0 }
          }}
          億円
        </ng-template>
      </ng-template>
      <ng-template #fita4PositionSummary>
        <div
          class="p-issue-header__order-summary"
          *ngIf="orderSummary && orderSummary.genbutsuPosition !== undefined"
          [class.p-issue-header__order-summary__market--full-width]="glState.fullWidth"
        >
          <span [ngbTooltip]="tooltipOrderSummaryPositionCash">現{{ orderSummary.genbutsuPosition | fastDecimal }}株</span>
          <ng-container *ngIf="orderSummary.marginBuyPosition !== undefined || orderSummary.marginSellPosition !== undefined">
            / <span [ngbTooltip]="tooltipOrderSummaryPositionMarginBuy">買建{{ orderSummary.marginBuyPosition | fastDecimal }}株</span> /
            <span [ngbTooltip]="tooltipOrderSummaryPositionMarginSell">売建{{ orderSummary.marginSellPosition | fastDecimal }}株</span>
          </ng-container>
        </div>
        <ng-template #tooltipOrderSummaryPositionSummary>
          <ng-container *ngTemplateOutlet="tooltipOrderSummaryPosition; context: { current: '' }"></ng-container>
        </ng-template>
        <ng-template #tooltipOrderSummaryPositionCash>
          <ng-container *ngTemplateOutlet="tooltipOrderSummaryPosition; context: { current: 'cash' }"></ng-container>
        </ng-template>
        <ng-template #tooltipOrderSummaryPositionMarginBuy>
          <ng-container *ngTemplateOutlet="tooltipOrderSummaryPosition; context: { current: 'marginBuy' }"></ng-container>
        </ng-template>
        <ng-template #tooltipOrderSummaryPositionMarginSell>
          <ng-container *ngTemplateOutlet="tooltipOrderSummaryPosition; context: { current: 'marginSell' }"></ng-container>
        </ng-template>

        <ng-template #tooltipOrderSummaryPosition let-current="current">
          <span [class.tooltip-price-active]="current == 'cash'">現物保有株数</span>
          /
          <span [class.tooltip-price-active]="current == 'marginBuy'">信用買建玉</span>
          /
          <span [class.tooltip-price-active]="current == 'marginSell'">信用売建玉</span>
        </ng-template>
      </ng-template>
      <ng-template #fita4ProfitLossSummary>
        <div
          class="p-issue-header__pl-summary"
          *ngIf="orderSummary.genbutuEvaluateProfitLoss !== undefined"
          [class.p-issue-header__pl-summary__market--full-width]="glState.fullWidth"
        >
          <span class="p-issue-header__heading" [ngbTooltip]="tooltipProfitLossSummary"></span>
          現物評価損益
          <span [ngClass]="orderSummary.genbutuEvaluateProfitLoss | priceChangeClass" [ngbTooltip]="tooltipProfitLossCash"
            >{{ orderSummary.genbutuEvaluateProfitLoss | fastDecimal: { addPlusSign: true } }}円</span
          >

          <ng-container *ngIf="orderSummary.marginEvaluateProfitLoss !== undefined || orderSummary.marginRealizedProfitLoss !== undefined">
            / 信用評価損益
            <span [ngClass]="orderSummary.marginEvaluateProfitLoss | priceChangeClass" [ngbTooltip]="tooltipProfitLossMarginEval"
              >{{ orderSummary.marginEvaluateProfitLoss | fastDecimal: { addPlusSign: true } }}円</span
            >
            / 信用実現損益
            <span [ngClass]="orderSummary.marginRealizedProfitLoss | priceChangeClass" [ngbTooltip]="tooltipProfitLossMarginReal"
              >{{ orderSummary.marginRealizedProfitLoss | fastDecimal: { addPlusSign: true } }}円</span
            >
            / 計:
            <span
              [ngClass]="
                orderSummary.genbutuEvaluateProfitLoss + orderSummary.marginRealizedProfitLoss + orderSummary.marginEvaluateProfitLoss
                  | priceChangeClass
              "
              >{{
                orderSummary.genbutuEvaluateProfitLoss + orderSummary.marginRealizedProfitLoss + orderSummary.marginEvaluateProfitLoss
                  | fastDecimal: { addPlusSign: true }
              }}円</span
            >
          </ng-container>
        </div>
        <ng-template #tooltipProfitLossSummary>
          <ng-container *ngTemplateOutlet="tooltipProfitLoss; context: { current: '' }"></ng-container>
        </ng-template>
        <ng-template #tooltipProfitLossCash>
          <ng-container *ngTemplateOutlet="tooltipProfitLoss; context: { current: 'cash' }"></ng-container>
        </ng-template>
        <ng-template #tooltipProfitLossMarginEval>
          <ng-container *ngTemplateOutlet="tooltipProfitLoss; context: { current: 'marginEval' }"></ng-container>
        </ng-template>
        <ng-template #tooltipProfitLossMarginReal>
          <ng-container *ngTemplateOutlet="tooltipProfitLoss; context: { current: 'marginReal' }"></ng-container>
        </ng-template>

        <ng-template #tooltipProfitLoss let-current="current">
          <span [class.tooltip-price-active]="current == 'cash'">現物評価損益</span>
          /
          <span [class.tooltip-price-active]="current == 'marginEval'">信用評価損益</span>
          /
          <span [class.tooltip-price-active]="current == 'marginReal'">信用実現損益</span>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'fita3'"> <ng-container *ngTemplateOutlet="fita3Template"></ng-container></ng-container>
  <ng-container *ngSwitchCase="'lita'"> <ng-container *ngTemplateOutlet="litaContent"></ng-container> </ng-container>
  <ng-container *ngSwitchCase="'lita-sp'"> <ng-container *ngTemplateOutlet="litaSpContent"></ng-container> </ng-container>
  <ng-container *ngSwitchDefault>
    <!-- fallback for old template -->
    <ng-container *ngIf="liteSpMode">
      <ng-container *ngTemplateOutlet="litaSpContent"></ng-container>
    </ng-container>
    <ng-container *ngIf="showTimestamp && !liteSpMode">
      <ng-container *ngTemplateOutlet="litaContent"></ng-container>
    </ng-container>
    <ng-container *ngIf="!showTimestamp && !liteSpMode"> <ng-container *ngTemplateOutlet="fita3Template"></ng-container></ng-container>
  </ng-container>
</ng-container>
<ng-template #litaSpContent>
  <div id="issueCodeHeader" (click)="onHeaderClick()" class="clearfix">
    <ng-container *ngIf="spInputMode; else spShowMode">
      <div
        id="spIssueCodeInputWrapper"
        [ngClass]="
          showButtons && !isMobile && !disableNormalMode
            ? 'p-header-issue-info--show-normal-button'
            : showTedinetButton && (isMobile || disableNormalMode)
            ? 'p-header-issue-info--show-tedinet-button'
            : ''
        "
      >
        <brisk-issue-code-input
          class="issueCodeInput spIssueCodeInput"
          (stockSelected)="onStockSelected($event)"
          (lostFocus)="onLostFocus()"
          (invalidInput)="invalidInput.emit($event)"
          [autoFocus]="true"
        >
        </brisk-issue-code-input>
      </div>
    </ng-container>
    <ng-template #spShowMode>
      <div
        class="float-left"
        id="spIssueNameInfo"
        [ngClass]="
          showButtons && !isMobile && !disableNormalMode
            ? 'p-header-issue-info--show-normal-button'
            : showTedinetButton && (isMobile || disableNormalMode)
            ? 'p-header-issue-info--show-tedinet-button'
            : ''
        "
      >
        <span id="spSearchButton">
          <fa-icon [icon]="['fas', 'search']"></fa-icon>
        </span>
        <span class="loanMarginFlag spLoanMarginFlag" [ngbTooltip]="tooltipLoanMargin">
          <fa-icon *ngIf="view && view.master.loanMarginFlag === 1" [icon]="['fas', 'circle']"></fa-icon>
          <fa-icon *ngIf="view && view.master.loanMarginFlag === 2" [icon]="['far', 'circle']"></fa-icon>
        </span>

        <span *ngIf="view" class="issueCode">{{ view.master.issueCode }}</span>
        <span *ngIf="view" class="issueName">
          {{ view.master.name }}
          <span class="d-inline" *ngIf="showButtons">
            ({{ view.master.prefix }}
            <ng-container *ngIf="view.master.industryName !== ''" id="industry">-{{ view.master.industryName }}</ng-container
            >)
          </span>
        </span>
      </div>
      <div class="float-right">
        <button
          class="p-header-issue-info__back-button c-btn c-btn--default"
          *ngIf="showTedinetButton && (isMobile || disableNormalMode || showButtons)"
          (click)="tedinetButtonClicked.emit(); $event.stopPropagation()"
          [ngClass]="isMobile || disableNormalMode ? 'p-header-issue-info__narrow-tedinet-button' : ''"
        >
          適時開示
        </button>
        <button
          class="p-header-issue-info__back-button c-btn c-btn--default"
          *ngIf="showButtons && !isMobile && !disableNormalMode"
          (click)="backToNormalModeClick.emit(); $event.stopPropagation()"
        >
          通常表示モード
        </button>
        <button
          class="p-header-issue-info__back-button c-btn c-btn--red-filled"
          *ngIf="showButtons && !isMobile && !disableNormalMode"
          (click)="bootBriskClick.emit(); $event.stopPropagation()"
        >
          BRiSK 起動
        </button>
      </div>
    </ng-template>
  </div>
  <div id="spInfoAlways">
    <div class="d-inline pricing" *ngIf="view" [ngClass]="showOpenPredict ? 'pricing-with-predict' : ''">
      <div *ngIf="view.master && view.master.exRightFlag" class="ex-right-flag" [ngbTooltip]="view.master.exRightFlag | exRightFlag">
        落
      </div>
      <span class="heading heading-price" [ngbTooltip]="tooltipPrice">現値</span>
      <span [ngbTooltip]="tooltipPriceDetail"
        ><ng-container *ngIf="view.predictLastPrice.predict">(</ng-container
        ><span
          *ngIf="view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide"
          [ngClass]="view.predictLastPrice.quoteSide | sideClass"
          >{{ view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide }} </span
        >{{ view.predictLastPrice.lastPrice10 | formatPrice }} <ng-container *ngIf="view.predictLastPrice.predict">)</ng-container></span
      >
      /
      <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass" [ngbTooltip]="tooltipPriceChangeDetail">{{
        view.lastPriceBasePriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
      }}</span>
      <ng-container *ngIf="showOpenPredict && openPredict">
        <span
          >/ (寄
          <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass">{{ view.predictOpenPriceBasePriceChange | priceChanges }}</span
          >)</span
        >
      </ng-container>
    </div>
    <div class="d-inline spVwap" *ngIf="view && !showOpenPredict">
      <span class="heading heading-vwap">VWAP比</span>
      <span [ngClass]="view.vwapPriceChange | priceChangeClass">{{
        view.vwapPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
      }}</span>
    </div>

    <div class="d-inline spOpen" *ngIf="view">
      <span class="heading heading-open">始値比</span>
      <span [ngClass]="view.openPriceChange | priceChangeClass">{{
        view.openPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
      }}</span>
    </div>
    <ng-container *ngIf="liteSpLines == 1">
      <div class="d-inline spVolume" *ngIf="view">
        <span class="heading heading-price">出来高</span>
        <span
          ><ng-container *ngIf="turnover10"
            >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株</ng-container
          ></span
        >
        /
        <span
          ><ng-container *ngIf="turnover10"
            >{{
              turnover10 / 1000000000
                | fastDecimal
                  : {
                      separateComma: true,
                      fractionDigits: 2
                    }
                | predictTransform: turnoverPredict
            }}
            億円</ng-container
          ></span
        >
      </div>
      <div class="d-inline spTimestamp" *ngIf="view">
        <span>{{ view.timestamp | timestampFormat: { microseconds: false } }}</span>
      </div>
    </ng-container>
  </div>
  <div id="spInfoAdditional" *ngIf="liteSpLines == 2">
    <div class="d-inline volume" *ngIf="view">
      <span class="heading heading-price">出来高</span>
      <span
        ><ng-container *ngIf="turnover10"
          >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株</ng-container
        ></span
      >
      /
      <span
        ><ng-container *ngIf="turnover10"
          >{{
            turnover10 / 1000000000
              | fastDecimal
                : {
                    separateComma: true,
                    fractionDigits: 2
                  }
              | predictTransform: turnoverPredict
          }}
          億円</ng-container
        ></span
      >
    </div>
    <div class="d-inline spTimestamp" *ngIf="view">
      <span>{{ view.timestamp | timestampFormat: { microseconds: false } }}</span>
    </div>
  </div>
</ng-template>
<ng-template #litaContent>
  <!-- Lite -->
  <div class="issueCodeBox">
    <div class="issueCodeInputWrapper">
      <brisk-issue-code-input
        class="issueCodeInput"
        (stockSelected)="onStockSelected($event)"
        (invalidInput)="invalidInput.emit($event)"
      ></brisk-issue-code-input>
    </div>
    <div class="loanMarginFlag" [ngbTooltip]="tooltipLoanMargin">
      <fa-icon *ngIf="view && view.master.loanMarginFlag === 1" [icon]="['fas', 'circle']"></fa-icon>
      <fa-icon *ngIf="view && view.master.loanMarginFlag === 2" [icon]="['far', 'circle']"></fa-icon>
    </div>
    <div *ngIf="view" class="issueCode">{{ view.master.issueCode }}</div>
    <div *ngIf="view" class="issueName">
      {{ view.master.name }}
      ({{ view.master.prefix }}
      <ng-container *ngIf="view.master.industryName !== ''" id="industry"> -{{ view.master.industryName }}</ng-container>
      )
    </div>
  </div>
  <div class="pricing" *ngIf="view" [ngClass]="showOpenPredict ? 'pricing-with-predict' : ''">
    <div *ngIf="view.master && view.master.exRightFlag" class="ex-right-flag" [ngbTooltip]="view.master.exRightFlag | exRightFlag">落</div>
    <span class="heading heading-price" [ngbTooltip]="tooltipPrice">現値</span>
    <span [ngbTooltip]="tooltipPriceDetail"
      ><ng-container *ngIf="view.predictLastPrice.predict">(</ng-container
      ><span
        *ngIf="view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide"
        [ngClass]="view.predictLastPrice.quoteSide | sideClass"
        >{{ view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide }} </span
      >{{ view.predictLastPrice.lastPrice10 | formatPrice }} <ng-container *ngIf="view.predictLastPrice.predict">)</ng-container></span
    >
    /
    <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass" [ngbTooltip]="tooltipPriceChangeDetail">{{
      view.lastPriceBasePriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
    }}</span>
    <ng-container *ngIf="showOpenPredict && openPredict">
      <span
        >/ (寄付予想
        <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass">{{ view.predictOpenPriceBasePriceChange | priceChanges }}</span
        >)</span
      >
    </ng-container>
  </div>
  <div class="pricing" *ngIf="!view"></div>
  <div class="vwap" *ngIf="view && !showOpenPredict">
    <span class="heading heading-vwap">VWAP比</span>
    <span [ngClass]="view.vwapPriceChange | priceChangeClass">{{
      view.vwapPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
    }}</span>
  </div>
  <div class="vwap" *ngIf="!view"></div>
  <div class="open" *ngIf="view">
    <span class="heading heading-open">始値比</span>
    <span [ngClass]="view.openPriceChange | priceChangeClass">{{
      view.openPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
    }}</span>
  </div>
  <div class="open" *ngIf="!view"></div>
  <div class="turnover" *ngIf="view">
    <span class="heading heading-price">出来高</span>
    <span
      ><ng-container *ngIf="turnover10"
        >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株</ng-container
      ></span
    >
    /
    <span
      ><ng-container *ngIf="turnover10"
        >{{
          turnover10 / 1000000000
            | fastDecimal
              : {
                  separateComma: true,
                  fractionDigits: 2
                }
            | predictTransform: turnoverPredict
        }}
        億円</ng-container
      ></span
    >
  </div>
  <div class="turnover" *ngIf="!view"></div>

  <div class="timestamp" *ngIf="view && showTimestamp">
    <span>{{ view.timestamp | timestampFormat: { microseconds: false } }} 更新</span>
  </div>

  <div class="timestamp" *ngIf="!view"></div>

  <ng-template briskAssist="header-last-price" #assist1="briskAssist">
    <button
      type="button"
      class="c-assist-popover-button p-popover-header-lite"
      placement="bottom"
      [ngbPopover]="popContent"
      popoverClass="c-assist-popover c-assist-popover--default c-assist-popover--small"
    ></button>

    <ng-template #popContent>
      <div class="c-assist-popover__description">
        <p class="c-assist-popover__text">
          直近取引値段 / 騰落率<br />
          カ：買特別気配<br />
          ウ：売特別気配<br />
          （　）内の数値は予想値<br />
          騰落率は基準値段からの変化率
        </p>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
<ng-template #fita3Template>
  <!-- BRISK -->
  <div class="issueCodeBox">
    <div class="issueCodeInputWrapper">
      <brisk-issue-code-input
        class="issueCodeInput brisk"
        (stockSelected)="onStockSelected($event)"
        (invalidInput)="invalidInput.emit($event)"
      ></brisk-issue-code-input>
    </div>
    <div class="loanMarginFlag" [ngbTooltip]="tooltipLoanMargin">
      <fa-icon *ngIf="view && view.master.loanMarginFlag === 1" [icon]="['fas', 'circle']"></fa-icon>
      <fa-icon *ngIf="view && view.master.loanMarginFlag === 2" [icon]="['far', 'circle']"></fa-icon>
    </div>
    <div *ngIf="view" class="issueCode">{{ view.master.issueCode }}</div>
    <div *ngIf="view" class="issueName">{{ view.master.name }}-{{ view.master.prefix }}</div>
  </div>

  <div class="pricing" *ngIf="view">
    <ng-container *ngIf="view.master && view.master.exRightFlag">
      <div class="ex-right-flag" [ngbTooltip]="view.master.exRightFlag | exRightFlag">落</div>
    </ng-container>
    <span class="heading heading-price" [ngbTooltip]="tooltipPrice">現値</span>

    <ng-template briskAssist="header-last-price" #assist1="briskAssist">
      <button
        type="button"
        class="c-assist-popover-button p-popover-header"
        placement="bottom"
        [ngbPopover]="popContent"
        popoverClass="c-assist-popover c-assist-popover--default c-assist-popover--small"
      ></button>

      <ng-template #popContent>
        <div class="c-assist-popover__description">
          <p class="c-assist-popover__text">
            直近取引値段 / 騰落率 / VWAP比<br />
            カ：買特別気配<br />
            ウ：売特別気配<br />
            （　）内の数値は予想値<br />
            騰落率は基準値段からの変化率
          </p>
        </div>
      </ng-template>
    </ng-template>

    <span [ngbTooltip]="tooltipPriceDetail"
      ><ng-container *ngIf="view.predictLastPrice.predict">(</ng-container
      ><span
        *ngIf="view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide"
        [ngClass]="view.predictLastPrice.quoteSide | sideClass"
        >{{ view.predictLastPrice.quoteFlag | quoteSideStr: view.predictLastPrice.quoteSide }} </span
      >{{ view.predictLastPrice.lastPrice10 | formatPrice }} <ng-container *ngIf="view.predictLastPrice.predict">)</ng-container></span
    >
    /
    <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass" [ngbTooltip]="tooltipPriceChangeDetail">{{
      view.lastPriceBasePriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
    }}</span>
    <ng-container *ngIf="showOpenPredict && openPredict">
      <span
        >/ (寄
        <span [ngClass]="view.lastPriceBasePriceChange | priceChangeClass">{{ view.predictOpenPriceBasePriceChange | priceChanges }}</span
        >)</span
      >
    </ng-container>
    <ng-container *ngIf="!showOpenPredict">
      /
      <span [ngClass]="view.vwapPriceChange | priceChangeClass" [ngbTooltip]="tooltipVwapDetail">{{
        view.vwapPriceChange | priceChanges | predictTransform: view.predictLastPrice.predict
      }}</span>
    </ng-container>
  </div>

  <div class="turnover" *ngIf="view">
    <span class="heading heading-price">出来高</span>
    <span
      ><ng-container *ngIf="turnover10"
        >{{ volume | fastDecimal: { separateComma: true, fractionDigits: 0 } | predictTransform: turnoverPredict }} 株</ng-container
      ></span
    >
    /
    <span
      ><ng-container *ngIf="turnover10"
        >{{
          turnover10 / 1000000000
            | fastDecimal
              : {
                  separateComma: true,
                  fractionDigits: 2
                }
            | predictTransform: turnoverPredict
        }}
        億円</ng-container
      ></span
    >
  </div>

  <div class="mcap" *ngIf="view && !showTimestamp">
    <span class="heading heading-mcap">時価総額</span>
    <span *ngIf="view.info.calcSharesOutstanding !== null && view.info.calcSharesOutstanding !== undefined"
      >{{
        ((view.info.calcSharesOutstanding || 0) * (view.master.basePrice10 || 0)) / 1000000000
          | fastDecimal: { separateComma: true, fractionDigits: 0 }
      }}
      億円</span
    >
  </div>

  <div class="timestamp" *ngIf="view && showTimestamp">
    <span>{{ view.timestamp | timestampFormat: { microseconds: true } }} 更新</span>
  </div>
</ng-template>
<ng-template #tooltipPriceChange>
  騰落率(基準値段比)<br />
  （　）内の数値は予想値
</ng-template>
<ng-template #tooltipVwap>
  VWAP比<br />
  （　）内の数値は予想値
</ng-template>
<ng-template #tooltipLoanMargin>
  ● 貸借銘柄<br />
  ○ 貸借融資銘柄（制度信用買のみ可能）
</ng-template>
<ng-template #tooltipPrice>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { vwap: !showTimestamp && !liteSpMode, current: '' }"></ng-container>
</ng-template>
<ng-template #tooltipPriceDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { vwap: !showTimestamp && !liteSpMode, current: 'price' }"></ng-container>
</ng-template>
<ng-template #tooltipPriceChangeDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { vwap: !showTimestamp && !liteSpMode, current: 'change' }"></ng-container>
</ng-template>
<ng-template #tooltipVwapDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { vwap: !showTimestamp && !liteSpMode, current: 'vwap' }"></ng-container>
</ng-template>

<ng-template #tooltipPriceBase let-vwap="vwap" let-current="current">
  <span [class.tooltip-price-active]="current == 'price'">直近取引値段</span>
  /
  <span [class.tooltip-price-active]="current == 'change'">騰落率</span>
  <ng-container *ngIf="vwap">
    /
    <span [class.tooltip-price-active]="current == 'vwap'">VWAP比</span>
  </ng-container>
  <br />
  カ：買特別気配<br />
  ウ：売特別気配<br />
  （　）内の数値は予想値<br />
  騰落率は基準値段からの変化率
</ng-template>
