<div id="splashBackground">
  <div id="splash">
    <div id="logo"></div>
    <wj-linear-gauge
      #gauge
      id="gauge"
      [hasShadow]="true"
      [isAnimated]="false"
      [isReadOnly]="true"
      [value]="bootStatus$ | async"
    ></wj-linear-gauge>
  </div>
</div>
