import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { defer, Observable, of, throwError } from 'rxjs';
import { format } from 'date-fns';
import { FlexVersion, FrontendApiService, OHLCResponse } from '@argentumcode/brisk-common';
import { catchError, map, mergeMap, retryWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { mockApiDataDir, mockApiDate } from './mock-api-info';

export class ItaLogDiff {
  issueCodeIdx: number;
  from: number;
  to: number;
}

interface CommonResponse {
  result: boolean;
  error_code?: number;
  error_message?: string;
}

interface MarketTokenResponse {
  token: string;
}

interface SessionResponse extends CommonResponse {
  type: number;
  csrf_token: string;
  csrf_token_period: number;
  user_id?: string;
  email?: string;
}

class CommonResponse {
  result: boolean;
  errorCode?: number;
  errorMessage?: string;

  constructor(data: any) {
    this.result = data.result;
    this.errorCode = data.error_code;
    this.errorMessage = data.error_message;
  }
}

export class BasePriceUpdate {
  constructor(data: any) {
    this.issueCode = data.issue_code;
    this.basePrice10 = data.base_price10;
    this.limitUp10 = data.limit_up10;
    this.limitDown10 = data.limit_down10;
  }

  public issueCode: number;
  public basePrice10: number;
  public limitUp10: number;
  public limitDown10: number;
}

export class ExceptionalSQ {
  constructor(data: any) {
    this.side = data.buy_sell;
    this.range = data.jump_range;
    this.sec = data.jump_sec;
    this.issueCode = data.issue_code;
    this.quoteLimitDown = data.quote_limit_down;
    this.quoteLimitUp = data.quote_limit_up;
  }

  public side: string;
  public range: number;
  public sec: number;
  public issueCode: number;
  public quoteLimitDown: number;
  public quoteLimitUp: number;
}

export class BootResponse extends CommonResponse {
  date: string;
  sessionStatus: string;
  wsUrl: string;
  wsToken: string;
  master: string;
  snapshot: string;
  identity: string;
  sessionInfo: any;
  time: number;
  sessionExpires: number;
  nextDate: number;
  exceptionalSQ: Array<ExceptionalSQ>;
  basePrices: Array<BasePriceUpdate>;
  series: number;
  latestNews: number;
  flexVersion: FlexVersion;

  constructor(data: any) {
    super(data);
    this.date = data.date;
    this.sessionStatus = data.session_status;
    this.wsUrl = data.ws_url;
    this.wsToken = data.ws_token;
    this.master = data.master;
    this.snapshot = data.snapshot;
    this.identity = data.identity;
    this.sessionInfo = data.session_info;
    this.sessionExpires = data.session_expires;
    this.nextDate = data.next_date;
    this.time = data.time;
    this.series = data.series;
    if (data.exceptional_sq) {
      this.exceptionalSQ = data.exceptional_sq.map((a) => new ExceptionalSQ(a));
    }
    if (data.base_prices) {
      this.basePrices = data.base_prices.map((a) => new BasePriceUpdate(a));
    }
    if (data.latest_news) {
      this.latestNews = Number(data.latest_news);
    } else {
      this.latestNews = 0;
    }
    if (data.flex_version) {
      this.flexVersion = data.flex_version;
    } else {
      this.flexVersion = FlexVersion.Version16;
    }
  }
}

export class APIHttpError extends Error {
  public readonly authErrorCode: string;
  public readonly rateLimitError: string;

  public constructor(public response: HttpErrorResponse) {
    super(response.name);
    this.message = `${response.status} ${response.message}`;
    this.authErrorCode = response.headers.get('X-Auth-Error-Code');
    this.rateLimitError = response.headers.get('X-Rate-Limit-Error');
  }
}

export class HttpAuthError extends APIHttpError {
  public constructor(public response: HttpErrorResponse) {
    super(response);
    this.name = 'HttpAuthError';
  }
}

function createHttpError(response: HttpErrorResponse): Error {
  if (response.status === 401) {
    return new HttpAuthError(response);
  } else {
    return new APIHttpError(response);
  }
}

function defaultBackoffConfig(): RetryBackoffConfig {
  return {
    initialInterval: 500,
    maxRetries: 3,
    maxInterval: 20000,
    backoffDelay: (it, initialInterval) => {
      return Math.pow(2, it) * initialInterval;
    },
    shouldRetry: (err: any) => {
      if (err && err.name === 'HttpAuthError') {
        return false;
      }
      return true;
    },
  };
}

interface JSFCResponseRow {
  date: string;
  sochi: string;
  kakuhoLongShares: number;
  kakuhoShortShares: number;
  sokuhoLongShares: number;
  sokuhoShortShares: number;
  standardizedLongShares: number;
  standardizedShortShares: number;
  gyakuhibuFee: number;
  gyakuhibuFeePercent: number;
  gyakuhibuFeeDayCount: number;
  gyakuhibuMaxFee: number;
}

export interface APIStockList {
  id: string;
  name: string;
  issue_codes: Array<number>;
}

export interface StockListsResponse {
  version: '1';
  stock_lists: Array<APIStockList>;
}

interface JSFCResponse {
  [key: string]: JSFCResponseRow;
}

export class Announcement {
  public id: number;
  public announcement: string;
  public createdAt: string;
  public updatedAt: string;

  public constructor(data?: { id: number; announcement: string; created_at: string; updated_at: string }) {
    if (data) {
      this.id = data.id;
      this.announcement = data.announcement;
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
    }
  }
}

export interface StockVolume {
  issue_code: number;
  turnover: number;
  calc_shares_outstanding: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private csrfToken = '';
  private csrfTokenPeriod = 0;

  private apiToken: string;
  private apiEndpoint: string;
  private preferLocal: boolean;

  constructor(private http: ApiAuthService) {}

  setEndpoint(endpoint: string, token: string, preferLocal: boolean) {
    this.apiEndpoint = endpoint;
    this.apiToken = token;
    this.preferLocal = preferLocal;
  }

  private getEndpoint(endpoint: string, preferLocal = true) {
    if (preferLocal && this.preferLocal) {
      return endpoint;
    }
    return this.apiEndpoint + endpoint;
  }

  getWsEndpoint(path: string) {
    if (this.apiEndpoint) {
      if (this.apiEndpoint.startsWith('http://') || this.apiEndpoint.startsWith('https://')) {
        return 'ws' + this.apiEndpoint.substr(4) + path;
      }
    }
    return location.protocol.replace('http', 'ws') + '//' + location.host + path;
  }

  private authorizationHeader(): HttpHeaders {
    return new HttpHeaders();
  }

  boot(): Observable<BootResponse> {
    return of({
      result: true,
      series: 0,
      date: mockApiDate,
      identity: 'dummy_identity',
      session_status: 'running',
      ws_url: '/realtime/0',
      master: 'dummy',
      snapshot: 'dummy',
      time: Math.floor(new Date().getTime() / 1000),
      session_expires: 0,
      next_date: Math.floor(new Date().getTime() / 1000) + 60 * 60 * 24,
      base_prices: [],
      exceptional_sq: [],
      flex_version: 16000,
    }).pipe(
      map((resp) => new BootResponse(resp)),
      catchError((err) => throwError(this.processError(err))),
      retryBackoff(defaultBackoffConfig())
    );
  }

  master(name: string): Observable<Uint8Array> {
    return this.http
      .get(`/assets/${mockApiDataDir}/master.dat`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((resp) => new Uint8Array(resp)),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  wsData(): Observable<Uint8Array> {
    return this.http
      .get(`/assets/${mockApiDataDir}/ws.dat`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((resp) => new Uint8Array(resp)),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  stocks(name: string): Observable<HttpEvent<any>> {
    return this.http
      .request(
        new HttpRequest('GET', `/assets/${mockApiDataDir}/snapshot.dat`, {
          responseType: 'arraybuffer',
          reportProgress: true,
          headers: this.authorizationHeader(),
        })
      )
      .pipe(catchError((err) => throwError(this.processError(err))));
  }

  stocksInfo(date: string): Observable<Array<StockVolume>> {
    const params = new HttpParams().set('date', date);
    return this.http
      .get(this.getEndpoint(`/assets/${mockApiDataDir}/stocks_info.json`), {
        responseType: 'json',
        params: params,
        headers: this.authorizationHeader(),
      })
      .pipe(
        map((resp) => <Array<StockVolume>>resp),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  stocksUpdate(date: string, diff: Array<ItaLogDiff>, series?: number): Observable<Uint8Array> {
    throw new Error('Should not be called');
    const post_param = { issue_ids: [], update_numbers_from: [], update_numbers_to: [] };
    for (const d of diff) {
      post_param.issue_ids.push(d.issueCodeIdx);
      post_param.update_numbers_from.push(d.from);
      post_param.update_numbers_to.push(d.to);
    }
    let seriesStr = '';
    if (series === undefined || series === null) {
      seriesStr = '';
    } else {
      seriesStr = `/${series}`;
    }

    const params = new HttpParams().set('date', date);
    const headers = this.authorizationHeader().set('Content-Type', 'application/json');
    return this.http
      .post(this.getEndpoint(`/api/stocks_update${seriesStr}`), post_param, {
        responseType: 'arraybuffer',
        params: params,
        headers: headers,
      })
      .pipe(
        map((resp) => new Uint8Array(resp)),
        catchError((err) => throwError(this.processError(err)))
      );
  }

  jsfc(issueCode: number, count?: number): Observable<JSFCResponse> {
    return of({});
  }

  ohlc(issueCode: number, date: Date): Observable<OHLCResponse> {
    return this.http
      .get(this.getEndpoint(`/assets/${mockApiDataDir}/ohlc/${Number(issueCode)}.json`), {
        responseType: 'json',
        headers: this.authorizationHeader(),
      })
      .pipe(
        map((resp) => <OHLCResponse>resp),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  marketToken(): Observable<MarketTokenResponse> {
    throw new Error('`marketToken()` should not be called');
    return this.http
      .get(this.getEndpoint(`/api/app/market-token`), {
        responseType: 'json',
        headers: this.authorizationHeader(),
      })
      .pipe(
        map((resp) => <MarketTokenResponse>resp),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  markets(
    series: number,
    date: string,
    indexFrom?: number,
    indexTo?: number
  ): Observable<{ market_conditions: Array<any>; gyakuhibu: Array<any> }> {
    return of({ market_conditions: [], gyakuhibu: null });
  }

  session(): Observable<SessionResponse> {
    throw new Error('`session()` should not be called');
    return this.http.get(`/api/session`, { responseType: 'json' }).pipe(
      map((resp) => {
        this.csrfToken = (<SessionResponse>resp).csrf_token;
        this.csrfTokenPeriod = (<SessionResponse>resp).csrf_token_period;
        return <SessionResponse>resp;
      }),
      catchError((err) => throwError(this.processError(err))),
      retryBackoff(defaultBackoffConfig())
    );
  }

  stockLists(date: string): Observable<StockListsResponse> {
    const params = new HttpParams().set('date', date);
    return this.http
      .get<StockListsResponse>(`/assets/${mockApiDataDir}/stock_lists.json`, {
        responseType: 'json',
        headers: this.authorizationHeader(),
        params: params,
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((err) => throwError(this.processError(err))),
        retryBackoff(defaultBackoffConfig())
      );
  }

  // HttpErrorResponse
  private processError(error: any): Error {
    if (error && error instanceof HttpErrorResponse) {
      return createHttpError(error);
    }
    return error;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  private apiToken: Observable<string> | null = null;
  private _user: string | null = null;

  constructor(private frontendApi: FrontendApiService, private http: HttpClient) {}

  setApiToken(token: string, user: string) {
    this.apiToken = of(token);
    this._user = user;
  }

  private getApiToken(): Observable<string> {
    return defer(() => {
      if (!this.apiToken) {
        this.apiToken = this.frontendApi.boot().pipe(
          shareReplay(1),
          tap((resp) => {
            if (resp.userId !== this._user) {
              throw new Error('Invalid User');
            }
          }),
          map((resp) => resp.apiToken)
        );
      }
      return this.apiToken;
    });
  }

  get<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'arraybuffer';
      withCredentials?: boolean;
    }
  ): Observable<ArrayBuffer>;

  get<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T>;

  get(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: string;
      withCredentials?: boolean;
    }
  ): Observable<any> {
    return this.getApiToken().pipe(
      switchMap((token) => {
        if (!options) {
          options = {};
        }
        options.headers = {};
        return this.http.get(url, options as any);
      }),
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((err, count) => {
            if (err instanceof HttpErrorResponse && err.status === 401 && count <= 1) {
              this.apiToken = null;
              return of(0);
            } else {
              return throwError(err);
            }
          })
        )
      )
    );
  }

  post(
    url: string,
    body: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    }
  ): Observable<ArrayBuffer> {
    return this.getApiToken().pipe(
      switchMap((token) => {
        options.headers = {};
        return this.http.post(url, body, options);
      }),
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((err, count) => {
            if (err instanceof HttpErrorResponse && err.status === 401 && count <= 1) {
              this.apiToken = null;
              return of(0);
            } else {
              return throwError(err);
            }
          })
        )
      )
    );
  }

  request<T, B>(request: HttpRequest<B>) {
    return this.getApiToken().pipe(
      switchMap((token) => {
        const req = request.clone({
          headers: new HttpHeaders(),
        });
        return this.http.request(req);
      }),
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((err, count) => {
            if (err instanceof HttpErrorResponse && err.status === 401 && count <= 1) {
              this.apiToken = null;
              return of(0);
            } else {
              return throwError(err);
            }
          })
        )
      )
    );
  }
}
