<brisk-vg-grid
  #grid
  briskVgGridFlexibleRowSize
  [itemsSource]="dataSource"
  [addDummyRow]="true"
  [showRowHeader]="true"
  [selectionMode]="'None'"
  [class.u-non-live-bg]="!live"
  [minWidth]="minWidthPx"
>
  <brisk-vg-grid-column
    [name]="'price10'"
    [binding]="'price'"
    [width]="'6*'"
    [header]="'値段'"
    [allowSorting]="false"
    [filterRoundPrecision]="1"
    [filterType]="'Condition'"
    [align]="'center'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <div [ngClass]="item | rowTypeClass: 'price':master" class="qr-grid-cell text-center add-padding">
        <ng-container *ngIf="normalized">
          {{ item.price10 / master.basePrice10 - 1 | priceChanges: { showPercent: false } }}
        </ng-container>
        <ng-container *ngIf="!normalized">
          {{ item.price10 | formatPrice }}
        </ng-container>
      </div>
    </ng-template>
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [name]="'quantity'"
    [binding]="'quantity'"
    [width]="'6*'"
    [header]="'株数'"
    [allowSorting]="false"
    [filterRoundPrecision]="0"
    [filterType]="'Condition'"
    [align]="'right'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <div class="text-right qr-grid-cell add-padding" [ngClass]="item | rowTypeClass: 'quantity':master">
        {{ item.quantity | fastDecimal: { separateComma: forceSeparateComma } }}
      </div>
    </ng-template>
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [name]="'turnover'"
    [binding]="'turnover'"
    [width]="'6*'"
    [header]="'金額'"
    [allowSorting]="false"
    [filterRoundPrecision]="1"
    [filterType]="'Condition'"
    [cssClass]="'add-padding'"
    [align]="'right'"
    [visible]="showAmount"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <div class="text-right qr-grid-cell add-padding" [ngClass]="item | rowTypeClass: 'turnover':master">
        {{
          (item.price10 * item.quantity) / 10000000
            | fastDecimal
              : {
                  fractionDigits: 1,
                  separateComma: forceSeparateComma
                }
        }}
      </div>
    </ng-template>
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [allowSorting]="true"
    [align]="'center'"
    [name]="'timestamp'"
    [binding]="'timestamp'"
    [width]="shortTimestamp ? '5*' : '12*'"
    [header]="'時刻'"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      {{ item.timestamp | timestampFormat: { microseconds: !shortTimestamp } }}
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>
