import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { normalize } from 'jaconv';
import { Button, ModalDialogService } from '../shared/modal-dialog.service';
import { Toast, ToasterService, ToastType } from '@argentumcode/brisk-common';

export class SmartPasteResult {
  public canceled = false;

  public constructor(
    // 追加対象の銘柄コード
    public issueCodes: Array<number> = [],
    // 存在しない銘柄コード
    public failureCount: number = 0,
    public emptyClipboard: boolean = false
  ) {}
}

class InternalSmartPasteResult {
  public canceled = false;

  public constructor(
    /**
     * 追加対象の銘柄コード(山括弧あり)
     */
    public pIssueCodes: Array<number> = [],
    /**
     * 追加対象の銘柄コード(山括弧なし)
     */
    public nIssueCodes: Array<number> = [],
    public issueCodes: Array<number> = [],
    // 存在しない銘柄コード
    public multipleColumn: boolean = false,
    public allowDuplication = false
  ) {}

  public merge(ret: InternalSmartPasteResult) {
    this.pIssueCodes = this.pIssueCodes.concat(ret.pIssueCodes);
    this.nIssueCodes = this.nIssueCodes.concat(ret.nIssueCodes);
    this.issueCodes = this.issueCodes.concat(ret.issueCodes);
    this.multipleColumn = this.multipleColumn || ret.multipleColumn;
    this.allowDuplication = this.allowDuplication && ret.allowDuplication;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SmartPasteService {
  private issueCodes: Set<number>;

  constructor(private toaster: ToasterService, private dialog: ModalDialogService) {}

  initialize(issueCodes: number[]) {
    this.issueCodes = new Set<number>();
    for (const issueCode of issueCodes) {
      this.issueCodes.add(issueCode);
    }
  }

  paste(event: ClipboardEvent): Observable<SmartPasteResult> {
    const data = event.clipboardData;
    if (!data) {
      // IE11
      const text = window['clipboardData'].getData('Text');
      return this.pasteText(text);
    } else {
      const html = data.getData('text/html');
      if (html) {
        return this.pasteHtml(html);
      } else {
        return this.pasteText(data.getData('text'));
      }
    }
  }

  pasteText(text: string): Observable<SmartPasteResult> {
    return new Observable((observer) => {
      try {
        if (!text) {
          observer.next(new SmartPasteResult(null, 0, true));
          observer.complete();
        } else {
          const issueCodes = this.parseText(text);
          observer.next(this.createResult(issueCodes));
          observer.complete();
        }
      } catch (ex) {
        observer.error(ex);
      }
    });
  }

  pasteHtml(html: string): Observable<SmartPasteResult> {
    return new Observable((observer) => {
      try {
        const result = this.parseHtml(html);
        if (result.multipleColumn) {
          this.dialog
            .dialog('複数のカラムが銘柄コードの候補となりました。続けますか。', [
              new Button('はい', 'Yes', 'btn-primary'),
              new Button('いいえ', 'Cancel'),
            ])
            .subscribe((dialogResult) => {
              if (dialogResult !== 'Yes') {
                const r = new SmartPasteResult();
                r.canceled = true;
                observer.next(r);
                observer.complete();
              }
              observer.next(this.createResult(result));
              observer.complete();
            });
        } else {
          observer.next(this.createResult(result));
          observer.complete();
        }
      } catch (ex) {
        observer.error(ex);
      }
    });
  }

  showToaster(result: SmartPasteResult) {
    console.log(result);
    if (result.emptyClipboard) {
      this.toaster.add(new Toast('クリップボードが空です', ToastType.Info));
    } else if (result.issueCodes.length === 0) {
      this.toaster.add(new Toast('銘柄コードが見つかりませんでした', ToastType.Info));
    } else if (result.failureCount === 0) {
      this.toaster.add(new Toast(`${result.issueCodes.length}件を追加しました`, ToastType.Success));
    } else {
      this.toaster.add(
        new Toast(
          [
            `${result.issueCodes.length}件を正常に追加しました。`,
            `${result.failureCount}件の銘柄コードが東証の銘柄として認識できず、追加できませんでした。`,
          ],
          ToastType.Error
        )
      );
    }
  }

  /**
   * 4桁または5桁の数字のうち、空白または()（）＜＞<>「」\[\]にて区切られているものを銘柄コードと見なす
   *
   * @param text
   */
  private parseText(text: string): InternalSmartPasteResult {
    if (!text) {
      return new InternalSmartPasteResult();
    }
    const allowDuplicate = !/[^0-9\r\n]/.test(text);
    const normalizedText = normalize(text).toLowerCase();
    const pRet = this.scan(/<j?([0-9]{4,5})((\.|\/|-)[a-z][a-z]?)?>/g, normalizedText).map((a) => Number(a[0]));
    const nRet = normalizedText
      .replace(/<j?([0-9]{4,5})((\.|\/|-)[a-z][a-z]?)?>/g, '')
      .split(/[\s()（）＜＞<>「」【】\[\]"]/)
      .filter((a) => /^[0-9]{4,5}((\/|\.|-)[a-z][a-z]?)?$/.test(a) || /^j[0-9]{4,5}((\/|\.|-)[a-z][a-z]?)?$/.test(a))
      .map((a) => Number(a.replace(/[^0-9]/g, '')));
    const ret = normalizedText
      .split(/[\s()（）＜＞<>「」【】\[\]"]/)
      .filter((a) => /^[0-9]{4,5}((\/|\.|-)[a-z][a-z]?)?$/.test(a) || /^j[0-9]{4,5}((\/|\.|-)[a-z][a-z]?)?$/.test(a))
      .map((a) => Number(a.replace(/[^0-9]/g, '')));
    return new InternalSmartPasteResult(pRet, nRet, ret, false, allowDuplicate);
  }

  private parseHtml(html: string): InternalSmartPasteResult {
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, 'text/html');
    return this.parseNode(dom);
  }

  private hasTable(node: Node): boolean {
    const children = node.childNodes;
    if (node instanceof HTMLTableElement) {
      return true;
    }
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (this.hasTable(child)) {
        return true;
      }
    }
    return false;
  }

  private parseNode(node: Node): InternalSmartPasteResult {
    if (!(node instanceof Document) && !this.hasTable(node)) {
      return this.parseText(this.getNodeText(node));
    }
    if (node instanceof HTMLTableElement) {
      return this.parseTable(node);
    } else if (node instanceof Text) {
      return this.parseText(node.wholeText);
    } else {
      const ret = new InternalSmartPasteResult();
      const children = node.childNodes;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const r = this.parseNode(child);
        ret.merge(r);
      }
      return ret;
    }
  }

  private parseTable(element: HTMLTableElement): InternalSmartPasteResult {
    const table = new Array<Array<string>>(element.rows.length).fill([]).map(() => []);
    for (let i = 0; i < element.rows.length; i++) {
      const row = element.rows[i];
      if (row instanceof HTMLTableRowElement) {
        for (let j = 0; j < row.cells.length; j++) {
          const cell = row.cells[j];
          let colSpan = 1;
          let rowSpan = 1;
          if (cell instanceof HTMLTableCellElement && cell.colSpan > 1) {
            colSpan = cell.colSpan;
          }
          if (cell instanceof HTMLTableCellElement && cell.rowSpan > 1) {
            rowSpan = cell.rowSpan;
          }
          let col = 0;
          if (!table[i]) {
            table[i] = [];
          }
          while (table[i][col] !== undefined) {
            col++;
          }
          const text = this.parseHtml(cell.innerHTML || '');
          for (let k = 0; k < rowSpan; k++) {
            for (let l = 0; l < colSpan; l++) {
              if (!table[i + k]) {
                table[i + k] = [];
              }
              table[i + k][col + l] = k !== 0 ? new InternalSmartPasteResult() : text;
            }
          }
        }
      }
    }
    if (table.length === 0) {
      return new InternalSmartPasteResult();
    }
    // 補正
    while (table.length > 1 && table[0].length < table[1].length) {
      table[0].unshift('');
    }
    const columnsCount = new Array(table.map((a) => a.length).reduce((a, b) => Math.max(a, b))).fill(0);
    if (columnsCount.length === 0) {
      return new InternalSmartPasteResult();
    }
    for (let r = 0; r < table.length; r++) {
      for (let c = 0; c < table[r].length; c++) {
        if (table[r][c] && table[r][c].issueCodes.filter((a) => this.issueCodes.has(a)).length > 0) {
          columnsCount[c]++;
        }
      }
    }
    const ret = new InternalSmartPasteResult();
    const maximumColumnCount = columnsCount.reduce((a, b) => Math.max(a, b));
    if (maximumColumnCount === 0) {
      return new InternalSmartPasteResult([], []);
    }
    let multipleMaximumColumn = 0;
    for (let c = 0; c < columnsCount.length; c++) {
      if (columnsCount[c] === maximumColumnCount) {
        for (let r = 0; r < table.length; r++) {
          if (table[r][c]) {
            ret.merge(table[r][c]);
          }
        }
        multipleMaximumColumn++;
        if (multipleMaximumColumn >= 2) {
          ret.multipleColumn = true;
        }
      }
    }
    return ret;
  }

  private getNodeText(node: Node): string {
    if (node instanceof Text) {
      return node.wholeText;
    }
    const children = node.childNodes;
    let result = '';
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      result += this.getNodeText(child) + ' ';
    }
    return result;
  }

  private createResult(ret: InternalSmartPasteResult): SmartPasteResult {
    const pLength = ret.pIssueCodes.filter((a) => this.issueCodes.has(a)).length;
    const nLength = ret.nIssueCodes.filter((a) => this.issueCodes.has(a)).length;
    const retIssueCodes = pLength > nLength ? ret.pIssueCodes : pLength < nLength ? ret.nIssueCodes : ret.issueCodes;
    const failure = retIssueCodes.filter((a) => a < 10000 && !this.issueCodes.has(a)).length;
    const s = {};
    const uniqueIssueCodes = [];
    for (const i of retIssueCodes) {
      if (ret.allowDuplication || !(i in s)) {
        uniqueIssueCodes.push(i);
      }
      s[i] = true;
    }
    return new SmartPasteResult(
      uniqueIssueCodes.filter((a) => this.issueCodes.has(a)),
      failure,
      false
    );
  }

  private scan(regexp: RegExp, text: string): Array<RegExpExecArray> {
    if (!regexp.global) {
      throw new RangeError('Global Option is required');
    }
    let m: RegExpExecArray;
    const r = new Array<RegExpExecArray>();
    while ((m = regexp.exec(text))) {
      m.shift();
      r.push(m);
    }
    return r;
  }
}
