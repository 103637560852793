import { Component, Input } from '@angular/core';
import { JSFCEntry } from './jsfc-entry';
import { format } from 'date-fns';
import { unparse } from 'papaparse';
import { FastDecimalPipe } from '../../brisk-common/fast-decimal.pipe';
import * as FileSaver from 'file-saver';
import { SochiPipe } from '../../brisk-common/sochi.pipe';

@Component({
  selector: 'brisk-jsfc',
  templateUrl: './jsfc.component.html',
  styleUrls: ['./jsfc.component.css'],
})
export class JsfcComponent {
  @Input()
  jsfcEntries: Array<JSFCEntry> = [];

  @Input()
  public minWidth: number = null;

  constructor(private _sochi: SochiPipe, private _fastDecimal: FastDecimalPipe) {}

  isDecimal(x: number) {
    return Math.round(x) !== x;
  }

  exportCsv(filename: string): void {
    if (this.jsfcEntries.length === 0) {
      return;
    }
    const csv = this.createCsv();

    // Excel対策にBOMを付ける
    const blob = new Blob([String.fromCharCode(0xfeff), csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, filename, true);
  }

  createCsv(): string {
    const fields = [
      '日付',
      '日証金 措置',
      '日証金 買',
      '日証金 売',
      '日証金 差',
      '制度信用 買',
      '制度信用 売',
      '制度信用 差',
      '逆日歩',
      '逆日歩(%)',
      '',
      '最大',
    ];
    const data = [];
    for (const jsfcEntry of this.jsfcEntries) {
      data.push([
        format(jsfcEntry.date, 'YYYY-MM-DD'),
        this._sochi.transform(jsfcEntry.sochi),

        this._fastDecimal.transform(jsfcEntry.jsfcLongShares, { fractionDigits: 0, separateComma: false }),
        this._fastDecimal.transform(jsfcEntry.jsfcShortShares, { fractionDigits: 0, separateComma: false }),
        this._fastDecimal.transform(jsfcEntry.jsfcDiffShares, { fractionDigits: 0, separateComma: false }),

        this._fastDecimal.transform(jsfcEntry.standardizedLongShares, { fractionDigits: 0, separateComma: false }),
        this._fastDecimal.transform(jsfcEntry.standardizedShortShares, { fractionDigits: 0, separateComma: false }),
        this._fastDecimal.transform(jsfcEntry.standardizedDiffShares, { fractionDigits: 0, separateComma: false }),

        this.isDecimal(jsfcEntry.gyakuhibuFee)
          ? this._fastDecimal.transform(jsfcEntry.gyakuhibuFee, { fractionDigits: 2 })
          : this._fastDecimal.transform(jsfcEntry.gyakuhibuFee, { fractionDigits: 0 }),
        this._fastDecimal.transform(jsfcEntry.gyakuhibuFeePercent, { fractionDigits: 4 }),
        this._fastDecimal.transform(jsfcEntry.gyakuhibuFeeDayCount, { fractionDigits: 0 }),
        this.isDecimal(jsfcEntry.gyakuhibuMaxFee)
          ? this._fastDecimal.transform(jsfcEntry.gyakuhibuMaxFee, { fractionDigits: 2 })
          : this._fastDecimal.transform(jsfcEntry.gyakuhibuMaxFee, { fractionDigits: 0 }),
      ]);
    }
    const csv = unparse({
      fields: fields,
      data: data,
    });
    return csv;
  }
}
