import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GroupHeader } from './group';
import { VgGridColumnFilter } from '../vg-grid-filter';
export type SortComparer<T> = (item1: T, item2: T, dir: SortDirection) => -1 | 0 | 1;

export type FilterValueGetter<T> = (
  item1: T,
  binding?: string
) => string | number | Set<string> | Set<number> | { name: string; value: string } | null | undefined;

export function comparerByValue<T>(getter: (item: T) => string | number | undefined | null): SortComparer<T> {
  return (item1: T, item2: T, dir: SortDirection) => {
    const a = getter(item1);
    const b = getter(item2);
    if (a === b) {
      return 0;
    }
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    if (a < b) {
      return dir === SortDirection.Asc ? -1 : 1;
    } else if (a > b) {
      return dir === SortDirection.Asc ? 1 : -1;
    } else {
      return 0;
    }
  };
}

export const enum SortDirection {
  Asc,
  Desc,
}

export class SortDescription {
  constructor(
    public readonly binding: string,
    public readonly sortDirection: SortDirection,
    private _compareFunction?: SortComparer<any>
  ) {}

  compare(item1, item2): number {
    if (this._compareFunction) {
      return this._compareFunction(item1, item2, this.sortDirection);
    }
    const a = item1[this.binding];
    const b = item2[this.binding];
    if (a === b) {
      return 0;
    }
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    if (a < b) {
      return this.sortDirection === SortDirection.Asc ? -1 : 1;
    } else if (a > b) {
      return this.sortDirection === SortDirection.Asc ? 1 : -1;
    } else {
      return 0;
    }
  }
}

export abstract class IGroupDescription {
  abstract group(item: any): string;
}

export class VgStreamView<T = any> {
  private _dataSource: Array<T>;
  private _dataSourceSubscription: Subscription;
  private readonly _defaultOrder: SortDirection;
  private _itemsSubject = new BehaviorSubject<Array<any>>([]);
  private _filterDescriptions: Array<VgGridColumnFilter> = [];
  private _filteredItems: Array<T> = [];
  private _filteredIndex: number = null;
  private _filterColumns = new Map<string, VgGridColumnFilter>();

  readonly items = this._itemsSubject.asObservable();
  sortDirection: SortDirection = null;

  get reverse(): boolean {
    if (this.sortDirection === null) {
      return this._defaultOrder === SortDirection.Desc;
    }
    return this.sortDirection === SortDirection.Desc;
  }

  constructor(dataSource: Observable<Array<T>>, defaultOrder = SortDirection.Asc) {
    this._dataSourceSubscription = dataSource.subscribe(
      (arr) => {
        if (this._dataSource !== arr) {
          this._dataSource = arr;
          this._filteredIndex = null;
        }
        this.update();
      },
      (err) => {
        this.dispose();
        throw err;
      },
      () => {
        this.dispose();
      }
    );
    this._defaultOrder = defaultOrder;
  }

  update() {
    if (this._filterDescriptions.length > 0) {
      if (this._filteredIndex === null || this._filteredIndex > this._dataSource.length) {
        this._filteredItems = [];
        this._filteredIndex = 0;
      }
      for (; this._filteredIndex < this._dataSource.length; this._filteredIndex++) {
        if (this._filter(this._dataSource[this._filteredIndex])) {
          this._filteredItems.push(this._dataSource[this._filteredIndex]);
        }
      }
      this._itemsSubject.next(this._filteredItems);
    } else {
      this._itemsSubject.next(this._dataSource);
    }
  }

  public dispose() {
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    if (this._itemsSubject) {
      this._itemsSubject.complete();
    }
  }

  private _updateFilter() {
    this._filteredItems = [];
    this._filteredIndex = null;
  }

  getFilterDescription(binding: string): VgGridColumnFilter {
    return this._filterColumns.has(binding) ? this._filterColumns.get(binding) : null;
  }

  setFilterDescription(filter: VgGridColumnFilter) {
    this._updateFilter();
    for (let i = 0; i < this._filterDescriptions.length; i++) {
      if (this._filterDescriptions[i].binding === filter.binding) {
        this._filterDescriptions.splice(i, 1, filter);
        this._filterColumns.set(filter.binding, filter);
        return;
      }
    }
    this._filterDescriptions.push(filter);
    this._filterColumns.set(filter.binding, filter);
  }

  clearFilterDescription(binding: string) {
    this._updateFilter();
    for (let i = 0; i < this._filterDescriptions.length; i++) {
      if (this._filterDescriptions[i].binding === binding) {
        this._filterDescriptions.splice(i, 1);
        this._filterColumns.delete(binding);
        return;
      }
    }
  }

  getUniqueValuesForColumn(binding: string): Set<any> {
    const ret = new Set<any>();
    const filter = this.getFilterDescription(binding);
    if (filter) {
      this.clearFilterDescription(binding);
    }
    for (const item of this._dataSource) {
      if (this._filter(item)) {
        ret.add(item[binding]);
      }
    }
    if (filter) {
      this.setFilterDescription(filter);
    }
    return ret;
  }

  private _filter(item: T): boolean {
    for (const f of this._filterDescriptions) {
      if (!f.filter(item)) {
        return false;
      }
    }
    return true;
  }
}

export class VgCollectionView<T = any> {
  private _dataSource: Array<T>;
  private _items: Array<T | GroupHeader>;
  private _groups: Map<string, Array<T>>;
  private _groupHeaders = new Map<string, GroupHeader>();
  private _groupNames: Array<string>;
  private _dataSourceSubscription: Subscription;
  private _itemsSubject = new BehaviorSubject<Array<T | GroupHeader>>([]);
  private _sortColumns = new Map<string, SortDirection>();
  private _filterColumns = new Map<string, VgGridColumnFilter>();

  readonly items = this._itemsSubject.asObservable();
  groupDescription: IGroupDescription = null;
  sortDescriptions: Array<SortDescription> = [];
  filterDescriptions: Array<VgGridColumnFilter> = [];

  constructor(dataSource: Observable<Array<T>>) {
    this._dataSourceSubscription = dataSource.subscribe(
      (arr) => {
        this._dataSource = arr;
        this.update();
      },
      (err) => {
        this.dispose();
        throw err;
      },
      () => {
        this.dispose();
      }
    );
  }

  isSorted(): boolean {
    return this._sortColumns.size > 0;
  }

  getSortDirection(binding: string): SortDirection | null {
    return this._sortColumns.has(binding) ? this._sortColumns.get(binding) : null;
  }

  isFiltered(): boolean {
    return this._filterColumns.size > 0;
  }

  getFilterDescription(binding: string): VgGridColumnFilter {
    return this._filterColumns.has(binding) ? this._filterColumns.get(binding) : null;
  }

  setFilterDescription(filter: VgGridColumnFilter) {
    for (let i = 0; i < this.filterDescriptions.length; i++) {
      if (this.filterDescriptions[i].binding === filter.binding) {
        this.filterDescriptions.splice(i, 1, filter);
        this._filterColumns.set(filter.binding, filter);
        return;
      }
    }
    this.filterDescriptions.push(filter);
    this._filterColumns.set(filter.binding, filter);
  }

  clearFilterDescription(binding: string) {
    for (let i = 0; i < this.filterDescriptions.length; i++) {
      if (this.filterDescriptions[i].binding === binding) {
        this.filterDescriptions.splice(i, 1);
        this._filterColumns.delete(binding);
        return;
      }
    }
  }

  getUniqueValuesForColumn(binding: string, valueGetter?: FilterValueGetter<any>): Set<any> {
    const ret = new Set<any>();
    const filter = this.getFilterDescription(binding);
    if (filter) {
      this.clearFilterDescription(binding);
    }
    for (const item of this._dataSource) {
      if (this.filter(item)) {
        const value = valueGetter ? valueGetter(item, binding) : item[binding];
        if (value instanceof Set) {
          for (const elm of value) {
            ret.add(elm);
          }
        } else {
          ret.add(value);
        }
      }
    }
    if (filter) {
      this.setFilterDescription(filter);
    }
    return ret;
  }

  update(headerCallback?: (header: GroupHeader) => void) {
    // TODO: Callbackを使わずによりよい形に書き直せないか
    if (!this._dataSource) {
      return;
    }
    this._items = [];
    for (const item of this._dataSource) {
      if (this.filter(item)) {
        this._items.push(item);
      }
    }
    this.sort();
    if (this.groupDescription) {
      this._createGroups();
      this._items = [];
      const newHeaders = new Map<string, GroupHeader>();
      for (const grp of this._groupNames) {
        const header = this._groupHeaders.has(grp) ? this._groupHeaders.get(grp) : new GroupHeader(grp);
        newHeaders.set(grp, header);

        if (headerCallback) {
          headerCallback(header);
        }
        this._items.push(header);
        if (!header.collapsed) {
          for (const item of this._groups.get(grp)) {
            this._items.push(item);
          }
        }
      }
      this._groupHeaders = newHeaders;
    }
    this._sortColumns.clear();
    for (const desc of this.sortDescriptions) {
      if (desc) {
        this._sortColumns.set(desc.binding, desc.sortDirection);
      }
    }

    this._filterColumns.clear();
    for (const f of this.filterDescriptions) {
      this._filterColumns.set(f.binding, f);
    }
    this._itemsSubject.next(this._items);
  }

  dispose() {
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    if (this._itemsSubject) {
      this._itemsSubject.complete();
    }
  }

  deleteItems(items: Array<any>): Array<any> {
    const targets = [];
    for (const item of items) {
      if (item instanceof GroupHeader) {
        if (item.collapsed && this._groups.has(item.name)) {
          for (const i of this._groups.get(item.name)) {
            targets.push(i);
          }
        }
      } else {
        targets.push(item);
      }
    }
    return targets;
  }

  protected filter(item: any): boolean {
    for (const f of this.filterDescriptions) {
      if (!f.filter(item)) {
        return false;
      }
    }
    return true;
  }

  protected sort() {
    if (this.sortDescriptions.length === 0) {
      return;
    }
    this._items.sort((a, b) => {
      for (const desc of this.sortDescriptions) {
        if (desc) {
          const c = desc.compare(a, b);
          if (c !== 0) {
            return c;
          }
        }
      }
      return 0;
    });
  }

  private _createGroups() {
    this._groups = new Map<string, Array<T>>();
    this._groupNames = [];
    for (const item of this._items as Array<T>) {
      const description = this.groupDescription.group(item);
      if (!this._groups.has(description)) {
        this._groups.set(description, []);
        this._groupNames.push(description);
      }
      this._groups.get(description).push(item);
    }
  }
}
