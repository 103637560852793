import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hit-lift-bar',
  templateUrl: './hit-lift-bar.component.html',
  styleUrls: ['./hit-lift-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HitLiftBarComponent implements OnInit {
  @Input()
  public hitCount: number;
  @Input()
  public liftCount: number;
  @Input()
  public issueCount: number;

  @Input()
  public isDisabled = false;

  get hitRate(): number {
    return this.hitCount / this.issueCount;
  }

  get liftRate(): number {
    return this.liftCount / this.issueCount;
  }

  constructor() {}

  ngOnInit() {}
}
