<wj-popup
  id="inputDialog"
  class="modal-content"
  #inputDialogPopup
  [modal]="true"
  [fadeIn]="false"
  [fadeOut]="false"
  [hideTrigger]="'None'"
  (hidden)="complete()"
>
  <form method="get" action="#" (submit)="submit(value)">
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <span class="close" data-dismiss="modal" aria-label="Close" (click)="inputDialogPopup.hide()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="text">{{ message }}</label>
        <input [(ngModel)]="value" type="text" autofocus class="form-control" id="text" name="text" maxlength="9" />
      </div>
    </div>
    <div class="modal-footer">
      <input type="submit" class="btn btn-primary" value="OK" />
    </div>
  </form>
</wj-popup>
