import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ORDER_OPERATOR_TOKEN, ORDER_UI_SERVICE_TOKEN } from '../types/interfaces';
import { NoopOrderOperatorService } from './noop-order-operator.service';
import { OrderDialogsComponent } from './order-dialogs/order-dialogs.component';
import { OrderDialogsPerWindowComponent } from './order-dialogs-per-window/order-dialogs-per-window.component';
import { TradeConfigComponent } from './trade-config/trade-config.component';
import { ItaPanelHeaderComponent } from './ita-panel-header/ita-panel-header.component';
import { NoopOrderUiService } from './noop-order-ui.service';

@NgModule({
  declarations: [OrderDialogsComponent, OrderDialogsPerWindowComponent, TradeConfigComponent, ItaPanelHeaderComponent],
  imports: [CommonModule],
  providers: [
    [
      { provide: ORDER_OPERATOR_TOKEN, useClass: NoopOrderOperatorService },
      { provide: ORDER_UI_SERVICE_TOKEN, useClass: NoopOrderUiService },
    ],
  ],
  exports: [OrderDialogsComponent, OrderDialogsPerWindowComponent, TradeConfigComponent, ItaPanelHeaderComponent],
})
export class NoopOrderUiModule {}
