import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface State {
  issueCode?: number;
  groupName?: string;
}

@Injectable()
export class MapViewTooltipService implements OnDestroy {
  private _state: State = {};
  private readonly _stateSubject = new BehaviorSubject(this._state);
  private _recursiveUpdate = false;
  readonly state$: Observable<State> = this._stateSubject.asObservable();
  readonly issueCode$ = this.state$.pipe(
    map((s) => s.issueCode),
    distinctUntilChanged()
  );
  readonly groupName$ = this.state$.pipe(
    map((s) => s.groupName),
    distinctUntilChanged()
  );

  private updateState(state: State) {
    if (this._recursiveUpdate) {
      if (isDevMode()) {
        console.error('Synchronous updating can cause unintended behavior. Consider using asapScheduler. See ReactiveX/rxjs#2155');
      } else {
        console.error('Synchronous updating');
      }
    }
    this._recursiveUpdate = true;
    this._state = state;
    this._stateSubject.next(this._state);
    this._recursiveUpdate = false;
  }

  updateIssueCode(issueCode: number) {
    this.updateState({
      ...this._state,
      issueCode,
    });
  }

  updateGroupName(groupName: string | undefined) {
    this.updateState({
      ...this._state,
      groupName,
    });
  }

  ngOnDestroy(): void {
    this._stateSubject.complete();
  }
}
