import { FlexConfig, FlexExchange } from '@argentumcode/brisk-common';

export const flexConfigTokyo: FlexConfig = {
  exchange: FlexExchange.Tokyo,
  ohlcLength: 60,
  marketCloseTimestamp: 15 * 60 * 60 * 1000,
  fallbackIssueCode: 1321,
};

export const flexConfigFukuoka: FlexConfig = {
  exchange: FlexExchange.Fukuoka,
  ohlcLength: 66,
  marketCloseTimestamp: (15 * 60 + 30) * 60 * 1000,
  fallbackIssueCode: 9942,
};
