import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { StockWrapper } from '@argentumcode/brisk-common';
import { Observable, Subscription } from 'rxjs';
import { ItaViewService } from '../../core/ita-view.service';
import { MiniChartComponent } from '../mini-chart/mini-chart.component';
import { ChartViewItem } from '../chart-portfolio/chart-portfolio.component';

@Component({
  selector: 'app-chart-portfolio-item',
  templateUrl: './chart-portfolio-item.component.html',
  styleUrls: ['./chart-portfolio-item.component.scss'],
})
export class ChartPortfolioItemComponent implements OnInit, OnDestroy {
  @ViewChild('chart')
  public chart: MiniChartComponent;
  @Output()
  public itemClicked = new EventEmitter();
  @Output()
  public itemDblClicked = new EventEmitter();
  public stockWrapper: StockWrapper;
  private id: number = null;
  private updatedViewSubscription: Subscription = null;

  constructor(private itaView: ItaViewService) {}

  @Input()
  set updatedView(updatedView: Observable<any>) {
    if (this.updatedViewSubscription !== null) {
      this.updatedViewSubscription.unsubscribe();
      this.updatedViewSubscription = null;
    }
    if (updatedView) {
      this.updatedViewSubscription = updatedView.subscribe(() => {
        if (this.chart) {
          this.chart.updateSize();
        }
      });
    }
  }

  private _item: ChartViewItem;

  @Input()
  get item(): ChartViewItem {
    return this._item;
  }

  set item(value: ChartViewItem) {
    if (this._item === value) {
      return;
    }
    this.discordStock();
    this._item = value;
    if (this._item) {
      [this.id, this.stockWrapper] = this.itaView.getStock(this.item.issueCode, true);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.discordStock();
    if (this.updatedViewSubscription !== null) {
      this.updatedViewSubscription.unsubscribe();
      this.updatedViewSubscription = null;
    }
  }

  private discordStock() {
    if (this.id !== null) {
      this.itaView.removeStock(this.id);
      this.id = null;
    }
  }
}
