import { Pipe, PipeTransform } from '@angular/core';
import { ColorConverterService } from './color-converter.service';

@Pipe({
  name: 'priceChangeClass',
})
export class PriceChangeClassPipe implements PipeTransform {
  constructor(private colorConverterService: ColorConverterService) {}

  transform(value: any): any {
    return this.colorConverterService.priceChangeToClass(value);
  }
}
