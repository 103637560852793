import { Summary } from '@argentumcode/brisk-common';

export enum SummaryChangeType {
  AddSummary,
  DeleteSummary,
  UpdateSummary,
}

export enum UpdateSummaryType {
  None,
  AddIssues,
  ChangeIssue,
  RemoveIssues,
  FullUpdate,
}

export enum UpdateSummaryListType {
  None = 0,
  FullUpdate = 1,
}

export class SummaryChangeEvent {
  public updateType: UpdateSummaryType = null;
  public indexes: Array<number> = null;

  public constructor(public type: SummaryChangeType, public summary: Summary) {}
}

export class SummaryListChangeEvent {
  public constructor(public type: UpdateSummaryListType) {}
}
