import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../brisk-browser/local-storage.service';

@Component({
  selector: 'brisk-first-tutorial',
  templateUrl: './first-tutorial.component.html',
  styleUrls: ['./first-tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstTutorialComponent implements OnInit {
  /// 配置方向。ngbPopoverにそのまま渡されます。
  @Input()
  placement = 'top';

  /// 配置するコンテナ。ngbPopoverにそのまま渡されます。
  @Input()
  container = 'body';

  /// 「もっと詳しく」ボタンのリンク先。空の場合はボタンが表示されません。
  @Input()
  detailLink = '';

  /// 表示状況を保存するためのキー
  /// 空の場合は表示状況の保存を行わない。表示状況の管理はShowメソッドやClose Eventで呼び出し元が適切に行う。
  @Input()
  storageKey = '';

  /// ngbPopoverに渡すCSSクラス名
  @Input()
  cssClass = '';

  /// 表示する画像のパス
  @Input()
  imagePath = '';

  @ViewChild('popover', { static: true })
  private _popover: NgbPopover;

  @Output()
  closed = new EventEmitter<void>();

  shown = false;

  constructor(private localStorage: LocalStorageService) {}

  ngOnInit() {}

  show() {
    if (!this.storageKey) {
      this._popover.open();
      this.shown = true;
      return;
    }
    const used = this.localStorage.getItem(`first_tutorial_${this.storageKey}`);

    if (!used) {
      this._popover.open();
      this.shown = true;
    }
  }

  close() {
    this._popover.close();
    if (this.storageKey) {
      this.localStorage.setItem(`first_tutorial_${this.storageKey}`, '1');
    }
    this.closed.emit();
  }

  closeWithoutEmit() {
    this._popover.close();
  }
}
