import { Injectable } from '@angular/core';
import { OrderOperator, OrderUIConfig } from '../types/interfaces';
import { BehaviorSubject, forkJoin, NEVER, Observable, of, ReplaySubject } from 'rxjs';
import { BriskDialogConfig, BriskDialogService, StockOrderSummaryForHeader } from '@argentumcode/brisk-common';
import { PersistentStateService } from '../../fita4-core/persistent-state.service';
import { mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { UnsupportedBrowserComponent } from '../../shared-components/unsupported-browser/unsupported-browser.component';

interface ViewOnlyState {
  // 発注不可であることの確認のダイアログをスキップするかどうか
  skipViewOnlyDialog?: boolean;
}

@Injectable()
export class TachibanaNoopOrderOperatorService implements OrderOperator {
  initialized: Observable<void>;

  updateStock: Observable<number | null> = NEVER;

  config: OrderUIConfig = {
    orderEnabled: false,
    showBrowserDialog: false, // 元々発注できないので、ダイアログも表示しない
  };

  config$ = of(this.config);

  reconnecting$ = of(false);

  constructor(
    private _persistentState: PersistentStateService<unknown, ViewOnlyState>,
    private cocomero: CocomeroService,
    private dialog: BriskDialogService
  ) {
    this.initialized = forkJoin([this._persistentState.viewOnlyState$.pipe(take(1)), this.cocomero.frontendBootData$]).pipe(
      mergeMap(([state, bootData]) => {
        // e支店では、セッションにviewOnly情報が含まれていて、"true"の時は閲覧専用である
        // トライアルサイトでは、viewOnlyは含まれていない
        if (state?.skipViewOnlyDialog || !(bootData?.viewOnly === 'true')) {
          return of(void 0);
        }
        return this.dialog
          .openDialog(
            {
              content: ['BRiSK Nextの発注機能は、一定の条件を満たしたお客様のみご利用頂けます。'],
              closeOnEscape: true,
              title: '閲覧専用モードで起動しています',
              checkboxText: '今後、このメッセージを表示しない ',
              showCloseButton: false,
              buttons: [
                {
                  text: 'OK',
                  initialFocus: true,
                },
              ],
            },
            {
              width: '48em',
            }
          )
          .pipe(
            tap((result) => {
              if (result.type === 'button' && result.checked) {
                this._persistentState.updateViewOnlyState({
                  skipViewOnlyDialog: true,
                });
              }
            })
          );
      }),
      shareReplay(1)
    );
  }

  getStockInfo(issueCode: number): Observable<StockOrderSummaryForHeader> {
    return of({});
  }
}
