import { Observable } from 'rxjs';

export class MiniChartSource {
  public lastPrices: Array<number>;
  public quantity: Array<number>;
  public updated: Observable<number>;
  public vwap: number;
  public vwapPrice: number;
  public vwapIndex: number;
}
