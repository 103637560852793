<wj-popup
  #csvImportDialog
  id="csvImportDialog"
  [modal]="true"
  [hideTrigger]="'None'"
  [fadeIn]="false"
  [fadeOut]="false"
  (hidden)="onHidden()"
>
  <div class="modal-header">
    <h5 class="modal-title">CSVインポート</h5>
    <span class="close wj-hide" data-dismiss="modal" aria-label="Close" (click)="csvImportDialog.hide()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <form [formGroup]="formGroup">
    <div class="modal-body">
      <div class="form-group">
        <label for="text">CSVファイル</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button [disabled]="formGroup.disabled" class="btn btn-outline-primary" type="button" (click)="file.click()" autofocus>
              ファイルを選択
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            readonly
            [value]="fileReadSubscription ? 'CSV読み込み中です' : filename ? filename.value : 'ファイルが選択されていません'"
            tabIndex="-1"
            (click)="file.click()"
          />
          <div class="invalid-feedback" [ngClass]="csvError ? 'd-block' : ''">
            {{ csvError }}
          </div>
        </div>
        <input
          #file
          type="file"
          class="d-none"
          (change)="onFileSelected($event.target.files[0]); $event.target.value = null"
          accept=".txt,.csv"
        />
      </div>
      <ng-container *ngIf="multipleWatchLists">
        <label>追加対象の銘柄銘柄リスト</label>
        <wj-flex-grid
          #watchListGrid
          briskFlexGridFlexibleRowSize
          [itemsSource]="watchLists"
          [autoClipboard]="false"
          [allowSorting]="false"
          [allowDragging]="'None'"
          [allowResizing]="'None'"
          [showAlternatingRows]="false"
          [allowAddNew]="false"
          [allowDelete]="false"
          [headersVisibility]="'Column'"
          (cellEditEnded)="onCellEditEnded(watchListGrid, $event)"
          (beginningEdit)="onBeginningEdit(watchListGrid, $event)"
        >
          <wj-flex-grid-column [width]="'0.3*'" binding="checked" name="checked" header=" " align="center"> </wj-flex-grid-column>
          <wj-flex-grid-column [width]="'1.4*'" binding="name" name="name" header="銘柄リスト名" align="center"> </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="add-padding"
            [width]="'0.5*'"
            binding=""
            name="issueCodeCount"
            header="有効行数"
            align="center"
            [isReadOnly]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
              <div class="text-right">
                <span>{{ item.issueCodes.length }}</span>
              </div>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="add-padding"
            [width]="'0.5*'"
            binding=""
            name="invalidCount"
            header="無効行数"
            align="center"
            [isReadOnly]="true"
          >
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
              <div class="text-right">
                <span>{{ item.invalidIssueCode.length }}</span>
              </div>
            </ng-template>
          </wj-flex-grid-column>
          <wj-flex-grid-column [width]="'1.6*'" binding="nameError" name="nameError" header="エラー" align="center" [isReadOnly]="true">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
              <div class="grid-name-error">
                <ng-container *ngIf="item.nameError?.required">
                  リスト名未入力
                </ng-container>
                <ng-container *ngIf="item.nameError?.notUnique">
                  リスト名重複
                </ng-container>
                <ng-container *ngIf="item.nameError?.nameLength">
                  リスト名9文字制限
                </ng-container>
              </div>
            </ng-template>
          </wj-flex-grid-column>
        </wj-flex-grid>
      </ng-container>
      <ng-container *ngIf="!multipleWatchLists">
        <div class="form-group">
          <label for="text">銘柄リスト名</label>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            [ngClass]="name.invalid && (name.dirty || name.touched) ? 'is-invalid' : ''"
            maxlength="9"
          />
          <div class="invalid-feedback">
            <ng-container *ngIf="name.errors?.required">
              銘柄リスト名を入力してください。
            </ng-container>
            <ng-container *ngIf="name.errors?.notUnique">
              銘柄リスト名が重複しています。
            </ng-container>
            <ng-container *ngIf="name.errors?.nameLength">
              銘柄リスト名は9文字以内で入力してください。
            </ng-container>
          </div>
        </div>
        <div class="form-group">
          <label for="text">対象カラム</label>
          <select class="form-control" name="column" formControlName="columnIndex">
            <option *ngFor="let item of columns; let i = index" [value]="i">{{ i + 1 }}: {{ item }}</option>
            <option *ngIf="columns && columns.length === 0 && !filename.value">ファイルを選択してください。</option>
          </select>
        </div>
        <div class="form-group">
          <label for="text">件数</label>
          <input
            type="text"
            readonly
            class="form-control"
            tabIndex="-1"
            [value]="!filename.value || !issueCount ? '' : (issueCount[columnIndex?.value] || 0).toString() + '件'"
          />
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="multipleWatchLists">
        <button #okBtn [disabled]="!validMultipleWatchLists" class="btn btn-primary" (click)="onSubmit()">インポート</button>
      </ng-container>
      <ng-container *ngIf="!multipleWatchLists">
        <button #okBtn [disabled]="!formGroup.valid" class="btn btn-primary" (click)="onSubmit()">インポート</button>
      </ng-container>
      <button #cancelBtn class="btn btn-light wj-hide">キャンセル</button>
    </div>
  </form>
</wj-popup>
