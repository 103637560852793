import { ApplicationRef, Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlreadyInitializedError, ParentWindowNotFoundError } from './error';
import { PARENT_WINDOW_GETTER } from './children-window.service';

@Injectable()
export class ChildrenWindowSyncService implements OnDestroy {
  private _initialized = false;

  constructor(@Inject(PARENT_WINDOW_GETTER) private _parent: () => Window) {}

  ngOnDestroy(): void {}

  initialize() {
    if (this._initialized) {
      throw new AlreadyInitializedError();
    }
    this._initialized = true;
    const p = this._parent();
    if (!p) {
      throw new ParentWindowNotFoundError();
    }
    this._setupConsole(p as any);
  }

  _setupConsole(p: typeof globalThis): void {
    if (p === window) {
      return;
    }
    const origLog = window.console.log;
    const origError = window.console.error;
    window.console.log = (...args: Array<any>) => {
      p.console.log('[CHILD]', ...args);
      origLog(...args);
    };
    window.console.error = (...args: Array<any>) => {
      p.console.error('[CHILD]', ...args);
      origError(...args);
    };
  }
}
