<span [ngbTooltip]="tooltip">
  <span class="heading">板時計</span>
  <span class="density">
    <span class="density__wrapper">
      <span *ngIf="state && !state.hidden" class="density__inner" [style.width]="5 * state?.density + 'em'"></span
    ></span>
    <span class="clock">
      <ng-container *ngIf="state && !state.hidden; else noavailable">{{ state.time | timestampFormat }}</ng-container>
      <ng-template #noavailable>
        --:--:--
      </ng-template>
    </span>
    &nbsp;
  </span>
</span>

<ng-template #tooltip>
  リアルタイムに受信した最新の板の時刻<br /><br />
  通信エラーの場合や、<br />
  最後に受信してから10秒経過した場合は --:--:-- <br /><br />

  オレンジのバーは直近1秒間のリアルタイム受信量
</ng-template>
