import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrGridComponent } from './qr-grid.component';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RowTypeClassPipe } from './row-type-class.pipe';
import { VgGridModule } from '../vg-grid/vg-grid.module';

@NgModule({
  imports: [CommonModule, WjGridModule, WjGridFilterModule, BriskCommonModule, ScrollingModule, VgGridModule],
  declarations: [QrGridComponent, RowTypeClassPipe],
  exports: [QrGridComponent],
})
export class QrGridModule {}
