import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampFormat',
})
export class TimestampFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const h = Math.trunc(value / 60 / 60 / 1000000).toString();
    const m = (Math.trunc(value / 60 / 1000000) % 60).toString();
    const s = (Math.trunc(value / 1000000) % 60).toString();
    const ms =
      args && 'microseconds' in args && args['microseconds']
        ? '.' + (value % 1000000).toString().padStart(6, '0')
        : args && 'milliseconds' in args && args['milliseconds']
        ? '.' + (Math.trunc(value / 1000) % 1000).toString().padStart(3, '0')
        : '';

    return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}${ms}`;
  }
}
