import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from '@argentumcode/brisk-common';
import { BehaviorSubject, Observable } from 'rxjs';

export class NewsState {
  constructor(public readonly localRevision: number, public readonly remoteRevision: number) {}
}

@Injectable({
  providedIn: 'root',
})
export class NewsService implements OnDestroy {
  // Local Storageが利用可能かどうか
  private _initialized = false;

  private _remoteRevision = 0;
  private _localRevision = 0;

  private _stateSubject: BehaviorSubject<NewsState>;
  public state: Observable<NewsState>;

  constructor(private _localStorage: LocalStorageService) {
    this._stateSubject = new BehaviorSubject<NewsState>(new NewsState(this._localRevision, this._remoteRevision));
    this.state = this._stateSubject.asObservable();
  }

  start(): void {
    this._initialized = true;
    const revisionStr = this._localStorage.getItem('news_revision');
    if (revisionStr) {
      this._localRevision = Number(revisionStr);
    } else {
      this._localRevision = 0;
    }
    this._updateRevision();
  }

  pushRevision(removeRevision: number): void {
    this._remoteRevision = removeRevision;
    this._updateRevision();
  }

  updateLocalRevision(): void {
    if (!this._initialized) {
      throw new Error('Not Initialized');
    }
    this._localRevision = this._remoteRevision;
    this._localStorage.setItem('news_revision', this._localRevision.toString());
    this._updateRevision();
  }

  private _updateRevision() {
    this._stateSubject.next(new NewsState(this._localRevision, this._remoteRevision));
  }

  ngOnDestroy(): void {
    this._stateSubject.complete();
  }
}
