import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { VgGridColumnChoiceFilter, VgGridColumnConditionFilter, VgGridColumnFilter, VgGridColumnValueFilter } from '../vg-grid-filter';
import { FilterType } from '../vg-grid-filter';
import { VgGridChoiceFilterComponent } from '../vg-grid-choice-filter/vg-grid-choice-filter.component';

export class FocusOutEvent {
  constructor(public element: HTMLElement) {}
}

@Component({
  selector: 'brisk-vg-grid-filter',
  templateUrl: './vg-grid-filter.component.html',
  styleUrls: ['./vg-grid-filter.component.scss'],
})
export class VgGridFilterComponent implements OnInit, OnChanges {
  FilterType = FilterType;

  @Input()
  filter: VgGridColumnFilter;

  @Output()
  apply = new EventEmitter<VgGridColumnFilter>();

  @Output()
  cancel = new EventEmitter<{}>();

  @Output()
  focusOut = new EventEmitter<FocusOutEvent>();

  _filterType: FilterType;

  @ViewChild('mainElement', { static: true })
  public mainElement: ElementRef;

  constructor() {}

  ngOnInit() {
    const elem = this.mainElement.nativeElement as HTMLDivElement;
    elem.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateFilterType();
  }

  private _updateFilterType() {
    if (this.filter instanceof VgGridColumnConditionFilter) {
      this._filterType = FilterType.Condition;
    }
    if (this.filter instanceof VgGridColumnValueFilter) {
      this._filterType = FilterType.Value;
    }
    if (this.filter instanceof VgGridColumnChoiceFilter) {
      this._filterType = FilterType.Choice;
    }
  }

  _onFocusout(event: FocusEvent) {
    const relatedTarget: HTMLElement = event.relatedTarget || event['explicitOriginalTarget'] || document.activeElement; // IE11
    const elem = this.mainElement.nativeElement as HTMLDivElement;
    console.log(relatedTarget.classList.contains('wj-dropdown-panel'));
    if (!relatedTarget || elem.contains(relatedTarget)) {
      return;
    } else if (
      'classList' in relatedTarget &&
      (relatedTarget.classList.contains('wj-listbox-item') || relatedTarget.classList.contains('wj-dropdown-panel'))
    ) {
      return;
    } else {
      this.focusOut.emit(new FocusOutEvent(relatedTarget));
      this.cancel.emit();
    }
  }

  _onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel.emit();
    }
  }
}
