import { ApplicationRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { AlreadyInitializedError } from './error';

@Injectable()
export class ParentWindowSyncService implements OnDestroy {
  private _initialized = false;
  constructor(private _ref: ApplicationRef) {}

  initialize() {
    console.log('parent window');
    if (this._initialized) {
      throw new AlreadyInitializedError();
    }
    this._initialized = true;
  }

  ngOnDestroy(): void {}
}
