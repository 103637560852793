// 単一カラムのユーザーの設定
import { VgGridColumn } from './vg-grid-column';

export interface VgGridColumnState {
  // 順序(重み)
  order: number;
  // 固定表示
  sticky: boolean;
  // 表示・非表示
  visible: boolean;
}

// カラムについてユーザーの設定
export interface VgGridColumnsState {
  [key: string]: VgGridColumnState;
}

const INF = 1000000000;

export function normalizeColumnStateConfig<T>(state: VgGridColumnsState, columns: Readonly<Array<VgGridColumn<T>>>): VgGridColumnsState {
  const keys = new Set<string>();
  // Check key state
  const nextState = { ...state };
  // 新しいカラムが追加された場合には初期設定する
  let headerOrder = -100000; //  headerは順序を固定する
  const columnStates: Array<VgGridColumnState & { name: string; index: number }> = [];
  let index = 0;
  for (const column of columns) {
    if (!column.name) {
      throw new Error('column name is required for ColumnState');
    }
    if (keys.has(column.name)) {
      throw new Error(`columns has duplicated key: ${column.name}`);
    }
    keys.add(column.name);
    if (!(column.name in nextState)) {
      nextState[column.name] = {
        visible: column.initialVisible,
        sticky: column.initialStickyColumn,
        order: INF,
      };
    }
    if (column.isHeader) {
      nextState[column.name] = {
        ...nextState[column.name],
        order: headerOrder,
      };
      headerOrder++;
    }
    columnStates.push({ name: column.name, ...nextState[column.name], index });
    index++;
  }

  // カラムの順序を設定する
  columnStates.sort((a1, a2) => {
    if (a1.order === a2.order) {
      return a1.index - a2.index;
    } else {
      return a1.order - a2.order;
    }
  });
  let sticky = false;
  for (let rev = columnStates.length - 1; rev >= 0; rev--) {
    sticky = sticky || columnStates[rev].sticky;
    columnStates[rev].sticky = sticky;
  }

  index = 0;
  for (const col of columnStates) {
    nextState[col.name] = { sticky: col.sticky, visible: col.visible, order: index };
    index++;
  }

  return nextState;
}
