<div #mainElement class="vg-grid-filter-main" tabindex="0" (focusout)="_onFocusout($event)" (keydown)="_onKeyDown($event)">
  <ng-container *ngIf="_filterType === FilterType.Condition">
    <brisk-vg-grid-condition-filter [filter]="filter" (apply)="apply.emit($event)" (cancel)="cancel.emit()">
    </brisk-vg-grid-condition-filter>
  </ng-container>
  <ng-container *ngIf="_filterType === FilterType.Value">
    <brisk-vg-grid-value-filter [filter]="filter" (apply)="apply.emit($event)" (cancel)="cancel.emit()"> </brisk-vg-grid-value-filter>
  </ng-container>
  <ng-container *ngIf="_filterType === FilterType.Choice">
    <brisk-vg-grid-choice-filter [filter]="filter" (apply)="apply.emit($event)" (cancel)="cancel.emit()"> </brisk-vg-grid-choice-filter>
  </ng-container>
</div>
