import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { FlexOperator } from './flex-operator';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { addMilliseconds, differenceInDays, isBefore, startOfDay } from 'date-fns';
import { environment } from '../../environments/default/environment';
import { TimestampFormatPipe } from '@argentumcode/brisk-common';

export class FlexTimestampError extends Error {
  constructor(debugLog: string) {
    super(debugLog);
    this.name = 'FlexTimestampError';
  }
}

export class WebsocketHeartbeatError extends Error {
  constructor(debugLog: string) {
    super(debugLog);
    this.name = 'WebsocketHeartbeatError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionCheckService implements OnDestroy {
  private checkTimestampSubscription: Subscription = null;
  private connectionClosedSubject = new ReplaySubject<Error>();
  public connectionClosed = this.connectionClosedSubject.asObservable();

  constructor(private timestampPipe: TimestampFormatPipe, private _zone: NgZone) {}

  startCheckFlexTimestamp(flexOperator: FlexOperator) {
    this.stopCheckFlexTimestamp();
    this.connectionClosedSubject = new ReplaySubject<Error>();
    this.connectionClosed = this.connectionClosedSubject.asObservable();
    this._zone.runOutsideAngular(() => {
      this.checkTimestampSubscription = timer(0, 1000).subscribe(() => {
        this._zone.runGuarded(() => {
          this.checkFlexTimestamp(flexOperator);
          this.checkHeartbeat(flexOperator);
        });
      });
    });
  }

  stopCheckFlexTimestamp() {
    if (this.checkTimestampSubscription) {
      this.checkTimestampSubscription.unsubscribe();
      this.checkTimestampSubscription = null;
    }
  }

  public checkFlexTimestamp(op: FlexOperator) {
    const flexTimestamp = op.getTimestamp();
    if (flexTimestamp === 0) {
      return;
    }
    if (!op.serverTimestamp) {
      return;
    }
    const serverTimestamp = op.serverTimestamp.getServerTimestamp(new Date());
    if (differenceInDays(startOfDay(op.date), startOfDay(serverTimestamp)) !== 0) {
      return;
    }
    const hour = (serverTimestamp.getUTCHours() + 9) % 24; // JST
    const minute = serverTimestamp.getUTCMinutes() % 60;
    const seconds = serverTimestamp.getUTCSeconds() % 60;
    if (hour >= 15 || hour <= 7) {
      return;
    }
    if ((hour === 11 && minute >= 30) || (hour === 12 && minute < 10)) {
      // Temporary
      return;
    }
    const timestamp = (60 * (hour * 60 + minute) + seconds) * 1000000;
    if (timestamp - flexTimestamp > 90 * 1000000) {
      throw new FlexTimestampError(
        `Server: ${serverTimestamp}, local: ${this.timestampPipe.transform(flexTimestamp, { microseconds: true })}`
      );
    }
  }

  public checkHeartbeat(op: FlexOperator) {
    if (
      op.lastHeartbeat &&
      isBefore(
        addMilliseconds(op.lastHeartbeat, environment.heartbeatIntervalMilliSeconds),
        op.serverTimestamp.getServerTimestamp(new Date())
      )
    ) {
      const errorLog =
        `connection check failure Server: ${addMilliseconds(op.lastHeartbeat, environment.heartbeatIntervalMilliSeconds)}` +
        `Local: ${op.serverTimestamp.getServerTimestamp(new Date())}}`;
      this.connectionClosedSubject.next(new WebsocketHeartbeatError(errorLog));
    }
  }

  ngOnDestroy(): void {
    this.stopCheckFlexTimestamp();
    if (this.connectionClosedSubject) {
      this.connectionClosedSubject.complete();
      this.connectionClosedSubject.unsubscribe();
    }
  }
}
