import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exRightFlag',
})
export class ExRightFlagPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const name = [
      '',
      '新株落ち',
      '配当落ち',
      'その他権利落ち',
      '新株落ち + 配当落ち',
      '新株落ち + その他権利落ち',
      '配当落ち + その他権利落ち',
      '新株落ち + 配当落ち + その他権利落ち',
      '権利預かり証落ち',
    ];
    return name[value];
  }
}
