import { Directive, Input } from '@angular/core';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';

@Directive({
  selector: '[briskFlexGridDisableScroll]',
})
export class FlexGridDisableScrollDirective {
  @Input()
  forceScrollDisable = false;

  constructor(private flexGrid: WjFlexGrid) {
    const func = this.flexGrid.scrollIntoView.bind(this.flexGrid);
    this.flexGrid.scrollIntoView = (r: number, c: number, refresh?: boolean) => {
      if (!this.forceScrollDisable) {
        return func(r, c, refresh);
      }
      return false;
    };
  }

  scrollIntoView(r: number, c: number, refresh?: boolean) {
    const backup = this.forceScrollDisable;
    try {
      this.forceScrollDisable = false;
      this.flexGrid.scrollIntoView(r, c, refresh);
    } finally {
      this.forceScrollDisable = backup;
    }
  }
}
