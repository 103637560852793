import { StockView } from '../flex';

export abstract class ItaCaptureOperatorService {
  abstract cloneStockView(s: StockView): StockView;

  abstract goUpdateNumber(s: StockView, updateNumber: number): void;

  abstract updateStockView(s: StockView): void;

  abstract filename(s: StockView): string;

  abstract bottom(): string;

  abstract workerFileName(): string;
}
