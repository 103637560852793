import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FastDecimalPipe } from '../../brisk-common/fast-decimal.pipe';
import { MiniChartSource } from '../mini-chart-source';
import { Subscription } from 'rxjs';
import { parse as parseUA } from 'woothee';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FlexConfig, FLEX_CONFIG } from '../../flex';

class YTick {
  public constructor(public y: number, public label: string, public showLine = true) {}
}

class XTick {
  public constructor(public x: number, public label: string) {}
}

@Component({
  selector: 'brisk-mini-chart',
  templateUrl: './mini-chart.component.html',
  styleUrls: ['./mini-chart.component.scss'],
})
export class MiniChartComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  get miniChartSource(): MiniChartSource {
    return this._miniChartSource;
  }

  set miniChartSource(value: MiniChartSource) {
    if (this._miniChartSource === value) {
      return;
    }
    this._miniChartSource = value;
    if (this.miniChartUpdatedSubscription) {
      this.miniChartUpdatedSubscription.unsubscribe();
    }
    if (this.miniChartSource) {
      this.miniChartUpdatedSubscription = this.miniChartSource.updated.subscribe(() => {
        this.update();
      });
      this.init();
    }
  }

  // 左側のパディング(y目盛用)
  @Input()
  public paddingLeft = 50;
  // 右側のパディング
  @Input()
  public paddingRight = 10;
  // 上側のパディング
  @Input()
  public paddingTop = 10;
  // 下側のパディング(x目盛用)
  @Input()
  public paddingBottom = 25;

  // グラフ描画領域の横幅
  @Input()
  public innerWidth = 300;
  // グラフ描画領域の横パディング
  @Input()
  public innerXPadding = 5;
  // グラフの横幅
  public width = this.innerXPadding * 2 + this.innerWidth;

  @Input()
  public lastPriceChangesHeight = 168;

  @Input()
  public quantityHeight = 50;

  // x軸のグリッド表示をするか
  @Input()
  public showXGrid = false;

  // x軸を表示するか
  @Input()
  public showXTick = true;

  // x軸メモリの長さ
  @Input()
  public xTickLength = 3;

  @Input()
  public fontSize = 10;

  @Input()
  public showVwap = true;

  randomIdForClipPath = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);

  // 描画領域全体の横幅
  public get outerWidth(): number {
    return this.paddingLeft + this.width + this.paddingRight;
  }
  // 描画領域全体の縦幅
  public get outerHeight(): number {
    return this.paddingTop + this.lastPriceChangesHeight + this.quantityHeight + this.paddingBottom;
  }
  public yLimitMin = 0.04;
  public yPadding = 0.005;
  public yLimit = this.yLimitMin;

  public yLines: Array<YTick> = [];
  public yLineTick = 0.04;

  public N: number;
  public quantityN: number;
  public quantityWidth: number;
  public quantityYLimit = 0;
  public quantityYPadding = 0.1;
  public quantityYLine = null;

  public isSafari = false;

  public xLines: Array<XTick> = [];

  constructor(
    private fastDecimal: FastDecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(FLEX_CONFIG) private flexConfig: FlexConfig
  ) {
    const ua = parseUA(window.navigator.userAgent);
    this.isSafari = ua.name === 'Safari' || ua.os === 'iPad' || ua.os === 'iPhone';
    this.N = this.flexConfig.ohlcLength * 5;
    this.quantityN = this.flexConfig.ohlcLength;
    this.quantityWidth = this.innerWidth / this.quantityN;
  }

  private _miniChartSource: MiniChartSource;

  private miniChartUpdatedSubscription: Subscription;

  // 単位 なし(0.01 = 1%)
  public lastPrices: Array<number>;

  // 単位 なし(0.01 = 1%)
  public vwap: number;
  // 単位 円
  public vwapPrice: number;
  public vwapIndex: number;

  // 単位 100万円
  public quantities: Array<number>;

  ngOnInit() {
    this.setupXLines();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('innerWidth' in simpleChanges || 'innerXPadding' in simpleChanges || 'showXTick' in simpleChanges) {
      this.setupXLines();
    }
    if ('innerWidth' in simpleChanges || 'innerXPadding' in simpleChanges) {
      this.setupWidth();
    }
  }

  private setupXLines() {
    const xLines = [
      new XTick((0 * this.innerWidth) / this.N, '9:00'),
      new XTick((60 * this.innerWidth) / this.N, '10:00'),
      new XTick((120 * this.innerWidth) / this.N, '11:00'),
      new XTick((180 * this.innerWidth) / this.N, '13:00'),
      new XTick((240 * this.innerWidth) / this.N, '14:00'),
      new XTick((300 * this.innerWidth) / this.N, '15:00'),
    ];
    if (this.innerXPadding === 0 && !this.showXTick) {
      this.xLines = xLines.slice(1, -1);
    } else {
      this.xLines = xLines;
    }
  }

  private setupWidth() {
    this.width = this.innerXPadding * 2 + this.innerWidth;
  }

  init() {
    this.update();
  }

  update() {
    this.lastPrices = this.miniChartSource.lastPrices.slice(0);
    this.vwap = this.miniChartSource.vwap;
    this.vwapPrice = this.miniChartSource.vwapPrice;
    this.vwapIndex = this.miniChartSource.vwapIndex;
    this.quantities = this.miniChartSource.quantity;
    let yLimit = this.yLimitMin;
    for (const lastPrice of this.lastPrices) {
      if (lastPrice) {
        yLimit = Math.max(yLimit, Math.abs(lastPrice) + this.yPadding);
      }
    }
    if (this.showVwap) {
      if (this.vwap) {
        this.yLimit = Math.max(yLimit, Math.abs(this.vwap) + this.yPadding);
      }
    }
    this.yLimit = yLimit;

    this.yLines = [new YTick(0, '0%')];
    for (let i = this.yLineTick; i < this.yLimit; i += this.yLineTick) {
      this.yLines.push(new YTick(i, `${Math.round(i * 100)}%`, false));
      this.yLines.push(new YTick(-i, `-${Math.round(i * 100)}%`, false));
    }
    if (this.yLimit % this.yLineTick === 0) {
      this.yLines.push(new YTick(this.yLimit, `${Math.round(this.yLimit * 100)}%`, false));
      this.yLines.push(new YTick(-this.yLimit, `-${Math.round(this.yLimit * 100)}%`, false));
    } else {
      for (let i = 1; i < this.yLines.length - 2; i++) {
        this.yLines[i].label = '';
      }
    }
    this.quantityYLimit = 0;
    for (const qty of this.quantities) {
      this.quantityYLimit = Math.max(qty * (1 + this.quantityYPadding), this.quantityYLimit);
    }
    if (this.quantityYLimit === 0) {
      this.quantityYLine = null;
      this.quantityYLimit = 1;
    } else {
      let y = 0;
      let lastY = null;
      let addValue = 0.1;
      let count = 0;
      while (y < this.quantityYLimit * 0.95) {
        lastY = y;
        y = addValue * ((count % 9) + 1);
        if (lastY <= this.quantityYLimit / 2 && this.quantityYLimit / 2 <= y) {
          if (Math.abs(this.quantityYLimit / 2 - lastY) < Math.abs(this.quantityYLimit / 2 - y)) {
            y = lastY;
          }
          break;
        }
        count++;
        if (count % 9 === 0) {
          addValue *= 10;
        }
      }
      if (y) {
        this.quantityYLine = new YTick(y, y < 1 ? y.toFixed(1) : this.fastDecimal.transform(y));
      } else {
        this.quantityYLine = null;
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.miniChartUpdatedSubscription) {
      this.miniChartUpdatedSubscription.unsubscribe();
      this.miniChartUpdatedSubscription = null;
    }
  }
}
