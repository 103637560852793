import { Component, ElementRef, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StockListService } from '../../stock-list.service';
import { PegState, ResizeService } from '@argentumcode/brisk-common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GlHeaderItem, GlOnHide, GlOnResize, GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { StockDetailsService } from '../../stock-details.service';
import { Container } from 'golden-layout';
import { ItaViewHeaderComponent } from '../../headers/ita-view-header/ita-view-header.component';
import { ItaPortfolioComponent } from 'fita3/src/app/portfolio/ita-portfolio/ita-portfolio.component';
import { CellDoubleClickService } from '../../cell-double-click.service';
import { ITA_GRID_CONTROLLER_TOKEN, ItaGridState } from './interface';

const itaWidth = 340;

@Component({
  selector: 'app-fita4-ita-grid-panel',
  templateUrl: './ita-grid-panel.component.html',
  styleUrls: ['./ita-grid-panel.component.scss'],
  providers: [
    {
      provide: ITA_GRID_CONTROLLER_TOKEN,
      useExisting: forwardRef(() => ItaGridPanelComponent),
    },
  ],
})
export class ItaGridPanelComponent implements OnInit, OnDestroy, GlOnResize, GlOnShow, GlOnHide, GlHeaderItem {
  @ViewChild('itaPortfolio')
  itaPortfolio: ItaPortfolioComponent;

  headerComponent = ItaViewHeaderComponent;
  state: ItaGridState;
  private resizeSubscription: Subscription;
  get collection() {
    return this.stockListService.stockCollectionView;
  }
  visible = false;
  itaColumnCount = 0;
  stateSubj: BehaviorSubject<ItaGridState>;
  state$: Observable<ItaGridState>;
  constructor(
    private stockListService: StockListService,
    private stockDetailsService: StockDetailsService,
    private element: ElementRef,
    private resize: ResizeService,
    private cellDoubleClick: CellDoubleClickService,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
    this.itaColumnCount = 1;
    const state: Partial<ItaGridState> = this.container.getState() || {};
    this.state = {
      pegState: state.pegState === undefined || state.pegState === PegState.TemporaryDisabled ? PegState.Enabled : state.pegState,
      rows: state.rows || 18,
    };
    this.stateSubj = new BehaviorSubject<ItaGridState>(this.state);
    this.state$ = this.stateSubj.asObservable();
  }

  ngOnInit(): void {
    this.visible = !this.container.isHidden;
    this.resize.dispatchResize();
  }

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
    setTimeout(() => {
      this.resize.dispatchResize();
    });
    this.visible = true;
  }

  glOnHide() {
    this.visible = false;
  }

  onResize() {
    const width = this.element.nativeElement.offsetWidth;
    const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;
    this.itaColumnCount = Math.max(1, Math.floor(normalizedWidth / itaWidth));
  }

  onStockSelected(issueCode: number) {
    this.stockDetailsService.changeIssueCodeRequest(Number(issueCode));
  }

  updateRows(rows: number) {
    rows = Math.round(rows);
    if (rows === undefined || rows === null || isNaN(rows)) {
      rows = 4;
    }
    if (rows < 1) {
      rows = 1;
    }
    if (rows > 99) {
      rows = 99;
    }
    this.updateState({ ...this.state, rows });
  }

  updatePegState(pegState: PegState) {
    this.updateState({ ...this.state, pegState });
  }

  updateState(state: ItaGridState) {
    this.state = state;
    this.stateSubj.next(this.state);
    this.container.setState(this.state);
  }

  resetAll() {
    this.itaPortfolio?.resetAll();
  }

  onCellDblClicked() {
    console.log('cell double click');
    this.cellDoubleClick.doubleClicked();
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
    this.stateSubj.complete();
  }
}
