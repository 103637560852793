<div class="p-stock-detail--main">
  <div class="p-stock-detail--header">
    <brisk-header
      id="issueHeader"
      (stockSelected)="onStockSelected($event)"
      [stockWrapper]="stockOperator"
      [showTimestamp]="false"
      (invalidInput)="onInvalidInput($event)"
      variant="fita4"
      [orderSummary]="orderSummary$ | async"
    >
    </brisk-header>
  </div>
  <div class="p-stock-detail--content">
    <golden-layout-root appFita4GoldenLayoutAutoResize #root [layout]="layout$" (stateChanged)="onStateChange(root)"></golden-layout-root>
  </div>
</div>
