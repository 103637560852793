import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import {
  DragCancelEvent,
  DragCancelReason,
  DragEndEvent,
  DragEventCommon,
  DragMoveEvent,
  DragOrigin,
  DragSource,
  DragStartEvent,
  DropHandler,
  DroppableCheckEvent,
} from './types';

export type DragType = 'touch' | 'mouse';

interface DragState {
  type: DragType;
  source: DragSource;
  subscription: Subscription;
  originalCursor: string;

  started: boolean;
  base: DragOrigin;
  dropTarget: HTMLElement | null;
}

@Injectable({
  providedIn: 'root',
})
export class DragDropManagerService {
  private _document: Document;

  private _event: Subscription | null = null;

  private _state: Readonly<DragState> | null = null;

  private _dropHandlers = new WeakMap<HTMLElement, DropHandler>();

  constructor(@Inject(DOCUMENT) _document: any, private _zone: NgZone) {
    this._document = _document;
  }

  prepareDrag(source: DragSource, event: MouseEvent) {
    if (event.type.startsWith('mouse')) {
      if (this._state) {
        this.cancelDrag('OtherDrag');
      }
      const sub = new Subscription();
      this._zone.runOutsideAngular(() => {
        sub.add(
          fromEvent(this._document, 'mousemove').subscribe((e: MouseEvent) => {
            this.onMouseMove(e);
          })
        );
        sub.add(
          fromEvent(this._document, 'mouseup').subscribe((e: MouseEvent) => {
            this.onMouseUp(e);
          })
        );
      });
      this._state = {
        source,
        subscription: sub,
        type: 'mouse',
        base: {
          pageX: event.pageX,
          pageY: event.pageY,
        },
        started: false,
        originalCursor: this._document.body.style.cursor,
        dropTarget: null,
      };
    }
    event.preventDefault();
  }

  private startDrag(ev: DragEventCommon) {
    if (!this._state) {
      throw new Error('not dragging');
    }
    this._state.source.onDragStart({
      event: 'DragStart',
      ...ev,
    });
    this._state = {
      ...this._state,
      started: true,
    };
    console.log('startDrag');
  }

  cancelDrag(reason: DragCancelReason) {
    if (!this._state) {
      throw new Error('not dragging');
    }
    if (this._state.started) {
      console.log('cancel');
      const cancelEvent = {
        event: 'DragCancel' as const,
        origin: { ...this._state.base },
        reason,
      };
      this._state.source.onDragCancel(cancelEvent);
      this.cleanupState(cancelEvent);
    } else {
      this.cleanupState();
    }
  }

  endDrag(event: MouseEvent) {
    if (!this._state) {
      throw new Error('not dragging');
    }
    if (this._state.started) {
      const ev = this.createEvent(event);
      const endEvent = {
        ...ev,
        event: 'DragEnd' as const,
      };
      this._endDropping(endEvent);
      this._state.source.onDragEnd(endEvent);
      console.log('dragEnd');
      this.cleanupState(endEvent);
    } else {
      this.cleanupState();
    }
  }

  private cleanupState(ev?: DragEndEvent | DragCancelEvent) {
    if (this._state) {
      this._state.subscription.unsubscribe();
      if (this._state.originalCursor) {
        this._document.body.style.cursor = this._state.originalCursor;
      }
      if (this._state.dropTarget) {
        if (this._dropHandlers.has(this._state.dropTarget) && this._dropHandlers.get(this._state.dropTarget).onDragLeave) {
          this._dropHandlers.get(this._state.dropTarget).onDragLeave({
            originalEvent: undefined,
            dragEvent: ev,
            event: 'DragLeave',
          });
        }
      }
      this._state = null;
    }
  }

  onMouseMove(event: MouseEvent) {
    if (this._state) {
      const ev = this.createEvent(event);
      if (!this._state.started) {
        if (Math.abs(ev.dx) < 1 && Math.abs(ev.dy) < 1) {
          return;
        }
        this.startDrag(ev);
      }
      this._state.source.onDragMove({
        event: 'DragMove',
        ...ev,
      });
      console.log('dragMove');
    }
  }

  onMouseUp(event: MouseEvent) {
    console.log('drag-drop-mouseup');
    if (this._state) {
      this.endDrag(event);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private createEvent(event: MouseEvent): DragEventCommon {
    if (!this._state) {
      throw new Error('not dragging');
    }
    const dx = event.pageX - this._state.base.pageX;
    const dy = event.pageY - this._state.base.pageY;
    return {
      originalEvent: event,
      origin: { ...this._state.base },
      pageX: event.pageX,
      pageY: event.pageY,
      clientX: event.clientX,
      clientY: event.clientY,
      dx,
      dy,
    };
  }

  setDropHandler(element: HTMLElement, handler: DropHandler): void {
    this._dropHandlers.set(element, handler);
  }

  removeDropHandler(element: HTMLElement): void {
    this._dropHandlers.delete(element);
  }

  _updateDropping(e: DragMoveEvent) {
    let element = this._document.elementFromPoint(e.clientX, e.clientY) as HTMLElement;
    let targetElement: HTMLElement = null;
    while (element && element !== this._document.body) {
      if (this._dropHandlers.has(element)) {
        const dropHandler = this._dropHandlers.get(element);
        const ev: DroppableCheckEvent = {
          dragEvent: e,
          droppable: true,
          event: 'DroppableCheckEvent',
          originalEvent: e.originalEvent,
        };
        if (dropHandler.onDroppableCheck) {
          dropHandler.onDroppableCheck(ev);
        }
        if (ev.droppable) {
          targetElement = element;
          break;
        }
      }
      element = element.parentElement;
    }
    this._updateDraggingState(targetElement, e);
  }

  _endDropping(e: DragEndEvent) {
    if (this._state.dropTarget) {
      if (this._dropHandlers.has(this._state.dropTarget) && this._dropHandlers.get(this._state.dropTarget).onDrop) {
        this._dropHandlers.get(this._state.dropTarget).onDrop({
          dragEvent: e,
          event: 'Drop',
          originalEvent: e.originalEvent,
        });
      }
      this._state = {
        ...this._state,
        dropTarget: null,
      };
    }
  }

  private _updateDraggingState(targetElement: HTMLElement | null, e: DragMoveEvent | DragStartEvent | DragEndEvent) {
    if (this._state.dropTarget !== targetElement) {
      if (this._dropHandlers.has(this._state.dropTarget) && this._dropHandlers.get(this._state.dropTarget).onDragLeave) {
        this._dropHandlers.get(this._state.dropTarget).onDragLeave({
          dragEvent: e,
          event: 'DragLeave',
          originalEvent: e.originalEvent,
        });
      }
      if (this._dropHandlers.has(targetElement) && this._dropHandlers.get(targetElement).onDragEnter) {
        this._dropHandlers.get(targetElement).onDragEnter({
          dragEvent: e,
          event: 'DragEnter',
          originalEvent: e.originalEvent,
        });
      }
      this._state = {
        ...this._state,
        dropTarget: targetElement,
      };
    } else {
      if (this._dropHandlers.has(this._state.dropTarget) && this._dropHandlers.get(this._state.dropTarget).onDragOver) {
        this._dropHandlers.get(this._state.dropTarget).onDragOver({
          dragEvent: e,
          event: 'DragOver',
          originalEvent: e.originalEvent,
        });
      }
    }
  }
}
