import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CanvasGridComponent,
  CellMouseEvent,
  CellRenderer,
  CellRenderEvent,
  DrawContext,
  ScrollChangedEvent,
} from '../../canvas-grid/canvas-grid/canvas-grid.component';
import { FlexVersion, ItaRowDelta, ItaRowItem, QRRowType, QuoteFlag, Side, StockView } from '../../flex';
import { ColorConverterService } from '../../brisk-common/color-converter.service';
import { PriceChangesPipe } from '../../brisk-common/price-changes.pipe';
import { StockWrapper } from '../../brisk-core/stock-wrapper';
import { interval, Subject, Subscription, timer, Observable, BehaviorSubject } from 'rxjs';
import { CancelEventArgs } from '@grapecity/wijmo';
import { FastDecimalPipe } from '../../brisk-common/fast-decimal.pipe';
import { TimeProvider } from '../../brisk-core/time-provider';
import { TimestampFormatPipe } from '../../brisk-common/timestamp-format.pipe';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { parse as parseUA } from 'woothee';
import { ThemeService } from '../../theme/theme.service';
import { throttle } from 'rxjs/operators';
import { FirstTutorialComponent } from '../../brisk-common/first-tutorial/first-tutorial.component';
import { DOCUMENT } from '@angular/common';
import { BriskDialogService } from '../../dialog/dialog.service';
import { UserAgentService } from '../../brisk-browser/user-agent.service';

enum CloseRangeType {
  // 通常の更新値幅(前場引け)
  Normal,
  // 通常の更新値幅(後場引け)
  Double,
}

// ペグ機能の状態を表す
export enum PegState {
  // ユーザーが意図的に無効化した
  Disabled,
  // スクロール等の操作によって一時的に無効化された
  TemporaryDisabled,
  // 有効
  Enabled,
  // 発注操作によって一時的に無効化された
  TemporaryDisabledByOrder,
}

/// 板上での売買区分
export enum ItaSide {
  /// 買引
  BidClose,
  /// 買
  Bid,
  /// 売
  Ask,
  /// 売引
  AskClose,
}

/// 一つのセルに対する板注文の表示情報
export class OrderItaRowSide {
  // 板に登録された仮order
  registeredOrder = 0;
  // 訂正: 移動先のオーダー(Drag中)
  amendingOrder = 0;
  // 訂正：移動前のオーダーかどうか
  isAmendingOrderSrc = false;
  // 実際に発注済のオーダ
  order = 0;
  // 個数 * price10
  normalizedOrder = 0;
  // 処理中のorder
  progressingOrder = 0;
  // 取消(訂正でなくなるものも含む)処理中のorder
  cancelingOrder = 0;
  // prefix
  registeredOrderPrefix = '';
}

/// 1つの行に対する板注文の表示情報
export interface ItaOrderRow {
  bid: OrderItaRowSide;
  ask: OrderItaRowSide;
  bidClose: OrderItaRowSide;
  askClose: OrderItaRowSide;
}

/// ItaOrderRowから特定のSideを取得する
function getItaRowSide(row: ItaOrderRow, side: ItaSide): OrderItaRowSide {
  switch (side) {
    case ItaSide.Ask:
      return row.ask;
    case ItaSide.AskClose:
      return row.askClose;
    case ItaSide.Bid:
      return row.bid;
    case ItaSide.BidClose:
      return row.bidClose;
  }
  throw new Error(`Unexpected ItarowSide ${side}`);
}

function isBuySide(side: ItaSide) {
  return side === ItaSide.Bid || side === ItaSide.BidClose;
}

export enum ItaPriceType {
  OVER = 'OVER',
  UNDER = 'UNDER',
  MARKET = 'MARKET',
}
export type Price10 = number;
export type PriceOrMarket = Price10 | ItaPriceType.MARKET;
export type ItaPrice = PriceOrMarket | ItaPriceType.OVER | ItaPriceType.UNDER;
export type Share = number;

function getEstimatePrice10(stockView: StockView, price10: PriceOrMarket, side: ItaSide): number {
  if (price10 === ItaPriceType.MARKET) {
    if (isBuySide(side)) {
      return stockView.master.limitUp10;
    } else {
      return stockView.master.limitDown10;
    }
  } else {
    return price10;
  }
}

/// 板に登録されている注文
export interface ItaRegisteredOrder {
  price10: PriceOrMarket;
  side: ItaSide;
  quantity: number;
  orderInfo?: any;
  issueCode: number;
  fromCtrl: boolean;
}

export interface ItaOrderService {
  state$: Observable<ItaOrderState>;
  requiredUpdateIta$?: Observable<void>;

  clearAmending(stockOperator: StockWrapper);

  get(stockOperator: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET'): ItaOrderRow;

  clearRegisteredOrder(stockOperator: StockWrapper);

  startAmending(stockOperator: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET', side: ItaSide);

  tryCancel(stockWrapper: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET', type: ItaSide);

  isAmending(stockOperator: StockWrapper);

  tryRegisterOrderByClick(stockWrapper: StockWrapper, p10: Price10 | 'MARKET', lotSize: Share, type: ItaSide, ctrl: boolean);

  price10Click(price10: number | 'MARKET');

  hasRegisteredOrder(stockOperator: StockWrapper);

  trySendOrderByClick(stockWrapper: StockWrapper, p10: Price10 | 'MARKET', lotSize: Share, type: ItaSide);

  runAmending(stockWrapper: StockWrapper, p10: Price10, type: ItaSide);

  updateAmending(stockOperator: StockWrapper, p10: Price10, side: ItaSide);

  invisibleAmending(stockOperator: StockWrapper);
}
export const ItaOrderServiceToken = new InjectionToken<ItaOrderService>('ItaOrderService');

export const ItaOrderStateType = {
  // 板発注無効
  Disabled: 'Disabled',

  // 板発注可能
  Normal: 'Normal',

  // 板登録済み
  ItaRegistered: 'ItaRegistered',

  // 訂正中
  Amending: 'Amending',

  // 処理中
  Processing: 'Processing',
} as const;

export type ItaOrderStateType = typeof ItaOrderStateType[keyof typeof ItaOrderStateType];

export interface DisabledState {
  type: 'Disabled';
}

export interface NormalState {
  type: 'Normal';
}

export interface ItaRegisteredOrderState {
  type: 'ItaRegistered';
  order: ItaRegisteredOrder;
}

export interface AmendingState {
  type: 'Amending';
  sourcePrice: number | 'MARKET' | 'OVER' | 'UNDER';
  sourceSide: ItaSide;
  destPrice: PriceOrMarket | null;
  destSide: ItaSide | null;
  quantity: number;
  issueCode: number;
}

export interface ProcessingState {
  type: 'Processing';
}

export type ItaOrderState = NormalState | DisabledState | ItaRegisteredOrderState | AmendingState | ProcessingState;

class ItaOrderMockService implements ItaOrderService {
  private state: ItaOrderState = {
    type: ItaOrderStateType.Normal,
  };

  private stateBehavior = new BehaviorSubject(this.state);

  state$ = this.stateBehavior.asObservable();

  private timerSubscription: Subscription | null = null;
  private orders: Array<ItaRegisteredOrder> = [];

  private updateState(state: ItaOrderState) {
    this.state = state;
    this.stateBehavior.next(this.state);
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    if (state.type === ItaOrderStateType.ItaRegistered) {
      this.timerSubscription = timer(3000).subscribe(() => {
        if (this.state.type === ItaOrderStateType.ItaRegistered) {
          this.updateState({
            type: ItaOrderStateType.Normal,
          });
        }
      });
    }
  }

  clearAmending(stockOperator: StockWrapper) {
    if (this.state.type === ItaOrderStateType.Amending) {
      this.updateState({
        type: ItaOrderStateType.Normal,
      });
    }
  }

  clearRegisteredOrder(stockOperator: StockWrapper) {
    if (this.state.type === ItaOrderStateType.ItaRegistered) {
      this.updateState({
        type: ItaOrderStateType.Normal,
      });
    }
  }

  get(stockOperator: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET'): ItaOrderRow {
    const view = stockOperator.current;
    const ret: ItaOrderRow = {
      ask: new OrderItaRowSide(),
      askClose: new OrderItaRowSide(),
      bid: new OrderItaRowSide(),
      bidClose: new OrderItaRowSide(),
    };

    for (const order of this.orders) {
      let match = false;
      if (p10 === 'OVER') {
      } else if (p10 === 'UNDER') {
      } else if (p10 === 'MARKET') {
        match = order.price10 === ItaPriceType.MARKET;
      } else {
        match = order.price10 === p10;
      }
      if (match) {
        const orderPrice10 = getEstimatePrice10(stockOperator.current, order.price10, order.side);
        switch (order.side) {
          case ItaSide.BidClose: {
            ret.bidClose.order += order.quantity;
            ret.bidClose.normalizedOrder += orderPrice10 * order.quantity;
            break;
          }
          case ItaSide.Bid: {
            ret.bid.order += order.quantity;
            ret.bid.normalizedOrder += orderPrice10 * order.quantity;
            break;
          }
          case ItaSide.AskClose: {
            ret.askClose.order += order.quantity;
            ret.askClose.normalizedOrder += orderPrice10 * order.quantity;
            break;
          }
          case ItaSide.Ask: {
            ret.ask.order += order.quantity;
            ret.ask.normalizedOrder += orderPrice10 * order.quantity;
            break;
          }
        }
      }
    }

    if (this.state.type === ItaOrderStateType.ItaRegistered) {
      if (p10 === 'OVER') {
      } else if (p10 === 'UNDER') {
      } else {
        if (this.state.order.price10 === p10) {
          switch (this.state.order.side) {
            case ItaSide.Ask: {
              ret.ask.registeredOrder = this.state.order.quantity;
              break;
            }
            case ItaSide.AskClose: {
              ret.askClose.registeredOrder = this.state.order.quantity;
              break;
            }
            case ItaSide.Bid: {
              ret.bid.registeredOrder = this.state.order.quantity;
              break;
            }
            case ItaSide.BidClose: {
              ret.bidClose.registeredOrder = this.state.order.quantity;
              break;
            }
          }
        }
      }
    }

    if (this.state.type === ItaOrderStateType.Amending) {
      if (this.state.sourcePrice === p10) {
        switch (this.state.sourceSide) {
          case ItaSide.Ask: {
            ret.ask.isAmendingOrderSrc = true;
            break;
          }
          case ItaSide.AskClose: {
            ret.askClose.isAmendingOrderSrc = true;
            break;
          }
          case ItaSide.Bid: {
            ret.bid.isAmendingOrderSrc = true;
            break;
          }
          case ItaSide.BidClose: {
            ret.bidClose.isAmendingOrderSrc = true;
            break;
          }
        }
      }
      if (this.state.destPrice !== null && this.state.destSide !== null && this.state.destPrice === p10) {
        switch (this.state.destSide) {
          case ItaSide.Ask: {
            ret.ask.amendingOrder = this.state.quantity;
            break;
          }
          case ItaSide.AskClose: {
            ret.askClose.amendingOrder = this.state.quantity;
            break;
          }
          case ItaSide.Bid: {
            ret.bid.amendingOrder = this.state.quantity;
            break;
          }
          case ItaSide.BidClose: {
            ret.bidClose.amendingOrder = this.state.quantity;
            break;
          }
        }
      }
    }
    return ret;
  }

  hasRegisteredOrder(stockOperator: StockWrapper) {
    return this.state.type === ItaOrderStateType.ItaRegistered;
  }

  invisibleAmending(stockOperator: StockWrapper) {
    if (this.state.type === ItaOrderStateType.Amending) {
      this.updateState({
        ...this.state,
        destPrice: null,
        destSide: null,
      });
    }
  }

  isAmending(stockOperator: StockWrapper) {
    return this.state.type === ItaOrderStateType.Amending;
  }

  price10Click(price10: number | 'MARKET') {}

  runAmending(stockWrapper: StockWrapper, p10: Price10, type: ItaSide) {
    if (this.state.type === ItaOrderStateType.Amending) {
      if (this.state.destPrice === null || this.state.destSide === null) {
        this.clearAmending(stockWrapper);
        return;
      }
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        if (order.price10 === this.state.sourcePrice && order.side === this.state.sourceSide) {
          order.price10 = this.state.destPrice;
          order.side = this.state.destSide;
        }
      }
    }
  }

  startAmending(stockOperator: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET', side: ItaSide) {
    if (this.state.type !== ItaOrderStateType.Normal) {
      return;
    }
    let qty = 0;
    const amendOrders: Array<ItaRegisteredOrder> = [];
    for (const order of this.orders) {
      if (order.side !== side) {
        continue;
      }
      let match = false;
      if (p10 === 'OVER') {
      } else if (p10 === 'UNDER') {
      } else if (p10 === 'MARKET') {
      } else {
        match = order.price10 === p10;
      }
      if (match) {
        qty += order.quantity;
        amendOrders.push(order);
      }
    }
    if (amendOrders.length > 0) {
      this.updateState({
        type: ItaOrderStateType.Amending,
        sourcePrice: p10,
        sourceSide: side,
        quantity: qty,
        destSide: null,
        destPrice: null,
        issueCode: stockOperator.current.master.issueCode,
      });
    }
  }

  tryCancel(stockWrapper: StockWrapper, p10: Price10 | 'OVER' | 'UNDER' | 'MARKET', type: ItaSide) {
    if (this.state.type === ItaOrderStateType.Normal) {
      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        if (order.price10 === p10 && order.side === type) {
          this.orders.splice(i, 1);
          i--;
        }
      }
    }
  }

  tryRegisterOrderByClick(stockWrapper: StockWrapper, p10: Price10 | 'MARKET', lotSize: Share, type: ItaSide, ctrl: boolean) {
    if (!(this.state.type === ItaOrderStateType.ItaRegistered || this.state.type === ItaOrderStateType.Normal)) {
      return;
    }
    if (p10 === 'MARKET') {
      // TODO: Support it
      return;
    }
    if (ctrl) {
      let qty = 0;
      if (!isBuySide(type)) {
        for (const row of stockWrapper.current.rows) {
          if (row.price10 >= p10) {
            qty += row.bid.quantity;
            if (type === ItaSide.AskClose) {
              qty += row.bidClose.quantity;
            }
          }
        }
        qty += stockWrapper.current.over.bid.quantity;
        qty += stockWrapper.current.market.bid.quantity;
        if (type === ItaSide.AskClose) {
          qty += stockWrapper.current.over.bidClose.quantity;
          qty += stockWrapper.current.market.bidClose.quantity;
        }
      } else {
        for (const row of stockWrapper.current.rows) {
          if (row.price10 <= p10) {
            qty += row.ask.quantity;
            if (type === ItaSide.BidClose) {
              qty += row.askClose.quantity;
            }
          }
        }
        qty += stockWrapper.current.under.ask.quantity;
        qty += stockWrapper.current.market.ask.quantity;
        if (type === ItaSide.BidClose) {
          qty += stockWrapper.current.under.askClose.quantity;
          qty += stockWrapper.current.market.askClose.quantity;
        }
      }
      this.updateState({
        type: ItaOrderStateType.ItaRegistered,
        order: {
          price10: p10,
          quantity: qty,
          side: type,
          issueCode: stockWrapper.current.master.issueCode,
          fromCtrl: true,
        },
      });
    } else {
      if (
        this.state.type === ItaOrderStateType.ItaRegistered &&
        this.state.order.side === type &&
        this.state.order.price10 === p10 &&
        this.state.order.quantity === lotSize
      ) {
        this.updateState({
          type: ItaOrderStateType.ItaRegistered,
          order: {
            price10: p10,
            quantity: lotSize * 10,
            side: type,
            issueCode: stockWrapper.current.master.issueCode,
            fromCtrl: false,
          },
        });
      } else {
        this.updateState({
          type: ItaOrderStateType.ItaRegistered,
          order: {
            price10: p10,
            quantity: lotSize,
            side: type,
            issueCode: stockWrapper.current.master.issueCode,
            fromCtrl: false,
          },
        });
      }
    }
  }

  trySendOrderByClick(stockWrapper: StockWrapper, p10: Price10 | 'MARKET', lotSize: Share, type: ItaSide) {
    if (this.state.type === ItaOrderStateType.ItaRegistered) {
      if (this.state.order.side === type) {
        // Ita Register
        this.orders.push(this.state.order);
        this.clearRegisteredOrder(stockWrapper);
      }
    }
  }

  updateAmending(stockOperator: StockWrapper, p10: Price10, side: ItaSide) {
    if (this.state.type === ItaOrderStateType.Amending) {
      if (this.state.sourcePrice === p10 && this.state.sourceSide === side) {
        this.invisibleAmending(stockOperator);
        return;
      }
      if (isBuySide(side) !== isBuySide(this.state.sourceSide)) {
        this.invisibleAmending(stockOperator);
        return;
      }
      this.updateState({
        ...this.state,
        destPrice: p10,
        destSide: side,
      });
    }
  }
}

const enum ItaPriceState {
  MiddleQuote = 1 << 16,
  MiddleSQ = 1 << 17,
  MiddleCEQ = 1 << 18,
}

function preventDefault(e: Event) {
  e.preventDefault();
}

@Component({
  selector: 'brisk-ita',
  templateUrl: 'ita.component.html',
  styleUrls: ['./ita.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItaComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('grid', { static: true })
  public grid: CanvasGridComponent;
  @ViewChild('themeElement', { static: true })
  private themeElement: ElementRef;

  @ViewChild('scrollTutorial')
  private scrollTutorial: FirstTutorialComponent;
  @ViewChild('dblClickTutorial')
  private dblClickTutorial: FirstTutorialComponent;

  private hoverOver = false;
  private hoverUnder = false;

  private showRenewalLine = false;
  private closeOutOfRange = false;
  private renewalUp10: number;
  private renewalDown10: number;
  /// 引け時の更新値幅
  private closeUp10: number;
  /// 引け時の更新値幅
  private closeDown10: number;

  private closeRangeType: CloseRangeType;
  private initialized = false;

  public cursorStyle;

  private themeChangedSubscription: Subscription;
  private themeColors: { [key: string]: string } = {};

  @Input()
  public itaWidth: number = undefined;

  @Input()
  public miniMode = false;

  /**
   * 固定行数
   */
  @Input()
  public fixedRows: number = null;

  @Input()
  get stockOperator(): StockWrapper {
    return this._stockOperator;
  }

  set stockOperator(value: StockWrapper) {
    if (this._stockOperator !== value) {
      if (this.refreshSubscription !== null) {
        this.refreshSubscription.unsubscribe();
        this.refreshSubscription = null;
      }
      this._stockOperator = value;
      this.grid.updateSize(true);
      if (value && this._stockOperator.updated) {
        this.setPegStatus(null);
        this.precalc();
        this.grid.draw(true);
        this.refreshSubscription = this._stockOperator.updated.subscribe(() => {
          this.precalc();

          if (this.pegCheck()) {
            this.pegSubject.next();
          }
          if (this.grid) {
            this.grid.draw(false);
          }
        });
        this._switchingStockOperator = true;
      } else {
        this.grid.draw(true);
      }
    }
  }

  private _stockOperator: StockWrapper;

  private refreshSubscription: Subscription = null;

  public get view(): StockView {
    return this._stockOperator && this._stockOperator.current;
  }

  @Input()
  public normalized = false;

  @Output()
  public normalizedChange = new EventEmitter<boolean>();

  @Input()
  public timeProvider: TimeProvider;

  @Input()
  public forceSeparateComma = false;

  @Input()
  public showClose = true;

  @Input()
  public showOrder = true;

  @Input()
  public showMyOrder = false;

  @Input()
  public showTotal = true;

  @Input()
  public showVolume = true;

  @Input()
  public showVolumeChartOnly = false;

  private gridHeight: number = null;

  @Output()
  public gridHeightChanged = new EventEmitter<number>();

  @Input()
  public alignRight = false;

  @Input()
  public fillStopPrice = false;

  @Input()
  public overUnderButton = false;

  @Input()
  public showScrollbar = false;

  @Input()
  public peg: PegState = PegState.Disabled;

  @Output()
  public pegChange = new EventEmitter<PegState>();

  // 板の値段がダブルクリックされた時のイベント(Tutorial用)
  @Output()
  public priceDblClick = new EventEmitter<{}>();

  // ダブルクリックで自動板中心値チュートリアルが完了した際のイベント
  // priceDblClickは自動板中心値チュートリアルが完了しなくとも発火しうるので区別している
  // (スクロールに関するチュートリアル表示中に矢印キーで板をスクロールしてからダブルクリックなど)
  @Output()
  public priceDblClickTutorialComplete = new EventEmitter<{}>();

  // 板のスクロールが発生した時のイベント (Tutorial用)
  @Output()
  public itaScroll = new EventEmitter<{}>();

  @Input()
  public enableScrollTutorial = false;

  @Input()
  public enableDblClickTutorial = false;

  // 板の値段がクリックされた時のイベント
  @Output()
  public priceClick = new EventEmitter<number | 'OVER' | 'UNDER' | 'MARKET'>();

  @Input()
  shown = true;

  public isSafari = false;

  private pegSubject = new Subject<any>();
  private pegSubscription: Subscription = null;

  private pegStatus: Side = null;
  private pegStatusSubscription: Subscription = null;

  private afterViewInitSubscription: Subscription = null;
  private fixItaSubscription: Subscription = null;

  private _orderDisabled = false;
  private _document?: Document;

  // 銘柄の切り替え中かどうか
  private _switchingStockOperator: boolean;

  public constructor(
    public colorConverter: ColorConverterService,
    private priceChanges: PriceChangesPipe,
    private fastDecimal: FastDecimalPipe,
    private dialog: BriskDialogService,
    private elem: ElementRef,
    private timestampPipe: TimestampFormatPipe,
    private theme: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    private userAgentService: UserAgentService,
    @Inject(DOCUMENT) _document: any,
    @Inject(ItaOrderServiceToken) @Optional() private fixIta?: ItaOrderService
  ) {
    if (_document) {
      this._document = _document;
    }
    const ua = parseUA(window.navigator.userAgent);
    this.isSafari = ua.name === 'Safari' || ua.os === 'iPad' || ua.os === 'iPhone';

    this.themeChangedSubscription = theme.theme$.subscribe(() => {
      this.updateTheme();
    });

    this.pegSubscription = this.pegSubject
      .asObservable()
      .pipe(throttle(() => interval(1000), { leading: true, trailing: true }))
      .subscribe(() => {
        this.doPeg();
      });
    if (!this.fixIta) {
      this.fixIta = new ItaOrderMockService();
    }
    if (this.fixIta) {
      this.fixItaSubscription = this.fixIta.state$.subscribe((state) => {
        this._orderDisabled = state.type === ItaOrderStateType.Disabled;
        if (!this.stockOperator) {
          return;
        }
        if (state.type === ItaOrderStateType.ItaRegistered || state.type === ItaOrderStateType.Amending) {
          if (state.type === ItaOrderStateType.Amending && state.issueCode === this.stockOperator.current.master.issueCode) {
            this.stopPegByOrder();
          } else if (
            state.type === ItaOrderStateType.ItaRegistered &&
            state.order.issueCode === this.stockOperator.current.master.issueCode
          ) {
            this.stopPegByOrder();
            if (state.order.price10 !== ItaPriceType.MARKET) {
              if (state.order.price10 < this.view.rows[0].price10) {
                this.moveToPrice(state.order.price10);
              } else if (state.order.price10 > this.view.rows[this.view.rows.length - 1].price10) {
                this.moveToPrice(state.order.price10);
              }
            }
          }
        } else if (this.peg === PegState.TemporaryDisabledByOrder) {
          this.peg = PegState.Enabled;
          this.pegChange.emit(this.peg);
        }
        this.stockOperator.update = true;
      });
      if (this.fixIta.requiredUpdateIta$) {
        this.fixItaSubscription.add(
          this.fixIta.requiredUpdateIta$.subscribe(() => {
            this.precalc();

            if (this.grid) {
              this.grid.draw(false);
            }
          })
        );
      }
    }
  }

  ngOnInit(): void {
    if (this.fixedRows) {
      this.grid.dummyRows = this.fixedRows + 3;
    }
    this.initialized = true;
    this.updateTheme();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (this.stockOperator) {
      this.stockOperator.update = true;
    }
    if (this.initialized) {
      if (this.fixedRows && this.grid.dummyRows !== this.fixedRows + 3) {
        this.grid.dummyRows = this.fixedRows + 3;
        this.updateSize();
      }
      this.updateSize();
      // wait updates of children components
      // TODO: replace with ViewChildren query.
      setTimeout(() => {
        this.updateSize();
      });
    }

    if (this.peg !== PegState.Enabled && this.pegStatus !== null) {
      this.setPegStatus(null);
    }

    if ('enableScrollTutorial' in simpleChanges || 'shown' in simpleChanges) {
      setTimeout(() => {
        if (this.scrollTutorial) {
          this.scrollTutorial.show();
        }
      });
    }
  }

  public onMouseWheel(event: WheelEvent) {
    if (!this.view) {
      return;
    }
    if (this.scrollTutorial) {
      this.scrollTutorial.close();
    }
    if (this.fixIta) {
      this.fixIta.clearAmending(this.stockOperator);
      this.fixIta.clearRegisteredOrder(this.stockOperator);
    }
    if (this.showScrollbar) {
      event.stopPropagation();
      if (this.miniMode) {
        if (this.view.master.tick.price10ToIndex(this.view.master.limitDown10) >= this.view.itaRowPriceIndex) {
          if (event.deltaY > 0) {
            event.preventDefault();
          }
        }
        if (this.view.master.tick.price10ToIndex(this.view.master.limitUp10) <= this.view.itaRowPriceIndex + this.view.itaRowCount - 1) {
          if (event.deltaY < 0) {
            event.preventDefault();
          }
        }
      }
      return;
    }
    if (event.deltaY > 0) {
      this.view.itaRowPriceIndex -= 1;
    } else {
      this.view.itaRowPriceIndex += 1;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  private precalc() {
    if (this.stockOperator && this.stockOperator.current) {
      const auctionReferencePrice10 = this.view.auctionPrice10;
      let up10 = this.view.master.tick.getUpRenewalPrice10(auctionReferencePrice10);
      let down10 = this.view.master.tick.getDownRenewalPrice10(auctionReferencePrice10);
      if (this.view.renewalUpRange10) {
        up10 = this.view.master.tick.roundUpPrice(this.view.renewalUpRange10 + auctionReferencePrice10);
      }
      if (this.view.renewalDownRange10) {
        down10 = auctionReferencePrice10 - this.view.renewalDownRange10;
      }

      if (this.view.version >= FlexVersion.Version16 && this.view.timestamp > (11 * 60 + 30) * 60 * 1000000) {
        // Version16以上で、前場終了後ならば引け値の範囲を2倍にする
        this.closeUp10 = this.view.master.tick.getUpDoubleRenewalPrice10(auctionReferencePrice10);
        this.closeDown10 = this.view.master.tick.getDownDoubleRenewalPrice10(auctionReferencePrice10);
        this.closeRangeType = CloseRangeType.Double;
      } else {
        this.closeDown10 = down10;
        this.closeUp10 = up10;
        this.closeRangeType = CloseRangeType.Normal;
      }

      const closeOutOfRange =
        this.view.predictClosePrice10 !== 0 &&
        (this.view.predictClosePrice10 < this.closeDown10 || this.view.predictClosePrice10 > this.closeUp10);

      const openOutOfRange = this.view.predictPrice10 !== 0 && (this.view.predictPrice10 < down10 || this.view.predictPrice10 > up10);
      this.showRenewalLine =
        this.view.quoteFlag === QuoteFlag.SQ || openOutOfRange || this.view.predictLastPrice.quoteFlag === QuoteFlag.SQ;
      this.closeOutOfRange = closeOutOfRange;
      this.renewalDown10 = down10;
      this.renewalUp10 = up10;
    }
  }

  public updateSize() {
    this.grid.updateSize();
  }

  public onDrawing(event: CancelEventArgs) {
    if (!this.view) {
      this.grid.rows = this.grid.maxRowCount;
      return;
    }
    if (this.view.master.maxItaRowCount - 3 < this.view.itaRowCount || (this.fixedRows && this.view.itaRowCount > this.fixedRows)) {
      this.view.itaRowCount = null;
    }
    if (this.view.itaRowCount === null) {
      if (!this.grid.maxRowCount) {
        event.cancel = true;
        return;
      }
      if (this.fixedRows) {
        this._stockOperator.viewMaxItaRow = this.fixedRows + 3;
        this.view.itaRowCount = Math.min(this.view.master.maxItaRowCount - 3, this.fixedRows);
      } else {
        this._stockOperator.viewMaxItaRow = this.grid.maxRowCount;
        this.view.itaRowCount = Math.min(this.view.master.maxItaRowCount, Math.max(4, this.grid.maxRowCount)) - 3;
      }
      this.stockOperator.update = true;
      event.cancel = true;
      return;
    }
    if (this.view.itaRowCountUpdated) {
      event.cancel = true;
      return;
    }

    let requireUpdateSize = false;
    if (this.grid.realMaxRowCount !== this.view.master.maxItaRowCount) {
      this.grid.realMaxRowCount = this.view.master.maxItaRowCount;
      requireUpdateSize = true;
    }
    this.grid.rows = this.view.itaRowCount + 3;
    if (requireUpdateSize) {
      this.grid.updateSize(true);
    }

    const scrollIndex =
      this.view.master.tick.price10ToIndex(this.view.master.limitDown10) +
      (this.view.master.maxItaRowCount - 3 - this.view.itaRowPriceIndex - this.view.itaRowCount);
    this.grid.setScrollIndex(scrollIndex, this.stockOperator);

    this._switchingStockOperator = false;
  }

  public onCellRender(event: CellRenderEvent) {
    const row = event.row;
    const col = event.col;
    const render = event.render;
    if (!this.view) {
      render.text = '';
      return;
    }
    const column = this.grid.columns[col];
    const name = column.name;
    const rowData =
      row === 0
        ? this.view.market
        : row === 1
        ? this.view.over
        : row === this.grid.rows - 1
        ? this.view.under
        : this.view.rows[this.grid.rows - row - 2];
    if (!rowData) {
      console.error('Failed to get rowData');
      return;
    }
    const orderRow: ItaOrderRow | null = this.fixIta
      ? this.fixIta.get(
          this.stockOperator,
          row === 0 ? 'MARKET' : row === 1 ? 'OVER' : row === this.grid.rows - 1 ? 'UNDER' : rowData.price10
        )
      : null;

    if (this.pegStatus === Side.Bid) {
      render.backgroundColor = this.getColorFromClass('p-ita-background-color-peg-up', 'ita-background-color-peg-up', 'color');
    } else if (this.pegStatus === Side.Ask) {
      render.backgroundColor = this.getColorFromClass('p-ita-background-color-peg-down', 'ita-background-color-peg-down', 'color');
    }

    if (!this.stockOperator.live) {
      render.backgroundColor = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
    }

    if (this.view.highPrice10 !== null && this.getRow(this.view.highPrice10, row + 1)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth * 2,
        color: this.getColorFromClass('p-ita-high-price-border', 'ita-high-price-border', 'color'),
      };
    }
    if (this.view.lowPrice10 !== null && this.getRow(this.view.lowPrice10, row)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth * 2,
        color: this.getColorFromClass('p-ita-low-price-border', 'ita-low-price-border', 'color'),
      };
    }
    if (this.showRenewalLine && this.getRow(this.renewalUp10, row + 1)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth,
        color: this.getColorFromClass('p-ita-price-range-border', 'ita-price-range-border', 'color'),
      };
    }
    if (this.showRenewalLine && this.getRow(this.renewalDown10, row)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth,
        color: this.getColorFromClass('p-ita-price-range-border', 'ita-price-range-border', 'color'),
      };
    }
    if (this.closeOutOfRange && this.getRow(this.closeUp10, row + 1)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth,
        color: this.getColorFromClass('p-ita-price-range-border', 'ita-price-range-border', 'color'),
      };
    }
    if (this.closeOutOfRange && this.getRow(this.closeDown10, row)) {
      render.borderBottom = {
        width: this.grid.defaultBorderWidth,
        color: this.getColorFromClass('p-ita-price-range-border', 'ita-price-range-border', 'color'),
      };
    }

    if (this.hoverUnder && this.view.rows[0] && this.view.rows[0].price10 !== this.view.master.limitDown10) {
      this.cursorStyle = 'pointer';
    } else if (
      this.hoverOver &&
      this.view.rows[this.grid.rows - 4] &&
      this.view.rows[this.grid.rows - 4].price10 !== this.view.master.limitUp10
    ) {
      this.cursorStyle = 'pointer';
    } else {
      this.cursorStyle = 'auto';
    }
    if (this.fillStopPrice && row === 1 && this.view.rows[this.grid.rows - 4].price10 === this.view.master.limitUp10) {
      render.backgroundColor = this.getColorFromClass('p-ita-background-color-limit-up', 'ita-background-color-limit-up', 'color');
    }
    if (this.fillStopPrice && row === this.grid.rows - 1 && this.view.rows[0].price10 === this.view.master.limitDown10) {
      render.backgroundColor = this.getColorFromClass('p-ita-background-color-limit-down', 'ita-background-color-limit-down', 'color');
    }
    if (name === 'price') {
      render.textAlign = 'center';
      if (row === 0) {
        render.text = '成行';
      } else if (row === 1) {
        if (this.view.rows[this.grid.rows - 4].price10 === this.view.master.limitUp10) {
          render.text = 'S高';
        } else {
          render.customTextRenderer = this.customOverRenderer.bind(this);
        }
      } else if (row === this.grid.rows - 1) {
        if (this.view.rows[0].price10 === this.view.master.limitDown10) {
          render.text = 'S安';
        } else {
          render.customTextRenderer = this.customUnderRenderer.bind(this);
        }
      } else {
        if (this.normalized) {
          render.text = this.priceChanges.transform(rowData.price10 / this.view.master.basePrice10 - 1, { showPercent: false });
        } else {
          render.text = this.fastDecimal.transform(rowData.price10 / 10, {
            fractionDigits: this.view.master.containsDecimal ? 1 : 0,
            separateComma: this.forceSeparateComma,
          });
        }
        if (rowData.price10 === this.view.lastPrice10) {
          render.backgroundColor = this.getColorFromClass('p-ita-background-color-last-price', 'ita-background-color-last-price', 'color');
        }
        const colorClass = this.colorConverter.priceChangeToClass(rowData.price10 / this.view.master.basePrice10 - 1);
        if (colorClass) {
          render.color = this.getColorFromClass(
            colorClass,
            this.colorConverter.priceChangeToVariable(rowData.price10 / this.view.master.basePrice10 - 1),
            'color'
          );
        } else {
          render.color = this.grid.defaultColor;
        }
        // FontWeightはGoogle Chromeでしか利用されていないため、Firefox向けのWorkaroundであるcolorNameを利用しない
        const fw = this.getColorFromClass(
          this.colorConverter.priceChangeToClass(rowData.price10 / this.view.master.basePrice10 - 1),
          undefined,
          'fontWeight'
        );
        if (fw === '500') {
          /*
           * nsテーマでの赤文字のフォントWeight問題への対応
           */
          render.fontWeight = 500;
        }
      }
    } else if (name === 'ask') {
      render.textAlign = 'right';
      render.backgroundColor =
        rowData.ask.delta === ItaRowDelta.None
          ? render.backgroundColor
          : rowData.ask.delta === ItaRowDelta.Increase
          ? this.getColorFromClass('p-ita-background-color-delta-increase', 'ita-background-color-delta-increase', 'color')
          : this.getColorFromClass('p-ita-background-color-delta-decrease', 'ita-background-color-delta-decrease', 'color');
      if (row === 0 && (this.stockOperator.current.quoteFlag === QuoteFlag.SQ || this.stockOperator.current.quoteFlag === QuoteFlag.CEQ)) {
        render.color = this.getColorFromClass(
          this.colorConverter.priceChangeToClass(-1),
          this.colorConverter.priceChangeToVariable(-1),
          'color'
        );
      }
      if (rowData.ask.quantity === 0) {
        render.text = '';
      } else {
        render.text = this.quantityToStr(rowData.ask);
      }
      if (this.miniMode && this.showMyOrder) {
        this.renderOrderForBidAsk(orderRow.ask, rowData.price10, render.text, render.text, render, this.alignRight ? 'right' : 'left');
        render.text = '';
      }
    } else if (name === 'bid') {
      render.backgroundColor =
        rowData.bid.delta === ItaRowDelta.None
          ? render.backgroundColor
          : rowData.bid.delta === ItaRowDelta.Increase
          ? this.getColorFromClass('p-ita-background-color-delta-increase', 'ita-background-color-delta-increase', 'color')
          : this.getColorFromClass('p-ita-background-color-delta-decrease', 'ita-background-color-delta-decrease', 'color');
      render.textAlign = this.alignRight ? 'right' : 'left';
      if (row === 0 && (this.stockOperator.current.quoteFlag === QuoteFlag.SQ || this.stockOperator.current.quoteFlag === QuoteFlag.CEQ)) {
        render.color = this.getColorFromClass(
          this.colorConverter.priceChangeToClass(1),
          this.colorConverter.priceChangeToVariable(1),
          'color'
        );
      }
      if (rowData.bid.quantity === 0) {
        render.text = '';
      } else {
        render.text = this.quantityToStr(rowData.bid);
      }
      if (this.miniMode && this.showMyOrder) {
        this.renderOrderForBidAsk(orderRow.bid, rowData.price10, render.text, render.text, render, this.alignRight ? 'right' : 'left');
        render.text = '';
      }
    } else if (name === 'askClose') {
      render.textAlign = 'right';
      render.backgroundColor =
        rowData.askClose.delta === ItaRowDelta.None
          ? render.backgroundColor
          : rowData.askClose.delta === ItaRowDelta.Increase
          ? this.getColorFromClass('p-ita-background-color-delta-increase', 'ita-background-color-delta-increase', 'color')
          : this.getColorFromClass('p-ita-background-color-delta-decrease', 'ita-background-color-delta-decrease', 'color');
      if (rowData.askClose.quantity === 0) {
        render.text = '';
      } else {
        render.text = this.quantityToStr(rowData.askClose);
      }
    } else if (name === 'bidClose') {
      render.textAlign = this.alignRight ? 'right' : 'left';
      render.backgroundColor =
        rowData.bidClose.delta === ItaRowDelta.None
          ? render.backgroundColor
          : rowData.bidClose.delta === ItaRowDelta.Increase
          ? this.getColorFromClass('p-ita-background-color-delta-increase', 'ita-background-color-delta-increase', 'color')
          : this.getColorFromClass('p-ita-background-color-delta-decrease', 'ita-background-color-delta-decrease', 'color');
      if (rowData.bidClose.quantity === 0) {
        render.text = '';
      } else {
        render.text = this.quantityToStr(rowData.bidClose);
      }
    } else if (name === 'askTotal') {
      render.textAlign = 'right';
      const total = this.showClose ? rowData.ask.total + rowData.askClose.total : rowData.ask.total;
      const normalizedTotal = this.showClose ? rowData.ask.normalizedTotal + rowData.askClose.normalizedTotal : rowData.ask.normalizedTotal;

      if (total === 0) {
        render.text = '';
      } else {
        if (this.normalized) {
          render.text = this.fastDecimal.transform(normalizedTotal / 10000000, {
            fractionDigits: 1,
            separateComma: this.forceSeparateComma,
          });
        } else {
          render.text = this.fastDecimal.transform(total, {
            separateComma: this.forceSeparateComma,
          });
        }
      }
    } else if (name === 'bidTotal') {
      render.textAlign = this.alignRight ? 'right' : 'left';
      const total = this.showClose ? rowData.bid.total + rowData.bidClose.total : rowData.bid.total;
      const normalizedTotal = this.showClose ? rowData.bid.normalizedTotal + rowData.bidClose.normalizedTotal : rowData.bid.normalizedTotal;

      if (total === 0) {
        render.text = '';
      } else {
        if (this.normalized) {
          render.text = this.fastDecimal.transform(normalizedTotal / 10000000, {
            fractionDigits: 1,
            separateComma: this.forceSeparateComma,
          });
        } else {
          render.text = this.fastDecimal.transform(total, {
            separateComma: this.forceSeparateComma,
          });
        }
      }
    } else if (name === 'fillQuantity') {
      render.textAlign = 'right';
      let left = '';
      if (this.view.openPrice10 !== 0 && this.getRow(this.view.openPrice10, row)) {
        left += 'O';
      }
      if (this.view.vwap10 !== 0 && this.getRow(this.view.vwap10, row)) {
        left += 'V';
      }
      if (rowData.fillQuantity === 0) {
        render.customTextRenderer = this.customVolumeRenderer(left, '', render);
      } else {
        render.customBackgroundRenderer = this.fillQuantityRenderer(rowData.fillQuantity, this.view.volume);
        if (this.normalized) {
          render.customTextRenderer = this.customVolumeRenderer(
            left,
            this.fastDecimal.transform(rowData.normalizedFillQuantity / 10000000, {
              separateComma: this.forceSeparateComma,
              fractionDigits: 1,
            }),
            render
          );
        } else {
          render.customTextRenderer = this.customVolumeRenderer(
            left,
            this.fastDecimal.transform(rowData.fillQuantity, {
              separateComma: this.forceSeparateComma,
            }),
            render
          );
        }
      }
      render.cacheKey = `${left}|${rowData.fillQuantity}|${this.view.volume}|${this.normalized}`;
      render.appendSimpleCacheKey();
    } else if (name === 'askState') {
      if (rowData.askState & (ItaPriceState.MiddleSQ | ItaPriceState.MiddleCEQ)) {
        render.textAlign = 'center';
        render.color = this.getColorFromClass(
          this.colorConverter.priceChangeToClass(-1),
          this.colorConverter.priceChangeToVariable(-1),
          'color'
        );
        render.text = this.getStateString(rowData.askState, Side.Ask);
      } else if (this.view.predictSide === Side.Ask && this.view.predictPrice10 !== 0 && this.getRow(this.view.predictPrice10, row)) {
        render.textAlign = 'center';
        render.text = '寄';
      } else if (
        this.view.predictCloseSide === Side.Ask &&
        this.view.predictClosePrice10 !== 0 &&
        this.getRow(this.view.predictClosePrice10, row)
      ) {
        render.textAlign = 'center';
        render.text = this.closeOutOfRange ? 'ザ' : '引';
      } else {
        render.textAlign = 'center';
        render.text = this.getStateString(rowData.askState, Side.Ask);
      }
    } else if (name === 'bidState') {
      if (rowData.bidState & (ItaPriceState.MiddleSQ | ItaPriceState.MiddleCEQ)) {
        render.textAlign = 'center';
        render.color = this.getColorFromClass(
          this.colorConverter.priceChangeToClass(1),
          this.colorConverter.priceChangeToVariable(1),
          'color'
        );
        render.text = this.getStateString(rowData.bidState, Side.Bid);
      } else if (this.view.predictSide === Side.Bid && this.view.predictPrice10 !== 0 && this.getRow(this.view.predictPrice10, row)) {
        render.textAlign = 'center';
        render.text = '寄';
      } else if (
        this.view.predictCloseSide === Side.Bid &&
        this.view.predictClosePrice10 !== 0 &&
        this.getRow(this.view.predictClosePrice10, row)
      ) {
        render.textAlign = 'center';
        render.text = this.closeOutOfRange ? 'ザ' : '引';
      } else {
        render.textAlign = 'center';
        render.text = this.getStateString(rowData.bidState, Side.Bid);
      }
    } else if (name === 'askOrder') {
      render.textAlign = 'right';
      const orderNum = this.showClose ? rowData.ask.order + rowData.askClose.order : rowData.ask.order;
      if (orderNum === 0) {
        render.text = '';
      } else {
        render.text = this.fastDecimal.transform(orderNum, {
          separateComma: this.forceSeparateComma,
        });
      }
    } else if (name === 'bidOrder') {
      render.textAlign = this.alignRight ? 'right' : 'left';
      const orderNum = this.showClose ? rowData.bid.order + rowData.bidClose.order : rowData.bid.order;
      if (orderNum === 0) {
        render.text = '';
      } else {
        render.text = this.fastDecimal.transform(orderNum, {
          separateComma: this.forceSeparateComma,
        });
      }
    } else if (name === 'bidFixOrder') {
      if (this._orderDisabled) {
        render.backgroundColor = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
      } else if (orderRow) {
        this.renderOrder(orderRow.bid, rowData.price10, render, this.alignRight ? 'right' : 'left');
      }
    } else if (name === 'askFixOrder') {
      if (this._orderDisabled) {
        render.backgroundColor = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
      } else if (orderRow) {
        this.renderOrder(orderRow.ask, rowData.price10, render, 'right');
      }
    } else if (name === 'bidCloseFixOrder') {
      if (this._orderDisabled) {
        render.backgroundColor = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
      } else if (orderRow) {
        this.renderOrder(orderRow.bidClose, rowData.price10, render, this.alignRight ? 'right' : 'left');
      }
    } else if (name === 'askCloseFixOrder') {
      if (this._orderDisabled) {
        render.backgroundColor = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
      } else if (orderRow) {
        this.renderOrder(orderRow.askClose, rowData.price10, render, 'right');
      }
    }
    if (render.cacheKey === undefined) {
      render.createSimpleCacheKey();
    }
  }

  public getRow(price10: number, row: number, targetRows = null): boolean {
    if (!targetRows) {
      targetRows = this.view.rows;
    }
    if (row === 1) {
      // OVER
      return price10 > targetRows[targetRows.length - 1].price10;
    } else if (row === targetRows.length + 2) {
      // UNDER
      return price10 < targetRows[0].price10;
    } else if (row === 0) {
      // 成行
      return false;
    } else if (1 < row && row < 2 + targetRows.length) {
      const rowData = targetRows[this.grid.rows - row - 2];
      return rowData && rowData.price10 === price10;
    } else {
      return false;
    }
  }

  private fillQuantityRenderer(quantity, allQuantity): (DrawContext) => void {
    return (c: DrawContext) => {
      if (!this.stockOperator.live || this.pegStatus !== null) {
        if (this.pegStatus === Side.Bid) {
          c.context.fillStyle = this.getColorFromClass('p-ita-background-color-peg-up', 'ita-background-color-peg-up', 'color');
        } else if (this.pegStatus === Side.Ask) {
          c.context.fillStyle = this.getColorFromClass('p-ita-background-color-peg-down', 'ita-background-color-peg-down', 'color');
        } else {
          c.context.fillStyle = this.getColorFromClass('p-ita-background-color-history', 'ita-background-color-history', 'color');
        }
        c.context.fillRect(c.left, c.top, c.width - this.grid.defaultBorderWidth, c.height - this.grid.defaultBorderWidth);
      } else {
        c.context.clearRect(c.left, c.top, c.width - this.grid.defaultBorderWidth, c.height - this.grid.defaultBorderWidth);
      }
      c.context.fillStyle = this.getColorFromClass('p-ita-background-color-quantity', 'ita-background-color-quantity', 'color');
      const rWidth = Math.ceil((quantity / allQuantity) * (c.width - this.grid.defaultBorderWidth - 4));
      c.context.fillRect(
        c.left + 2 + c.width - this.grid.defaultBorderWidth - 4 - rWidth,
        c.top + 2,
        rWidth,
        c.height - this.grid.defaultBorderWidth - 4
      );
    };
  }

  private customVolumeRenderer(left: string, right: string, render: CellRenderer): (c: DrawContext) => void {
    return (c: DrawContext) => {
      const context = c.context;
      context.save();
      try {
        const rightBorderWidth = (render.borderRight && render.borderRight.width) || this.grid.defaultBorderWidth;
        const padding = this.grid.defaultPadding;
        context.beginPath();
        context.rect(c.left, c.top, c.width, c.height);
        context.clip();

        context.textBaseline = 'middle';
        if (left !== '') {
          context.textAlign = 'left';
          context.font = this.grid.defaultFont.toString();
          context.fillStyle = this.grid.defaultColor;
          context.fillText(left, c.left + padding, c.top + c.height / 2);
        }
        if (!this.showVolumeChartOnly) {
          if (right !== '') {
            context.textAlign = 'right';
            context.font = this.grid.defaultFont.toString();
            context.fillStyle = this.grid.defaultColor;
            context.fillText(right, c.left + c.width - padding, c.top + c.height / 2);
          }
        }
      } finally {
        context.restore();
      }
    };
  }

  private roundRectPath(ctx: CanvasRenderingContext2D, left: number, top: number, width: number, height: number, radius: number) {
    ctx.beginPath();
    ctx.arc(left + radius, top + radius, radius, -Math.PI, -0.5 * Math.PI, false);
    ctx.arc(left + width - radius, top + radius, radius, -0.5 * Math.PI, 0, false);
    ctx.arc(left + width - radius, top + height - radius, radius, 0, 0.5 * Math.PI, false);
    ctx.arc(left + radius, top + height - radius, radius, 0.5 * Math.PI, Math.PI, false);
    ctx.closePath();
  }

  private customOverRenderer(c: DrawContext): void {
    const context = c.context;
    context.save();
    try {
      context.beginPath();
      context.rect(c.left, c.top, c.width, c.height);
      context.clip();
      if (this.overUnderButton) {
        context.strokeStyle = '#538bb0';
        context.lineWidth = this.grid.defaultBorderWidth;
        this.roundRectPath(
          context,
          c.left + this.grid.defaultBorderWidth / 2 + 1,
          c.top + +this.grid.defaultBorderWidth / 2 + 1,
          c.width - this.grid.defaultBorderWidth - 2,
          c.height - this.grid.defaultBorderWidth - 2,
          this.grid.defaultBorderWidth * 2
        );
        if (this.hoverOver) {
          context.fillStyle = '#ccdeeb';
          context.fill();
        }
        context.stroke();
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = this.grid.defaultFont.toString();
        context.fillStyle = '#538bb0';
      } else {
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = this.grid.defaultFont.toString();
        context.fillStyle = this.hoverOver
          ? this.getColorFromClass('p-ita-over-under-hover-color', 'ita-over-under-hover-color', 'color')
          : this.grid.defaultColor;
      }
      context.fillText('OVER', c.left + c.width / 2, c.top + this.grid.rowHeight / 2);
      const pos = context.measureText('OVER').width / 2;
      context.textAlign = 'left';
      context.fillText('▲', c.left + c.width / 2 + pos + 2, c.top + this.grid.rowHeight / 2);
    } finally {
      context.restore();
    }
  }

  private customUnderRenderer(c: DrawContext): void {
    const context = c.context;
    context.save();
    try {
      context.beginPath();
      context.rect(c.left, c.top, c.width, c.height);
      context.clip();

      if (this.overUnderButton) {
        context.strokeStyle = '#538bb0';
        context.lineWidth = this.grid.defaultBorderWidth;
        this.roundRectPath(
          context,
          c.left + this.grid.defaultBorderWidth / 2 + 1,
          c.top + +this.grid.defaultBorderWidth / 2 + 1,
          c.width - this.grid.defaultBorderWidth - 2,
          c.height - this.grid.defaultBorderWidth - 2,
          this.grid.defaultBorderWidth * 2
        );
        if (this.hoverUnder) {
          context.fillStyle = '#ccdeeb';
          context.fill();
        }
        context.stroke();
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = this.grid.defaultFont.toString();
        context.fillStyle = '#538bb0';
      } else {
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = this.grid.defaultFont.toString();
        context.fillStyle = this.hoverUnder
          ? this.getColorFromClass('p-ita-over-under-hover-color', 'ita-over-under-hover-color', 'color')
          : this.grid.defaultColor;
      }
      context.fillText('UNDR', c.left + c.width / 2, c.top + this.grid.rowHeight / 2);
      const pos = context.measureText('UNDR').width / 2;
      context.textAlign = 'right';
      context.fillText('▼', c.left + c.width / 2 - pos - 2, c.top + this.grid.rowHeight / 2);
    } finally {
      context.restore();
    }
  }

  private getStateString(state: number, side: Side) {
    if (state & ItaPriceState.MiddleQuote) {
      return '-';
    } else if (state & ItaPriceState.MiddleSQ) {
      // 現在情報を表示している時のみカウントダウンを実施する
      if (this.timeProvider && this.stockOperator.live) {
        const ret = this.view.getQuoteRemain(this.timeProvider.getTime());
        if (ret) {
          return ret;
        }
        if (side === Side.Ask) {
          return 'ウ';
        } else {
          return 'カ';
        }
      } else {
        if (side === Side.Ask) {
          return 'ウ';
        } else {
          return 'カ';
        }
      }
    } else if (state & ItaPriceState.MiddleCEQ) {
      return 'C';
    } else {
      return '';
    }
  }

  private onMouseDownOrderOverUnder(ev: CellMouseEvent, priceType: 'OVER' | 'UNDER', type: ItaSide) {
    if (!this.fixIta) {
      return;
    }
    if (!this.view) {
      return;
    }
    if (ev.button === 0) {
      if (ev.shiftKey) {
        if (this.normalized) {
          this.dialog.alert('正規化中には訂正できません');
          return;
        }
        this.fixIta.tryCancel(this.stockOperator, priceType, type);
      } else {
        this.fixIta.clearRegisteredOrder(this.stockOperator);
      }
    } else if (ev.button === 2) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      this.fixIta.startAmending(this.stockOperator, priceType, type);
    } else if (ev.button === 1) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      if (ev.buttons !== 4) {
        this.fixIta.clearAmending(this.stockOperator);
        this.dialog.alert('他のマウスボタン操作中には板登録できません');
        return;
      }
      this.fixIta.tryCancel(this.stockOperator, priceType, type);
    }
  }

  private onMouseDownOrderMarket(ev: CellMouseEvent, lotSize: Share, type: ItaSide): void {
    if (!this.fixIta) {
      return;
    }
    if (!this.view) {
      return;
    }
    if (ev.button === 0) {
      if (ev.shiftKey) {
        if (this.normalized) {
          this.dialog.alert('正規化中には訂正できません');
          return;
        }
        this.fixIta.tryCancel(this.stockOperator, 'MARKET', type);
      } else {
        if (this.normalized) {
          this.dialog.alert('正規化中に板登録できません');
          return;
        }
        if (this.fixIta.isAmending(this.stockOperator)) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('訂正中には板登録できません。訂正を中断します');
          return;
        }
        if (ev.buttons !== 1) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('他のマウスボタン操作中には板登録できません');
          return;
        }
        this.fixIta.tryRegisterOrderByClick(
          this.stockOperator,
          'MARKET',
          lotSize,
          type,
          this.userAgentService.isMac ? ev.metaKey : ev.ctrlKey
        );
      }
    } else if (ev.button === 2) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      this.fixIta.startAmending(this.stockOperator, 'MARKET', type);
    } else if (ev.button === 1) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      if (ev.buttons !== 4) {
        this.fixIta.clearAmending(this.stockOperator);
        this.dialog.alert('他のマウスボタン操作中には板登録できません');
        return;
      }
      this.fixIta.tryCancel(this.stockOperator, 'MARKET', type);
    }
  }

  onMouseDownMiniOrder(ev: CellMouseEvent, p10: Price10, lotSize: Share, type: ItaSide): void {
    if (this.miniMode && this.showMyOrder) {
      if (!this.fixIta) {
        return;
      }
      if (!this.view) {
        return;
      }
      if (ev.button === 0) {
        if (this.normalized) {
          this.dialog.alert('正規化中に板登録できません');
          return;
        }
        if (this.fixIta.isAmending(this.stockOperator)) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('訂正中には板登録できません。訂正を中断します');
          return;
        }
        if (ev.buttons !== 1) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('他のマウスボタン操作中には板登録できません');
          return;
        }
        this.fixIta.tryRegisterOrderByClick(this.stockOperator, p10, lotSize, type, this.userAgentService.isMac ? ev.metaKey : ev.ctrlKey);
      }
    }
  }

  private onMouseDownOrder(ev: CellMouseEvent, p10: Price10, lotSize: Share, type: ItaSide): void {
    if (!this.fixIta) {
      return;
    }
    if (!this.view) {
      return;
    }
    if (ev.button === 0) {
      if (ev.shiftKey) {
        if (this.normalized) {
          this.dialog.alert('正規化中には訂正できません');
          return;
        }
        this.fixIta.tryCancel(this.stockOperator, p10, type);
      } else {
        if (this.normalized) {
          this.dialog.alert('正規化中に板登録できません');
          return;
        }
        if (this.fixIta.isAmending(this.stockOperator)) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('訂正中には板登録できません.訂正を中断します');
          return;
        }
        if (ev.buttons !== 1) {
          this.fixIta.clearAmending(this.stockOperator);
          this.dialog.alert('他のマウスボタン操作中には板登録できません');
          return;
        }
        this.fixIta.tryRegisterOrderByClick(this.stockOperator, p10, lotSize, type, this.userAgentService.isMac ? ev.metaKey : ev.ctrlKey);
      }
    } else if (ev.button === 2) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      this.fixIta.startAmending(this.stockOperator, p10, type);
      ev.preventDefault();
    } else if (ev.button === 1) {
      if (this.normalized) {
        this.dialog.alert('正規化中には訂正できません');
        return;
      }
      if (ev.buttons !== 4) {
        this.fixIta.clearAmending(this.stockOperator);
        this.dialog.alert('他のマウスボタン操作中には板登録できません');
        return;
      }
      this.fixIta.tryCancel(this.stockOperator, p10, type);
    }
  }

  private quantityToStr(item: ItaRowItem): string {
    if (this.normalized) {
      return this.fastDecimal.transform(item.normalizedQuantity / 10000000, { separateComma: this.forceSeparateComma, fractionDigits: 1 });
    } else {
      return this.fastDecimal.transform(item.quantity, { separateComma: this.forceSeparateComma });
    }
  }

  private renderOrder(side: OrderItaRowSide, price10: number, render: CellRenderer, direction: 'left' | 'right') {
    render.customTextRenderer = (c: DrawContext) => {
      const context = c.context;
      context.save();
      try {
        context.beginPath();
        context.rect(c.left, c.top, c.width, c.height);
        context.clip();

        context.textBaseline = 'middle';
        context.textAlign = direction;
        context.font = this.grid.defaultFont.setFontWeight('700').toString();
        let movePos = this.grid.defaultPadding;
        if (side.registeredOrder) {
          context.fillStyle = (side.registeredOrder * price10) / 10 >= 10000000 ? 'red' : this.grid.defaultColor;
          const [str, width] = this.ellipsisDraw(
            context,
            `[${side.registeredOrderPrefix}${this.fastDecimal.transform(side.registeredOrder)}]`,
            c.width - movePos
          );
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (side.progressingOrder > 0) {
          context.fillStyle = this.grid.defaultColor;
          const [str, width] = this.ellipsisDraw(context, `(${this.fastDecimal.transform(side.progressingOrder)})`, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (side.cancelingOrder > 0) {
          context.fillStyle = 'gray';
          const [str, width] = this.ellipsisDraw(context, ` ${this.fastDecimal.transform(side.cancelingOrder)}`, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (side.amendingOrder) {
          context.fillStyle = 'gray';
          const [str, width] = this.ellipsisDraw(context, `[${this.fastDecimal.transform(side.amendingOrder)}]`, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        const orderQuantity = side.order - side.cancelingOrder;
        if (orderQuantity) {
          context.fillStyle = side.isAmendingOrderSrc ? '#aaa' : this.grid.defaultColor;
          const [str, width] = this.ellipsisDraw(
            context,
            this.normalized
              ? this.fastDecimal.transform(side.normalizedOrder / 10000000, { separateComma: true, fractionDigits: 1 })
              : `${this.fastDecimal.transform(orderQuantity)}`,
            c.width - movePos
          );
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
      } finally {
        context.restore();
      }
    };
  }

  private renderOrderForBidAsk(
    side: OrderItaRowSide,
    price10: number,
    quantity: string,
    normalizedQuantity: string,
    render: CellRenderer,
    direction: 'left' | 'right'
  ) {
    render.customTextRenderer = (c: DrawContext) => {
      const context = c.context;
      context.save();
      try {
        context.beginPath();
        context.rect(c.left, c.top, c.width, c.height);
        context.clip();

        context.textBaseline = 'middle';
        context.textAlign = direction;
        context.font = this.grid.defaultFont.setFontWeight('700').toString();
        let movePos = this.grid.defaultPadding;
        if (side.registeredOrder) {
          context.fillStyle = (side.registeredOrder * price10) / 10 >= 10000000 ? 'red' : this.grid.defaultColor;
          const [str, width] = this.ellipsisDraw(
            context,
            `[${side.registeredOrderPrefix}${this.fastDecimal.transform(side.registeredOrder)}]`,
            c.width - movePos
          );
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (side.progressingOrder > 0) {
          context.fillStyle = this.grid.defaultColor;
          const [str, width] = this.ellipsisDraw(context, `(${this.fastDecimal.transform(side.progressingOrder)})`, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (side.cancelingOrder > 0) {
          context.fillStyle = 'gray';
          const [str, width] = this.ellipsisDraw(context, ` ${this.fastDecimal.transform(side.cancelingOrder)}`, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
        if (quantity) {
          context.fillStyle = this.grid.defaultColor;
          context.font = this.grid.defaultFont.toString();
          const [str, width] = this.ellipsisDraw(context, this.normalized ? normalizedQuantity : quantity, c.width - movePos);
          if (direction === 'left') {
            context.fillText(str, c.left + movePos, c.top + this.grid.rowHeight / 2);
          } else {
            context.fillText(str, c.left + c.width - movePos, c.top + this.grid.rowHeight / 2);
          }
          movePos += width;
        }
      } finally {
        context.restore();
      }
    };
  }

  private ellipsisDraw(context: CanvasRenderingContext2D, str: string, maxWidth: number): [string, number] {
    const width = context.measureText(str).width;
    if (width <= maxWidth) {
      return [str, width];
    }
    for (let i = 0; i < str.length; i++) {
      const newStr = '…' + str.substr(i);
      const newWidth = context.measureText(newStr).width;
      if (newWidth <= maxWidth) {
        return [newStr, newWidth];
      }
    }
    return ['', 0];
  }

  public onCellMouseDown(event: CellMouseEvent) {
    if (!this.view || this._switchingStockOperator) {
      return;
    }
    event.preventDefault();
    const column = this.grid.columns[event.col];
    if (column.name === 'price') {
      if (event.button === 0) {
        if (event.row === 1) {
          this.priceClick.emit('OVER');
          this.buttonMove(1);
        } else if (event.row === this.grid.rows - 1) {
          this.priceClick.emit('UNDER');
          this.buttonMove(-1);
        } else if (event.row === 0) {
          this.priceClick.emit('MARKET');
          this.fixIta.price10Click('MARKET');
        } else {
          const price10 = this.view.rows[this.grid.rows - event.row - 2] && this.view.rows[this.grid.rows - event.row - 2].price10;
          if (price10) {
            this.priceClick.emit(price10);
            if (this.fixIta) {
              this.fixIta.price10Click(price10);
            }
          }
        }
      }
    }

    if (column.name === 'askState' || column.name === 'bidState') {
      this.moveToState(column.name === 'askState' ? Side.Ask : Side.Bid, event.row);
    }

    if (this.miniMode && this.showMyOrder && (column.name === 'ask' || column.name === 'bid')) {
      if (event.row === 1 || event.row === this.grid.rows - 1) {
      } else if (event.row === 0) {
      } else {
        const p10 = this.view.rows[this.grid.rows - event.row - 2].price10;
        const lotSize = this.view.master.lotSize;
        this.onMouseDownMiniOrder(event, p10, lotSize, column.name === 'ask' ? ItaSide.Ask : ItaSide.Bid);
        return;
      }
    }

    if (
      column.name === 'askFixOrder' ||
      column.name === 'askCloseFixOrder' ||
      column.name === 'bidFixOrder' ||
      column.name === 'bidCloseFixOrder'
    ) {
      const type: ItaSide =
        column.name === 'askFixOrder'
          ? ItaSide.Ask
          : column.name === 'askCloseFixOrder'
          ? ItaSide.AskClose
          : column.name === 'bidFixOrder'
          ? ItaSide.Bid
          : ItaSide.BidClose;
      const lotSize = this.view.master.lotSize;
      if (event.row === 1 || event.row === this.grid.rows - 1) {
        this.onMouseDownOrderOverUnder(event, event.row === 1 ? 'OVER' : 'UNDER', type);
      } else if (event.row === 0) {
        this.onMouseDownOrderMarket(event, lotSize, type);
      } else {
        const p10 = this.view.rows[this.grid.rows - event.row - 2].price10;
        this.onMouseDownOrder(event, p10, lotSize, type);
      }
    } else {
      if (this.fixIta) {
        this.fixIta.clearRegisteredOrder(this.stockOperator);
      }
    }
  }

  /**
   * 状態の表す値段に移動する
   */
  private moveToState(side: Side, row: number) {
    // 成行
    if (row === 0) {
      return;
    }
    const rowData =
      row === 0
        ? this.view.market
        : row === 1
        ? this.view.over
        : row === this.grid.rows - 1
        ? this.view.under
        : this.view.rows[this.grid.rows - row - 2];
    if (!rowData) {
      console.error('Failed to get rowData');
      return;
    }
    if (side === Side.Ask) {
      if (rowData.askState & (ItaPriceState.MiddleSQ | ItaPriceState.MiddleCEQ)) {
        this.moveToPrice(this.view.askPrice10);
        this.stopPeg();
      } else if (this.view.predictSide === Side.Ask && this.view.predictPrice10 !== 0 && this.getRow(this.view.predictPrice10, row)) {
        this.moveToPrice(this.view.predictPrice10);
        this.stopPeg();
      } else if (
        this.view.predictCloseSide === Side.Ask &&
        this.view.predictClosePrice10 !== 0 &&
        this.getRow(this.view.predictClosePrice10, row)
      ) {
        this.moveToPrice(this.view.predictClosePrice10);
        this.stopPeg();
      } else if (rowData.askState !== 0) {
        this.moveToPrice(this.view.askPrice10);
        this.stopPeg();
      }
    } else {
      if (rowData.bidState & (ItaPriceState.MiddleSQ | ItaPriceState.MiddleCEQ)) {
        this.moveToPrice(this.view.bidPrice10);
        this.stopPeg();
      } else if (this.view.predictSide === Side.Bid && this.view.predictPrice10 !== 0 && this.getRow(this.view.predictPrice10, row)) {
        this.moveToPrice(this.view.predictPrice10);
        this.stopPeg();
      } else if (
        this.view.predictCloseSide === Side.Bid &&
        this.view.predictClosePrice10 !== 0 &&
        this.getRow(this.view.predictClosePrice10, row)
      ) {
        this.moveToPrice(this.view.predictClosePrice10);
        this.stopPeg();
      } else if (rowData.bidState !== 0) {
        this.moveToPrice(this.view.bidPrice10);
        this.stopPeg();
      }
    }
  }

  private moveToPrice(price10: number) {
    this.view.itaRowPriceIndex = this.stockOperator.current.master.tick.price10ToIndex(price10) - Math.trunc(this.view.itaRowCount / 2);
  }

  public onCellMouseUp(event: CellMouseEvent) {
    if (!this.view || this._switchingStockOperator) {
      return;
    }
    event.preventDefault();
    const column = this.grid.columns[event.col];

    if (column.name === 'price') {
      if (event.button === 1 && column.name === 'price') {
        this.normalized = !this.normalized;
        this.normalizedChange.emit(this.normalized);
        this.stockOperator.update = true;
      }
      if (this.fixIta) {
        this.fixIta.clearAmending(this.stockOperator);
      }
    } else if (
      column.name === 'askFixOrder' ||
      column.name === 'askCloseFixOrder' ||
      column.name === 'bidFixOrder' ||
      column.name === 'bidCloseFixOrder'
    ) {
      const type: ItaSide =
        column.name === 'askFixOrder'
          ? ItaSide.Ask
          : column.name === 'askCloseFixOrder'
          ? ItaSide.AskClose
          : column.name === 'bidFixOrder'
          ? ItaSide.Bid
          : ItaSide.BidClose;
      if (event.row === 0 || event.row === 1 || event.row === this.grid.rows - 1) {
        if (event.row === 0) {
          if (event.button === 2) {
            const lotSize = this.view.master.lotSize;
            if (this.fixIta.hasRegisteredOrder(this.stockOperator)) {
              this.fixIta.trySendOrderByClick(this.stockOperator, 'MARKET', lotSize, type);
            } else {
              this.fixIta.clearAmending(this.stockOperator);
            }
          }
        }
        this.fixIta.clearAmending(this.stockOperator);
      } else {
        if (event.button === 2) {
          const p10 = this.view.rows[this.grid.rows - event.row - 2].price10;
          const lotSize = this.view.master.lotSize;
          if (this.fixIta.hasRegisteredOrder(this.stockOperator)) {
            this.fixIta.trySendOrderByClick(this.stockOperator, p10, lotSize, type);
          } else if (this.fixIta.isAmending(this.stockOperator)) {
            this.fixIta.runAmending(this.stockOperator, p10, type);
          }
          this.fixIta.clearAmending(this.stockOperator);
        }
      }
    } else if (this.miniMode && this.showMyOrder && (column.name === 'ask' || column.name === 'bid')) {
      if (event.row === 0 || event.row === 1 || event.row === this.grid.rows - 1) {
      } else {
        if (event.button === 2) {
          const p10 = this.view.rows[this.grid.rows - event.row - 2].price10;
          const lotSize = this.view.master.lotSize;
          if (this.fixIta.hasRegisteredOrder(this.stockOperator)) {
            this.fixIta.trySendOrderByClick(this.stockOperator, p10, lotSize, column.name === 'ask' ? ItaSide.Ask : ItaSide.Bid);
          }
        }
      }
    } else {
      if (this.fixIta) {
        this.fixIta.clearAmending(this.stockOperator);
      }
    }

    if (event.button === 2 && this._document) {
      // prevent contextmenu if a dialog is opened from click event
      this._document.addEventListener('contextmenu', preventDefault, true);
      setTimeout(() => {
        this._document.removeEventListener('contextmenu', preventDefault, true);
      }, 0);
    }
  }

  public onCellDblClick(event: CellMouseEvent) {
    if (!this.view) {
      return;
    }
    if (event.button === 0) {
      if (this.grid.columns[event.col].name === 'price') {
        if (2 <= event.row && event.row < this.grid.rows - 1) {
          this.resetPosition();
          if (this.peg === PegState.TemporaryDisabled || this.peg === PegState.TemporaryDisabledByOrder) {
            this.peg = PegState.Enabled;
            this.pegChange.emit(this.peg);
          }
          this.priceDblClick.emit({});
          if (this.dblClickTutorial && this.dblClickTutorial.shown) {
            this.dblClickTutorial.close();
            this.onDblClickTutorialComplete();
          }
        }
      }
    }
  }

  public onHeaderCellDblClick(event: CellMouseEvent) {
    if (!this.view) {
      return;
    }
    if (event.button === 0) {
      if (this.grid.columns[event.col].name === 'price') {
        this.resetPosition();
        if (this.peg === PegState.TemporaryDisabled || this.peg === PegState.TemporaryDisabledByOrder) {
          this.peg = PegState.Enabled;
          this.pegChange.emit(this.peg);
        }
        this.priceDblClick.emit({});
      }
    }
  }

  public onCellMouseEnter(event: CellMouseEvent) {
    if (!this.view || this._switchingStockOperator) {
      return;
    }
    const column = this.grid.columns[event.col];
    if (column.name === 'price') {
      if (event.row === 1) {
        this.hoverOver = true;
        this.stockOperator.update = true;
      } else if (event.row === this.grid.rows - 1) {
        this.hoverUnder = true;
        this.stockOperator.update = true;
      }
    }
    if (
      column.name === 'askFixOrder' ||
      column.name === 'askCloseFixOrder' ||
      column.name === 'bidFixOrder' ||
      column.name === 'bidCloseFixOrder'
    ) {
      const type: ItaSide =
        column.name === 'askFixOrder'
          ? ItaSide.Ask
          : column.name === 'askCloseFixOrder'
          ? ItaSide.AskClose
          : column.name === 'bidFixOrder'
          ? ItaSide.Bid
          : ItaSide.BidClose;
      if (event.row === 0 || event.row === 1 || event.row === this.grid.rows - 1) {
        // TODO:
      } else {
        // TODO:
        if (this.view.rows[this.grid.rows - event.row - 2]) {
          const p10 = this.view.rows[this.grid.rows - event.row - 2].price10;
          this.fixIta.updateAmending(this.stockOperator, p10, type);
        }
      }
    } else {
      if (this.fixIta) {
        // TODO: マウスイベントが飛んで、OrderColumn間を直接移動した場合に正しく動作しない
        this.fixIta.clearRegisteredOrder(this.stockOperator);
      }
    }
  }

  public onCellMouseLeave(event: CellMouseEvent) {
    if (!this.view || this._switchingStockOperator) {
      return;
    }
    const column = this.grid.columns[event.col];
    if (column.name === 'price') {
      if (event.row === 1) {
        this.hoverOver = false;
        this.stockOperator.update = true;
      } else if (event.row === this.grid.rows - 1) {
        this.hoverUnder = false;
        this.stockOperator.update = true;
      }
    }
    if (
      column.name === 'askFixOrder' ||
      column.name === 'askCloseFixOrder' ||
      column.name === 'bidFixOrder' ||
      column.name === 'bidCloseFixOrder'
    ) {
      if (this.fixIta) {
        this.fixIta.invisibleAmending(this.stockOperator);
      }
    }
  }

  public saveImage(footer: string, filePrefix = 'brisk-', timestampMicroseconds = true) {
    const [qrString, qrColor] = this.getQRStringAndColor(this.view);
    let ret = this.grid.getImage({
      bottomRight: footer,
      headingLeft: `${this.view.master.issueCode} ${this.view.master.name}`,
      headingRight: `${this.timestampPipe.transform(this.view.timestamp, { microseconds: timestampMicroseconds })}`,
      headingCenter: qrString,
      headingCenterColor: qrColor,
    });
    if (!ret) {
      return;
    }
    ret = ret.replace('data:image/png;base64,', '');
    const bin = window.atob(ret);
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'image/png',
    });
    saveAs(
      blob,
      `${filePrefix}ita-${this.view.master.name}-${format(this.stockOperator.date, 'YYYY-MM-DD')}-` +
        `${this.timestampPipe.transform(this.view.timestamp, { microseconds: true }).replace(/[:.]/g, '')}.png`,
      true
    );
  }

  getQRStringAndColor(view: StockView): [string, string] {
    for (let i = view.qr.length - 1; i >= 0; i--) {
      if (view.frame > view.qr[i].frameNumber) {
        return ['', ''];
      }
      if (view.frame === view.qr[i].frameNumber) {
        const priceChangeType = view.qr[i].type === QRRowType.Ask ? 1 : view.qr[i].type === QRRowType.Bid ? -1 : 0;
        return [
          `約定 ${this.fastDecimal.transform(view.qr[i].quantity, {
            separateComma: true,
          })}株@${this.fastDecimal.transform(view.qr[i].price10 / 10, {
            fractionDigits: this.view.master.containsDecimal ? 1 : 0,
            separateComma: true,
          })}円`,
          priceChangeType === 0
            ? this.grid.defaultColor
            : this.getColorFromClass(
                this.colorConverter.priceChangeToClass(priceChangeType),
                this.colorConverter.priceChangeToVariable(priceChangeType),
                'color'
              ),
        ];
      }
    }
    return ['', ''];
  }

  private buttonMove(direction: number) {
    this.view.itaRowPriceIndex += direction * this.view.itaRowCount;
    if (this.view.itaRowPriceIndex <= 0) {
      this.view.itaRowPriceIndex = 1;
    }
    this.stopPeg();
    this.stockOperator.update = true;
  }

  onSizeUpdated() {
    if (this.view) {
      if (this.fixedRows) {
        this._stockOperator.viewMaxItaRow = this.fixedRows + 3;
        this.view.itaRowCount = Math.min(this.view.master.maxItaRowCount - 3, this.fixedRows);
        if (this.gridHeight !== this.grid.height) {
          this.gridHeight = this.grid.height;
          this.elem.nativeElement.style.height = `${this.gridHeight}px`;
          setTimeout(() => {
            this.gridHeightChanged.emit(this.gridHeight);
          });
        }
      } else {
        this._stockOperator.viewMaxItaRow = this.grid.maxRowCount;
        this.view.itaRowCount = Math.min(this.view.master.maxItaRowCount, Math.max(4, this.grid.maxRowCount)) - 3;
      }
      this.stockOperator.update = true;
    }
  }

  onScrollChanged(event: ScrollChangedEvent) {
    if (this.view) {
      if (event.eventSource && event.eventSource !== this.stockOperator) {
        return;
      }
      const newRow =
        this.view.master.tick.price10ToIndex(this.view.master.limitDown10) +
        (this.view.master.maxItaRowCount - 3 - event.row - this.view.itaRowCount);
      if (this.view.itaRowPriceIndex !== newRow) {
        this.view.itaRowPriceIndex = newRow;
        if (!event.includeScrollTopChange) {
          this.stopPeg();
          this.itaScroll.emit({});
          if (this.dblClickTutorial) {
            this.dblClickTutorial.show();
          }
        }
      }

      if (this.fixIta) {
        this.fixIta.clearAmending(this.stockOperator);
        this.fixIta.clearRegisteredOrder(this.stockOperator);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.themeChangedSubscription) {
      this.themeChangedSubscription.unsubscribe();
      this.themeChangedSubscription = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    if (this.pegSubject) {
      this.pegSubject.complete();
      this.pegSubscription.unsubscribe();
    }
    if (this.afterViewInitSubscription) {
      this.afterViewInitSubscription.unsubscribe();
      this.afterViewInitSubscription = null;
    }
    if (this.fixItaSubscription) {
      this.fixItaSubscription.unsubscribe();
      this.fixItaSubscription = null;
    }
  }

  updateTheme() {
    this.themeColors = {};
    this.grid.updateSize(true);
  }

  getColorFromClass(className: string, colorName: string | undefined, name: 'color' | 'fontWeight') {
    const key = `${className}$${name}`;
    if (key in this.themeColors) {
      return this.themeColors[key];
    }
    if (colorName) {
      const color = this.theme.getStyleValue(colorName);
      if (color) {
        this.themeColors[key] = color;
        return color;
      }
    }
    const elem: HTMLSpanElement = this.themeElement.nativeElement;
    elem.className = className;
    this.themeColors[key] = getComputedStyle(elem)[name];
    return this.themeColors[key];
  }

  ngAfterViewInit(): void {
    this.afterViewInitSubscription = timer(0).subscribe(() => {
      this.updateTheme();
    });
  }

  stopPeg() {
    if (this.peg === PegState.Enabled || this.peg === PegState.TemporaryDisabledByOrder) {
      this.peg = PegState.TemporaryDisabled;
      this.pegChange.emit(this.peg);
    }
  }

  stopPegByOrder() {
    if (this.peg === PegState.Enabled) {
      this.peg = PegState.TemporaryDisabledByOrder;
      this.pegChange.emit(this.peg);
    }
  }

  pegCheck(): boolean {
    if (this.peg !== PegState.Enabled) {
      return false;
    }
    if (!this.view) {
      return false;
    }
    if (!this.view.itaRowPriceIndex) {
      return false;
    }
    if (this.view.rows.length === 0) {
      return;
    }
    if (this.view.itaCenterPrice10Raw < this.view.rows[0].price10) {
      return true;
    } else if (this.view.itaCenterPrice10Raw > this.view.rows[this.view.rows.length - 1].price10) {
      return true;
    }
    return false;
  }

  doPeg() {
    const nextIndex =
      this.stockOperator.current.master.tick.price10ToIndex(this.view.itaCenterPrice10) - Math.trunc(this.view.itaRowCount / 2);
    if (this.view.itaRowPriceIndex < nextIndex) {
      this.setPegStatus(Side.Bid); // 上がる
    } else if (this.view.itaRowPriceIndex > nextIndex) {
      this.setPegStatus(Side.Ask); // 下がる
    }
    this.moveToPrice(this.view.itaCenterPrice10);
  }

  private setPegStatus(s: Side) {
    if (this.pegStatusSubscription) {
      this.pegStatusSubscription.unsubscribe();
      this.pegStatusSubscription = null;
    }
    this.pegStatus = s;
    this.stockOperator.update = true;
    if (s === null) {
      return;
    }
    this.pegStatusSubscription = timer(1000).subscribe(() => {
      this.setPegStatus(null);
    });
  }

  resetPosition() {
    if (this.view) {
      this.moveToPrice(this.view.itaCenterPrice10);
    }
  }

  focus() {
    if (this.grid) {
      this.grid.focus();
    }
  }

  onKeyDown(ev: KeyboardEvent) {
    if (ev.key === 'ArrowUp' || ev.key === 'Up') {
      // IE11: Up
      this.keyMove(1);
      ev.preventDefault();
    } else if (ev.key === 'ArrowDown' || ev.key === 'Down') {
      // IE11: Down
      this.keyMove(-1);
      ev.preventDefault();
    } else if (ev.key === 'PageUp') {
      this.buttonMove(1);
    } else if (ev.key === 'PageDown') {
      this.buttonMove(-1);
    } else if (ev.key === 'Control') {
      console.log('Control');
    }
  }

  onKeyUp(ev: KeyboardEvent) {
    if (ev.key === 'Control') {
      console.log('Ctrl Up');
    }
  }

  private keyMove(direction: number) {
    this.view.itaRowPriceIndex += direction * 1;
    if (this.view.itaRowPriceIndex <= 0) {
      this.view.itaRowPriceIndex = 1;
    }
    this.stopPeg();
    this.stockOperator.update = true;
  }

  onMouseLeave() {
    if (this.fixIta) {
      this.fixIta.clearRegisteredOrder(this.stockOperator);
    }
  }

  onDblClickTutorialComplete() {
    this.priceDblClickTutorialComplete.emit({});
  }
}
