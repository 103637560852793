import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowRefService } from '../brisk-browser/window-ref.service';
import { ResizeService } from '../resize/resize.service';
import { VgGridComponent } from '../vg-grid/vg-grid/vg-grid.component';

@Directive({
  selector: '[briskVgGridFlexibleRowSize]',
})
export class VgGridFlexibleRowSizeDirective implements OnInit, OnDestroy {
  @Input()
  public flexibleRowSizeCoefficient = 0.08;

  @Input()
  public updateRowSize = true;

  private resizeSubscription: Subscription = null;

  constructor(
    private grid: VgGridComponent,
    private elementRef: ElementRef,
    private window: WindowRefService,
    private resize: ResizeService
  ) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
  }

  ngOnInit(): void {
    this.onResize();
  }

  private onResize() {
    if (this.updateRowSize) {
      const lineHeight = this.window.nativeWindow.getComputedStyle(this.elementRef.nativeElement).lineHeight;
      if (!lineHeight) {
        return;
      }
      const rowSize = lineHeight === 'normal' ? 16 : Math.ceil(Number(lineHeight.slice(0, -2)));
      this.grid.rowHeight = rowSize + 2;
    }
    this.grid.updateSize(false);
  }
}
