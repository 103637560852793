import { Pipe, PipeTransform } from '@angular/core';
import { ColorConverterService } from './color-converter.service';
import { Side } from '../flex';

@Pipe({
  name: 'sideClass',
})
export class SideClassPipe implements PipeTransform {
  constructor(private colorConverterService: ColorConverterService) {}

  transform(side: number): any {
    if (side === Side.Ask) {
      return this.colorConverterService.priceChangeToClass(-1);
    } else {
      return this.colorConverterService.priceChangeToClass(1);
    }
  }
}
