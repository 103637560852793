import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private _themes = {
    light: '白',
    dark: '黒',
    'dark-inverse': '黒(赤緑反転)',
    ns: '黒2',
    black: '黒2',
  };
  private _theme: string = null;
  private themeChangedSubject = new Subject<string>();
  public get theme$(): Observable<string> {
    return this.themeChangedSubject.asObservable();
  }

  public get theme(): string {
    return this._theme;
  }

  public set theme(t: string) {
    if (t in this._themes) {
      if (this._theme !== t) {
        this.updateTheme(t);
      }
    }
  }

  constructor(private _platform: Platform) {
    this.updateTheme('light');
  }

  private updateTheme(theme: string) {
    if (this._theme === theme) {
      return;
    }
    const doc = window.document;
    if (this._theme) {
      doc.body.classList.remove(`theme-${this._theme}`);
    }
    if (theme) {
      doc.body.classList.add(`theme-${theme}`);
    }
    this._theme = theme;
    this.themeChangedSubject.next(theme);
  }

  ngOnDestroy(): void {
    this.themeChangedSubject.complete();
  }

  getName(theme: string) {
    return this._themes[theme];
  }

  getStyleValue(name: string): string | undefined {
    if (this._platform.FIREFOX) {
      const ret = getComputedStyle(document.body).getPropertyValue(`--brisk-theme-${name}`);
      if (!ret) {
        throw new Error(`Failed to get style value ${name}`);
      }
      return ret;
    } else {
      return undefined;
    }
  }
}
