import { ItaCaptureOperatorService, TimestampFormatPipe } from '@argentumcode/brisk-common';
import { StockView } from '@argentumcode/brisk-common';
import { format } from 'date-fns';
import { CocomeroService } from './cocomero.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BriskItaCaptureOperatorService extends ItaCaptureOperatorService {
  constructor(private op: CocomeroService, private timestampFormatPipe: TimestampFormatPipe) {
    super();
  }

  bottom(): string {
    return 'BRiSK / ArGentumCode K.K.';
  }

  cloneStockView(s: StockView): StockView {
    return this.op.op.cloneStockView(s);
  }

  filename(s: StockView): string {
    return (
      `brisk-ita-${s.master.name}-${format(this.op.op.date, 'YYYY-MM-DD')}-` +
      `${this.timestampFormatPipe.transform(s.timestamp, { microseconds: true }).replace(/[:.]/g, '')}.gif`
    );
  }

  goUpdateNumber(s: StockView, updateNumber: number): void {
    this.op.goUpdateNumber(s, updateNumber);
  }

  updateStockView(s: StockView): void {
    this.op.op.updateStockView(s);
  }

  workerFileName(): string {
    return '/assets/workers/gif.worker.js';
  }
}
