import { Injectable } from '@angular/core';
import { FlexExchange } from '@argentumcode/brisk-common';
import { environment } from '../../environments/default/environment';

export interface IssueTypeBase {
  issueType: number | number[];
  name: string;
}

export interface IssueTypeForSummaries extends IssueTypeBase {
  isForeign: boolean;
  visible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class IssueTypesService {
  private _issueTypesForSummaries: Readonly<Array<IssueTypeForSummaries>> = [];
  private _issueTypesForMapView: Readonly<Array<IssueTypeBase>> = [];

  constructor() {}

  issueTypesForSummaries(): Readonly<Array<IssueTypeForSummaries>> {
    return this._issueTypesForSummaries;
  }

  issueTypesForMapView(): Readonly<Array<IssueTypeBase>> {
    return this._issueTypesForMapView;
  }

  initialize(date: Date): void {
    if ((environment.flexConfig.exchange as FlexExchange) === FlexExchange.Fukuoka) {
      this._issueTypesForSummaries = [
        { issueType: 111, isForeign: false, name: '一部', visible: false },
        { issueType: 113, isForeign: true, name: '一部', visible: false },
        { issueType: 114, isForeign: false, name: 'Q-Board', visible: true },
        { issueType: [115, 116], isForeign: false, name: 'ETF', visible: true },
        { issueType: 117, isForeign: false, name: '優先出資証券', visible: false },
        { issueType: 119, isForeign: false, name: 'REIT', visible: true },
      ];
      this._issueTypesForMapView = [
        { issueType: [111, 113], name: '一部' },
        { issueType: 114, name: 'Q-Board' },
        { issueType: [115, 116], name: 'ETF' },
        { issueType: 117, name: '優先出資証券' },
        { issueType: 119, name: 'REIT' },
      ];
    } else {
      if (date.getTime() < new Date(2022, 3, 4).getTime()) {
        // 2022-04-04より前
        this._issueTypesForSummaries = [
          { issueType: 111, isForeign: false, name: '一部', visible: true },
          { issueType: 113, isForeign: true, name: '一部', visible: false },
          { issueType: 112, isForeign: false, name: '二部', visible: true },
          { issueType: 121, isForeign: true, name: '二部', visible: false },
          { issueType: 114, isForeign: false, name: 'マザーズ', visible: true },
          { issueType: 118, isForeign: true, name: 'マザーズ', visible: false },
          { issueType: [115, 116], isForeign: false, name: 'ETF', visible: true },
          // { issueType: 116, isForeign: true, name: 'ETF', visible: false },
          { issueType: 117, isForeign: false, name: '優先出資証券', visible: false },
          { issueType: 119, isForeign: false, name: 'REIT', visible: true },
          { issueType: 122, isForeign: false, name: 'PRO', visible: false },
          { issueType: 123, isForeign: true, name: 'PRO', visible: false },
          { issueType: 124, isForeign: false, name: 'JASDAQ S', visible: true },
          { issueType: 125, isForeign: true, name: 'JASDAQ S', visible: false },
          { issueType: 126, isForeign: false, name: 'JASDAQ G', visible: true },
          { issueType: 127, isForeign: true, name: 'JASDAQ G', visible: false },
        ];
        this._issueTypesForMapView = [
          { issueType: [111, 113], name: '一部' },
          { issueType: [112, 121], name: '二部' },
          { issueType: [114, 118], name: 'マザーズ' },
          { issueType: [115, 116], name: 'ETF' },
          { issueType: 119, name: 'REIT' },
          { issueType: [117, 122, 123], name: 'その他' },
          { issueType: [124, 125], name: 'JASDAQ S' },
          { issueType: [126, 127], name: 'JASDAQ G' },
        ];
      } else {
        // 2022-04-04以降
        this._issueTypesForSummaries = [
          { issueType: 111, isForeign: false, name: 'プライム', visible: true },
          { issueType: 113, isForeign: true, name: 'プライム', visible: false },
          { issueType: 112, isForeign: false, name: 'スタンダード', visible: true },
          { issueType: 121, isForeign: true, name: 'スタンダード', visible: false },
          { issueType: 114, isForeign: false, name: 'グロース', visible: true },
          { issueType: 118, isForeign: true, name: 'グロース', visible: false },
          { issueType: [115, 116], isForeign: false, name: 'ETF', visible: true },
          // { issueType: 116, isForeign: true, name: 'ETF', visible: false },
          { issueType: 117, isForeign: false, name: '優先出資証券', visible: false },
          { issueType: 119, isForeign: false, name: 'REIT', visible: true },
          { issueType: 122, isForeign: false, name: 'PRO', visible: false },
          { issueType: 123, isForeign: true, name: 'PRO', visible: false },
        ];
        this._issueTypesForMapView = [
          { issueType: [111, 113], name: 'プライム' },
          { issueType: [112, 121], name: 'スタンダード' },
          { issueType: [114, 118], name: 'グロース' },
          { issueType: [115, 116], name: 'ETF' },
          { issueType: 119, name: 'REIT' },
          { issueType: [117, 122, 123], name: 'その他' },
        ];
      }
    }
  }
}
