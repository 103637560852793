import { Observable } from 'rxjs';
import { ChartInfo } from './chart-info';
import { ObservableArray } from '@grapecity/wijmo';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { ChartDrawInfo1day, ChartDrawInfo1month, ChartDrawInfo1week, ChartDrawInfo5min } from './chart-draw-info';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export abstract class ChartDataSourceAbstract {
  abstract date: Date;
  ohlc5min: ObservableArray;
  ohlc1day: ObservableArray;
  ohlc1week: ObservableArray;
  ohlc1month: ObservableArray;
  line5minSegments: Array<ObservableArray>;
  line1daySegments: Array<ObservableArray>;
  line1weekSegments: Array<ObservableArray>;
  line1monthSegments: Array<ObservableArray>;
  line5minPoint: ObservableArray;
  line1dayPoint: ObservableArray;
  line1weekPoint: ObservableArray;
  line1monthPoint: ObservableArray;
  callbacks: Observable<{}>;
  chartInfo: ChartInfo;
  stockWrapper: StockWrapper;
  drawInfo: {
    '5min'?: ChartDrawInfo5min;
    '1day'?: ChartDrawInfo1day;
    '1week'?: ChartDrawInfo1week;
    '1month'?: ChartDrawInfo1month;
  };
}
