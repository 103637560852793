import { Directive, ElementRef, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';
import { AssistService } from './assist.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[briskAssist]',
  exportAs: 'briskAssist',
})
export class AssistDirective implements OnDestroy, OnInit {
  private assistSubscription: Subscription;
  private assistSetupSubscription: Subscription;
  private visible = false;
  private viewRef: EmbeddedViewRef<any>;
  public openable = false;

  constructor(
    private elementRef: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private assist: AssistService
  ) {}

  @Input() briskAssist: string;

  ngOnInit() {
    this.assistSubscription = this.assist.assist$.subscribe((a) => {
      if (a) {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        this.viewRef.detectChanges();
        if (this.viewRef.rootNodes[0] && this.viewRef.rootNodes[0].click) {
          this.viewRef.rootNodes[0].click();
        }
        this.visible = true;
      } else {
        if (this.visible) {
          this.viewRef.destroy();
        }
        this.visible = false;
        this.viewContainer.clear();
      }
    });
    this.assistSetupSubscription = this.assist.setup$.subscribe(() => {
      this.openable = this.assist.openable(this.briskAssist);
    });
  }

  ngOnDestroy(): void {
    if (this.assistSubscription) {
      this.assistSubscription.unsubscribe();
    }
    if (this.visible) {
      this.viewContainer.clear();
    }
    if (this.assistSetupSubscription) {
      this.assistSetupSubscription.unsubscribe();
    }
  }

  public openAssist(e?: Event) {
    this.assist.openAssist(this.briskAssist);
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
  }
}
