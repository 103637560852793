import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CellType, FlexGrid } from '@grapecity/wijmo.grid';
import {
  CancelEventArgs,
  Clipboard,
  Color,
  EventArgs,
  getActiveElement,
  ICollectionView,
  ObservableArray,
  Point,
  SortDescription,
} from '@grapecity/wijmo';
import {
  CellClickEvent,
  CellEditingEvent,
  CellHeaderClickEvent,
  EditingContext,
  FlexGridDisableScrollDirective,
  GroupHeader,
  InvalidIssueCodeEvent,
  KuroChartService,
  Portfolio as FlexPortfolio,
  ResizeService,
  RowDeletingEvent,
  RowDraggedEvent,
  RowDragStartEvent,
  StockInfo,
  StockMaster,
  Summary,
  SummaryPortfolio as FlexSummaryPortfolio,
  SummaryType,
  ThemeService,
  VgCollectionView,
  VgGridColumnsState,
  VgGridComponent,
  WindowRefService,
} from '@argentumcode/brisk-common';
import { FIXPosition } from '../../fix/fix.service';
import { Subscription } from 'rxjs';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { WjFlexGridFilter } from '@grapecity/wijmo.angular2.grid.filter';
import { SimpleOrderService } from '../../core/simple-order.service';
import { NewPortfolioEntry, Portfolio, StockPortfolio } from '../portfolio';
import { WjMenu } from '@grapecity/wijmo.angular2.input';
import { environment } from '../../../environments/default/environment';

const OHLC_LENGTH = environment.flexConfig.ohlcLength;

export enum Group {
  Registered,
  Smart,
  Financial,
  Segment,
  Industry,
  Other,
}

function rshift(number: number, precision: number, reverseShift: boolean) {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = ('' + number).split('e');
  return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
}

// From: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function round(number: number, precision: number) {
  return rshift(Math.round(rshift(number, precision, false)), precision, true);
}

export class SummaryPortfolioFromStock extends StockPortfolio {
  public constructor(
    index: number,
    public summary: Summary,
    private summaryPortfolio: FlexSummaryPortfolio,
    portfolio: FlexPortfolio,
    master: StockMaster,
    info: StockInfo
  ) {
    super(master, portfolio, info, null, index);
  }

  get name(): string {
    return this.summary.name;
  }

  get issueCount(): number {
    if (this.master.issueCode === 1321) {
      return 225;
    } else {
      return undefined;
    }
  }

  get uniqueIssueCount(): number {
    return this.summary.issueCount;
  }

  get hitCount(): number {
    return this.summaryPortfolio.hitCount;
  }

  get liftCount(): number {
    return this.summaryPortfolio.liftCount;
  }

  get upCount(): number {
    return this.summaryPortfolio.upCount;
  }

  get downCount(): number {
    return this.summaryPortfolio.downCount;
  }

  get openPriceBasePriceChange(): number {
    return this.summaryPortfolio.openPriceBasePriceChange;
  }

  get openPriceBasePriceChangePredict(): boolean {
    return this.summaryPortfolio.predictOpenPrice;
  }

  get vwapPredict(): boolean {
    return this.summaryPortfolio.vwapPredict;
  }

  get disabledHitCount(): boolean {
    return false;
  }

  set disabledHitCount(_) {}

  get group(): Group {
    return Group.Segment;
  }

  set group(_) {}

  get turnover10ForSummary(): number {
    const ret = this.turnover10;
    if (ret === 0 || ret === undefined || ret == null) {
      return null;
    }
    if (ret >= 1) {
      return round(ret, 0);
    } else {
      return round(ret, 2);
    }
  }

  set turnover10ForSummary(_) {}
}

export class SummaryPortfolio extends Portfolio {
  private _lastPriceChanges: ObservableArray = null;
  private _showDetails = false;

  public constructor(public index: number, public summary: Summary, private portfolio: FlexSummaryPortfolio) {
    super();
    this._showDetails = summary.type !== SummaryType.Market && summary.type !== SummaryType.SmartList;
  }

  highlightName: boolean;

  get lastPriceBasePriceChange(): number {
    if (!this._showDetails) {
      return null;
    }
    return this.portfolio.lastPriceChange;
  }

  set lastPriceBasePriceChange(_) {}

  get name(): string {
    return this.summary.name;
  }

  set name(_) {}

  get priceBasePriceChanges(): ObservableArray {
    if (this._lastPriceChanges === null) {
      this._lastPriceChanges = new ObservableArray(this.portfolio.lastPriceChanges);
    } else {
      this._lastPriceChanges.deferUpdate(() => {
        for (let x = 0; x < OHLC_LENGTH; x++) {
          this._lastPriceChanges[x] = this.portfolio.lastPriceChanges[x];
        }
      });
    }
    return this._lastPriceChanges;
  }

  set priceBasePriceChanges(_) {}

  get turnover10(): number {
    if (!this._showDetails) {
      return null;
    }
    return this.portfolio && this.portfolio.turnover10 / 1000000000;
  }

  set turnover10(_) {}

  get volume(): number {
    return this.portfolio && this.portfolio.volume;
  }

  set volume(_) {}

  get vwapPriceChange(): number {
    if (!this._showDetails) {
      return null;
    }
    return this.portfolio.vwapChange;
  }

  set vwapPriceChange(_) {}

  get predict(): boolean {
    if (!this._showDetails) {
      return false;
    }
    return this.portfolio && this.portfolio.predict;
  }

  set predict(_) {}

  get issueCount(): number {
    return this.summary.issueList.length;
  }

  set issueCount(_) {}

  get hitCount(): number {
    return this.portfolio && this.portfolio.hitCount;
  }

  set hitCount(_) {}

  get liftCount(): number {
    return this.portfolio && this.portfolio.liftCount;
  }

  set liftCount(_) {}

  get uniqueIssueCount(): number {
    return this.summary.issueCount;
  }

  set uniqueIssueCount(_) {}

  get upCount(): number {
    return this.portfolio && this.portfolio.upCount;
  }

  set upCount(_) {}

  get downCount(): number {
    return this.portfolio && this.portfolio.downCount;
  }

  set downCount(_) {}

  get simpleAverage(): boolean {
    return true;
  }

  set simpleAverage(_) {}

  get turnoverPredict(): boolean {
    return this.portfolio && this.portfolio.turnoverPredict;
  }

  set turnoverPredict(_) {}

  get openPriceBasePriceChange(): number {
    return this.portfolio && this.portfolio.openPriceBasePriceChange;
  }

  set openPriceBasePriceChange(_) {}

  get openPriceBasePriceChangePredict(): boolean {
    return this.portfolio && this.portfolio.predictOpenPrice;
  }

  set openPriceBasePriceChangePredict(_) {}

  get vwapPredict(): boolean {
    return this.portfolio && this.portfolio.vwapPredict;
  }

  set vwapPredict(_) {}

  get disabledHitCount(): boolean {
    return !this._showDetails;
  }

  set disabledHitCount(_) {}

  get editable(): boolean {
    return this.summary && this.summary.type === SummaryType.WatchList;
  }

  set editable(_) {}

  get group(): Group {
    if (
      this.summary.type === SummaryType.WatchList ||
      this.summary.type === SummaryType.History ||
      this.summary.type === SummaryType.OrderHistory
    ) {
      return Group.Registered;
    } else if (this.summary.type === SummaryType.Market || this.summary.type === SummaryType.SmartList) {
      return Group.Smart;
    } else if (this.summary.type === SummaryType.Stock || this.summary.type === SummaryType.Index) {
      return Group.Segment;
    } else if (this.summary.type === SummaryType.Master) {
      return Group.Other;
    } else if (this.summary.type === SummaryType.Industry) {
      return Group.Industry;
    } else if (this.summary.type === SummaryType.Server) {
      return Group.Financial;
    }
  }

  set group(_) {}

  get turnover10ForSummary(): number {
    const ret = this.turnover10;
    if (ret === 0 || ret === undefined || ret == null) {
      return null;
    }
    if (ret >= 1) {
      return round(ret, 0);
    } else {
      return round(ret, 2);
    }
  }

  set turnover10ForSummary(_) {}
}

export class TradeSummaryPortfolio extends StockPortfolio {
  public constructor(master: StockMaster, portfolio: FlexPortfolio, info: StockInfo, index: number, public position: FIXPosition) {
    super(master, portfolio, info, null, index);
  }

  protected get markPrice10(): number {
    return this.portfolio
      ? this.portfolio.lastPrice10 === 0
        ? this.portfolio.quotePrice10 === 0
          ? this.master.basePrice10
          : this.portfolio.quotePrice10
        : this.portfolio.lastPrice10
      : 0;
  }

  get calculatedMarkPrice10(): number {
    return this.portfolio
      ? this.portfolio.lastPrice10 === 0
        ? this.portfolio.predictPrice10 === 0
          ? this.master.basePrice10
          : this.portfolio.predictPrice10
        : this.portfolio.lastPrice10
      : 0;
  }

  get pos(): number {
    return this.position ? this.position.buyFilledQuantity.value - this.position.sellFilledQuantity.value : 0;
  }

  get delta(): number {
    const markPrice10 = this.markPrice10;
    return this.position ? (this.pos * markPrice10) / 10 / 1000000 : 0;
  }

  private calcPositionPL(markPrice10: number): number {
    return this.position ? (this.position.beginPosition.value * (markPrice10 - this.master.basePrice10)) / 10 : 0;
  }

  private calcExecutionPL(markPrice10: number): number {
    return this.position
      ? ((this.position.buyFilledQuantity.value - this.position.sellFilledQuantity.value) * markPrice10 -
          (this.position.buyFilledAmount10.value - this.position.sellFilledAmount10.value)) /
          10
      : 0;
  }

  get positionPL(): number {
    return this.calcPositionPL(this.markPrice10);
  }

  get calculatedPositionPL(): number {
    return this.calcPositionPL(this.calculatedMarkPrice10);
  }

  get executionPL(): number {
    return this.calcExecutionPL(this.markPrice10);
  }

  get calculatedExecutionPL(): number {
    return this.calcExecutionPL(this.calculatedMarkPrice10);
  }

  get pl(): number {
    return this.positionPL + this.executionPL;
  }

  get buyFilledQuantity(): number {
    return this.position ? this.position.buyFilledQuantity.value : 0;
  }

  get sellFilledQuantity(): number {
    return this.position ? this.position.sellFilledQuantity.value : 0;
  }

  get buyWorkingQuantity(): number {
    return this.position ? this.position.buyWorkingQuantity.value : 0;
  }

  get sellWorkingQuantity(): number {
    return this.position ? this.position.sellWorkingQuantity.value : 0;
  }

  get filledAmount(): number {
    return this.position ? (this.position.buyFilledAmount10.value + this.position.sellFilledAmount10.value) / 10 / 1000000 : 0;
  }

  get buyFilledAmount(): number {
    return this.position ? this.position.buyFilledAmount10.value / 10 / 1000000 : 0;
  }

  get sellFilledAmount(): number {
    return this.position ? this.position.sellFilledAmount10.value / 10 / 1000000 : 0;
  }

  get buyWorkingAmount(): number {
    return this.position ? this.position.buyWorkingAmount10.value / 10 / 1000000 : 0;
  }

  get sellWorkingAmount(): number {
    return this.position ? this.position.sellWorkingAmount10.value / 10 / 1000000 : 0;
  }
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioComponent implements OnInit, OnDestroy {
  @ViewChild('portfolio')
  public portfolio: FlexGrid;

  @ViewChild('themeElement', { static: true })
  public themeElement: ElementRef;

  @ViewChild('popupMenu', { static: true })
  public popupMenu: WjMenu;

  @Input()
  public dataSource: ICollectionView | VgCollectionView = null;

  @Input()
  public showIndex = true;

  @Input()
  public showIssueCode = true;

  @Input()
  public showSpecialQuote = false;

  @Input()
  public showOpenPriceChange = false;

  @Input()
  visibleOpenPriceChange = true;

  @Input()
  public showVolume = true;

  @Input()
  public showMarketCapitalization = false;

  @Input()
  public showIndustry = true;

  @Input()
  public showPrefix = true;

  @Input()
  public showPrice = false;

  @Input()
  public showVwap = true;

  @Input()
  public visibleVwap = true;

  @Input()
  public showTurnover10 = true;

  @Input()
  public visibleTurnover10 = true;

  @Input()
  public showLastPriceBasePriceChanges = true;

  @Input()
  public visibleLastPriceBasePriceChanges = true;

  @Input()
  public shortVolume = false;

  @Input()
  public showPriceBasePriceChanges = false;

  @Input()
  public nameHeader = '';

  @Input()
  public allowDelete = true;

  @Input()
  public updated = false;

  @Input()
  public editable = false;

  @Input()
  public nameEditable = false;

  @Input()
  public showTradeSummary = false;

  @Input()
  public showIssueCount = false;

  @Input()
  public showHitLiftBar = false;

  @Input()
  public orderButtonType: 'none' | 'single' | 'monex' | 'triple' = 'none';

  @Input()
  public allowSorting = true;

  @Input()
  public allowFilter = true;

  @Input()
  formerPriceChange = false;

  @Input()
  formerVwap = false;

  @Input()
  showLowPrice = false;

  @Input()
  showHighPrice = false;

  @Input()
  groupSort = false;

  @Input()
  sortDescriptorIndex: number = null;

  @Output()
  public issueCodeUpdated = new EventEmitter<[Portfolio, number]>();

  @Output()
  public nameUpdated = new EventEmitter<[Portfolio, string]>();

  @Output()
  public gridPaste = new EventEmitter<ClipboardEvent>();

  @Output()
  public cellDblClick = new EventEmitter<EventArgs>();

  @Output()
  public cellCtrlClick = new EventEmitter<any>();

  @Output()
  public cellClick = new EventEmitter<any>();

  @Output()
  public headerContextMenu = new EventEmitter<CellHeaderClickEvent>();

  @Output()
  public cellEditBeginning = new EventEmitter<CellEditingEvent>();

  @Output()
  public draggedRow = new EventEmitter<[number, number]>();

  @Output()
  public draggingRow = new EventEmitter<[Portfolio, CancelEventArgs]>();

  @Output()
  public invalidInput = new EventEmitter<InvalidIssueCodeEvent>();

  @Output()
  public initialized = new EventEmitter<{}>();

  @Output()
  public selectionChanged = new EventEmitter<Portfolio>();

  @Output()
  public rowDeleting = new EventEmitter<RowDeletingEvent>();

  @ViewChild(FlexGridDisableScrollDirective)
  public portfolioDisableScroll: FlexGridDisableScrollDirective;

  @ViewChild('filter')
  public filter: WjFlexGridFilter;

  @ViewChild('grid', { static: true })
  public grid: VgGridComponent;

  @Input()
  applyMinWidth = false;

  @Input()
  columnsState: VgGridColumnsState = undefined;

  hitColor: Color;
  liftColor: Color;
  rowSize = 24;

  private filterDefinition_ = '';
  private gridInitialized = false;

  private issueCodeUpdatedQueue: Array<[Portfolio, number]> = [];
  private nameUpdatedQueue: Array<[Portfolio, string]> = [];
  private editing = false;

  private draggingRowIndex = 0;

  private resizeSubscription: Subscription = null;
  private themeSubscription: Subscription = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private hostElement: ElementRef,
    private resize: ResizeService,
    private window: WindowRefService,
    private simpleOrder: SimpleOrderService,
    private theme: ThemeService,
    private kuroChart: KuroChartService
  ) {
    this.resizeSubscription = this.resize.resize.subscribe(() => {
      this.onResize();
    });
    this.themeSubscription = this.theme.theme$.subscribe(() => {
      this.updateTheme();
    });
  }

  onInitialized(grid: FlexGrid, filter: FlexGridFilter) {
    this.gridInitialized = true;
    this.onResize();
    this.initialized.emit();
  }

  ngOnInit() {
    this.updateTheme();
  }

  refresh(fullUpdate = false) {
    this.changeDetectorRef.markForCheck();
  }

  onDeletingRow(e): void {
    if (this.allowDelete) {
      e.cancel = false;
    } else {
      e.cancel = true;
    }
  }

  onBeginningEdit(e: CellEditingEvent): void {
    this.cellEditBeginning.emit(e);
    this.editing = true;
  }

  onActiveEditorKeyDownImpl(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onStockSelected(editingContext: EditingContext, issueCode: number) {
    editingContext.value = issueCode;
    this.grid.endCellEditing();
  }

  onCellEditEnding(e: CellEditingEvent): void {
    this.editing = false;
    if (this.editable) {
      this.issueCodeUpdated.emit([e.item, e.editingContext.value]);
    } else if (this.nameEditable) {
      this.nameUpdated.emit([e.item, e.editingContext.value]);
    }
  }

  onKeyDown(sender: VgGridComponent, e: KeyboardEvent) {
    const ctrl = e.ctrlKey || e.metaKey;
    const shift = e.shiftKey;

    // IE対応のためにkeyCodeを利用する
    if ((ctrl && e.key === 'v') || (shift && e.key === 'Insert')) {
      if (!sender.editingItem) {
        this.emitPaste();
      }
    } else if (ctrl && e.key === 'c') {
      if (!sender.editing) {
        this.copy(sender);
      }
    }
  }

  scrollToTop() {
    this.grid.scrollToTop();
  }

  onCellDblClick(e: CellClickEvent) {
    if (e.column.name === 'Index' || (this.editable && e.column.name === 'issueCode') || this.grid.editing) {
      return;
    }
    this.cellDblClick.emit(EventArgs.empty);
  }

  onClick(e: CellClickEvent) {
    if (e.event.ctrlKey || e.event.metaKey) {
      if (e.event.button === 0) {
        this.cellCtrlClick.next([e.item, e.event]);
      }
    }
    if (e.event.button === 0) {
      this.cellClick.emit();
    }
  }

  onSortingColumn(sender: FlexGrid, e: Event) {
    sender.select(-1, -1);
  }

  scrollToBottom() {
    const rc = this.portfolio.cells.getCellBoundingRect(this.portfolio.rows.length - 1, 0, true);
    this.portfolio.scrollPosition = new Point(0, -rc.top);
  }

  private emitPaste() {
    const activeElement = getActiveElement();
    const host = this.hostElement.nativeElement;
    const el = document.createElement('textarea');
    el.style.position = 'fixed';
    el.style.opacity = '0';
    host.appendChild(el);
    el.select();
    el.onkeydown = (ev) => {
      ev.preventDefault();
    };
    el.onpaste = (ev) => {
      this.gridPaste.emit(ev);
    };
    setTimeout(() => {
      activeElement.focus();
      host.removeChild(el);
    }, 100);
  }

  onSelectionChanging() {
    this.portfolioDisableScroll.forceScrollDisable = false;
  }

  // onSelectionChanged() {
  //   this.portfolioDisableScroll.forceScrollDisable = true;
  // }

  onDraggedRow(row: RowDraggedEvent) {
    this.draggedRow.emit([row.draggedIndex, row.index]);
  }

  onDraggingRow(event: RowDragStartEvent) {
    if (this.dataSource instanceof VgCollectionView) {
      if (this.dataSource.sortDescriptions.length > 0 || this.dataSource.filterDescriptions.length > 0) {
        // ソートもしくはフィルタされている時の並び替えは無意味
        event.cancel = true;
        return;
      }
    }
    if (event.item instanceof NewPortfolioEntry) {
      event.cancel = true;
      return;
    }
  }

  copy(grid: VgGridComponent) {
    if (this.showIssueCode) {
      let issueCodes = '';
      for (const row of grid.getSelectionItems()) {
        if (row.issueCode) {
          issueCodes += row.issueCode.toString() + '\n';
        }
      }
      Clipboard.copy(issueCodes);
    }
  }

  onResize() {
    const lineHeight = this.window.nativeWindow.getComputedStyle(document.body).lineHeight;
    if (!lineHeight) {
      return;
    }
  }

  selectedItems(): any[] {
    return this.grid.getSelectionItems();
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = null;
    }
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
      this.themeSubscription = null;
    }
  }

  onFilterChanged(filter: FlexGridFilter) {}

  updateTheme() {
    this.hitColor = new Color(this.getStyleByClassName('c-portfolio__hit', 'portfolio-hit-background-color'));
    this.liftColor = new Color(this.getStyleByClassName('c-portfolio__lift', 'portfolio-lift-background-color'));
    this.changeDetectorRef.markForCheck();
  }

  getStyleByClassName(className: string, colorName: string): string {
    const color = this.theme.getStyleValue(colorName);
    if (color) {
      return color;
    }
    const elem: HTMLSpanElement = this.themeElement.nativeElement;
    elem.className = className;
    const style = getComputedStyle(elem).backgroundColor;
    elem.className = 'd-none';
    return style;
  }

  order(event: Event, issueCode: number, orderType?: string) {
    if (issueCode) {
      return this.simpleOrder.orderWithIssueCode(issueCode, orderType);
    }
  }

  onClickForSort(flexGrid: FlexGrid, event: MouseEvent) {
    const ht = flexGrid.hitTest(event.pageX, event.pageY);
    if (ht.cellType === CellType.ColumnHeader) {
      const column = flexGrid.columns[ht.col];
      if (this.allowSorting && column.allowSorting) {
        this.customSort(flexGrid, column.binding, event.ctrlKey);
      }
    }
  }

  customSort(flexGrid: FlexGrid, columnName: string, ctrlKey: boolean) {
    const sds = flexGrid.collectionView.sortDescriptions;
    if (ctrlKey) {
      sds.clear();
      return;
    }
    if (this.groupSort) {
      if (sds.length !== 2 || sds[1].property !== columnName) {
        sds.clear();
        sds.push(new SortDescription('group', true));
        sds.push(new SortDescription(columnName, true));
      } else {
        const newSd = new SortDescription(columnName, !sds[1].ascending);
        sds.clear();
        sds.push(new SortDescription('group', true));
        sds.push(newSd);
      }
    } else {
      if (sds.length === 0 || sds[0].property !== columnName) {
        sds.clear();
        sds.push(new SortDescription(columnName, true));
      } else {
        const newSd = new SortDescription(columnName, !sds[0].ascending);
        sds.clear();
        sds.push(newSd);
      }
    }
  }

  onItemsSourceChanged(grid: FlexGrid) {
    // for (let i = 0; i < grid.rows.length; i++) {
    //   if (grid.rows[i].hasChildren) {
    //     grid.rows[i].isCollapsed = grid.rows[i].dataItem.groupDescription.defaultCollapsed(grid.rows[i].dataItem.items);
    //   }
    // }
  }

  showOpenChart(): boolean {
    return (
      this.showPrice &&
      this.kuroChart.initialized &&
      this.grid.selectedItem &&
      this.grid.selectedItem['issueCode'] &&
      this.grid.getSelectionItems().length === 1
    );
  }

  showDelete(): boolean {
    return this.allowDelete;
  }

  deleteItem() {
    this.grid.deleteSelected();
  }

  openChart() {
    if (this.grid.selectedItem && this.grid.selectedItem['issueCode']) {
      this.kuroChart.openChart(Number(this.grid.selectedItem['issueCode']));
    }
  }

  focus() {
    this.grid.focus();
  }

  onNameChange(sender: FlexGrid, name: string) {
    if (sender.activeEditor) {
      sender.activeEditor.value = name;
    }
  }

  onSelectionChanged() {
    if (this.grid.selectedItem) {
      this.selectionChanged.emit(this.grid.selectedItem);
    }
  }

  updateCollapsed(item: GroupHeader) {
    item.collapsed = !item.collapsed;
    if (this.dataSource instanceof VgCollectionView) {
      this.dataSource.update();
    }
  }

  onRowDeleting(event: RowDeletingEvent) {
    this.rowDeleting.emit(event);
  }

  onIssueCodeInputLostFocus() {
    this.grid.endCellEditing(true);
  }

  setCurrentItem(item: any) {
    this.grid.setCurrentItem(item);
  }

  getCurrentItem(): any {
    return this.grid.selectedItem;
  }

  scrollIntoView() {
    this.grid.scrollIntoView();
  }

  onCellHeaderClick(event: CellHeaderClickEvent) {
    if (event.column.name === 'index' || event.column.name === 'hitLiftBar') {
      if (this.dataSource instanceof VgCollectionView) {
        if (this.sortDescriptorIndex === null) {
          this.dataSource.sortDescriptions.splice(0, this.dataSource.sortDescriptions.length);
        } else {
          this.dataSource.sortDescriptions.splice(this.sortDescriptorIndex, this.sortDescriptorIndex, null);
        }
        this.dataSource.update();
      }
    }
  }
}
