import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSourceStock } from '../chart-data-source-stock';
import { ChartComponent } from '../chart/chart.component';
import { ChartSource } from '../chart-source';
import { LocalStorageService } from '../../brisk-browser/local-storage.service';

@Component({
  selector: 'brisk-selectable-chart',
  templateUrl: './selectable-chart.component.html',
  styleUrls: ['./selectable-chart.component.scss'],
})
export class SelectableChartComponent implements OnInit {
  @Input()
  get normalized(): boolean {
    return this._normalized;
  }

  set normalized(value: boolean) {
    if (this._normalized !== value) {
      this._normalized = value;
      if (this.saveToLcalStorage) {
        this.localStorage.setItem('chart_normalized', value ? '1' : '0');
      }
      this.normalizedChange.emit(this._normalized);
    }
  }

  @Output()
  normalizedChange = new EventEmitter<boolean>();

  @ViewChild('chart', { static: true })
  public chartComponent: ChartComponent;

  @Input()
  set chartSource(chartSource: ChartSource) {
    if (chartSource) {
      this.dataSource = new ChartDataSourceStock(chartSource);
      this._chartSource = chartSource;
    }
  }

  get chartSource(): ChartSource {
    return this._chartSource;
  }

  /**
   * 新規ユーザーの値段のy軸を%表示にするかどうか
   *
   * @default true
   */
  @Input()
  public defaultNormalized = true;

  @Input()
  public nonProportional = false;

  private _normalized = true;

  /**
   * 価格表示・正規化表示切り替えのチェックボックスを表示するかどうか
   *
   * @default true
   */
  @Input()
  showNormalizedPriceCheckbox = true;

  @Input()
  showNk225Checkbox = false;

  @Input()
  disableLocalStorage = false;

  /**
   * 現在このコントロールを表示しているかどうか
   */
  @Input()
  shown = false;

  get priceMode(): boolean {
    return !this.normalized;
  }

  set priceMode(value: boolean) {
    this.normalized = !value;
  }

  /**
   * 日経225チャートを表示するか
   */
  private _showNk225 = false;

  @Input()
  get showNk225(): boolean {
    return this._showNk225;
  }

  set showNk225(value: boolean) {
    if (this._showNk225 !== value) {
      this._showNk225 = value;
      if (this.saveToLcalStorage) {
        this.localStorage.setItem('chart_show_nk225', value ? '1' : '0');
      }
      this.showNk225Change.emit(value);
    }
  }

  @Output()
  showNk225Change = new EventEmitter<boolean>();

  public dataSource: ChartDataSourceStock;
  private _chartSource: ChartSource;

  @Input()
  type = '5min';

  @Output()
  typeChange = new EventEmitter<string>();

  private saveToLcalStorage = false;

  constructor(private localStorage: LocalStorageService) {}

  ngOnInit() {
    if (!this.disableLocalStorage) {
      const normalized = this.localStorage.getItem('chart_normalized');
      this._normalized = normalized !== null && normalized !== undefined ? normalized === '1' : this.defaultNormalized;
      const showNk225 = this.localStorage.getItem('chart_show_nk225');
      this._showNk225 = showNk225 !== null && showNk225 !== undefined ? showNk225 === '1' : true;
      this.saveToLcalStorage = true;
    }
  }

  refresh() {
    this.chartComponent.refresh();
  }
}
