import { Injectable } from '@angular/core';
import { OrderOperator, OrderUIConfig } from '../types/interfaces';
import { NEVER, Observable, of } from 'rxjs';
import { StockOrderSummaryForHeader } from '@argentumcode/brisk-common';

@Injectable()
export class NoopOrderOperatorService implements OrderOperator {
  initialized: Observable<void> = of(void 0);

  updateStock: Observable<number | null> = NEVER;

  config: OrderUIConfig = {
    orderEnabled: false,
    showBrowserDialog: false, // 元々発注できないので、ダイアログも表示しない
  };

  config$ = of(this.config);

  reconnecting$ = of(false);

  constructor() {}

  getStockInfo(issueCode: number): Observable<StockOrderSummaryForHeader> {
    return of({});
  }
}
