import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ItaViewItem } from '../ita-portfolio/ita-portfolio.component';
import { ItaViewService } from '../../core/ita-view.service';
import { ItaComponent, PegState, StockWrapper, TimeProvider } from '@argentumcode/brisk-common';
import { Observable, Subscription } from 'rxjs';
import { StockView } from '@argentumcode/brisk-common';

@Component({
  selector: 'app-ita-portfolio-item',
  templateUrl: './ita-portfolio-item.component.html',
  styleUrls: ['./ita-portfolio-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItaPortfolioItemComponent implements OnInit, OnDestroy {
  @ViewChild('ita')
  public ita: ItaComponent;
  @Output()
  public itemClicked = new EventEmitter();
  @Output()
  public itemDblClicked = new EventEmitter();
  @Output()
  public gridHeightChanged = new EventEmitter();

  @Input()
  public normalized: boolean;

  @Input()
  public rows: number;

  @Output()
  public normalizedChange = new EventEmitter<boolean>();

  private id;
  number = null;
  public stockWrapper: StockWrapper;
  private updatedViewSubscription: Subscription = null;
  private itaRowIndexUpdatedSubscription: Subscription = null;

  get view(): StockView {
    return this.stockWrapper ? this.stockWrapper.current : null;
  }

  @Input()
  set updatedView(updatedView: Observable<any>) {
    if (this.updatedViewSubscription !== null) {
      this.updatedViewSubscription.unsubscribe();
      this.updatedViewSubscription = null;
    }
    if (updatedView) {
      this.updatedViewSubscription = updatedView.subscribe(() => {
        if (this.ita) {
          this.ita.updateSize();
        }
      });
    }
  }

  @Input()
  get item(): ItaViewItem {
    return this._item;
  }

  set item(value: ItaViewItem) {
    if (this._item === value) {
      return;
    }
    this.discordStock();
    this._item = value;
    if (this._item) {
      [this.id, this.stockWrapper] = this.itaView.getStock(this.item.issueCode, false);
      this.stockWrapper.current.itaRowPriceIndex = this.item.itaRowIndex;
      this.itaRowIndexUpdatedSubscription = this.item.itaRowIndexUpdated.subscribe(() => {
        this.stockWrapper.current.itaRowPriceIndex = this.item.itaRowIndex;
      });
    }
  }

  @Input()
  public timeProvider: TimeProvider;

  private _item: ItaViewItem;

  @Input()
  public peg: PegState;

  @Output()
  public pegChange = new EventEmitter<PegState>();

  @Input()
  public shown = false;

  @Input()
  public itaWidth = 100;

  constructor(private itaView: ItaViewService, public readonly elementRef: ElementRef) {}

  private discordStock() {
    if (this.id !== null) {
      if (this.itaRowIndexUpdatedSubscription !== null) {
        this.itaRowIndexUpdatedSubscription.unsubscribe();
        this.itaRowIndexUpdatedSubscription = null;
      }
      if (this.stockWrapper) {
        this.item.itaRowIndex = this.stockWrapper.current.itaRowPriceIndex + this.stockWrapper.current.itaRowCount;
        this.item.onItaRowIndexUpdated();
      }
      this.itaView.removeStock(this.id);
      this.id = null;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.discordStock();
    if (this.updatedViewSubscription !== null) {
      this.updatedViewSubscription.unsubscribe();
      this.updatedViewSubscription = null;
    }
    if (this.itaRowIndexUpdatedSubscription !== null) {
      this.itaRowIndexUpdatedSubscription.unsubscribe();
      this.itaRowIndexUpdatedSubscription = null;
    }
  }
}
