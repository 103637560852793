import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { WjListBox, WjPopup } from '@grapecity/wijmo.angular2.input';
import { CocomeroService } from '../../core/cocomero.service';

class Summary {
  public summaryId;
  public name;
}

@Component({
  selector: 'app-show-index-dialog',
  templateUrl: './show-index-dialog.component.html',
  styleUrls: ['./show-index-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowIndexDialogComponent implements OnInit {
  @Output()
  public showIndex = new EventEmitter<Array<string>>();

  @ViewChild('showIndexDialog', { static: true })
  public showIndexDialog: WjPopup;

  public summaries: Array<Summary> = [];

  constructor(private cocomero: CocomeroService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  show() {
    this.summaries = this.cocomero.summaries.allInvisibleSummary().map((summary) => {
      const s = new Summary();
      s.name = summary.name;
      s.summaryId = summary.summaryId;
      return s;
    });
    this.showIndexDialog.show(true);
    this.changeDetectorRef.markForCheck();
  }

  showIndexes(dialog: WjPopup, listBox: WjListBox) {
    const ret: Array<string> = [];
    for (const item of listBox.checkedItems) {
      ret.push(item.summaryId);
    }
    this.showIndex.emit(ret);
    dialog.hide();
  }

  onCheckedItemChanged() {
    this.changeDetectorRef.markForCheck();
  }
}
