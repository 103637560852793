<brisk-canvas-grid
  #grid
  [ngClass]="miniMode ? 'u-mini-ita' : ''"
  [cursorStyle]="cursorStyle"
  (cellMouseDown)="onCellMouseDown($event)"
  (cellMouseUp)="onCellMouseUp($event)"
  (cellMouseEnter)="onCellMouseEnter($event)"
  (cellMouseLeave)="onCellMouseLeave($event)"
  (cellDblClick)="onCellDblClick($event)"
  (headerCellDblClick)="onHeaderCellDblClick($event)"
  (cellRender)="onCellRender($event)"
  (wheel)="onMouseWheel($event)"
  (sizeUpdated)="onSizeUpdated()"
  (drawing)="onDrawing($event)"
  [fixedRowCount]="fixedRows && true"
  [headerRowCount]="2"
  [footerRowCount]="1"
  [showScrollbar]="showScrollbar"
  [fixedWidth]="itaWidth"
  (scrollChange)="onScrollChanged($event)"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (mouseleave)="onMouseLeave()"
>
  <brisk-canvas-grid-style id="cellStyle" type="Cell"></brisk-canvas-grid-style>
  <brisk-canvas-grid-style id="headerStyle" type="Header"></brisk-canvas-grid-style>

  <brisk-canvas-grid-column [enable]="!miniMode && showTotal" header="売累計" width="*" name="askTotal"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column [enable]="!miniMode && showOrder" header="売件数" width="0.6*" name="askOrder"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column
    [enable]="!miniMode && showMyOrder"
    [bold]="true"
    header="自売引"
    width="*"
    name="askCloseFixOrder"
  ></brisk-canvas-grid-column>
  <brisk-canvas-grid-column [enable]="!miniMode && showClose" header="売引" width="*" name="askClose"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column
    [enable]="!miniMode && showMyOrder"
    [bold]="true"
    header="自売"
    width="*"
    name="askFixOrder"
  ></brisk-canvas-grid-column>
  <brisk-canvas-grid-column header="売" width="*" name="ask"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column header="" width="0.2*" name="askState"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column header="値段" width="*" name="price"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column header="" width="0.2*" name="bidState"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column header="買" width="*" name="bid"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column
    [enable]="!miniMode && showMyOrder"
    [bold]="true"
    header="自買"
    width="*"
    name="bidFixOrder"
  ></brisk-canvas-grid-column>
  <brisk-canvas-grid-column [enable]="!miniMode && showClose" header="買引" width="*" name="bidClose"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column
    [enable]="!miniMode && showMyOrder"
    [bold]="true"
    header="自買引"
    width="*"
    name="bidCloseFixOrder"
  ></brisk-canvas-grid-column>
  <brisk-canvas-grid-column [enable]="!miniMode && showOrder" header="買件数" width="0.6*" name="bidOrder"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column [enable]="!miniMode && showTotal" header="買累計" width="*" name="bidTotal"></brisk-canvas-grid-column>
  <brisk-canvas-grid-column
    [enable]="!miniMode && showVolume"
    [header]="showVolumeChartOnly ? ' ' : '出来高'"
    [width]="showVolumeChartOnly ? '0.4*' : '*'"
    name="fillQuantity"
  ></brisk-canvas-grid-column>
</brisk-canvas-grid>

<span #themeElement class="p-theme-element"></span>

<ng-container *ngIf="!miniMode">
  <ng-template briskAssist="ita-dbl-click" #assist1="briskAssist">
    <button
      type="button"
      class="c-assist-popover-button p-assist-ita-dbl-click"
      placement="right"
      [ngbPopover]="popContent"
      popoverClass="c-assist-popover c-assist-popover--default c-assist-popover--ita"
    ></button>

    <ng-template #popContent>
      <div class="c-assist-popover__description">
        <p class="c-assist-popover__text c-assist-popover__text--broadened">
          ・値段の列をダブルクリックで板中心リセット<br />・マウスホイールでスクロール<br />・▲OVER, ▼UNDRで１ページ分ジャンプ<br />・自動板中心値チェックで表示範囲が値動きに追随
        </p>
      </div>
      <div class="c-assist-popover__buttons">
        <a class="c-assist-popover__button" (click)="assist1.openAssist($event)" href="#">
          <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
          もっと詳しく
        </a>
      </div>
    </ng-template>
  </ng-template>

  <ng-template briskAssist="ita-wheel-click" #assist2="briskAssist">
    <button
      type="button"
      class="c-assist-popover-button p-assist-ita-wheel-click"
      placement="left"
      [ngbPopover]="popContent2"
      popoverClass="c-assist-popover c-assist-popover--default c-assist-popover--small"
    ></button>

    <ng-template #popContent2>
      <div class="c-assist-popover__container">
        <img class="c-assist-popover__img" src="assets/images/wheel.png" />
        <div>
          <div class="c-assist-popover__description">
            <p class="c-assist-popover__text">
              マウスホイールをクリックで<br />
              値段⇔騰落率<br />
              株数⇔金額（単位：百万円）<br />をカンタン切替
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>

<brisk-first-tutorial
  *ngIf="enableScrollTutorial && shown"
  #scrollTutorial
  class="p-scroll-tutorial"
  placement="bottom"
  imagePath="assets/images/wheel.png"
  storageKey="ita_scroll"
>
  板はマウスホイールを利用してスクロールできます。
</brisk-first-tutorial>

<brisk-first-tutorial
  *ngIf="enableDblClickTutorial && shown"
  #dblClickTutorial
  class="p-dblclick-tutorial"
  storageKey="ita_dblclick"
  (closed)="onDblClickTutorialComplete()"
>
  値段の列をダブルクリックすることで、<br />板の中心値段にジャンプします。
</brisk-first-tutorial>
