<svg *ngIf="stockWrapper && stockWrapper.current" viewBox="0 0 380 44">
  <circle
    *ngIf="stockWrapper.current.master.loanMarginFlag === 1"
    cx="26"
    cy="17"
    r="3"
    class="p-loan-margin-1"
    [ngbTooltip]="tooltipLoanMargin"
  ></circle>
  <circle
    *ngIf="stockWrapper.current.master.loanMarginFlag === 2"
    cx="26"
    cy="17"
    r="3"
    class="p-loan-margin-2"
    [ngbTooltip]="tooltipLoanMargin"
  ></circle>
  <text class="stock-name" [attr.x]="40" [attr.y]="isEdge ? 22 : 16" dominant-baseline="central">
    {{ stockWrapper.current.master.issueCode }} {{ stockWrapper.current.master.name }}
  </text>
  <text class="turnover" [attr.x]="370" [attr.y]="isEdge ? 22 : 16" text-anchor="end" dominant-baseline="central" [ngbTooltip]="'売買代金'">
    <ng-container *ngIf="stockWrapper.current.turnover10 || stockWrapper.current.predictTurnover10">
      {{
        (stockWrapper.current.turnover10 || stockWrapper.current.predictTurnover10) / 1000000000
          | fastDecimal: { separateComma: true, fractionDigits: 2 }
          | predictTransform: stockWrapper.current.turnoverPredict
      }}
      億円
    </ng-container>
  </text>
  <text class="lastPrice" [attr.x]="40" [attr.y]="44">
    <tspan
      *ngIf="stockWrapper.current.master.exRightFlag"
      class="ex-right-flag"
      [ngbTooltip]="stockWrapper.current.master.exRightFlag | exRightFlag"
    >
      落&nbsp;
    </tspan>
    <tspan [ngbTooltip]="hasTspanTooltipBug ? null : tooltipPriceDetail">
      現値
      <ng-container *ngIf="stockWrapper.current.predict">(</ng-container>
      <tspan
        *ngIf="stockWrapper.current.predictLastPrice.quoteFlag | quoteSideStr: stockWrapper.current.predictLastPrice.quoteSide"
        [ngClass]="stockWrapper.current.predictLastPrice.quoteSide | sideClass"
      >
        {{ stockWrapper.current.predictLastPrice.quoteFlag | quoteSideStr: stockWrapper.current.predictLastPrice.quoteSide }}
      </tspan>
      {{ stockWrapper.current.predictLastPrice.lastPrice10 | formatPrice }}
      <ng-container *ngIf="stockWrapper.current.predict">)</ng-container>
    </tspan>
    /
    <tspan
      [ngClass]="stockWrapper.current.lastPriceBasePriceChange | priceChangeClass"
      [ngbTooltip]="hasTspanTooltipBug ? null : tooltipPriceChangeDetail"
    >
      {{ stockWrapper.current.lastPriceBasePriceChange | priceChanges | predictTransform: stockWrapper.current.predict }}
    </tspan>
    <ng-container *ngIf="stockWrapper.current.lastPrice10">
      /
      <tspan
        [ngClass]="stockWrapper.current.vwapPriceChange | priceChangeClass"
        [ngbTooltip]="hasTspanTooltipBug ? null : tooltipVwapDetail"
      >
        {{ stockWrapper.current.vwapPriceChange | priceChanges | predictTransform: stockWrapper.current.predict }}
      </tspan>
    </ng-container>
  </text>
</svg>
<brisk-mini-chart
  [quantityHeight]="0"
  [lastPriceChangesHeight]="150"
  [fontSize]="14"
  [paddingLeft]="45"
  [innerWidth]="325"
  [paddingRight]="10"
  [paddingBottom]="10"
  [showXTick]="false"
  [showXGrid]="false"
  [innerXPadding]="0"
  [xTickLength]="7"
>
  <brisk-mini-chart-from-stock-view [stockWrapper]="stockWrapper"></brisk-mini-chart-from-stock-view>
</brisk-mini-chart>
<ng-template #tooltipLoanMargin>
  ● 貸借銘柄<br />
  ○ 貸借融資銘柄（制度信用買のみ可能）
</ng-template>
<ng-template #tooltipPrice>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { current: '' }"></ng-container>
</ng-template>
<ng-template #tooltipPriceDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { current: 'price' }"></ng-container>
</ng-template>
<ng-template #tooltipPriceChangeDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { current: 'change' }"></ng-container>
</ng-template>
<ng-template #tooltipVwapDetail>
  <ng-container *ngTemplateOutlet="tooltipPriceBase; context: { current: 'vwap' }"></ng-container>
</ng-template>

<ng-template #tooltipPriceBase let-vwap="vwap" let-current="current">
  <span [class.tooltip-price-active]="current == 'price'">直近取引値段</span>
  /
  <span [class.tooltip-price-active]="current == 'change'">騰落率</span>
  /
  <span [class.tooltip-price-active]="current == 'vwap'">VWAP比</span>
  <br />
  カ：買特別気配<br />
  ウ：売特別気配<br />
  （　）内の数値は予想値<br />
  騰落率は基準値段からの変化率
</ng-template>
