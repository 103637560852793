import { Directive, Input, TemplateRef } from '@angular/core';
import { EditingContext } from './vg-grid/vg-grid.component';
import { Observable } from 'rxjs';
import { VgCollectionView, VgStreamView } from './collections/data-collecton';

export type CellType = 'Cell' | 'CellEdit' | 'GroupHeader';

export interface CellTemplateContext<T> {
  $implicit: T;
  item: T;
  editingContext: EditingContext;
}

@Directive({
  selector: '[briskVgGridCellTemplate]',
})
export class VgGridCellTemplateDirective<T> {
  @Input()
  cellType: CellType = 'Cell';

  @Input()
  briskVgGridCellTemplateSource: Array<T> | Observable<Array<T>> | VgCollectionView<T> | VgStreamView<T>;

  @Input()
  get briskVgGridCellTemplateCellType(): CellType {
    return this.cellType;
  }

  set briskVgGridCellTemplateCellType(cellType: CellType) {
    this.cellType = cellType;
  }

  constructor(private templateRef: TemplateRef<CellTemplateContext<T>>) {}

  get template(): TemplateRef<CellTemplateContext<T>> {
    return this.templateRef;
  }

  static ngTemplateContextGuard<T>(dir: VgGridCellTemplateDirective<T>, ctx: unknown): ctx is CellTemplateContext<T> {
    return true;
  }
}
