import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { concat, Observable, Observer, of, Subscription } from 'rxjs';
import { WjPopup } from '@grapecity/wijmo.angular2.input';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { WatchListService } from '../../core/watch-list.service';

export class DialogResult {
  addToList: boolean;
  name: string;
}

@Component({
  selector: 'app-smart-paste-dialog',
  templateUrl: './smart-paste-dialog.component.html',
  styleUrls: ['./smart-paste-dialog.component.scss'],
})
export class SmartPasteDialogComponent implements OnInit, OnDestroy {
  @ViewChild('smartPasteDialog', { static: true })
  private _smartPasteDialog: WjPopup;

  private _observer: Observer<DialogResult | null> = null;

  form: FormGroup;
  showName = false;

  listName = '';

  private watchListNames: Set<string>;

  private _dialogResult: DialogResult = null;
  private _valueSubscription: Subscription;

  get name(): AbstractControl {
    return this.form && this.form.get('name');
  }

  constructor(private watchList: WatchListService) {}

  ngOnInit() {}

  init(listName: string) {
    this.watchListNames = new Set<string>();
    for (const w of this.watchList.all()) {
      this.watchListNames.add(w.name);
    }

    const nameControl = new FormControl('Name', [
      Validators.required,
      this.nameLengthValidator.bind(this),
      this.uniqueNameValidator.bind(this),
    ]);
    this.form = new FormGroup({
      addOrCreate: new FormControl(listName ? 'add' : 'create'),
      name: nameControl,
    });

    if (this._valueSubscription) {
      this._valueSubscription.unsubscribe();
    }
    this._valueSubscription = concat(of(this.form.get('addOrCreate').value), this.form.get('addOrCreate').valueChanges).subscribe((v) => {
      if (v === 'add') {
        nameControl.setValue('');
        nameControl.disable();
        this.showName = false;
      } else {
        nameControl.setValue('');
        nameControl.enable();
        this.showName = true;
      }
    });

    this.listName = listName;
    this._dialogResult = null;
  }

  showDialog(targetListName: string): Observable<DialogResult | null> {
    return new Observable<DialogResult | null>((subj) => {
      this.init(targetListName);
      setTimeout(() => {
        // ダイアログの大きさに応じて正しくセンタリングするために、大きさ反映後にpopup#showを呼び出す
        this._smartPasteDialog.show();
        this._observer = subj;
      });
    });
  }

  onHidden() {
    if (this._observer) {
      this._observer.next(this._dialogResult);
      this._observer.complete();
      this._observer = null;
    }
    if (this._valueSubscription) {
      this._valueSubscription.unsubscribe();
      this._valueSubscription = null;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this._dialogResult = new DialogResult();
      this._dialogResult.addToList = this.form.get('addOrCreate').value === 'add';
      this._dialogResult.name = this.form.get('name').value;
      this._smartPasteDialog.hide();
    }
  }

  uniqueNameValidator(control: AbstractControl): { [key: string]: any } | null {
    return this.watchListNames && this.watchListNames.has((control.value || '').toString().trim())
      ? {
          notUnique: control.value,
        }
      : null;
  }

  nameLengthValidator(control: AbstractControl): { [key: string]: any } | null {
    return (control.value || '').length > 9
      ? {
          nameLength: control.value,
        }
      : null;
  }

  ngOnDestroy(): void {
    if (this._valueSubscription) {
      this._valueSubscription.unsubscribe();
      this._valueSubscription = null;
    }
  }
}
