import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fita4-trade-config',
  templateUrl: './trade-config.component.html',
  styleUrls: ['./trade-config.component.scss'],
})
export class TradeConfigComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
