<brisk-vg-grid
  #grid
  briskVgGridFlexibleRowSize
  [itemsSource]="collectionView"
  [selectionMode]="'SingleRow'"
  [addDummyRow]="true"
  [showRowHeader]="true"
  [minWidth]="minWidth"
  (selectionChanged)="onSelectionChanged(grid)"
  [disableEdgeScrollFix]="disableEdgeScrollBugFix"
  (dblclick)="onDblClick($event)"
  (columnFiltered)="onFiltered()"
  (columnFilterFocusOut)="columnFilterFocusOut.emit($event)"
  (columnFilterOpening)="filterOpening.emit($event)"
  (columnFilterClosed)="filterClosed.emit($event)"
  (columnSorted)="onSorted()"
>
  <brisk-vg-grid-column
    [minWidth]="5"
    minWidthUnit="Em"
    [width]="5"
    widthUnit="Fr"
    name="time"
    binding="time2"
    header="時刻"
    align="center"
    [allowSorting]="true"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [minWidth]="10"
    minWidthUnit="Em"
    [width]="10"
    widthUnit="Fr"
    cssClass="add-padding"
    name="typeName"
    binding="typeName"
    header="種別"
    align="left"
    [allowSorting]="false"
    [filterType]="'Value'"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [minWidth]="11"
    minWidthUnit="Em"
    [width]="11"
    widthUnit="Fr"
    cssClass="add-padding"
    name="stockName"
    binding="stockName"
    header="銘柄名"
    align="left"
    [allowSorting]="false"
    [filterType]="'Value'"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [minWidth]="3.5"
    minWidthUnit="Em"
    [width]="3.5"
    widthUnit="Fr"
    name="issueCode"
    binding="issueCode"
    header="コード"
    align="center"
    [allowSorting]="false"
    [filterType]="'Value'"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [minWidth]="2"
    minWidthUnit="Em"
    [width]="2"
    widthUnit="Fr"
    name="prefix"
    binding="prefix"
    header=" "
    align="center"
    [allowSorting]="false"
    [filterType]="'Value'"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    i18n-header="@@commonMarketMarketGridMessageHeader"
    [minWidth]="11"
    minWidthUnit="Em"
    [width]="11"
    widthUnit="Fr"
    cssClass="add-padding"
    name="message"
    binding="text"
    header="詳細 "
    align="left"
    [allowSorting]="false"
  >
  </brisk-vg-grid-column>
  <brisk-vg-grid-column
    [minWidth]="6"
    minWidthUnit="Em"
    [width]="6"
    widthUnit="Fr"
    cssClass="add-padding"
    name="turnover"
    binding="turnover"
    header="金額"
    align="center"
    dataType="Number"
    [allowSorting]="true"
    [defaultSortDirection]="'Desc'"
    [filterType]="'Condition'"
    [filterRoundPrecision]="1"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <div class="text-right">
        <span *ngIf="item.turnover">{{ item.turnover | fastDecimal: { separateComma: true, fractionDigits: 1 } }}億円</span>
      </div>
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>
