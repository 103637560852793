import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Config, PARENT_CONFIG, parentWindowInitializer, ParentWindowService } from './parent-window.service';
import { Subject } from 'rxjs';
import { ParentWindowSyncService } from './parent-window-sync.service';
import { ChildrenWindowSyncService } from './children-window-sync.service';

declare global {
  interface Window {
    tick?: Subject<{}>;
    inject?<T>(token: string): T;
  }
}
@NgModule({
  imports: [CommonModule],
})
export class MultipleWindowModule {
  static forParentWindow(config: Config): ModuleWithProviders<MultipleWindowModule> {
    return {
      ngModule: MultipleWindowModule,
      providers: [
        ParentWindowService,
        ParentWindowSyncService,
        {
          provide: APP_INITIALIZER,
          useFactory: parentWindowInitializer,
          multi: true,
          deps: [ParentWindowService],
        },
        {
          provide: PARENT_CONFIG,
          useValue: config,
          multi: true,
        },
      ],
    };
  }

  static forChildrenWindow(): ModuleWithProviders<MultipleWindowModule> {
    return {
      ngModule: MultipleWindowModule,
      providers: [ChildrenWindowSyncService],
    };
  }
}
