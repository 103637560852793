import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeNamePipe } from './theme-name.pipe';
import { ThemeSelectorComponent } from './theme-selector/theme-selector.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  imports: [CommonModule, NgbDropdownModule, IconsModule],
  declarations: [ThemeNamePipe, ThemeSelectorComponent],
  exports: [ThemeNamePipe, ThemeSelectorComponent],
})
export class ThemeModule {
  constructor() {}
}
