<div class="c-tooltip">
  <div *ngIf="stockWrapper.current" class="tooltip-inner" [class.tooltip-inner--show-title]="groupName">
    <div class="map-view-tooltip__title">{{ groupName }}</div>
    <div class="map-view-tooltip__chart">
      <div class="map-view-tooltip__chart_inner">
        <app-mini-chart #chart *ngIf="stockWrapper" [stockWrapper]="stockWrapper"></app-mini-chart>
      </div>
    </div>
  </div>
</div>
