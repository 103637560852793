<div id="scrollWrapper" tabIndex="0" #focusElement>
  <div #canvasHost id="canvasHost">
    <div id="resizeWrapper">
      <div #canvasWrapper id="canvasWrapper">
        <canvas #canvas></canvas>
      </div>
    </div>
  </div>

  <div
    #scroll
    id="scroll"
    class="c-ita-scroll-wrapper"
    (scroll)="onScroll(scroll, $event)"
    [style.cursor]="cursorStyle"
    (mouseup)="onMouseUp(scroll, $event)"
    (mouseenter)="onMouseEnter(scroll, $event)"
    (mouseleave)="onMouseLeave(scroll, $event)"
    (mousemove)="onMouseMove(scroll, $event)"
    (mousedown)="onMouseDown(scroll, $event)"
    (dblclick)="onDblClick(scroll, $event)"
    (contextmenu)="onContextMenu($event)"
  >
    <div #scrollContent id="scrollContent"></div>
  </div>
</div>
<ng-content></ng-content>
