import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewOptionsComponent } from './view-options/view-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbButtonsModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGaugeModule } from '@grapecity/wijmo.angular2.gauge';
import { GoldenLayoutAutoResizeDirective } from './golden-layout-auto-resize.directive';
import { FatalErrorDialogComponent } from '../fatal-error-dialog/fatal-error-dialog.component';
import { BriskBrowserModule, BriskCommonModule, IconsModule, ItaModule, VgGridModule } from '@argentumcode/brisk-common';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';
import { GridColumnEditorDialogComponent } from './grid-column-editor-dialog/grid-column-editor-dialog.component';
import { ReconnectingMessageComponent } from './reconnecting-message/reconnecting-message.component';
import { MiniItaComponent } from './mini-ita/mini-ita.component';
import { GridLastPriceColumnComponent } from './grid-columns/grid-last-price-column.component';
import { GridLastPriceBasePriceChangesColumnComponent } from './grid-columns/grid-last-price-base-price-changes-column';
import { GridIssueNameColumnComponent } from './grid-columns/grid-issue-name-column.component';
import { GridLastPriceVwapChangesColumnComponent } from './grid-columns/grid-last-price-vwap-changes-column.component';
import { GridStockPrefixColumnComponent } from './grid-columns/grid-stock-prefix-column.component';
import { GridIssueCodeColumnComponent } from './grid-columns/grid-issue-code-column.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GridColumnsEditorDirective } from './grid-columns-editor.directive';
import { SplashComponent } from './splash/splash.component';
import { ItaClockComponent } from './ita-clock/ita-clock.component';
import { DemoFinishedDialogComponent } from './demo-finished-dialog/demo-finished-dialog.component';
import { DemoStartDialogComponent } from './demo-start-dialog/demo-start-dialog.component';

@NgModule({
  declarations: [
    ViewOptionsComponent,
    GoldenLayoutAutoResizeDirective,
    FatalErrorDialogComponent,
    UnsupportedBrowserComponent,
    GridColumnEditorDialogComponent,
    ReconnectingMessageComponent,
    MiniItaComponent,
    GridLastPriceColumnComponent,
    GridLastPriceBasePriceChangesColumnComponent,
    GridIssueNameColumnComponent,
    GridLastPriceVwapChangesColumnComponent,
    GridStockPrefixColumnComponent,
    GridIssueCodeColumnComponent,
    GridColumnsEditorDirective,
    SplashComponent,
    ItaClockComponent,
    DemoFinishedDialogComponent,
    DemoStartDialogComponent,
  ],
  exports: [
    ViewOptionsComponent,
    GoldenLayoutAutoResizeDirective,
    FatalErrorDialogComponent,
    UnsupportedBrowserComponent,
    GridColumnEditorDialogComponent,
    ReconnectingMessageComponent,
    MiniItaComponent,
    GridLastPriceColumnComponent,
    GridLastPriceBasePriceChangesColumnComponent,
    GridIssueNameColumnComponent,
    GridLastPriceVwapChangesColumnComponent,
    GridStockPrefixColumnComponent,
    GridIssueCodeColumnComponent,
    GridColumnsEditorDirective,
    SplashComponent,
    ItaClockComponent,
    DemoStartDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbButtonsModule,
    WjInputModule,
    IconsModule,
    BriskBrowserModule,
    VgGridModule,
    BriskCommonModule,
    ItaModule,
    NgbTooltipModule,
    MatDialogModule,
    WjGaugeModule,
  ],
})
export class SharedComponentsModule {}
