import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { StockWrapper } from '../../brisk-core/stock-wrapper';
import { FlexConfig, FLEX_CONFIG, StockView } from '../../flex';
import { Subscription } from 'rxjs';
import { PegState } from '../../ita/ita/ita.component';

@Component({
  selector: 'brisk-timestamp-header',
  templateUrl: './timestamp-header.component.html',
  styleUrls: ['./timestamp-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimestampHeaderComponent implements OnInit, OnDestroy {
  private updatedSubscription: Subscription;

  @Input()
  public singleBackMode = false;

  @Input()
  public timestampMilliseconds = true;

  @Input()
  public showHistoryButtons = true;

  // DEPRECATED
  @Input()
  public showUpdateNumber = true;

  @Input()
  public controllerOnly = false;

  @Input() showCamera = false;

  @Input()
  get stockWrapper(): StockWrapper {
    return this._stockWrapper;
  }

  set stockWrapper(value: StockWrapper) {
    if (value === this._stockWrapper) {
      return;
    }
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }
    this._stockWrapper = value;
    if (value) {
      this.updatedSubscription = value.updated.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  @Output()
  public saveClick = new EventEmitter<MouseEvent>();

  @Input()
  public peg: PegState = PegState.Disabled;

  @Output()
  public pegChange = new EventEmitter<PegState>();

  private _stockWrapper: StockWrapper;

  get view(): StockView {
    return this._stockWrapper ? this._stockWrapper.current : null;
  }

  get base(): StockView {
    return this._stockWrapper ? this._stockWrapper.base : null;
  }

  get endSession(): boolean {
    if (!this._stockWrapper) {
      return false;
    }
    return this._stockWrapper.base.timestamp === this.flexConfig.marketCloseTimestamp * 1000;
  }

  get pegEnabled() {
    return this.peg === PegState.Enabled;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, @Inject(FLEX_CONFIG) private flexConfig: FlexConfig) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.updatedSubscription) {
      this.updatedSubscription.unsubscribe();
      this.updatedSubscription = null;
    }
  }

  onPegClicked() {
    if (this.peg === PegState.Enabled) {
      this.peg = PegState.Disabled;
    } else {
      this.peg = PegState.Enabled;
    }
    this.pegChange.emit(this.peg);
  }
}
