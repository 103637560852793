import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ORDER_OPERATOR_TOKEN, ORDER_UI_SERVICE_TOKEN } from '../types/interfaces';
import { TachibanaNoopOrderOperatorService } from './tachibana-noop-order-operator.service';
import { NoopOrderUiModule } from '../noop-order-ui/noop-order-ui.module';
import { ItaOrderTutorialComponent } from './ita-order-tutorial/ita-order-tutorial.component';

@NgModule({
  declarations: [ItaOrderTutorialComponent],
  imports: [CommonModule, NoopOrderUiModule],
  providers: [[{ provide: ORDER_OPERATOR_TOKEN, useClass: TachibanaNoopOrderOperatorService }]],
  exports: [NoopOrderUiModule],
})
export class TachibanaNoopOrderUiModule {}
