import { Injectable } from '@angular/core';
import { Condition, Price10, Share, Side } from './object';
import { FIXAccount } from './fix.service';
import { StockMaster } from '@argentumcode/brisk-common';

export class OrderSource {
  constructor(
    public side: Side,
    public condition: Condition,
    public price10: Price10,
    public quantity: Share,
    public account: FIXAccount,
    public tag: string
  ) {}

  clone(): OrderSource {
    return new OrderSource(this.side, this.condition, this.price10, this.quantity, this.account, this.tag);
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderSourceService {
  private orderSource: { [index: number]: OrderSource } = {};

  constructor() {}

  public get(master: StockMaster): OrderSource {
    if (!(master.issueCode in this.orderSource)) {
      this.orderSource[master.issueCode] = new OrderSource(
        Side.Buy,
        Condition.Zaraba,
        new Price10(master.basePrice10),
        new Share(master.lotSize),
        null,
        ''
      );
    }
    return this.orderSource[master.issueCode];
  }
}
