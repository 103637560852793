import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SummaryPortfolio } from '../portfolio/portfolio.component';
import { SummaryType } from '@argentumcode/brisk-common';

@Component({
  selector: 'app-index-portfolio-line',
  templateUrl: './index-portfolio-line.component.html',
  styleUrls: ['./index-portfolio-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexPortfolioLineComponent implements OnInit, OnChanges {
  @Input()
  summary: SummaryPortfolio;

  @Input()
  showUpDown = true;

  @Input()
  showCount = true;

  @Input()
  showOpen = true;

  showSummaryDetail = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('summary' in simpleChanges) {
      this.showSummaryDetail = this.summary.summary.type !== SummaryType.SmartList && this.summary.summary.type !== SummaryType.Market;
    }
  }

  refresh() {
    this.changeDetectorRef.markForCheck();
  }
}
