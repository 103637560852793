import { Injectable } from '@angular/core';
import { from, Observable, Observer, of, throwError } from 'rxjs';
import { catchError, delay, map, mergeMap, retryWhen } from 'rxjs/operators';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { fromByteArray, toByteArray } from 'base64-js';
import uuidv5 from 'uuid/v5';

const watchListNamespace = '46072fc0-9a06-4536-b681-71a813dfee75';

class BootResponse {
  userId: string;
  identity: string;
  csrfToken: string;
  apiToken: string;
  apiEndpoint: string;
  apiLocalPrefer: boolean;
  data: any;
  newsRevision: number;
  sessionExpires: number;
  chartToken: string;
  chartOrigin: string;

  constructor(data: {
    user_id: string;
    identity: string;
    csrf_token: string;
    api_token: string;
    api_endpoint: string;
    api_local_prefer: boolean;
    data: any;
    news_revision: number;
    session_expires: number;
    chart_token: string;
    chart_origin: string;
  }) {
    this.userId = data.user_id;
    this.identity = data.identity;
    this.csrfToken = data.csrf_token;
    this.apiToken = data.api_token;
    this.apiEndpoint = data.api_endpoint;
    this.apiLocalPrefer = data.api_local_prefer;
    this.data = data.data;
    this.newsRevision = data.news_revision || 0;
    this.sessionExpires = data.session_expires;
    this.chartOrigin = data.chart_origin;
    this.chartToken = data.chart_token;
  }
}

export interface WatchListResponse {
  error: boolean;
  err?: Error;
  empty: boolean;
  version?: string;
  uuid?: string;
  data?: Uint8Array;
}

export interface WatchListRequest {
  userId: string;
  uuid: string;
  version: string;
  data: Uint8Array;
}

function defaultBackoffConfig(): RetryBackoffConfig {
  return {
    initialInterval: 500,
    maxRetries: 3,
    maxInterval: 20000,
    backoffDelay: (it, initialInterval) => {
      return Math.pow(2, it) * initialInterval;
    },
    shouldRetry: (err: any) => {
      if (err && err.name === 'HttpAuthError') {
        return false;
      }
      return true;
    },
  };
}

export class APIHttpError extends Error {
  public readonly authErrorCode: string;
  public readonly rateLimitError: string;

  public constructor(public response: HttpErrorResponse) {
    super(response.name);
    this.message = `${response.status} ${response.message}`;
    this.authErrorCode = response.headers.get('X-Auth-Error-Code');
    this.rateLimitError = response.headers.get('X-Rate-Limit-Error');
  }
}

export class HttpAuthError extends APIHttpError {
  public constructor(public response: HttpErrorResponse) {
    super(response);
    this.name = 'HttpAuthError';
  }
}

function createHttpError(response: HttpErrorResponse): Error {
  if (response.status === 401) {
    return new HttpAuthError(response);
  } else {
    return new APIHttpError(response);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FrontendApiService {
  constructor(private http: HttpClient) {}

  private csrfToken: string;

  boot(): Observable<BootResponse> {
    return of({
      user_id: 'dummy_user_id',
      identity: 'dummy_identity',
      csrf_token: 'dummy_csrf_token',
      api_token: 'dummy_api_token',
      api_endpoint: '',
      api_local_prefer: false,
      data: {},
      news_revision: 0,
      session_expires: Math.floor(new Date().getTime() / 1000) + 60 * 60 * 24,
      chart_token: '',
      chart_origin: '',
    }).pipe(
      map((resp) => {
        this.csrfToken = resp['csrf_token'];
        return new BootResponse(<any>resp);
      })
    );
  }

  feedback(feedback: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return new Observable((observer: Observer<{}>) => {
      const postParam = { feedback: feedback, csrf_token: this.csrfToken };
      this.http.post('/api/frontend/feedback', postParam, { headers: headers, responseType: 'text' }).subscribe(
        () => {
          observer.next({});
        },
        (err) => {
          observer.error(err);
        },
        () => {
          observer.complete();
        }
      );
    }).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((err, index) => {
            const isBadRequest = err instanceof HttpErrorResponse && err.status === 400;
            if (!isBadRequest || index >= 1) {
              return throwError(err);
            }
            console.error(`CSRF Error`, err);
            return this.boot();
          })
        )
      )
    );
  }

  getWatchlist(): Observable<WatchListResponse> {
    return of({
      error: false,
      empty: true,
    });
  }

  saveWatchlist(w: WatchListRequest): Observable<string> {
    const data = fromByteArray(w.data);
    return of(uuidv5(data, watchListNamespace));
  }

  // HttpErrorResponse
  private processError(error: any): Error {
    if (error && error instanceof HttpErrorResponse) {
      return createHttpError(error);
    }
    return error;
  }
}
