import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradePanelComponent } from './trade-panel/trade-panel.component';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { FormsModule } from '@angular/forms';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { TradeSummaryComponent } from './trade-summary/trade-summary.component';
import { TradeSummaryPriceClassPipe } from './trade-summary-price-class.pipe';
import { SharedModule } from '../shared/shared.module';
import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
import { TradeSummaryBoxComponent } from './trade-summary-box/trade-summary-box.component';
import { PortfolioModule } from '../portfolio/portfolio.module';

@NgModule({
  imports: [CommonModule, WjInputModule, WjGridModule, WjNavModule, FormsModule, WjGridFilterModule, SharedModule, PortfolioModule],
  declarations: [TradePanelComponent, TradeSummaryComponent, TradeSummaryPriceClassPipe, TradeSummaryBoxComponent],
  exports: [TradePanelComponent, TradeSummaryComponent, TradeSummaryBoxComponent],
})
export class FixModule {}
