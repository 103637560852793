import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { GroupingType } from 'fita3/src/app/portfolio/map-view/grouper';

export const GroupingTypes = {
  None: {
    name: 'なし',
    groupings: [],
    order: 0,
  },
  Industry: {
    name: '業種',
    groupings: [GroupingType.Industry],
    order: 1,
  },
  Section: {
    name: '市場区分',
    groupings: [GroupingType.Section],
    order: 2,
  },
  IndustrySection: {
    name: '業種・市場',
    groupings: [GroupingType.Industry, GroupingType.Section],
    order: 3,
  },
  SectionIndustry: {
    name: '市場・業種',
    groupings: [GroupingType.Section, GroupingType.Industry],
    order: 4,
  },
} as const;
export type GroupingTypeKey = keyof typeof GroupingTypes;
export interface MapViewState {
  grouping: GroupingTypeKey;
}

export interface MapViewController {
  state$: Observable<MapViewState>;

  updateGroupings(grouping: GroupingTypeKey): void;
  saveImage(): void;
}

export const MAP_VIEW_CONTROLLER_TOKEN = new InjectionToken<MapViewController>('MapViewController');
