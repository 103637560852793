import { Component, Inject, OnInit } from '@angular/core';
import { StockDetailService } from '../../stock-detail.service';
import { Observable } from 'rxjs';
import { ChartSource, ResizeService } from '@argentumcode/brisk-common';
import { GlOnResize, GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { SummaryListsService } from '../../summary-lists.service';
import { Container } from 'golden-layout';

interface ChartPanelState {
  chartType?: string;
  normalized?: boolean;
  showNk225?: boolean;
}

@Component({
  selector: 'app-fita4-chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrls: ['./chart-panel.component.scss'],
})
export class ChartPanelComponent implements OnInit, GlOnResize, GlOnShow {
  state: ChartPanelState;
  chartSource: Observable<ChartSource>;

  constructor(
    private stockDetailService: StockDetailService,
    private resize: ResizeService,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.chartSource = this.stockDetailService.chartSource;
  }

  ngOnInit(): void {
    this.state = this.container.getState() || {};
    let updateState = false;
    const newState: ChartPanelState = { ...this.state };
    if (!this.state.chartType) {
      newState.chartType = '5min';
      updateState = true;
    }
    if (this.state.normalized === undefined) {
      newState.normalized = true;
      updateState = true;
    }
    if (this.state.showNk225 === undefined) {
      newState.showNk225 = true;
      updateState = true;
    }
    if (updateState) {
      this.updateState(newState);
    }
    // To avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  updateChartType(chartType: string) {
    if (!this.state) {
      return;
    }
    this.updateState({ ...this.state, chartType });
  }

  updateNormalized(normalized: boolean) {
    if (!this.state) {
      return;
    }
    this.updateState({ ...this.state, normalized });
  }

  updateShowNk225(showNk225: boolean) {
    if (!this.state) {
      return;
    }
    console.log('updateShowNk225', showNk225);
    this.updateState({ ...this.state, showNk225 });
  }

  updateState(state: ChartPanelState) {
    this.state = state;
    this.container.setState(state);
  }

  glOnResize(): void {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  glOnShow(): void {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }
}
