import { OHLCResponse, OHLCResponseExtended } from './ohlc';
import { StockWrapper } from '../brisk-core/stock-wrapper';
import { Observable } from 'rxjs';

export class LineChartTodayData {
  public rates: Array<number>;
  public lastIndex: number;
  constructor(ohlcLength: number) {
    this.rates = new Array<number>(ohlcLength).fill(undefined);
  }
}

export class ChartSource {
  constructor(
    public stockWrapper: StockWrapper,
    public pastData: Observable<OHLCResponse>,
    public fiveMinDays: number,
    public days: number = null,
    public weeks: number = null,
    public months: number = null,
    public lineChartTodayData?: LineChartTodayData,
    public lineChartName?: string,
    public lineChartUpdated?: Observable<{}>,
    public lineChartPastData?: Observable<OHLCResponseExtended>
  ) {}
}
