import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCamera,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCircle as fasCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFastBackward,
  faFastForward,
  faFileExport,
  faFilter,
  faInfoCircle,
  faPen,
  faPlus,
  faRedoAlt,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faInfoCircle,
      faCamera,
      faCheck,
      faRedoAlt,
      faFileExport,
      fasCircle,
      farCircle,
      faExternalLinkAlt,
      faPlus,
      faPen,
      faPlusSquare,
      faMinusSquare,
      faFastBackward,
      faFastForward,
      faCaretLeft,
      faCaretRight,
      faSearch,
      faExclamationTriangle,
      faSort,
      faSortUp,
      faSortDown,
      faFilter,
      faSyncAlt
    );
  }
}
