import { Component, Inject, Input, OnInit } from '@angular/core';
import { BasePortalOutlet, Portal } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// ダイアログのボタン
export interface DialogButton<R> {
  // ボタンの表示内容を設定します。
  text: string;
  // ボタンのCSS Classを設定します
  cssClass?: string;
  // ボタンがクリックされた時に返す内容を設定します
  result?: R;
  // 表示時の初期フォーカスを設定します
  initialFocus?: boolean;
}

// 単純な形式のダイアログの表示設定
export interface SimpleDialog<T, R, D> {
  // ダイアログの表示内容を設定します。
  // 文字列の配列を渡した場合は、配列の要素毎に改行されます。
  content: Portal<T> | string | Array<string>;

  // ダイアログの表示内容をmat-dialog-contentで囲うかどうかを設定します。
  // buttonsとcheckboxTextを設定せずに、actionsを自前で定義する場合にFalseにします。
  // (デフォルト: True)
  wrapContent?: boolean;

  // ダイアログのタイトルを設定します。
  title?: string;
  // ダイアログにチェックボックスを表示する場合に、その内容を設定します。
  checkboxText?: string;
  // ダイアログにチェックボックスを表示する場合に、そのチェック状態を設定します。
  checked?: boolean;
  // ダイアログに渡すデータを設定します。
  // 表示するコンポーネントでMAT_DIALOG_DATAをInjectして、dataプロパティを読み出すことで取得できます。
  data?: D;

  // ダイアログに表示するボタンを設定します。
  buttons?: Array<DialogButton<R>>;

  // Escapeキーで閉じるかどうかを設定します。(デフォルト: True)
  closeOnEscape?: boolean;

  // 閉じるボタンを表示するかどうかを設定します。(デフォルト: True)
  showCloseButton?: boolean;
}

export interface SimpleDialogResultButton<R> {
  type: 'button';
  checked?: boolean;
  value: R;
}
export interface SimpleDialogResultClosed {
  type: 'closed';
}
export const SimpleDialogResultClosed = {
  type: 'closed',
} as const;
export type SimpleDialogResult<R> = SimpleDialogResultButton<R> | SimpleDialogResultClosed;

@Component({
  selector: 'brisk-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent<T, R> implements OnInit {
  closedResult = SimpleDialogResultClosed;

  dialogTitle: string;
  checkboxText: string;
  checked: boolean;
  content: Portal<T>;
  contentText: Array<string>;
  buttons: Array<DialogButton<R>>;
  showCloseButton: boolean;
  wrapContent: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) data: SimpleDialog<T, R, any>, private dialogRef: MatDialogRef<SimpleDialogResult<R>>) {
    this.dialogTitle = data.title || '';
    this.checkboxText = data.checkboxText;
    this.checked = data.checked;
    this.buttons = data.buttons || [];
    this.wrapContent = data.wrapContent === undefined ? true : data.wrapContent;
    if (!this.wrapContent) {
      if (typeof data.content === 'string' || Array.isArray(data.content)) {
        throw new Error('You must set Portal to content if wrapContent is false');
      }
      if (this.checkboxText || this.buttons.length > 0) {
        throw new Error('You cannot set actions if wrapContent is false');
      }
    }
    if (typeof data.content === 'string') {
      this.contentText = [data.content];
    } else if (Array.isArray(data.content)) {
      this.contentText = data.content;
    } else {
      this.content = data.content;
    }
    if (data.closeOnEscape === undefined || data.closeOnEscape) {
      this.dialogRef.keydownEvents().subscribe((event) => {
        if (event.key === 'Escape') {
          this.dialogRef.close(this.closedResult);
          event.preventDefault();
          event.stopPropagation();
        }
      });
    }
    this.showCloseButton = data.showCloseButton === undefined || data.showCloseButton;
  }

  onCloseButtonClick() {
    this.dialogRef.close(this.closedResult);
  }

  ngOnInit(): void {}

  onClick(result: R) {
    this.dialogRef.close({ type: 'button', value: result, checked: this.checked });
  }
}
