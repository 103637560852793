import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

export class Button {
  constructor(public name: string, public value: string, public cssClass = '') {}
}

export interface ModelDialogComponentInterface {
  prompt(
    message: string | Array<string> | TemplateRef<any>,
    buttons: Array<Button>,
    closable?: boolean,
    title?: string,
    cssClass?: string
  ): Observable<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalDialogService {
  private component: ModelDialogComponentInterface;

  constructor() {}

  public dialog(
    message: string | Array<string> | TemplateRef<any>,
    buttons: Array<Button>,
    closable?: boolean,
    title?: string,
    cssClass?: string
  ): Observable<string | null> {
    return this.component.prompt(message, buttons, closable, title, cssClass);
  }

  public setupComponent(component: ModelDialogComponentInterface) {
    this.component = component;
  }
}
