// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environmentBase } from '../default/environment.base';
import { TachibanaNoopOrderUiModule } from '../../app/order/tachibana-noop-order-ui/tachibana-noop-order-ui.module';

declare const PARENT_ORIGIN: string;

export const environment = {
  ...environmentBase,
  name: 'brisk-e-shiten-viewonly-demo',
  showKabutan: false,
  showBuffetCode: true,
  showMapView: true,
  helps: [
    {
      name: '板発注',
      link: 'https://static.brisk.jp/next-help/itaorderhelp.html',
    },
    {
      name: '銘柄リスト',
      link: 'https://static.brisk.jp/next-help/stocklisthelp.html',
    },
    {
      name: 'サイドパネル',
      link: 'https://static.brisk.jp/next-help/sidepanelhelp.html',
    },
    {
      name: 'フル板ウィンドウ',
      link: 'https://static.brisk.jp/next-help/fullitapanelhelp.html',
    },
    {
      name: '場況速報',
      link: 'https://static.brisk.jp/next-help/bakyohelp.html',
    },
    {
      name: 'スマートコピペ',
      link: 'https://static.brisk.jp/next-help/smart-copipe.html',
    },
    {
      name: 'よくあるご質問(FAQ)',
      link: 'https://static.brisk.jp/next-help/faq.html',
    },
    {
      name: '本サイトについて',
      link: 'https://static.brisk.jp/next-demo/notice.html',
    },
  ],

  orderUiModule: TachibanaNoopOrderUiModule,
  orderUiModuleForRoot: TachibanaNoopOrderUiModule,
  tradeModules: [],
  stockDetailPanels: [],
  tradePanels: [],
  stockDetailProviders: [],

  sharedServices: {},

  tedinetUrl: 'https://timely-disclosure.brisk.jp',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
