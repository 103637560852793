import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { EventManager } from '@angular/platform-browser';
import { throttle } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

@Injectable()
export class ResizeService implements OnDestroy {
  private resizeSubject = new Subject<{}>();
  private zoneSubscription: Subscription;
  public resize = this.resizeSubject.asObservable();
  private readonly removeFunctions: Function[];

  private observerTargets = new WeakMap<Element, (e: ResizeObserverEntry) => void>();
  private hasUpdate = false;
  private nextUpdate = false;
  private updateCount = 0;
  private recursiveResize = false;

  private resizeObserver: ResizeObserver;

  constructor(eventManager: EventManager, private zone: NgZone) {
    this.resizeObserver = new ResizeObserver((entires) => this.onElementResize(entires));

    this.removeFunctions = [
      eventManager.addGlobalEventListener('window', 'resize', this.onResize.bind(this)),
      eventManager.addGlobalEventListener('window', 'orientationchange', this.onResize.bind(this)),
    ];
    this.zoneSubscription = this.zone.onStable.subscribe(() => {
      if (this.hasUpdate && this.nextUpdate) {
        this.nextUpdate = false;
      }
      if (this.nextUpdate) {
        this.nextUpdate = false;
        this.zone.run(() => {
          this.resizeSubject.next({});
        });
      }
      if (this.hasUpdate) {
        this.hasUpdate = false;
        this.zone.run(() => {
          this.resizeSubject.next({});
        });
        this.nextUpdate = true;
        this.updateCount = 0;
      }
    });
  }

  dispatchResize() {
    if (this.recursiveResize) {
      return;
    }
    this.recursiveResize = true;
    const resizeEvent = window.document.createEvent('UIEvents');
    (<any>resizeEvent).initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
    this.recursiveResize = false;
  }

  observeElement(element: Element): Observable<ResizeObserverEntry> {
    return new Observable<ResizeObserverEntry>((subj) => {
      this.observe(element, (e) => subj.next(e));
      return () => {
        this.unobserve(element);
      };
    });
  }

  private observe(element: Element, handler: (ResizeObserverEntry) => void) {
    this.observerTargets.set(element, handler);
    this.resizeObserver.observe(element);
  }

  private unobserve(element: Element) {
    this.resizeObserver.unobserve(element);
    this.observerTargets.delete(element);
  }
  private onResize() {
    this.hasUpdate = true;
    this.updateCount++;
  }

  private onElementResize(entries: Array<ResizeObserverEntry>) {
    for (const ent of entries) {
      this.zone.runGuarded(() => {
        if (this.observerTargets.has(ent.target)) {
          this.observerTargets.get(ent.target)(ent);
        }
      });
    }
  }

  ngOnDestroy(): void {
    for (const func of this.removeFunctions) {
      func();
    }
    if (this.zoneSubscription) {
      this.zoneSubscription.unsubscribe();
    }
    this.resizeObserver.disconnect();
  }
}
