import { Pipe, PipeTransform } from '@angular/core';
import { QRRow, QRRowType, StockMaster } from '../flex';

@Pipe({
  name: 'rowTypeClass',
})
export class RowTypeClassPipe implements PipeTransform {
  private getClassName(value: QRRow) {
    switch (value.type) {
      case QRRowType.None:
        return 'qr-row-none-bg';

      case QRRowType.Ask:
        return 'qr-row-ask-bg';

      case QRRowType.Bid:
        return 'qr-row-bid-bg';
    }
    return '';
  }

  transform(row: QRRow, column: string, master: StockMaster): any {
    if (column === 'price') {
      return this.getClassName(row);
    } else if (column === 'quantity' && master && row.quantity >= master.lotSize * 10) {
      return this.getClassName(row);
    } else if (column === 'turnover' && row.price10 * row.quantity >= 50000000) {
      return this.getClassName(row);
    }
    return '';
  }
}
