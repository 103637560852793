<div id="marketsWrapper">
  <div id="markets">
    <div>
      <div class="heading-markets"></div>
    </div>
    <div id="latestMarkets">
      <div id="latestMarketsWrapper">
        <div class="latestMarket">
          <div class="text">BRiSK Next デモ（ある日の寄り付き3分間を再現中！）</div>
          <div class="text">発注機能付き製品版は立花証券e支店に口座をお持ちの皆様に無料で提供しています。</div>
        </div>
        <div class="latestMarket following1">
          <div class="text">BRiSK Next デモ（ある日の寄り付き3分間を再現中！）</div>
          <div class="text">発注機能付き製品版は立花証券e支店に口座をお持ちの皆様に無料で提供しています。</div>
        </div>
        <div class="latestMarket following2">
          <div class="text">BRiSK Next デモ（ある日の寄り付き3分間を再現中！）</div>
          <div class="text">発注機能付き製品版は立花証券e支店に口座をお持ちの皆様に無料で提供しています。</div>
        </div>
      </div>
    </div>
  </div>
</div>
