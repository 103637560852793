import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { VgGridColumnValueFilter } from '../vg-grid-filter';
import { default as natsort } from 'natsort';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'brisk-vg-grid-value-filter',
  templateUrl: './vg-grid-value-filter.component.html',
  styleUrls: ['./vg-grid-value-filter.component.scss'],
})
export class VgGridValueFilterComponent implements OnInit, OnChanges, OnDestroy {
  // #407 IE/Edgeでは中間状態を利用しない
  patchForIEOrEdge = false;

  @Input()
  filter: VgGridColumnValueFilter;

  @Output()
  apply = new EventEmitter<VgGridColumnValueFilter>();

  @Output()
  cancel = new EventEmitter<{}>();

  @ViewChild('searchBox', { static: true })
  searchBox: ElementRef;

  searchText = '';
  oldSearchText = '';

  uniqueValues: Array<{
    name: string;
    value: any;
    checked: boolean;
  }> = [];
  private originalUniqueValues: Array<{ name: string; value: any }> = [];

  allChecked = true;
  indeterminate = true;

  hasEmpty = true;

  private _maxCount = 250;

  private _inputSubscription: Subscription = null;

  constructor(private _changeDetectorRef: ChangeDetectorRef, platform: Platform) {
    this.patchForIEOrEdge = false;
    if (platform.TRIDENT || platform.EDGE) {
      this.patchForIEOrEdge = true;
    }
  }

  ngOnInit() {
    this._inputSubscription = merge(
      fromEvent(this.searchBox.nativeElement, 'input'),
      fromEvent(this.searchBox.nativeElement, 'compositionend'),
      fromEvent(this.searchBox.nativeElement, 'keyup')
    )
      .pipe(
        map(() => {
          console.log(this.searchText);
          return this.searchText;
        }),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        // この比較は、IE11で何故かフィルタオープン時にイベントが発生するので対策として追加した (#403)
        if (this.oldSearchText !== this.searchText) {
          this.oldSearchText = this.searchText;
          this._search();
          this._checkAll(true);
          this._updateItemChecked();
          this._changeDetectorRef.markForCheck();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filter) {
      this.originalUniqueValues = [];
      this.hasEmpty = false;
      this.filter.uniqueValues.forEach((v) => {
        if (v !== null && v !== undefined && v !== '') {
          this.originalUniqueValues.push({ name: this.filter.nameGetter ? this.filter.nameGetter(v) : String(v), value: v });
        } else {
          this.hasEmpty = true;
        }
      });
      const sorter = natsort({ insensitive: true });
      this.originalUniqueValues.sort((a, b) => sorter(a.name, b.name));
      this.searchText = '';
      this._search();

      if (this._allUnchecked()) {
        this._checkAll();
      }
      this._updateItemChecked();
    }
  }

  private _search() {
    if (this.hasEmpty && this.searchText === '') {
      this.uniqueValues = [{ name: '(なし)', value: null, checked: this.filter.values.has(null) }];
    } else {
      this.uniqueValues = [];
    }
    for (const a of this.originalUniqueValues) {
      if (this.searchText && a && !a.name.includes(this.searchText)) {
        continue;
      }
      this.uniqueValues.push({
        name: a.name,
        value: a.value,
        checked: this.filter.values.has(a.value),
      });
      if (this.uniqueValues.length >= this._maxCount) {
        break;
      }
    }
  }

  ngOnDestroy(): void {
    if (this._inputSubscription) {
      this._inputSubscription.unsubscribe();
      this._inputSubscription = null;
    }
  }

  applyFilter() {
    const filter = new VgGridColumnValueFilter();
    if (this.filter) {
      filter.binding = this.filter.binding;
      filter.valueGetter = this.filter.valueGetter;
    }
    if ((this._allChecked() && !this.searchText) || this._allUnchecked()) {
      this.apply.emit(null);
      return;
    }
    for (const value of this.uniqueValues) {
      if (value.checked) {
        filter.values.add(value.value);
      }
    }
    this.apply.emit(filter);
  }

  clearFilter() {
    this.apply.emit(null);
  }

  _updateItemChecked() {
    if (this._allUnchecked()) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this._allChecked()) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  _onCheckChanged(c: HTMLInputElement) {
    this._checkAll(c.checked);
    this._updateItemChecked();
  }

  private _allChecked(): boolean {
    return this.uniqueValues.every((a) => a.checked);
  }

  private _allUnchecked(): boolean {
    return this.uniqueValues.every((a) => !a.checked);
  }

  private _checkAll(checked = true) {
    this.uniqueValues = this.uniqueValues.map((a) => {
      a.checked = checked;
      return a;
    });
  }
}
