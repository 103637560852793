import { Inject, Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';

@Injectable()
export class LocalStorageService {
  private prefix = '';

  constructor(private window: WindowRefService) {}

  public setItem(key: string, value: string) {
    this.window.nativeWindow.localStorage.setItem(`${this.prefix}${key}`, value);
  }

  public getItem(key: string): string {
    return this.window.nativeWindow.localStorage.getItem(`${this.prefix}${key}`);
  }

  public removeItem(key: string) {
    this.window.nativeWindow.localStorage.removeItem(`${this.prefix}${key}`);
  }

  public getItemRaw(key: string): string {
    return this.window.nativeWindow.localStorage.getItem(`${key}`);
  }

  public setItemRaw(key: string, value: string) {
    this.window.nativeWindow.localStorage.setItem(`${key}`, value);
  }

  public setPrefix(prefix: string) {
    this.prefix = prefix;
  }
}
