export class ParentWindowNotFoundError extends Error {
  constructor() {
    super();

    Object.setPrototypeOf(this, ParentWindowNotFoundError.prototype);
    this.name = 'ParentWindowNotFoundError';
    this.message = 'Parent Window Not Found';
  }
}

export class AlreadyInitializedError extends Error {
  constructor() {
    super();

    Object.setPrototypeOf(this, AlreadyInitializedError.prototype);
    this.name = 'AlreadyInitializedError';
    this.message = 'Already Initialized';
  }
}
