<div mat-dialog-content>
  <div class="wrapper">
    <div class="body-text">
      <p>BRiSK Next 3分間デモが終了しました。発注機能付きのBRiSK Next製品版は立花証券e支店に口座をお持ちの皆様に無料で提供しています。</p>
    </div>

    <div class="buttons">
      <button type="button" class="btn btn-primary e-shiten-button" (click)="openEShitenPage()">立花証券e支店に口座を開設する</button>
      <button type="button" class="btn btn-secondary" (click)="reload()">もう一度3分間デモを体験する</button>
    </div>
  </div>
</div>
