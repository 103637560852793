import { Injectable } from '@angular/core';
import { CocomeroService } from './cocomero.service';
import { StockWrapper } from '@argentumcode/brisk-common';
import { Subject, Subscription } from 'rxjs';
import { Trace } from '@argentumcode/brisk-common';

export class MiniItaView {
  stockWrapper: StockWrapper;
  updatedSubject: Subject<{}>;
  id: number;
  skipIta: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ItaViewService {
  private eventSubscription: Subscription = null;
  private basePriceUpdatedSubscription: Subscription = null;
  private stockWrappers = new Map<number, MiniItaView>();
  private stockWrappersId = 0;
  private startCount = 0;
  private tracer: Trace;
  private tracerState = false;
  private basePriceUpdates = new Set<number>();

  private hasUpdate = false;

  constructor(private cocomero: CocomeroService) {}

  public getStock(issueCode: number, skipIta: boolean): [number, StockWrapper] {
    this.tracerState = false;
    const view = new MiniItaView();
    view.updatedSubject = new Subject<{}>();
    view.stockWrapper = new StockWrapper(
      this.cocomero.op.flexConfig,
      view.updatedSubject.asObservable(),
      this.cocomero.op.createStockView(this.cocomero.op.issueCodeMap[issueCode], false, true),
      this.cocomero.op.date,
      this.cocomero
    );
    view.stockWrapper.update = true;
    view.id = this.stockWrappersId++;
    view.skipIta = skipIta;
    this.stockWrappers.set(view.id, view);
    this.hasUpdate = true;
    return [view.id, view.stockWrapper];
  }

  public removeStock(id: number) {
    if (this.stockWrappers.has(id)) {
      this.stockWrappers.get(id).stockWrapper.dispose();
      this.stockWrappers.delete(id);
    }
  }

  public clearRowCount() {
    for (const it = this.stockWrappers.values(); ; ) {
      const result = it.next();
      if (result.done) {
        break;
      }
      result.value.stockWrapper.current.itaRowCount = null;
      console.log('cleared');
    }
  }

  private eventLoop() {
    if (!this.tracer) {
      this.tracer = this.cocomero.op.createTrace();
    }
    if (this.hasUpdate) {
      this.hasUpdate = false;
    }
    for (const it = this.stockWrappers.values(); ; ) {
      const result = it.next();
      if (result.done) {
        break;
      }
      const w = result.value.stockWrapper;
      if (this.basePriceUpdates.has(result.value.stockWrapper.base.master.issueCode)) {
        result.value.stockWrapper.base.itaRowPriceIndexUpdated = true;
      }
      const upIta = w.current.itaRowPriceIndexUpdated || w.current.itaRowCountUpdated || w.update;
      const key = `${w.current.frame}:${w.current.itaRowCountUpdated}:${w.current.itaRowCount}`;
      if (this.tracerState === false || upIta || this.tracer.has(w.current.master.id)) {
        this.cocomero.op.updateStockView(w.current, result.value.skipIta, !result.value.skipIta, upIta, true);
      }
      const newKey = `${w.current.frame}:${w.current.itaRowCountUpdated}:${w.current.itaRowCount}`;
      if (key !== newKey || w.update || upIta || w.current.specialQuoteTime) {
        result.value.updatedSubject.next({});
        w.update = false;
      }
    }
    if (this.tracerState === false) {
      this.tracerState = true;
      this.tracer.clear();
    }
    this.basePriceUpdates.clear();
  }

  public start() {
    if (this.startCount === 0 && this.eventSubscription === null) {
      this.tracerState = false;
      // TODO: unsubscirbe
      this.cocomero.op.basePriceUpdated.subscribe(([issueId, issueCode]) => {
        this.basePriceUpdates.add(issueCode);
      });
      this.eventSubscription = this.cocomero.eventLoopObservable.subscribe(() => {
        this.eventLoop();
      });
    }
    this.startCount++;
  }

  public stop() {
    this.startCount--;
    if (this.startCount === 0 && this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
      if (this.basePriceUpdatedSubscription !== null) {
        this.basePriceUpdatedSubscription.unsubscribe();
        this.basePriceUpdatedSubscription = null;
      }
    }
  }
}
