<brisk-qr-grid
  #qrGrid
  id="qrGrid"
  [shortTimestamp]="shortTimestamp"
  [normalized]="false"
  [stockOperator]="stockOperator"
  [forceSeparateComma]="true"
  [shown]="true"
  [minWidthPx]="minWidthPx"
  [showAmount]="showAmount"
></brisk-qr-grid>
