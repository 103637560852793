export class SummaryPortfolio {
  public lastPriceChange: number;
  public vwapChange: number;
  public openPriceBasePriceChange: number;
  public lastPriceChanges = new Array<number>(60).fill(undefined);
  public hitCount = 0;
  public liftCount = 0;
  public volume = 0;
  public turnover10 = 0;
  public predict = false;
  public predictOpenPrice = false;
  public turnoverPredict = false;
  public vwapPredict = false;
  public upCount = 0;
  public downCount = 0;
  public upCountPredict = false;
  public downCountPredict = false;
}
