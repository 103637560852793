import { Component, ElementRef, OnDestroy, OnInit, StaticProvider, ViewChild } from '@angular/core';
import { GlHeaderItem, GlOnResize, GlOnShow } from '@argentumcode/ngx-golden-layout';
import { JsfcComponent as CommonJsfcComponent, QrGridComponent, ResizeService } from '@argentumcode/brisk-common';
import { StockDetailService } from '../../stock-detail.service';
import { SAVE_ITEM_HEADER, SaveItemHeaderComponent } from '../../headers/save-item-header/save-item-header.component';
import { Subscription } from 'rxjs';

const timestampShowWidth = 450;
const amountShowWidth = 365;

@Component({
  selector: 'app-fita4-qr-panel',
  templateUrl: './qr-panel.component.html',
  styleUrls: ['./qr-panel.component.scss'],
})
export class QrPanelComponent implements OnInit, GlOnShow, GlOnResize, GlHeaderItem, OnDestroy {
  @ViewChild('qrGrid', { static: true }) qrGrid: QrGridComponent;
  headerComponent = SaveItemHeaderComponent;
  additionalTokens: Array<StaticProvider> = [
    {
      provide: SAVE_ITEM_HEADER,
      useValue: {
        tooltip: '歩み値CSVエクスポート',
        click: () => {
          this.export();
        },
      },
    },
  ];

  shortTimestamp = false;
  minWidthPx = 340;
  showAmount = false;
  subscription: Subscription = new Subscription();
  get stockOperator() {
    return this.stockDetailService.stockOperator;
  }
  constructor(private resize: ResizeService, private stockDetailService: StockDetailService, private element: ElementRef) {
    this.subscription.add(
      this.resize.resize.subscribe(() => {
        this.onResize();
      })
    );
  }

  ngOnInit(): void {}

  glOnShow() {
    this.resize.dispatchResize();
  }

  glOnResize(): void {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  onResize() {
    const width = this.element.nativeElement.offsetWidth;
    const fontSize = parseInt(window.getComputedStyle(document.documentElement).fontSize, 10) || 16;
    const normalizedWidth = (width * 16) / fontSize;
    this.shortTimestamp = normalizedWidth < timestampShowWidth;
    this.showAmount = normalizedWidth >= amountShowWidth;
    this.minWidthPx = fontSize * 18;
  }

  export() {
    this.qrGrid.exportCsv();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
