import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { BrowserModule } from '@angular/platform-browser';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { AngularSplitModule } from 'angular-split';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { WjGaugeModule } from '@grapecity/wijmo.angular2.gauge';
import {
  BriskBrowserModule,
  BriskCommonModule,
  BriskCoreModule,
  CanvasGridModule,
  MarketsModule,
  ResizeModule,
  ThemeModule,
  IconsModule,
} from '@argentumcode/brisk-common';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { RecommendChromeButtonComponent } from './recommend-chrome-button/recommend-chrome-button.component';
import { PlatformModule } from '@angular/cdk/platform';
import { OptionsComponent } from './options/options.component';

@NgModule({
  imports: [
    CommonModule,
    CanvasGridModule,
    BrowserModule,
    BrowserAnimationsModule,
    WjGridModule,
    WjGridFilterModule,
    WjInputModule,
    WjGaugeModule,
    AngularSplitModule,
    FormsModule,
    ResizeModule,
    NgbModule,
    BriskCommonModule,
    BriskCoreModule,
    BriskBrowserModule,
    ReactiveFormsModule,
    ThemeModule,
    IconsModule,
    MarketsModule,
    PlatformModule,
  ],
  providers: [{ provide: 'Window', useValue: window }],
  declarations: [
    AnnouncementsComponent,
    FeedbackComponent,
    InputDialogComponent,
    ModalDialogComponent,
    RecommendChromeButtonComponent,
    OptionsComponent,
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    WjGridModule,
    WjGridFilterModule,
    WjInputModule,
    WjGaugeModule,
    FormsModule,
    AngularSplitModule,
    CanvasGridModule,
    ResizeModule,
    AnnouncementsComponent,
    FeedbackComponent,
    InputDialogComponent,
    ModalDialogComponent,
    RecommendChromeButtonComponent,
    NgbModule,
    BriskCommonModule,
    ReactiveFormsModule,
    ThemeModule,
    MarketsModule,
    PlatformModule,
  ],
})
export class SharedModule {}
