import { ErrorHandler, Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  fromEvent,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  throwError,
  TimeoutError,
  timer,
} from 'rxjs';
import { catchError, filter, map, share, shareReplay, take, timeout, timeoutWith } from 'rxjs/operators';
import { FlexOperatorService } from './flex-operator.service';
import {
  AssistService,
  BriskDialogService,
  DisallowMultipleTabsService,
  ErrorHandlerService,
  FrontendApiService,
  IssueCodeInputListElement,
  IssueCodeInputService,
  KuroChartService,
  LocalStorageService,
  Market,
  MarketConditionsService,
  SessionExpireError,
  StockController,
  StockWrapper,
  ThemeService,
  TimeProvider,
  Toast,
  ToasterService,
  ToastType,
  UserAgentService,
  ViewHistoryService,
} from '@argentumcode/brisk-common';
import { FlexWebSocketService } from './flex-web-socket.service';
import { FlexOperator } from './flex-operator';
import { Summaries } from './summary.service';
import { CollectionView } from '@grapecity/wijmo';
import { ApiAuthService, ApiService, APIStockList, BootResponse } from './api.service';
import { StorageService } from './storage.service';
import { ServerWatchListNotExistsError, WatchListConflictError, WatchListService, WatchListSizeError } from './watch-list.service';
import { environment } from '../../environments/default/environment';
import { QrLazyService } from './qr-lazy.service';
import { FIXService } from '../fix/fix.service';
import { ConnectionCheckService } from './connection-check.service';
import { StockView, Trace } from '@argentumcode/brisk-common';
import { SmartPasteService } from '../portfolio/smart-paste.service';
import { EventManager } from '@angular/platform-browser';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';
import { MarketsService } from './markets.service';
import { format, isAfter, parse } from 'date-fns';
import { SimpleOrderService } from './simple-order.service';
import { VersionService } from './version.service';
import { NewsService } from './news.service';
import { ChartService } from './chart.service';

import * as Sentry from '@sentry/browser';
import { TutorialService } from '../tutorial/tutorial.service';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal } from '@angular/cdk/portal';
import { DemoUnsupportedBrowserComponent } from '../shared/demo-unsupported-browser/demo-unsupported-browser.component';
import { IssueTypesService } from './issue-types.service';

declare var require: any;
const errors = require('../../environments/default/errors.json');

class Time implements TimeProvider {
  constructor(private op: FlexOperator) {}

  getTime(): Date {
    if (this.op.serverTimestamp) {
      return this.op.serverTimestamp.getServerTimestamp(new Date());
    }
    return new Date();
  }
}

enum WebSocketEventType {
  // 接続完了
  connected,
  // 接続安定(1分間切断なし)
  steadied,
}

class WebSocketEvent {
  constructor(public type: WebSocketEventType) {}
}

interface WsFlexCounter {
  // 受信時間
  time: number;
  // 受信件数
  count: number;
}

interface BootOption {
  issueCode: number;
}

@Injectable({
  providedIn: 'root',
})
export class CocomeroService implements StockController, OnDestroy {
  // 再接続中かどうか
  public reconnecting = false;

  private portfolios: CollectionView = new CollectionView([]);

  private summaryInitializedSubject = new ReplaySubject(1);
  public summaryInitialized = this.summaryInitializedSubject.asObservable();
  public initialized = false;
  public initializedSubject = new ReplaySubject<boolean>(1);
  public bootStatus = new ReplaySubject<number>(1);
  public unserializeStart = new ReplaySubject<{}>(1);
  public trace: Trace = null;
  public portfolioTrace: Trace = null;
  public stockWrapper: StockWrapper = null;
  private stockUpdate: Subject<{}> = null;

  public op: FlexOperator;
  private date: string;
  private _dateSubject = new BehaviorSubject<Date>(null);
  public date$: Observable<Date> = this._dateSubject.asObservable().pipe(filter((d) => d !== null));
  private sessionStatus: 'done' | 'running';
  public summaries: Summaries = null;

  public issueCode: number;
  private issueCodeChangeTimer: Subscription = null;
  public updateFrame: Subject<boolean> = new Subject<boolean>();
  private nextIssueCode: number = null;

  public get marketConditions(): Array<Market> {
    return this.marketConditionService.markets;
  }

  private eventLoopSubject = new Subject<{}>();
  public eventLoopObservable = this.eventLoopSubject.asObservable();

  private count = 0;

  private issueCodeChangedSubject = new Subject<number>();
  public issueCodeChanged = this.issueCodeChangedSubject.asObservable();
  private hasPortfolioUpdate = false;

  private sessionInitializedSubject = new ReplaySubject<{}>();
  public sessionInitialized = this.sessionInitializedSubject.asObservable();

  private dateChangedSubject = new ReplaySubject<string>(1);
  public dateChanged$: Observable<string> = this.dateChangedSubject.asObservable();

  public time: Time;

  private readonly removeTabCheckFunction: Function;
  private connectionCheckSubscription: Subscription = null;
  private fixUpdatedSubscription: Subscription = null;
  private bootSubscription: Subscription;
  private websocketSubscription: Subscription = null;
  private marketSubscription: Subscription = null;

  private bootTime: Date;
  private sessionExpireTime: Date;
  private nextDateTime: Date;

  private snapshotLoadedSubject = new ReplaySubject<{}>();
  private snapshotLoaded = this.snapshotLoadedSubject.asObservable();

  public error = false;
  private marketError = new ReplaySubject<Error>();
  public marketError$ = this.marketError.asObservable();

  disableStockUpdate = false;

  private _disableChangeIssueCodeThrottle = false;
  private _lastPortfolioUpdate = 0;
  private _notAvailableIssueCode = new Subject<number>();
  notAvailableIssueCode$ = this._notAvailableIssueCode.asObservable();

  private _frontendBootData = new ReplaySubject<any>(1);
  // フロントエンドのboot endpointから提供されるセッションに含まれるデータ
  frontendBootData$ = this._frontendBootData.asObservable();

  // 1銘柄でも15:00:00に到達しかどうかを表すObservable
  private marketFinishedSubject = new BehaviorSubject<boolean>(false);
  marketFinished$: Observable<boolean> = this.marketFinishedSubject.asObservable().pipe(
    filter((a) => a),
    take(1)
  );

  private _initialIssueCode: number | undefined = undefined;

  wsFlexLastTime = 0;
  wsFlexLastTimeUpdated = 0;
  wsFlexCounterQueue: Array<WsFlexCounter> = new Array(120);
  wsFlexCounterSum = 0;
  wsFlexCounterQueueStartIndex = 0;
  wsFlexCounterQueueEndIndex = 0;
  wsFlexCounterQueueLength = 0;

  wsFlexCounterQueuePush(time: number, count: number): void {
    if (this.wsFlexCounterQueueLength === this.wsFlexCounterQueue.length) {
      this.wsFlexCounterQueuePop();
    }
    this.wsFlexCounterQueue[this.wsFlexCounterQueueEndIndex] = {
      time,
      count,
    };
    this.wsFlexCounterSum += count;
    this.wsFlexCounterQueueLength++;
    this.wsFlexCounterQueueEndIndex++;
    if (this.wsFlexCounterQueueEndIndex === this.wsFlexCounterQueue.length) {
      this.wsFlexCounterQueueEndIndex = 0;
    }
  }

  wsFlexCounterQueuePop(): void {
    if (this.wsFlexCounterQueueLength === 0) {
      return;
    }
    this.wsFlexCounterSum -= this.wsFlexCounterQueue[this.wsFlexCounterQueueStartIndex].count;
    this.wsFlexCounterQueueLength--;
    this.wsFlexCounterQueueStartIndex++;
    if (this.wsFlexCounterQueueStartIndex === this.wsFlexCounterQueue.length) {
      this.wsFlexCounterQueueStartIndex = 0;
    }
  }

  wsFlexCounterQueuePopOneSec(now: number): void {
    while (this.wsFlexCounterQueueLength > 0 && this.wsFlexCounterQueue[this.wsFlexCounterQueueStartIndex].time <= now - 1000) {
      this.wsFlexCounterQueuePop();
    }
  }

  get wsMockDone$(): Observable<{}> {
    return this.flexWs.wsMockDone$;
  }

  constructor(
    private zone: NgZone,
    private api: ApiService,
    private apiAuth: ApiAuthService,
    private flexWs: FlexWebSocketService,
    private flexOperatorService: FlexOperatorService,
    private issueCodeInput: IssueCodeInputService,
    private storage: StorageService,
    private watchList: WatchListService,
    private viewHistory: ViewHistoryService,
    private connectionCheck: ConnectionCheckService,
    private errorHandler: ErrorHandler,
    private smartPaste: SmartPasteService,
    private fix: FIXService,
    private marketConditionService: MarketConditionsService,
    private markets: MarketsService,
    private localStorage: LocalStorageService,
    public qr: QrLazyService,
    private disallowMultipleTabs: DisallowMultipleTabsService,
    private assist: AssistService,
    private simpleOrder: SimpleOrderService,
    private version: VersionService,
    private userAgent: UserAgentService,
    private news: NewsService,
    private kuroChart: KuroChartService,
    private frontendApi: FrontendApiService,
    private toaster: ToasterService,
    private chart: ChartService,
    private theme: ThemeService,
    private issueTypes: IssueTypesService,
    eventManager: EventManager,
    private platform: Platform,
    private dialog: BriskDialogService
  ) {
    let bootOption: Observable<BootOption | null> = of(null);
    if (window.opener && environment.zenitaOrigin) {
      bootOption = fromEvent(window, 'message' as const).pipe(
        filter((event: MessageEvent) => {
          return event.origin === environment.zenitaOrigin;
        }),
        take(1),
        shareReplay(),
        timeout(3 * 1000),
        map((r: MessageEvent) => {
          return r.data as BootOption;
        }),
        catchError((err) => {
          if (err instanceof TimeoutError) {
            return of(null);
          }
          return throwError(err);
        })
      );
      bootOption.subscribe();
      (window.opener as Window).postMessage(
        {
          message: 'boot',
        },
        environment.zenitaOrigin
      );
    }
    if (this.errorHandler instanceof ErrorHandlerService) {
      this.errorHandler.setErrorMessages(errors);
    }

    if (environment.disallowMultipleTabs) {
      this.disallowMultipleTabs.check();
    }
    if (environment.showAssistButton) {
      this.assist.setup(environment.assistUrl);
    }
    this.watchList.watchListError.subscribe((err) => {
      if (err && err.name === 'WatchListSizeError') {
        const toast = new Toast('登録銘柄リストが大きすぎるため、サーバーへの保存を行うことが出来ませんでした。', ToastType.Error);
        toast.delay = 60 * 60 * 1000;
        this.toaster.add(toast);
      } else if (err && err.name === 'ServerWatchListNotExistsError') {
      } else if (err && err.name === 'WatchListConflictError') {
        const toast = new Toast('登録銘柄リストの変更に競合がありました。', ToastType.Info);
        toast.delay = 60 * 60 * 1000;
        this.toaster.add(toast);
      } else {
        const toast = new Toast('登録銘柄リストのサーバーへの保存に失敗しました。', ToastType.Error);
        toast.delay = 60 * 60 * 1000;
        this.toaster.add(toast);
      }
      Sentry.captureException(err);
    });
    // WASMの初期化 / APIからの手記情報の取得
    this.frontendApi.boot().subscribe((frontendBoot) => {
      this.api.setEndpoint(frontendBoot.apiEndpoint, frontendBoot.apiToken, frontendBoot.apiLocalPrefer);
      this.apiAuth.setApiToken(frontendBoot.apiToken, frontendBoot.userId);

      this._frontendBootData.next(frontendBoot.data);
      this._frontendBootData.complete();

      const bootJoin = forkJoin([this.api.boot(), this.frontendApi.getWatchlist(), this.flexOperatorService.initialized]);
      bootJoin.subscribe(([boot, watchListResp, _]) => {
        // セッション情報を送信する
        if (this.errorHandler instanceof ErrorHandlerService) {
          this.errorHandler.setUserContext(frontendBoot.userId);
          this.errorHandler.errorRaised.subscribe(() => {
            this.error = true;
            if (this.websocketSubscription) {
              this.websocketSubscription.unsubscribe();
            }
            if (this.marketSubscription) {
              this.marketSubscription.unsubscribe();
            }
            console.log('error');
          });
        }
        this.simpleOrder.initialize(frontendBoot.data);
        this.date = boot.date;
        this._dateSubject.next(parse(this.date));
        this._dateSubject.complete();
        this.sessionStatus = <any>boot.sessionStatus;
        this.kuroChart.setup(frontendBoot.chartToken, frontendBoot.chartOrigin + '/', frontendBoot.chartOrigin, this.date);
        if (environment.usePrefix) {
          if (frontendBoot.data.prev_user_id) {
            this.localStorage.setPrefix(`user_${frontendBoot.data.prev_user_id}_`);
          } else {
            this.localStorage.setPrefix(`user_${frontendBoot.userId}_`);
          }
        }
        this.sessionInitializedSubject.next({});
        // Local Storageからのデータ読み出し
        if (watchListResp.error) {
          const toast = new Toast(
            'サーバーに保存されている登録銘柄リストの取得に失敗しました。このブラウザで最後に起動した時の登録銘柄リストを表示します。',
            ToastType.Error
          );
          toast.delay = 60 * 60 * 1000;
          this.toaster.add(toast);
          Sentry.captureException(watchListResp.err);
        }
        this.version.initialize();
        this.viewHistory.loadFromLocalStorage();
        this.watchList.setup(frontendBoot.userId, watchListResp);
        this.news.start();
        this.news.pushRevision(frontendBoot.newsRevision);

        // 終了時刻 / 次回起動時刻を設定する
        const now = new Date().getTime();
        this.bootTime = new Date();
        if (frontendBoot.sessionExpires) {
          this.sessionExpireTime = new Date((frontendBoot.sessionExpires - boot.time + 60 + 300 * Math.random()) * 1000 + now);
        } else {
          this.sessionExpireTime = new Date(Number(new Date()) + 100000000000);
        }
        this.nextDateTime = new Date((boot.nextDate - boot.time + 60 + 240 * Math.random()) * 1000 + now);

        // 時刻の設定
        this.op = new FlexOperator(this.flexOperatorService, boot.flexVersion);
        this.time = new Time(this.op);
        this.op.marketFinished$.subscribe((f) => {
          this.marketFinishedSubject.next(true);
          // this.marketFinishedSubject.complete();
        });

        if (!this.checkBrowserForNextTrial()) {
          this.dialog
            .openDialog(
              {
                content: new ComponentPortal(DemoUnsupportedBrowserComponent),
                closeOnEscape: false,
                title: 'BRiSK Next未対応環境です',
                showCloseButton: false,
              },
              {
                width: '48em',
              }
            )
            .subscribe();
          return;
        }

        // WebSocketとOperator間のデータのやりとりの設定
        this.flexWs.onData.subscribe((data) => {
          const [ts, count] = this.op.pushWs(data);
          if (count !== 0) {
            this.wsFlexLastTime = Math.max(ts, this.wsFlexLastTime);
            this.wsFlexLastTimeUpdated = performance.now();
            this.wsFlexCounterQueuePush(this.wsFlexLastTimeUpdated, count);
          }
          this.op.applyBasePrice();
        });
        this.op.onSendData.subscribe((data) => {
          this.flexWs.send(data);
        });

        if (this.sessionStatus === 'running') {
          // 起動中ならWebsocketの接続
          this.startWebSocket(boot);
        }
        this.bootStatus.next(5); // 5%起動完了

        // マスタと初期情報の読み出し
        forkJoin([this.api.master(boot.master), this.api.stocksInfo(this.date), this.api.stockLists(this.date)]).subscribe(
          ([buf, stockInfo, serverSideStockLists]) => {
            this.op.push(buf);
            if (!this.op.loadMaster()) {
              throw new Error('マスタ読み込みエラー');
            }
            this.issueTypes.initialize(this.op.date);
            this.viewHistory.clean(this.op.issueCodeMap);
            // SQ / BasePriceの設定
            if (boot.exceptionalSQ) {
              for (const sqi of boot.exceptionalSQ) {
                this.op.pushExceptionalSQ(
                  sqi.issueCode,
                  sqi.side === 'B' ? 0 : 1,
                  sqi.range * 10,
                  sqi.sec,
                  sqi.quoteLimitDown * 10,
                  sqi.quoteLimitUp * 10
                );
              }
            }
            if (boot.basePrices) {
              for (const bp of boot.basePrices) {
                this.op.pushBasePrice(bp);
              }
              this.op.applyBasePrice();
            }
            this.op.basePriceUpdated.subscribe(([issueId, issueCode]) => {
              if (this.stockWrapper && this.stockWrapper.base.master.issueCode === issueCode) {
                this.stockWrapper.base.itaRowPriceIndexUpdated = true;
              }
            });
            this.op.setStockInfo(stockInfo);

            this.initializeFlex(serverSideStockLists.stock_lists); // マスタから各種情報の設定

            this.bootStatus.next(10); // 10%起動完了

            this.startMarket(boot.series || 0); // 市況の起動

            // 銘柄スナップショットの読み出し
            let length = 20 * 1000000;
            this.api.stocks(boot.snapshot).subscribe((event) => {
              // AngularのバグでProductionビルドでは、型情報が消えるため、オブジェクトを直接操作
              if (event['body']) {
                const opBuf = new Uint8Array(event['body']);

                // プログレスバーの表示を更新するために、setTimeoutを利用
                this.bootStatus.next(10 + (this.sessionStatus === 'running' ? 80 : 90));
                this.unserializeStart.next({});
                this.unserializeStart.complete();

                bootOption.subscribe((opt) => {
                  if (opt) {
                    this._initialIssueCode = opt.issueCode;
                  }
                  setTimeout(() => {
                    this.op.unserialize(opBuf);
                    // 銘柄スナップショットの取得完了
                    this.snapshotLoadedSubject.next({});
                    this.snapshotLoadedSubject.complete();
                    if (this.sessionStatus === 'done') {
                      this.initializeDone();
                    }
                  });
                });
              } else if (event['headers']) {
                length = Number(event['headers'].get('x-original-length'));
              } else if (event['loaded']) {
                if (length > 0) {
                  this.bootStatus.next(10 + ((this.sessionStatus === 'running' ? 80 : 90) * event['loaded']) / length);
                }
              }
            });
          }
        );
      });
    });
  }

  private startWebSocket(bootResponse: BootResponse) {
    const [backoffConfig, backoffReset] = this.timebasedBackoff(() => {
      this.reconnecting = true;
      console.log('WebSocket再接続');
    });
    this.websocketSubscription = this.connectWebSocket(bootResponse, this.snapshotLoaded)
      .pipe(retryBackoff(backoffConfig))
      .subscribe(
        (event) => {
          if (event.type === WebSocketEventType.connected) {
            this.reconnecting = false;
            console.log('WebSocket接続完了');
          } else if (event.type === WebSocketEventType.steadied) {
            backoffReset();
            console.log('WebSocket接続安定');
          }
        },
        (err) => {
          console.log('WebSocket接続エラー(再接続失敗)');
          throw err;
        },
        () => {
          this.reconnecting = false;
        }
      );
  }

  private startMarket(series: number) {
    const [marketBackoffConfig, marketBackoffReset] = this.timebasedBackoff(() => {});

    this.marketSubscription = this.markets
      .start(series, parse(this.date), this.sessionStatus !== 'done', marketBackoffReset)
      .pipe(retryBackoff(marketBackoffConfig))
      .subscribe(
        (arrD) => {
          const additionalMarkets = this.marketConditionService.pushMarkets(
            arrD,
            (issueCode) => this.op.stockMasters[this.op.issueCodeMap[issueCode]]
          );
          for (const market of additionalMarkets) {
            this.summaries.pushMarket(market);
          }
          if (additionalMarkets.length > 0) {
            this.summaries.applyMarket();
          }
          if (arrD.length > 0) {
            marketBackoffReset();
          }
        },
        (err) => {
          this.marketError.next(err || new Error('MarketError'));
          console.error('Market Error');
        },
        () => {
          console.log('Market Done');
        }
      );
  }

  private connectWebSocket(bootResponse: BootResponse, initializedObservable: Observable<any>): Observable<WebSocketEvent> {
    return new Observable((subject) => {
      if (this.error) {
        subject.complete();
      }
      const bootObservable = !bootResponse ? this.api.boot() : of(bootResponse);
      let closedSubscription: Subscription;
      let connectedSubscription: Subscription;
      let timerSubscription: Subscription;
      let updateNumberSubscription: Subscription;
      let stockUpdateSubscription: Subscription;
      const bootSubscription = bootObservable.subscribe(
        (boot) => {
          if (boot.sessionStatus === 'done') {
            subject.complete();
            return;
          }
          connectedSubscription = this.flexWs.connected$.subscribe(() => {
            subject.next(new WebSocketEvent(WebSocketEventType.connected));
            this.zone.runOutsideAngular(() => {
              timerSubscription = timer(60 * 1000).subscribe(() => {
                this.zone.runGuarded(() => {
                  subject.next(new WebSocketEvent(WebSocketEventType.steadied));
                });
              });
            });
          });
          if (boot.wsUrl.includes('realtime/')) {
            this.flexWs.url = this.api.getWsEndpoint(boot.wsUrl);
          } else {
            this.flexWs.url = this.api.getWsEndpoint(boot.wsUrl.replace('realtime', 'realtime/0'));
            boot.series = undefined;
          }
          this.op.prepareReconnect();
          // Heartbeatのリセット
          this.op.lastHeartbeat = null;
          this.connectionCheck.startCheckFlexTimestamp(this.op);
          closedSubscription = merge(this.flexWs.error, this.connectionCheck.connectionClosed).subscribe((err) => {
            console.log('Connection Error', err);
            subject.error(err);
            return;
          });
          updateNumberSubscription = forkJoin([
            initializedObservable,
            this.op.websocketFrameNumberReceived.pipe(timeoutWith(20 * 1000, throwError(new Error('Websocket Timeout')))),
          ]).subscribe(
            ([_, wsNumber]) => {
              const apiNumber = this.op.getFrameNumbers();
              const req: Array<{ issueCodeIdx: number; from: number; to: number }> = [];
              for (let i = 0; i < apiNumber.length; i++) {
                if (apiNumber[i] < wsNumber[i]) {
                  req.push({ issueCodeIdx: i, from: apiNumber[i], to: wsNumber[i] });
                }
              }
              const obs = req.length > 0 ? this.api.stocksUpdate(this.date, req, boot.series) : of(null);
              stockUpdateSubscription = obs
                .pipe(
                  retryBackoff({
                    initialInterval: 1000,
                    maxRetries: 5,
                    maxInterval: 20000,
                    backoffDelay: (it, initialInterval) => {
                      return 1000 + it * 1000;
                    },
                    shouldRetry: (err: any) => {
                      if (err && err.response && err.response.status === 401) {
                        // 認証エラー
                        return false;
                      }
                      if (err && err.response) {
                        if (err.response.headers.get('x-error-reason') === 'too-old') {
                          return false;
                        }
                      }
                      return true;
                    },
                  })
                )
                .subscribe(
                  (buf) => {
                    if (buf) {
                      this.op.push(buf);
                    }
                    this.reconnecting = false;
                    this.op.apiStart();
                    this.initializeDone();
                  },
                  (error) => {
                    subject.error(error);
                  }
                );
            },
            (error) => {
              subject.error(error);
            }
          );
          this.flexWs.start();
        },
        (err) => {
          subject.error(err);
        }
      );
      return () => {
        bootResponse = null;
        console.log('Cleaned');
        if (closedSubscription) {
          closedSubscription.unsubscribe();
        }
        if (connectedSubscription) {
          connectedSubscription.unsubscribe();
        }
        if (updateNumberSubscription) {
          updateNumberSubscription.unsubscribe();
        }
        if (timerSubscription) {
          timerSubscription.unsubscribe();
        }
        if (stockUpdateSubscription) {
          stockUpdateSubscription.unsubscribe();
        }
        bootSubscription.unsubscribe();
        this.flexWs.stop();
        this.connectionCheck.stopCheckFlexTimestamp();
      };
    });
  }

  wsMockStart() {
    this.flexWs.wsMockStart();
  }

  public hasIssueCode(issueCode: number) {
    return issueCode in this.op.issueCodeMap;
  }

  private initializeFlex(serverSideSummaries: Array<APIStockList>) {
    // 銘柄補完の作成
    this.issueCodeInput.stockSource = this.op.stockMasters.map(
      (master) =>
        new IssueCodeInputListElement({
          issueCode: master.issueCode,
          name: master.name,
        })
    );
    this.smartPaste.initialize(this.op.stockMasters.map((master) => master.issueCode));
    this.summaries = new Summaries(
      this.op,
      this.storage,
      this.watchList,
      this.viewHistory,
      this.issueTypes.issueTypesForSummaries(),
      serverSideSummaries
    );
    this.summaryInitializedSubject.next();
    this.summaryInitializedSubject.complete();
  }

  private initializeDone() {
    if (this.initialized) {
      return;
    }
    const stocks = {};
    this.bootStatus.next(100);
    for (const issueCode of this.op.stockMasters.map((a) => a.issueCode)) {
      stocks[issueCode] = true;
    }
    const firstIssueCode =
      this._initialIssueCode || this.viewHistory.getLatestIssueCode(stocks) || environment.flexConfig.fallbackIssueCode;
    this.trace = this.op.createTrace();

    this.changeIssueCodeImpl(firstIssueCode);
    this.initialized = true;
    this.initializedSubject.next(true);
    this.initializedSubject.complete();
    this.summaries.summaryChanged.subscribe(() => {
      this.hasPortfolioUpdate = true;
    });
    this.dateCheck();
    this.eventLoop();
  }

  public changeIssueCode(issueCode) {
    if (this._disableChangeIssueCodeThrottle) {
      this.changeIssueCodeImpl(issueCode);
      return;
    }
    if (this.issueCodeChangeTimer === null) {
      this.changeIssueCodeImpl(issueCode);
      this.setIssueCodeTimer();
    } else {
      this.nextIssueCode = issueCode;
      this.issueCodeChangeTimer.unsubscribe();
      this.setIssueCodeTimer();
    }
  }

  disableChangeIssueCodeThrottle() {
    this._disableChangeIssueCodeThrottle = true;
  }

  private changeIssueCodeImpl(issueCode: number) {
    if (this.issueCode !== issueCode) {
      if (!(issueCode in this.op.issueCodeMap)) {
        // 銘柄コードが存在しない場合、ログを取得し無視する
        Sentry.captureException(new Error(`Invalid Issue Code ${issueCode}`));
        this._notAvailableIssueCode.next(issueCode);
        return;
      }
      console.log('issueCodeChanged', issueCode);
      this.issueCode = issueCode;
      this.viewHistory.pushStock(issueCode);
      this.summaries.updateHistory();
      if (this.stockUpdate !== null) {
        this.stockUpdate.complete();
        this.stockUpdate = null;
      }
      const lastMaxItaRowCount = this.stockWrapper && this.stockWrapper.viewMaxItaRow;
      this.stockUpdate = new Subject<{}>();
      this.stockWrapper = new StockWrapper(
        environment.flexConfig,
        this.stockUpdate.asObservable(),
        this.op.createStockView(this.op.issueCodeMap[issueCode]),
        this.op.date,
        this
      );
      this.stockWrapper.current.itaRowCount = lastMaxItaRowCount
        ? Math.min(lastMaxItaRowCount - 3, this.stockWrapper.current.master.maxItaRowCount - 3)
        : null;
      if (this.stockWrapper.current.itaRowCount !== null) {
        this.stockWrapper.current.itaRowCount = Math.max(this.stockWrapper.current.itaRowCount, 1);
      }
      this.stockWrapper.viewMaxItaRow = lastMaxItaRowCount;
      this.stockWrapper.update = true;
      this.issueCodeChangedSubject.next(issueCode);
    }
  }

  updateHistory(issueCode: number) {
    this.viewHistory.pushStock(issueCode);
    this.summaries.updateHistory();
  }

  private setIssueCodeTimer() {
    this.issueCodeChangeTimer = timer(100).subscribe(() => {
      this.issueCodeChangeTimer = null;
      if (this.nextIssueCode !== null) {
        this.changeIssueCodeImpl(this.nextIssueCode);
      }
      this.nextIssueCode = null;
    });
  }

  private eventLoop() {
    const upIta = this.stockWrapper.current.itaRowPriceIndexUpdated || this.stockWrapper.current.itaRowCountUpdated;
    const upByEv = `${this.stockWrapper.base.frame}|${this.stockWrapper.current.frame}`;
    if (upIta || this.trace.has(this.stockWrapper.base.master.id)) {
      this.op.updateStockView(this.stockWrapper.base);
    }
    if (this.stockWrapper.base !== this.stockWrapper.current) {
      this.op.updateStockView(this.stockWrapper.current);
    }
    if (this.count % 10 === 0) {
      this.updatePortfolioRequest(this.count % 100 === 0);
    }
    this.count++;
    if (!this.disableStockUpdate) {
      if (this.stockUpdate || this.stockWrapper.current.specialQuoteTime) {
        if (
          upByEv !== `${this.stockWrapper.base.frame}|${this.stockWrapper.current.frame}` ||
          upIta ||
          this.stockWrapper.update ||
          this.stockWrapper.current.specialQuoteTime
        ) {
          this.stockWrapper.update = false;
          this.zone.run(() => {
            this.stockUpdate.next();
          });
        }
      }
    }
    this.eventLoopSubject.next();
    this.trace.clear();
    this.zone.runOutsideAngular(() => {
      window.requestAnimationFrame(this.eventLoop.bind(this));
    });
  }

  updatePortfolioRequest(updateSummary: boolean) {
    if (updateSummary) {
      this.updatePortfolio(updateSummary);
    } else {
      if (performance.now() - this._lastPortfolioUpdate > 160) {
        this.updatePortfolio(false);
        this._lastPortfolioUpdate = performance.now();
      }
    }
  }

  private updatePortfolio(updateSummary: boolean) {
    let update = false;
    if (!this.portfolioTrace) {
      update = this.op.updatePortfolios();
      this.portfolioTrace = this.op.createTrace();
    } else {
      update = this.op.updatePortfolios(this.portfolioTrace.get());
      this.portfolioTrace.clear();
    }
    if (update) {
      this.hasPortfolioUpdate = true;
    }
    if (updateSummary) {
      if (this.hasPortfolioUpdate) {
        this.zone.runGuarded(() => {
          this.summaries.update();
          this.chart.update(this.op);
          update = true;
          this.hasPortfolioUpdate = false;
        });
      }
    }
    if (update) {
      this.zone.runGuarded(() => {
        this.updateFrame.next(updateSummary);
      });
    }
  }

  public createSnapshot(stockWrapper) {
    if (stockWrapper) {
      if (stockWrapper.current) {
        // TODO: Dispose
      }
      stockWrapper.current = this.op.cloneStockView(this.stockWrapper.base);
      stockWrapper.update = true;
    }
  }

  public live(stockWrapper: StockWrapper) {
    if (stockWrapper) {
      stockWrapper.base.itaRowPriceIndex =
        stockWrapper.current.itaRowPriceIndex - stockWrapper.base.itaRowCount + stockWrapper.current.itaRowCount;
      stockWrapper.base.itaRowCount = stockWrapper.current.itaRowCount;
      if (stockWrapper.current !== stockWrapper.base) {
        // TODO: Dispose
      }
      stockWrapper.current = stockWrapper.base;
      stockWrapper.update = true;
    }
  }

  goUpdateNumber(view: StockView, updateNumber: number) {
    this.op.goUpdateNumber(view, updateNumber);
  }

  private dateCheck() {
    this.zone.runOutsideAngular(() => {
      timer(0, 60 * 1000).subscribe(() => {
        this.zone.runGuarded(() => {
          const now = new Date();
          if (isAfter(now, this.sessionExpireTime)) {
            throw new SessionExpireError();
          } else if (isAfter(this.nextDateTime, this.bootTime) && isAfter(now, this.nextDateTime)) {
            this.dateChangedSubject.next(format(this.nextDateTime, 'YYYY-MM-DD'));
            this.dateChangedSubject.complete();
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.removeTabCheckFunction) {
      this.removeTabCheckFunction();
    }
    if (this.issueCodeChangeTimer) {
      this.issueCodeChangeTimer.unsubscribe();
    }
    if (this.bootSubscription) {
      this.bootSubscription.unsubscribe();
    }

    this.marketFinishedSubject.complete();
  }

  timebasedBackoff(backoffCallback?: () => void): [RetryBackoffConfig, () => void] {
    let backoffCount = 0;
    let backoffStart = 0;
    return [
      {
        initialInterval: 1000,
        maxRetries: 1000,
        maxInterval: 20000,
        backoffDelay: (it, initialInterval) => {
          return Math.pow(1.8, backoffCount) * initialInterval;
        },
        shouldRetry: (err: any) => {
          if (err && err.response && err.response.status === 401) {
            // 認証エラー
            return false;
          }
          if (backoffCount === 0) {
            backoffStart = performance.now();
          }
          backoffCount++;
          if (this.error) {
            return false;
          }
          // 60秒間接続に失敗したらBackoffを終了する
          if ((performance.now() - backoffStart) / 1000 > 60) {
            console.log('backoff timeout');
            return false;
          }
          if (backoffCallback) {
            backoffCallback();
          }
          return true;
        },
      },
      () => {
        backoffCount = 0;
      },
    ];
  }

  getVersion(versionStr): number {
    try {
      const version = versionStr.split(/[^0-9]/, 2)[0];
      return Number(version);
    } catch {
      return null;
    }
  }

  checkBrowser(): boolean {
    if (!window.navigator.userAgent) {
      return true; // 判定不能
    }
    const ua = this.userAgent.parsedUserAgent;

    // Check Browser Version
    if (
      (ua.name === 'Firefox' && this.getVersion(ua.version) < 63) ||
      (ua.name === 'Chrome' && this.getVersion(ua.version) < 69) ||
      (ua.name === 'Edge' && this.getVersion(ua.version) < 15) ||
      (ua.name === 'Safari' && this.getVersion(ua.version) < 11)
    ) {
      console.log(ua.version);
      console.log([ua.name, this.getVersion(ua.version)]);
      return false;
    }
    return true;
  }

  checkBrowserForNextTrial(): boolean {
    if (!window.navigator.userAgent) {
      return true; // 判定不能
    }
    const ua = this.userAgent.parsedUserAgent;

    if (ua.category === 'smartphone' || ua.category === 'mobilephone') {
      return false;
    }
    return true;
  }

  checkBrowserForOrder(): boolean {
    if (!window.navigator.userAgent) {
      return false; // 判定不能
    }
    const ua = this.userAgent.parsedUserAgent;
    if (ua.os === 'Windows 10') {
      return (ua.name === 'Chrome' || ua.name === 'Edge') && this.platform.BLINK;
    } else if (ua.os === 'Mac OSX') {
      return ua.name === 'Chrome' && this.platform.BLINK;
    } else {
      return false;
    }
  }

  checkWin7(): boolean {
    const ua = this.userAgent.parsedUserAgent;

    return !(ua.os === 'Windows 7' && this.userAgent.isWin32 && (ua.name === 'Firefox' || ua.name === 'Internet Explorer'));
  }

  updateTheme(theme: string) {
    this.storage.theme = theme;
    this.theme.theme = theme;
  }

  wsFlexTimeDiff(): number {
    return performance.now() - this.wsFlexLastTimeUpdated;
  }

  wsFlexTimeCountOneSec(): number {
    const now = performance.now();
    this.wsFlexCounterQueuePopOneSec(now);
    return this.wsFlexCounterSum;
  }
}
