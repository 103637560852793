import { Injectable } from '@angular/core';
import { BootResponse } from './api.service';

export interface ISimpleOrderService {
  initialize(boot: BootResponse);

  orderWithIssueCode(issueCode: number);
}

@Injectable()
export class SimpleOrderService implements ISimpleOrderService {
  constructor() {}

  orderWithIssueCode(issueCode: number, orderType?: string) {
    // ignore
  }

  initialize(boot: any) {
    // Do nothing
  }
}
