import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Market } from '../market-conditions.service';
import { VgCollectionView } from '../../vg-grid/collections/data-collecton';
import { VgGridComponent } from '../../vg-grid/vg-grid/vg-grid.component';
import { FocusOutEvent } from '../../vg-grid/vg-grid-filter/vg-grid-filter.component';

@Component({
  selector: 'brisk-markets-grid',
  templateUrl: './markets-grid.component.html',
  styleUrls: ['./markets-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketsGridComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  get dataSource(): Array<Market> {
    return this._dataSource;
  }

  set dataSource(value: Array<Market>) {
    if (value === null || value === undefined) {
      return;
    }

    if (this._dataSource !== value) {
      if (
        this._internalArray.length > 0 &&
        value.length > this._internalArray.length &&
        this._internalArray[this._internalArray.length - 1] === value[0]
      ) {
        // 並び替えが発生しうるので、既存のデータも更新する
        for (let i = 0; i < this._internalArray.length; i++) {
          this._internalArray[this._internalArray.length - i - 1] = value[i];
        }
        for (let i = this._internalArray.length; i < value.length; i++) {
          const data = value[i];
          this._internalArray.unshift(data);
        }
        this._internalSourceSubject.next(this._internalArray);
      } else {
        this._internalArray.splice(0, this._internalArray.length);
        for (let i = 0; i < value.length; i++) {
          const data = value[value.length - i - 1];
          this._internalArray.push(data);
        }
        this._internalSourceSubject.next(this._internalArray);
      }
      this._dataSource = value;
    }
  }

  @Input()
  public issueCode$: Observable<number>;

  @Input()
  public clickMode: 'click' | 'dblclick' = 'click';

  @Output()
  public stockSelected = new EventEmitter<number>();

  @Output()
  public dblClicked = new EventEmitter<{}>();

  @Output()
  public columnFilterFocusOut = new EventEmitter<FocusOutEvent>();

  @Output()
  public filterOpening = new EventEmitter<{}>();

  @Output()
  public filterClosed = new EventEmitter<{}>();

  @Input()
  public minWidth: number = null;

  @Input()
  public disableEdgeScrollBugFix = false;

  @ViewChild('grid', { static: true })
  private _grid: VgGridComponent;

  private _dataSource: Array<any>;

  private _internalArray: Array<any> = [];
  private _internalSourceSubject = new BehaviorSubject<Array<any>>([]);
  public collectionView: VgCollectionView = new VgCollectionView(this._internalSourceSubject.asObservable());

  private issueCodeChangedSubscription: Subscription = null;

  private updating = false;

  constructor() {}

  ngOnInit() {
    if (this.issueCode$) {
      this.issueCodeChangedSubscription = this.issueCode$.subscribe((issueCode) => {
        if (this.updating) {
          return;
        }
        if (this._grid.selectedItem && (!this._grid.selectedItem.issue_code || this._grid.selectedItem.issue_code === issueCode)) {
          return;
        }
        this._grid.setCurrentItem(null);
      });
    }
  }

  ngOnDestroy() {
    this._internalSourceSubject.complete();
    if (this.issueCodeChangedSubscription !== null) {
      this.issueCodeChangedSubscription.unsubscribe();
      this.issueCodeChangedSubscription = null;
    }
  }

  onSelectionChanged(grid: VgGridComponent) {
    if (!grid.selectedItem) {
      return;
    }
    if (this.clickMode !== 'click') {
      return;
    }
    if (grid.selectedItem.issue_code) {
      this.updating = true;
      try {
        this.stockSelected.emit(grid.selectedItem.issue_code);
      } finally {
        this.updating = false;
      }
    }
  }

  onSorted() {
    this._grid.setCurrentItem(null);
  }

  onFiltered() {
    this._grid.setCurrentItem(null);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  onDblClick(e: MouseEvent) {
    if (!this._grid.selectedItem) {
      return;
    }
    if (this._grid.selectedItem.issue_code) {
      this.updating = true;
      try {
        this.stockSelected.emit(this._grid.selectedItem.issue_code);
        this.dblClicked.emit();
      } finally {
        this.updating = false;
      }
    }
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      console.log('focus');
      this._grid.scrollIntoView();
    });
  }
}
