<brisk-vg-grid
  #grid
  [itemsSource]="dataSource"
  [rowHeight]="rowSize"
  [selectionMode]="'MultipleRow'"
  (selectionChanged)="onSelectionChanged()"
  (beginningEdit)="onBeginningEdit($event)"
  (cellEditEnding)="onCellEditEnding($event)"
  (keydown)="onKeyDown(grid, $event)"
  [sortDescriptorIndex]="sortDescriptorIndex"
  (rowDeleting)="onRowDeleting($event)"
  (cellClick)="onClick($event)"
  (cellDblClick)="onCellDblClick($event)"
  (rowDragged)="onDraggedRow($event)"
  (rowDragStart)="onDraggingRow($event)"
  (cellHeaderClick)="onCellHeaderClick($event)"
  (cellHeaderContextMenu)="headerContextMenu.next($event)"
  [contextMenu]="portfolioContextMenu"
  [contextMenuSubject]="undefined"
  [vgGridColumnsState]="columnsState"
  briskVgGridFlexibleRowSize
>
  <!-- ヘッダ -->
  <ng-template briskVgGridCellTemplate [cellType]="'GroupHeader'" let-item="item">
    <div class="text-left add-padding">
      <button class="p-group-header-btn" (click)="updateCollapsed(item)">
        <ng-container *ngIf="item.collapsed; else notCollapsedGroup">
          <fa-icon [icon]="['far', 'plus-square']"></fa-icon>
        </ng-container>
        <ng-template #notCollapsedGroup>
          <fa-icon [icon]="['far', 'minus-square']"></fa-icon>
        </ng-template>
      </button>
      &nbsp;{{ item.name }}
    </div>
  </ng-template>

  <!-- Index -->
  <brisk-vg-grid-column
    *ngIf="showIndex"
    [width]="applyMinWidth ? 2 : '0.65*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 2 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="index"
    header=""
    align="center"
    [isHeader]="true"
    [allowRowDragging]="true"
    [allowSorting]="false"
    [binding]="'index'"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="p-index-box">{{ item.index }}</div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 銘柄コード -->
  <brisk-vg-grid-column
    *ngIf="showIssueCode"
    cssClass="issueCode"
    [width]="applyMinWidth ? 5 : '1.2*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="issueCode"
    header="コード"
    binding="issueCode"
    align="center"
    [allowEditing]="editable"
    [allowSorting]="allowSorting"
    [disableHide]="true"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="item.issueCode">
        <span>{{ item.issueCode }}</span>
      </ng-container>
      <ng-container *ngIf="!item.issueCode">
        <div ngbTooltip="クリックで銘柄を追加" container="body">
          <span>+</span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template briskVgGridCellTemplate [cellType]="'CellEdit'" let-item="item" let-editingContext="editingContext">
      <div class="issueCodeEdit">
        <brisk-issue-code-input
          (keydown.enter)="onActiveEditorKeyDownImpl($event)"
          class="p-portfolio-issue-code-input"
          [grid]="true"
          [autoFocus]="true"
          (stockSelected)="onStockSelected(editingContext, $event)"
          (invalidInput)="invalidInput.emit($event)"
          [defaultInput]="editingContext.cleaned ? editingContext.value : null"
          (lostFocus)="onIssueCodeInputLostFocus()"
        ></brisk-issue-code-input>
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- Hit/Lift Box -->
  <brisk-vg-grid-column
    *ngIf="showHitLiftBar"
    [width]="'0.3*'"
    name="hitLiftBar"
    header=""
    align="center"
    [allowRowDragging]="false"
    [allowSorting]="false"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <app-hit-lift-bar [hitCount]="item.hitCount" [liftCount]="item.liftCount" [issueCount]="item.uniqueIssueCount"></app-hit-lift-bar>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 現値 -->
  <brisk-vg-grid-column
    *ngIf="showPrice"
    [width]="applyMinWidth ? 7.0 : '1.85*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 7.0 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    name="price10"
    binding="price"
    header="現値"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    [filterRoundPrecision]="1"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div
        class="text-right hitListItem add-padding"
        [ngClass]="item.hitLift ? 'hitListItem-' + item.hitLift : ''"
        [style.backgroundColor]="item.hitLift | hitLiftBackground: item.hitLiftOpacity:hitColor:liftColor"
      >
        <ng-container *ngIf="item.predict">(</ng-container>
        <span *ngIf="item.specialQuote" [ngClass]="item.specialQuoteSide | sideClass">{{ item.specialQuoteStr }} </span
        >{{ item.price10 | formatPrice }}
        <ng-container *ngIf="item.predict">)</ng-container>
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 騰落率 -->
  <brisk-vg-grid-column
    *ngIf="formerPriceChange && showLastPriceBasePriceChanges"
    [width]="applyMinWidth ? 5.5 : '1.45*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    name="lastPriceBasePriceChanges"
    binding="lastPriceBasePriceChange100"
    header="騰落率"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [visible]="visibleLastPriceBasePriceChanges"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <div class="text-right" [ngbTooltip]="item.simpleAverage ? '騰落率単純平均' : null">
          <span [ngClass]="item.lastPriceBasePriceChange | priceChangeClass">
            {{ item.lastPriceBasePriceChange | priceChanges | predictTransform: item.predict }}
          </span>
        </div>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'single'"
    [width]="'0.8*'"
    name="orderBtn"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode)" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        発注
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(現買) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'triple'"
    [width]="'0.8*'"
    name="orderBtnBid"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'bid')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        現買
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(現売) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'triple'"
    [width]="'0.8*'"
    name="orderBtnAsk"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'ask')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        現売
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(信用) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'triple'"
    [width]="'0.8*'"
    name="orderBtnMargin"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'margin')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        信用
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(現買) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'monex'"
    [width]="'0.5*'"
    name="orderBtnBid"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'cashBuy')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        買
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(現売) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'monex'"
    [width]="'0.5*'"
    name="orderBtnBid"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'cashSell')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        売
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(信新) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'monex'"
    [width]="'0.5*'"
    name="orderBtnBid"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button (click)="order($event, item.issueCode, 'margin')" class="c-btn c-btn--default order-btn shadow-none" *ngIf="item.issueCode">
        新
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 発注ボタン(信返) -->
  <brisk-vg-grid-column
    *ngIf="orderButtonType === 'monex'"
    [width]="'0.5*'"
    name="orderBtnBid"
    header=" "
    [allowSorting]="false"
    [capturePointer]="false"
    align="center"
  >
    <ng-template briskVgGridCellTemplate [cellType]="'Cell'" let-item="item">
      <button
        (click)="order($event, item.issueCode, 'marginRefund')"
        class="c-btn c-btn--default order-btn shadow-none"
        *ngIf="item.issueCode"
      >
        返
      </button>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 銘柄名 / 指数名 -->
  <brisk-vg-grid-column
    [width]="applyMinWidth ? 11.5 : '3*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 11.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="name"
    [header]="nameHeader"
    binding="name"
    align="center"
    [allowEditing]="nameEditable"
    [allowSorting]="false"
    [filterType]="allowFilter ? 'Value' : 'None'"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="item.editable; else normalName">
        <div class="c-name__wrapper add-padding text-left" [class.c-name--highlight-background]="item.highlightName">
          <span class="c-name" [class.c-name--highlight]="item.highlightName">{{ item.name }}</span>
          <fa-icon class="u-editable-icon" [icon]="['fas', 'pen']" transform="shrink-8"></fa-icon>
        </div>
      </ng-container>
      <ng-template #normalName>
        <div class="text-left add-padding c-name c-name--normal" [class.c-name--highlight]="item.highlightName">
          {{ item.name }}
        </div>
      </ng-template>
    </ng-template>
    <ng-template briskVgGridCellTemplate [cellType]="'CellEdit'" let-item="item" let-editingContext="editingContext">
      <input briskVgGridInput [(ngModel)]="editingContext.value" type="text" maxlength="9" class="vg-grid-cell-input add-padding" />
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 騰落率 -->
  <brisk-vg-grid-column
    *ngIf="!formerPriceChange && showLastPriceBasePriceChanges"
    [width]="'1.45*'"
    cssClass="add-padding"
    name="lastPriceBasePriceChanges"
    binding="lastPriceBasePriceChange100"
    header="騰落率"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    [visible]="visibleLastPriceBasePriceChanges"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <div class="text-right" [ngbTooltip]="item.simpleAverage ? '騰落率単純平均' : null">
          <span [ngClass]="item.lastPriceBasePriceChange | priceChangeClass">
            {{ item.lastPriceBasePriceChange | priceChanges | predictTransform: item.predict }}
          </span>
        </div>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- VWAP比 -->
  <brisk-vg-grid-column
    *ngIf="showVwap && formerVwap"
    [width]="applyMinWidth ? 5.5 : '1.4*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    binding="vwapPriceChange100"
    name="vwapPriceChange"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    header="VWAP比"
    align="center"
    dataType="Number"
    [visible]="visibleVwap"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <div class="text-right" [ngbTooltip]="item.simpleAverage ? 'VWAP比単純平均' : null">
          <span [ngClass]="item.vwapPriceChange | priceChangeClass">{{
            item.vwapPriceChange | priceChanges | predictTransform: item.vwapPredict
          }}</span>
        </div>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 売買代金 -->
  <brisk-vg-grid-column
    *ngIf="showTurnover10"
    [width]="applyMinWidth ? 8 : '2*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 8 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    [binding]="shortVolume ? 'turnover10ForSummary' : 'turnover10'"
    name="turnover10"
    header="売買代金"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    align="center"
    dataType="Number"
    [visible]="visibleTurnover10"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <div class="text-right" *ngIf="item.turnover10 !== null && item.turnover10 !== undefined && item.turnover10 !== 0">
          <ng-container *ngIf="shortVolume && item.turnover10 >= 1; else longTurnover">
            {{
              item.turnover10
                | fastDecimal
                  : {
                      separateComma: true,
                      fractionDigits: 0
                    }
                | predictTransform: item.turnoverPredict
            }}
            億円
          </ng-container>
          <ng-template #longTurnover>
            {{
              item.turnover10
                | fastDecimal
                  : {
                      separateComma: true,
                      fractionDigits: 2
                    }
                | predictTransform: item.turnoverPredict
            }}
            億円
          </ng-template>
        </div>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- VWAP比 -->
  <brisk-vg-grid-column
    *ngIf="showVwap && !formerVwap"
    [width]="'1.6*'"
    cssClass="add-padding"
    binding="vwapPriceChange100"
    name="vwapPriceChange"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    header="VWAP比"
    align="center"
    dataType="Number"
    [visible]="visibleVwap"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <ng-container *ngIf="!item.disabledHitCount; else disabledCell">
        <div class="text-right" [ngbTooltip]="item.simpleAverage ? 'VWAP比単純平均' : null">
          <span [ngClass]="item.vwapPriceChange | priceChangeClass">{{
            item.vwapPriceChange | priceChanges | predictTransform: item.vwapPredict
          }}</span>
        </div>
      </ng-container>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 売買代金前日比 -->
  <brisk-vg-grid-column
    *ngIf="showVolume"
    cssClass="add-padding"
    binding="turnoverRate100"
    [width]="applyMinWidth ? 7 : '2*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 7 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="turnoverRate"
    header="代金前日比"
    align="center"
    dataType="Number"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    [filterType]="allowFilter ? 'Condition' : 'None'"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right">{{ item.turnoverRate | percent: '1.2-2' | predictTransform: item.turnoverPredict }}</div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 出来高 -->
  <brisk-vg-grid-column
    *ngIf="showVolume"
    cssClass="add-padding"
    binding="volume"
    [width]="applyMinWidth ? 7 : '2*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 7 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="volume"
    header="出来高"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [filterRoundPrecision]="0"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right" *ngIf="item.volume">
        {{
          item.volume
            | fastDecimal
              : {
                  separateComma: true,
                  fractionDigits: 0
                }
            | predictTransform: item.turnoverPredict
        }}
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 安値 -->
  <brisk-vg-grid-column
    *ngIf="showLowPrice"
    [width]="applyMinWidth ? 5.5 : '1.4*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    binding="lowPriceBasePriceChange100"
    name="lowPriceBasePriceChange"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    header="安値"
    align="center"
    dataType="Number"
    [visible]="visibleVwap"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right" [ngbTooltip]="'安値(基準値段比)'">
        <span [ngClass]="item.lowPriceBasePriceChange | priceChangeClass">{{ item.lowPriceBasePriceChange | priceChanges }}</span>
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 高値 -->
  <brisk-vg-grid-column
    *ngIf="showHighPrice"
    [width]="applyMinWidth ? 5.5 : '1.4*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    binding="highPriceBasePriceChange100"
    name="highPriceBasePriceChange"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    header="高値"
    align="center"
    dataType="Number"
    [visible]="visibleVwap"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right" [ngbTooltip]="'高値(基準値段比)'">
        <span [ngClass]="item.highPriceBasePriceChange | priceChangeClass">{{ item.highPriceBasePriceChange | priceChanges }}</span>
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- すいかチャート -->
  <brisk-vg-grid-column
    *ngIf="showPriceBasePriceChanges"
    [width]="applyMinWidth ? 8 : '2.5*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 8 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="priceBasePriceChanges"
    header="すいかチャート"
    align="center"
    [allowSorting]="false"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <app-barchart [source]="item.priceBasePriceChanges"></app-barchart>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 前日売買代金 -->
  <brisk-vg-grid-column
    *ngIf="false"
    cssClass="add-padding"
    binding="lastDayTurnover"
    [width]="applyMinWidth ? 8 : '2*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 8 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="lastDayTurnover"
    header="前日売買代金"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
  >
    <ng-container *briskVgGridCellTemplate="let item">
      <div class="text-right" *ngIf="item.lastDayTurnover !== null && item.lastDayTurnover !== undefined && item.lastDayTurnover !== 0">
        <ng-container *ngIf="shortVolume && item.lastDayTurnover >= 1; else longTurnover">
          {{
            item.lastDayTurnover / 1e8
              | fastDecimal
                : {
                    separateComma: true,
                    fractionDigits: 0
                  }
          }}
          億円
        </ng-container>
        <ng-template #longTurnover>
          {{
            item.lastDayTurnover / 1e8
              | fastDecimal
                : {
                    separateComma: true,
                    fractionDigits: 2
                  }
          }}
          億円
        </ng-template>
      </div>
    </ng-container>
  </brisk-vg-grid-column>

  <!-- 寄騰落率 -->
  <brisk-vg-grid-column
    *ngIf="showPrice"
    [width]="applyMinWidth ? 5.5 : '1.7*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    cssClass="add-padding"
    binding="openPrice10BasePriceChanges100"
    name="openPrice10BasePriceChanges"
    header="寄騰落率"
    align="center"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    dataType="Number"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right" [ngClass]="item.openPrice10BasePriceChanges | priceChangeClass">
        {{ item.openPrice10BasePriceChanges | priceChanges | predictTransform: item.openPrice10Predict }}
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 始値比 -->
  <brisk-vg-grid-column
    *ngIf="showOpenPriceChange"
    cssClass="add-padding"
    [width]="applyMinWidth ? 5.5 : '1.6*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 5.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    binding="openPriceChange100"
    name="lastPriceOpenPriceChanges"
    header="始値比"
    align="center"
    dataType="Number"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    [visible]="visibleOpenPriceChange"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right">
        <span [ngClass]="item.openPriceChange | priceChangeClass">{{
          item.openPriceChange | priceChanges | predictTransform: item.predict
        }}</span>
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- Prefix -->
  <brisk-vg-grid-column
    *ngIf="showPrefix"
    [width]="applyMinWidth ? 2 : '0.65*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 2 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="prefix"
    [header]="' '"
    binding="prefix"
    displayName="市場区分"
    align="center"
    [filterType]="allowFilter ? 'Value' : 'None'"
    [allowSorting]="allowSorting"
  >
  </brisk-vg-grid-column>

  <!-- 業種 -->
  <brisk-vg-grid-column
    *ngIf="showIndustry"
    binding="industryName"
    [width]="applyMinWidth ? 6 : '1.6*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 6 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="industryName"
    header="業種"
    align="center"
    [filterType]="allowFilter ? 'Value' : 'None'"
    [allowSorting]="false"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-left add-padding">
        {{ item.industryName }}
      </div>
    </ng-template>
  </brisk-vg-grid-column>

  <!-- 時価総額 -->
  <brisk-vg-grid-column
    *ngIf="showMarketCapitalization"
    cssClass="add-padding"
    binding="marketCapitalizationFilterValue"
    [width]="applyMinWidth ? 6.5 : '1.85*'"
    [widthUnit]="applyMinWidth ? 'Fr' : 'Auto'"
    [minWidth]="applyMinWidth ? 6.5 : undefined"
    [minWidthUnit]="applyMinWidth ? 'Em' : undefined"
    name="marketCapitalization"
    header="時価総額"
    align="center"
    [filterType]="allowFilter ? 'Condition' : 'None'"
    [allowSorting]="allowSorting"
    [defaultSortDirection]="'Desc'"
    [filterRoundPrecision]="0"
    dataType="Number"
  >
    <ng-template briskVgGridCellTemplate let-item="item">
      <div class="text-right" *ngIf="item.marketCapitalization">
        {{ item.marketCapitalization * 1e-8 | fastDecimal: { separateComma: true } }}
        億円
      </div>
    </ng-template>
  </brisk-vg-grid-column>
</brisk-vg-grid>

<ng-template #disabledCell>
  <div class="p-disabled-cell"></div>
</ng-template>

<span #themeElement class="d-none"></span>

<context-menu #portfolioContextMenu [disabled]="!showDelete() && !showOpenChart()">
  <ng-template contextMenuItem [visible]="showDelete.bind(this)" (execute)="deleteItem()">削除</ng-template>
  <ng-template contextMenuItem [visible]="showOpenChart.bind(this)" (execute)="openChart()">累出来チャート</ng-template>
</context-menu>
