const EPS = 1e-6;

class TimeInfo {
  date: Date;
}
class WeekInfo {
  year: number;
  week: number;
}
class MonthInfo {
  year: number;
  month: number;
}

export class QRChartItem {
  public originalQuantity: number;

  constructor(
    public readonly x: number,
    public readonly basePrice10?: number,
    public openPrice10?: number,
    public highPrice10?: number,
    public lowPrice10?: number,
    public closePrice10?: number,
    public quantity?: number,
    public openQuantity?: number,
    public closeQuantity?: number,
    public info?: TimeInfo | WeekInfo | MonthInfo,
    originalQuantity?: number
  ) {
    if (!originalQuantity) {
      this.originalQuantity = quantity;
    } else {
      this.originalQuantity = originalQuantity;
    }
  }

  get open(): number {
    if (this.openPrice10 === undefined || this.basePrice10 === undefined) {
      return undefined;
    }
    return this.openPrice10 / this.basePrice10 - 1;
  }
  get close(): number {
    if (this.closePrice10 === undefined || this.basePrice10 === undefined) {
      return undefined;
    }
    return this.closePrice10 / this.basePrice10 - 1;
  }
  get high(): number {
    if (this.highPrice10 === undefined || this.basePrice10 === undefined) {
      return undefined;
    }
    return this.highPrice10 / this.basePrice10 - 1 + EPS;
  }
  get low(): number {
    if (this.lowPrice10 === undefined || this.basePrice10 === undefined) {
      return undefined;
    }
    return this.lowPrice10 / this.basePrice10 - 1 - EPS;
  }

  get quantitySum(): number {
    return (this.quantity || 0) + (this.openQuantity || 0) + (this.closeQuantity || 0);
  }
}

export class LineChartItem {
  constructor(public readonly x: number, public rate: number) {}
}
