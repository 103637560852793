import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { GlOnHide, GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { Container } from 'golden-layout';
import { StockDetailService } from '../../stock-detail.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface State {
  urlTemplate: string;
}
@Component({
  selector: 'app-fita4-external-website-panel',
  templateUrl: './external-website-panel.component.html',
  styleUrls: ['./external-website-panel.component.scss'],
})
export class ExternalWebsitePanelComponent implements OnInit, GlOnShow, GlOnHide {
  urlTemplate: string;
  private containerVisible = new BehaviorSubject<boolean>(false);

  url$: Observable<string>;
  constructor(private stockDetailService: StockDetailService, @Inject(GoldenLayoutContainer) private container: Container) {}

  ngOnInit(): void {
    const s = this.container.getState() as State;
    if (s.urlTemplate) {
      this.urlTemplate = s.urlTemplate;
    }
    this.containerVisible.next(!this.container.isHidden);
    this.url$ = combineLatest([this.stockDetailService.issueCode$, this.stockDetailService.visible$, this.containerVisible]).pipe(
      filter(([issueCode, v1, v2]) => v1 && v2),
      map(([issueCode, v1, v2]) => {
        return this.urlTemplate.replace('{issueCode}', issueCode.toString());
      })
    );
  }

  glOnHide(): void {
    this.containerVisible.next(false);
  }

  glOnShow(): void {
    this.containerVisible.next(true);
  }
}
