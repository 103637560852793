import { Inject, Injectable, isDevMode, NgZone, OnDestroy } from '@angular/core';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { StockController, StockView, StockWrapper, Trace, WindowRefService } from '@argentumcode/brisk-common';
import { Subject, Subscription } from 'rxjs';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from './order/types/interfaces';

export class SingleStock {
  skipIta: boolean;
  skipQr: boolean;
  stockWrapper: StockWrapper;
  updatedSubject: Subject<{}>;
}
@Injectable({
  providedIn: 'root',
})
export class SingleStockOperatorService implements OnDestroy, StockController {
  private items: Array<SingleStock>;
  private basePriceUpdates: Set<number>;
  private orderUpdates: Set<number>;
  private hasUpdate = false;
  private trace: Trace;
  private _subscription: Subscription = new Subscription();
  private _initialized: boolean;
  private _destroy = false;

  constructor(
    private cocomero: CocomeroService,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    private window: WindowRefService,
    private ngZone: NgZone
  ) {
    this.items = [];
    this.basePriceUpdates = new Set<number>();
    this.orderUpdates = new Set<number>();
    this._initialized = false;

    cocomero.initializedSubject.subscribe(() => {
      this._initialized = true;
      this.trace = this.cocomero.op.createTrace();
      this._subscription.add(
        cocomero.op.basePriceUpdated.subscribe(([_, issueCode]) => {
          this.basePriceUpdates.add(issueCode);
        })
      );
    });

    this._subscription.add(
      this.orderOperator.updateStock.subscribe((issueCode) => {
        if (issueCode === null) {
          this.hasUpdate = true;
        } else {
          this.orderUpdates.add(issueCode);
        }
      })
    );
    this.ngZone.runOutsideAngular(() => {
      this._eventLoopWrapper();
    });
  }

  createStockWrapper(issueCode: number): StockWrapper {
    if (!this._initialized) {
      throw new Error('not initialized');
    }
    const view = new SingleStock();
    view.updatedSubject = new Subject<{}>();
    view.stockWrapper = new StockWrapper(
      this.cocomero.op.flexConfig,
      view.updatedSubject.asObservable(),
      this.cocomero.op.createStockView(this.cocomero.op.issueCodeMap[issueCode], false, true),
      this.cocomero.op.date,
      this
    );
    view.stockWrapper.update = true;
    this.hasUpdate = true;
    this.items.push(view);
    return view.stockWrapper;
  }

  disposeStockWrapper(stockWrapper: StockWrapper): void {
    if (!this._initialized) {
      throw new Error('not initialized');
    }
    if (isDevMode()) {
      console.assert(this.items.filter((item) => item.stockWrapper === stockWrapper).length > 0);
    }
    for (const i of this.items.filter((item) => item.stockWrapper === stockWrapper)) {
      i.updatedSubject.complete();
    }
    this.items = this.items.filter((item) => item.stockWrapper !== stockWrapper);
  }

  private _eventLoop(): Array<() => void> {
    if (!this._initialized) {
      return [];
    }
    const updates = [];

    for (const item of this.items) {
      // 基準値段更新の場合は板の更新を強制する
      if (this.basePriceUpdates.has(item.stockWrapper.base.master.issueCode)) {
        item.stockWrapper.base.itaRowPriceIndexUpdated = true;
      }
      // 注文情報更新の場合はupdateを発生させる
      if (this.orderUpdates.has(item.stockWrapper.base.master.issueCode)) {
        item.stockWrapper.update = true;
      }
      // 板の表示範囲に更新があるかどうか
      const upIta = item.stockWrapper.current.itaRowPriceIndexUpdated || item.stockWrapper.current.itaRowCountUpdated;
      // updateNumberに更新があったかどうか。
      const upByEv = `${item.stockWrapper.base.frame}|${item.stockWrapper.current.frame}`;

      // 板の表示範囲に更新があるかWebSocketから受信したデータがある場合にリアルタイム側の更新
      if (upIta || this.trace.has(item.stockWrapper.base.master.id)) {
        this.cocomero.op.updateStockView(item.stockWrapper.base, item.skipIta, item.skipQr, upIta, false);
      }
      // 過去表示の場合は常に更新 (NOTICE: 非効率的)
      if (item.stockWrapper.base !== item.stockWrapper.current) {
        this.cocomero.op.updateStockView(item.stockWrapper.current, item.skipIta, item.skipQr, upIta, false);
      }
      // updateStockViewによって、frameに変化があったか、もしくはupdateを発行する必要がある状態の時
      if (
        this.hasUpdate ||
        upByEv !== `${item.stockWrapper.base.frame}|${item.stockWrapper.current.frame}` ||
        upIta ||
        item.stockWrapper.update ||
        item.stockWrapper.current.specialQuoteTime
      ) {
        item.stockWrapper.update = false;
        updates.push(() => {
          item.updatedSubject.next({});
        });
      }
    }
    this.basePriceUpdates.clear();
    this.orderUpdates.clear();
    this.trace.clear();
    this.hasUpdate = false;
    return updates;
  }

  private _eventLoopWrapper() {
    if (this._destroy) {
      return;
    }
    const updates = this._eventLoop();
    if (updates.length > 0) {
      this.ngZone.runGuarded(() => {
        for (const update of updates) {
          update();
        }
      });
    }
    this.window.nativeWindow.requestAnimationFrame(this._eventLoopWrapper.bind(this));
  }

  public createSnapshot(stockWrapper) {
    if (stockWrapper) {
      if (stockWrapper.current) {
        // TODO: Dispose
      }
      stockWrapper.current = this.cocomero.op.cloneStockView(stockWrapper.base);
      stockWrapper.update = true;
    }
  }

  public live(stockWrapper: StockWrapper) {
    if (stockWrapper) {
      stockWrapper.base.itaRowPriceIndex =
        stockWrapper.current.itaRowPriceIndex - stockWrapper.base.itaRowCount + stockWrapper.current.itaRowCount;
      stockWrapper.base.itaRowCount = stockWrapper.current.itaRowCount;
      if (stockWrapper.current !== stockWrapper.base) {
        // TODO: Dispose
      }
      stockWrapper.current = stockWrapper.base;
      stockWrapper.update = true;
    }
  }

  goUpdateNumber(view: StockView, updateNumber: number) {
    this.cocomero.op.goUpdateNumber(view, updateNumber);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    for (const item of this.items) {
      item.updatedSubject.complete();
    }
    this._destroy = true;
  }
}
