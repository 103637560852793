import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ToasterService } from '../toaster.service';
import { Toast } from '../toast';
import { Observable } from 'rxjs';

@Component({
  selector: 'brisk-toaster-container',
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterContainerComponent implements OnInit {
  readonly toasts$: Observable<Array<Toast>>;

  constructor(private toaster: ToasterService) {
    this.toasts$ = this.toaster.toasts$;
  }

  ngOnInit() {}

  _onHide(toast: Toast) {
    this.toaster.remove(toast);
  }
}
