import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  normalizeColumnStateConfig,
  ResizeService,
  SimpleDialog,
  VgCollectionView,
  VgGridColumnsState,
  VgGridComponent,
} from '@argentumcode/brisk-common';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface GridColumnForReorder {
  id: string;
  name: string;
  visible: boolean;
  sticky: boolean;
  order: number;

  initialOrder: number;
  initialVisible: boolean;
  initialSticky: boolean;

  disableHide: boolean;
}

@Component({
  selector: 'app-fita4-grid-column-editor-dialog',
  templateUrl: './grid-column-editor-dialog.component.html',
  styleUrls: ['./grid-column-editor-dialog.component.scss'],
})
export class GridColumnEditorDialogComponent<T> implements OnInit, OnDestroy {
  columnsArray: Array<GridColumnForReorder> = [];
  columnsArraySubject: Subject<Array<GridColumnForReorder>> = new Subject();
  columns = new VgCollectionView(this.columnsArraySubject.asObservable());

  dirty = false;

  @ViewChild('grid')
  grid: VgGridComponent<GridColumnForReorder>;

  initialState: VgGridColumnsState;

  constructor(
    private cd: ChangeDetectorRef,
    private resize: ResizeService,
    @Inject(MAT_DIALOG_DATA) private dialog: SimpleDialog<unknown, unknown, VgGridComponent<T>>,
    private matDialogRef: MatDialogRef<unknown>
  ) {
    const grid = dialog.data;
    this.initialState = grid.vgGridColumnsState;

    const state = normalizeColumnStateConfig(grid.vgGridColumnsState, grid.columns);
    this.columnsArray = grid.columns
      .filter((column) => !column.isHeader)
      .map((column, i) => ({
        id: column.name,
        visible: state[column.name].visible,
        sticky: state[column.name].sticky,
        order: state[column.name].order,
        name: column.displayName || column.header,
        initialOrder: i,
        initialSticky: column.initialStickyColumn,
        initialVisible: column.initialVisible,

        disableHide: column.disableHide,
      }));
    this.columnsArray.sort((c1, c2) => c1.order - c2.order);
    let stickyMask = true;
    for (let i = this.columnsArray.length - 1; i >= 0; i--) {
      this.columnsArray[i].sticky = this.columnsArray[i].sticky && stickyMask;
      if (this.columnsArray[i].sticky) {
        stickyMask = false;
      }
    }
    this.columnsArraySubject.next(this.columnsArray);
  }

  ngOnInit(): void {}

  moveToDelta(delta: number) {
    if (typeof this.grid.selectedIndex === 'number') {
      this.dirty = true;
      const target = this.grid.selectedIndex + delta;
      if (0 <= target && target < this.columnsArray.length) {
        [this.columnsArray[target], this.columnsArray[this.grid.selectedIndex]] = [
          this.columnsArray[this.grid.selectedIndex],
          this.columnsArray[target],
        ];
        this.columnsArraySubject.next(this.columnsArray);
      }
    }
  }

  setFixed() {
    if (typeof this.grid.selectedIndex === 'number') {
      this.dirty = true;
      const orig = this.columnsArray[this.grid.selectedIndex].sticky;
      for (const c of this.columnsArray) {
        c.sticky = false;
      }
      // Toggle
      this.columnsArray[this.grid.selectedIndex].sticky = !orig;
      this.columnsArraySubject.next(this.columnsArray);
    }
  }

  update() {
    if (!this.dirty) {
      this.matDialogRef.close({
        type: 'button',
        value: {
          nextState: this.initialState,
        },
      });
      return;
    }
    const newState: VgGridColumnsState = {};
    let order = 0;
    for (const column of this.columnsArray) {
      newState[column.id] = {
        order: order++,
        sticky: column.sticky,
        visible: column.visible,
      };
    }
    this.matDialogRef.close({
      type: 'button',
      value: {
        nextState: newState,
      },
    });
  }

  ngOnDestroy(): void {
    this.columnsArraySubject.complete();
  }

  reset() {
    this.initialState = undefined;
    for (let i = 0; i < this.columnsArray.length; i++) {
      this.columnsArray[i].order = this.columnsArray[i].initialOrder;
      this.columnsArray[i].visible = this.columnsArray[i].initialVisible;
      this.columnsArray[i].sticky = this.columnsArray[i].initialSticky;
    }
    this.columnsArray.sort((c1, c2) => c1.order - c2.order);
    this.columnsArraySubject.next(this.columnsArray);
    this.dirty = false;
  }

  close() {
    this.matDialogRef.close({
      type: 'button',
      value: {},
    });
  }
}
