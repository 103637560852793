import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { FrontendApiService, LocalStorageService, WatchListRequest, WatchListResponse } from '@argentumcode/brisk-common';
import { interval, Observable, ReplaySubject, Subject } from 'rxjs';
import { throttle } from 'rxjs/operators';
import { deflate, inflate } from 'pako';
import * as stringify from 'json-stable-stringify';

export class WatchListSizeError extends Error {
  constructor(size: number) {
    super();
    this.name = 'WatchListSizeError';
    this.message = `Too large watch lists: ${size}B`;
  }
}

export class ServerWatchListNotExistsError extends Error {
  constructor() {
    super();
    this.name = 'ServerWatchListNotExistsError';
    this.message = `LocalStorage has commit id but server doesn't have commit id`;
  }
}

export class WatchListConflictError extends Error {
  constructor() {
    super();
    this.name = 'WatchListConflictError';
    this.message = `Watch List is conflicted`;
  }
}

export class WatchListEntry {
  constructor(public issueCode: number) {}

  toJSON() {
    return this.issueCode;
  }
}

export class WatchList {
  id: number = null;
  name: string = null;
  entries: Array<WatchListEntry> = [];

  constructor(data?: { id: number; name: string; entries: Array<any> }) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.entries = data.entries.map((a) => new WatchListEntry(a));
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class WatchListService implements OnDestroy {
  private watchLists: Array<WatchList> = [];
  // 保存を無効化する。Trueにすると、LocalStorageへのデータ保存は行なわれない。
  public disableSave = false;
  private _userId: string;
  private _commitSubject: Subject<{}>;
  private _errorSubject = new ReplaySubject<Error>();
  private _lastCommit = false;
  public watchListError: Observable<Error> = this._errorSubject.asObservable();
  private _stopCommit = false;

  constructor(private localStorage: LocalStorageService, private frontendApi: FrontendApiService, private _ngZone: NgZone) {
    this._commitSubject = new Subject<{}>();
    this._commitSubject
      .asObservable()
      .pipe(throttle(() => interval(15 * 1000), { leading: true, trailing: true }))
      .subscribe(() => {
        this._ngZone.runGuarded(() => {
          this.commit();
        });
      });
  }

  setup(userId: string, watchListResp: WatchListResponse) {
    this._userId = userId;
    (() => {
      this.loadFromLocalStorage();
      if (watchListResp.error) {
        this._stopCommit = true;
        return;
      }
      if (watchListResp.empty) {
        // demo版(mock api)では常にemptyなので何もせずリターンする
        return;
      }
      if (this.localStorage.getItem('watchListsCommittedId') === watchListResp.uuid) {
        return;
      }
      let serverWatchList: Array<WatchList>;
      try {
        serverWatchList = this.deserializeWatchListForCommit(watchListResp.version, watchListResp.data);
      } catch (ex) {
        this._errorSubject.next(ex);
        return;
      }
      if (this.isDirty()) {
        // ローカルデータとのマージ
        let conflicted: Array<string>;
        [this.watchLists, conflicted] = this.merge(serverWatchList, this.watchLists);
        if (conflicted && conflicted.length > 0) {
          this._errorSubject.next(new WatchListConflictError());
        }
        this.localStorage.setItem('watchListsCommittedId', watchListResp.uuid);
        this.localStorage.setItem('watchListsCommitted', this.serialize(serverWatchList));
      } else {
        // サーバーデータを優先する
        this.watchLists = serverWatchList;
        this.localStorage.setItem('watchListsCommittedId', watchListResp.uuid);
        this.localStorage.setItem('watchListsCommitted', this.serialize(serverWatchList));
      }
    })();
    if (this.watchLists.length === 0) {
      this.add(new WatchList({ id: null, name: '銘柄リスト1', entries: [7203, 9432, 9984, 9437, 6758] }));
    }
    this.saveToLocalStorage();
  }

  public loadFromLocalStorage() {
    this.watchLists = this.deserialize(this.localStorage.getItem('watchLists'));
  }

  public saveToLocalStorage() {
    if (this.disableSave) {
      return;
    }
    this.localStorage.setItem('watchLists', this.serialize(this.watchLists));

    this._ngZone.runOutsideAngular(() => {
      this._commitSubject.next({});
    });
  }

  public find(id: number): WatchList {
    const ret = this.watchLists.filter((a) => a.id === id);
    return ret[0] || null;
  }

  public add(watchList: WatchList) {
    if (watchList.id !== null) {
      throw new Error('既に登録されているWatchListです');
    }
    if (this.watchLists.length === 0) {
      watchList.id = 1;
    } else {
      watchList.id = this.watchLists.slice(-1)[0].id + 1;
    }
    this.watchLists.push(watchList);
  }

  public serialize(watchLists: Array<WatchList>): string {
    return JSON.stringify(watchLists);
  }

  public deserialize(str: string): Array<WatchList> {
    if (str === null || str === undefined) {
      return [];
    }
    const data = JSON.parse(str);
    if (data !== null && data !== undefined) {
      return data.map((a) => {
        return new WatchList(a);
      });
    }
    return [];
  }

  public delete(id: number) {
    this.watchLists = this.watchLists.filter((a) => a.id !== id);
    // TODO: addと挙動を合わせて、自動保存を止める
    this.saveToLocalStorage();
  }

  public all(): Array<WatchList> {
    return this.watchLists;
  }

  public validName(name: string): boolean {
    return name.trim().length !== 0 && this.watchLists.filter((a) => a.name === name.trim()).length === 0;
  }

  public commit() {
    if (!this.isDirty()) {
      return;
    }
    if (this._lastCommit) {
      return;
    }
    if (this.disableSave) {
      return;
    }
    if (this._stopCommit) {
      return;
    }
    this._lastCommit = true;
    const id: string = this.localStorage.getItem('watchListsCommittedId');
    try {
      const [version, data] = this.serializeWatchListForCommit(this.watchLists);
      const serializedWatchList = this.serialize(this.watchLists);

      let wl: WatchListRequest;
      if (!id) {
        wl = {
          version: version,
          data: data,
          uuid: '',
          userId: this._userId,
        };
      } else {
        wl = {
          version: version,
          data: data,
          uuid: id,
          userId: this._userId,
        };
      }
      this.frontendApi.saveWatchlist(wl).subscribe(
        (uuid) => {
          this.localStorage.setItem('watchListsCommittedId', uuid);
          this.localStorage.setItem('watchListsCommitted', serializedWatchList);
          this._lastCommit = false;
        },
        (err) => {
          this._errorSubject.next(err);
        }
      );
    } catch (e) {
      if (e && e.name === 'WatchListSizeError') {
        this._errorSubject.next(e);
      } else {
        throw e;
      }
    }
  }

  public isDirty(): boolean {
    const committed = this.localStorage.getItem('watchListsCommitted');
    if (!committed) {
      return true;
    }
    const wl = JSON.parse(committed).map((a) => {
      return new WatchList(a);
    });
    return !this.equalWatchLists(this.watchLists, wl);
  }

  public serializeWatchListForCommit(watchList: Array<WatchList>): [string, Uint8Array] {
    const json = stringify(watchList);
    // 1MB
    if (json.length >= 1000000) {
      throw new WatchListSizeError(json.length);
    }
    const data = deflate(new TextEncoder().encode(json), { level: 1 });
    return ['1.0.0', data];
  }

  public deserializeWatchListForCommit(version: string, data: Uint8Array): Array<WatchList> {
    if (version === '1.0.0') {
      const d = new TextDecoder('utf-8').decode(inflate(data));
      return JSON.parse(d).map((row) => new WatchList(row));
    } else {
      throw new Error('Unsupported Version');
    }
  }

  public equalWatchList(w1: WatchList, w2: WatchList): boolean {
    if (w1.name !== w2.name || w1.entries.length !== w2.entries.length) {
      return false;
    }
    for (let j = 0; j < w1.entries.length; j++) {
      const e1 = w1.entries[j];
      const e2 = w2.entries[j];
      if (e1.issueCode !== e2.issueCode) {
        return false;
      }
    }
    return true;
  }

  public equalWatchLists(wl1: Array<WatchList>, wl2: Array<WatchList>): boolean {
    if (wl1.length !== wl2.length) {
      return false;
    }
    for (let i = 0; i < wl1.length; i++) {
      const w1 = wl1[i];
      const w2 = wl2[i];
      if (!this.equalWatchList(w1, w2)) {
        return false;
      }
    }
    return true;
  }

  merge(wl1: Array<WatchList>, wl2: Array<WatchList>): [Array<WatchList>, Array<string>] {
    const ret: Array<WatchList> = [].concat(wl1);
    const conflicted: Array<string> = [];
    for (const w2 of wl2) {
      let same = false;
      let sameName = false;
      for (const w1 of ret) {
        if (w1.name === w2.name) {
          sameName = true;
        }
        if (this.equalWatchList(w1, w2)) {
          same = true;
          break;
        }
      }
      if (!same) {
        ret.push(w2);
        if (sameName) {
          conflicted.push(w2.name);
        }
      }
    }
    for (let i = 0; i < ret.length; i++) {
      ret[i].id = i + 1;
    }
    return [ret, conflicted];
  }

  ngOnDestroy(): void {
    this._commitSubject.complete();
    this._errorSubject.complete();
  }
}
