import { Injectable, OnDestroy } from '@angular/core';
import { PegState } from '@argentumcode/brisk-common';
import { StockListViewMode } from 'fita3/src/app/portfolio/summary-list.service';
import { TutorialState } from 'fita3/src/app/core/storage.service';
import { PersistentStateService } from './persistent-state.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Fita4StorageService implements OnDestroy {
  private _invisibleSummary: Array<string> = [];
  private _subscription: Subscription;

  constructor(private persistentState: PersistentStateService<unknown>) {
    this._subscription = this.persistentState.invisibleSummary$.subscribe((invisibleSummary) => {
      this._invisibleSummary = invisibleSummary;
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public get invisibleSummary(): Array<string> {
    return this._invisibleSummary;
  }

  public set invisibleSummary(arr: Array<string>) {
    this.persistentState.updateInvisibleSummary(arr);
  }

  public addInvisibleSummary(summaryId: string) {
    const arr = this.invisibleSummary;
    const idx = arr.indexOf(summaryId);
    if (idx !== -1) {
      return;
    }
    arr.push(summaryId);
    this.persistentState.updateInvisibleSummary(arr);
  }

  public removeInvisibleSummary(summaryId: string) {
    const arr = this.invisibleSummary;
    const idx = arr.indexOf(summaryId);
    if (idx === -1) {
      return;
    }
    arr.splice(idx, 1);
    this.persistentState.updateInvisibleSummary(arr);
  }

  public get splitSize(): number {
    console.error('splitSize should not be called');
    return 25;
  }

  public set splitSize(num: number) {
    console.error('splitSize should not be called');
  }

  public getDefaultTabIndex(index: number): number {
    console.error('getDefaultTabIndex should not be called');
    return 0;
  }

  public setDefaultTabIndex(index: number, number: number) {
    console.error('setDefaultTabIndex should not be called');
  }

  public getShowIndustryPrice(index: number): boolean {
    console.error('getShowIndustryPrice should not be called');
    return true;
  }

  public setShowIndustryPrice(index: number, value: boolean) {
    console.error('setShowIndustryPrice should not be called');
  }

  public get theme(): string {
    console.error('theme should not be called');
    return '';
  }

  public set theme(s: string) {
    console.error('theme should not be called');
  }

  public get viewMode(): StockListViewMode {
    console.error('view mode should not be called');
    return StockListViewMode.Detail;
  }

  public set viewMode(s: StockListViewMode) {
    console.error('view mode should not be called');
  }

  public get peg(): PegState {
    console.error('peg should not be called');
    return PegState.Enabled;
  }

  public set peg(p: PegState) {
    console.error('peg should not be called');
  }

  public get tutorialState(): TutorialState {
    console.error('tutorialState should not be called');
    return TutorialState.Finished;
  }

  public set tutorialState(state: TutorialState) {
    console.error('tutorialState should not be called');
  }

  public get tutorialBackup(): string {
    console.error('tutorialBackup should not be called');
    return '';
  }

  public set tutorialBackup(s: string) {
    console.error('tutorialBackup should not be called');
  }
}
