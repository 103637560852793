import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssistService {
  private assistChanged = new ReplaySubject<boolean>();
  public assist$: Observable<boolean> = this.assistChanged.asObservable();
  private assist = false;
  private targetUrl: { [key: string]: string };
  private setupSubject = new ReplaySubject<{}>(1);
  public setup$ = this.setupSubject.asObservable();

  constructor() {
    this.assist = false;
    this.assistChanged.next(false);
    this.targetUrl = {};
  }

  public setup(assistData: { [key: string]: string }) {
    for (const k of Object.keys(assistData)) {
      const value = assistData[k];
      const key = k.replace(/[A-Z]/g, (s) => '-' + s.charAt(0).toLowerCase());
      this.targetUrl[key] = value;
    }
    this.setupSubject.next({});
  }

  public toggleAssist() {
    this.assist = !this.assist;
    this.assistChanged.next(this.assist);
  }

  public stopAssist() {
    if (this.assist) {
      this.toggleAssist();
    }
  }

  public openAssist(id: string) {
    window.open(this.targetUrl[id]);
  }

  public openable(key: string): boolean {
    return key in this.targetUrl;
  }
}
