import { Component, OnInit } from '@angular/core';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { Market, ResizeService } from '@argentumcode/brisk-common';
import { CellDoubleClickService } from '../../cell-double-click.service';
import { GlOnResize, GlOnShow } from '@argentumcode/ngx-golden-layout';

@Component({
  selector: 'app-fita4-markets-panel',
  templateUrl: './markets-panel.component.html',
  styleUrls: ['./markets-panel.component.scss'],
})
export class MarketsPanelComponent implements OnInit, GlOnShow, GlOnResize {
  get marketConditions(): Array<Market> {
    return this.cocomero.marketConditions;
  }

  constructor(private cocomero: CocomeroService, private dblClick: CellDoubleClickService, private resize: ResizeService) {}

  ngOnInit(): void {}

  onStockSelected(issueCode: number) {
    this.cocomero.changeIssueCode(Number(issueCode));
  }

  onDoubleClicked() {
    this.dblClick.doubleClicked();
  }

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
  }
}
