import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDirective } from './drag.directive';
import { DragWithPreviewDirective } from './drag-with-preview.directive';
import { DropDirective } from './drop.directive';

@NgModule({
  declarations: [DragDirective, DragWithPreviewDirective, DropDirective],
  imports: [CommonModule],
  exports: [DragDirective, DragWithPreviewDirective, DropDirective],
})
export class DragDropModule {}
