import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  GlOnResize,
  GlOnShow,
  GlOnTab,
  GoldenLayoutComponent,
  GoldenLayoutContainer,
  GoldenLayoutModule,
  IExtendedGoldenLayoutConfig,
} from '@argentumcode/ngx-golden-layout';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { StockListGridPanelComponent } from '../stock-list-grid-panel/stock-list-grid-panel.component';
import { ResizeService, SummaryType } from '@argentumcode/brisk-common';
import { StockListService } from '../../stock-list.service';
import { ItaGridPanelComponent } from '../ita-grid/ita-grid-panel.component';
import { Container, Tab } from 'golden-layout';
import { ChartGridPanelComponent } from '../chart-grid-panel/chart-grid-panel.component';
import { SummaryListsService } from '../../summary-lists.service';

import uuidv4 from 'uuid/v4';
import { filter, tap } from 'rxjs/operators';
import { FontSizeService } from '../../font-size.service';
import { IndexPortfolioLineComponent } from 'fita3/src/app/portfolio/index-portfolio-line/index-portfolio-line.component';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { PersistentState, PersistentStateService, SmartListAutoRefreshType } from '../../fita4-core/persistent-state.service';
import { multipleWindowInterval } from '@argentumcode/multiple-window';
import { MapViewPanelComponent } from '../map-view-panel/map-view-panel.component';
import { environment } from '../../../environments/default/environment';
import { commonDimensionParameters, createNewComponentKeepOriginalTab } from '../../golden-layout-utils';

interface StockListState {
  summaryId?: string;
  config?: IExtendedGoldenLayoutConfig;
  active?: boolean;
  uniqId?: string;
  smartListAutoRefreshType?: SmartListAutoRefreshType;
}

@Component({
  selector: 'app-fita4-stock-list-panel',
  templateUrl: './stock-list-panel.component.html',
  styleUrls: ['./stock-list-panel.component.scss'],
  providers: [
    StockListService,
    GoldenLayoutModule.forChild([
      {
        type: StockListGridPanelComponent,
        name: 'StockListGrid',
      },
      {
        type: ItaGridPanelComponent,
        name: 'ItaGrid',
      },
      {
        type: ChartGridPanelComponent,
        name: 'ChartGrid',
      },
      {
        type: MapViewPanelComponent,
        name: 'MapView',
      },
    ]),
  ],
})
export class StockListPanelComponent implements OnInit, OnDestroy, GlOnShow, GlOnTab, GlOnResize, AfterViewInit {
  state: StockListState;
  layout$ = new BehaviorSubject<IExtendedGoldenLayoutConfig>({
    settings: {
      showPopoutIcon: false,
      showMaximiseIcon: false,
      showCloseIcon: false,
    },
    content: [
      {
        type: 'stack',
        content: [
          {
            type: 'component',
            title: '一覧',
            componentName: 'StockListGrid',
            isClosable: false,
          },
          {
            type: 'component',
            title: '板',
            componentName: 'ItaGrid',
            isClosable: false,
          },
          {
            type: 'component',
            title: 'チャート',
            isClosable: false,
            componentName: 'ChartGrid',
          },
          {
            type: 'component',
            title: 'マップ',
            isClosable: false,
            componentName: 'MapView',
            id: 'MapView',
          },
        ],
      },
    ],
  });

  updateTypes = [
    {
      key: SmartListAutoRefreshType.Realtime,
      name: 'リアルタイム更新',
      updateButton: false,
    },
    {
      key: SmartListAutoRefreshType.Second5,
      name: '5秒毎更新',
      updateButton: true,
    },
    {
      key: SmartListAutoRefreshType.Minute,
      name: '1分毎更新',
      updateButton: true,
    },
    {
      key: SmartListAutoRefreshType.Manual,
      name: '自動更新オフ',
      updateButton: true,
    },
  ];

  private _subscription = new Subscription();

  private _defaultSmartListAutoRefreshType?: SmartListAutoRefreshType = undefined;

  public _smartListAutoRefreshType?: SmartListAutoRefreshType = undefined;
  public _enableSmartListRefreshButton = false;

  private _smartListUpdateSubscription?: Subscription = undefined;

  @ViewChild('indexPortfolioLine', { static: false }) indexPortfolioLine: IndexPortfolioLineComponent;

  @ViewChild('layout', { static: false }) layout: GoldenLayoutComponent;
  get summary() {
    return this.summaryListService.summary;
  }

  get isSmartList(): boolean {
    return this.summary.summary.type === SummaryType.SmartList;
  }

  constructor(
    private summaryListService: StockListService,
    private resize: ResizeService,
    private element: ElementRef,
    private summaryLists: SummaryListsService,
    private _fontSize: FontSizeService,
    private _cocomero: CocomeroService,
    private _persistentState: PersistentStateService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this._subscription.add(
      fromEvent(element.nativeElement as HTMLDivElement, 'click', { capture: true }).subscribe(() => {
        this.setToActive(true);
      })
    );
    this._subscription.add(
      this.summaryLists.changeActive$.subscribe((activeId) => {
        if (!this.state || !activeId) {
          return;
        }
        this.setToActive(this.state.uniqId === activeId);
      })
    );
    this._subscription.add(
      this._cocomero.updateFrame.subscribe((summary) => {
        if (summary) {
          this.indexPortfolioLine?.refresh();
        }
      })
    );
    this._subscription.add(
      this._persistentState.defaultSmartListAutoRefreshType$.subscribe((refreshType) => {
        this._defaultSmartListAutoRefreshType = refreshType;
        this._updateRefreshType();
        this._changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();

    if (this._smartListUpdateSubscription) {
      this._smartListUpdateSubscription.unsubscribe();
      this._smartListUpdateSubscription = undefined;
    }
  }

  ngAfterViewInit(): void {
    this._subscription.add(
      this._fontSize.fontSize$.subscribe(() => {
        if (this.layout) {
          this.layout.getGoldenLayoutInstance().config.dimensions = {
            ...this.layout.getGoldenLayoutInstance().config.dimensions,
            ...commonDimensionParameters,
            headerHeight: this._fontSize.glHeaderSize(),
          };
        }
      })
    );

    if (this.layout) {
      const mapViewPanels = this.layout.getGoldenLayoutInstance().root.getItemsById('MapView');
      if (mapViewPanels.length === 0 && environment.showMapView) {
        createNewComponentKeepOriginalTab(
          this.layout.getGoldenLayoutInstance(),
          {
            type: 'component',
            title: 'マップ',
            isClosable: false,
            componentName: 'MapView',
            id: 'MapView',
          },
          'StockListGrid',
          'componentName'
        );
      } else if (mapViewPanels.length > 0 && !environment.showMapView) {
        this.layout.closeComponent('MapView');
      }
    }
  }

  setToActive(active: boolean) {
    if (!this.state) {
      return;
    }
    if (active) {
      if (this.state.active) {
        return;
      }
      this.state.active = true;
      this.summaryLists.setActive(this.state.uniqId);
      this.container.setState(this.state);
      this.setActiveTabState(this.container.tab, this.state.active);
    } else {
      if (!this.state.active) {
        return;
      }
      this.state.active = false;
      this.container.setState(this.state);
      this.setActiveTabState(this.container.tab, this.state.active);
    }
  }

  ngOnInit(): void {
    this.state = this.container.getState() || {};
    let stateUpdated = false;
    if (!this.state.uniqId) {
      stateUpdated = true;
      // Actually, golden layout has unique id, but how to access it?
      this.state.uniqId = uuidv4();
    }
    if (!this.state.active) {
      this.state.active = false;
    }
    if (this.state.config) {
      this.layout$.next(this.state.config);
    }

    if (stateUpdated) {
      this.container.setState(this.state);
    }
    if (this.state.active) {
      this.summaryLists.setActive(this.state.uniqId);
    }

    if (this.state.summaryId) {
      this.summaryListService.setSummaryId(this.state.summaryId);
    } else {
      this.summaryListService.selectFirst();
    }
    this._subscription.add(
      this.summaryLists.summaryChange$.subscribe(([id, summaryId]) => {
        if (id === this.state.uniqId) {
          this.summaryListService.setSummaryId(summaryId);
        }
      })
    );
    this._subscription.add(
      this.summaryListService.summaryId$.pipe(filter((a) => !!a)).subscribe((summaryId) => {
        this.container.setTitle(`銘柄一覧(${this.summaryListService.summary.name})`);
        if (this.state.summaryId !== summaryId) {
          this.state.summaryId = summaryId;
          this.container.setState(this.state);
        }
      })
    );
    this._updateRefreshType();
    this.resize.dispatchResize();
    this.glOnTab(this.container.tab);
  }

  glOnResize(): void {
    this.resize.dispatchResize();
  }

  glOnShow(): void {
    this.resize.dispatchResize();
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }

  setActiveTabState(tab: Tab, active: boolean) {
    if (active) {
      tab.element.addClass('app-fita4-active');
    } else {
      tab.element.removeClass('app-fita4-active');
    }
  }
  glOnTab(tab: Tab) {
    tab.element.on('mousedown', () => {
      this.setToActive(true);
    });
    tab.element.on('touchstart', () => {
      this.setToActive(true);
    });
    if (this.state) {
      this.setActiveTabState(tab, this.state.active);
    }
  }

  onStateChanged(layout: GoldenLayoutComponent) {
    const headerSize = this._fontSize.glHeaderSize();
    const config = layout.getGoldenLayoutInstance().toConfig() as IExtendedGoldenLayoutConfig;

    // 非表示(銘柄リスト削除状態)状態の時に文字サイズが変更された場合に必要 (コンポーネントが表示されたタイミングで発火するイベントがないため）
    if (config.dimensions.headerHeight !== headerSize) {
      this.layout.getGoldenLayoutInstance().config.dimensions = {
        ...this.layout.getGoldenLayoutInstance().config.dimensions,
        ...commonDimensionParameters,
        headerHeight: headerSize,
      };
      this.layout.getGoldenLayoutInstance().updateSize();
    }

    if (this.state) {
      this.state.config = config;
      this.container.setState(this.state);
    }
  }

  refreshSmartList() {
    if (this.summary && this.isSmartList) {
      this.summaryListService.refreshSmartList();
      console.log('update');
    }
  }

  _updateRefreshType(updateRefreshType?: SmartListAutoRefreshType) {
    if (updateRefreshType) {
      if (this.state) {
        this.state.smartListAutoRefreshType = updateRefreshType;
        this._persistentState.updateDefaultSmartListAutoRefreshType(updateRefreshType);
        this.container.setState(this.state);
      }
    }
    this._smartListAutoRefreshType = this.state?.smartListAutoRefreshType || this._defaultSmartListAutoRefreshType;
    if (!this._smartListAutoRefreshType) {
      return;
    }

    for (const updateType of this.updateTypes) {
      if (updateType.key === this._smartListAutoRefreshType) {
        this._enableSmartListRefreshButton = updateType.updateButton;
      }
    }

    if (this.state && !this.state.smartListAutoRefreshType) {
      this.state.smartListAutoRefreshType = this._smartListAutoRefreshType;
      this.container.setState(this.state);
    }
    if (this._smartListUpdateSubscription) {
      this._smartListUpdateSubscription.unsubscribe();
      this._smartListUpdateSubscription = undefined;
    }
    switch (this._smartListAutoRefreshType) {
      case SmartListAutoRefreshType.Realtime: {
        this._smartListUpdateSubscription = this._cocomero.updateFrame.subscribe((frame) => {
          this.refreshSmartList();
        });
        break;
      }
      case SmartListAutoRefreshType.Second5: {
        this._smartListUpdateSubscription = multipleWindowInterval(5000).subscribe(() => {
          this.refreshSmartList();
        });
        break;
      }
      case SmartListAutoRefreshType.Minute: {
        this._smartListUpdateSubscription = multipleWindowInterval(60000).subscribe(() => {
          this.refreshSmartList();
        });
        break;
      }
      case SmartListAutoRefreshType.Manual: {
        break;
      }
    }
  }
}
