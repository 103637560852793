import { IFilter } from './collections/filter';
import { FilterValueGetter } from './collections/data-collecton';

export enum FilterType {
  None = 0,
  Value = 1,
  Condition = 2,
  Choice = 3,
}

export function stringToFilterType(s: string): FilterType {
  if (s === '' || s === 'None') {
    return FilterType.None;
  } else if (s === 'Value') {
    return FilterType.Value;
  } else if (s === 'Condition') {
    return FilterType.Condition;
  } else if (s === 'Choice') {
    return FilterType.Choice;
  } else {
    throw new Error(`Invalid Filter Type ${s}`);
  }
}

export enum ConditionCombination {
  And = 'and',
  Or = 'or',
}

export enum ConditionType {
  None = 'none',
  Equal = 'equal',
  NotEqual = 'notEqual',
  Greater = 'greater',
  GreaterOrEqual = 'greaterOrEqual',
  Less = 'less',
  LessOrEqual = 'lessOrEqual',
}

export const ValueType = {
  Number: 'Number',
  Date: 'Date',
  DateTime: 'DateTime',
} as const;
export type ValueType = typeof ValueType[keyof typeof ValueType];

export abstract class VgGridColumnFilter {
  public binding;
  // TODO: Set type
  public valueGetter?: FilterValueGetter<any>;

  abstract get clean(): boolean;

  abstract filter(item: any): boolean;
}

function shift(number: number, precision: number, reverseShift: boolean) {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = ('' + number).split('e');
  return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
}

// From: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function round(number: number, precision: number) {
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

export const SpecialValueFilterType = {
  None: 'None',
  Only: 'Only',
  Hide: 'Hide',
} as const;
export type SpecialValueFilterType = typeof SpecialValueFilterType[keyof typeof SpecialValueFilterType];

export class VgGridColumnConditionFilter extends VgGridColumnFilter implements IFilter {
  public combination: ConditionCombination = ConditionCombination.And;
  public conditionType1: ConditionType = ConditionType.None;
  public conditionValue1: number | Date = null;
  public conditionType2: ConditionType = ConditionType.None;
  public conditionValue2: number | Date = null;

  public compareWithRound = true;
  public roundPrecision = 2;
  public valueType: ValueType = ValueType.Number;

  public specialValue: any = undefined;
  public specialValueName: string = undefined;
  public specialValueFilter: SpecialValueFilterType = SpecialValueFilterType.None;

  get clean(): boolean {
    return (
      this.conditionType1 === ConditionType.None &&
      this.conditionType2 === ConditionType.None &&
      this.specialValueFilter === SpecialValueFilterType.None
    );
  }

  filter(item: any): boolean {
    const value = this.valueGetter ? this.valueGetter(item, this.binding) : item[this.binding];
    if (this.specialValue !== undefined) {
      if (this.specialValueFilter === SpecialValueFilterType.None) {
        if (value === this.specialValue) {
          return true;
        }
      } else if (this.specialValueFilter === SpecialValueFilterType.Hide) {
        if (value === this.specialValue) {
          return false;
        }
      } else if (this.specialValueFilter === SpecialValueFilterType.Only) {
        return value === this.specialValue;
      }
    }
    if (this.conditionType1 === ConditionType.None && this.conditionType2 === ConditionType.None) {
      return true;
    }
    if (this.conditionType1 === ConditionType.None && this.conditionType2 !== ConditionType.None) {
      return this._filter(value, this.conditionValue2, this.conditionType2);
    }
    if (this.conditionType2 === ConditionType.None && this.conditionType1 !== ConditionType.None) {
      return this._filter(value, this.conditionValue1, this.conditionType1);
    }
    const cond1 = this._filter(value, this.conditionValue1, this.conditionType1);
    if (this.combination === ConditionCombination.And) {
      if (cond1) {
        return this._filter(value, this.conditionValue2, this.conditionType2);
      } else {
        return false;
      }
    } else {
      if (cond1) {
        return true;
      } else {
        return this._filter(value, this.conditionValue2, this.conditionType2);
      }
    }
  }

  private _filter(value: any, filterValue: number | Date, filterType: ConditionType): boolean {
    if (this.compareWithRound) {
      if (typeof value === 'number' && isFinite(value)) {
        value = round(value, this.roundPrecision);
      }
    }
    if (value instanceof Date && filterValue instanceof Date) {
      // 1秒単位で正規化する
      value = Math.floor(value.getTime() / 1000);
      filterValue = Math.floor(filterValue.getTime() / 1000);
    }
    switch (filterType) {
      case ConditionType.None:
        return true;
      case ConditionType.Equal:
        return value === filterValue;
      case ConditionType.NotEqual:
        return value !== filterValue;
      case ConditionType.Greater:
        return value > filterValue;
      case ConditionType.GreaterOrEqual:
        return value >= filterValue;
      case ConditionType.Less:
        return value < filterValue;
      case ConditionType.LessOrEqual:
        return value <= filterValue;
      default:
        throw new Error(`Invalid Filter Type ${filterType}`);
    }
  }
}

export class VgGridColumnValueFilter extends VgGridColumnFilter implements IFilter {
  public values = new Set<any>();

  public uniqueValues = new Set<any>();
  public nameGetter?: (value: any) => string;

  get clean(): boolean {
    return this.values.size === 0;
  }

  filter(item: any): boolean {
    let value = this.valueGetter ? this.valueGetter(item, this.binding) : item[this.binding];
    if (value instanceof Set) {
      for (const elm of value) {
        if (this.values.has(elm)) {
          return true;
        }
      }
      return false;
    } else {
      if (value === '' || value === undefined) {
        value = null;
      }
      return this.values.has(value);
    }
  }
}

// フィルタ項目の選択肢
export interface GridFilterChoice<T, V> {
  label: string;
  filter: (item: T, value?: V) => boolean;
}
// 事前に定義された選択肢の中からフィルタ項目を選ぶ
export class VgGridColumnChoiceFilter extends VgGridColumnFilter implements IFilter {
  public selectedChoices = new Set<GridFilterChoice<any, any>>();
  public choices: Array<GridFilterChoice<any, any>> = [];

  get clean(): boolean {
    return this.selectedChoices.size === 0;
  }

  filter(item: any): boolean {
    for (const choice of this.selectedChoices) {
      if (choice.filter(item, this.valueGetter ? this.valueGetter(item) : item[this.binding])) {
        return true;
      }
    }
    return false;
  }
}
