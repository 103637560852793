import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { Subject, Subscription } from 'rxjs';
import { ItaViewService } from '../../core/ita-view.service';
import { CocomeroService } from '../../core/cocomero.service';
import { ItaViewItem } from '../ita-portfolio/ita-portfolio.component';
import { VgCollectionView, VgGridComponent } from '@argentumcode/brisk-common';

export class ChartViewItem {
  constructor(public issueCode: number) {}
}

@Component({
  selector: 'app-chart-portfolio',
  templateUrl: './chart-portfolio.component.html',
  styleUrls: ['./chart-portfolio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPortfolioComponent implements OnInit, OnDestroy {
  @Input()
  get columns(): number {
    return this._columns;
  }

  set columns(value: number) {
    this._columns = value;
    this.columnIndexes = new Array(this._columns).fill(0).map((x, i) => i);
    if (this.dataSourceInput) {
      this._updateCollection();
    }
    this.onResize();
    this.changeDetectorRef.markForCheck();
  }

  @Input('dataSource')
  get dataSourceInput(): VgCollectionView {
    return this._dataSourceInput;
  }

  set dataSourceInput(value: VgCollectionView) {
    if (this._dataSourceInput === value) {
      return;
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
    this._dataSourceInput = value;
    if (this._dataSourceInput) {
      this._dataSourceInput.items.subscribe((items) => {
        this._items = items;
        this.onCollectionChanged();
      });
    }
  }

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (this._visible === value) {
      return;
    }
    this._visible = value;
    if (value) {
      if (this._invisibleUpdate) {
        this._updateCollection();
      }
      if (!this.started) {
        this.itaView.start();
        this.started = true;
      }
    } else {
      if (this.started) {
        this.itaView.stop();
        this.started = false;
      }
    }
  }

  private _visible: boolean;
  private _invisibleUpdate = false;

  private started: boolean;
  private _dataSourceInput: VgCollectionView = null;

  private _dataSourceSubscription: Subscription = null;
  private _items: Array<any> = [];

  @Output()
  cellDblClicked = new EventEmitter<{}>();

  @Output()
  stockSelected = new EventEmitter<number>();

  @ViewChild('grid', { static: true })
  public grid: VgGridComponent;

  private _columns = 5;
  public readonly rowSizeCoefficient = 0.6;
  public dataSource: Array<Array<ItaViewItem>> = [];

  public columnIndexes = new Array(this._columns).fill(0).map((x, i) => i);

  private updatedViewSubject = new Subject<any>();
  public updatedView = this.updatedViewSubject.asObservable();

  public cache = new WeakMap();

  constructor(
    private itaView: ItaViewService,
    private cocomero: CocomeroService,
    private changeDetectorRef: ChangeDetectorRef,
    private hostElement: ElementRef
  ) {}

  ngOnInit() {
    if (this.visible && !this.started) {
      this.itaView.start();
    }
    // NOTICE: これを行なわないと、初期値の高さ24pxで必要な分だけコンポーネントを作ってしまいパフォーマンスが悪化する
    //         大きくしてから小さくした場合などでも同様であり根本的な解決ではない。
    this.grid.rowHeight = 100000;
  }

  ngOnDestroy() {
    if (this.started) {
      this.itaView.stop();
    }
    if (this.updatedViewSubject) {
      this.updatedViewSubject.complete();
    }
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
      this._dataSourceSubscription = null;
    }
  }

  scroll(grid: FlexGrid, event: KeyboardEvent, direction: number) {
    const root = grid.hostElement.querySelector('[wj-part="root"]');
    if (root) {
      console.log(root.scrollTop);
      root.scrollTop += (root.clientHeight / 2) * direction;
      event.preventDefault();
    }
  }

  onInitialized(grid: FlexGrid) {
    this.onResize();
  }

  onUpdatedView(grid: FlexGrid) {
    this.updatedViewSubject.next();
  }

  onResize() {
    if (!this.grid) {
      return;
    }
    // resize時の処理
    const hostElement: HTMLElement = this.hostElement.nativeElement;
    const height = (this.rowSizeCoefficient / this.columns) * hostElement.offsetWidth;
    if (height > 0) {
      this.grid.rowHeight = height;
    }
  }

  onCollectionChanged() {
    if (!this.visible) {
      this._invisibleUpdate = true;
      return;
    }
    this._updateCollection();
  }

  private _updateCollection() {
    this.dataSource = [];
    for (let i = 0; i < this._items.length; i++) {
      if (this._items[i].issueCode) {
        if (i % this._columns === 0) {
          this.dataSource.push([]);
        }

        const lastRow = this.dataSource[this.dataSource.length - 1];
        const item = this.cache.has(this._items[i]) ? this.cache.get(this._items[i]) : new ItaViewItem(Number(this._items[i].issueCode));
        lastRow.push(item);
        this.cache.set(this._items[i], item);
      }
    }
    this._invisibleUpdate = false;
    this.changeDetectorRef.markForCheck();
  }

  scrollToTop() {
    if (!this.grid) {
      return;
    }
    this.grid.scrollToTop();
  }

  onItemClicked(item: ItaViewItem) {
    if (item) {
      this.cocomero.changeIssueCode(item.issueCode);
      this.stockSelected.emit(item.issueCode);
    }
  }

  onItemDblClicked(item: ItaViewItem) {
    if (item) {
      this.cellDblClicked.emit();
    }
  }

  focus() {
    this.grid.focus();
  }
}
