import { Observable, Subscription } from 'rxjs';

export class PagedView {
  get current() {
    return this._current;
  }

  set current(value) {
    this._current = value;
    this._update();
  }

  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  set itemsPerPage(value: number) {
    this._itemsPerPage = value;
    this._update();
  }

  // 最大ページ数
  public pages;

  // 現在のページ番号(0-indexed)
  private _current = 0;

  // 1ページ事のアイテム数
  private _itemsPerPage = 1;

  // ページングされたデータ
  public data: Array<any> = [];

  private _arr: Array<any> = [];

  get dataSource(): Array<any> {
    return this._arr;
  }

  set dataSource(arr: Array<any>) {
    this._arr = arr;
    this._update();
  }

  constructor() {
    this._update();
  }

  private _update() {
    this.pages = Math.ceil(this._arr.length / this._itemsPerPage);
    if (this.pages === 0) {
      this.pages = 1;
    }
    if (this._current >= this.pages) {
      this._current = this.pages - 1;
    }
    this.data = [];
    for (let i = this._current * this._itemsPerPage; i < this._arr.length && this.data.length < this._itemsPerPage; i++) {
      this.data.push(this._arr[i]);
    }
  }
}
