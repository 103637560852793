import { Injectable } from '@angular/core';
import { HistoryType, ViewHistoryService } from '../../brisk-core/view-history.service';
import * as jaconv from 'jaconv';

@Injectable()
export class IssueCodeInputService {
  get stockSource(): IssueCodeInputListElement[] {
    return this._stockSource;
  }

  set stockSource(value: IssueCodeInputListElement[]) {
    this._stockSource = value;
    this.issueCodeMap.clear();
    for (const t of value) {
      this.issueCodeMap.set(t.issueCode, t);
    }
  }
  private _stockSource: IssueCodeInputListElement[] = [];
  private issueCodeMap = new Map<number, IssueCodeInputListElement>();

  static normalize(str: string): string {
    return jaconv.toHebon(jaconv.toHiragana(jaconv.normalize(str))).toLowerCase();
  }

  constructor(private history: ViewHistoryService) {}

  filter(filter: string, maxCount?: number): Array<IssueCodeInputListElement> {
    let history = false;
    if (filter.startsWith('*') || filter === '') {
      history = true;
      filter = filter.slice(1);
    }
    const issueCode = IssueCodeInputService.normalize(filter.split(/\s+/)[0]);
    const normalizedFilter = IssueCodeInputService.normalize(filter);
    const newList: IssueCodeInputListElement[] = [];

    if (history) {
      for (let i = this.history.histories.length - 1; i >= 0; i--) {
        const code = this.history.histories[i].code;
        if (this.issueCodeMap.has(code)) {
          this.issueCodeMap.get(code).history = i;
          newList.push(this.issueCodeMap.get(code));
        }
      }
      return newList;
    }
    if (!history) {
      for (const d of this.stockSource) {
        if (maxCount && newList.length >= maxCount) {
          break;
        }
        if (d.normalizedIssueCode.startsWith(issueCode)) {
          d.priority = 1;
          newList.push(d);
        }
      }
    }

    if (filter !== '') {
      for (const d of this.stockSource) {
        if (maxCount && newList.length >= maxCount) {
          break;
        }
        if (d.normalizedName.includes(normalizedFilter)) {
          d.priority = 3;
          newList.push(d);
        }
      }
    }
    return newList;
  }
}

export interface IssueCodeInputStock {
  issueCode: number;
  name: string;
}

export class IssueCodeInputListElement {
  public issueCode: number;
  public name: string;
  public normalizedName: string;
  public normalizedIssueCode: string;
  public priority: number;
  public history: number;

  constructor(data: IssueCodeInputStock) {
    if (data.issueCode === null) {
      this.issueCode = null;
      this.name = '';
      return;
    }
    this.issueCode = data.issueCode;
    this.name = data.name;
    this.normalizedIssueCode = this.issueCode.toString();
    this.normalizedName = IssueCodeInputService.normalize(data.name);
    this.history = 0;
  }
}
