import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItaComponent } from './ita/ita.component';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { CanvasGridModule } from '../canvas-grid/canvas-grid.module';
import { TimestampFormatPipe } from '../brisk-common/timestamp-format.pipe';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icons/icons.module';
import { BriskDialogModule } from '../dialog/dialog.module';

@NgModule({
  imports: [CommonModule, CanvasGridModule, BriskCommonModule, NgbPopoverModule, IconsModule, BriskDialogModule],
  declarations: [ItaComponent],
  exports: [ItaComponent],
  providers: [TimestampFormatPipe],
})
export class ItaModule {
  constructor() {}
}
