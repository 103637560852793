export const enum ItaRowDelta {
  None = 0,
  Increase = 1,
  Decrease = 2,
}

export class ItaRowItem {
  quantity: number;
  normalizedQuantity: number;
  order: number;
  delta: ItaRowDelta;
  total: number;
  normalizedTotal: number;
}

export class ItaRow {
  constructor() {
    this.bid = new ItaRowItem();
    this.bidClose = new ItaRowItem();
    this.ask = new ItaRowItem();
    this.askClose = new ItaRowItem();
  }

  price10: number;
  fillQuantity: number;
  normalizedFillQuantity: number;
  bid: ItaRowItem;
  bidClose: ItaRowItem;
  bidState: number;

  ask: ItaRowItem;
  askClose: ItaRowItem;
  askState: number;
}

export class ItaRowDetail extends ItaRow {
  constructor() {
    super();
  }
}
