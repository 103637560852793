import { Injectable } from '@angular/core';
import { Observable, Observer, ReplaySubject, Subject } from 'rxjs';
import { WindowRefService } from './window-ref.service';
import { AnonymousSubject } from 'rxjs/internal-compatibility';

export class WebSocketConnectionError extends Error {
  public type = 'WebSocketConnectionError';

  constructor(message: string) {
    super(message);
    this.name = 'WebSocketConnectionError';
  }
}

@Injectable()
export class WebSocketClientService {
  private closeEvent = new ReplaySubject<any>();
  private windowClosed = this.closeEvent.asObservable();
  constructor(private window: WindowRefService) {
    window.nativeWindow.addEventListener('pagehide', () => {
      if (this.closeEvent) {
        this.closeEvent.next();
        this.closeEvent.complete();
        this.closeEvent = null;
      }
    });
  }

  connect(url: string, subprotocol?: string): Subject<MessageEvent> {
    return this.create(url, subprotocol);
  }

  private create(url: string, subprotocol?: string): Subject<MessageEvent> {
    const ws: WebSocket = new this.window.nativeWindow['WebSocket'](url, subprotocol);
    let forceClose = false;
    ws.binaryType = 'arraybuffer';
    const observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = () => {
        obs.error(new WebSocketConnectionError(`WebSocket Error`));
      };
      ws.onclose = (event: CloseEvent) => {
        if (!forceClose && !event.wasClean) {
          obs.error(new WebSocketConnectionError(`WebSocket Close Error`));
        }
        obs.complete();
      };
      return ws.close.bind(ws);
    });
    const observer = {
      next: (data: MessageEvent) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send((<Uint8Array>(<any>data)).buffer);
        }
      },
      complete: () => {
        console.log('Close WS');
        ws.close();
        console.log(ws.readyState);
      },
      error(err: any): void {},
    };
    this.windowClosed.subscribe(() => {
      if (ws.readyState === 0 || ws.readyState === 1) {
        forceClose = true;
        ws.close();
      }
    });
    return new AnonymousSubject(observer, observable);
  }
}
