import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fita4-ita-panel-header',
  templateUrl: './ita-panel-header.component.html',
  styleUrls: ['./ita-panel-header.component.scss'],
})
export class ItaPanelHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onUpdateItaPanelSize(normalizedWidth: number) {}
}
