import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { WebSocketClientService } from '@argentumcode/brisk-common';
import { multipleWindowTimer } from '@argentumcode/multiple-window';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ApiService } from './api.service';

class MockWsSubject {
  subscribe(x: any, y: any, z: any): Subscription {
    return {} as Subscription;
  }
  unsubscribe() {}
  next(msg: any) {}
  complete() {}
}

@Injectable({
  providedIn: 'root',
})
export class FlexWebSocketService implements OnDestroy {
  private ws: any = null;

  private onDataSubject = new Subject<Uint8Array>();
  public onData: Observable<Uint8Array> = this.onDataSubject.asObservable();

  private restartSubject = new Subject<Error>();
  public error = this.restartSubject.asObservable();

  public wsSub: Subscription;

  public url: string;

  public connected = false;
  private connectedSubject = new Subject<{}>();
  public connected$ = this.connectedSubject.asObservable();

  private wsMockDoneSubject = new ReplaySubject<{}>(1);
  wsMockDone$ = this.wsMockDoneSubject.asObservable();

  private wsMockStartSubject = new ReplaySubject<{}>(1);
  private wsMockStart$ = this.wsMockStartSubject.asObservable();

  constructor(private webSocket: WebSocketClientService, private zone: NgZone, private api: ApiService) {}

  wsMockStart() {
    this.wsMockStartSubject.next({});
    this.wsMockStartSubject.complete();
  }

  wsMock(): Observable<Uint8Array> {
    return this.api.wsData().pipe(
      switchMap((buf: Uint8Array) => {
        return new Observable<Uint8Array>((subscriber) => {
          // UpdateNumberの入ったデータが一番最初に入っているのでまず読み込む
          let i = 0;
          const startTimestamp = new DataView(buf.buffer).getUint32(i, true);
          const size = new DataView(buf.buffer).getUint32(i + 4, true);
          const data = buf.subarray(i + 8, i + 8 + size);
          subscriber.next(data);
          i += 8 + size;

          this.wsMockStart$.pipe(take(1)).subscribe(() => {
            const startTime = performance.now();
            const next: (now: number) => { data: Uint8Array; end: boolean } = (now: number) => {
              if (i < buf.length) {
                const ts = new DataView(buf.buffer).getUint32(i, true);
                if (ts > startTimestamp + now - startTime) {
                  return { data: null, end: false };
                }
                const size = new DataView(buf.buffer).getUint32(i + 4, true);
                // console.log(size);
                const data = buf.subarray(i + 8, i + 8 + size);
                i += 8 + size;
                return { data: data, end: false };
              } else {
                return { data: null, end: true };
              }
            };
            multipleWindowTimer(10, 10).subscribe(() => {
              this.zone.runGuarded(() => {
                const now = performance.now();
                for (;;) {
                  const data = next(now);
                  if (data.end) {
                    subscriber.complete();
                    break;
                  } else {
                    if (data.data) {
                      subscriber.next(data.data);
                    } else {
                      break;
                    }
                  }
                }
              });
            });
          });
        });
      })
    );
  }

  start() {
    if (this.ws) {
      throw new Error('Already Connected');
    }
    // this.ws = this.webSocket.connect(this.url, 'flex');
    this.ws = new MockWsSubject();
    this.connected = false;

    this.wsSub = this.wsMock().subscribe(
      (data) => {
        this.onDataSubject.next(data);

        if (!this.connected) {
          this.connected = true;
          this.connectedSubject.next();
        }
      },
      (err) => {
        throw err;
      },
      () => {
        this.onComplete();
      }
    );

    return;

    this.zone.runOutsideAngular(() => {
      this.wsSub = this.ws.subscribe(
        (msg: MessageEvent) => {
          this.onDataSubject.next(new Uint8Array(msg.data));
          if (!this.connected) {
            this.connected = true;
            this.connectedSubject.next();
          }
        },
        (err) => {
          this.zone.runGuarded(() => {
            console.error(err);
            if (err && err.type !== 'WebSocketConnectionError') {
              this.stop();
              throw err;
            } else {
              // 再接続する
              this.stop();
              this.restartSubject.next(err);
            }
          });
        },
        () => {
          this.zone.runGuarded(() => {
            this.onComplete();
          });
        }
      );
    });
  }

  send(buf: Uint8Array) {
    if (this.ws) {
      this.ws.next(buf);
    }
  }

  stop() {
    if (this.wsSub) {
      this.wsSub.unsubscribe();
    }
    if (this.ws) {
      this.ws.unsubscribe();
      this.ws.complete();
      this.ws = null;
    }
  }

  private onComplete() {
    console.log('wsMock done');
    this.wsMockDoneSubject.next({});
    this.wsMockDoneSubject.complete();
    this.stop();
  }

  ngOnDestroy() {
    this.stop();
    this.restartSubject.complete();
    this.restartSubject.unsubscribe();
    this.onDataSubject.complete();
    this.onDataSubject.unsubscribe();
  }
}
