import { Injectable, OnDestroy, Optional, RendererFactory2, TemplateRef } from '@angular/core';
import { Overlay, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { MultipleWindowDialogService } from './multiple-window-dialog.service';
import uuidv4 from 'uuid/v4';
import { MultipleWindowModalOverlayComponent } from './multiple-window-modal-overlay/multiple-window-modal-overlay.component';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { Observable, of, Subscriber, Subscription } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { SimpleDialog, SimpleDialogComponent, SimpleDialogResult, SimpleDialogResultClosed } from './simple-dialog/simple-dialog.component';
import { WindowRefService } from '../brisk-browser/window-ref.service';

interface BackdropCurrentWindow {
  currentWindow: true;
}

interface BackdropOtherWindow {
  currentWindow: false;
  overlay: OverlayRef;
}
type Backdrop = BackdropCurrentWindow | BackdropOtherWindow;

interface BriskDialogConfigData {
  // 複数ウィンドウで動作している時に、他のWindowの操作も防止するかどうか
  multipleWindowModal?: boolean;
  additionalPanelClass?: string[];
  additionalBackdropClass?: string[];
}

export type BriskDialogConfig<R> = MatDialogConfig<R> & BriskDialogConfigData;

@Injectable()
export class BriskDialogService implements OnDestroy {
  private stateSubscription = new Subscription();

  // 自分のWindow及び他のWindow由来のDialogのためのOverlay
  private backdrops: {
    [key: string]: Backdrop;
  } = {};

  // 現在モーダルが表示されているウィンドウ。切り替えのために利用。
  topWindow: Window | undefined = undefined;

  constructor(
    private _dialog: MatDialog,
    _overlayContainer: OverlayContainer,
    rendererFactory: RendererFactory2,
    private _window: WindowRefService,
    private _overlay: Overlay,
    @Optional() private _multiple?: MultipleWindowDialogService
  ) {
    // Disable animation Overlayを使うもの全てのAngular Animationを無効化してしまう問題がある
    //   https://github.com/angular/components/issues/3616 が解決されたら、その方法で実装しなおす。
    const renderer = rendererFactory.createRenderer(null, null);
    renderer.setProperty(_overlayContainer.getContainerElement(), '@.disabled', true);

    if (_multiple) {
      this._otherWindowDialogOverlay();
    }
  }

  ngOnDestroy(): void {
    if (this._multiple) {
      // Force update state to close
      // _dialog.closeAll()を呼んでも afterClosedが呼び出されないため、手動で呼び出している。
      for (const id of Object.keys(this.backdrops)) {
        if (this.backdrops[id].currentWindow) {
          this._multiple.closeDialog(id);
        }
      }
    }
    this._dialog.closeAll();
    this.stateSubscription.unsubscribe();
  }

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: BriskDialogConfig<D>
  ): Observable<MatDialogRef<T, R>> {
    const dialogRef: MatDialogRef<T, R> = this._dialog.open(componentOrTemplateRef, {
      disableClose: true,
      // 既存のダイアログと同デザインにするためのCSSクラス
      panelClass: ['brisk-dialog', ...(config.additionalPanelClass ?? [])],
      // AnimationなしのBackdrop
      backdropClass: ['brisk-dialog-overlay', ...(config.additionalBackdropClass ?? [])],
      ...config,
    });
    if (this._multiple && config.multipleWindowModal) {
      const id = uuidv4();
      console.assert(!(id in this.backdrops));
      this.backdrops[id] = {
        currentWindow: true,
      };
      this._multiple.openDialog(id, this._window.nativeWindow);
      dialogRef.afterClosed().subscribe(() => {
        this._multiple.closeDialog(id);
      });
    }
    return of(dialogRef);
  }

  openDialog<T, R = any, D = any>(
    dialog: SimpleDialog<T, R, D>,
    config?: BriskDialogConfig<SimpleDialog<T, R, D>>
  ): Observable<SimpleDialogResult<R>> {
    const dialogRef: Observable<MatDialogRef<SimpleDialogComponent<unknown, unknown>, SimpleDialogResult<R>>> = this.open(
      SimpleDialogComponent,
      {
        ...config,
        data: dialog,
      }
    );
    return dialogRef.pipe(
      mergeMap((ref) => {
        return ref.afterClosed().pipe(
          map((f) => {
            if (!f) {
              return SimpleDialogResultClosed;
            } else {
              return f;
            }
          })
        );
      })
    );
  }

  // 単純なメッセージをダイアログとして表示する。
  // window#alert の代替として利用することを想定。
  alert(message: string) {
    return this.openDialog(
      {
        content: message,
        buttons: [
          {
            text: 'OK',
          },
        ],
      },
      {
        width: '30em',
        maxWidth: '80vw',
      }
    );
  }

  // 他のWindowでモーダルが開かれた時のOverlayを
  private _otherWindowDialogOverlay() {
    this.stateSubscription.add(
      this._multiple.state$.subscribe((state) => {
        if (state.modalIds.length === 0) {
          this.topWindow = undefined;
        } else {
          this.topWindow = state.modalIds[state.modalIds.length - 1].dialogWindow;
        }
        for (const id of state.modalIds.map((a) => a.id)) {
          if (id in this.backdrops) {
            continue;
          }
          const ref = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'brisk-dialog-overlay',
          });
          // backdropのクリック時にモーダルが表示されているウィンドウに移動する
          // Dispose時にcompleteされるため、unsubscribe不要
          ref.backdropClick().subscribe(() => {
            if (this.topWindow) {
              try {
                if (this.topWindow.opener === this._window.nativeWindow) {
                  this.topWindow.focus();
                }
              } catch {}
            }
          });
          ref.keydownEvents().subscribe((ev) => {
            ev.preventDefault();
            ev.stopPropagation();
          });
          const c = new ComponentPortal(MultipleWindowModalOverlayComponent);
          ref.attach(c);
          this.backdrops[id] = {
            currentWindow: false,
            overlay: ref,
          };
        }
        // 表示されているBackdropのうち、既にState上に存在しないものを削除する
        for (const id of Object.keys(this.backdrops)) {
          if (state.modalIds.some((a) => a.id === id)) {
            continue;
          }
          const backdrop = this.backdrops[id];
          if (backdrop.currentWindow === false) {
            backdrop.overlay.dispose();
          }
          delete this.backdrops[id];
        }
      })
    );
  }
}
