import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GlOnResize, GlOnShow } from '@argentumcode/ngx-golden-layout';
import { ResizeService } from '@argentumcode/brisk-common';
import { SummaryListComponent as OSummaryListComponent } from 'fita3/src/app/portfolio/summary-list/summary-list.component';
import { SummaryListsService } from '../../summary-lists.service';

@Component({
  selector: 'app-fita4-summary-list-panel',
  templateUrl: './summary-list-panel.component.html',
  styleUrls: ['./summary-list-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SummaryListPanelComponent implements OnInit, GlOnShow, GlOnResize {
  constructor(private resize: ResizeService, private summary: SummaryListsService) {}

  @ViewChild('summaryList', { static: true })
  summaryList: OSummaryListComponent;

  ngOnInit(): void {
    this.summary.setSummaryItemGetter((item) => this.summaryList.getSummaryPortfolio(item));
    this.summary.setFirstItemGetter(() => this.summaryList.getFirstPortfolio());
  }

  glOnShow(): void {
    this.resize.dispatchResize();
  }

  glOnResize(): void {
    this.resize.dispatchResize();
    this.summaryList.getSummaryPortfolio('a');
  }
}
