export enum ToastType {
  Success,
  Info,
  Error,
}

export class Toast {
  delay: number | null;
  cssClass: string | null;

  icon: Array<string>;

  messages: Array<string>;

  constructor(message: string | Array<string>, type: ToastType = ToastType.Info) {
    if (typeof message === 'string') {
      this.messages = [message];
    } else {
      this.messages = message;
    }
    switch (type) {
      case ToastType.Success: {
        this.cssClass = 'c-toast--success';
        this.icon = ['fas', 'check'];
        break;
      }
      case ToastType.Info: {
        this.cssClass = 'c-toast--info';
        this.icon = ['fas', 'info-circle'];
        break;
      }
      case ToastType.Error: {
        this.cssClass = 'c-toast--error';
        this.icon = ['fas', 'exclamation-triangle'];
        break;
      }
      default: {
        throw new Error('Invalid Toast Type');
      }
    }
  }
}
