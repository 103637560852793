import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { format } from 'date-fns';
import { tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  OHLCResponse,
  OHLCResponseExtended,
  SummaryType,
  Portfolio,
  StockMaster,
  LineChartTodayData,
  FlexExchange,
} from '@argentumcode/brisk-common';
import { Summaries } from './summary.service';
import { FlexOperator } from './flex-operator';
import { environment } from '../../environments/default/environment';

// cf. summary.service.ts
const summaryIdETF = 'issueType:115,116';
const summaryIdREIT = 'issueType:119';
const OHLC_LENGTH = environment.flexConfig.ohlcLength;

class IndustryChartDatum {
  public masters: Array<StockMaster> = [];
  public portfolios: Array<Portfolio> = [];
  public chart = new LineChartTodayData(OHLC_LENGTH);
  constructor() {}
}

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private cache: { [key: string]: OHLCResponse } = {};
  private industryChartData: { [key: string]: IndustryChartDatum } = {};
  private nk225Master: StockMaster;
  private nk225Portfolio: Portfolio;
  private nk225TodayChart = new LineChartTodayData(OHLC_LENGTH);
  private nk225PastChart: OHLCResponseExtended;
  public nk225Name: string;
  private chartUpdate: Subject<{}> = new Subject<{}>();
  public updated: Observable<{}> = this.chartUpdate.asObservable();

  private showNk225Chart = (environment.flexConfig.exchange as FlexExchange) === FlexExchange.Tokyo;

  constructor(private api: ApiService) {}

  public initialize(op: FlexOperator, summaries: Summaries) {
    // 注意: op.summariesには非表示summaryが入っていない
    for (const summary of summaries.summaries) {
      if (
        summary.type === SummaryType.Industry ||
        (summary.type === SummaryType.Index && (summary.summaryId === summaryIdETF || summary.summaryId === summaryIdREIT))
      ) {
        const industryChartDatum = new IndustryChartDatum();
        this.industryChartData[summary.summaryId] = industryChartDatum;
        // cf. getStockPortfolio in summary.service
        for (const [issueCode, _] of summary.issueList) {
          if (issueCode in op.issueCodeMap) {
            const idx = op.issueCodeMap[issueCode];
            industryChartDatum.masters.push(op.stockMasters[idx]);
            industryChartDatum.portfolios.push(op.portfolios[idx]);
          }
        }
      }
    }
    if (this.showNk225Chart) {
      const nk225idx = op.issueCodeMap[1321];
      this.nk225Master = op.stockMasters[nk225idx];
      this.nk225Portfolio = op.portfolios[nk225idx];
      this.nk225Name = this.nk225Master.name;
    }
  }

  public update(op: FlexOperator) {
    const counts = new Array<number>(OHLC_LENGTH);
    const currentIdx = this.timestampToIndex(op.getTimestamp());
    for (const summaryId of Object.keys(this.industryChartData)) {
      const masters = this.industryChartData[summaryId].masters;
      const portfolios = this.industryChartData[summaryId].portfolios;
      const rates = this.industryChartData[summaryId].chart.rates.fill(undefined);
      counts.fill(0);
      for (let i = 0; i < masters.length; i++) {
        let lastRate: number;
        for (let x = 0; x < currentIdx + 1; x++) {
          if (portfolios[i].lastPrices10[x] !== 0) {
            lastRate = portfolios[i].lastPrices10[x] / masters[i].basePrice10 - 1;
          }
          if (lastRate !== undefined) {
            if (rates[x] === undefined) {
              rates[x] = 0;
            }
            rates[x] += lastRate;
            counts[x]++;
          }
        }
      }
      for (let x = 0; x < OHLC_LENGTH; x++) {
        if (rates[x] !== undefined) {
          // this implies counts[x] > 0
          rates[x] = rates[x] / counts[x];
          this.industryChartData[summaryId].chart.lastIndex = x;
        }
      }
    }
    if (this.showNk225Chart) {
      this.nk225TodayChart.rates.fill(undefined);
      for (let x = 0; x < currentIdx + 1; x++) {
        if (this.nk225Portfolio.lastPrices10[x] !== 0) {
          this.nk225TodayChart.rates[x] = this.nk225Portfolio.lastPrices10[x] / this.nk225Master.basePrice10 - 1;
          this.nk225TodayChart.lastIndex = x;
        }
      }
    }
    this.chartUpdate.next();
  }

  public getChart(issueCode: number, date: Date): Observable<OHLCResponse> {
    const cacheKey = `${issueCode}:${format(date, `YYYY-MM-DD`)}`;
    if (!(cacheKey in this.cache)) {
      return this.api.ohlc(issueCode, date).pipe(
        tap((data) => {
          this.cache[cacheKey] = data;
        })
      );
    } else {
      return of(this.cache[cacheKey]);
    }
  }

  // cf. summary.service.ts, stock-master.ts
  private getSummaryId(master: StockMaster): string {
    if (master.industryCode === 9999) {
      if (master.issueType === 115 || master.issueType === 116) {
        return summaryIdETF;
      } else if (master.issueType === 119) {
        return summaryIdREIT;
      }
    }
    return `industry:${master.industryCode}`;
  }

  public getIndustryChart(master: StockMaster): LineChartTodayData {
    const summaryId = this.getSummaryId(master);
    return this.industryChartData[summaryId] ? this.industryChartData[summaryId].chart : undefined;
  }

  public getNk225TodayChart(): LineChartTodayData {
    return this.nk225TodayChart;
  }

  public getNk225PastChart(date: Date): Observable<OHLCResponseExtended> {
    if (!this.nk225PastChart) {
      return this.getChart(1321, date).pipe(
        map((ohlc) => OHLCResponseExtended.createPriceChanges(ohlc, this.nk225Master.basePrice10 / 10)),
        tap((data) => {
          this.nk225PastChart = data;
        })
      );
    } else {
      return of(this.nk225PastChart);
    }
  }

  // cf. ChartDataSourceStock
  timestampToIndex(timestamp: number): number {
    const ret = Math.trunc((timestamp - 9 * 60 * 60 * 1000000 - 1) / (5 * 60 * 1000000));
    if (ret <= 29) {
      return Math.max(0, ret);
    } else if (ret <= 41) {
      return 29;
    } else {
      return Math.min(ret - 12, OHLC_LENGTH - 1);
    }
  }
}
