import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fastDecimal',
})
export class FastDecimalPipe implements PipeTransform {
  transform(value: any, args?: FastDecimalFormat): any {
    if (value === undefined || value === null) {
      return '';
    }
    if (args === undefined || args === null) {
      args = {};
    }
    const option: FastDecimalFormat = {
      separateComma: 'separateComma' in args ? args.separateComma : true,
      fractionDigits: 'fractionDigits' in args ? args.fractionDigits : 0,
      addPlusSign: 'addPlusSign' in args ? args.addPlusSign : false,
    };
    let numberStr = value.toFixed(option.fractionDigits);
    if (value > 0 && option.addPlusSign) {
      numberStr = '+' + numberStr;
    }
    if (option.separateComma) {
      if (option.fractionDigits <= 3) {
        return numberStr.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      } else {
        const spittedNumber: string[] = numberStr.split('.', 2);
        return spittedNumber[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '.' + spittedNumber[1];
      }
    } else {
      return numberStr;
    }
  }
}

interface FastDecimalFormat {
  separateComma?: boolean;
  fractionDigits?: number;
  addPlusSign?: boolean;
}
