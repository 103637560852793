import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface SaveItemHeader {
  tooltip: string;
  icon?: IconProp;
  click: (ev: MouseEvent) => void;
}
export const SAVE_ITEM_HEADER = new InjectionToken<SaveItemHeader>('SAVE_ITEM_HEADER');

@Component({
  selector: 'app-fita4-save-item-header',
  templateUrl: './save-item-header.component.html',
  styleUrls: ['./save-item-header.component.scss'],
})
export class SaveItemHeaderComponent implements OnInit {
  constructor(@Inject(SAVE_ITEM_HEADER) public saveItemHeader: SaveItemHeader) {}

  ngOnInit(): void {}
}
