import { ChangeDetectorRef, Component, ErrorHandler, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorCode, ErrorHandlerService, ErrorWrapper, LocalStorageService } from '@argentumcode/brisk-common';
import { Title } from '@angular/platform-browser';
import { Popup } from '@grapecity/wijmo.input';
import { Button, ModalDialogService } from 'fita3/src/app/shared/modal-dialog.service';
import { StorageService } from 'fita3/src/app/core/storage.service';
import { ReloadService } from 'fita3/src/app/core/reload.service';
import { PersistentStateService } from '../fita4-core/persistent-state.service';
import { ORDER_OPERATOR_TOKEN, OrderOperator } from '../order/types/interfaces';
import { CancelEventArgs } from '@grapecity/wijmo';

/**
 * エラーの分類
 */
enum ErrorType {
  /**
   * 事前に分類されない種類のエラー
   */
  FatalError,
  /**
   * メモリ不足
   */
  MemoryAllocationError,
}

@Component({
  selector: 'app-fita4-fatal-error-dialog',
  templateUrl: './fatal-error-dialog.component.html',
  styleUrls: ['./fatal-error-dialog.component.scss'],
})
export class FatalErrorDialogComponent implements OnInit, OnDestroy {
  public readonly ErrorType = ErrorType;
  public errorType: ErrorType = ErrorType.FatalError;
  @ViewChild('popup', { static: true })
  public popup: Popup;
  @ViewChild('authPopup')
  public authPopup: Popup;

  private errorRaisedSubscription: Subscription = null;

  errorMessage = '';
  errorCode: ErrorCode;
  showRestart: boolean;
  showLogin: boolean;

  constructor(
    @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: ModalDialogService,
    private title: Title,
    private state: PersistentStateService<unknown>,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    private _reload: ReloadService
  ) {}

  ngOnInit() {
    this.errorRaisedSubscription = this.errorHandler.errorRaised.subscribe((err: ErrorWrapper) => {
      this.processError(err);
    });
  }

  reload() {
    this._reload.reload();
  }

  reloadWithResetLayout() {
    this.dialog
      .dialog('レイアウトが初期化されます。よろしいですか。', [
        new Button('初期化する', 'yes', 'btn-danger'),
        new Button('キャンセル', 'cancel', 'btn-light'),
      ])
      .subscribe((result) => {
        if (result === 'yes') {
          if (this.orderOperator.config.orderEnabled) {
            this.state.updateMainWindowLayoutWithOrder(undefined);
            this.state.updateFullItaWindowLayoutWithOrder(undefined);
          } else {
            this.state.updateMainWindowLayoutViewOnly(undefined);
            this.state.updateFullItaWindowLayoutViewOnly(undefined);
          }
          alert('レイアウトが初期化されました。証券会社のウェブサイトから再度起動してください。');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.errorRaisedSubscription !== null) {
      this.errorRaisedSubscription.unsubscribe();
      this.errorRaisedSubscription = null;
    }
  }

  hiding(e: CancelEventArgs) {
    e.cancel = true;
  }

  processError(err: ErrorWrapper): void {
    if (this.popup.isVisible) {
      return;
    }
    this.errorMessage = err.errorMessage;
    this.errorCode = err.code;
    this.title.setTitle(`BRiSK ${this.errorCode}`);
    this.showLogin = err.showLogin;
    this.showRestart = err.showRestart;

    this.popup.show();
    this.changeDetectorRef.markForCheck();
  }
}
