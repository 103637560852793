import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoldenLayoutComponent, IExtendedGoldenLayoutConfig } from '@argentumcode/ngx-golden-layout';
import { asyncScheduler, BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject, timer } from 'rxjs';
import {
  DisallowMultipleTabsService,
  LocalStorageService,
  Market,
  ResizeService,
  ThemeService,
  Toast,
  ToasterService,
} from '@argentumcode/brisk-common';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { ChartService } from 'fita3/src/app/core/chart.service';
import { StockDetailsService } from '../stock-details.service';
import { SummaryListsService } from '../summary-lists.service';
import { ComponentConfig, ContentItem, ItemConfigType } from 'golden-layout';
import { StorageService } from 'fita3/src/app/core/storage.service';
import { ChildrenWindowSyncService, proxyObservable } from '@argentumcode/multiple-window';
import { Title } from '@angular/platform-browser';
import { FontSizeService } from '../font-size.service';
import { ORDER_OPERATOR_TOKEN, ORDER_UI_SERVICE_TOKEN, OrderOperator, OrderUiService } from '../order/types/interfaces';
import { PersistentStateService } from '../fita4-core/persistent-state.service';
import { mergeMap, observeOn, switchMap, take } from 'rxjs/operators';
import { BriskDialogService } from '@argentumcode/brisk-common';
import { Fita4Service } from '../fita4-core/fita4.service';
import { environment as fita4Env } from '../../environments/default/environment';
import { environment as fita3Env } from 'fita3/src/environments/default/environment';
import { DemoFinishedDialogService } from '../shared-components/demo-finished-dialog.service';
import { commonDimensionParameters } from '../golden-layout-utils';

export interface WindowState {
  innerWidth?: number;
  innerHeight?: number;
  screenTop?: number;
  screenLeft?: number;
}

type HelpEntry = { name: string; class?: string } & ({ link: string } | { onClick: string });

const INITIAL_LAYOUT: IExtendedGoldenLayoutConfig = {
  settings: {
    showPopoutIcon: false,
    showMaximiseIcon: false,
    showCloseIcon: false,
  },
  dimensions: {
    minItemWidth: 100,
  },
  content: [
    {
      type: 'column',
      content: [
        {
          type: 'stack',
          content: [
            {
              type: 'component',
              componentName: 'StockDetail', // The name defined in componentTypes
              title: '銘柄詳細',
              componentState: {
                syncWithCocomero: true,
              },
              id: 'stockDetailMain',
              isClosable: false,
            },
            {
              type: 'component',
              componentName: 'StockDetail',
              title: '銘柄詳細',
              componentState: {
                syncWithCocomero: false,
                issueCode: 7203,
              },
              isClosable: true,
            },
          ],
        },

        {
          type: 'stack',
          content: [
            {
              type: 'component',
              componentName: 'StockDetail', // The name defined in componentTypes
              title: '銘柄詳細',
              componentState: {
                issueCode: 9432,
              },
              isClosable: true,
            },
          ],
        },
      ],
    },
  ],
};

@Component({
  selector: 'app-fita4-ita-root',
  templateUrl: './secondary-window.component.html',
  styleUrls: ['./secondary-window.component.scss'],
})
export class SecondaryWindowComponent implements OnInit {
  @ViewChild(GoldenLayoutComponent, { static: false }) layout: GoldenLayoutComponent;
  layoutConfig$ = new ReplaySubject(1);

  private initialStateChange = false;
  private useDefaultLayout = false;
  orderEnabled = false;
  pendingFocusToMainPanel = false;

  showViewConfig = false;

  orderInitialized = false;

  fita4Initialized = false;

  watchListIssueCodes$: Observable<Set<number>>;
  marketError$: Observable<Error>;

  private doNotShowTedinetDialog = false;

  get tedinetUrl(): string | null {
    return fita4Env.tedinetUrl;
  }

  get marketCondition(): Array<Market> {
    return this.cocomero.marketConditions;
  }

  get helps(): Array<HelpEntry> {
    return fita4Env.helps;
  }

  constructor(
    private resize: ResizeService,
    private cocomero: CocomeroService,
    private chart: ChartService,
    private localStorage: LocalStorageService,
    private storage: StorageService,
    private toast: ToasterService,
    private stockDetailsService: StockDetailsService,
    private summaryLists: SummaryListsService,
    private theme: ThemeService,
    private _childrenWindowSync: ChildrenWindowSyncService,
    private _fontSize: FontSizeService,
    private _title: Title,
    private zone: NgZone,
    private persistentState: PersistentStateService<unknown>,
    @Inject(ORDER_OPERATOR_TOKEN) private orderOperator: OrderOperator,
    @Inject(ORDER_UI_SERVICE_TOKEN) private orderUi: OrderUiService,
    private dialog: BriskDialogService,
    private fita4: Fita4Service,
    private _demoFinishedDialog: DemoFinishedDialogService,
    private _disallowMultipleTabs: DisallowMultipleTabsService
  ) {
    this._childrenWindowSync.initialize();
    this._disallowMultipleTabs.check();
    _title.setTitle('BRiSK Next - フル板');
    this.persistentState.theme$.subscribe((t) => {
      this.theme.theme = t;
    });

    this.zone.runOutsideAngular(() => {
      timer(0, 160).subscribe(() => {
        if (this.initialized) {
          this.cocomero.updatePortfolioRequest(false);
        }
      });
      timer(0, 5000).subscribe(() => {
        if (this.initialized) {
          this.cocomero.updatePortfolioRequest(true);
        }
      });
    });
    forkJoin([
      proxyObservable(orderOperator.initialized).pipe(
        mergeMap(() =>
          orderOperator.config.orderEnabled
            ? this.persistentState.fullItaWindowLayoutWithOrder$
            : this.persistentState.fullItaWindowLayoutViewOnly$
        ),
        take(1)
      ),
      proxyObservable(orderOperator.initialized),
      this._fontSize.fontSizeLoaded$,
    ]).subscribe(([savedLayout, _]) => {
      this.orderEnabled = orderOperator.config.orderEnabled;
      if (savedLayout) {
        try {
          // TODO: requires deep clone
          const layout = { ...savedLayout };
          if (layout) {
            layout.dimensions = {
              ...layout.dimensions,
              ...commonDimensionParameters,
              headerHeight: this._fontSize.glHeaderSize(),
            };
            this.layoutConfig$.next(layout);
          }
        } catch {
          this.toast.add(new Toast('レイアウトの読み込みに失敗しました。初期レイアウトを利用します。'));
        }
      } else {
        this.layoutConfig$.next({
          ...INITIAL_LAYOUT,
          dimensions: {
            ...commonDimensionParameters,
            headerHeight: this._fontSize.glHeaderSize(),
          },
        });
        this.useDefaultLayout = true;
      }

      this.summaryLists.newListItem.subscribe((item) => {
        console.log('new item', item);
        this.openStockList(item);
      });

      this._fontSize.fontSize$.subscribe(() => {
        if (this.layout) {
          this.layout.getGoldenLayoutInstance().config.dimensions.headerHeight = this._fontSize.glHeaderSize();
        }
      });
    });
    this.fita4.pendingFocusToMainPanel$.pipe(observeOn(asyncScheduler)).subscribe((pendingFocusToMainPanel) => {
      this.pendingFocusToMainPanel = pendingFocusToMainPanel;
      this.activateMainPanel();
    });

    this.fita4.initialized$.subscribe(() => {
      this.fita4Initialized = true;
    });

    this.orderOperator.initialized.subscribe(() => {
      this.orderInitialized = true;
    });

    this.watchListIssueCodes$ = this.fita4.watchListIssueCodes$;
    this.marketError$ = this.fita4.marketError$;

    this.persistentState.doNotShowTedinetDialog$.subscribe((doNotShowTedinetDialog) => {
      this.doNotShowTedinetDialog = doNotShowTedinetDialog;
    });
  }

  private addComponent(config: ItemConfigType, targetId: string) {
    if (!this.layout) {
      return;
    }
    if (targetId && this.layout) {
      const l = this.layout.getGoldenLayoutInstance();
      let targetItem = l.root.getItemsById(targetId)[0];
      if (targetItem.parent.isStack) {
        targetItem = targetItem.parent;
        if (targetItem.parent.isColumn) {
          for (let i = 0; i < targetItem.parent.contentItems.length; i++) {
            if (targetItem.parent.contentItems[i] === targetItem) {
              targetItem.parent.addChild(config, i + 1);
              break;
            }
          }
        } else {
          const column = (l.createContentItem({
            type: 'column',
            width: targetItem.config.width,
            height: targetItem.config.height,
            content: [],
          }) as any) as ContentItem;
          column.addChild(config);
          //
          targetItem.parent.replaceChild(targetItem, column);
          column.addChild(targetItem, 0);
        }
      }
    } else {
      this.layout.getGoldenLayoutInstance().root.contentItems[0].addChild(config);
    }
  }
  openStockList(item: string) {
    if (!this.layout) {
      return;
    }
    const components = this.layout.getComponents();
    let targetSummaryList = null;
    for (const key of Object.keys(components)) {
      const component = components[key];
      if ((component.config as ComponentConfig).componentName === 'StockList') {
        if (targetSummaryList === null || (component.config as ComponentConfig).componentState?.active) {
          targetSummaryList = component.id;
        }
      }
    }

    const config = {
      type: 'component',
      componentName: 'StockList', // The name defined in componentTypes
      title: '銘柄リスト',
      componentState: {
        active: true,
        summaryId: item,
      },
    };
    this.addComponent(config, targetSummaryList);
  }

  get initialized(): boolean {
    return this.cocomero.initialized;
  }

  onStateChanged() {
    if (!this.initialStateChange) {
      this.initialStateChange = true;
      // if (this.useDefaultLayout) {
      // if (!this.orderOperator.config.orderEnabled) {
      this.layout.closeComponent('trade');
      // }
      // }
      if (this.orderOperator.config.orderEnabled) {
        if (!this.layout.getGoldenLayoutInstance().root.getItemsById('trade')) {
          this.layout.createNewComponent({
            type: 'component',
            componentName: 'Trade', // The name defined in componentTypes
            title: '取引',
            id: 'trade',
            componentState: {},
            isClosable: false,
          });
        }
      }
      if (this.pendingFocusToMainPanel) {
        this.activateMainPanel();
      }
    }
    if (!this.cocomero.error) {
      if (this.orderOperator.config.orderEnabled) {
        this.persistentState.updateFullItaWindowLayoutWithOrder(this.layout.getGoldenLayoutInstance().toConfig());
      } else {
        this.persistentState.updateFullItaWindowLayoutViewOnly(this.layout.getGoldenLayoutInstance().toConfig());
      }
    }
  }

  newStockDetail(issueCode?: number) {
    if (!this.layout) {
      return;
    }
    const components = this.layout.getComponents();
    let targetStockDetail = null;
    for (const key of Object.keys(components)) {
      const component = components[key];
      if ((component.config as ComponentConfig).componentName === 'StockDetail') {
        if (targetStockDetail === null || (component.config as ComponentConfig).componentState?.syncWithCocomero) {
          targetStockDetail = component.id;
        }
      }
    }

    const config = {
      type: 'component',
      componentName: 'StockDetail', // The name defined in componentTypes
      title: '銘柄詳細',
      componentState: {
        active: true,
        issueCode: issueCode || fita3Env.flexConfig.fallbackIssueCode,
      },
    };
    this.layout.createNewComponent(config, targetStockDetail);
    this.resize.dispatchResize();
  }

  onStockSelected(issueCode: number) {
    this.stockDetailsService.changeIssueCodeRequest(issueCode);
  }

  resetLayout() {
    this.dialog
      .openDialog(
        {
          content: 'レイアウトを初期化しますか。',
          buttons: [
            {
              text: 'はい',
              cssClass: 'c-btn c-btn--danger',
              result: 'yes',
            },
            {
              text: 'いいえ',
              cssClass: 'c-btn c-btn--default',
              result: 'no',
              initialFocus: true,
            },
          ],
        },
        {
          width: '30em',
        }
      )
      .subscribe((result) => {
        if (result.type === 'button' && result.value === 'yes') {
          const layout = INITIAL_LAYOUT;
          layout.dimensions = {
            ...layout.dimensions,
            ...commonDimensionParameters,
            headerHeight: this._fontSize.glHeaderSize(),
          };
          this.layoutConfig$.next(layout);
        }
      });
  }

  @HostListener('document:contextmenu', ['$event'])
  ignoreOverlayBackdropContextMenu(event: MouseEvent) {
    if (event.target && event.target['classList']) {
      if (event.target['classList'].contains('cdk-overlay-backdrop')) {
        event.preventDefault();
      }
    }
  }

  activateMainPanel() {
    try {
      const main = this.layout.getGoldenLayoutInstance().root.getItemsById('stockDetailMain')[0];
      main.parent.setActiveContentItem(main);
      this.fita4.updatePendingFocusToMainPanel(false);
    } catch {}
  }

  ngOnInit(): void {}

  openHelp(help: HelpEntry) {
    if ('link' in help) {
      window.open(help.link, '_blank', 'noopener');
    } else {
      if (help.onClick === 'itaOrderTutorial') {
        this.orderUi.tryShowItaOrderTutorial(true);
      }
    }
  }

  openEShitenPage() {
    window.open('https://www.e-shiten.jp/', '_blank', 'noopener');
    gtag('event', 'cv_click', {
      click_target: 'open_account_button',
      event_label: 'https://www.e-shiten.jp/',
      button_place: 'secondary-window-header',
      value: 1,
    });
  }

  tryOpenTedinet() {
    if (!this.tedinetUrl) {
      return;
    }
    if (this.doNotShowTedinetDialog) {
      this.openTedinet();
    } else {
      this.dialog
        .openDialog(
          {
            content: ['アルゲンタム・コード運営の外部サイト「BRiSK適時開示」を別ウィンドウで開きます。'],
            closeOnEscape: true,
            checkboxText: '今後、このメッセージを表示しない ',
            showCloseButton: true,
            buttons: [
              {
                text: 'OK',
                initialFocus: true,
                result: 'open',
              },
              {
                text: 'キャンセル',
                result: 'cancelled',
              },
            ],
          },
          {
            width: '48em',
          }
        )
        .subscribe((result) => {
          if (result.type === 'button' && result.value === 'open') {
            if (result.checked) {
              this.persistentState.updateDoNotShowTedinetDialog(true);
            }
            this.openTedinet();
          }
        });
    }
  }

  openTedinet() {
    window.open(this.tedinetUrl, '_blank');
  }
}
