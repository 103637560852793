import { Tick } from './tick';

const industryCodes: { [key: string]: string } = {
  '50': '水産農林',
  '1050': '鉱業',
  '2050': '建設',
  '3050': '食料品',
  '3100': '繊維',
  '3150': 'パルプ紙',
  '3200': '化学',
  '3250': '医薬品',
  '3300': '石油石炭',
  '3350': 'ゴム',
  '3400': 'ガラス土石',
  '3450': '鉄鋼',
  '3500': '非鉄金属',
  '3550': '金属',
  '3600': '機械',
  '3650': '電気機器',
  '3700': '輸送用機器',
  '3750': '精密機器',
  '3800': 'その他製品',
  '4050': '電気ガス',
  '5050': '陸運',
  '5100': '海運',
  '5150': '空運',
  '5200': '倉庫運輸',
  '5250': '情報通信',
  '6050': '卸売',
  '6100': '小売',
  '7050': '銀行',
  '7100': '証券等',
  '7150': '保険',
  '7200': 'その他金融',
  '8050': '不動産',
  '9050': 'サービス',
};

export class StockMaster {
  id: number;
  issueCode: number;
  tick: Tick;
  tickType: number;
  basePrice10: number;
  limitUp10: number;
  limitDown10: number;
  flexName: string;
  lotSize: number;
  issueType: number;
  industryCode: number;
  loanMarginFlag: number;
  indexFlag: number; // 24bit number

  name: string;
  prefix: string;
  showPrefix: boolean;

  maxItaRowCount: number;

  isNewListed: boolean;
  seiriKanriFlag: number;
  exRightFlag: number;

  get industryName() {
    if (this.industryCode === 9999) {
      if (this.issueType === 115 || this.issueType === 116) {
        return 'ETF';
      } else if (this.issueType === 119) {
        return 'REIT';
      }
    }
    return industryCodes[this.industryCode];
  }

  get containsDecimal(): boolean {
    if (this.tick.indexToPrice10(this.tick.price10ToIndex(this.limitDown10) + 1) - this.limitDown10 < 10) {
      return true;
    }
    return false;
  }
}
