function shift(number: number, precision: number, reverseShift: boolean) {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = ('' + number).split('e');
  return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
}

// From: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/round
function round(number: number, precision: number) {
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

export const enum QRRowType {
  None = 0,
  Ask = 1,
  Bid = 2,
  Lazy = 3,
}

export class QRRow {
  type: QRRowType;
  timestamp: number;
  price10: number;
  quantity: number;
  frameNumber: number;

  get turnover(): number {
    if (!this.price10) {
      return undefined;
    }
    return round((this.price10 * this.quantity) / 10 / 1e6, 1);
  }

  get price(): number {
    if (!this.price10) {
      return undefined;
    } else {
      return this.price10 / 10;
    }
  }
}
