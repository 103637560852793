import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSourceAbstract } from '../chart-data-source-abstract';
import { ChartDrawInfo } from '../chart-draw-info';
import { Subscription } from 'rxjs';
import { FlexChart, Series } from '@grapecity/wijmo.chart';
import { ObservableArray } from '@grapecity/wijmo';
import { StockView } from '../../flex';
import { ChartDataSourceStock } from '../chart-data-source-stock';
import { StockWrapper } from '../../brisk-core/stock-wrapper';
import { ChartSource } from '../chart-source';
import { WindowRefService } from '../../brisk-browser/window-ref.service';
import { ChartTypeInfo } from '../chart-info';
import { ThemeService } from '../../theme/theme.service';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

type StyleValue =
  | {
      type: 'theme';
      key: string;
    }
  | {
      type: 'value';
      value: string;
    };

function getFromTheme(key: string): StyleValue {
  return {
    type: 'theme',
    key,
  };
}

function styleValue(value: string): StyleValue {
  return {
    type: 'value',
    value,
  };
}

const themes: { [key: string]: { [key: string]: StyleValue } } = {
  'c-chart__candle-stick--positive': {
    fill: getFromTheme('chart-positive-fill'),
    stroke: getFromTheme('chart-positive-stroke'),
  },
  'c-chart__candle-stick--negative': {
    fill: getFromTheme('chart-negative-fill'),
    stroke: getFromTheme('chart-negative-stroke'),
  },
  'c-chart__quantity': {
    fill: getFromTheme('chart-quantity-fill'),
    stroke: getFromTheme('chart-quantity-stroke'),
  },
  'c-chart__quantity--open': {
    fill: getFromTheme('chart-open-close-quantity-fill'),
    stroke: getFromTheme('chart-open-close-quantity-stroke'),
  },
  'c-chart__quantity--close': {
    fill: getFromTheme('chart-open-close-quantity-fill'),
    stroke: getFromTheme('chart-open-close-quantity-stroke'),
  },
  'c-chart__line-chart': {
    fill: getFromTheme('line-chart-fill'),
    stroke: getFromTheme('line-chart-stroke'),
    strokeWidth: styleValue('1.5'),
  },
  'c-chart__line-chart--industry': {
    fill: getFromTheme('line-chart-industry-fill'),
    stroke: getFromTheme('line-chart-industry-stroke'),
    strokeWidth: styleValue('1.5'),
  },
};

@Component({
  selector: 'brisk-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  type_ = '5min';

  private chartUpdateSubscription: Subscription = null;
  private themeSubscription: Subscription = null;
  private document: Document;

  openQuantityStyle: { [key: string]: string } = {};
  closeQuantityStyle: { [key: string]: string } = {};
  quantityStyle: { [key: string]: string } = {};
  positiveStyle: { [key: string]: string } = {};
  negativeStyle: { [key: string]: string } = {};
  lineChartStyle: { [key: string]: string } = {};

  // 表示用の情報
  drawInfo: ChartDrawInfo;
  chartTypeInfo: ChartTypeInfo;

  symbolSize: number;
  pointSymbolSize: number;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('qrChart') qrChart: FlexChart;
  @ViewChild('qrQuantityChart') qrQuantityChart: FlexChart;
  @ViewChild('qrQuantitySeries') qrQuantitySeries: Series;

  @Input() normalized = true;
  @Output() normalizedChange = new EventEmitter();

  @Input() showLineChart = true;

  @Input()
  set stockWrapper(stockWrapper: StockWrapper) {
    this.dataSource = new ChartDataSourceStock(new ChartSource(stockWrapper, null, 1));
  }

  get stockWrapper(): StockWrapper {
    return this.dataSource && this.dataSource.stockWrapper;
  }

  public get view(): StockView {
    return this.dataSource && this.dataSource.stockWrapper ? this.dataSource.stockWrapper.base : null;
  }

  @Input()
  set type(type: string) {
    if (this.type_ === type) {
      return;
    }
    this.type_ = type;
    if (!this.stockWrapper) {
      return;
    }
    if (this.qrChart) {
      (<any>this.qrChart).beginUpdate();
    }
    this.selectChart();
    if (this.qrChart) {
      (<any>this.qrChart).endUpdate();
    }
    this.setSymbolSize();
    this.changeDetectorRef.detectChanges();
  }

  get type(): string {
    return this.type_;
  }

  private chartDataSource_: ChartDataSourceAbstract;

  @Input()
  set dataSource(chartDataSource: ChartDataSourceAbstract) {
    this.chartDataSource_ = chartDataSource;
    if (this.chartUpdateSubscription !== null) {
      this.chartUpdateSubscription.unsubscribe();
    }
    if (this.chartDataSource_) {
      this.chartUpdateSubscription = this.chartDataSource_.callbacks.subscribe(this.updated.bind(this));
      this.selectChart();
    }
  }

  get dataSource() {
    return this.chartDataSource_;
  }

  @Input()
  lineChartType: string;

  @Input()
  nonProportional = false; // 文字サイズを画面横幅に依存させないモード

  @Output()
  timeClick = new EventEmitter<number>();

  @ViewChild('themeElement', { static: true })
  themeElement: ElementRef;

  qrChartData: ObservableArray;
  lineChartSegments: Array<ObservableArray>;
  lineChartPoint: ObservableArray;
  dummyArray: Array<{}>; // チャートの描画順序制御に使うダミー配列

  plotMarginOHLC = '9 20 18 60';
  plotMarginQuantity = '9 20 18 60';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private window: WindowRefService,
    private theme: ThemeService,
    private platform: Platform,
    @Inject(DOCUMENT) document: any
  ) {
    this.themeSubscription = this.theme.theme$.subscribe(() => {
      this.updateTheme();
    });
    this.setSymbolSize();
    this.document = document;
  }

  selectChart() {
    if (!this.chartDataSource_) {
      return;
    }
    switch (this.type) {
      case '5min':
        this.qrChartData = this.chartDataSource_.ohlc5min;
        this.dummyArray = [{}];
        this.lineChartSegments = this.chartDataSource_.line5minSegments;
        this.lineChartPoint = this.chartDataSource_.line5minPoint;
        this.chartTypeInfo = this.chartDataSource_.chartInfo.info5min;
        break;

      case '1day':
        this.qrChartData = this.chartDataSource_.ohlc1day;
        this.dummyArray = [{}];
        this.lineChartSegments = this.chartDataSource_.line1daySegments;
        this.lineChartPoint = this.chartDataSource_.line1dayPoint;
        this.chartTypeInfo = this.chartDataSource_.chartInfo.info1day;
        break;

      case '1week':
        this.qrChartData = this.chartDataSource_.ohlc1week;
        this.dummyArray = [{}];
        this.lineChartSegments = this.chartDataSource_.line1weekSegments;
        this.lineChartPoint = this.chartDataSource_.line1weekPoint;
        this.chartTypeInfo = this.chartDataSource_.chartInfo.info1week;
        break;

      case '1month':
        this.qrChartData = this.chartDataSource_.ohlc1month;
        this.dummyArray = [{}];
        this.lineChartSegments = this.chartDataSource_.line1monthSegments;
        this.lineChartPoint = this.chartDataSource_.line1monthPoint;
        this.chartTypeInfo = this.chartDataSource_.chartInfo.info1month;
        break;
    }
    this.drawInfo = this.chartDataSource_.drawInfo[this.type];
    this.updated();
  }

  ngOnInit() {
    this.updateTheme();
    this.selectChart();
    this.setSymbolSize();
  }

  ngOnDestroy(): void {
    if (this.chartUpdateSubscription !== null) {
      this.chartUpdateSubscription.unsubscribe();
    }
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  updated() {
    this.changeDetectorRef.markForCheck();
  }

  setSymbolSize() {
    if (this.wrapper && this.drawInfo) {
      const d: HTMLDivElement = this.wrapper.nativeElement;
      const fontSize = parseInt(this.window.nativeWindow.getComputedStyle(this.document.documentElement).fontSize, 10) || 16;
      this.symbolSize = Math.max(3, Math.trunc((d.offsetWidth - 3.6 * fontSize) / (this.drawInfo.axisXMax - this.drawInfo.axisXMin)) - 2);
      this.plotMarginOHLC = [0.75 * fontSize, 0.83 * fontSize, 0.75 * fontSize, 4.14 * fontSize].join(' ');
      this.plotMarginQuantity = [0.75 * fontSize, 0.83 * fontSize, 1.3 * fontSize + 6, 4.14 * fontSize].join(' ');
    } else {
      this.symbolSize = 5;
      this.plotMarginOHLC = this.plotMarginQuantity = '9 20 20 50';
    }
    this.pointSymbolSize = Math.min(8, this.symbolSize);
  }

  public onRendered() {
    this.changeDetectorRef.markForCheck();
  }

  refresh() {
    if (!this.qrChart) {
      return;
    }
    this.setSymbolSize();
    this.changeDetectorRef.markForCheck();
    this.qrChart.refresh(true);
    this.qrQuantityChart.refresh(true);
    this.qrChart.refresh(true);
  }

  priceChangeFormatter(engine, label): any {
    const number = Math.round(label.val * 100);
    label.text = number + '%';
    return label;
  }

  price10Formatter(engine, label): any {
    label.text = (label.val / 10).toLocaleString('ja-JP', { maximumFractionDigits: 1 });
    return label;
  }

  onMouseDown(e: MouseEvent) {
    if (e.button === 1) {
      this.normalized = !this.normalized;
      this.normalizedChange.emit(this.normalized);
    }
  }

  updateTheme() {
    this.quantityStyle = this.getStyleByClassName('c-chart__quantity');
    this.openQuantityStyle = this.getStyleByClassName('c-chart__quantity--open');
    this.closeQuantityStyle = this.getStyleByClassName('c-chart__quantity--close');
    this.positiveStyle = this.getStyleByClassName('c-chart__candle-stick--positive');
    this.negativeStyle = this.getStyleByClassName('c-chart__candle-stick--negative');
    if (this.lineChartType === 'Industry') {
      this.lineChartStyle = this.getStyleByClassName('c-chart__line-chart--industry', ['fill', 'stroke', 'strokeWidth']);
    } else {
      this.lineChartStyle = this.getStyleByClassName('c-chart__line-chart', ['fill', 'stroke', 'strokeWidth']);
    }
    this.changeDetectorRef.markForCheck();
  }

  getStyleByClassName(className: string, keys: Array<string> = ['fill', 'stroke']): { [key: string]: string } {
    const style = {};
    if (this.platform.FIREFOX) {
      const themeValues = themes[className];
      for (const key of Object.keys(themeValues)) {
        const value = themeValues[key];
        if (value.type === 'theme') {
          style[key] = this.theme.getStyleValue(value.key);
        } else {
          style[key] = value.value;
        }
      }
      return style;
    }

    const elem: HTMLSpanElement = this.themeElement.nativeElement;
    elem.className = className;
    const computedStyle = getComputedStyle(elem);
    for (const key of keys) {
      style[key] = computedStyle[key];
    }
    elem.className = 'd-none';
    return style;
  }
}
