import { AbstractType, FactoryProvider, InjectionToken, Type } from '@angular/core';
import { ParentWindowNotFoundError } from './error';
import { isObservable, Observable } from 'rxjs';

export function proxyObservable<T>(obs: Observable<T>): Observable<T> {
  return new Observable<T>((sub) => {
    const subj = obs.subscribe(sub);
    return () => {
      return subj.unsubscribe();
    };
  });
}

export const PARENT_WINDOW_GETTER = new InjectionToken<() => Window>('Parent Window', {
  factory: () => () => window.opener,
});

export function fromParentWindow<T>(
  token: Type<T> | InjectionToken<T> | AbstractType<T>,
  parentName: string,
  { convertObservable }: { convertObservable: boolean } = { convertObservable: false }
): FactoryProvider {
  return {
    provide: token,
    useFactory: (parent: () => Window) => {
      const p = parent();
      if (!p || !p.inject) {
        throw new ParentWindowNotFoundError();
      }
      const ret = p.inject(parentName);
      if (ret && typeof ret === 'object') {
        // prevent to destroy parent service from a child window
        const handler = {
          get: function (target: object | null, proc: string | number | symbol, _: any) {
            if (proc === 'ngOnDestroy') {
              return () => {};
            }
            const value = (Reflect.get as any)(...arguments);
            if (convertObservable && typeof proc === 'string' && proc[proc.length - 1] === '$' && isObservable(value)) {
              return proxyObservable(value);
            }
            return value;
          },
        };
        return new Proxy(ret, handler);
      } else {
        return ret;
      }
    },
    deps: [PARENT_WINDOW_GETTER],
  };
}
