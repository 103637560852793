import { Injectable, InjectionToken, Inject, OnDestroy } from '@angular/core';
import { LocalStorageService, ResizeService } from '@argentumcode/brisk-common';
import { CocomeroService } from 'fita3/src/app/core/cocomero.service';
import { asapScheduler, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PersistentStateService } from './fita4-core/persistent-state.service';
import { observeOn } from 'rxjs/operators';

export const FontSizeConfig = new InjectionToken<'mainWindow' | 'fullItaWindow'>('FontSizeConfig');

@Injectable({
  providedIn: 'root',
})
export class FontSizeService implements OnDestroy {
  private fontPixel = 16;

  private _fontSizeBehavior = new BehaviorSubject<string>('s');
  fontSize$: Observable<string> = this._fontSizeBehavior.asObservable();

  get fontSize(): string {
    return this._fontSize;
  }

  set fontSize(value: string) {
    this.updateFontSize(value);
  }

  private _fontSize: string = null;

  private _fontSizeLoaded = false;

  private _fontSizeLoadedSubject = new ReplaySubject<string>(1);
  fontSizeLoaded$: Observable<string> = this._fontSizeLoadedSubject.asObservable();

  constructor(
    private resize: ResizeService,
    private cocomero: CocomeroService,
    private persistentState: PersistentStateService<unknown>,
    @Inject(FontSizeConfig) private fontSizeKey: 'mainWindow' | 'fullItaWindow'
  ) {
    this.fontSize = 's';
    (this.fontSizeKey === 'mainWindow' ? this.persistentState.mainWindowFontSize$ : this.persistentState.fullItaWindowFontSize$)
      .pipe(observeOn(asapScheduler))
      .subscribe((item) => {
        if (['xs', 's', 'm', 'l', 'xl'].indexOf(item) !== -1) {
          this.fontSize = item;
        }
        this._fontSizeLoaded = true;
        this._fontSizeLoadedSubject.next(this.fontSize);
        this._fontSizeLoadedSubject.complete();
      });
  }

  private updateFontSize(fontSize: string) {
    if (this._fontSize === fontSize) {
      return;
    }
    if (fontSize && this._fontSizeLoaded) {
      if (this.fontSizeKey === 'mainWindow') {
        this.persistentState.updateMainWindowFontSize(fontSize);
      } else {
        this.persistentState.updateFullItaWindowFontSize(fontSize);
      }
    }
    const doc = window.document.body.parentElement;
    if (this._fontSize) {
      doc.classList.remove(`fita3-font-${this._fontSize}`);
    }
    if (fontSize) {
      doc.classList.add(`fita3-font-${fontSize}`);
    }
    this._fontSize = fontSize;
    setTimeout(() => {
      this.fontPixel = parseInt(window.getComputedStyle(doc).fontSize, 10) || 16;
      this.resize.dispatchResize();
    }, 0);
    this._fontSizeBehavior.next(this._fontSize);
  }

  glHeaderSize(): number {
    return (
      {
        xs: 21,
        s: 25,
        m: 29,
        l: 34,
        xl: 42,
      }[this._fontSize] || 20
    );
  }

  ngOnDestroy() {
    this._fontSizeLoadedSubject.complete();
  }
}
