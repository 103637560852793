<div>
  お使いのOSもしくはブラウザは推奨動作環境外です。発注機能をご利用頂くには以下のいずかの環境で起動してください。
  <br />
  <br />
  <ul>
    <li>Windows 10上のGoogle Chromeブラウザの最新版</li>
    <li>Windows 10上のMicrosoft Edgeブラウザの最新版</li>
    <li>最新バージョンのmacOS上のGoogle Chromeブラウザの最新版</li>
  </ul>
</div>
