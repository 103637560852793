import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StockDetailService } from '../../stock-detail.service';
import { ChartDataSourceStock, ChartSource, ResizeService } from '@argentumcode/brisk-common';
import { Observable, Subscription } from 'rxjs';
import { GlOnShow, GoldenLayoutContainer } from '@argentumcode/ngx-golden-layout';
import { GlOnResize } from '@argentumcode/ngx-golden-layout';
import { Container } from 'golden-layout';

interface Ohlc5MinState {
  normalized: boolean;
  showIndustryLine: boolean;
}

@Component({
  selector: 'app-fita4-ohlc5min-panel',
  templateUrl: './ohlc5min-panel.component.html',
  styleUrls: ['./ohlc5min-panel.component.scss'],
})
export class Ohlc5minPanelComponent implements OnInit, GlOnShow, GlOnResize, OnDestroy {
  state: Ohlc5MinState;
  dataSource: ChartDataSourceStock;
  showIndustryPriceCheckbox = false;
  industryName: string = undefined;
  subscription: Subscription = new Subscription();

  constructor(
    private stockDetail: StockDetailService,
    private resize: ResizeService,
    @Inject(GoldenLayoutContainer) private container: Container
  ) {
    this.subscription.add(
      this.stockDetail.detailToolBoxChartSource.subscribe((chartSource) => {
        this.dataSource = new ChartDataSourceStock(chartSource);
        if (chartSource.lineChartTodayData) {
          this.showIndustryPriceCheckbox = true;
          this.industryName = chartSource.lineChartName;
        } else {
          this.showIndustryPriceCheckbox = false;
          this.industryName = undefined;
        }
      })
    );
    const state: Partial<Ohlc5MinState> = this.container.getState() || {};
    let updateState = false;
    if (state.normalized === undefined) {
      state.normalized = true;
      updateState = true;
    }
    if (state.showIndustryLine === undefined) {
      state.showIndustryLine = true;
      updateState = true;
    }
    this.state = {
      normalized: state.normalized,
      showIndustryLine: state.showIndustryLine,
    };

    if (updateState) {
      this.container.setState(this.state);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateNormalized(normalized: boolean) {
    this.updateState({ ...this.state, normalized });
  }
  updateShowIndustryLine(showIndustryLine: boolean) {
    this.updateState({ ...this.state, showIndustryLine });
  }

  updateState(state: Ohlc5MinState) {
    this.state = state;
    this.container.setState(state);
  }
  glOnShow() {
    this.resize.dispatchResize();
  }

  glOnResize() {
    setTimeout(() => {
      this.resize.dispatchResize();
    });
  }
}
