import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsfcComponent } from './jsfc/jsfc.component';
import { IndividualDetailComponent } from './individual-detail/individual-detail.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';
import { VgGridModule } from '../vg-grid/vg-grid.module';
import { FastDecimalPipe } from '../brisk-common/fast-decimal.pipe';
import { TedinetComponent } from './tedinet/tedinet.component';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, BriskCommonModule, IconsModule, VgGridModule],
  declarations: [JsfcComponent, IndividualDetailComponent, TedinetComponent],
  exports: [JsfcComponent, IndividualDetailComponent, TedinetComponent],
  providers: [FastDecimalPipe],
})
export class IndividualDetailModule {
  constructor() {}
}
