/**
 * 足毎に異なるチャートの設定を行う
 */
import { QRChartItem, LineChartItem } from './chart-data';
import { addDays, addMonths, format, startOfISOWeek } from 'date-fns';

const SHORT_ASI_FACTOR = 1e6 * 10; // 100万円単位
const LONG_ASI_FACTOR = 1e8 * 10; // 1億円単位

export interface ChartDrawInfo {
  title: string;
  axisXMin: number;
  axisXMax: number;
  axisXMajorUnit: number;
  axisYMajorUnit: number;

  axisXItemFormatter(engine: any, label: any): any;

  quantityXItemFormatter(engine: any, label: any): any;

  qrTooltip(ht: any): any;

  quantityTooltip(ht: any): any;
}

export class ChartDrawInfoCommon {
  static ohlcString(item: QRChartItem | LineChartItem, label?: string) {
    if (!item) {
      return;
    }
    if (item instanceof QRChartItem) {
      return (
        `<table><tr><td>始値:</td><td>${(item.open * 100).toFixed(1)}%</td>
          <td>${(item.openPrice10 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 1 })}円</td></tr>` +
        `<tr><td>高値: </td><td>${(item.high * 100).toFixed(1)}%</td>
          <td>${(item.highPrice10 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 1 })}円</td></tr>` +
        `<tr><td>安値: </td><td>${(item.low * 100).toFixed(1)}%</td>
          <td>${(item.lowPrice10 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 1 })}円</td></tr>` +
        `<tr><td>終値: </td><td>${(item.close * 100).toFixed(1)}%</td>
          <td>${(item.closePrice10 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 1 })}円</td></tr></table>`
      );
    } else {
      // LineChartItem
      return `<table><tr><td>${label}: </td><td>${(item.rate * 100).toFixed(1)}%</td></tr></table>`;
    }
  }

  static quantityStringShortTerm(item: QRChartItem, label?: string) {
    let ret = `${label}<br>`;
    ret += (item.quantitySum / 10000 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '万円';
    if ((item.openQuantity || 0) > 0) {
      ret += '<br><br>寄付:<br>' + (item.openQuantity / 10000 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '万円';
    }
    if ((item.closeQuantity || 0) > 0) {
      ret += '<br><br>大引:<br>' + (item.closeQuantity / 10000 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '万円';
    }
    return ret;
  }

  static quantityStringLongTerm(item: QRChartItem, label?: string) {
    let ret = `${label}<br>`;
    ret += (item.quantitySum / 1e8 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '億円';
    if ((item.openQuantity || 0) > 0) {
      ret += '<br><br>寄付:<br>' + (item.openQuantity / 1e8 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '億円';
    }
    if ((item.closeQuantity || 0) > 0) {
      ret += '<br><br>大引:<br>' + (item.closeQuantity / 1e8 / 10).toLocaleString('ja-JP', { maximumFractionDigits: 0 }) + '億円';
    }
    return ret;
  }
}

export class ChartDrawInfo5min implements ChartDrawInfo {
  public static dayGapSize = 6;
  public static sessionGapSize = 2;
  public days = 3;

  title = '5分足';
  axisXMin = -0.5;
  axisXMax: number;
  axisXMajorUnit = 0.5;
  axisYMajorUnit = 0.01;
  dateMap: { [key: number]: Date } = {};

  /**
   * 日付からグラフのX座標に変換する
   * @param daysOffset 0なら当日 -1なら前日...
   * @param time (0 <= this.ohlcLength)
   */
  public timeToX(daysOffset: number, time: number) {
    const dayIndex = this.days - 1 + daysOffset;
    const dayStartX = dayIndex * (this.ohlcLength + ChartDrawInfo5min.sessionGapSize + ChartDrawInfo5min.dayGapSize);
    return dayStartX + time + (time >= 30 ? ChartDrawInfo5min.sessionGapSize : 0);
  }

  constructor(private date: Date, days: number, private ohlcLength: number, private lineChartName?: string) {
    this.days = days;
    this.axisXMax =
      this.ohlcLength * this.days + ChartDrawInfo5min.dayGapSize * (this.days - 1) + ChartDrawInfo5min.sessionGapSize * this.days + 1;
  }

  public setX(day: number, date: Date) {
    this.dateMap[day] = date;
  }

  formatHHMM(h: number, m: number) {
    return ('00' + h).slice(-2) + ':' + ('00' + m).slice(-2);
  }

  getTime(number): string {
    if (number <= 30) {
      return this.formatHHMM(9 + Math.trunc(number / 12), (number % 12) * 5);
    } else if (number >= 30 + ChartDrawInfo5min.sessionGapSize && number <= this.ohlcLength + ChartDrawInfo5min.sessionGapSize) {
      return this.formatHHMM(
        10 + Math.trunc((number - ChartDrawInfo5min.sessionGapSize) / 12),
        ((number - ChartDrawInfo5min.sessionGapSize) % 12) * 5
      );
    } else if (number >= this.ohlcLength + ChartDrawInfo5min.sessionGapSize + ChartDrawInfo5min.dayGapSize) {
      return this.getTime(number - this.ohlcLength - ChartDrawInfo5min.sessionGapSize - ChartDrawInfo5min.dayGapSize);
    } else {
      return '';
    }
  }

  getDateAndTime(number): string {
    const dateDiff =
      Math.trunc(number / (this.ohlcLength + ChartDrawInfo5min.sessionGapSize + ChartDrawInfo5min.dayGapSize)) - this.days + 1;
    const today = dateDiff === 0 ? this.date : this.dateMap[dateDiff] || addDays(this.date, dateDiff);
    return (
      //`${format(today, 'YYYY-MM-DD')} ` +
      `xxxx-xx-xx ` + `${this.getTime(number % (this.ohlcLength + ChartDrawInfo5min.sessionGapSize + ChartDrawInfo5min.dayGapSize))}`
    );
  }

  axisXItemFormatter(engine, label) {
    // HH:MMの形式
    label.text = '';
    if (label.val % 1 !== 0) {
      label.text = this.getTime(label.val + 0.5);
      if (label.text !== '11:30' && label.text !== '12:30' && !label.text.endsWith('00')) {
        label.text = '';
      } else if (label.text === '11:30' || label.text === '12:30') {
        label.text = ' ';
      }
    }
    return label;
  }

  quantityXItemFormatter(engine, label) {
    label.text = label.val / SHORT_ASI_FACTOR;
    label.text = String(label.text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (label.text === '0') {
      label.text = '(/百万円)';
    }
    return label;
  }

  qrTooltip(ht) {
    return '<div class="qrTooltip">' + this.getDateAndTime(ht.x) + ChartDrawInfoCommon.ohlcString(ht.item, this.lineChartName) + '</div>';
  }

  quantityTooltip(ht) {
    const ret = `<div class="quantityTooltip">${ChartDrawInfoCommon.quantityStringShortTerm(ht.item, this.getDateAndTime(ht.x))}</div>`;
    return ret;
  }
}

export class ChartDrawInfo1day implements ChartDrawInfo {
  title = '日足';
  axisXMin = -180.5;
  axisXMax = 0.5;
  axisYMajorUnit = 0.05;
  axisXMajorUnit = 1;

  constructor(private date: Date, private lineChartName?: string) {}

  private getDate(x: number): Date {
    return addDays(this.date, x);
  }

  axisXItemFormatter(engine, label) {
    const date = this.getDate(label.val);
    if (date.getDate() === 1) {
      label.text = ' '; // format(date, 'YYYY/MM/DD');
    } else {
      label.text = '';
    }
    return label;
  }

  quantityXItemFormatter(engine, label) {
    label.text = label.val / LONG_ASI_FACTOR;
    label.text = String(label.text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (label.text === '0') {
      label.text = '(/億円)';
    }
    return label;
  }

  qrTooltip(ht) {
    return (
      '<div class="qrTooltip">' +
      // format(this.getDate(ht.x), 'YYYY/MM/DD') +
      'xxxx/xx/xx' +
      ChartDrawInfoCommon.ohlcString(ht.item, this.lineChartName) +
      '</div>'
    );
  }

  quantityTooltip(ht) {
    return `<div class="quantityTooltip">
      ${ChartDrawInfoCommon.quantityStringLongTerm(
        ht.item,
        'xxxx/xx/xx'
        // format(this.getDate(ht.x), 'YYYY/MM/DD')
      )}</div>`;
  }
}

export class ChartDrawInfo1week implements ChartDrawInfo {
  axisYMajorUnit = 0.1;
  title = '週足';
  axisXMin = -180.5;
  axisXMax = 0.5;
  axisXMajorUnit = 1;

  constructor(private date: Date, private lineChartName?: string) {
    this.date = startOfISOWeek(this.date);
  }

  private getDate(x: number) {
    return addDays(this.date, x * 7);
  }

  axisXItemFormatter(engine, label) {
    const cur = this.getDate(label.val);
    const next = addDays(cur, 8);
    if (cur.getMonth() !== next.getMonth()) {
      label.text = ' '; // format(next, 'YYYY-MM');
    } else {
      label.text = '';
    }
    return label;
  }

  quantityXItemFormatter(engine, label) {
    label.text = label.val / LONG_ASI_FACTOR;
    label.text = String(label.text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (label.text === '0') {
      label.text = '(/億円)';
    }
    return label;
  }

  qrTooltip(ht) {
    return (
      '<div class="qrTooltip">' + this.getDateRangeString(ht.x) + ChartDrawInfoCommon.ohlcString(ht.item, this.lineChartName) + '</div>'
    );
  }

  quantityTooltip(ht) {
    return `<div class="quantityTooltip">${ChartDrawInfoCommon.quantityStringLongTerm(ht.item, this.getDateRangeString(ht.x))}</div>`;
  }

  private getDateRangeString(x) {
    const date = this.getDate(x);
    const date2 = addDays(date, 5);
    return `xxxx-xx-xx-xxxx-xx-xx`;
    // return `${format(date, 'YYYY-MM-DD')}-${format(date2, 'YYYY-MM-DD')}`;
  }
}

export class ChartDrawInfo1month implements ChartDrawInfo {
  title = '月足';
  axisXMin = -108.5;
  axisXMax = 0.5;
  axisXMajorUnit = 1;
  axisYMajorUnit = 0.2;

  constructor(private date: Date, private lineChartName?: string) {}

  private getMonth(x: number): Date {
    return addMonths(this.date, x);
  }

  axisXItemFormatter(engint, label) {
    const date = this.getMonth(label.val);
    label.text = ' '; // format(date, 'YYYY-MM');
    return label;
  }

  quantityXItemFormatter(engine, label) {
    label.text = label.val / LONG_ASI_FACTOR;

    label.text = String(label.text).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (label.text === '0') {
      label.text = '(/億円)';
    }
    return label;
  }

  qrTooltip(ht) {
    return (
      '<div class="qrTooltip">' +
      // format(this.getMonth(ht.x), 'YYYY-MM') +
      'xxxx-xx' +
      ChartDrawInfoCommon.ohlcString(ht.item, this.lineChartName) +
      '</div>'
    );
  }

  quantityTooltip(ht) {
    return `<div class="quantityTooltip">
            ${ChartDrawInfoCommon.quantityStringLongTerm(
              ht.item,
              // format(this.getMonth(ht.x), 'YYYY-MM')
              'xxxx-xx'
            )}</div>`;
  }
}
