import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GridFilterChoice, VgGridColumnChoiceFilter, VgGridColumnValueFilter } from '../vg-grid-filter';
import { fromEvent, merge, Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { default as natsort } from 'natsort';

@Component({
  selector: 'brisk-vg-grid-choice-filter',
  templateUrl: './vg-grid-choice-filter.component.html',
  styleUrls: ['./vg-grid-choice-filter.component.scss'],
})
export class VgGridChoiceFilterComponent implements OnInit, OnChanges, OnDestroy {
  // #407 IE/Edgeでは中間状態を利用しない
  patchForIEOrEdge = false;

  @Input()
  filter: VgGridColumnChoiceFilter;

  @Output()
  apply = new EventEmitter<VgGridColumnChoiceFilter>();

  @Output()
  cancel = new EventEmitter<{}>();

  choices: Array<{
    original: GridFilterChoice<any, any>;
    label: string;
    checked: boolean;
  }> = [];

  allChecked = true;
  indeterminate = true;

  private _inputSubscription: Subscription = null;

  constructor(private _changeDetectorRef: ChangeDetectorRef, platform: Platform) {
    this.patchForIEOrEdge = false;
    if (platform.TRIDENT || platform.EDGE) {
      this.patchForIEOrEdge = true;
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filter) {
      this.choices = this.filter.choices.map((a) => ({
        original: a,
        label: a.label,
        checked: this.filter.selectedChoices.has(a),
      }));
      if (this._allUnchecked()) {
        this._checkAll();
      }
      this._updateItemChecked();
    }
  }

  ngOnDestroy(): void {
    if (this._inputSubscription) {
      this._inputSubscription.unsubscribe();
      this._inputSubscription = null;
    }
  }

  applyFilter() {
    const filter = new VgGridColumnChoiceFilter();
    if (this.filter) {
      filter.binding = this.filter.binding;
      filter.valueGetter = this.filter.valueGetter;
      filter.choices = this.filter.choices;
    }
    if (this._allChecked() || this._allUnchecked()) {
      this.apply.emit(null);
      return;
    }
    for (const value of this.choices) {
      if (value.checked) {
        filter.selectedChoices.add(value.original);
      }
    }
    this.apply.emit(filter);
  }

  clearFilter() {
    this.apply.emit(null);
  }

  _updateItemChecked() {
    if (this._allUnchecked()) {
      this.allChecked = false;
      this.indeterminate = false;
    } else if (this._allChecked()) {
      this.allChecked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  _onCheckChanged(c: HTMLInputElement) {
    this._checkAll(c.checked);
    this._updateItemChecked();
  }

  private _allChecked(): boolean {
    return this.choices.every((a) => a.checked);
  }

  private _allUnchecked(): boolean {
    return this.choices.every((a) => !a.checked);
  }

  private _checkAll(checked = true) {
    this.choices = this.choices.map((a) => {
      a.checked = checked;
      return a;
    });
  }
}
