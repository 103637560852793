/**
 * 表示用にWASM側から取得している銘柄情報を管理
 */
import { StockMaster } from './stock-master';
import { ItaRow } from './ita-row';
import { QRRow } from './qr-row';
import { StockInfo } from './stock-info';
import { differenceInMilliseconds } from 'date-fns';

export enum Side {
  Bid = 0,
  Ask = 1,
}

export class OHLC {
  openPrice10: number;
  highPrice10: number;
  lowPrice10: number;
  closePrice10: number;
  turnover10;
}

export enum QuoteFlag {
  Normal = 0,
  Buchinuki = 1,
  SQ = 2,
  CEQ = 3,
}

export const enum FlexVersion {
  Version15 = 15000,
  Version16 = 16000,
}

export class PredictLastPrice {
  lastPrice10: number;
  predict = false;
  turnover10: number;
  volume: number;
  quoteFlag: QuoteFlag;
  quoteSide: Side;
}

export class StockView {
  itaRowCount = null;
  itaRowPriceIndexUpdated = false;
  private _itaRowPriceIndex = null;
  get itaRowPriceIndex(): number {
    return this._itaRowPriceIndex;
  }

  set itaRowPriceIndex(index) {
    if (this._itaRowPriceIndex !== index) {
      this._itaRowPriceIndex = index;
      this.itaRowPriceIndexUpdated = true;
    }
  }

  rows: Array<ItaRow> = [];
  over: ItaRow = new ItaRow();
  under: ItaRow = new ItaRow();
  market: ItaRow = new ItaRow();
  qr: Array<QRRow> = [];

  ohlc: OHLC[];
  lastPrice10: number;
  openPrice10: number;
  volume: number;
  turnover10: number;
  timestamp: number;
  frame: number;
  maxFrame: number;

  askPrice10: number;
  bidPrice10: number;

  // ストップ高安を考慮しない時の寄付値段予想(ストップ高安で寄らないならその値段)
  predictOpenPrice10: number;

  // ストップ高安を考慮した時の寄付値段予想(ストップ高安で寄らないなら0)
  predictPrice10: number;
  predictVolume: number;
  predictTurnover10: number;
  predictSide: Side;
  predictClosePrice10: number;
  predictCloseVolume: number;
  predictCloseTurnover10: number;
  predictCloseSide: Side;
  specialQuoteTime: number;

  quoteFlag: QuoteFlag;
  quoteSide: Side;

  predictLastPrice = new PredictLastPrice();

  renewalUpRange10: number;
  renewalDownRange10: number;

  auctionPrice10: number;

  hasNextFrame: boolean;
  hasPreviousFrame: boolean;

  /* 計算で求める */
  highPrice10: number = null;
  lowPrice10: number = null;
  vwap10: number = null;
  specialQuoteDateTime: Date;
  specialQuoteTimeCache: number = null;

  itaCenterPrice10: number = null;
  itaCenterPrice10Raw: number = null;

  get itaRowCountUpdated(): boolean {
    return this.itaRowCount !== this.rows.length;
  }

  constructor(
    public readonly id: number,
    public master: StockMaster,
    public info: StockInfo,
    public readonly version: FlexVersion,
    public readonly ohlcLength: number
  ) {
    for (let i = 0; i < (this.itaRowCount || 0); i++) {
      this.rows.push(new ItaRow());
    }
    this.ohlc = new Array<OHLC>(this.ohlcLength).fill(null).map(() => new OHLC());
  }

  get vwapPriceChange(): number {
    if (this.predictLastPrice.lastPrice10 === 0 || this.turnover10 === 0) {
      return null;
    }
    return this.predictLastPrice.lastPrice10 / (this.turnover10 / this.volume) - 1;
  }

  get openPriceChange(): number {
    if (this.predictLastPrice.lastPrice10 === 0 || this.openPrice10 === 0) {
      return null;
    }
    return this.predictLastPrice.lastPrice10 / this.openPrice10 - 1;
  }

  get lastPriceBasePriceChange(): number {
    if (this.predictLastPrice.lastPrice10 === 0) {
      return null;
    }
    return this.predictLastPrice.lastPrice10 / this.master.basePrice10 - 1;
  }

  get predict(): boolean {
    return this.predictLastPrice.predict;
  }

  get predictOpenPriceBasePriceChange(): number {
    if (this.predictOpenPrice10 === 0) {
      return null;
    }
    return this.predictOpenPrice10 / this.master.basePrice10 - 1;
  }

  getQuoteRemain(time: Date): string {
    if (!this.specialQuoteTime || !time) {
      return '';
    }
    const sec = Math.ceil(differenceInMilliseconds(this.specialQuoteDateTime, time) / 1000);
    if (sec < 10 && sec >= 0) {
      return sec.toString();
    } else {
      return '';
    }
  }

  get turnoverPredict(): boolean {
    return this.openPrice10 === 0 && !!this.predictPrice10;
  }
}
