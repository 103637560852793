import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { BriskDialogService, SimpleDialogResult, VgCollectionView, VgGridColumnsState, VgGridComponent } from '@argentumcode/brisk-common';
import { Subscription } from 'rxjs';
import { ContextMenu, ContextMenuItem, ContextMenuService } from '@argentumcode/ngx-contextmenu';
import { ComponentPortal } from '@angular/cdk/portal';
import { GridColumnEditorDialogComponent } from './grid-column-editor-dialog/grid-column-editor-dialog.component';

@Directive({
  selector: '[appFita4GridColumnsEditor]',
  exportAs: 'appFita4GridColumnsEditor',
})
export class GridColumnsEditorDirective implements OnChanges, OnDestroy, OnInit {
  @Output()
  vgGridColumnsStateChange = new EventEmitter<VgGridColumnsState>();

  @Input()
  attachContextMenu = true;

  private contextMenuSubscription: Subscription = null;

  constructor(@Optional() private grid: VgGridComponent, private contextMenu: ContextMenuService, private dialog: BriskDialogService) {}

  updateGridState(grid: VgGridComponent) {
    this.dialog
      .openDialog({
        content: new ComponentPortal(GridColumnEditorDialogComponent),
        title: '表示カラムの設定',
        wrapContent: false,
        data: grid,
      })
      .subscribe((state?: SimpleDialogResult<{ nextState?: VgGridColumnsState }>) => {
        if (state && state.type === 'button' && 'nextState' in state.value) {
          if (grid.itemsSource instanceof VgCollectionView) {
            grid.itemsSource.filterDescriptions.splice(0, grid.itemsSource.filterDescriptions.length);
            grid.itemsSource.sortDescriptions.splice(0, grid.itemsSource.sortDescriptions.length);
          }
          this.vgGridColumnsStateChange.emit(state.value.nextState);
          if (grid.itemsSource instanceof VgCollectionView) {
            grid.itemsSource.update();
          }
        }
      });
  }

  ngOnInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(): void {
    if (this.attachContextMenu) {
      if (!this.contextMenuSubscription) {
        this.contextMenuSubscription = this.grid.cellHeaderContextMenu.subscribe((event) => {
          this.showContextMenu(event.event, this.grid);
        });
      }
    } else {
      if (this.contextMenuSubscription) {
        this.contextMenuSubscription.unsubscribe();
        this.contextMenuSubscription = null;
      }
    }
  }

  showContextMenu(event: MouseEvent | KeyboardEvent, grid: VgGridComponent) {
    this.contextMenu.show.next({
      event: event,
      item: grid,
      contextMenu: new ContextMenu({
        menuClass: '',
        items: [
          new ContextMenuItem({
            text: '表示項目の設定',
            executeFunction: () => {
              this.updateGridState(grid);
            },
          }),
        ],
      }),
    });
  }

  ngOnDestroy(): void {
    if (this.contextMenuSubscription) {
      this.contextMenuSubscription.unsubscribe();
      this.contextMenuSubscription = null;
    }
  }
}
