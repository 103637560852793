import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { VgGridColumn, VgGridColumnWidth, WidthUnit } from '../vg-grid-column';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { GroupHeader } from '../collections/group';
import { VgGridCellTemplateDirective } from '../vg-grid-cell-template.directive';
import { SortDescription, SortDirection, VgCollectionView, VgStreamView } from '../collections/data-collecton';
import {
  FilterType,
  VgGridColumnChoiceFilter,
  VgGridColumnConditionFilter,
  VgGridColumnFilter,
  VgGridColumnValueFilter,
} from '../vg-grid-filter';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Platform } from '@angular/cdk/platform';
import { FocusOutEvent } from '../vg-grid-filter/vg-grid-filter.component';
import { DragCancelEvent, DragEndEvent, DragMoveEvent, DragSource, DragStartEvent } from '../../drag-drop/types';
import { DragDropManagerService } from '../../drag-drop/drag-drop-manager.service';
import { normalizeColumnStateConfig, VgGridColumnsState } from '../vg-grid-column-state';

export const VG_GRID_COLUMN_GETTER = new InjectionToken<VgGridColumnGetter<unknown>>('VG_GRID_COLUMN_GETTER');

export interface VgGridColumnGetter<T> {
  column: VgGridColumn<T>;
  change: EventEmitter<void>;
}

enum CtrlMultipleSelection {
  None,
  Add,
  Remove,
}

export enum SelectionMode {
  None,
  SingleRow,
  MultipleRow,
}

function selectionModeFromString(s: string): SelectionMode {
  switch (s) {
    case 'None':
      return SelectionMode.None;
    case 'SingleRow':
      return SelectionMode.SingleRow;
    case 'MultipleRow':
      return SelectionMode.MultipleRow;
  }
  throw new Error(`Invalid Selection Mode: ${s}`);
}

export class CellEditingEvent<T = any> {
  constructor(
    public readonly item: any,
    public readonly column: VgGridColumn<T>,
    public cancel = false,
    public editingContext: EditingContext = null
  ) {}
}

export class RowDeletingEvent {
  constructor(public readonly itemSet: Array<any>) {}
}

export class EditingContext {
  value: any;
  readonly originalValue: any;

  constructor(value: any, originalValue: any, public readonly cleaned: boolean) {
    this.value = value;
    this.originalValue = originalValue;
  }
}

export class CellClickEvent<T = any> {
  constructor(
    public readonly item: any,
    public readonly column: VgGridColumn<T>,
    public readonly row: number,
    public readonly event: MouseEvent
  ) {}
}

export class RowDraggedEvent {
  constructor(public readonly draggedIndex: number, public readonly index: number) {}
}

export class RowDragStartEvent {
  public cancel = false;

  constructor(public readonly draggedIndex: number, public readonly item: any) {}
}

export class CellHeaderClickEvent<T = any> {
  public cancel = false;

  constructor(public readonly column: VgGridColumn<T>, public readonly event: MouseEvent) {}
}

export class ColumnEvent<T = any> {
  constructor(public readonly column: VgGridColumn<T>) {}
}

export class ColumnFilteredEvent {}

@Component({
  selector: 'brisk-vg-grid',
  templateUrl: './vg-grid.component.html',
  styleUrls: ['./vg-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VgGridComponent<T = any> implements OnInit, AfterContentInit, OnChanges, AfterViewChecked, OnDestroy, DragSource {
  @Input()
  itemsSource: Array<T> | Observable<Array<T>> | VgCollectionView<T> | VgStreamView<T>;

  /**
   * 1行の高さをpixel単位で取得または設定します
   */
  @Input()
  rowHeight = 24;

  /**
   * ヘッダ用のダミー行を自動追加するかどうか
   */
  @Input()
  addDummyRow = true;

  /**
   * ヘッダ行を表示するか
   */
  @Input()
  showRowHeader = true;

  /**
   * 最小幅をピクセルで指定します。
   */
  @Input()
  minWidth = null;

  @Input()
  get selectionMode() {
    return this._selectionMode;
  }

  set selectionMode(s: SelectionMode | string) {
    if (typeof s === 'string') {
      this._selectionMode = selectionModeFromString(s);
    } else {
      this._selectionMode = s;
    }
  }

  @Input()
  sortDescriptorIndex = null;

  @Input()
  templateCacheSize = 100;

  // EdgeのスクロールバグへのFixを無効化する
  @Input()
  disableEdgeScrollFix = false;

  /// 複数行モードでの、列の幅を指定します。
  @Input()
  multipleRowModeColumnWidth?: Array<string | number> = undefined;

  /// アイテムの同一性判定に用いる関数を設定します
  @Input()
  equalItemFunction: any = undefined;

  /** 行に適用するclassesを返す関数 */
  @Input()
  additionalRowClasses: ((row: T) => string[]) | undefined = undefined;

  /** KeyDown eventを無視するかどうか */
  @Input()
  ignoreKeyDown = false;

  private _selectionMode = SelectionMode.None;
  private _selectionType = SelectionMode.None;

  // 複数行モード時の行数
  _multipleRowCount = 1;

  /**
   * 表示用の行情報
   */
  rows: Array<T | GroupHeader>;

  /**
   * 表示用のindex
   */
  private _indexes: Array<number>;
  private _indexesSubject = new BehaviorSubject<Array<number>>([]);
  _indexes$ = this._indexesSubject.asObservable();
  private _indexesStream = false;
  private _indexesAddRow = false;
  private _indexesReverse = false;

  /* 表示用の項目 */

  visibleColumns: Array<VgGridColumn<T>> = [];
  visibleColumnsWidth: Array<VgGridColumnWidth> = [];

  private _itemSourceSubscription: Subscription;

  private _columns: Array<VgGridColumn<T>> = [];
  private _columnsChildrenSubscription: Subscription;
  private _columnsChildrenUpdateSubscription: Subscription;

  @ContentChildren(VG_GRID_COLUMN_GETTER as any)
  private _columnComponents: QueryList<VgGridColumnGetter<T>>;

  @ViewChild('cdkViewport', { static: true })
  private _cdkViewport: CdkVirtualScrollViewport;

  @ViewChild('headerScrollWrapper', { static: true })
  private _headerScrollWrapper: ElementRef<HTMLDivElement>;

  // 次のMouseUp時に編集を開始するかどうか
  private _nextStartEditing: boolean;

  public selectedItem: T | GroupHeader;
  public selectedIndex: number;

  // 複数選択時のベースとなるアイテム
  private _baseItem: any;
  private _baseIndex: number;

  // 複数選択時の選択されたアイテム
  public selectedItemSet = new Set<any>();
  private _selectedItemSetCommited = new Set<any>();
  private _ctrlMultipleSelection: CtrlMultipleSelection = CtrlMultipleSelection.None;

  public editingItem: any = null;
  public editingColumn: VgGridColumn<T> = null;
  public editingContext: EditingContext = null;

  private _editableColumn: VgGridColumn<T> = null;
  private _pendingUpdateByEditing: Array<any> = null;

  // マウス長押しでの行移動
  private _pointerEventTarget: HTMLElement = null;
  private _pointerEventOriginalTarget: any = null;
  // マウスカーソルが範囲外にある時の強さ
  private _pointerPosition: number;
  // マウスカーソルが範囲外にある時の更新のタイマー
  private _pointerMoveRowSubscription: Subscription;

  @Input()
  vgGridColumnsState: VgGridColumnsState | undefined = undefined;

  @Output()
  selectionChanged = new EventEmitter<{}>();

  @Output()
  columnWidthChanged = new EventEmitter<{}>();

  @Output()
  beginningEdit = new EventEmitter<CellEditingEvent<T>>();

  @Output()
  cellEditEnding = new EventEmitter<CellEditingEvent<T>>();

  @Output()
  rowDeleting = new EventEmitter<RowDeletingEvent>();

  @Output()
  cellClick = new EventEmitter<CellClickEvent<T>>();

  @Output()
  cellDblClick = new EventEmitter<CellClickEvent<T>>();

  @Output()
  rowDragged = new EventEmitter<RowDraggedEvent>();

  @Output()
  rowDragStart = new EventEmitter<RowDragStartEvent>();

  @Output()
  cellHeaderClick = new EventEmitter<CellHeaderClickEvent<T>>();

  @Output()
  cellHeaderContextMenu = new EventEmitter<CellHeaderClickEvent<T>>();

  @Output()
  columnSorted = new EventEmitter<ColumnEvent<T>>();

  @Output()
  columnFiltered = new EventEmitter<ColumnFilteredEvent>();

  @Output()
  columnFilterClosed = new EventEmitter<{}>();

  @Output()
  columnFilterOpened = new EventEmitter<{}>();

  @Output()
  columnFilterOpening = new EventEmitter<{}>();

  @Output()
  columnFilterFocusOut = new EventEmitter<FocusOutEvent>();

  @ContentChildren(VgGridCellTemplateDirective)
  private _cellTemplates: QueryList<VgGridCellTemplateDirective<T>>;

  groupHeaderTemplate: TemplateRef<any> = null;

  _showFlagForAvoidEdgeScrollBug = true;

  private _dragRow = false;
  _dragItem: any = null;
  _dragIndex: number = null;
  _dragTargetUp: any = null;
  _dragTargetDown: any = null;
  _dragCount = 0;

  // 横幅の数字(px)
  _realWidth = 0;

  _requiredWidth: number = null;

  _stickyColumnsWidth = 0;
  _stickyColumnsVisibleIndex = null;
  _scrollLeft = 0; // CSSでStickyが利用できない場合に疑似的に再現する

  SelectionMode = SelectionMode;

  initializedAllColumns = false;

  leftScrollPos: number;

  get editing(): boolean {
    return !!this.editingItem;
  }

  get columns(): Readonly<Array<VgGridColumn<T>>> {
    return this._columns;
  }

  cssStickyUsable: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private platform: Platform,
    private dragDropManager: DragDropManagerService,
    private zone: NgZone
  ) {
    // IEは利用不可。Edgeでは、利用可能だが挙動が不安定なので利用しない
    this.cssStickyUsable = !(this.platform.EDGE || this.platform.TRIDENT);
  }

  ngOnInit() {
    if (!this.disableEdgeScrollFix && (this.platform.EDGE || this.platform.TRIDENT)) {
      this._showFlagForAvoidEdgeScrollBug = false;
      setTimeout(() => {
        this._showFlagForAvoidEdgeScrollBug = true;
        setTimeout(() => {
          this.updateSize();
          setTimeout(() => {
            this.updateSize();
          });
        });
      });
    }
    if (this.cssStickyUsable) {
      (this._cdkViewport.elementRef.nativeElement as HTMLElement).appendChild(this._headerScrollWrapper.nativeElement);
      this._headerScrollWrapper.nativeElement.style.display = 'block';
    } else {
      this._headerScrollWrapper.nativeElement.classList.add('vg-grid-row-header-scroll-wrapper--sticky-unusable');
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if ('itemsSource' in simpleChanges) {
      this._updateItemSource();
    }
    if ('vgGridColumnsState' in simpleChanges) {
      this._updateColumnInfo();
    }
  }

  private _updateItemSource() {
    if (this.itemsSource instanceof Observable) {
      this._setItemSourceArrayObservable(this.itemsSource);
    } else if (this.itemsSource instanceof VgCollectionView) {
      this._setItemSourceArrayObservable(this.itemsSource.items);
    } else if (this.itemsSource instanceof VgStreamView) {
      this._setItemSourceArrayObservableForStream(this.itemsSource.items);
    } else if (this.itemsSource) {
      this._setItemSourceArray(this.itemsSource);
    } else {
      this._setItemSourceArray([]);
    }
  }

  private _setItemSourceArrayObservableForStream(obs: Observable<Array<any>>) {
    if (this._itemSourceSubscription) {
      this._itemSourceSubscription.unsubscribe();
      this._itemSourceSubscription = null;
    }
    this._itemSourceSubscription = obs.subscribe((arr) => {
      this._setItemSourceArrayForStream(arr);
      this._updateSortFilter();
    });
  }

  private _setItemSourceArrayForStream(arr: Array<any>) {
    if (this._indexesStream && this._indexesAddRow === this.addDummyRow) {
      // 差分更新
      const requireRows = arr.length + (this.addDummyRow ? 1 : 0);
      while (this._indexes.length < requireRows) {
        this._indexes.push(this._indexes.length - (this.addDummyRow ? 1 : 0));
      }
      if (requireRows < this._indexes.length) {
        this._indexes.splice(requireRows, this._indexes.length - requireRows);
      }
    } else {
      this._indexes = [];
      this._indexesStream = true;
      this._indexesAddRow = this.addDummyRow;
      if (this.addDummyRow) {
        this._indexes.push(null);
      }
      for (let i = 0; i < arr.length; i++) {
        this._indexes.push(i);
      }
    }
    if (this.itemsSource instanceof VgStreamView) {
      this._indexesReverse = this.itemsSource.reverse;
    }
    this._indexesSubject.next(this._indexes);
    this.rows = arr;
  }

  private _setItemSourceArrayObservable(obs: Observable<Array<T | GroupHeader>>) {
    if (this._itemSourceSubscription) {
      this._itemSourceSubscription.unsubscribe();
      this._itemSourceSubscription = null;
    }
    this._itemSourceSubscription = obs.subscribe((arr) => {
      this._setItemSourceArray(arr);
      this._updateSortFilter();
    });
  }

  private _setItemSourceArray(arr: Array<T | GroupHeader>) {
    if (this.editingItem) {
      this._pendingUpdateByEditing = arr;
      return;
    }
    this._indexes = [];
    this._indexesStream = false;
    this._indexesAddRow = false;
    this._indexesReverse = false;
    if (this.addDummyRow) {
      this._indexes.push(null);
    }
    for (let i = 0; i < arr.length; i++) {
      this._indexes.push(i);
    }
    let match = true;
    if (arr && this.rows && arr.length === this.rows.length) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== this.rows[i]) {
          match = false;
          break;
        }
      }
    } else {
      match = false;
    }
    this.rows = arr;
    if (!match) {
      this._clearMultipleSelection();
      this._updateIndex();
    }
    this._indexesSubject.next(this._indexes);
    this._changeDetectorRef.markForCheck();
  }

  private _updateSortFilter() {
    if (this.itemsSource instanceof VgCollectionView) {
      for (const col of this.visibleColumns) {
        col._sortDirection = this.itemsSource.getSortDirection(col.binding);
        col._filtered = !!this.itemsSource.getFilterDescription(col.binding);
      }
    } else if (this.itemsSource instanceof VgStreamView) {
      for (const col of this.visibleColumns) {
        if (col.allowSorting) {
          col._sortDirection = this.itemsSource.sortDirection;
        }
        col._filtered = !!this.itemsSource.getFilterDescription(col.binding);
      }
    }
  }

  private _updateIndex() {
    this.selectedIndex = undefined;
    for (let i = 0; i < this.rows.length; i++) {
      if (this.equalItemFunction ? this.equalItemFunction(this.selectedItem, this.rows[i]) : this.selectedItem === this.rows[i]) {
        this.selectedIndex = i;
        if (this.selectedItem !== this.rows[i]) {
          this.selectedItem = this.rows[i];
        }
      }
    }
    if (this.selectedIndex === undefined) {
      this.selectedItem = null;
      this.selectionChanged.emit();
    }
    this._baseIndex = undefined;

    for (let i = 0; i < this.rows.length; i++) {
      if (this._baseItem === this.rows[i]) {
        this._baseIndex = i;
      }
    }
  }

  ngAfterContentInit() {
    this._updateColumnFromChildren();
    if (this._columnsChildrenSubscription) {
      this._columnsChildrenSubscription.unsubscribe();
      this._columnsChildrenSubscription = null;
    }
    this._columnsChildrenSubscription = this._columnComponents.changes.subscribe(() => {
      this._updateColumnFromChildren();
    });
    this._cellTemplates.forEach((item) => {
      if (item.cellType === 'GroupHeader') {
        this.groupHeaderTemplate = item.template;
      } else {
        throw new Error(`Invalid CellType ${item.cellType}`);
      }
    });
    this.updateSize();
  }

  /**
   * コンポーネントのサイズに変化があった時に呼び出す
   *
   * @param forceUpdate Trueならば非表示時にも更新する
   */
  updateSize(forceUpdate = true) {
    if (this._updateColumnWidth(forceUpdate) || forceUpdate) {
      this._cdkViewport.checkViewportSize();
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * y座標のスクロール位置を先頭にリセットする
   */
  scrollToTop(scrollBehavior?: ScrollBehavior) {
    this._cdkViewport.scrollToOffset(0, scrollBehavior);
  }

  /**
   * 子のColumnComponentからColumnsを更新する
   */
  private _updateColumnFromChildren() {
    this._clearColumns();
    this.initializedAllColumns = true;
    if (this._columnsChildrenUpdateSubscription) {
      this._columnsChildrenUpdateSubscription.unsubscribe();
      this._columnsChildrenUpdateSubscription = null;
    }

    this._columnComponents.forEach((item, index) => {
      const subscription = item.change.subscribe(() => {
        if (this.initializedAllColumns) {
          this._updateColumnInfo();
        } else {
          this._updateColumnFromChildren();
        }
      });
      if (!this._columnsChildrenUpdateSubscription) {
        this._columnsChildrenUpdateSubscription = subscription;
      } else {
        this._columnsChildrenUpdateSubscription.add(subscription);
      }
      if (item.column) {
        this._columns.push(item.column);
      } else {
        this.initializedAllColumns = false;
      }
    });
    this._updateColumnInfo();
  }

  /**
   * カラム情報の個数及び内容に変化があった時に、更新とビューへの反映を行う
   */
  private _updateColumnInfo() {
    if (!this.initializedAllColumns) {
      return;
    }

    this._changeDetectorRef.markForCheck();

    this._editableColumn = null;
    this.visibleColumns.splice(0, this.visibleColumns.length);
    if (this.vgGridColumnsState) {
      this.vgGridColumnsState = normalizeColumnStateConfig(this.vgGridColumnsState, this._columns);
      for (const column of this._columns) {
        if (this.vgGridColumnsState[column.name].visible) {
          this.visibleColumns.push(column);
          column.stickyColumn = this.vgGridColumnsState[column.name].sticky;
        }
      }
      this.visibleColumns.sort((c1, c2) => {
        return this.vgGridColumnsState[c1.name].order - this.vgGridColumnsState[c2.name].order;
      });
    } else {
      for (const column of this._columns) {
        if (column.visible && column.initialVisible) {
          this.visibleColumns.push(column);
          if (column.allowEditing && this._editableColumn === null) {
            this._editableColumn = column;
          }
        }
      }
    }

    this._updateColumnWidth(true);
    this._updateSortFilter();
  }

  /**
   * Columnsの情報を削除する
   */
  private _clearColumns() {
    this.visibleColumnsWidth.splice(0, this.visibleColumnsWidth.length);
    this.visibleColumns.splice(0, this.visibleColumns.length);
    this._columns.splice(0, this._columns.length);
    if (this._columnsChildrenUpdateSubscription) {
      this._columnsChildrenUpdateSubscription.unsubscribe();
      this._columnsChildrenUpdateSubscription = null;
    }
  }

  private _updateColumnWidth(forceUpdate: boolean): boolean {
    if (this.visibleColumns.length === 0) {
      return false;
    }
    if (this.multipleRowModeColumnWidth) {
      return this._updateColumnWidthForMultipleRowMode(forceUpdate);
    }
    const fontSize = parseInt(window.getComputedStyle(this._cdkViewport.elementRef.nativeElement).fontSize, 10) || 16;
    let allWidth = this._cdkViewport.elementRef.nativeElement.clientWidth;
    if (allWidth === 0 && !forceUpdate) {
      return false;
    }
    this._realWidth = allWidth;
    if (this.minWidth && allWidth < this.minWidth) {
      allWidth = this.minWidth;
    }
    let fixedWidth = 0;
    let flexibleWidth = 0;
    this.visibleColumnsWidth = [];
    for (let i = 0; i < this.visibleColumns.length; i++) {
      this.visibleColumnsWidth.push(new VgGridColumnWidth());
      if (this.visibleColumns[i].widthUnit === WidthUnit.Px) {
        fixedWidth += Math.round(this.visibleColumns[i].width);
        this.visibleColumnsWidth[i].width = Math.round(Number(this.visibleColumns[i].width));
      } else if (this.visibleColumns[i].widthUnit === WidthUnit.Fr) {
        this.visibleColumnsWidth[i].width = this.visibleColumns[i].width;
        flexibleWidth += this.visibleColumnsWidth[i].width;
      } else if (this.visibleColumns[i].widthUnit === WidthUnit.Em) {
        fixedWidth += Math.round(this.visibleColumns[i].width * fontSize);
        this.visibleColumnsWidth[i].width = Math.round(Number(this.visibleColumns[i].width * fontSize));
      }
    }
    allWidth -= fixedWidth;
    for (let i = 0; i < this.visibleColumns.length; i++) {
      if (this.visibleColumns[i].widthUnit === WidthUnit.Fr) {
        this.visibleColumnsWidth[i].width = Math.round((this.visibleColumnsWidth[i].width / flexibleWidth) * allWidth);
      }
    }
    let sum = 0;
    for (let i = 0; i < this.visibleColumns.length; i++) {
      this.visibleColumnsWidth[i].left = sum;
      sum += this.visibleColumnsWidth[i].width;
    }
    allWidth += fixedWidth;
    if (sum !== allWidth) {
      for (let i = this.visibleColumns.length - 1; i >= 0; i--) {
        if (this.visibleColumns[i].widthUnit === WidthUnit.Fr) {
          this.visibleColumnsWidth[i].width -= sum - allWidth;
          break;
        }
      }
      sum = 0;
      for (let i = 0; i < this.visibleColumns.length; i++) {
        this.visibleColumnsWidth[i].left = sum;
        sum += this.visibleColumnsWidth[i].width;
      }
      if (sum !== allWidth) {
        console.error('Invalid size', sum, allWidth);
      }
    }
    // apply min, max width
    for (let i = 0; i < this.visibleColumns.length; i++) {
      if (this.visibleColumns[i].minWidthUnit !== undefined && this.visibleColumns[i].minWidth !== undefined) {
        if (this.visibleColumns[i].minWidthUnit === WidthUnit.Fr) {
          throw new Error('fr is not supported for min width');
        } else if (this.visibleColumns[i].minWidthUnit === WidthUnit.Px) {
          this.visibleColumnsWidth[i].width = Math.max(this.visibleColumnsWidth[i].width, this.visibleColumns[i].minWidth);
        } else if (this.visibleColumns[i].minWidthUnit === WidthUnit.Em) {
          this.visibleColumnsWidth[i].width = Math.max(this.visibleColumnsWidth[i].width, this.visibleColumns[i].minWidth * fontSize);
        }
      }
      if (this.visibleColumns[i].maxWidthUnit !== undefined && this.visibleColumns[i].maxWidth !== undefined) {
        if (this.visibleColumns[i].maxWidthUnit === WidthUnit.Fr) {
          throw new Error('fr is not supported for min width');
        } else if (this.visibleColumns[i].maxWidthUnit === WidthUnit.Px) {
          this.visibleColumnsWidth[i].width = Math.min(this.visibleColumnsWidth[i].width, this.visibleColumns[i].maxWidth);
        } else if (this.visibleColumns[i].maxWidthUnit === WidthUnit.Em) {
          this.visibleColumnsWidth[i].width = Math.min(this.visibleColumnsWidth[i].width, this.visibleColumns[i].maxWidth * fontSize);
        }
      }
    }
    // apply widthGrowFactor
    sum = 0;
    for (let i = 0; i < this.visibleColumns.length; i++) {
      this.visibleColumnsWidth[i].left = sum;
      sum += this.visibleColumnsWidth[i].width;
    }
    const remainderWidth = allWidth - sum;
    if (remainderWidth > 0) {
      let factorSum = 0;
      for (let i = 0; i < this.visibleColumns.length; i++) {
        if (this.visibleColumns[i].widthGrowFactor > 0) {
          // negativeな値はinvalidなのでサイレントに無視する
          factorSum += this.visibleColumns[i].widthGrowFactor;
        }
      }
      if (factorSum > 0) {
        for (let i = 0; i < this.visibleColumns.length; i++) {
          if (this.visibleColumns[i].widthGrowFactor > 0) {
            const grow = Math.round((this.visibleColumns[i].widthGrowFactor / factorSum) * remainderWidth);
            this.visibleColumnsWidth[i].width += grow;
            sum += grow;
          }
        }
        // adjust
        if (sum !== allWidth) {
          for (let i = this.visibleColumns.length - 1; i >= 0; i--) {
            if (this.visibleColumns[i].widthGrowFactor > 0) {
              this.visibleColumnsWidth[i].width -= sum - allWidth;
              break;
            }
          }
          // check
          sum = 0;
          for (let i = 0; i < this.visibleColumns.length; i++) {
            this.visibleColumnsWidth[i].left = sum;
            sum += this.visibleColumnsWidth[i].width;
          }
          if (sum !== allWidth) {
            console.error('Invalid size (widthGrowFactor)', sum, allWidth);
          }
        }
      }
    }
    sum = 0;
    this._stickyColumnsVisibleIndex = 0;
    this._stickyColumnsWidth = 0;
    for (let i = 0; i < this.visibleColumns.length; i++) {
      this.visibleColumnsWidth[i].left = sum;
      sum += this.visibleColumnsWidth[i].width;
      if (this.visibleColumns[i].stickyColumn || (!this.vgGridColumnsState && this.visibleColumns[i].initialStickyColumn)) {
        this._stickyColumnsVisibleIndex = i + 1;
        this._stickyColumnsWidth = sum;
      }
    }

    if (sum !== allWidth) {
      this._requiredWidth = sum;
    } else {
      this._requiredWidth = null;
    }
    this._multipleRowCount = 1;
    this.columnWidthChanged.emit({});
    this._changeDetectorRef.markForCheck();
    return true;
  }

  private _updateColumnWidthForMultipleRowMode(forceUpdate: boolean): boolean {
    if (this.visibleColumns.length === 0) {
      return false;
    }
    let allWidth = this._cdkViewport.elementRef.nativeElement.clientWidth;
    if (allWidth === 0 && !forceUpdate) {
      return false;
    }
    this._realWidth = allWidth;
    if (this.minWidth && allWidth < this.minWidth) {
      allWidth = this.minWidth;
    }
    // カラムの幅・位置の計算
    let fixedWidth = 0;
    let flexibleWidth = 0;
    const columnWidth: Array<number> = [];
    const leftPos: Array<number> = [];
    for (let i = 0; i < this.multipleRowModeColumnWidth.length; i++) {
      columnWidth.push(0);
      leftPos.push(0);
      if (typeof this.multipleRowModeColumnWidth[i] === 'number') {
        fixedWidth += Math.round(Number(this.visibleColumns[i]));
        columnWidth[i] = Math.round(Number(this.visibleColumns[i]));
      } else {
        if (this.multipleRowModeColumnWidth[i] === '*') {
          columnWidth[i] = 1;
        } else {
          columnWidth[i] = Number(String(this.multipleRowModeColumnWidth[i]).slice(0, -1));
        }
        flexibleWidth += columnWidth[i];
      }
    }
    allWidth -= fixedWidth;
    for (let i = 0; i < this.multipleRowModeColumnWidth.length; i++) {
      if (typeof this.multipleRowModeColumnWidth[i] !== 'number') {
        columnWidth[i] = Math.round((columnWidth[i] / flexibleWidth) * allWidth);
      }
    }
    let sum = 0;
    for (let i = 0; i < this.multipleRowModeColumnWidth.length; i++) {
      leftPos[i] = sum;
      sum += columnWidth[i];
    }
    allWidth += fixedWidth;
    if (sum !== allWidth) {
      for (let i = columnWidth.length - 1; i >= 0; i--) {
        if (typeof this.multipleRowModeColumnWidth[i] !== 'number') {
          columnWidth[i] -= sum - allWidth;
          break;
        }
      }
      sum = 0;
      for (let i = 0; i < this.multipleRowModeColumnWidth.length; i++) {
        leftPos[i] = sum;
        sum += columnWidth[i];
      }
      if (sum !== allWidth) {
        console.error('Invalid size', sum, allWidth);
      }
    }

    // 複数行を考慮した列毎の位置の決定
    const used = new Set<string>();
    let maxRow = 0;

    this.visibleColumnsWidth = [];
    for (let i = 0; i < this.visibleColumns.length; i++) {
      let found = false;
      this.visibleColumnsWidth.push(new VgGridColumnWidth());
      for (let row = 0; !found && row <= maxRow; row++) {
        for (let column = 0; !found && column < columnWidth.length; column++) {
          const key = `${row}:${column}`;
          if (used.has(key)) {
            continue;
          }
          this.visibleColumnsWidth[i].left = leftPos[column];
          this.visibleColumnsWidth[i].rowIndex = row;
          this.visibleColumnsWidth[i].rowSpan = this.visibleColumns[i].rowSpan;
          this.visibleColumnsWidth[i].width = 0;
          maxRow = Math.max(maxRow, this.visibleColumnsWidth[i].rowIndex + this.visibleColumnsWidth[i].rowSpan);
          for (let j = 0; j < this.visibleColumns[i].colSpan; j++) {
            if (j + column < columnWidth.length) {
              this.visibleColumnsWidth[i].width += columnWidth[j + column];
            }
            for (let k = 0; k < this.visibleColumns[i].rowSpan; k++) {
              const useKey = `${row + k}:${column + j}`;
              used.add(useKey);
            }
          }
          found = true;
          break;
        }
      }
    }
    this._multipleRowCount = maxRow;
    this.columnWidthChanged.emit({});
    this._changeDetectorRef.markForCheck();
    return true;
  }

  trackItem(index, item) {
    return item;
  }

  onRowClick(item: any, index: number) {
    if (this.selectionMode === SelectionMode.None) {
      return;
    }
    if (!item || index < 0) {
      return;
    }
    if (index >= this.rows.length) {
      return;
    }
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      this._baseIndex = this.selectedIndex = index;
      this._selectionType = SelectionMode.SingleRow;
      this._scrollIntoView();
      this.selectionChanged.emit({});
    }
    if (this.selectionMode === SelectionMode.MultipleRow) {
      this._baseItem = item;
      this._baseIndex = index;
      this._clearMultipleSelection();
    }
  }

  onRowClickForMultipleSelection(item: any, index: number) {
    if (this.selectionMode === SelectionMode.None) {
      return;
    }
    if (!item || index < 0) {
      return;
    }
    if (index >= this.rows.length) {
      return;
    }
    if (this.selectionMode === SelectionMode.SingleRow) {
      this.onRowClick(item, index);
    }

    if (this._ctrlMultipleSelection === CtrlMultipleSelection.None) {
      if (this.selectedItem !== item) {
        this.selectedItem = item;
        this.selectedIndex = index;
        this._scrollIntoView();
        this._rangeSelection(this.selectedIndex, true);
        this.selectionChanged.emit();
      }
    } else {
      this._rangeSelection(index, this._ctrlMultipleSelection === CtrlMultipleSelection.Add);
    }
  }

  onCtrlRowClick(item: any, index: number) {
    if (this.selectionMode !== SelectionMode.MultipleRow) {
      return;
    }
    if (!item || index < 0) {
      return;
    }
    if (index >= this.rows.length) {
      return;
    }
    this._selectionType = SelectionMode.MultipleRow;
    if (this.selectedItemSet.has(item)) {
      this._baseIndex = item;
      this._baseIndex = index;
      this.selectedItemSet.delete(item);
      this._ctrlMultipleSelection = CtrlMultipleSelection.Remove;
    } else {
      this._baseIndex = item;
      this._baseIndex = index;
      this.selectedItemSet.add(item);
      this._ctrlMultipleSelection = CtrlMultipleSelection.Add;
    }
    this._selectedItemSetCommited.clear();
    this.selectedItemSet.forEach((a) => {
      this._selectedItemSetCommited.add(a);
    });
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.ignoreKeyDown) {
      return;
    }
    if (e.key === 'ArrowUp' || e.key === 'Up') {
      this._moveIndex(-1, e.shiftKey, e);
    } else if (e.key === 'ArrowDown' || e.key === 'Down') {
      this._moveIndex(1, e.shiftKey, e);
    } else if (e.key === 'PageUp') {
      this._moveIndex(-this._pageUpDownMoveSize(), e.shiftKey, e);
    } else if (e.key === 'PageDown') {
      this._moveIndex(this._pageUpDownMoveSize(), e.shiftKey, e);
    } else if (e.key === 'Home') {
      this._moveIndex(-this.rows.length, e.shiftKey, e);
    } else if (e.key === 'End') {
      this._moveIndex(this.rows.length, e.shiftKey, e);
    } else if (e.key === 'Enter') {
      if (this.editingItem) {
        this.endCellEditing();
      }
    } else if (e.key === 'Delete' || e.key === 'Backspace' || e.key === 'Del') {
      // IE11ではDeleteキーはDelとなる
      if (!this.editingItem) {
        this.deleteSelected();
        e.preventDefault();
      }
    } else if (e.key === 'Escape' || e.key === 'Esc') {
      // IE11では、EscapeキーはEscとなる。
      if (this.editingItem) {
        this.endCellEditing(true);
      }
    } else if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
      if (this.editingItem) {
      } else {
        if (this.rows.length > 0) {
          this.selectedIndex = 0;
          if (this.selectedItem !== this.rows[0]) {
            this.selectedItem = this.rows[0];
            this.selectionChanged.emit();
          }
          this._baseIndex = this.rows.length - 1;
          this._baseItem = this.rows[this.rows.length - 1];
          this._rangeSelection(0, true);
          this._scrollIntoView();
        }
        e.preventDefault();
      }
    } else if (e.key.length === 1 && !e.ctrlKey && !e.metaKey && !e.altKey) {
      if (!this.editingItem && this.selectedItem && this._editableColumn) {
        this._startEdit(this.selectedItem, this._editableColumn, e.key);
        e.preventDefault();
      }
    } else {
    }
  }

  private _pageUpDownMoveSize(): number {
    return Math.floor(this._cdkViewport.getViewportSize() / (this._multipleRowCount * this.rowHeight)) - (this.showRowHeader ? 1 : 0);
  }

  private _moveIndex(delta: number, shiftKey: boolean, event: KeyboardEvent) {
    if (this.selectedIndex === null || this.selectedIndex === undefined) {
      return;
    }
    event.preventDefault();
    this.selectedIndex += delta;
    if (this.selectedIndex < 0) {
      this.selectedIndex = 0;
    } else if (this.selectedIndex >= this.rows.length) {
      this.selectedIndex = this.rows.length - 1;
    }
    if (this.selectedItem !== this.rows[this.selectedIndex]) {
      this.selectedItem = this.rows[this.selectedIndex];
      this._scrollIntoView();
      this.selectionChanged.emit({});
    }

    if (!shiftKey) {
      this._baseIndex = this.selectedIndex;
      this._clearMultipleSelection();
    } else {
      this._rangeSelection(this.selectedIndex, true);
    }
  }

  _scrollIntoView() {
    this.focus();
    if (this.selectedIndex === null || this.selectedIndex === undefined) {
      return;
    }
    const top = this._cdkViewport.measureScrollOffset('top');
    const bottom = top + this._cdkViewport.getViewportSize() - (this.addDummyRow ? this._multipleRowCount * this.rowHeight : 0);
    const itemTop = this.selectedIndex * (this._multipleRowCount * this.rowHeight);
    const itemBottom = (this.selectedIndex + 1) * (this._multipleRowCount * this.rowHeight);
    if (itemBottom >= bottom) {
      this._cdkViewport.scrollToOffset(itemBottom - this._cdkViewport.getViewportSize() + this._multipleRowCount * this.rowHeight, 'auto');
    } else if (itemTop <= top) {
      this._cdkViewport.scrollToIndex(this.selectedIndex);
    }
  }

  scrollIntoViewWithIndexes(minIndex: number, maxIndex: number) {
    // Note: this.focus(); は必要？
    const top = this._cdkViewport.measureScrollOffset('top');
    const bottom = top + this._cdkViewport.getViewportSize() - (this.addDummyRow ? this._multipleRowCount * this.rowHeight : 0);
    const itemTop = minIndex * (this._multipleRowCount * this.rowHeight);
    const itemBottom = (maxIndex + 1) * (this._multipleRowCount * this.rowHeight);
    if (itemTop <= top) {
      // 両側はみ出ている場合はtop側を優先して合わせる
      this._cdkViewport.scrollToIndex(minIndex);
    } else if (itemBottom >= bottom) {
      this._cdkViewport.scrollToOffset(itemBottom - this._cdkViewport.getViewportSize() + this._multipleRowCount * this.rowHeight, 'auto');
    }
  }

  onCellPointerDown(row: number, item, column: VgGridColumn<T>, event: MouseEvent) {
    if (column.allowRowDragging) {
      this._dragRow = true;
      return;
    } else {
      this._dragRow = false;
    }
    if (event.button === 0) {
      if (event.ctrlKey || event.metaKey) {
        this.onCtrlRowClick(item, row);
      } else if (event.shiftKey) {
        this.onRowClickForMultipleSelection(item, row);
      } else {
        this._nextStartEditing = this.selectedItem === item;
        this.onRowClick(item, row);
      }
      if (!this.editing && column.capturePointer) {
        this.dragDropManager.prepareDrag(this, event);
        // this._pointerEventTarget = this._cdkViewport.elementRef.nativeElement;
        // this._pointerEventOriginalTarget = [row, item, column];
        //
        // this._pointerEventTarget.setPointerCapture(event.pointerId);
      }
    } else if (event.button === 2) {
      if (!(event.ctrlKey || event.metaKey) && !event.shiftKey) {
        if (!this.selectedItemSet.has(item)) {
          this._nextStartEditing = this.selectedItem === item;
          this.onRowClick(item, row);
        }
      }
    }
    this.cellClick.emit(new CellClickEvent(item, column, row, event));
  }

  _onCellDblClick(row: number, item, column: VgGridColumn<T>, event: MouseEvent) {
    this.cellDblClick.emit(new CellClickEvent(item, column, row, <PointerEvent>event));
  }

  onCellPointerUp(row: number, item, column: VgGridColumn<T>, event: MouseEvent) {
    if (this._nextStartEditing && column.allowEditing && item && item === this.selectedItem) {
      this._startEdit(item, column);
    }
    this._dragRow = false;
  }

  private _startEdit(item: any, column: VgGridColumn<T>, defaultString?: string) {
    this._clearMultipleSelection();
    if (this.editingItem === null && this.editingColumn === null) {
      const eventArgs = new CellEditingEvent(item, column, false);
      this.beginningEdit.emit(eventArgs);
      if (!eventArgs.cancel) {
        this._pendingUpdateByEditing = null;
        this.editingItem = item;
        this.editingColumn = column;
        if (defaultString) {
          this.editingContext = new EditingContext(defaultString, item[column.binding], true);
        } else {
          this.editingContext = new EditingContext(item[column.binding], item[column.binding], false);
        }
      }
    }
  }

  private _endEdit(commit = true) {
    if (this.editingItem) {
      if (commit) {
        const eventArgs = new CellEditingEvent(this.editingItem, this.editingColumn, false, this.editingContext);
        this.cellEditEnding.emit(eventArgs);
        this.refresh();
      }

      this.editingItem = null;
      this.editingColumn = null;
      this.editingContext = null;
      if (this._pendingUpdateByEditing) {
        this._setItemSourceArray(this._pendingUpdateByEditing);
        this._pendingUpdateByEditing = null;
      }
      this.focus();
    }
  }

  _onScroll() {
    if (this.showRowHeader) {
      if (!this.cssStickyUsable) {
        const element: HTMLDivElement = this._headerScrollWrapper.nativeElement;
        element.scrollLeft = this._cdkViewport.measureScrollOffset('left');
        this._scrollLeft = this._cdkViewport.measureScrollOffset('left');
      }
    }
    this._endEdit();
  }

  ngAfterViewChecked() {}

  /**
   * 表示されている index番目のカラムの幅をピクセル数で取得します。
   * @Input index カラムのIndex
   * @return カラムの幅。カラムが存在しない場合はnull
   */
  getColumnWidth(index: number): number {
    if (index >= this.visibleColumnsWidth.length || index < 0) {
      return null;
    }
    return this.visibleColumnsWidth[index].width;
  }

  /**
   * 現在編集中のセルの編集を確定またはキャンセルします。
   */
  endCellEditing(cancel = false) {
    this._endEdit(!cancel);
  }

  refresh() {
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this._itemSourceSubscription) {
      this._itemSourceSubscription.unsubscribe();
      this._itemSourceSubscription = null;
    }
    if (this._columnsChildrenUpdateSubscription) {
      this._columnsChildrenUpdateSubscription.unsubscribe();
      this._columnsChildrenUpdateSubscription = null;
    }
    if (this._columnsChildrenSubscription) {
      this._columnsChildrenSubscription.unsubscribe();
      this._columnsChildrenSubscription = null;
    }
    if (this._pointerMoveRowSubscription) {
      this._pointerMoveRowSubscription.unsubscribe();
      this._pointerMoveRowSubscription = null;
    }
  }

  // TODO: Remove It
  _isGroupHeader(item: any): boolean {
    return item instanceof GroupHeader;
  }

  _onHeaderClick(column: VgGridColumn<T>, event: MouseEvent) {
    const ev = new CellHeaderClickEvent(column, event);
    this.cellHeaderClick.emit(ev);
    if (ev.cancel) {
      return;
    }
    if (column.allowSorting) {
      if (this.itemsSource instanceof VgCollectionView) {
        if (event.ctrlKey || event.metaKey) {
        } else {
          this._updateSortCollection(this.itemsSource, column);
        }
        this.columnSorted.emit(new ColumnEvent(column));
      } else if (this.itemsSource instanceof VgStreamView) {
        if (event.ctrlKey || event.metaKey) {
          this.itemsSource.sortDirection = null;
          this.itemsSource.update();
        } else {
          if (this.itemsSource.sortDirection === SortDirection.Asc) {
            this.itemsSource.sortDirection = SortDirection.Desc;
          } else if (this.itemsSource.sortDirection === SortDirection.Desc) {
            this.itemsSource.sortDirection = null;
          } else {
            this.itemsSource.sortDirection = SortDirection.Asc;
          }
          this.itemsSource.update();
        }
        this.columnSorted.emit(new ColumnEvent(column));
      }
    }
  }

  _clearMultipleSelection() {
    this.selectedItemSet.clear();
    this._selectionType = SelectionMode.SingleRow;
  }

  _rangeMultipleSelection() {
    // TODO: 前回の状態を保持して、追加された範囲のみを追加する
    const low = Math.min(this._baseIndex, this.selectedIndex);
    const high = Math.max(this._baseIndex, this.selectedIndex);
    this.selectedItemSet.clear();
    for (let i = low; i <= high; i++) {
      this.selectedItemSet.add(this.rows[i]);
    }

    this._selectionType = SelectionMode.MultipleRow;
    this._changeDetectorRef.markForCheck();
  }

  _rangeSelection(index: number, add: boolean) {
    const low = Math.min(this._baseIndex, index);
    const high = Math.max(this._baseIndex, index);
    this.selectedItemSet.clear();
    this._selectedItemSetCommited.forEach((item) => {
      this.selectedItemSet.add(item);
    });
    for (let i = low; i <= high; i++) {
      if (add) {
        this.selectedItemSet.add(this.rows[i]);
      } else {
        this.selectedItemSet.delete(this.rows[i]);
      }
    }
    this._selectionType = SelectionMode.MultipleRow;
    this._changeDetectorRef.markForCheck();
  }

  private _clearSortCollection(collection: VgCollectionView) {
    if (this.sortDescriptorIndex === null) {
      collection.sortDescriptions.splice(0, collection.sortDescriptions.length);
    } else {
      collection.sortDescriptions.splice(this.sortDescriptorIndex, 1, null);
    }
    collection.update();
  }

  private _updateSortCollection(collection: VgCollectionView, column: VgGridColumn<T>) {
    const dir = collection.getSortDirection(column.binding);
    const nextDir =
      dir === null
        ? column.defaultSortDirection
        : dir === column.defaultSortDirection && dir === SortDirection.Asc
        ? SortDirection.Desc
        : dir === column.defaultSortDirection && dir === SortDirection.Desc
        ? SortDirection.Asc
        : null;

    if (nextDir === null) {
      this._clearSortCollection(collection);
      return;
    }
    const desc = new SortDescription(column.binding, nextDir, column.sortComparer);
    if (this.sortDescriptorIndex === null) {
      collection.sortDescriptions.splice(0, collection.sortDescriptions.length);
      collection.sortDescriptions.push(desc);
    } else {
      collection.sortDescriptions.splice(this.sortDescriptorIndex, 1, desc);
    }
    collection.update();
  }

  _onFilterMouseDown(column: VgGridColumn<T>, event: MouseEvent, popover: NgbPopover) {
    if (event.button === 0) {
      event.stopPropagation();
      event.preventDefault();
      if (this.itemsSource instanceof VgCollectionView || this.itemsSource instanceof VgStreamView) {
        const filter = this._getFilterDescriptionForDialog(this.itemsSource, column);
        if (popover.isOpen()) {
          this._closeFilter(popover);
          return;
        }
        this.columnFilterOpening.emit({});
        popover.open({
          popover: popover,
          item: column,
          filter: filter,
        });
        this.columnFilterOpened.emit({});
      }
    }
  }

  private _getFilterDescriptionForDialog(collection: VgCollectionView | VgStreamView, column: VgGridColumn<T>): VgGridColumnFilter {
    let filter = collection.getFilterDescription(column.binding);
    if (column.filterType === FilterType.Value) {
      if (!filter) {
        filter = new VgGridColumnValueFilter();
        filter.binding = column.binding;
        filter.valueGetter = column.filterValueGetter;
      }
      if (filter instanceof VgGridColumnValueFilter) {
        filter.uniqueValues = collection.getUniqueValuesForColumn(filter.binding, filter.valueGetter);
        filter.nameGetter = column.filterNameGetter;
      }
    } else if (column.filterType === FilterType.Condition) {
      if (!filter) {
        filter = new VgGridColumnConditionFilter();
        if (filter instanceof VgGridColumnConditionFilter) {
          filter.roundPrecision = column.filterRoundPrecision;
          filter.valueType = column.filterValueType;
          filter.specialValueName = column.filterSpecialValueName;
          filter.specialValue = column.filterSpecialValue;
        }
        filter.valueGetter = column.filterValueGetter;
        filter.binding = column.binding;
      }
    } else if (column.filterType === FilterType.Choice) {
      if (!filter) {
        filter = new VgGridColumnChoiceFilter();
        if (filter instanceof VgGridColumnChoiceFilter) {
          filter.choices = column.filterChoices;
        }
        filter.valueGetter = column.filterValueGetter;
        filter.binding = column.binding;
      }
    }
    return filter;
  }

  _onFilterClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  _onApplyFilter(popover: NgbPopover, filter: VgGridColumnFilter, item: VgGridColumn<T>) {
    if (this.itemsSource instanceof VgCollectionView || this.itemsSource instanceof VgStreamView) {
      if (filter && !filter.clean) {
        filter.binding = item.binding;
        this.itemsSource.setFilterDescription(filter);
      } else {
        this.itemsSource.clearFilterDescription(item.binding);
      }
      this.itemsSource.update();
    }
    this._closeFilter(popover);
    this.columnFiltered.emit(new ColumnFilteredEvent());
  }

  _onCancelFilter(popover: NgbPopover) {
    this._closeFilter(popover);
  }

  _closeFilter(popover: NgbPopover) {
    popover.close();
    this.columnFilterClosed.emit();
  }

  _onFocusOutFilter(popover: NgbPopover, event: FocusOutEvent) {
    this.columnFilterFocusOut.emit(event);
  }

  focus() {
    this._cdkViewport.elementRef.nativeElement.focus();
  }

  _onDragEnter(row: number, item: any, event: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragItem) {
      return;
    }
    if (row === this._dragIndex) {
      return;
    }
    if (row < this._dragIndex) {
      if (this._dragTargetUp === item) {
        this._dragCount++;
      } else {
        this._dragCount = 1;
        this._dragTargetUp = item;
      }
      this._dragTargetDown = null;
    } else {
      this._dragTargetUp = null;
      if (this._dragTargetDown === item) {
        this._dragCount++;
      } else {
        this._dragCount = 1;
        this._dragTargetDown = item;
      }
    }
  }

  _onDragLeave(row: number, item: any, event: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragItem) {
      return;
    }
    if (row === this._dragIndex) {
      return;
    }
    if (this._dragTargetUp === item) {
      this._dragCount--;
      if (this._dragCount === 0) {
        this._dragTargetUp = null;
      }
    }
    if (this._dragTargetDown === item) {
      this._dragCount--;
      if (this._dragCount === 0) {
        this._dragTargetDown = null;
      }
    }
  }

  _onDrop(row: number, item: any, dragEvent: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragItem) {
      return;
    }
    dragEvent.preventDefault();
    this.rowDragged.emit(new RowDraggedEvent(this._dragIndex, row));
  }

  _onDragStart(row: number, item: any, event: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragRow) {
      event.preventDefault();
      return;
    }
    const ev = new RowDragStartEvent(row, item);
    this.rowDragStart.emit(ev);
    if (ev.cancel) {
      event.preventDefault();
      return;
    }

    if (!this.platform.TRIDENT) {
      event.dataTransfer.setData('data', 'dummy');
    }
    event.dataTransfer.effectAllowed = 'move';
    this._dragItem = item;
    this._dragIndex = row;
    this.onRowClick(item, row);
  }

  _onDragOver(row: number, item: any, event: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragRow) {
      event.preventDefault();
      return;
    }
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  _onDragEnd(row: number, item: any, event: DragEvent, rowElement: HTMLDivElement) {
    if (!this._dragItem) {
      return;
    }
    this._dragItem = null;
    this._dragIndex = null;
    this._dragTargetUp = null;
    this._dragTargetDown = null;
    this._dragCount = 0;
  }

  setCurrentItem(item: any) {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
      if (this.selectedItem && this.rows) {
        for (let i = 0; i < this.rows.length; i++) {
          if (this.rows[i] === this.selectedItem) {
            this.selectedIndex = i;
          }
        }
      }
      this._clearMultipleSelection();
      this.selectionChanged.emit();
    }
  }

  scrollIntoView() {
    this._scrollIntoView();
  }

  _onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  _onContextMenuHeader(column: VgGridColumn<T>, event: MouseEvent) {
    const ev = new CellHeaderClickEvent(column, event);
    this.cellHeaderContextMenu.emit(ev);
    event.preventDefault();
    event.stopPropagation();
  }

  deleteSelected() {
    if (!this.editingItem) {
      let items = this.getSelectionItems();
      if (items.length > 0) {
        if (this.itemsSource instanceof VgCollectionView) {
          items = this.itemsSource.deleteItems(items);
        }
        this.rowDeleting.emit(new RowDeletingEvent(items));
      }
    }
  }

  getSelectionItems(): Array<any> {
    const ret = [];
    if (this._selectionType === SelectionMode.SingleRow || this._selectionType === SelectionMode.MultipleRow) {
      for (const row of this.rows) {
        if (row === this.selectedItem || this.selectedItemSet.has(row)) {
          ret.push(row);
        }
      }
    }
    return ret;
  }

  _onFocusOut(event: FocusEvent) {}

  _onFocusIn(event: FocusEvent) {}

  _getRow(index: number): any {
    if (this._indexesReverse) {
      return this.rows[this.rows.length - index - 1];
    } else {
      return this.rows[index];
    }
  }

  onDragStart(ev: DragStartEvent): void {}

  onDragCancel(ev: DragCancelEvent): void {
    this.dragFinalize();
  }

  onDragEnd(ev: DragEndEvent): void {
    this.dragFinalize();
  }

  dragFinalize() {
    this.zone.runGuarded(() => {
      this._pointerPosition = null;
      if (this._pointerMoveRowSubscription) {
        this._pointerMoveRowSubscription.unsubscribe();
        this._pointerMoveRowSubscription = null;
      }

      this._ctrlMultipleSelection = CtrlMultipleSelection.None;
      this._selectedItemSetCommited.clear();
    });
  }

  onDragMove(ev: DragMoveEvent): void {
    this.zone.runGuarded(() => {
      const rect = this._cdkViewport.elementRef.nativeElement.getBoundingClientRect();
      const y = ev.clientY - rect.top;
      if (y < 0 || y >= rect.bottom - rect.top) {
        if (y < 0) {
          this._pointerPosition = y;
        } else {
          this._pointerPosition = y - (rect.bottom - rect.top);
        }
        if (!this._pointerMoveRowSubscription) {
          this._pointerMoveRowSubscription = timer(0, 60).subscribe(() => {
            this._cdkViewport.scrollToOffset(this._cdkViewport.measureScrollOffset('top') + this._pointerPosition);
          });
        }
      } else {
        this._pointerPosition = null;
        if (this._pointerMoveRowSubscription) {
          this._pointerMoveRowSubscription.unsubscribe();
          this._pointerMoveRowSubscription = null;
        }
        const scrollY = y + this._cdkViewport.measureScrollOffset();
        const row = Math.floor(scrollY / (this._multipleRowCount * this.rowHeight)) - (this.addDummyRow ? 1 : 0);
        this.onRowClickForMultipleSelection(this.rows[row], row);
      }
    });
  }
}
