import { TraceOperator } from './flex-operator';

export class Trace {
  constructor(public readonly traceId: number, private op: TraceOperator) {}

  clear(): void {
    this.op.clearTrace(this.traceId);
  }

  has(issueId: number): boolean {
    return this.op.hasTrace(this.traceId, issueId);
  }

  get(): Uint16Array {
    return this.op.getTrace(this.traceId);
  }

  dispose() {
    this.op = null;
  }
}
