import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantityMajorUnit',
})
export class QuantityMajorUnitPipe implements PipeTransform {
  transform(maxQuantity: number, args?: any): any {
    if (maxQuantity === 0) {
      return 1000;
    }
    let majorUnit = 1;
    while (majorUnit < maxQuantity) {
      majorUnit *= 10;
    }
    majorUnit /= 10;
    return majorUnit;
  }
}
