export const ElementType = {
  Stock: 'Stock',
  Group: 'Group',
} as const;
export type ElementType = typeof ElementType[keyof typeof ElementType];

export interface TreeMapStock {
  /// 種別
  elementType: 'Stock';
  /// 銘柄のキー(東証の銘柄コードなど)
  readonly key: string;
  /// 重み(時価総額など)
  weight: number;
  /// 名前
  name: string;
  /// Info: 追加で表示する情報(騰落率など)
  info: string;
  /// 背景色
  backgroundColor: string;

  /// 親のKey
  readonly parentsKey: Array<string>;
}

export type TreeMapStockUpdate = Partial<Pick<TreeMapStock, 'weight' | 'name' | 'info' | 'backgroundColor'>>;

export interface TreeMapGroup {
  /// 種別
  elementType: 'Group';

  /// グループのキー
  readonly key: string;

  // 名前
  name: string;

  /// 背景色
  backgroundColor: string;

  // パディング
  readonly padding: number;

  // グループタイトルのフォントサイズ
  readonly fontSize: number;

  // グループタイトルの高さ
  readonly titleHeight: number;

  // タイトルを表示する最小の横幅
  readonly titleMinSizeWidth: number;

  // タイトルを表示する最小の縦幅
  readonly titleMinSizeHeight: number;

  /// 親のKey
  readonly parentsKey: Array<string>;

  // 子の種別
  readonly childElementType: ElementType;

  // 子のキー
  readonly children: Array<string>;
}

export type TreeMapGroupUpdate = Partial<Pick<TreeMapGroup, 'name' | 'backgroundColor'>>;
export interface MouseEventWithElement {
  original: MouseEvent;
  element: TreeMapStock | TreeMapGroup | undefined;
}

export interface Theme {
  // 1px辺りの密度。2.0にすると4px(2x2)を1pxに縮小して描画する。等倍(1.0)だとフォントにアンチエイリアスが効かないため日本語の表示が汚なくなる。
  additionalDensity?: number;
  /// 全体のボーダーの幅
  borderWidth: number;
  /// 全体のボーダー色
  borderColor: string;
  /// 全体の背景色
  backgroundColor: string;
  /// 描画に利用するフォント
  font: string;
  /// 銘柄のボーダーの色
  stockBorderColor: string;
  /// 銘柄のボーダーの太さ
  stockBorderWidth: number;
  /// グループのボーダー色
  groupsBorderColor: ReadonlyArray<string>;
  /// グループのボーダーの太さ
  groupsBorderWidth: ReadonlyArray<number>;
}
