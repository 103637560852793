import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniChartComponent } from './mini-chart/mini-chart.component';
import { PriceChartPolygonPipe } from './price-chart-polygon.pipe';
import { MiniChartFromStockViewComponent } from './mini-chart-from-stock-view/mini-chart-from-stock-view.component';
import { TransformPricePointPipe } from './transform-price-point.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BriskCommonModule } from '../brisk-common/brisk-common.module';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, BriskCommonModule],
  declarations: [MiniChartComponent, PriceChartPolygonPipe, MiniChartFromStockViewComponent, TransformPricePointPipe],
  providers: [TransformPricePointPipe],
  exports: [MiniChartComponent, MiniChartFromStockViewComponent],
})
export class MiniChartModule {}
