import { Injectable, OnDestroy } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BootParameter } from './boot_parameter';
import { WindowRefService } from '../brisk-browser/window-ref.service';
import { LocalStorageService } from '../brisk-browser/local-storage.service';
import { ThemeService } from '../theme/theme.service';

@Injectable({
  providedIn: 'root',
})
export class KuroChartService implements OnDestroy {
  private readonly _removeMessageFunction: Function;
  // Chart APIの認証トークン
  private _authToken = '';
  // 玄ChartのURL
  private _chartUrl = '';
  // 玄ChartのOrigin
  private _origin = '';

  // TODO: issueCodeはURLに含めるべき。二つのWindowを短い間隔で開いた時にPostMessageが混信しうるため。
  private _issueCode: number;

  // 現在日
  private _date = '';

  constructor(
    eventManager: EventManager,
    private _theme: ThemeService,
    private _localStorage: LocalStorageService,
    private _windowRef: WindowRefService
  ) {
    this._removeMessageFunction = eventManager.addGlobalEventListener('window', 'message', (m: MessageEvent) => {
      this._processMessage(m);
    });
  }

  /**
   * セットアップが完了し、玄チャートとの連携が利用可能かどうか
   * @return {boolean} 玄チャートが利用可能ならば true, 利用不可ならば false
   */
  get initialized(): boolean {
    return this._authToken !== '';
  }

  /**
   * 玄チャート連携の初期化
   *
   * @param token    Chart APIの認証トークン
   * @param chartUrl 玄チャートのURL
   * @param origin   玄チャートのOrigin
   * @param date     基準日(YYYY-MM-DD)
   */
  setup(token: string, chartUrl: string, origin: string, date: string): void {
    this._authToken = token;
    this._chartUrl = chartUrl;
    this._origin = origin;
    this._date = date;
  }

  ngOnDestroy(): void {
    if (this._removeMessageFunction) {
      this._removeMessageFunction();
    }
  }

  /**
   * 玄チャートを起動する
   * @param issueCode 銘柄コード
   */
  openChart(issueCode: number): void {
    this._windowRef.nativeWindow.open(this._chartUrl);
    this._issueCode = issueCode;
  }

  private _processMessage(m: MessageEvent): void {
    // Check Origin
    if (!this._checkOrigin(m.origin)) {
      return;
    }
    if (m.data && 'isOpened' in m.data && m.data['isOpened']) {
      this._sendAuthenticationMessage(m);
    }
  }

  private _sendAuthenticationMessage(m: MessageEvent): void {
    const data: BootParameter = {
      baseDate: this._date,
      isPriceFormat: !(this._localStorage.getItem('chart_normalized') === '1'),
      theme: this._theme.theme,
      issueCode: this._issueCode,
      token: this._authToken,
    };
    if (m.source) {
      (<any>m.source.postMessage)(data, this._origin);
    }
  }

  private _checkOrigin(origin: string): boolean {
    return origin === this._origin;
  }
}
